import { Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import traslate from "../../../i18n/translate";
import catalogDiamondService from "../../../services/catalog-diamond.service";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
const { Option } = Select;
const EditViewDataMapping = () => {
  const [dealerId, setDealerId] = useState(0);
  const [fileList, setFilesList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [diamondColumnList, setDiamondColumnList] = useState([]);
  const [loading, setLoading] = useState(false);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        handleGetDiamondMappingDropdownDetails(
          loginDetials.responseData.dealerId
        );
        handleGetDiamondColumnsOfDealerUpload(
          loginDetials.responseData.dealerId
        );
      }
    }
  }, []);
  const handleGetDiamondMappingDropdownDetails = (id) => {
    try {
      let inputData = {
        dealerId: id,
      };
      setLoading(true);
      catalogDiamondService
        .GetDiamondMappingDropdownDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData[0]) {
              setFilesList(responseData[0]);
              setSelectedFile(responseData[0][0].dimFileName);
            }
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetDiamondColumnsOfDealerUpload = (id) => {
    try {
      let inputData = {
        dealerId: id,
      };

      catalogDiamondService
        .GetDiamondColumnsOfDealerUpload(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDiamondColumnList(responseData);
          } else {
            setDiamondColumnList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="prodetail__section">
      <div className="form__fields">
        <div className="col-md-12">
          <div className="prod__maindiv data__maping">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0">
                  <Link to={"/diamondMapping"}>{"<< Back"}</Link>
                </h4>
              </div>
              <div className="col-md-12">
                <b> {traslate("View /Edit Mapping Columns")} </b>
                <Select
                  style={{ marginLeft: "10px" }}
                  name="selectedFile"
                  placeholder="Select File"
                  optionFilterProp="children"
                  showSearch
                  onChange={(e) => {
                    setSelectedFile(e.target.value);
                  }}
                  value={selectedFile}
                >
                  {fileList.map((item, i) => {
                    return (
                      <Option value={item.dimFileName}>
                        {item.dimFileName}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="col-lg-12 mt-4">
                <Table
                  columns={[
                    {
                      title: traslate("Column ID"),
                      dataIndex: "columnId",
                      width: 150,
                    },
                    {
                      title: traslate("Column Name"),
                      dataIndex: "columnName",
                    },
                    {
                      title: traslate("Action"),
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <div className="input__block mb-0">
                              <img alt="edit-icon" src={Edit_icon} />
                            </div>
                          </React.Fragment>
                        );
                      },
                    },
                  ]}
                  loading={loading}
                  dataSource={diamondColumnList}
                  pagination={{
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditViewDataMapping;
