import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import traslate from "../../i18n/translate";
import { Table, Modal, Input, Button, Select, Spin, message } from "antd";
import UserImg_icon from "../../assets/images/UserImage.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import ManageWhiteLabelService from "../../services/white-label.service";
import { NotificationManager } from "react-notifications";
import { PlusOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const WhiteLabel = () => {
  const { Option } = Select;

  const [loading, setLoading] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [manageWhiteLabelList, setManageWhiteLabelList] = useState([]);
  const [dealerId, setDealerId] = useState();
  const [selectedRow, setSelectedRow] = useState({
    logo: "",
    logoImg: "",
    name: "",
    footerLogo: "",
    footerLogoImg: "",
    footerLogoLink: "",
    terms_of_Service: "",
    privacy_Statement: "",
  });
  const [visible, setVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [getUserDetailsLoading, setUserDetailsLoading] = useState(false);
  const [logoPath, setLogoPath] = useState("");
  const [footerLogoPath, setFooterLogoPath] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        handleGetCompanyWhiteLabel(loginDetials.responseData.dealerId);
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  const handleGetCompanyWhiteLabel = (id) => {
    setLoading(true);
    let inputData = {
      dealerID: id,
    };
    ManageWhiteLabelService.GetCompanyWhiteLabel(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            if (loginDetials) {
              if (loginDetials.responseData.masterRoleName === "Super") {
                setManageWhiteLabelList(responseData);
              } else {
                let filteredUser = responseData.filter((item) => {
                  return (
                    item.userId.toLowerCase() ===
                    loginDetials.responseData.userId.toLowerCase()
                  );
                });
                setManageWhiteLabelList(filteredUser);
              }
            }
            setLoading(false);
          } else {
            setManageWhiteLabelList([]);
            setLoading(false);
          }
        } else {
          setManageWhiteLabelList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setVisible(true);
  };

  const handleUpdateRow = () => {
    const formData = new FormData();
    formData.append("CompanyName", selectedRow.name);
    formData.append("FooterLogo", selectedRow.footerLogoImg);
    formData.append("FooterLogoLink", selectedRow.footerLogoLink);
    formData.append("Logo", selectedRow.logoImg);
    formData.append("Terms_of_Service", selectedRow.terms_of_Service);
    formData.append("Privacy_Statement", selectedRow.privacy_Statement);
    setSaveLoading(true);
    ManageWhiteLabelService.UpdateWhiteLabel(formData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Edited Successfully");
          setSaveLoading(false);
        }
        console.log("Row updated successfully:", response.data);
        setVisible(false);
        handleGetCompanyWhiteLabel();
      })
      .catch((error) => {
        console.log(error, "---Error Updating Data---");
      });
  };

  const handleOnChange = (e, name) => {
    const value = e.target ? e.target.value : e;
    setSelectedRow({
      ...selectedRow,
      [name]: value,
    });
  };

  const handleCancelImage = (type) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const EditUserFileUpload = async (e, imgId) => {
    var files = e.target.files[0];
    const [file] = e.target.files;
    if (file.name) {
      if (file.name.toLowerCase().match(/.(jpg|jpeg|png|gif||PNG)$/i)) {
        let img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (imgId === "logo") {
            setSelectedRow({
              ...selectedRow,
              logoImg: files,
              [imgId]: img.src,
            });
          } else {
            setSelectedRow({
              ...selectedRow,
              footerLogoImg: files,
              [imgId]: img.src,
            });
          }
        };
      }
    }
  };

  const columns = [
    {
      title: traslate("Logo"),
      dataIndex: "logo",
      key: "logo",
      width: 100,
      render: (row, item) => {
        return (
          <div className="image__block">
            {item.logo === "" ? (
              <img width="35" height="35" src={UserImg_icon} alt="" />
            ) : (
              <img width="35" height="35" src={item.logo} alt="" />
            )}
          </div>
        );
      },
    },
    {
      title: traslate("Company Name"),
      dataIndex: "name",
      sorter: (a, b) => a.activity.localeCompare(b.activity),
      width: 150,
    },
    {
      title: traslate("Footer Logo"),
      dataIndex: "footerLogo",
      key: "footerLogo",
      width: 100,
      render: (row, item) => {
        return (
          <div className="image__block">
            {item.footerLogo == "" ? (
              <img width="35" height="35" src={UserImg_icon} alt="" />
            ) : (
              <img width="35" height="35" src={item.footerLogo} alt="" />
            )}
          </div>
        );
      },
    },
    {
      title: traslate("Footer Logo Link"),
      dataIndex: "footerLogoLink",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: 150,
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      width: 80,
      render: (index, row) => (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} onClick={() => handleEditRow(row)} alt="" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="magento__mapping">
          <div className="row">
            <div className="col-md-12">
              <div className="heading__block mb-3">
                <h4 className="workarea__heading mt-0 mb-0">
                  {" "}
                  {traslate("White Label")}{" "}
                </h4>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="shipping_tbldiv">
                <div className="table__scroll">
                  <Table
                    columns={columns}
                    dataSource={manageWhiteLabelList}
                    loading={loading}
                    onChange={(pagination, filters, sorters) => {
                      document
                        .getElementsByClassName("content__area")[0]
                        .scroll(0, 0);
                    }}
                    pagination={{
                      showSizeChanger: true,
                      responsive: true,
                    }}
                    scroll={{ y: 800 }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={traslate("Edit User")}
                className="modal__manageUser__table"
                centered
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                  <Button key="back" onClick={() => setVisible(false)}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={saveLoading}
                    onClick={handleUpdateRow}
                  >
                    {traslate("Save Changes")}
                  </Button>,
                ]}
              >
                <Spin spinning={getUserDetailsLoading} size="large">
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="input__block">
                            <label>{traslate("Logo")}</label>
                            <div className="divimage_block">
                              <div className="img__showdiv manage-user-img-sp">
                                <img
                                  id="logo"
                                  src={selectedRow.logo}
                                  alt="logo"
                                  style={
                                    !selectedRow.logo
                                      ? {
                                          visibility: "hidden",
                                        }
                                      : {}
                                  }
                                />

                                <span onClick={() => handleCancelImage("logo")}>
                                  x
                                </span>
                              </div>

                              {!selectedRow.logo && (
                                <div>
                                  <label
                                    htmlFor="file-upload"
                                    class="primary-btn choose_file_upload"
                                  >
                                    {traslate("Choose Image")}
                                  </label>
                                  <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) =>
                                      EditUserFileUpload(e, "logo")
                                    }
                                  />
                                  <span> {traslate("No File Chosen")} </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="input__block">
                            <label>{traslate("Company Name")}</label>
                            <Input
                              value={selectedRow?.name}
                              onChange={(e) => handleOnChange(e, "name")}
                            />
                          </div>
                          <div className="input__block">
                            <label>{traslate("Footer Logo")}</label>
                            <div className="divimage_block">
                              <div className="img__showdiv manage-user-img-sp">
                                <img
                                  id="footerLogo"
                                  src={selectedRow.footerLogo}
                                  alt="footerLogo"
                                  style={
                                    !selectedRow.footerLogo
                                      ? {
                                          visibility: "hidden",
                                        }
                                      : {}
                                  }
                                />

                                <span
                                  onClick={() =>
                                    handleCancelImage("footerLogo")
                                  }
                                >
                                  x
                                </span>
                              </div>

                              {!selectedRow.footerLogo && (
                                <div>
                                  <label
                                    htmlFor="file-upload"
                                    class="primary-btn choose_file_upload"
                                  >
                                    {traslate("Choose Image")}
                                  </label>
                                  <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) =>
                                      EditUserFileUpload(e, "footerLogo")
                                    }
                                  />
                                  <span> {traslate("No File Chosen")} </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="input__block">
                            <label>{traslate("Footer Logo Link")}</label>
                            <Input
                              value={selectedRow?.footerLogoLink}
                              onChange={(e) =>
                                handleOnChange(e, "footerLogoLink")
                              }
                            />
                          </div>
                          <div className="">
                            <label>{traslate("Terms Of Service")}</label>
                            <CKEditor
                              editor={ClassicEditor}
                              
                              data={selectedRow?.terms_of_Service}
                              onChange={( event,editor) => {
                                const data = editor.getData();
                                setSelectedRow({
                                  ...selectedRow,
                                  terms_of_Service: data,
                                });
                              }}

                              // onChange={(e) => handleOnChange(e, "terms_of_Service")}
                              //data={state.Summary}
                              // onReady={(editor) => {}}
                              // onChange={(event, editor) => {
                              //   state.Error["Summary"] = null;
                              //   //const data = editor.getData();

                              // }}
                            />
                            {/* {state.Error ? (
                    <div className="text-danger">{state.Error["Summary"]}</div>
                  ) : null} */}
                          </div>
                          <br />
                          <div className="editor__section">
                            <label>{traslate("Privacy Statement")}</label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={selectedRow?.privacy_Statement}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setSelectedRow({
                                  ...selectedRow,
                                  privacy_Statement: data,
                                });
                              }}
                              //onChange={(e) => handleOnChange(e, "privacy_Statement")}
                              //data={state.Summary}
                              // onReady={(editor) => {}}
                              // onChange={(event, editor) => {
                              //   state.Error["Summary"] = null;
                              //   //const data = editor.getData();

                              // }}
                            />
                            {/* {state.Error ? (
                    <div className="text-danger">{state.Error["Summary"]}</div>
                  ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhiteLabel;
