import React, { Fragment } from "react";
import Network from "./Network";

export const Archived = () => {
  return (
    <Fragment>
      <Network type="Archived Vendor" />
    </Fragment>
  );
};

export default Archived;
