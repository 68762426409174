import React, { Fragment } from "react";
import Network from "./Network";

export const Popular = () => {
  return (
    <Fragment>
      <Network type="Popular" />
    </Fragment>
  );
};

export default Popular;
