import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Input, Select, Table, Button, Space, Modal, Spin } from "antd";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Create_icon from "../../assets/images/create_icon.png";
import UserImg_icon from "../../assets/images/UserImage.png";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";
import ManageUserService from "../../services/manage-user.service";
import DealerProfileService from "../../services/dealer-profile.service";
import LoginService from "../../services/login.service";
import AdminToolsDataService from "../../services/admin-tools.service";

import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import config from "../../helper/config";

const string = "ABCDEFGHIJKLMNOPQRSTUVXYZ";
const lowerstring = "abcdefghijklmnopqrstuvwxyz";
const numeric = "0123456789";
const punctuation = "!@#$&}{[]:;?><,./-=";

const ManageUser = () => {
  const { Option } = Select;
  const initialState = {
    lastLogin: "",
    userSinus: "",
    name: "",
    email: "",
    location: "",
    access: 2,
    showAccess: "",
    roleMasterID: "",
    jobTitle: "",
    notesTxtArea: "",
    userName: "",
    password: "",
    currentPassword: "",
    confirmPass: "",
    generatePassword: "",
    oldEmailId: "",
    userAvtar: "",
    file: null,
    userId: "",
  };

  const [addUserData, setAddUserData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [showCurrentPassword, setshowCurrentPassword] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [dealerId, setDealerId] = useState();
  const [delModalVisible, setDelModalVisible] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [isEmail, setIsEmail] = useState(false);
  const [isUserName, setIsUserName] = useState(true);
  const [useVisible, setUseVisible] = useState(false);
  const [getUserDetailsLoading, setUserDeatilsLoading] = useState(false);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
  const [accessLevelType, setAccessLevelType] = useState("");
  const [userId, setUserId] = useState("");

  const [booleanValue, setBooleanValue] = useState(false);
  //Validation starts
  const [validations, setValidations] = useState({
    nameVal: "",
    emailVal: "",
    userNameVal: "",
    passwordVal: "",
    confirmPassVal: "",
  });

  /*Modal Functionality Starts*/
  const [visible, setVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);

  /*API binding Starts*/
  const [manageUserGridList, setManageUserGridList] = useState([]);
  const [companyRoleMasterDetailsList, setCompanyRoleMasterDetailsList] =
    useState([]);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        handleGetDealersLocation(loginDetials.responseData.dealerId);
        handleGetCompanyManagerUserDetails(loginDetials.responseData.dealerId);

        setDealerId(loginDetials.responseData.dealerId);
        setUserId(loginDetials.responseData.userId);
        setDealerMemberTypeName(loginDetials.responseData.dealerMemberTypeName);
        setAccessLevelType(loginDetials.responseData.masterRoleName);
      }
      handleGetCompanyRoleMasterDetails();
    }
  }, []);

  const handleGetDealersLocation = (id) => {
    let inputData = {
      dealerID: id,
    };

    DealerProfileService.GetDealersLocation(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;

        if (message === "Success") {
          if (responseData) {
            setLocationList(responseData);
          }
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCurrentPassShowHide = () => {
    setshowCurrentPassword(!showCurrentPassword);
  };
  const handlePassShowHide = () => {
    setshowPassword(!showPassword);
  };

  const handlePassConfirmShowHide = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const handleResetPassValidation = () => {
    let isValid = true;

    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );
    if (addUserData.password == undefined || !addUserData.password) {
      isValid = false;
      validations.passwordVal = traslate("Password is compulsory");
    } else if (!addUserData.password.match(strongRegex)) {
      isValid = false;
      validations.passwordVal = traslate(
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"
      );
    } else {
      validations.passwordVal = "";
    }
    if (
      addUserData.confirmPass == undefined ||
      addUserData.password != addUserData.confirmPass ||
      !addUserData.confirmPass
    ) {
      isValid = false;
      validations.confirmPassVal = traslate("Password does not match");
    } else {
      validations.confirmPassVal = "";
    }
    setValidations((prevState) => ({
      ...prevState,
      ...validations,
    }));
    return isValid;
  };
  const handleValidation = () => {
    let isValid = true;

    if (!addUserData.name) {
      isValid = false;
      validations.nameVal = traslate("Name is compulsory");
    } else {
      validations.nameVal = "";
    }
    var validEmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!addUserData.email) {
      isValid = false;
      validations.emailVal = traslate("Email is compulsory");
    } else if (!addUserData.email.match(validEmailRegex)) {
      isValid = false;
      validations.emailVal = traslate("Email address is invalid");
    } else {
      if (isEmail == false) {
        validations.emailVal = "";
      }
    }
    if (!addUserData.userName.trim()) {
      isValid = false;
      validations.userNameVal = traslate("Username is compulsory");
    } else {
      if (isUserName == false) {
        validations.userNameVal = "";
      }
    }
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );

    if (!addUserData.password) {
      isValid = false;
      validations.passwordVal = traslate("Password is compulsory");
    } else if (!addUserData.password.match(strongRegex)) {
      isValid = false;
      validations.passwordVal = traslate(
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"
      );
    } else {
      validations.passwordVal = "";
    }

    if (!addUserData.confirmPass) {
      isValid = false;
      validations.confirmPassVal = traslate("Confirm Password is compulsory");
    } else if (addUserData.password != addUserData.confirmPass) {
      isValid = false;
      validations.confirmPassVal = traslate("Password does not match");
    } else {
      validations.confirmPassVal = "";
    }
    setValidations((prevState) => ({
      ...prevState,
      ...validations,
    }));
    return isValid;
  };

  const handleUpdateValidation = () => {
    let isValid = true;

    if (!addUserData.name) {
      isValid = false;
      validations.nameVal = traslate("Name is compulsory");
    } else {
      validations.nameVal = "";
    }
    var validEmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!addUserData.email) {
      isValid = false;
      validations.emailVal = traslate("Email is compulsory");
    } else if (!addUserData.email.match(validEmailRegex)) {
      isValid = false;
      validations.emailVal = traslate("Email address is invalid");
    } else {
      validations.emailVal = "";
    }
    setValidations((prevState) => ({
      ...prevState,
      ...validations,
    }));
    return isValid;
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAddUserData({
        ...addUserData,
        [name]: e,
      });
    } else {
      setAddUserData({
        ...addUserData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name == "name") {
        setValidations((prevdata) => ({
          ...prevdata,
          nameVal: "",
        }));
      }
      if (e.target.name == "email") {
        setValidations((prevdata) => ({
          ...prevdata,
          emailVal: "",
        }));
      }
      if (e.target.name == "userName") {
        setValidations((prevdata) => ({
          ...prevdata,
          userNameVal: "",
        }));
      }
      if (e.target.name == "password") {
        setValidations((prevdata) => ({
          ...prevdata,
          passwordVal: "",
        }));
      }
      if (e.target.name == "confirmPass") {
        setValidations((prevdata) => ({
          ...prevdata,
          confirmPassVal: "",
        }));
      }
    }
  };
  const handleInsertSubmit = () => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    } else {
      if (isEmail == false && isUserName == false) {
        handleInsertNewUserDetail(dealerId);
      }
    }
  };

  const handleSaveSubmit = () => {
    var isValid = handleUpdateValidation();
    if (!isValid) {
      return false;
    } else {
      if (isEmail == false) {
        handleUpdateUserDetails_New(dealerId);
      }
    }
  };

  const handleResetPassSubmit = (e) => {
    var isValid = handleResetPassValidation();
    if (!isValid) {
      return false;
    } else {
      if (dealerMemberTypeName == "Admin") {
        handleAdminResetPassword();
      } else {
        handleAdminSuperResetPassword();
      }
    }
  };
  const handleAdminResetPassword = () => {
    let inputData = {
      userName: addUserData.userName,
      password: addUserData.password,
    };

    LoginService.AdminResetPassword(inputData)
      .then((response) => {
        var message = response.data.message;

        if (message == "True") {
          NotificationManager.success(
            "Your Password Has Been Reset Successfully"
          );
          setshowPassword(false);
          setshowConfirmPassword(false);
          handleResetPassCancel();
        } else {
          NotificationManager.error("Your Password Has Been Reset Failed");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdminSuperResetPassword = () => {
    let inputData = {
      userName: addUserData.userName,
      currentpassword: addUserData.currentPassword,
      password: addUserData.password,
    };

    LoginService.AdminSuperResetPassword(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message == "True") {
          if (responseData.errorMsg == "Password Not Match") {
            NotificationManager.error("Your Current Password Did Not Match");
          } else {
            NotificationManager.success(
              "Your Password Has Been Reset Successfully"
            );
            setshowCurrentPassword(false);
            setshowPassword(false);
            setshowConfirmPassword(false);
            handleResetPassCancel();
          }
        } else {
          NotificationManager.error("Your Password Has Been Reset Failed");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const handleDeleteUser = (e) => {
    setDelModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setDelModalVisible(false);
  };

  //Generate password

  const generatePassword = (e) => {
    setUseVisible(true);
    e.preventDefault();
    let character = "";
    let password = "";
    while (password.length < 10) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(
        lowerstring.length * Math.random() * Math.random()
      );
      const entity4 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      character += string.charAt(entity1);
      character += numeric.charAt(entity2);
      character += lowerstring.charAt(entity3);
      character += punctuation.charAt(entity4);
      password = character;
    }
    password = password;
    setAddUserData((prevState) => ({
      ...prevState,
      generatePassword: password.substring(0, 10),
    }));
  };

  const setgenpassword = () => {
    setAddUserData((prevState) => ({
      ...prevState,
      password: addUserData.generatePassword,
      confirmPass: addUserData.generatePassword,
    }));
  };

  const handleCancelImage = () => {
    setAddUserData((prevState) => ({
      ...prevState,
      userAvtar: "",
    }));

    setAddUserData((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  //File upload starts
  const fileUpload = async (e) => {
    if (e.target.files[0].name) {
      if (
        e.target.files[0].name.toLowerCase().match(/.(jpg|jpeg|png|gif||PNG)$/i)
      ) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          setAddUserData({
            ...addUserData,
            file: e.target.files[0],
          });
        };
      }
    }
  };
  const EditUserFileUpload = async (e) => {
    if (e.target.files[0].name) {
      if (
        e.target.files[0].name.toLowerCase().match(/.(jpg|jpeg|png|gif||PNG)$/i)
      ) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          setAddUserData({
            ...addUserData,
            file: e.target.files[0],
          });
        };
      }
    }
  };
  //Validation ends

  const handleModalOpen = () => {
    setAddUserData(initialState);
    setVisible(true);
  };
  const handleTableModalOpen = (id, row) => {
    setBooleanValue(row.userId.toLowerCase() === userId.toLowerCase());
    setTableVisible(true);
    if (id) {
      handleGetCompanyManagerUserDetailsByUserId(id);
    }
  };
  const handleResetPassModalOpen = () => {
    handleGetCompanyManagerUserDetailsByUserId(addUserData.userId);
    setAddUserData((prevState) => ({
      ...prevState,
      password: "",
      confirmPass: "",
    }));
    setResetPasswordVisible(true);
  };
  const handleCancel = () => {
    setUseVisible(false);
    setVisible(false);
    setAddUserData(initialState);
    setValidations({
      nameVal: "",
      emailVal: "",
      userNameVal: "",
      passwordVal: "",
      confirmPassVal: "",
    });
    setshowPassword(false);
    setshowConfirmPassword(false);
  };
  const handleTableCancel = () => {
    setTableVisible(false);
    setAddUserData(initialState);
    setValidations({
      nameVal: "",
      emailVal: "",
      userNameVal: "",
      passwordVal: "",
      confirmPassVal: "",
    });
    setshowPassword(false);
    setshowConfirmPassword(false);
  };
  const handleResetPassCancel = () => {
    setResetPasswordVisible(false);
    setAddUserData((prevState) => ({
      ...prevState,
      password: "",
      confirmPass: "",
      currentPassword: "",
    }));
    setValidations({
      nameVal: "",
      emailVal: "",
      userNameVal: "",
      passwordVal: "",
      confirmPassVal: "",
    });
    setshowCurrentPassword(false);
    setshowPassword(false);
    setshowConfirmPassword(false);
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }
  const columns = [
    {
      title: "",
      dataIndex: "userAvtar",
      key: "userAvtar",
      width: 60,
      render: (row, item) => {
        return (
          <div className="image__block">
            {item.userAvtar == "" ? (
              <img width="35" height="35" src={UserImg_icon} alt="" />
            ) : (
              <img width="35" height="35" src={item.userAvtar} alt="" />
            )}
          </div>
        );
      },
    },
    {
      title: traslate("User"),
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 250,
    },
    {
      title: traslate("Activity"),
      dataIndex: "activity",
      sorter: (a, b) => a.activity.localeCompare(b.activity),
      width: 150,
    },
    {
      title: traslate("Logins"),
      dataIndex: "logins",
      sorter: (a, b) => a.logins - b.logins,
      width: 100,
    },
    {
      title: traslate("E-Mail"),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: 250,
    },
    {
      title: traslate("Access"),
      dataIndex: "access",
      sorter: (a, b) => a.access.localeCompare(b.access),
      width: 100,
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      width: 80,
      render: (index, row) => (
        <div className="action__btns">
          <div className="image__block">
            <img
              src={Edit_icon}
              onClick={() => handleTableModalOpen(row.userId, row)}
              alt=""
            />
          </div>
        </div>
      ),
    },
  ];

  const handleGetCompanyManagerUserDetails = (id) => {
    setLoading(true);
    let inputData = {
      dealerID: id,
    };
    ManageUserService.GetCompanyManagerUserDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            if (loginDetials) {
              if (loginDetials.responseData.masterRoleName == "Super") {
                setManageUserGridList(responseData);
              } else {
                let filteredUser = responseData.filter((item) => {
                  return (
                    item.userId.toLowerCase() ==
                    loginDetials.responseData.userId.toLowerCase()
                  );
                });
                setManageUserGridList(filteredUser);
              }
            }
            setLoading(false);
          } else {
            setManageUserGridList([]);
            setLoading(false);
          }
        } else {
          setManageUserGridList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetCompanyRoleMasterDetails = () => {
    ManageUserService.GetCompanyRoleMasterDetails()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setCompanyRoleMasterDetailsList(responseData);
          } else {
            setCompanyRoleMasterDetailsList([]);
          }
        } else {
          setCompanyRoleMasterDetailsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetCompanyManagerUserDetailsByUserId = (id) => {
    let inputData = {
      userId: id.toString(),
    };
    setUserDeatilsLoading(true);
    ManageUserService.GetCompanyManagerUserDetailsByUserId(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData[0];
        if (message === "Success") {
          if (responseData) {
            setAddUserData({
              lastLogin: responseData.lastLogin,
              userSinus: responseData.userSinus,
              name: responseData.name,
              email: responseData.email,
              location:
                responseData.locationId == 0 ? "" : responseData.locationId,
              access: responseData.rolemasterid,
              showAccess: responseData.access,
              roleMasterID: responseData.rolemasterid,
              jobTitle: responseData.jobTitle,
              notesTxtArea: responseData.note,
              userName: responseData.userName,
              password: "",
              confirmPass: "",
              generatePassword: "",
              oldEmailId: responseData.email,
              userAvtar: responseData.userAvtar,
              userId: responseData.userId,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setUserDeatilsLoading(false);
      })
      .finally(() => {
        setUserDeatilsLoading(false);
      });
  };
  const handleInsertNewUserDetail = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "userName",
        addUserData.userName ? addUserData.userName : ""
      );
      formData.append("email", addUserData.email ? addUserData.email : "");
      formData.append(
        "password",
        addUserData.password ? addUserData.password : ""
      );
      formData.append(
        "masterID",
        addUserData.access ? Number(addUserData.access) : 0
      );
      formData.append(
        "note",
        addUserData.notesTxtArea ? addUserData.notesTxtArea : ""
      );
      formData.append("name", addUserData.name ? addUserData.name : "");
      formData.append(
        "userAvatar",
        addUserData.file ? addUserData.file.name : ""
      );
      formData.append("user_Avatar", addUserData.file);
      formData.append(
        "location",
        addUserData.location === "" ? 0 : parseInt(addUserData.location)
      );
      formData.append(
        "jobTitle",
        addUserData.jobTitle ? addUserData.jobTitle : ""
      );
      formData.append("dealerID", dealerId);
      formData.append("userId", "");
      await ManageUserService.InsertNewUserDetail(formData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "True") {
            setVisible(false);
            NotificationManager.success("User Added Successfully");
            handleGetCompanyManagerUserDetails(dealerId);
            setUseVisible(false);
          } else {
            NotificationManager.error(responseData.errorMsg);
            setUseVisible(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("User Insert Failed");
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateUserDetails_New = async () => {
    try {
      const formData = new FormData();
      formData.append("DealerID", dealerId.toString());
      formData.append("userID", addUserData.userId);
      formData.append(
        "LocationID",
        addUserData.location == undefined || addUserData.location == ""
          ? "0"
          : addUserData.location.toString()
      );
      formData.append(
        "JobTitle",
        addUserData.jobTitle ? addUserData.jobTitle : ""
      );

      formData.append(
        "UserAvatar",
        !addUserData.file ? "" : addUserData.file.name
      );
      formData.append("user_Avatar", !addUserData.file ? "" : addUserData.file);
      formData.append("AccessLevelID", addUserData.access.toString());
      formData.append(
        "note",
        addUserData.notesTxtArea ? addUserData.notesTxtArea : ""
      );

      formData.append(
        "UserAvatarLink",
        addUserData.userAvtar !== ""
          ? addUserData.userAvtar.replace(
              `${config.BlobURL}gemfind2staging/administration/UserAvtar/`,
              ""
            )
          : ""
      );
      formData.append("Name", addUserData.name);
      formData.append("Email", addUserData.email);

      setSaveLoading(true);
      await ManageUserService.UpdateUserDetails_New(formData)
        .then((response) => {
          var message = response.data.message;
          if (message == "YES") {
            NotificationManager.success("User Edited Successfully");
            handleGetCompanyManagerUserDetails(dealerId);
            setResetPasswordVisible(false);
            setTableVisible(false);
            setSaveLoading(false);
          } else {
            NotificationManager.error("User Edit Failed");
            setSaveLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("User Edit Failed");
          setSaveLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSaveLoading(false);
    }
  };
  const handleDeleteManageUserDetail = async () => {
    try {
      let inputData = {
        dealerID: dealerId,
        userName: addUserData.userName,
        userId: addUserData.userId,
      };
      await ManageUserService.DeleteManageUserDetail(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message == "True") {
            setTableVisible(false);
            handleGetCompanyManagerUserDetails(dealerId);
            NotificationManager.success("User Deleted Successfully");
          } else {
            NotificationManager.error("User Delete Failed");
          }
        })
        .catch((error) => {
          NotificationManager.error("User Delete Failed");
        });
    } catch (error) {
      console.log(error);
    }
    setDelModalVisible(false);
  };

  const handleCheckDealerEmail = (value) => {
    let inputData = {
      email: value,
    };

    AdminToolsDataService.CheckDealerEmail(inputData)
      .then((response) => {
        var checkMessage = response.data.responseData.isEmailExist;
        setValidations((prevState) => ({
          ...prevState,
          emailVal: checkMessage ? "Email is Taken" : "",
        }));
        setIsEmail(checkMessage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckDealerUser = async (value) => {
    if (!addUserData.userName) {
      return false;
    }
    let inputData = {
      userName: value,
    };

    var isValid = true;
    AdminToolsDataService.CheckDealerUser(inputData)
      .then((response) => {
        var checkMessage = response.data.responseData.isUserNameExist;
        isValid = checkMessage;
        setValidations((prevState) => ({
          ...prevState,
          userNameVal: checkMessage ? "Username is Taken" : "",
        }));

        setIsUserName(checkMessage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*API binding Ends*/
  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="magento__mapping">
          <div className="row">
            <div className="col-md-12">
              <div className="heading__block mb-3">
                <h4 class="workarea__heading mt-0 mb-0">
                  {" "}
                  {traslate("User Role Detail")}{" "}
                </h4>
                {accessLevelType == "Super" && (
                  <Button
                    disabled={
                      accessLevelType === "Super" || accessLevelType === ""
                        ? false
                        : true
                    }
                    className={
                      accessLevelType === "Super" || accessLevelType === ""
                        ? "primary-btn d-flex align-items-center"
                        : "disabled-btn primary-btn d-flex align-items-center"
                    }
                    onClick={handleModalOpen}
                  >
                    {" "}
                    <img src={Create_icon} alt="" />
                    <span> {traslate("New User")} </span>
                  </Button>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="shipping_tbldiv">
                <div className="table__scroll">
                  <Table
                    columns={columns}
                    dataSource={manageUserGridList}
                    loading={loading}
                    onChange={(pagination, filters, sorters) => {
                      document
                        .getElementsByClassName("content__area")[0]
                        .scroll(0, 0);
                    }}
                    pagination={{
                      showSizeChanger: true,
                      responsive: true,
                    }}
                    scroll={{ y: 800 }}
                  />
                </div>

                {/* Add user modal starts */}

                <div className="col-lg-12">
                  <Modal
                    title={traslate("Add New User")}
                    className="modal__manageUser"
                    centered
                    visible={visible}
                    width={600}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleInsertSubmit}
                      >
                        {traslate("Save")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div class="col-lg-12">
                                <div className="col-md-6">
                                  <div className="divimage_block w-100">
                                    {addUserData.showsImagePath ||
                                    addUserData.file ? (
                                      <div className="img__showdiv manage-user-img-sp">
                                        {addUserData.file ? (
                                          <img
                                            src={URL.createObjectURL(
                                              addUserData.file
                                            )}
                                          />
                                        ) : null}
                                        <span onClick={handleCancelImage}>
                                          x
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <label
                                          htmlFor=""
                                          className="user__avtar"
                                        >
                                          {traslate("User Avatar")}
                                        </label>
                                        <label
                                          htmlFor="file-upload"
                                          class="primary-btn choose_file_upload"
                                        >
                                          {traslate("Choose Image")}
                                        </label>
                                        <input
                                          id="file-upload"
                                          type="file"
                                          onChange={(e) => fileUpload(e)}
                                        />
                                        <span>
                                          {" "}
                                          {traslate("No File Chosen")}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("Name")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter name"
                                    name="name"
                                    value={addUserData.name}
                                    onChange={handleOnChange}
                                    className={
                                      validations.nameVal && "border__red"
                                    }
                                  />
                                  <div>
                                    {validations.nameVal && (
                                      <p className="error-color-red">
                                        {validations.nameVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("Email")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Email"
                                    name="email"
                                    value={addUserData.email}
                                    onChange={handleOnChange}
                                    className={
                                      validations.emailVal && "border__red"
                                    }
                                    onBlur={() =>
                                      handleCheckDealerEmail(addUserData.email)
                                    }
                                  />
                                  <div>
                                    {validations.emailVal && (
                                      <p className="error-color-red">
                                        {validations.emailVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label> {traslate("Location")} </label>
                                  <Select
                                    value={
                                      addUserData.location
                                        ? addUserData.location
                                        : null
                                    }
                                    name="location"
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleOnChange(e, "select", "location")
                                    }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                      const childrenText =
                                        option.children
                                          ?.toString()
                                          .toLowerCase() || "";
                                      const inputText = input.toLowerCase();

                                      return childrenText.includes(inputText);
                                    }}
                                    className="border__grey"
                                  >
                                    <Option value="">
                                      {traslate
                                        ? traslate("Select Location")
                                        : "Select Location"}
                                    </Option>
                                    {locationList &&
                                      locationList.map((item, i) => (
                                        <Option key={i} value={item.locationId}>
                                          {item.locationName}{" "}
                                        </Option>
                                      ))}
                                  </Select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label> {traslate("Access Level")} </label>
                                  <Select
                                    value={
                                      addUserData.access
                                        ? addUserData.access
                                        : null
                                    }
                                    name="access"
                                    placeholder="Select Access Level"
                                    onChange={(e) =>
                                      handleOnChange(e, "select", "access")
                                    }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    className="border__grey"
                                  >
                                    {companyRoleMasterDetailsList &&
                                      companyRoleMasterDetailsList.map(
                                        (item) => {
                                          return (
                                            <Option
                                              value={item.roleMasterID}
                                              key={item.roleMasterID}
                                            >
                                              {item.roleName}
                                            </Option>
                                          );
                                        }
                                      )}
                                  </Select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label> {traslate("Job Title")} </label>
                                  <input
                                    type="text"
                                    placeholder="Job Title"
                                    name="jobTitle"
                                    value={addUserData.jobTitle}
                                    onChange={handleOnChange}
                                    className="border__grey"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("User Name")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter user name"
                                    name="userName"
                                    value={addUserData.userName}
                                    onChange={handleOnChange}
                                    className={
                                      validations.userNameVal && "border__red"
                                    }
                                    onBlur={() =>
                                      handleCheckDealerUser(
                                        addUserData.userName
                                      )
                                    }
                                  />
                                  <div>
                                    {validations.userNameVal && (
                                      <p className="error-color-red">
                                        {validations.userNameVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="input__block">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Password")}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          autoComplete="new-password"
                                          placeholder="Enter Password"
                                          name="password"
                                          value={addUserData.password}
                                          onChange={handleOnChange}
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          className={
                                            validations.passwordVal &&
                                            "border__red"
                                          }
                                        />
                                        <span
                                          class="p-viewer"
                                          onClick={handlePassShowHide}
                                        >
                                          <i
                                            class={
                                              showPassword
                                                ? "fa fa-eye"
                                                : "fa fa-eye-slash"
                                            }
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <div>
                                          {validations.passwordVal && (
                                            <p className="error-color-red">
                                              {validations.passwordVal}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Confirm Password")}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type={
                                            showConfirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          placeholder="Confirm Password"
                                          name="confirmPass"
                                          value={addUserData.confirmPass}
                                          onChange={handleOnChange}
                                          className={
                                            validations.confirmPassVal &&
                                            "border__red"
                                          }
                                        />
                                        <span
                                          class="p-viewer"
                                          onClick={handlePassConfirmShowHide}
                                        >
                                          <i
                                            class={
                                              showConfirmPassword
                                                ? "fa fa-eye"
                                                : "fa fa-eye-slash"
                                            }
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <div>
                                          {validations.confirmPassVal && (
                                            <p className="error-color-red">
                                              {validations.confirmPassVal}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div>
                                      {validations.passwordVal && (
                                        <p className="error-color-red">
                                          {validations.passwordVal}
                                        </p>
                                      )}
                                    </div> */}
                                    <div className="col-md-12">
                                      <label> {traslate("OR")} </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        type="text"
                                        name="generatePassword"
                                        value={addUserData.generatePassword}
                                        onChange={handleOnChange}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <button
                                        className="primary-btn mobilebtn mr-2"
                                        onClick={generatePassword}
                                      >
                                        {traslate("Generate Password")}
                                      </button>
                                      {useVisible == true ? (
                                        <Button
                                          onClick={setgenpassword}
                                          className="primary-btn mobilebtn"
                                        >
                                          {traslate("Use")}
                                        </Button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="input__block">
                                  <label> {traslate("Notes")} </label>
                                  <textarea
                                    name="notesTxtArea"
                                    value={addUserData.notesTxtArea}
                                    onChange={handleOnChange}
                                    className="border__grey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                {/* Add user modal ends */}

                {/* Table modal starts */}

                <div className="col-lg-12">
                  <Modal
                    title={traslate("Edit User")}
                    className="modal__manageUser__table"
                    centered
                    visible={tableVisible}
                    maskClosable={false}
                    closable={true}
                    width={600}
                    onCancel={() => {
                      handleTableCancel();
                    }}
                    footer={[
                      <Button key="back" onClick={handleTableCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button
                        disabled={
                          accessLevelType === "Super" || accessLevelType === ""
                            ? addUserData.showAccess === "Super"
                              ? true
                              : false
                            : true
                        }
                        key="submit"
                        className={
                          accessLevelType === "Super" || accessLevelType === ""
                            ? "primary-btn"
                            : "display-btn primary-btn"
                        }
                        onClick={() => {
                          handleDeleteUser();
                        }}
                      >
                        {traslate("Delete User")}
                      </Button>,
                      <Button
                        key="submit"
                        type="primary"
                        loading={saveLoading}
                        onClick={handleSaveSubmit}
                      >
                        {traslate("Save Changes")}
                      </Button>,
                    ]}
                  >
                    <Spin spinning={getUserDetailsLoading} size="large">
                      <div className="col-lg-12">
                        <div
                          className="m
                        odal__body"
                        >
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div class="col-lg-12">
                                  <div className="col-md-6 ">
                                    <h5 className="mb-3">
                                      {addUserData.showAccess}
                                    </h5>

                                    <p className="mb-0">
                                      <strong>
                                        {" "}
                                        {traslate("Last Login")} :{" "}
                                      </strong>
                                      {addUserData.lastLogin
                                        ? addUserData.lastLogin
                                        : "User has not logged in yet"}
                                    </p>

                                    <p>
                                      <strong>
                                        {" "}
                                        {traslate("User Since")} :{" "}
                                      </strong>
                                      {addUserData.userSinus}
                                    </p>
                                  </div>
                                </div>

                                <div class="col-lg-12">
                                  <div className="col-md-6">
                                    <div className="divimage_block w-100">
                                      {addUserData.userAvtar !== "" ||
                                      addUserData.file ? (
                                        <div className="img__showdiv manage-user-img-sp">
                                          {addUserData.file ? (
                                            <img
                                              src={URL.createObjectURL(
                                                addUserData.file
                                              )}
                                            />
                                          ) : (
                                            <img
                                              src={addUserData.userAvtar}
                                              alt=""
                                            />
                                          )}
                                          <span onClick={handleCancelImage}>
                                            x
                                          </span>
                                        </div>
                                      ) : (
                                        <div>
                                          <label
                                            htmlFor=""
                                            className="user__avtar"
                                          >
                                            {traslate("User Avatar")}
                                          </label>
                                          <label
                                            htmlFor="file-upload"
                                            class="primary-btn choose_file_upload"
                                          >
                                            {traslate("Choose Image")}
                                          </label>
                                          <input
                                            id="file-upload"
                                            type="file"
                                            onChange={(e) =>
                                              EditUserFileUpload(e)
                                            }
                                          />
                                          <span>
                                            {" "}
                                            {traslate("No File Chosen")}{" "}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="input__block">
                                    <label> {traslate("Name")}</label>
                                    <input
                                      type="text"
                                      placeholder="Enter name"
                                      name="name"
                                      value={addUserData.name}
                                      onChange={handleOnChange}
                                      className={
                                        validations.nameVal && "border__red"
                                      }
                                    />
                                    <div>
                                      {validations.nameVal && (
                                        <p className="error-color-red">
                                          {validations.nameVal}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input__block">
                                    <label> {traslate("Email")}</label>
                                    <input
                                      type="text"
                                      placeholder="Enter Email"
                                      name="email"
                                      value={addUserData.email}
                                      onChange={handleOnChange}
                                      className={
                                        validations.emailVal && "border__red"
                                      }
                                      onBlur={() =>
                                        addUserData.oldEmailId !==
                                        addUserData.email
                                          ? handleCheckDealerEmail(
                                              addUserData.email
                                            )
                                          : null
                                      }
                                    />
                                    <div>
                                      {validations.emailVal && (
                                        <p className="error-color-red">
                                          {validations.emailVal}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input__block">
                                    <label> {traslate("Location")} </label>
                                    <Select
                                      value={
                                        addUserData.location
                                          ? addUserData.location
                                          : null
                                      }
                                      name="location"
                                      placeholder="Select"
                                      onChange={(e) =>
                                        handleOnChange(e, "select", "location")
                                      }
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) => {
                                        const childrenText =
                                          option.children
                                            ?.toString()
                                            .toLowerCase() || "";
                                        const inputText = input.toLowerCase();

                                        return childrenText.includes(inputText);
                                      }}
                                      className="border__grey"
                                    >
                                      <Option value="">
                                        {traslate
                                          ? traslate("Select Location")
                                          : "Select Location"}
                                      </Option>
                                      {locationList &&
                                        locationList.map((item, i) => (
                                          <Option
                                            key={i}
                                            value={item.locationId}
                                          >
                                            {item.locationName}{" "}
                                          </Option>
                                        ))}
                                    </Select>
                                  </div>
                                </div>
                                {accessLevelType == "Super" && (
                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Access Level")}{" "}
                                      </label>
                                      <Select
                                        value={
                                          addUserData.access
                                            ? addUserData.access
                                            : null
                                        }
                                        name="access"
                                        placeholder="Admin"
                                        onChange={(e) =>
                                          handleOnChange(e, "select", "access")
                                        }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        className="border__grey"
                                      >
                                        {companyRoleMasterDetailsList &&
                                          companyRoleMasterDetailsList.map(
                                            (item) => {
                                              return (
                                                <Option
                                                  value={item.roleMasterID.toString()}
                                                  key={item.roleMasterID}
                                                >
                                                  {item.roleName}
                                                </Option>
                                              );
                                            }
                                          )}
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-6">
                                  <div className="input__block">
                                    <label> {traslate("Job Title")} </label>
                                    <input
                                      type="text"
                                      placeholder="Job Title"
                                      name="jobTitle"
                                      value={addUserData.jobTitle}
                                      onChange={handleOnChange}
                                      className="border__grey"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {traslate("Notes")} </label>
                                    <textarea
                                      name="notesTxtArea"
                                      value={addUserData.notesTxtArea}
                                      onChange={handleOnChange}
                                      className="border__grey"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <button
                                          disabled={
                                            accessLevelType === "Super" ||
                                            accessLevelType === "" ||
                                            booleanValue == true
                                              ? false
                                              : true
                                          }
                                          className={
                                            accessLevelType === "Super" ||
                                            accessLevelType === "" ||
                                            booleanValue == true
                                              ? "primary-btn mobilebtn"
                                              : "disabled-btn primary-btn mobilebtn"
                                          }
                                          onClick={handleResetPassModalOpen}
                                        >
                                          {traslate("Reset Password")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Spin>
                  </Modal>
                </div>

                {/* Table modal ends */}

                <div className="col-lg-12">
                  <Modal
                    title={traslate("Reset Password")}
                    className="modal__resetPassword"
                    centered
                    visible={resetPasswordVisible}
                    maskClosable={false}
                    width={300}
                    onCancel={handleResetPassCancel}
                    footer={[
                      <Button key="back" onClick={handleResetPassCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleResetPassSubmit}
                      >
                        {traslate("Save")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="input__block">
                                  <label> {traslate("User Name")}</label>
                                  <input
                                    type="text"
                                    placeholder="Enter user name"
                                    name="userName"
                                    value={addUserData.userName}
                                    onChange={handleOnChange}
                                    disabled="true"
                                    className={
                                      validations.userNameVal && "border__red"
                                    }
                                  />
                                </div>
                              </div>

                              {booleanValue == true && (
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label>
                                      {traslate("Current Password")}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      placeholder="Enter Current Password"
                                      name="currentPassword"
                                      value={addUserData.currentPassword}
                                      onChange={handleOnChange}
                                      type={
                                        showCurrentPassword
                                          ? "text"
                                          : "password"
                                      }
                                    />
                                    <span
                                      class="p-viewer"
                                      onClick={handleCurrentPassShowHide}
                                    >
                                      <i
                                        class={
                                          showCurrentPassword
                                            ? "fa fa-eye"
                                            : "fa fa-eye-slash"
                                        }
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-12">
                                <div className="input__block">
                                  <label>
                                    {traslate("New Password")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    placeholder="Enter New Password"
                                    name="password"
                                    value={addUserData.password}
                                    onChange={handleOnChange}
                                    type={showPassword ? "text" : "password"}
                                    className={
                                      validations.passwordVal && "border__red"
                                    }
                                  />
                                  <span
                                    class="p-viewer"
                                    onClick={handlePassShowHide}
                                  >
                                    <i
                                      class={
                                        showPassword
                                          ? "fa fa-eye"
                                          : "fa fa-eye-slash"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <div>
                                    {validations.passwordVal && (
                                      <p className="error-color-red">
                                        {validations.passwordVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="input__block">
                                  <label>
                                    {traslate("Confirm New Password")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    placeholder="Confirm New Password"
                                    name="confirmPass"
                                    value={addUserData.confirmPass}
                                    onChange={handleOnChange}
                                    className={
                                      validations.confirmPassVal &&
                                      "border__red"
                                    }
                                  />
                                  <span
                                    class="p-viewer"
                                    onClick={handlePassConfirmShowHide}
                                  >
                                    <i
                                      class={
                                        showConfirmPassword
                                          ? "fa fa-eye"
                                          : "fa fa-eye-slash"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <div>
                                    {validations.confirmPassVal && (
                                      <p className="error-color-red">
                                        {validations.confirmPassVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>

                  <Modal
                    title={traslate("Delete")}
                    className="modalconsupld__section"
                    centered
                    visible={delModalVisible}
                    onCancel={handleDeleteCancel}
                    width={400}
                    footer={[
                      <Button key="back" onClick={handleDeleteCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleDeleteManageUserDetail}
                      >
                        {traslate("Delete")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-lg-12 text-center">
                                <p>
                                  {traslate("Are you sure you want to delete")}{" "}
                                  "{addUserData.name}" {traslate("User")}
                                </p>
                                <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                {/* Reset password modal starts */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageUser;
