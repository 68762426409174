import { httpAdminReport } from "../helper/http-common";
class AdminDiamondReportService {
    GetRetailerDropdownList(inputData) {
        return httpAdminReport.post("/AdminDimondreports/Admin_ExtendedDiamondChartRetailerDropDownList", inputData)
    }
    GetVendorDropdownList(inputData) {
        return httpAdminReport.post("/AdminDimondreports/Admin_ExtendedDiamondChartVendorDropDownList", inputData)
    }    
    CheckDealermembers(inputData) {
        return httpAdminReport.post("/Admin_JewelryReport/CheckDealermembers", inputData);
    }
    DiamondChartForCutGrade(inputData) {
        return httpAdminReport.post("/AdminDimondreports/DiamondChartForCutGrade", inputData);
    }
    LoadChartReports(inputData) {
        return httpAdminReport.post("/AdminDimondreports/LoadChartReports", inputData);
    }
    GetTopDiamondAttributeTableList(inputData){
        return httpAdminReport.post("/AdminDimondreports/GetDiamondReportForTableChart", inputData);
    }
    GetRecentDiamondAttributeTableList(inputData){
        return httpAdminReport.post("/AdminDimondreports/GetDiamondReportForTableChartAll", inputData);
    }
    GetAllChartForTableList(inputData){
        return httpAdminReport.post("/AdminDimondreports/GetDiamondReportForTableChartAll20R", inputData);
    }
}

export default new AdminDiamondReportService();
