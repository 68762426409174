import React from "react";
import Allproducts from "../All Products/Allproducts";

const SharedProduct = () => {
  return (
    <React.Fragment>
      <Allproducts type="MergeProduct" />
    </React.Fragment>
  );
};

export default SharedProduct;
