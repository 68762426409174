import React, { useState, useEffect, Fragment } from "react";
import { Table, Spin, Modal, Button } from "antd";
import traslate from "../../../i18n/translate";
import { useSelector, useDispatch } from "react-redux";
import appsDiamondlinksService from "../../../services/apps-diamondlinks.service";
import AppsStudBuilderService from "../../../services/apps-studbuilder.service";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import {
  setChecklinkHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../../actions/headerMenu/headerMenu";

export const StudBuilderDealers = (props) => {
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [saveLoadingProfileData, setSaveLoadingProfileData] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [dealerId, setDealerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [commVendorData, setCommVendorData] = useState([]);
  const [diamondRapNetPermissionData, setDiamondRapNetPermission] = useState(
    []
  );

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [checkBool, setCheckBool] = useState(null);
  const [mainCheckVal, setMainCheckVal] = useState(false);
  const [diamondCount, setDiamondCount] = useState({});
  const [disabledbtn, setDisabledbtn] = useState(true);
  const [boolCount, setBoolCount] = useState(0);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCheckboxData, setSelectedCheckboxData] = useState(null);
  const [confirmPopUpForAll, setConfirmPopUpForAll] = useState(false);
  const [selectedAllCheckboxData, setSelectedAllCheckboxData] = useState(null);

  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleStudBindDealerList(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleStudBindDealerList = (id, pageNo, pageSize) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        moduleName: "StudBuilder",
      };
      setLoading(true);
      AppsStudBuilderService.StudBindDealerList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.dealerlist;
          let dimaondcount = response.data.responseData.dimaondcount;
          if (message === "Success") {
            if (checkBool !== null) {
              if (checkBool == true) {
                var tempcommVendorData = responseData;
                tempcommVendorData.forEach((element) => {
                  element.selectedDealerID = 1;
                });
                setCommVendorData(tempcommVendorData);
                setDiamondCount(dimaondcount);
              } else if (checkBool == false && boolCount == 1) {
                setCommVendorData(responseData);
                setDiamondCount(dimaondcount);
              } else {
                var tempcommVendorData = responseData;
                tempcommVendorData.forEach((element) => {
                  element.selectedDealerID = 0;
                });
                setCommVendorData(tempcommVendorData);
                setDiamondCount(dimaondcount);
              }
            } else {
              setCommVendorData(responseData);
              const temp = responseData;
              const testBoolean = temp.every((m) => {
                if (m.selectedDealerID == 0) {
                  return false;
                }
                return true;
              });
              setMainCheckVal(testBoolean);
              setDiamondCount(dimaondcount);
            }

            setLoading(false);
            setBoolCount(1);
          } else {
            setCommVendorData([]);

            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (row, e, name) => {
    setDisabledbtn(false);
    if (name === "selectedDealerID") {
      const temp = commVendorData.map((x) => {
        if (x.dealerID == row.dealerID) {
          return {
            ...x,
            selectedDealerID: !e.target.checked ? 1 : 0, // Remove ! from here if you change onchange from table checkbox
          };
        } else return x;
      });

      setCommVendorData(temp);
      setDiamondRapNetPermission(temp);

      const testBoolean = temp.every((m) => {
        if (m.selectedDealerID == 0) {
          return false;
        }
        return true;
      });
      setMainCheckVal(testBoolean);
      setCheckBool(testBoolean);
    }
  };

  const handleOnCheckGridAll = (e) => {
    setDisabledbtn(false);
    setCheckBool(e);
    setMainCheckVal(e);
    let tempcommVendorData = commVendorData;
    tempcommVendorData.forEach((element) => {
      element.selectedDealerID = e ? 1 : 0;
    });
    setCommVendorData([...tempcommVendorData]);
    setDiamondRapNetPermission([...tempcommVendorData]);
  };

  const handleShowDataToRightNew = (record) => {
    try {
      let inputData = {
        vendorID: record.dealerID.toString(),
        f_Type: "",
        dealerID: dealerId.toString(),
      };
      setSelectedColumnId(record.dealerID);
      setLoadingProfileData(true);
      appsDiamondlinksService
        .ShowDataToRightNew(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setRowDetails(responseData);
              setLoadingProfileData(false);
            } else {
              setRowDetails({});
              setLoadingProfileData(false);
            }
          } else {
            setRowDetails({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  const handleSaveSelect = () => {
    try {
      let selectedList = diamondRapNetPermissionData
        .filter(function (item) {
          return item.selectedDealerID == 1;
        })
        .map(function (item) {
          return item.dealerID;
        });
      let inputData = {
        dealerID: dealerId.toString(),
        listDealerID: selectedList,
        moduleName: "StudBuilder",
      };
      setSaveLoadingProfileData(true);
      AppsStudBuilderService.StudUpdateList_Click(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Updated Successfully.");
            handleStudBindDealerList(dealerId, pageNo, pageSize);
            setSaveLoadingProfileData(false);
            setDisabledbtn(true);
          } else {
            NotificationManager.error("Not Updated.");
            setSaveLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSaveLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setSaveLoadingProfileData(false);
    }
  };

  const handleOnVendorNameClick = () => {
    navigate("/applicationPreviewDiamondLink");
  };

  const columns = [
    {
      title: traslate("Vendor Name"),
      dataIndex: "dealerCompany",
      showSorterTooltip: false,
      sorter: (a, b) => a.dealerCompany.localeCompare(b.dealerCompany),
      render: (item, row) => {
        return (
          <span
            // onClick={() => handleOnVendorNameClick()}
            className="linkText"
          >
            {row.dealerCompany}
          </span>
        );
      },
    },
    {
      title: traslate("Mined Diamonds"),
      dataIndex: "mindCount",
      showSorterTooltip: false,
      sorter: (a, b) => a.mindCount - b.mindCount,
    },
    {
      title: traslate("Lab Grown Diamonds"),
      dataIndex: "labTotalCount",
      showSorterTooltip: false,
      sorter: (a, b) => a.labTotalCount - b.labTotalCount,
    },
    {
      title: traslate("Color Diamonds"),
      dataIndex: "color",
      showSorterTooltip: false,
      sorter: (a, b) => a.color - b.color,
    },
    {
      title: traslate("Updated"),
      dataIndex: "modified",
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.modified) - new Date(b.modified),
    },
    {
      title: (
        <div className="filtbtn__div filter__eye">
          <label className="table__label">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </label>
          <span className="select__all">
            <input
              type="checkbox"
              id="sel"
              onChange={(e) => {
                setSelectedAllCheckboxData(e.target.checked);
                setConfirmPopUpForAll(true);
              }}
              checked={mainCheckVal}
            />
          </span>
        </div>
      ),
      dataIndex: "selectedDealerID",
      width: 30,
      render: (item, row) => {
        return (
          <div className="action__btns">
            <input
              type="checkbox"
              name="selectedDealerID"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.selectedDealerID == 1 ? true : false}
              onChange={(e) => {
                setIsChecked(row.selectedDealerID == 1);
                handleOpenConfirmation(row, e, "selectedDealerID");
              }}
            />
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleHeaderMenuClickForSideProfileApp = (type) => {
    if (type == "Profile") {
      navigate("/dealerProfile", {
        state: {
          key: "Profile",
          id: selectedColumnId,
          pageType: "StudBuilder",
        },
      });
    } else if (type == "Conversation") {
      navigate("/dealerProfile", {
        state: {
          key: "Conversation",
          id: selectedColumnId,
          pageType: "StudBuilder",
        },
      });
    } else if (type == "Inventory") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["viewMyMinedDiamond"]));
      navigate("/diamondSearch", {
        state: {
          id: selectedColumnId,
        },
      });
    }
  };

  //
  const openInNewTab = (url) => {
    window.open(url);
  };

  // Confirmation Popup Start
  const handleOpenConfirmation = (row, e, name) => {
    setConfirmPopUp(true);
    setSelectedCheckboxData({
      row,
      e,
      name,
    });
  };

  const handleCancelConfirmation = () => {
    setConfirmPopUp(false);
  };

  const handleConfirmConfirmation = () => {
    if (selectedCheckboxData) {
      handleOnChange(
        selectedCheckboxData.row,
        selectedCheckboxData.e,
        selectedCheckboxData.name
      );
    }
    setConfirmPopUp(false);
  };

  const handleCancelConfirmationForAll = () => {
    setConfirmPopUpForAll(false);
  };

  const handleConfirmConfirmationForAll = () => {
    handleOnCheckGridAll(selectedAllCheckboxData);
    setConfirmPopUpForAll(false);
  };

  // Confirmation Popup End

  return (
    <div className="form__fields">
      <div className="row magento__mapping permission__section">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-0">
              {traslate("Diamond Vendors")}
            </h4>
          </div>
        </div>
        <div className="col-lg-9 divForm vendors-section">
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={commVendorData}
              scroll={{ x: 600, y: 800 }}
              rowKey={(record) => record.dealerID}
              onChange={(pagination, filters, sorters) => {
                document
                  .getElementsByClassName("content__area")[0]
                  .scroll(0, 0);
              }}
              pagination={{
                showSizeChanger: true,
                responsive: true,
              }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  handleShowDataToRightNew(record);
                },
              })}
            />
          </Spin>
        </div>

        <div className="col-lg-3 col-md-3">
          <Spin spinning={loadingprofileData}>
            <div className="right__section">
              {selectedColumnId !== 0 ? (
                <Fragment>
                  <div className="profile__block">
                    <span
                      onClick={() =>
                        handleHeaderMenuClickForSideProfileApp("Profile")
                      }
                      className="linkText"
                    >
                      {rowDetails.designerName ? rowDetails.designerName : null}
                    </span>
                    <div className="logoImg__block p-0">
                      <img src={rowDetails.src ? rowDetails.src : null} />
                    </div>
                  </div>

                  <div className="retailer__dtls mb-4">
                    <p>
                      {" "}
                      {rowDetails.dealerName
                        ? rowDetails.dealerName
                        : null}{" "}
                    </p>
                    <a href={`mailto:${rowDetails.dealerEmail}`}>
                      {" "}
                      {rowDetails.dealerEmail
                        ? rowDetails.dealerEmail
                        : null}{" "}
                    </a>
                    <a
                      onClick={() =>
                        openInNewTab(
                          rowDetails.website ? rowDetails.website : null
                        )
                      }
                    >
                      {rowDetails.website ? rowDetails.website : null}
                    </a>
                    <p>
                      {" "}
                      {rowDetails.dealerPhone
                        ? rowDetails.dealerPhone
                        : null}{" "}
                    </p>

                    <hr />
                  </div>
                  <div className="links__block">
                    <ul>
                      <li>
                        <span
                          onClick={() =>
                            handleHeaderMenuClickForSideProfileApp("Profile")
                          }
                          className="linkText"
                        >
                          <i
                            class="fa fa-address-book-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {traslate("View Profile")}
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            handleHeaderMenuClickForSideProfileApp(
                              "Conversation"
                            )
                          }
                          className="linkText"
                        >
                          <i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
                          {traslate("Conversation")}
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            handleHeaderMenuClickForSideProfileApp("Inventory")
                          }
                          className="linkText"
                        >
                          <i class="fa fa-diamond" aria-hidden="true"></i>{" "}
                          {traslate("View Inventory")}
                        </span>
                      </li>
                    </ul>
                    <hr />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="retailer__dtls mb-4">
                    <p>{traslate("Total Mined Diamonds")}</p>
                    <span>{diamondCount.totalMindCount}</span>

                    <hr />

                    <p>{traslate("Total LabGrown Diamonds")}</p>
                    <span>{diamondCount.totalLabTotalCount}</span>

                    <hr />
                    <p>{traslate("Total Color Diamonds")}</p>
                    <span>{diamondCount.totalColor}</span>
                  </div>
                </Fragment>
              )}
              <div className="input__block">
                <button
                  disabled={disabledbtn}
                  className={
                    disabledbtn == true ? "disabled-btn" : "primary-btn"
                  }
                  onClick={handleSaveSelect}
                  loading={saveLoadingProfileData}
                >
                  {" "}
                  {traslate("Update List")}{" "}
                </button>
              </div>
            </div>
          </Spin>
        </div>
      </div>
      <Modal
        title={traslate("Please Confirm")}
        className="datamapping__modal"
        width={400}
        centered
        visible={confirmPopUp}
        onCancel={handleCancelConfirmation}
        maskClosable={false}
        footer={[
          <Button onClick={handleCancelConfirmation}>{traslate("No")}</Button>,

          <Button type="primary" onClick={handleConfirmConfirmation}>
            {traslate("Yes")}
          </Button>,
        ]}
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12 text-center">
            <p>
              {" "}
              {traslate(
                `Do you want to ${
                  !isChecked ? "connect" : "disconnect"
                } Vendor Diamond?`
              )}{" "}
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        title={traslate("Please Confirm")}
        className="datamapping__modal"
        width={400}
        centered
        visible={confirmPopUpForAll}
        onCancel={handleCancelConfirmationForAll}
        maskClosable={false}
        footer={[
          <Button onClick={handleCancelConfirmationForAll}>
            {traslate("No")}
          </Button>,
          <Button type="primary" onClick={handleConfirmConfirmationForAll}>
            {traslate("Yes")}
          </Button>,
        ]}
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12 text-center">
            <p>
              {" "}
              {traslate(
                `Do you want to ${
                  !mainCheckVal ? "connect" : "disconnect"
                } All Vendor Diamond?`
              )}{" "}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudBuilderDealers;
