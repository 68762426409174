import { FILTER_VALUE_SUBMIT, ALL_FILTER_VALUE_SUBMIT } from "./types";

export const setFilterValuesubmit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FILTER_VALUE_SUBMIT,
      payload: data,
    });
  } catch (err) {}
};

export const setALLfilterValuesubmit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_FILTER_VALUE_SUBMIT,
      payload: data,
    });
  } catch (err) {}
};
