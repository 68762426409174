import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import DiamondCutComp from "../../ChartsGroup/DiamondCutComp";
import DiamondShapeComp from "../../ChartsGroup/DiamondShapeComp";
import DiamondClarityComp from "../../ChartsGroup/DiamondClarityComp";
import DiamondCertificateComp from "../../ChartsGroup/DiamondCertificateComp";
import DiamondCutGradeComp from "../../ChartsGroup/DiamondCutGradeComp";
import DiamondColorSearchesComp from "../../ChartsGroup/DiamondColorSearchesComp";
import DiamondSizeSearchesComp from "../../ChartsGroup/DiamondSizeSearchesComp";
import DiamondClicksComp from "../../ChartsGroup/DiamondClicksComp";
import DiamondPriceComp from "../../ChartsGroup/DiamondPriceComp";
import traslate from "../../../i18n/translate";
import GlobalDiamondReportService from "../../../services/reports-globalDiamond-service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const GlobalExtendedDiamondChartReports = () => {
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  /*Initial State for Binding Ends*/

  /*React Binding Starts*/
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [forDealer, setForDealer] = useState("");
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
  }, []);

  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    //
    if (dates) {
      //
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };
  /*DatePicker Binding Ends*/

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    let inputData = {
      dealerID: id,
    };

    GlobalDiamondReportService.CheckDealermembers(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var forDealer = responseData.forDealer;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setForDealer(forDealer);
          } else {
            setForDealer("");
          }
          handleLoadChartReports(id, forDealer);
        } else {
          setForDealer("");
        }
      })
      .catch((error) => {
        setForDealer("");
      });
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      type: "",
      reportName: "ExtDiamondChrt",
    };
    setLoadChartReportsLoading(true);
    GlobalDiamondReportService.LoadChartReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var TotalDiamondClicks =
          responseData.TotalDiamondClicks[0].TotalClickCnt;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setTotalDiamondClicks(TotalDiamondClicks);
            setKey(!key);
          }
        } else {
          setTotalDiamondClicks([]);
        }
      })
      .catch((error) => {
        setLoadChartReportsLoading(false);
        setTotalDiamondClicks([]);
      })
      .finally(() => setLoadChartReportsLoading(false));
  };
  const handleGetVendorDropdownData = () => {
    GlobalDiamondReportService.GetVendors()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setVendorList(responseData.Table);
          }
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        setVendorList([]);
      });
  };
  const handleGetRetailerDropdownData = () => {
    GlobalDiamondReportService.GetRetailers()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setRetailerList(responseData.Table);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setRetailerList([]);
      });
  };

  /*Cascading VendorIds and RetailerIds Starts*/
  const changeRetailerListHandler = (data) => {
    data = data.toString();
    let inputData = {
      vendorsID: data,
    };
    GlobalDiamondReportService.GetRetailersOnVendorDropdownChange(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setRetailerList(responseData.retailers);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setRetailerList([]);
      });
  };
  const changeVendorListHandler = (data) => {
    data = data.toString();
    let inputData = {
      retailerID: data,
    };
    GlobalDiamondReportService.GetVendorsOnRetailerDropdownChange(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            if (responseData.vendors !== null) {
              setVendorList(responseData.vendors);
            }
          }
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setVendorList([]);
      });
  };
  /*Cascading VendorIds and RetailerIds Ends*/

  /*API Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    let vId = "";
    let rId = "";
    if (isSelect === "select" && name === "vendors") {
      vId = e.includes(0) ? [0] : e;
      setGlobalDiamond((prevState) => {
        const updatedState = { ...prevState, vendorIds: vId };
        if (updatedState.vendorIds.length === 0 && updatedState.retailerIds?.length ===0) {
          handleGetVendorDropdownData();
          handleGetRetailerDropdownData();
        }
        else{
          changeRetailerListHandler(vId);
        }

        return updatedState;
      });
    }

    else if (isSelect === "select" && name === "retailers") {
      rId = e.includes(0) ? [0] : e;
      setGlobalDiamond((prevState) => {
        const updatedState = { ...prevState, retailerIds: rId };
        if (updatedState.vendorIds?.length === 0  && updatedState.retailerIds.length === 0 ) {
          handleGetVendorDropdownData();
          handleGetRetailerDropdownData();
        }
        else{
          changeVendorListHandler(rId);
        }

        return updatedState;
      });
    }
    else {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        [name]: e,
      }));
    }
  };



  const handleApplyFilter = () => {
    handleCheckDealermembers(dealerId);
    setIsApplyFilter(!isApplyFilter);
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Extended Diamond Chart Reports")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Vendor(s)")} </label>
              <Select
                className="border__grey"
                showSearch
                mode="multiple"
                showArrow
                placeholder="Select one or more vendor"
                optionFilterProp="children"
                value={globalDiamonds.vendorIds}
                onChange={(e) => handleOnChange(e, "select", "vendors")}
              >
                {vendorList.length > 0 &&
                  vendorList.map((item, key) => {
                    return (
                      <Option key={item.dealerID} value={item.dealerID}>
                        {" "}
                        {item.dealercompany}{" "}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Retailer(s)")} </label>
              <Select
                showSearch
                showArrow
                className="border__grey"
                placeholder="Select one or more retailer "
                optionFilterProp="children"
                onChange={(e) => handleOnChange(e, "select", "retailers")}
                notFoundContent="No Data Found"
                mode="multiple"
                value={globalDiamonds.retailerIds}
              >
                {retailerList.length > 0 &&
                  retailerList.map((item, key) => {
                    return (
                      <React.Fragment>
                        <Option key={item.dealerID} value={item.dealerID}>
                          {" "}
                          {item.dealercompany}{" "}
                        </Option>
                      </React.Fragment>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                className="border__grey"
                placeholder="All"
                showSearch
                name="SelectedDiamondValue"
                optionFilterProp="children"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All">{traslate("All")}</Option>
                <Option value="Mined">{traslate("Mined Diamond")}</Option>
                <Option value="Lab">{traslate("Lab Grown Diamond")}</Option>
              </Select>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4">
            <div className="input__block">
              <label>
                <Spin spinning={LoadChartReportsLoading} size="small">
                  <b>
                    {" "}
                    {traslate("Total Clicks")} :{" "}
                    {TotalDiamondClicks ? TotalDiamondClicks : 0}
                  </b>
                </Spin>
              </label>
            </div>
          </div>

          {/* Diamond Cut Searches Chart Starts */}
          {forDealer && (
            <DiamondCutComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Cut Searches Chart Ends */}

          {/* Diamond Shape Searches Chart Starts */}
          {forDealer && (
            <DiamondShapeComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}
          {/* Diamond Shape Searches Chart Ends */}

          {forDealer && (
            <DiamondClarityComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Certificate Searches Chart Starts */}
          {forDealer && (
            <DiamondCertificateComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}
          {/* Diamond Certificate Searches Chart Ends */}

          {/* Diamond CutGrade Searches Chart Starts */}
          {forDealer && (
            <DiamondCutGradeComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond CutGrade Searches Chart Ends */}

          {/* Diamond Color Searches Chart Starts */}
          {forDealer && (
            <DiamondColorSearchesComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Color Searches Chart Ends */}

          {/* Diamond Size Searches Chart Starts */}
          {forDealer && (
            <DiamondSizeSearchesComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Size Searches Chart Ends */}

          {/* Diamond Clicks Chart Starts */}
          {forDealer && (
            <DiamondClicksComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Clicks Searches Chart Ends */}

          {/* Diamond Price Chart Starts */}
          {forDealer && (
            <DiamondPriceComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Price Searches Chart Ends */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalExtendedDiamondChartReports;
