import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Spin,
} from "antd";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut, Bar, Line, Scatter, Pie, Chart } from "react-chartjs-2";
import traslate from "../../i18n/translate";
import GlobalDiamondReportService from "../../services/reports-globalDiamond-service";
import { DatePicker, Space } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const DiamondCutComp = ({ globalDiamonds, isApplyFilter, mainForDealer }) => {
  /*React Binding Starts*/
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondCutSearchesList, setDiamondCutSearchesList] = useState([]);
  const [diamondCutSearchesLabels, setDiamondCutSearchesLabels] = useState([]);
  const [DiamondCutSearchesLoading, setDiamondCutSearchesLoading] =
    useState(false);
  const [IdealDataList, setIdealDataList] = useState([]);
  const [VeryGoodDataList, setVeryGoodDataList] = useState([]);
  const [ExcellentDataList, setExcellentDataList] = useState([]);
  const [GoodDataList, setGoodDataList] = useState([]);
  const [FairDataList, setFairDataList] = useState([]);
  const [IdealColorData, setIdealColorData] = useState([]);
  const [VeryGoodColorData, setVeryGoodColorData] = useState([]);
  const [ExcellentColorData, setExcellentColorData] = useState([]);
  const [GoodColorData, setGoodColorData] = useState([]);
  const [FairColorData, setFairColorData] = useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          // handleCheckDealermembers(loginDetials.loginDetials.responseData.dealerId);
          handleDiamondChartForCutGrade(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      GlobalDiamondReportService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            handleDiamondChartForCutGrade(id, forDealer);
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleDiamondChartForCutGrade = (dealerId, forDealer) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        // "fromDate": "2021-08-17",
        // "toDate": "2022-09-15",
        retailerIds: globalDiamonds.retailerIds.toString(),
        vendorIds: globalDiamonds.vendorIds.toString(),
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        type: "",
        reportName: "ExtDiamondChrt",
      };
      setDiamondCutSearchesLoading(true);

      GlobalDiamondReportService.DiamondChartForCutGrade(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table;
          setDiamondCutSearchesLoading(false);
          if (message === "Success") {
            if (responseData.length > 0) {
              setDiamondCutSearchesList(responseData);
              var carateRangeArr = responseData.map((item) => {
                return item.carateRange;
              });
              setDiamondCutSearchesLabels(carateRangeArr);
              var IdealDataList = responseData.map((item) => {
                return item.Ideal;
              });
              setIdealDataList(IdealDataList);
              var VeryGoodDataList = responseData.map((item) => {
                return item.VeryGood;
              });
              setVeryGoodDataList(VeryGoodDataList);
              var ExcellentDataList = responseData.map((item) => {
                return item.Excellent;
              });
              setExcellentDataList(ExcellentDataList);
              var GoodDataList = responseData.map((item) => {
                return item.Good;
              });
              setGoodDataList(GoodDataList);
              var FairDataList = responseData.map((item) => {
                return item.Fair;
              });
              setFairDataList(FairDataList);
              IdealColorChartDataHandler(IdealDataList);
              VeryGoodColorChartDataHandler(VeryGoodDataList);
              ExcellentChartDataHandler(ExcellentDataList);
              GoodChartDataHandler(GoodDataList);
              FairChartDataHandler(FairDataList);
              setKey(!key);
            } else {
              setDiamondCutSearchesList([]);
              setDiamondCutSearchesLabels([]);
              setIdealDataList([]);
              setVeryGoodDataList([]);
              setExcellentDataList([]);
              setGoodDataList([]);
              setFairDataList([]);
            }
          } else {
            setDiamondCutSearchesList([]);
            setDiamondCutSearchesLabels([]);
            setIdealDataList([]);
            setVeryGoodDataList([]);
            setExcellentDataList([]);
            setGoodDataList([]);
            setFairDataList([]);
          }
        })
        .catch((error) => {
          setDiamondCutSearchesList([]);
          setDiamondCutSearchesLoading(false);
          setIdealDataList([]);
          setVeryGoodDataList([]);
          setExcellentDataList([]);
          setGoodDataList([]);
          setFairDataList([]);
        });
    } catch (error) {
      setDiamondCutSearchesList([]);
      setDiamondCutSearchesLoading(false);
      setIdealDataList([]);
      setVeryGoodDataList([]);
      setExcellentDataList([]);
      setGoodDataList([]);
      setFairDataList([]);
    }
  };
  /*API Binding Ends*/

  const IdealColorChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setIdealColorData(color);
  };
  const VeryGoodColorChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(219, 68, 55)");
    }
    setVeryGoodColorData(color);
  };
  const ExcellentChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(244, 180, 0)");
    }
    setExcellentColorData(color);
  };
  const GoodChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(15, 157, 88)");
    }
    setGoodColorData(color);
  };
  const FairChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(171, 71, 188)");
    }
    setFairColorData(color);
  };

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {traslate("Diamond Cut Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={DiamondCutSearchesLoading} size="large">
            <Bar
              key={key}
              data={{
                labels: diamondCutSearchesLabels,
                datasets: [
                  {
                    label: "Ideal",
                    data: IdealDataList,
                    backgroundColor: IdealColorData,
                    borderColor: IdealColorData,
                    borderWidth: 1,
                  },
                  {
                    label: "Very Good",
                    data: VeryGoodDataList,
                    backgroundColor: VeryGoodColorData,
                    borderColor: VeryGoodColorData,
                    borderWidth: 1,
                  },
                  {
                    label: "Excellent",
                    data: ExcellentDataList,
                    backgroundColor: ExcellentColorData,
                    borderColor: ExcellentColorData,
                    borderWidth: 1,
                  },
                  {
                    label: "Good",
                    data: GoodDataList,
                    backgroundColor: GoodColorData,
                    borderColor: GoodColorData,
                    borderWidth: 1,
                  },
                  {
                    label: "Fair",
                    data: FairDataList,
                    backgroundColor: FairColorData,
                    borderColor: FairColorData,
                    borderWidth: 1,
                  },
                ],
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: true,
                      text: "Carat Size",
                      font: {
                        size: 16,
                      },
                    },
                  },
                  y: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: true,
                      text: "Percentage Of Searches",
                      font: {
                        size: 16,
                      },
                    },
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";
                        let formattedValue = context.formattedValue || "";

                        if (label) {
                          label =
                            label +
                            " " +
                            "(" +
                            parseFloat(formattedValue).toFixed() +
                            "%)";
                          // label = parseFloat(formattedValue).toFixed() + "%";
                        }
                        return label;
                      },
                    },
                  },
                },
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondCutComp;
