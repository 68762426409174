import { httpDealer } from "../helper/http-common";

class DealerProfileService {
  GetCompanyProfileDetails(inputdata) {
    return httpDealer.post("/Dealer/GetCompanyProfileDetails", inputdata);
  }
  UpdateCompanyProfileDetails(inputdata) {
    return httpDealer.post("/Dealer/UpdateCompanyProfileDetails", inputdata);
  }
  GetUserProfileDetails(inputdata) {
    return httpDealer.post("/Dealer/GetUserProfileDetails", inputdata);
  }
  GetDealersLocation(inputdata) {
    return httpDealer.post("/Dealer/GetDealersLocation", inputdata);
  }
  UpdateUserProfileDetails(inputdata) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Profile/UpdateUserProfileDetails", inputdata, {
      headers,
    });
  }
  uploadimagecompanylogo(inputdata) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Dealer/uploadimagecompanylogo", inputdata, {
      headers,
    });
  }
  UpdateStoreImage(inputdata) {
    return httpDealer.post("/Dealer/UpdateStoreImage", inputdata);
  }
  UpdateAdvertisingImage(inputdata) {
    return httpDealer.post("/Dealer/UpdateAdvertisingImage", inputdata);
  }
  DeleteImageFromAddSample(inputdata) {
    return httpDealer.post("/Dealer/DeleteImageFromAddSample", inputdata);
  }
  DeleteStoreImage(inputdata) {
    return httpDealer.post("/Dealer/DeleteStoreImage", inputdata);
  }
  UploadSliderimage(inputdata) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Dealer/UploadSliderimage", inputdata, {
      headers,
    });
  }
  UploadAdverticingSliderimage(inputdata) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Dealer/UploadAdverticingSliderimage", inputdata, {
      headers,
    });
  }

  //Email Setting Page
  TestSMTPSetting(inputData) {
    return httpDealer.post("/Dealer/TestSMTPSetting", inputData);
  }
}

export default new DealerProfileService();
