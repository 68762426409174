import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Spin,
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import MasterLinkService from "../../../services/apps-masterLink.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NotificationManager } from "react-notifications";

const MasterLinkLegacy = () => {
  // const [switchValue, setswitchValue] = useState(trushoppingcarte);
  const { Option } = Select;
  const { TextArea } = Input;

  // api start //

  const [initialData, setInitialData] = useState({
    appointments: true,
    wishlist: true,
    bridalregistry: true,
    shoppingcart: true,
    showstorehouse: true,
    websiteurl: "",
    googleanalyticid: "",
    showonlymsrp: true,
    masterlinktheme: "",
    showview: "",
    newmasterlinkuse: true,
    currency: "",
    loginurl: "",
    showwholeprice: true,
    showaddress: true,
    diamondsearch: true,
    diamondimageurl: "",
    vendordetailsopen: true,
    showadditionalpopup: true,
    shoppingcartpage: "",
    heartsonfire: "",
    showsettingname: true,
    showringbuilderwith: true,
    showdefaultringsi: true,
    shopdiamonds: "",
    showbridaleng: "",
    shopdesigners: "",
    shopjewelery: "",
    shopwatches: "",
  });
  const [dealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);
  const [masterlinkth, setMasterlinkth] = useState([]);

  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);

          handleGetMasterLagacyDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
        handleGetMasterLinkTheme();
      }
    }
  }, []);

  const handleGetMasterLinkTheme = () => {
    setLoading(true);
    try {
      MasterLinkService.BindThemeDropDown()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setMasterlinkth(responseData);
            }
          } else {
            setLoading(false);
            setMasterlinkth([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetMasterLagacyDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      MasterLinkService.GetLegacyMasterLinkPreferences(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var designerSEO11 = response.data.responseData.designerSEO1;
          //   var obj = response.data.responseData.legacyDesignerSEO2;

          if (message === "Success") {
            if (responseData) {
              var diamondContent1 = "";
              var bridalEngContent1 = "";
              var designerContent1 = "";
              var jewelryContent1 = "";
              var watchesContent1 = "";

              setInitialData((prevState) => ({
                ...prevState,
                appointments: responseData.legacyDesignerSEO2[0].isAppointment,
                wishlist: responseData.legacyDesignerSEO2[0].isWishlist,
                bridalregistry:
                  responseData.legacyDesignerSEO2[0].isBridalRegistry,
                shoppingcart: responseData.legacyDesignerSEO2[0].isShoppingCart,
                showstorehouse: responseData.legacyDesignerSEO2[0].isStoreHours,
                websiteurl:
                  responseData.legacyDesignerSEO2[0].designersWebSiteURL,
                googleanalyticid:
                  responseData.legacyDesignerSEO2[0].googleanalyticID,
                showonlymsrp: responseData.legacyDesignerSEO2[0].showMSRPprice,
                masterlinktheme: responseData.legacyDesignerSEO2[0].theme1,
                showview: responseData.legacyDesignerSEO2[0].showView,
                newmasterlinkuse:
                  responseData.legacyDesignerSEO2[0].masterlinkIframe,
                currency:
                  responseData.legacyDesignerSEO2[0].currency.toString(),
                loginurl: responseData.legacyDesignerSEO2[0].loginUrl,
                showwholeprice:
                  responseData.legacyDesignerSEO2[0].isWholeSalePrice,
                showaddress: responseData.legacyDesignerSEO2[0].isShowAddress,
                diamondsearch:
                  responseData.legacyDesignerSEO2[0].loadFromMasterlink,
                diamondimageurl:
                  responseData.legacyDesignerSEO2[0].diamondImageURL,
                vendordetailsopen:
                  responseData.legacyDesignerSEO2[0].displayVendorDetail,
                showadditionalpopup:
                  responseData.legacyDesignerSEO2[0].showAdditionalPopUp,
                shoppingcartpage:
                  responseData.legacyDesignerSEO2[0].showShoppingCartMessage,
                heartsonfire:
                  responseData.legacyDesignerSEO2[0].heartsOnFireLink,
                showsettingname:
                  responseData.legacyDesignerSEO2[0].isSettingName,
                showringbuilderwith:
                  responseData.legacyDesignerSEO2[0].isOneStep,
                showdefaultringsi:
                  responseData.legacyDesignerSEO2[0].isRingSize,

                // shopdiamonds:diamondContent1,
                // showbridaleng:bridalEngContent1,
                // shopdesigners:designerContent1,
                // shopjewelery:jewelryContent1,
                // shopwatches:watchesContent1
              }));

              for (var i = 0; i < designerSEO11.length; i++) {
                if (designerSEO11[i].parentMenuID == 1) {
                  diamondContent1 = designerSEO11[i].diamondContent;
                }
                if (designerSEO11[i].parentMenuID == 2) {
                  bridalEngContent1 = designerSEO11[i].bridalEngContent;
                }
                if (designerSEO11[i].parentMenuID == 3) {
                  designerContent1 = designerSEO11[i].designerContent;
                }
                if (designerSEO11[i].parentMenuID == 4) {
                  jewelryContent1 = designerSEO11[i].jewelryContent;
                }
                if (designerSEO11[i].parentMenuID == 5) {
                  watchesContent1 = designerSEO11[i].watchesContent;
                }
              }

              setInitialData((prevState) => ({
                ...prevState,

                shopdiamonds: diamondContent1,
                // showbridaleng:bridalEngContent1,
                // // shopdesigners:designerContent1,
                // // shopjewelery:jewelryContent1,
                // // shopwatches:watchesContent1
              }));
              setInitialData((prevState) => ({
                ...prevState,
                showbridaleng: bridalEngContent1,
              }));
              setInitialData((prevState) => ({
                ...prevState,
                shopdesigners: designerContent1,
              }));
              setInitialData((prevState) => ({
                ...prevState,
                shopjewelery: jewelryContent1,
              }));
              setInitialData((prevState) => ({
                ...prevState,
                shopwatches: watchesContent1,
              }));

              setLoading(false);
              setBtnVisible(true);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // end api //

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setBtnVisible(false);
      setInitialData({
        ...initialData,
        [name]: e,
      });
    } else {
      setBtnVisible(false);
      setInitialData({
        ...initialData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        diamondContent: initialData.shopdiamonds,
        bridalEngContent: initialData.showbridaleng,
        designerContent: initialData.shopdesigners,
        jewelryContent: initialData.shopjewelery,
        watchesContent: initialData.shopwatches,
        designersWebSiteURL: initialData.websiteurl,
        googleanalyticID: initialData.googleanalyticid,
        isWishlist: initialData.wishlist,
        isBridalRegistry: initialData.bridalregistry,
        isShoppingCart: initialData.shoppingcart,
        isStoreHours: initialData.showstorehouse,
        masterlinkIframe: initialData.newmasterlinkuse,
        isWholeSalePrice: initialData.showwholeprice,
        showCallforAddress: true, // Extra field
        loadFromMasterlink: initialData.diamondsearch,
        vendorDetailDesplay: true, // Extra field
        isSettingName: initialData.showsettingname,
        showAdditionalPopUp: initialData.showadditionalpopup,
        isAppointment: initialData.appointments,
        isOneStep: initialData.showringbuilderwith,
        isRingSize: initialData.showdefaultringsi,
        isRinShowAdvanceDiamondSearchgSize: true, // Extra field
        showAdvanceRingBuilder: true, // Extra field
        theme1: initialData.masterlinktheme,
        theme2: "",
        showView: initialData.showview,
        showMSRPprice: initialData.showonlymsrp,
        loginUrl: initialData.loginurl,
        diamondImageURL: initialData.diamondimageurl,
        currency: initialData.currency,
        showShoppingCartMessage: initialData.shoppingcartpage,
        heartsOnFireLink: initialData.heartsonfire,
        displayVendorDetail: initialData.vendordetailsopen,
        showAdvanceDiamondSearch: true, // Extra field
        isShowAddress: initialData.showaddress,
      };
      setLoading(true);
      MasterLinkService.SaveMasterLinkLegacyData(inputData)
        .then((response) => {
          var message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Updated Successfully.");
            handleGetMasterLagacyDetails(dealerId);
          } else {
            NotificationManager.success("Data not Updated");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }

    setBtnVisible(true);
  };

  return (
    <React.Fragment>
      <div className="navigation__section dlegacy__section">
        <div className="form__fields">
          <div className="col-md-12 mt-2">
            <h4 className="workarea__heading mt-0 mb-0">
              {" "}
              {""} {traslate("Legacy")} {}{" "}
            </h4>
          </div>

          <Spin spinning={loading}>
            <div className="col-lg-6 mt-4">
              <div className="form__section">
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Appointments")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        // defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "appointments")
                        }
                        checked={initialData.appointments}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Wishlist")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        // defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "wishlist")
                        }
                        checked={initialData.wishlist}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Bridal Registry")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "bridalregistry")
                        }
                        checked={initialData.bridalregistry}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Shopping Cart")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "shoppingcart")
                        }
                        checked={initialData.shoppingcart}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Show Store Hours")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showstorehouse")
                        }
                        checked={initialData.showstorehouse}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Website URL")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        name="websiteurl"
                        value={initialData.websiteurl}
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Google Analytic ID")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        name="googleanalyticid"
                        value={initialData.googleanalyticid}
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Only MSRP Prices")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showonlymsrp")
                        }
                        checked={initialData.showonlymsrp}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Masterlink Theme")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="Select View"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "masterlinktheme")
                        }
                        // name="ringBuilder"
                        value={initialData.masterlinktheme}
                        // filterOption={(input, option) =>
                        //     option.children
                        //         .toLowerCase()
                        //         .indexOf(input.toLowerCase()) >= 0
                        // }
                      >
                        {/* <Option value="jack">10</Option>
                                            <Option value="lucy">25</Option>
                                            <Option value="tom">30</Option> */}
                        <Option value="">Select Theme</Option>
                        {masterlinkth &&
                          masterlinkth.map((item, i) => {
                            return (
                              <Option value={item.value}>{item.text}</Option>
                            );
                          })}
                      </Select>
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Show View")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="Select View"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showview")
                        }
                        // name="ringBuilder"
                        value={initialData.showview}
                      >
                        {" "}
                        <Option value="">{traslate("Select View")}</Option>
                        <Option value="1"> {traslate("List View")} </Option>
                        <Option value="2"> 3 {traslate("Items View")} </Option>
                        <Option value="3"> 4 {traslate("Items View")} </Option>
                        <Option value="4"> 5 {traslate("Items View")} </Option>
                      </Select>
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("New MasterLink Use In Iframe?")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "newmasterlinkuse")
                        }
                        checked={initialData.newmasterlinkuse}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Currency")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="USD"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "currency")
                        }
                        // name="ringBuilder"
                        value={initialData.currency}
                      >
                        <Option value="1"> {traslate("USD")} </Option>
                        <Option value="2"> {traslate("GBP (Pound)")} </Option>
                        <Option value="3"> {traslate("AUD")} </Option>
                      </Select>
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Login URL")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        name="loginurl"
                        value={initialData.loginurl}
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show WholeSale Price Internal Use Link?")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showwholeprice")
                        }
                        checked={initialData.showwholeprice}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Address/Phone Details?")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showaddress")
                        }
                        checked={initialData.showaddress}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Diamond Search / Ringbuilder load from Masterlink?"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "diamondsearch")
                        }
                        checked={initialData.diamondsearch}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Diamond Image URL")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        name="diamondimageurl"
                        value={initialData.diamondimageurl}
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Vendor Details open or closed by default in new masterlink"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Open"
                        unCheckedChildren="Close"
                        onChange={(e) =>
                          handleOnChange(e, "select", "vendordetailsopen")
                        }
                        checked={initialData.vendordetailsopen}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Additional PopUp in new masterlink")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showadditionalpopup")
                        }
                        checked={initialData.showadditionalpopup}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Shopping Cart page message")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <TextArea
                        rows={2}
                        name="shoppingcartpage"
                        value={initialData.shoppingcartpage}
                        onChange={handleOnChange}
                      />
                      {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Hearts On Fire Link")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        name="heartsonfire"
                        value={initialData.heartsonfire}
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Setting Name at Setting Grid")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showsettingname")
                        }
                        checked={initialData.showsettingname}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Ring Builder with One Step")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showringbuilderwith")
                        }
                        checked={initialData.showringbuilderwith}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Default Ring Size")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(e) =>
                          handleOnChange(e, "select", "showdefaultringsi")
                        }
                        checked={initialData.showdefaultringsi}
                      />
                      <Tooltip className="ml-1" placement="right">
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="mb-2"> {traslate("Shop Diamonds")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      {/* <TextArea rows={2} /> */}
                      <div className="masterlink__editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={initialData.shopdiamonds}
                          // id="editor"
                          onChange={(event, editor) => {
                            setBtnVisible(false);
                            const data = editor.getData();
                            setInitialData({
                              ...initialData,
                              shopdiamonds: data,
                            });
                          }}
                        />
                      </div>
                      {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="mb-2">
                      {" "}
                      {traslate("Shop Bridal & Engagement")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <div className="masterlink__editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={initialData.showbridaleng}
                          // id="editor"
                          onChange={(event, editor) => {
                            setBtnVisible(false);
                            const data = editor.getData();
                            setInitialData({
                              ...initialData,
                              showbridaleng: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="mb-2"> {traslate("Shop Designers")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <div className="masterlink__editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={initialData.shopdesigners}
                          // id="editor"
                          onChange={(event, editor) => {
                            setBtnVisible(false);
                            const data = editor.getData();
                            setInitialData({
                              ...initialData,
                              shopdesigners: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="mb-2"> {traslate("Shop Jewelry")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <div className="masterlink__editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={initialData.shopjewelery}
                          // id="editor"
                          onChange={(event, editor) => {
                            setBtnVisible(false);
                            const data = editor.getData();
                            setInitialData({
                              ...initialData,
                              shopjewelery: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="mb-2"> {traslate("Shop Watches")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <div className="masterlink__editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={initialData.shopwatches}
                          // id="editor"
                          onChange={(event, editor) => {
                            setBtnVisible(false);
                            const data = editor.getData();
                            setInitialData({
                              ...initialData,
                              shopwatches: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-4">
              <button
                class={btnVisible ? "disabled-btn" : "primary-btn"}
                disabled={btnVisible}
                loading={submitLoading}
                onClick={handleSubmit}
              >
                {" "}
                {traslate("Update Settings")}{" "}
              </button>
            </div>
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MasterLinkLegacy;
