import * as React from "react"
const AtIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={15}
    viewBox="0 0 38 38"
    {...props}
    style={{
        marginRight:"9px"
    }}
  >
    <title>{"at"}</title>
    <path d="M0 16q0-3.232 1.28-6.208t3.392-5.12 5.12-3.392T16 0q3.264 0 6.24 1.28t5.088 3.392 3.392 5.12T32 16v6.016q0 2.496-1.76 4.224T26.016 28q-2.272 0-3.936-1.472t-1.984-3.68Q18.144 24 16 24q-2.176 0-4-1.056t-2.944-2.912T8 16q0-3.296 2.336-5.632T16 8t5.664 2.368T24 16v6.016q0 .8.608 1.408t1.408.576q.8 0 1.408-.576T28 22.016V16q0-3.264-1.6-6.016t-4.384-4.352T16 4 9.984 5.632 5.6 9.984 4 16t1.6 6.048T9.984 26.4 16 28h2.016q.8 0 1.408.608T20 30.016t-.576 1.408-1.408.576H16q-3.264 0-6.208-1.248t-5.12-3.424-3.392-5.12T0 16zm12 0q0 1.664 1.184 2.848T16 20t2.816-1.152T20 16t-1.184-2.816T16 12t-2.816 1.184T12 16z" />
  </svg>
)
export default AtIcon
