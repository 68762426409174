import React, { useState, useEffect, Fragment } from "react";
import { Checkbox, Button, Modal, Spin } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../../i18n/translate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import communityVendorService from "../../../services/community-vendor.service";
import { useSelector, useDispatch } from "react-redux";
import Rectangle from "../../../assets/images/Rectangle.png";
import { useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  setChecklinkHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../../actions/headerMenu/headerMenu";
import { useNavigate } from "react-router-dom";

const DealerProfile = () => {
  //inventory ends
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [dealerId, setDealerId] = useState("");
  const [visible, setVisible] = useState(false);
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [sideProfileLoading, setSideProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [retailerId, setRetailerId] = useState("");
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [company, setCompany] = useState("");
  const [content, setContent] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [page, setPage] = useState("RetailerAffirmation");
  const [checkbox, setCheckBox] = useState("");
  const [conversationData, setConversationData] = useState([]);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [conversationMsg, setConversationMsg] = useState("");
  const [validations, setValidations] = useState({
    conversationVal: "",
    tradeshowVal: "",
    salesRepVal: "",
    appointmentDateVal: "",
    buyerNameVal: "",
    emailAddressVal: "",
    phoneNumberVal: "",
    qncVal: "",
    timeval: "",
  });
  const [appointmentData, setAppointmentData] = useState({
    tradeshow: "",
    salesRep: "",
    appointmentDate: "",
    appointmentTime: "",
    buyerName: "",
    emailAddress: "",
    phoneNumber: "",
    preferred: "E-Mail",
    qnc: "",
    interested: false,
  });
  const [contentLoading, setContentLoading] = useState(false);
  const [tradeShowList, setTradeShowList] = useState([]);
  const [contentAppData, setContentAppData] = useState("");
  const [pageType, setPageType] = useState("");

  const loginDetials = useSelector((state) => state.loginReducer);
  const location = useLocation();
  const [deleteConversationData, setDeleteConversationData] = useState({
    id: "",
  });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [visibleImagePreview, setVisibleImagePreview] = useState(false);
  const [visibleEmailModal, setVisibleEmailModal] = useState(false);
  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);

  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: `I am interested in opening a new account with ${rowDetails.designerName} and becoming an authorized reseller.By checking this box and clicking the submit button below, your name and store information will be sent to ${rowDetails.designerName}.`,
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",
      note: `By checking this box and clicking the submit button below Ihere by affirm that ${
        company ? company : null
      } has an open and active account with ${
        rowDetails.designerName
      },that I am an authorized reseller of ${
        rowDetails.designerName
      },and that I am permitted to have the ir virtual inventory on my website.`,
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setCompany(loginDetials.loginDetials.responseData.dealerCompany);
          handleGetTradeShowAppointment(
            loginDetials.loginDetials.responseData.dealerId
          );
          if (location.state) {
            setRetailerId(location.state.id);
            setPageType(location.state.pageType);
            if (location.state.key) {
              if (location.state.id) {
                handleLoadLeftRightSideData(
                  loginDetials.loginDetials.responseData.dealerId,
                  location.state.id
                );
              }
              if (location.state.key == "Profile" && location.state.id) {
                setSelectedTab("Profile");
                handleLoadVendorProfile(location.state.id);
              } else if (
                location.state.key == "Location" &&
                location.state.id
              ) {
                setSelectedTab("Location");
              } else if (
                location.state.key == "Inventory" &&
                location.state.id
              ) {
                setSelectedTab("Inventory");
              } else if (location.state.key == "Conversation") {
                setSelectedTab("Conversation");
                handleLoadVendorConversation(
                  loginDetials.loginDetials.responseData.dealerId,
                  location.state.id,
                  location.state.pageType
                );
              }
            }
          }
        }
      }
    }
  }, []);

  const handleGetTradeShowAppointment = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };
      communityVendorService
        .TradeShowAppointment(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setTradeShowList(responseData);
            } else {
              setTradeShowList([]);
            }
          } else {
            setTradeShowList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadVendorConversation = (dealerid, id, page) => {
    try {
      let inputData = {
        vendorID: id,
        dealerID: dealerid,
        moduleType: page ? page : pageType,
      };
      setConversationLoading(true);
      communityVendorService
        .LoadVendorConversation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setConversationData(responseData);
              setConversationLoading(false);
            } else {
              setConversationLoading(false);
              setConversationData([]);
            }
          } else {
            setConversationLoading(false);
            setConversationData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setConversationLoading(false);
        });
    } catch (error) {
      console.log(error);
      setConversationLoading(false);
    }
  };

  const handleLoadLeftRightSideData = (id, retailerID) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        vendorId: retailerID.toString(),
        f_Type: "1",
      };
      setSelectedColumnId(retailerID);
      setSideProfileLoading(true);
      communityVendorService
        .LoadLeftRightSideData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setRowDetails(responseData);
              setSideProfileLoading(false);
            } else {
              setRowDetails({});
              setSideProfileLoading(false);
            }
          } else {
            setRowDetails({});
            setSideProfileLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSideProfileLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSideProfileLoading(false);
    }
  };

  const handleLoadVendorProfile = (id) => {
    try {
      let inputData = {
        vendorId: id.toString(),
      };
      setLoadingProfileData(true);
      communityVendorService
        .LoadVendorProfile(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setProfileData(responseData);
              setLoadingProfileData(false);
            } else {
              setProfileData([]);
              setLoadingProfileData(false);
            }
          } else {
            setProfileData({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  const handleSendInvitationModalCancel = () => {
    setIsModalSendInvitationVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  const handleTabsChange = (e) => {
    setSelectedTab(e);
    if (e == "Profile") {
      handleLoadVendorProfile(retailerId);
    }
    if (e == "Conversation") {
      handleLoadVendorConversation(dealerId, retailerId);
    }
    if (e == "Inventory") {
      handleHeaderMenuClickForSideProfileApp();
    }
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
  }

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleGetEmailData = () => {
    try {
      let inputData = {
        vendorID: retailerId,
        dealerID: dealerId,
        emailType: rowDetails.emailType,
      };
      setEmailLoading(true);
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  const handleCheckBoxValidation = () => {
    NotificationManager.error("Please select option");
  };
  const handleNewAccountRequest = () => {
    try {
      let inputData = {
        email_Type: rowDetails.emailType,
        vendorID: retailerId,
        dealerID: dealerId,
      };
      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleSendInvitationModalCancel();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: rowDetails.emailType,
        vendorID: retailerId,
        dealerID: dealerId,
      };
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;

          if (message === "Success") {
            NotificationManager.success("Email has been sent Successfully");
            setContent(responseData);
          } else {
            NotificationManager.success("Email has not been sent ");
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleSendInvitationModalCancel();
  };

  const handleValidation = () => {
    const validations = {
      conversationVal: "",
      appointmentVal: "",
      tradeshowVal: "",
      appointmentDateVal: "",
      buyerNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      qncVal: "",
      timeval: "",
    };

    let isValid = true;
    if (selectedTab == "Conversation") {
      if (!conversationMsg.trim()) {
        isValid = false;
        validations.conversationVal = traslate(
          "Conversation data is compulsory"
        );
      }
    }

    if (selectedTab == "Appointments") {
      if (tradeShowList.length !== 0) {
        if (!appointmentData.tradeshow) {
          isValid = false;
          validations.tradeshowVal = traslate("Trade show is compulsory");
        }
      }
      if (!appointmentData.salesRep) {
        isValid = false;
        validations.salesRepVal = traslate("Sales rep is compulsory");
      }
      if (!appointmentData.appointmentDate) {
        isValid = false;
        validations.appointmentDateVal = traslate(
          "Appointment date is compulsory"
        );
      }
      if (!appointmentData.buyerName) {
        isValid = false;
        validations.buyerNameVal = traslate("Buyer name is compulsory");
      }
      let validRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!appointmentData.emailAddress.trim()) {
        isValid = false;
        validations.emailAddressVal = traslate("Email address is compulsory");
      } else if (!appointmentData.emailAddress.match(validRegex)) {
        isValid = false;
        validations.emailAddressVal = traslate("Email address is invalid");
      } else {
        validations.emailAddressVal = "";
      }
      if (!appointmentData.phoneNumber.trim()) {
        isValid = false;
        validations.phoneNumberVal = traslate("Phone number is compulsory");
      } else if (
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes("-")) ||
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes(",")) ||
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes("+"))
      ) {
        validations.phoneNumberVal = "";
      } else if (
        !/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(
          appointmentData.phoneNumber
        )
      ) {
        isValid = false;
        validations.phoneNumberVal = traslate("Phone number is invalid");
      } else {
        validations.phoneNumberVal = "";
      }
      if (!appointmentData.qnc) {
        isValid = false;
        validations.qncVal = traslate("Question is compulsory");
      }
      if (!appointmentData.appointmentTime) {
        isValid = false;
        validations.timeval = traslate("Time is compulsory");
      }
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleConversationSave = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        vendorId: selectedColumnId.toString(),
        cMessage: conversationMsg,
        itemID: "",
        moduleType: pageType ? pageType : "",
      };
      communityVendorService
        .PostDealerConversation(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Conversation added Successfully.");
            handleLoadVendorConversation(dealerId, selectedColumnId);
          } else {
            NotificationManager.error("Conversation not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setConversationMsg("");
    setValidations({
      conversationVal: "",
    });
  };

  const handleSendEmailCancel = () => {
    setVisibleEmailModal(false);
  };

  const handleSendEmailDetails = () => {
    try {
      let inputData = {
        dealerShowID: "",
        dealerID: dealerId.toString(),
        vendorID: selectedColumnId.toString(),
        appintmentDate: appointmentData.appointmentDate,
        appointmentTime: appointmentData.appointmentTime,
        buyerName: appointmentData.buyerName,
        email: appointmentData.emailAddress,
        phoneNumber: appointmentData.phoneNumber,
        questionsAndComments: appointmentData.qnc,
        boothNumber: appointmentData.tradeshow,
        editHTML: contentAppData,
        preferred: appointmentData.preferred,
        localRep: appointmentData.salesRep,
        placingInventory: appointmentData.interested == true ? 1 : 0,
      };
      communityVendorService
        .InsertVendorsAppointment(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Appointement added Successfully.");
            handleLoadVendorConversation(dealerId, selectedColumnId);
          } else {
            NotificationManager.error("Appointement not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const openInNewTab = () => {
    let url = rowDetails.website;
    if (rowDetails.website.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.website.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleHeaderMenuClickForSideProfileApp = () => {
    dispatch(setChecklinkHeaderMenuData("My Catalog"));
    let MenuData = [`${"Products"}`, `${"ProductsDiamond"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["diamondSearch"]));

    navigate("/diamondSearch", {
      state: {
        id: retailerId,
      },
    });
  };

  const handleDeleteModal = (id) => {
    setDeleteModalVisible(true);
    setDeleteConversationData({
      id: id,
    });
  };
  const handleCancelModal = () => {
    setDeleteModalVisible(false);
    setDeleteConversationData({
      id: "",
    });
  };

  const handleRemoveCustomerConversation = () => {
    let inputData = {
      conersionId: deleteConversationData.id,
    };
    setDeleteLoading(true);

    try {
      communityVendorService
        .RemoveVendorCustomerConversation(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.success(
              "Conversation Has Been Removed Successfully."
            );
            handleLoadVendorConversation(dealerId, retailerId);
          } else {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.error("Conversation Has Not Been Remove.");
          }
        })
        .catch((error) => {
          console.log(error);
          setDeleteLoading(false);
          setDeleteModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      setDeleteModalVisible(false);
    }
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion retailerprof__section permission__section">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    Dealer Profile{" "}
                  </h4>
                </div>
                <div className="col-lg-9 col-md-9">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="Profile"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="Profile" title={traslate("Profile")}>
                        <div className="tab__contentdiv">
                          <Spin spinning={loadingprofileData}>
                            <div className="col-lg-12">
                              <div className="desc__maindiv">
                                <div className="col-lg-12">
                                  <div className="top__slider">
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...options}
                                    >
                                      {profileData.imagepaths &&
                                        profileData.imagepaths.map(
                                          (item, i) => {
                                            return item.imageName ? (
                                              <div className="item" key={i}>
                                                <div className="image__block">
                                                  <img src={item.imageName} />
                                                </div>
                                              </div>
                                            ) : null;
                                          }
                                        )}
                                    </OwlCarousel>
                                  </div>
                                </div>
                                <div className="col-lg-12 p-3">
                                  {profileData.aboutCompany && (
                                    <div className="editor__ck">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={profileData.aboutCompany}
                                        config={{
                                          toolbar: [],
                                        }}
                                        onChange={(e, editor) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Spin>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="Inventory"
                        title={traslate("Inventory")}
                      ></Tab>

                      <Tab
                        eventKey="Conversation"
                        title={traslate("Conversation")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 mt-2">
                                <div className="input__block">
                                  <textarea
                                    name="conversationMsg"
                                    onChange={(e) =>
                                      setConversationMsg(e.target.value)
                                    }
                                    value={conversationMsg}
                                    placeholder="Your message to the vendor."
                                  ></textarea>
                                  <p>
                                    {" "}
                                    {conversationData &&
                                    conversationData.length > 0 ? null : (
                                      <span>
                                        {traslate(
                                          "Write the first message ...."
                                        )}
                                      </span>
                                    )}
                                  </p>
                                  <div>
                                    {validations.conversationVal && (
                                      <p className="error-color-red">
                                        {validations.conversationVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <Button
                                  className="primary-btn"
                                  onClick={handleConversationSave}
                                >
                                  {traslate("Post")}
                                </Button>
                              </div>
                              <Spin spinning={conversationLoading}>
                                <div className="col-lg-12">
                                  {conversationData.map((item, key) => {
                                    return (
                                      <div
                                        className="notedesc__maindiv"
                                        key={item.id}
                                      >
                                        <div className="date__headdiv">
                                          <div>
                                            <span>
                                              {item.cDateFormated +
                                                "-" +
                                                item.senderName}
                                            </span>
                                          </div>
                                          <div>
                                            <p
                                              onClick={() =>
                                                handleDeleteModal(item.id)
                                              }
                                              className="linkText"
                                            >
                                              Delete
                                            </p>
                                          </div>
                                        </div>
                                        <div className="msg__headdiv">
                                          <div className="editor__ck">
                                            {item?.messages && (
                                              <CKEditor
                                                editor={ClassicEditor}
                                                data={item?.messages || ""}
                                                config={{
                                                  toolbar: [],
                                                }}
                                                onChange={(e, editor) => {
                                                  e.preventDefault();
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Spin>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <Spin spinning={sideProfileLoading}>
                    <div className="right__section">
                      <div className="profile__block">
                        <p className="linkText">
                          {rowDetails.designerName
                            ? rowDetails.designerName
                            : ""}
                        </p>
                        <div className="logoImg__block p-0">
                          <img src={rowDetails.designerLogo} />
                        </div>
                      </div>

                      <div className="links__block">
                        <ul>
                          <li>
                            <span
                              onClick={() => setSelectedTab("Profile")}
                              className="linkText"
                            >
                              <i
                                class="fa fa-address-book-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {traslate("View Profile")}
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => setSelectedTab("Conversation")}
                              className="linkText"
                            >
                              <i
                                class="fa fa-comments-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {traslate("Conversation")}
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() =>
                                handleHeaderMenuClickForSideProfileApp(
                                  "Inventory"
                                )
                              }
                              className="linkText"
                            >
                              <i class="fa fa-diamond" aria-hidden="true"></i>{" "}
                              {traslate("View Inventory")}
                            </span>
                          </li>
                        </ul>
                        <hr />
                      </div>

                      <div className="retailer__dtls mb-4">
                        <p>
                          {" "}
                          {rowDetails.dealerName
                            ? rowDetails.dealerName
                            : null}{" "}
                        </p>
                        <a href={`mailto:${rowDetails.dealerEmail}`}>
                          {rowDetails.dealerEmail
                            ? rowDetails.dealerEmail
                            : null}
                        </a>
                        <p>
                          {rowDetails.dealerPhone
                            ? rowDetails.dealerPhone
                            : null}
                        </p>
                        <span
                          className="linkText"
                          onClick={() => openInNewTab()}
                        >
                          {" "}
                          {rowDetails.website}
                        </span>
                        <hr />
                      </div>
                    </div>
                  </Spin>
                </div>
                {/* End Side Profile */}

                <div className="col-lg-12">
                  <Modal
                    title={traslate("Create A New Note")}
                    className="modalretailerprof__section"
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button key="submit" type="primary" onClick={handleOk}>
                        {traslate("Submit")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="editor__section">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    onReady={(editor) => {
                                      // You can store the "editor" and use when it is needed.
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>

                  <Modal
                    title={
                      page == "RetailerAffirmation"
                        ? traslate("Retailer Affirmation")
                        : traslate("Send Email")
                    }
                    visible={isModalSendInvitationVisible}
                    className="forgot__password"
                    onCancel={handleSendInvitationModalCancel}
                    footer={[
                      //
                      <div
                        className={
                          page == "RetailerAffirmation" ? "d-block" : "d-none"
                        }
                      >
                        <Button
                          key="submit"
                          className={
                            checkbox == "" ? "disabled-btn" : "primary-btn"
                          }
                          disabled={checkbox == "" ? true : false}
                          onClick={
                            checkbox == "CurrentAuthorizedReseller"
                              ? handleShowEmailWindow
                              : checkbox == "NewAccountRequest"
                              ? handleNewAccountRequest
                              : handleCheckBoxValidation
                          }
                        >
                          {traslate("Submit")}
                        </Button>
                      </div>,
                      <div
                        className={page == "SendEmail" ? "d-block" : "d-none"}
                      >
                        <Button
                          className="primary-btn"
                          key="submit"
                          onClick={handleSendEmail}
                        >
                          {traslate("Send")}
                        </Button>
                      </div>,
                    ]}
                  >
                    <div className="form__fields p-0 border-0 mh-auto">
                      {page == "RetailerAffirmation" ? (
                        <div>
                          {category.map((item) => {
                            return (
                              <Fragment>
                                <div className="radio__block">
                                  <Checkbox
                                    key={item.value}
                                    onChange={handleCheckBoxChange}
                                    checked={item.value == checkbox}
                                    value={item.value}
                                  >
                                    <label> {traslate(item.label)} </label>
                                  </Checkbox>
                                </div>
                                <div>
                                  <p> {item.note} </p>
                                </div>
                              </Fragment>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="editor__section">
                          <Spin spinning={emailLoading}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={content}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(data);
                              }}
                            />
                          </Spin>
                        </div>
                      )}
                    </div>
                  </Modal>

                  {/* Inventory table modal */}

                  <Modal
                    title={traslate("Image Preview")}
                    centered
                    visible={visibleImagePreview}
                    onOk={() => setVisibleImagePreview(false)}
                    onCancel={() => setVisibleImagePreview(false)}
                    width={400}
                  >
                    <div className="form__fields mh-auto">
                      <img src={Rectangle} className="img-fluid" />
                    </div>
                  </Modal>

                  {/* Appointment Schedule Modal */}

                  <Modal
                    className="emailTemplateEditorModal"
                    title={traslate("Send Email")}
                    centered
                    visible={visibleEmailModal}
                    onOk={() => setVisibleEmailModal(false)}
                    onCancel={() => setVisibleEmailModal(false)}
                    width={800}
                    hieght={600}
                    footer={[
                      <Button key="back" onClick={handleSendEmailCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button key="Save" onClick={handleSendEmailDetails}>
                        {traslate("Save")}
                      </Button>,
                    ]}
                  >
                    <div className="editor__section">
                      <Spin spinning={contentLoading}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={contentAppData}
                          onReady={(editor) => {}}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setContentAppData(data);
                          }}
                        />
                      </Spin>
                    </div>
                  </Modal>
                  <Modal
                    className=""
                    centered
                    visible={deleteModalVisible}
                    onOk={handleRemoveCustomerConversation}
                    onCancel={handleCancelModal}
                    maskClosable={false}
                    width={400}
                    footer={[
                      <Button key="back" onClick={handleCancelModal}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button
                        key="submit"
                        loading={deleteLoading}
                        type="primary"
                        onClick={handleRemoveCustomerConversation}
                      >
                        {traslate("Delete")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-lg-12 text-center">
                                <p className="subheading">
                                  Are You Sure You Want delete this conversation
                                  ?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DealerProfile;
