import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Spin,
} from "antd";
import {
  Bar,
  getElementAtEvent,
} from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import GlobalDiamondReportService from "../../../services/reports-globalDiamond-service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import ReportJewelryReportService from "../../../services/reports-Jewelry-service";
import moment from "moment";
const { RangePicker } = DatePicker;

const JewelryAddToCartReport = () => {
  const DiamondCutChartRef = useRef();
  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  /*Initial State for Binding Ends*/
  /*React Binding Starts*/
  const [TableForAllList, setTableForAllList] = useState([]);
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TableForAllLoading, setTableForAllLoading] = useState(false);
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondCutGradeSearchesLabels, setdiamondCutGradeSearchesLabels] =
    useState([]);
  const [DiamondCutGradeSearchesList, setDiamondCutGradeSearchesList] =
    useState([]);
  const [DiamondCutGradeColorData, setDiamondCutGradeColorData] = useState([]);
  const [DiamondCutGradeYAxisList, setDiamondCutGradeYAxisList] = useState([]);
  const [TableCutGradeValue, setTableCutGradeValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortType, setSortType] = useState("asc");
  const [sortColumnName, setSortColumnName] = useState("GT.MODIFIED");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (TableCutGradeValue) {
      handleGetDiamondReportForTableChartAll(dealerId, forDealer);
    }
  }, [TableCutGradeValue]);


  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      GlobalDiamondReportService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            handleLoadChartReports(id, forDealer);
            handleGetDiamondReportForTableChartAll(
              id,
              forDealer,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2016-03-07",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2023-04-06",
        retailerIds: "",
        vendorIds: "",
        addToType: "AddToCart",
        reporttype: "Dealer",
      };
      setLoadChartReportsLoading(true);

      ReportJewelryReportService.AddToCartOrWishlistChartColunmData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondCutGradeSearchesList = responseData.VendorBasedData;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setDiamondCutGradeSearchesList(DiamondCutGradeSearchesList);
              var DiamondCutGradeSearchesLabelArr =
                DiamondCutGradeSearchesList.map((item) => {
                  return item.VendorName;
                });
              setdiamondCutGradeSearchesLabels(DiamondCutGradeSearchesLabelArr);
              var DiamondCutGradeYAxisList = DiamondCutGradeSearchesList.map(
                (item) => {
                  return item.CNT;
                }
              );
              var DiamondCutGradeColorList = DiamondCutGradeSearchesList.map(
                (item) => {
                  return item.VendorName;
                }
              );
              setDiamondCutGradeYAxisList(DiamondCutGradeYAxisList);
              diamondCutGradeColorHandler(DiamondCutGradeColorList);
              setKey(!key);
            }
          } else {
            setDiamondCutGradeSearchesList([]);
            setDiamondCutGradeYAxisList([]);
          }
        })
        .catch((error) => {
          setDiamondCutGradeSearchesList([]);
          setDiamondCutGradeYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondCutGradeSearchesLabels([]);
        });
    } catch (error) {
      setDiamondCutGradeSearchesList([]);
      setDiamondCutGradeYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondCutGradeSearchesLabels([]);
    }
  };
  const handleGetDiamondReportForTableChartAll = (
    dealerId,
    forDealer,
    pageNumber,
    pageSiz,
    sortOrderCol,
    sortOrder
  ) => {
    try {
      let inputData = {
        reporttype: "Dealer",
        dealerID: dealerId,
        forDealer: 3,
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: "",
        vendorIds: TableCutGradeValue.toString(),
        category: "",
        metalType: "",
        metalColorType: "",
        price: "",
        vendor: "",
        retailer: "",
        price1: "",
        price2: "",
        addToType: "AddToCart",
        pageIndex: pageNumber ? pageNumber : 1,
        pageSize: pageSiz ? pageSiz : 10,
        sortColumnName:
          sortOrderCol == "UserIPAddress"
            ? "UserIPAddress"
            : sortOrderCol == "VendorName"
              ? "VendorName"
              : sortOrderCol == "RetailerName"
                ? "RetailerName"
                : sortOrderCol == "CollectionNew"
                  ? "CollectionNew"
                  : sortOrderCol == "Category"
                    ? "Category"
                    : sortOrderCol == "Date"
                      ? "Date"
                      : sortOrderCol == "StyleNumber"
                        ? "StyleNumber"
                        : sortOrderCol == "MetalType"
                          ? "MetalType"
                          : sortOrderCol == "MetalColor"
                            ? "MetalColor"
                            : sortOrderCol == "Price"
                              ? "Price"
                              : "UserIPAddress",
        sortOrder: sortOrder ? sortOrder.toString() : sortType.toString(),
      };
      setTableForAllLoading(true);

      ReportJewelryReportService.AddToCartOrWIshlistReportForTableChart(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var TableForAllList =
            responseData.AddToCartOrWishlistReportForTableChartresp;
          var TotalEventsCount = responseData.totalCount;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setTotalCount(TableForAllList?.[0]?.totalRecords || 0);
              setTableForAllList(TableForAllList);
              setTableForAllLoading(false);
            }
          } else {
            setTotalCount(0);
            setTableForAllList([]);
            setTableForAllLoading(false);
          }
        })
        .catch((error) => {
          setTotalCount(0);
          setTableForAllList([]);
          setTableForAllLoading(false);
        });
    } catch (error) {
      setTotalCount(0);
      setTableForAllList([]);
      setTableForAllLoading(false);
    }
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "GT.MODIFIED";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*API Binding Ends*/
  const diamondCutGradeColorHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setDiamondCutGradeColorData(color);
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    //
    if (dates) {
      //
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };
  /*DatePicker Binding Ends*/

  const handleOnChange = (e, isSelect, name) => {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e,
    });
  };

  const handleApplyFilter = () => {
    handleLoadChartReports(dealerId, forDealer);
    handleGetDiamondReportForTableChartAll(dealerId, forDealer);
  };

  return (
    <React.Fragment>
      <div className="form__fields magento__mapping">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Jewelery Vendor based Add To Cart Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          {TableForAllList &&
            TableForAllList.length > 0 &&
            DiamondCutGradeSearchesList.length > 0 && (
              <div className="col-lg-12 col-md-6 mt-4">
                <div className="input__block">
                  <label>
                    <Spin spinning={LoadChartReportsLoading} size="small">
                      <b>
                        {" "}
                      </b>
                    </Spin>
                  </label>
                </div>
              </div>
            )}

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="chart__section">
            <Spin spinning={LoadChartReportsLoading} size="large">
              {TableForAllList && TableForAllList.length > 0 ? (
                <Bar
                  key={key}
                  onClick={(event) => {
                    const element = getElementAtEvent(
                      DiamondCutChartRef.current,
                      event
                    );
                    if (!element.length) return;

                    const { index } = element[0];
                    setTableCutGradeValue(diamondCutGradeSearchesLabels[index]);
                  }}
                  ref={DiamondCutChartRef}
                  data={{
                    labels: diamondCutGradeSearchesLabels,
                    datasets: [
                      {
                        label: "Count",
                        data: DiamondCutGradeYAxisList,
                        backgroundColor: DiamondCutGradeColorData,
                        borderColor: DiamondCutGradeColorData,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={300}
                  height={300}
                  options={{
                    scales: {
                      x: {
                        title: {
                          color: "rgb(66, 66, 66)",
                          display: false,
                          text: "Certificate",
                          font: {
                            size: 16,
                          },
                        },
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {
                          },
                        },
                      },
                    },
                  }}
                />
              ) : null}
            </Spin>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              loading={TableForAllLoading}
              columns={[
                {
                  title: traslate("UserIPAddress"),
                  dataIndex: "UserIPAddress",
                  sorter: (a, b) => { },
                },

                {
                  title: traslate("Vendor"),
                  dataIndex: "VendorName",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Retailer"),
                  dataIndex: "RetailerName",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Collection"),
                  dataIndex: "CollectionNew",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Category"),
                  dataIndex: "Category",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Date"),
                  sorter: (a, b) => { },
                  render: (item, row) => {
                    return moment(row.Date).format("MM-DD-YYYY, hh:mm:ss A");
                  },
                },

                {
                  title: traslate("Style Number"),
                  dataIndex: "StyleNumber",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Metal Type"),
                  dataIndex: "MetalType",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Metal Color"),
                  dataIndex: "MetalColor",
                  sorter: (a, b) => { },
                },
                {
                  title: traslate("Price"),
                  dataIndex: "Price",
                  sorter: (a, b) => { },
                },
              ]}
              dataSource={TableForAllList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryAddToCartReport;
