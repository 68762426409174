import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Spin
} from "antd";
import { Chart as ChartJS } from "chart.js/auto";
import {
  Doughnut,
  Bar,
  Line,
  Scatter,
  Pie,
  Chart,
  getElementAtEvent
} from "react-chartjs-2";
import traslate from "../../i18n/translate";
// import GlobalDiamondReportService from "../../services/reports-globalDiamond-service";
import AdminDiamondReportService from "../../services/reports-AdminDiamond-service";
import { DatePicker, Space } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const AdminDiamondCutComp = ({
  globalDiamonds,
  mainForDealer,
  isApplyFilter,
  pageNo,
  pageSize,
  sortOrderCol,
  sortOrderType,
  setPageNo,
  handleGetDiamondCutList
}) => {
  /*React Binding Starts*/
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondCutSearchesList, setDiamondCutSearchesList] = useState([]);
  const [diamondCutSearchesLabels, setDiamondCutSearchesLabels] = useState([]);
  const [DiamondCutSearchesLoading, setDiamondCutSearchesLoading] =
    useState(false);
  const [VeryGoodDataList, setVeryGoodDataList] = useState([]);
  const [ExcellentDataList, setExcellentDataList] = useState([]);
  const [GoodDataList, setGoodDataList] = useState([]);
  const [FairDataList, setFairDataList] = useState([]);
  const [IdealColorData, setIdealColorData] = useState([]);
  const [DiamondCutReportYAxisList, setDiamondCutReportYAxisList] = useState(
    []
  );
  const DiamondCutChartRef = useRef();
  const [TableCutGradeValue, setTableCutGradeValue] = useState("");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          // handleCheckDealermembers(loginDetials.loginDetials.responseData.dealerId);
          handleDiamondChartForCutGrade(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  useEffect(() => {
    if (TableCutGradeValue) {
      if (window.location.pathname === "/ExtendedDiamondChartReports") {
        return 0;
      } else {
        handleGetDiamondCutList(
          dealerId,
          mainForDealer,
          pageNo,
          pageSize,
          sortOrderCol,
          sortOrderType,
          TableCutGradeValue
        );
      }
    }
  }, [TableCutGradeValue]);

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id
      };

      AdminDiamondReportService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            handleDiamondChartForCutGrade(id, forDealer);
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleDiamondChartForCutGrade = (dealerId, forDealer) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: globalDiamonds.retailerIds.toString(),
        vendorIds: globalDiamonds.vendorIds.toString(),
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue
      };
      setDiamondCutSearchesLoading(true);

      AdminDiamondReportService.DiamondChartForCutGrade(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table;

          setDiamondCutSearchesLoading(false);
          if (message === "Success") {
            if (responseData.length > 0) {
              setDiamondCutSearchesList(responseData);
              var carateRangeArr = responseData.map((item) => {
                return item.CutGrade;
              });
              setDiamondCutSearchesLabels(carateRangeArr);
              var DiamondCutReportYAxisList = responseData.map((item) => {
                return item.CutGradeCount;
              });
              var DiamondCutReportColorList = responseData.map((item) => {
                return item.CutGrade;
              });
              setDiamondCutReportYAxisList(DiamondCutReportYAxisList);
              IdealColorChartDataHandler(DiamondCutReportColorList);
              setKey(!key);
            }
          } else {
            setDiamondCutSearchesList([]);
            setDiamondCutSearchesLabels([]);
          }
        })
        .catch((error) => {
          setDiamondCutSearchesList([]);
          setDiamondCutSearchesLoading(false);
        });
    } catch (error) {
      setDiamondCutSearchesList([]);
      setDiamondCutSearchesLoading(false);
      setVeryGoodDataList([]);
      setExcellentDataList([]);
      setGoodDataList([]);
      setFairDataList([]);
    }
  };
  /*API Binding Ends*/

  const IdealColorChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setIdealColorData(color);
  };

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {traslate("Diamond Cut Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={DiamondCutSearchesLoading} size="large">
            <Bar
              key={key}
              onClick={
                (event) => {
                  const element = getElementAtEvent(
                    DiamondCutChartRef.current,
                    event
                  );
                  if (!element.length) return;

                  const { index } = element[0];
                  // setTableCutGradeValue(
                  //   element[0].element.$datalabels[0].$context.dataset.label
                  // );
                  setTableCutGradeValue(
                    diamondCutSearchesLabels[index]
                  );
                  setPageNo(1);
                }
                // handleGetDiamondReportForTableChartAll(dealerId, forDealer)
              }
              ref={DiamondCutChartRef}
              data={{
                labels: diamondCutSearchesLabels,
                datasets: [
                  {
                    label: "Count",
                    data: DiamondCutReportYAxisList,
                    backgroundColor: IdealColorData,
                    borderColor: IdealColorData,
                    borderWidth: 1
                  }
                ]
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: false,
                      text: "Carat Size",
                      font: {
                        size: 16
                      }
                    }
                  },
                  y: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: false,
                      text: "Percentage Of Searches",
                      font: {
                        size: 16
                      }
                    }
                  }
                },
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  datalabels: {
                    display: false
                  },
                  legend: {
                    display: false
                  }
                }
                // plugins: {
                //   datalabels: {
                //     display: false
                //   },
                //   tooltip: {
                //     enabled: true,
                //     callbacks: {
                //       label: function (context) {
                //         let label = context.dataset.label || "";
                //         let formattedValue = context.formattedValue || "";

                //         if (label) {
                //           label =
                //             label +
                //             " " +
                //             "(" +
                //             parseFloat(formattedValue).toFixed() +
                //             "%)";
                //           // label = parseFloat(formattedValue).toFixed() + "%";
                //         }
                //         return label;
                //       }
                //     }
                //   }
                // }
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDiamondCutComp;
