import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import AdminToolsService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import traslate from "../../../i18n/translate";

const { confirm } = Modal;

const ClientDataMapping = () => {
  const [loading, setLoading] = useState(false);
  const [clientDataMappingList, setClientDataMapList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortType, setSortType] = useState("asc");
  const [sortColumnName, setSortColumnName] = useState("dealerId");

  useEffect(() => {
    handleGetClientDataMappingList(pageNo, pageSize, "dealerId", "asc");
  }, []);

  function showConfirm(id) {
    confirm({
      title: "Set Default Mapping",
      icon: <ExclamationCircleOutlined />,
      content:
        traslate("Are you Sure By Submit Existing Mapping replace with Default mapping. ?"),
      onOk() {
        handleSetDefaultMapping(id);
      },
      onCancel() { },
    });
  }
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerId";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetClientDataMappingList(
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  const handleGetClientDataMappingList = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    let inputData = {
      pageNumber: pageNo ? pageNo : 1,
      pageSize: pageSize ? pageSize : 10,
      sortOrderCol: sortColumnName || "dealerId",
      sortOrderType: sortOrder || "ASC",
    };
    setLoading(true);
    AdminToolsService.GetClientDataMappingList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message === "Success") {
          //
          setClientDataMapList(responseData.client_dataMapListResponse);
          setTotalCount(responseData.totalRecord);
        } else {
          setClientDataMapList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSetDefaultMapping = (id) => {
    setLoading(true);
    let inputData = {
      dealerID: id,
    };
    AdminToolsService.SetDefaultMapping(inputData)
      .then((response) => {
        var message = response.data.message;
        setLoading(false);
        if (message === "Success") {
          handleGetClientDataMappingList(
            pageNo,
            pageSize,
            sortColumnName,
            sortType
          );
          NotificationManager.success(traslate("Defualt Mapping Created Successfully."));
        } else {
          NotificationManager.error(traslate("Not Set Defualt Mapping."));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="clientdata_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Client Data Maps")}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="clientdata_tbldiv clientDataMapTable">
                <Table
                  className="table-responsive"
                  loading={loading}
                  columns={[
                    {
                      title: traslate("Dealer ID"),
                      dataIndex: "dealerID",
                      sorter: (a, b) => a.dealerID - b.dealerID,
                      width: 120,
                    },
                    {
                      title: traslate("Dealer Company"),
                      dataIndex: "dealerCompany",
                      sorter: (a, b) =>
                        a.dealerCompany.localeCompare(b.dealerCompany),
                    },
                    {
                      title: traslate("Website Address"),
                      dataIndex: "websiteAddress",
                    },
                    {
                      title: traslate("Upload"),
                      dataIndex: "upload",
                      width: 80,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/uploadProductFile"}
                                  state={{
                                    dealerData: item,
                                    key: "ClientDataMapping",
                                  }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100,
                    },
                    {
                      title: traslate("InBound Data Map"),
                      dataIndex: "inbound_datamap",
                      width: 100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/adminDataMapping"}
                                  state={{ key: item.dealerID, columnID: 14 }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100,
                    },
                    {
                      title: traslate("Outgoing Data Map"),
                      dataIndex: "outgoing_datamap",
                      width: 100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/outGoingDataMap"}
                                  state={{ dealerID: item.dealerID }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100,
                    },
                    {
                      title: traslate("OutBound Sync"),
                      dataIndex: "outbound_sync",
                      width: 100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/outBoundSync"}
                                  state={{ dealerID: item.dealerID }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100,
                    },
                    {
                      title: traslate("Set Default Mapping"),
                      dataIndex: "setdefault_mapping",
                      width: 120,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <Button
                              className="primary-btn"
                              onClick={(e) => {
                                showConfirm(item.dealerID);
                              }}
                            >
                              {traslate("Submit")}
                            </Button>
                          </React.Fragment>
                        );
                      },
                      width: 120,
                    },
                  ]}
                  onChange={(pagination, filters, sorters) => {
                    document
                      .getElementsByClassName("content__area")[0]
                      .scroll(0, 0);
                    handleTableASCDES(pagination, filters, sorters);
                  }}
                  dataSource={clientDataMappingList}
                  pagination={{
                    total: totalCount,
                    pageNo: pageNo,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                  }}
                  scroll={{ y: 800 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientDataMapping;
