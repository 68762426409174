import { httpApps } from "../helper/http-common";
class DiamondLinksNavigationDataService {
    GetNavigationDetails(inputData) {
        return httpApps.post("/DiamondLink/GetNavigationDetails", inputData);
    }
    UpdateDiamondLinkNavigationDetails(inputData) {
        return httpApps.post("/DiamondLink/UpdateDiamondLinkNavigationDetails", inputData);
    }
}

export default new DiamondLinksNavigationDataService();
