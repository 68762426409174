import React, { useState, useEffect, useRef } from "react";
import { Table, Spin } from "antd";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import GlobalDiamondReportService from "../../../services/reports-globalDiamond-service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;

const DiamondVendorBasedWishListReport = () => {
  const DiamondCutChartRef = useRef();
  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
  };
  /*Initial State for Binding Ends*/
  /*React Binding Starts*/
  const [TableForAllList, setTableForAllList] = useState([]);
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TableForAllLoading, setTableForAllLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondCutGradeSearchesLabels, setdiamondCutGradeSearchesLabels] =
    useState([]);
  const [DiamondCutGradeSearchesList, setDiamondCutGradeSearchesList] =
    useState([]);
  const [DiamondCutGradeColorData, setDiamondCutGradeColorData] = useState([]);
  const [DiamondCutGradeYAxisList, setDiamondCutGradeYAxisList] = useState([]);
  const [TableCutGradeValue, setTableCutGradeValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortType, setSortType] = useState("desc");
  const [sortColumnName, setSortColumnName] = useState("AK.Date");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (TableCutGradeValue) {
      handleGetDiamondReportForTableChartAll(
        dealerId,
        forDealer,
        1,
        10,
        "AK.Date",
        "asc"
      );
      setSortColumnName("AK.Date");
      setSortType("desc");
      setPageNo(1);
      setPageSize(10);
    }
  }, [TableCutGradeValue]);

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      GlobalDiamondReportService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }

            handleLoadChartReports(id, forDealer);
            handleGetDiamondReportForTableChartAll(
              id,
              forDealer,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer.toString(),
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2016-03-07",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2023-04-06",
        selectedDiamondValue: "All",
        addToType: "AddToWishlist",
      };
      setLoadChartReportsLoading(true);

      ReportsDiamondService.AddToCartOrWishlistChartColunmData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondCutGradeSearchesList = responseData.VendorBasedData;
          var TotalDiamondClicks =
            responseData.TotalDiamondClicks[0].TotalClickCnt;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setTotalDiamondClicks(TotalDiamondClicks);
              setDiamondCutGradeSearchesList(DiamondCutGradeSearchesList);
              var DiamondCutGradeSearchesLabelArr =
                DiamondCutGradeSearchesList.map((item) => {
                  return item.VendorName;
                });
              setdiamondCutGradeSearchesLabels(DiamondCutGradeSearchesLabelArr);
              var DiamondCutGradeYAxisList = DiamondCutGradeSearchesList.map(
                (item) => {
                  return item.CNT;
                }
              );
              var DiamondCutGradeColorList = DiamondCutGradeSearchesList.map(
                (item) => {
                  return item.VendorName;
                }
              );
              setDiamondCutGradeYAxisList(DiamondCutGradeYAxisList);
              diamondCutGradeColorHandler(DiamondCutGradeColorList);
              setKey(!key);
            }
          } else {
            setTotalCount(0);

            setDiamondCutGradeSearchesList([]);
            setDiamondCutGradeYAxisList([]);
          }
        })
        .catch((error) => {
          setTotalCount(0);

          setDiamondCutGradeSearchesList([]);
          setDiamondCutGradeYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondCutGradeSearchesLabels([]);
        });
    } catch (error) {
      setTotalCount(0);

      setDiamondCutGradeSearchesList([]);
      setDiamondCutGradeYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondCutGradeSearchesLabels([]);
    }
  };
  const handleGetDiamondReportForTableChartAll = (
    dealerId,
    forDealer,
    pageNumber,
    pageSiz,
    sortColumn,
    sorttype
  ) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2016-03-12",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2023-04-11",
        clarity: "",
        cutGrade: "",
        shape: "",
        color: "",
        size: "",
        certificate: "",
        selectedDiamondValue: "All",
        pageNumber: pageNumber ? pageNumber : pageNo,
        pageSize: pageSiz ? pageSiz : pageNo,
        sortOrderCol: sortColumn ? sortColumn : sortColumnName,
        sortOrderType: sorttype ? sorttype : sortType,
        addToType: "AddToWishlist",
        price: "",
        price1: "",
        price2: "",
        vendorIds: TableCutGradeValue ? TableCutGradeValue.toString() : "",
      };

      setTableForAllLoading(true);

      ReportsDiamondService.AddToCartOrWishlistReportForTableChart(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var TableForAllList = responseData.tableChartList;
          var TotalEventsCount = responseData.totalCount;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setTotalCount(TotalEventsCount);
              setTableForAllList(TableForAllList);
              setTableForAllLoading(false);
            }
          } else {
            setTotalCount(0);
            setTableForAllList([]);
            setTableForAllLoading(false);
          }
        })
        .catch((error) => {
          setTotalCount(0);
          setTableForAllList([]);
          setTableForAllLoading(false);
        });
    } catch (error) {
      setTotalCount(0);
      setTableForAllList([]);
      setTableForAllLoading(false);
    }
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "DESC";
      column = "AK.Date";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*API Binding Ends*/
  const diamondCutGradeColorHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setDiamondCutGradeColorData(color);
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };
  /*DatePicker Binding Ends*/

  const handleApplyFilter = () => {
    handleLoadChartReports(dealerId, forDealer);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      1,
      10,
      sortColumnName,
      sortType
    );
  };

  return (
    <React.Fragment>
      <div className="form__fields magento__mapping">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Diamond Vendor based Wish List Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          {TableForAllList &&
            TableForAllList.length > 0 &&
            DiamondCutGradeSearchesList.length > 0 && (
              <div className="col-lg-12 col-md-6">
                <div className="input__block">
                  <label>
                    <Spin spinning={LoadChartReportsLoading} size="small">
                      <b>
                        {" "}
                        {traslate("Total Clicks")} :{" "}
                        {TotalDiamondClicks ? TotalDiamondClicks : 0}
                      </b>
                    </Spin>
                  </label>
                </div>
              </div>
            )}

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="chart__section">
            <Spin spinning={LoadChartReportsLoading} size="large">
              {TableForAllList && TableForAllList.length > 0 ? (
                <Bar
                  key={key}
                  onClick={(event) => {
                    const element = getElementAtEvent(
                      DiamondCutChartRef.current,
                      event
                    );
                    if (!element.length) return;

                    const { index } = element[0];
                    setTableCutGradeValue(diamondCutGradeSearchesLabels[index]);
                  }}
                  ref={DiamondCutChartRef}
                  data={{
                    labels: diamondCutGradeSearchesLabels,
                    datasets: [
                      {
                        label: "Count",
                        data: DiamondCutGradeYAxisList,
                        backgroundColor: DiamondCutGradeColorData,
                        borderColor: DiamondCutGradeColorData,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={300}
                  height={300}
                  options={{
                    scales: {
                      x: {
                        title: {
                          color: "rgb(66, 66, 66)",
                          display: false,
                          text: "Certificate",
                          font: {
                            size: 16,
                          },
                        },
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {},
                        },
                      },
                    },
                  }}
                />
              ) : null}
            </Spin>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              loading={TableForAllLoading}
              columns={[
                {
                  title: traslate("UserIPAddress"),
                  dataIndex: "usersIPAddress",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Date"),
                  dataIndex: "date",
                  sorter: (a, b) => {},
                  render: (item, row) => {
                    return moment(row.date).format("MM-DD-YYYY, hh:mm:ss A");
                  },
                },
                {
                  title: traslate("Shape"),
                  dataIndex: "shape",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Size"),
                  dataIndex: "size",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Color"),
                  dataIndex: "color",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Cut"),
                  dataIndex: "cutGrade",
                  sorter: (a, b) => {},
                },

                {
                  title: traslate("Clarity"),
                  dataIndex: "clarity",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Certificate"),
                  dataIndex: "certificate",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Retail Price"),
                  dataIndex: "retailPrice",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Cost"),
                  dataIndex: "cost",
                  sorter: (a, b) => {},
                },
              ]}
              dataSource={TableForAllList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondVendorBasedWishListReport;
