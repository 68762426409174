import React, { useState, useEffect } from "react";
import { Select, Table, Spin } from "antd";
import traslate from "../../../i18n/translate";
import moment from "moment";
import { useSelector } from "react-redux";
import AdminDiamondReportService from "../../../services/reports-AdminDiamond-service";
import AdminDiamondCutComp from "../../AdminChartsGroup/AdminDiamondCutComp";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const DiamondCutReport = () => {
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  const [TableShapeValue, setTableShapeValue] = useState("");
  const [TableClarityValue, setTableClarityValue] = useState("");
  const [TableCutGradeValue, setTableCutGradeValue] = useState("");
  const [TableColorValue, setTableColorValue] = useState("");
  const [TableSizeValue, setTableSizeValue] = useState("");
  const [TableCertificateValue, setTableCertificateValue] = useState("");
  const [tableKey, setTableKey] = useState(true);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  const [forDealer, setForDealer] = useState("");
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [TotalDiamondRecords, setTotalDiamondRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrderCol, setsortOrderCol] = useState("date");
  const [sortOrderType, setsortOrderType] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [DiamondCutTableList, setDiamondCutTableList] = useState([]);
  const [key, setKey] = useState(true);
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };

  const handleCheckDealermembers = (id) => {
    let inputData = {
      dealerID: id,
    };

    AdminDiamondReportService.CheckDealermembers(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var forDealer = responseData.forDealer;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setForDealer(forDealer);
          } else {
            setForDealer("");
          }
          handleLoadChartReports(id, forDealer);
          handleGetDiamondCutList(
            id,
            forDealer,
            pageNo,
            pageSize,
            sortOrderCol,
            sortOrderType
          );
        } else {
          setForDealer("");
        }
      })
      .catch((error) => {
        setForDealer("");
      });
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      type: "Global",
      reportName: "CutGrade",
    };
    setLoadChartReportsLoading(true);

    AdminDiamondReportService.LoadChartReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var TotalDiamondClicks =
          responseData.TotalDiamondClicks[0].TotalClickCnt;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setTotalDiamondClicks(TotalDiamondClicks);
            setKey(!key);
          }
        } else {
          setTotalDiamondClicks([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadChartReportsLoading(false);
        setTotalDiamondClicks([]);
      })
      .finally(() => setLoadChartReportsLoading(false));
  };

  const handleGetDiamondCutList = (
    dealerId,
    forDealer,
    pageNo,
    pageSize,
    sortOrderCol,
    sortOrderType,
    TableCutGradeValue
  ) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      clarity: TableClarityValue,
      cutGrade: TableCutGradeValue ? TableCutGradeValue : "",
      shape: TableShapeValue,
      color: TableColorValue,
      size: TableSizeValue,
      certificate: TableCertificateValue,
      pageNumber: pageNo,
      pageSize: pageSize,
      sortOrderCol: sortOrderCol || "date",
      sortOrderType: sortOrderType || "DESC",
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      reporttype: "Global",
      reportName: "CutGrade",
    };
    setLoading(true);
    setTableCutGradeValue(TableCutGradeValue);
    AdminDiamondReportService.GetAllChartForTableList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setDiamondCutTableList(
            responseData.getDiamondReportForTableChartAll20resp
          );
          setTotalDiamondRecords(responseData.totalRows);
          setKey(!key);
        } else {
          setDiamondCutTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  function handleOnChange(e, isSelect, name) {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e,
    });
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const handleApplyFilter = () => {
    setTableKey(!tableKey);
    handleCheckDealermembers(dealerId);
    setIsApplyFilter(!isApplyFilter);
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    // ;
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setsortOrderType(type);
      setsortOrderCol(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setsortOrderType(type);
      setsortOrderCol(column);
    } else {
      type = "DESC";
      column = "date";
      setsortOrderCol(column);
      setsortOrderType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);

    handleGetDiamondCutList(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type,
      TableCutGradeValue
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Diamond Cut Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>

              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder="All"
                optionFilterProp="children"
                name="SelectedDiamondValue"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
              >
                <Option value="All"> {traslate("All")} </Option>
                <Option value="Mined"> {traslate("Mined Diamond")} </Option>
                <Option value="Lab"> {traslate("Lab Grown Diamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <button
                className="primary-btn mobile-mt-0"
                style={{ marginTop: 25 }}
                onClick={handleApplyFilter}
              >
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          {DiamondCutTableList && DiamondCutTableList.length > 0 && (
            <div className="col-lg-12 col-md-6 mt-4">
              <div className="input__block">
                <label>
                  <Spin spinning={LoadChartReportsLoading} size="small">
                    <b>
                      {" "}
                      {traslate("Total Clicks")} :{" "}
                      {TotalDiamondClicks ? TotalDiamondClicks : 0}
                    </b>
                  </Spin>
                </label>
              </div>
            </div>
          )}
          <div className="col-lg-12">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondCutComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  sortOrderCol={sortOrderCol}
                  sortOrderType={sortOrderType}
                  setPageNo={setPageNo}
                  handleGetDiamondCutList={handleGetDiamondCutList}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              key={tableKey}
              className="pendjewelreq__tbl"
              columns={[
                {
                  title: traslate("UsersIPAddress"),
                  dataIndex: "usersIPAddress",
                  width: 150,
                  sorter: {},
                  render: (item, row) => {
                    return (
                      <div>
                        {row.usersIPAddress === "::1" ? (
                          <span>-</span>
                        ) : (
                          <span>{row.usersIPAddress}</span>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: traslate("Date"),
                  dataIndex: "date",
                  width: 120,
                  sorter: {},
                  render: (row, item) => {
                    //

                    if (Object.keys(item.date).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>
                            {moment(item.date).format("MM-DD-YYYY, hh:mm:ss A")}
                          </span>
                        </React.Fragment>
                      );
                    }
                  },
                },
                {
                  title: traslate("Shape"),
                  dataIndex: "shape",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Size"),
                  dataIndex: "size",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Color"),
                  dataIndex: "color",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Cut"),
                  dataIndex: "cutGrade",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Clarity"),
                  dataIndex: "clarity",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Certificates"),
                  dataIndex: "certificate",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Retail Price"),
                  dataIndex: "retailPrice",
                  width: 120,
                  sorter: {},
                },
                {
                  title: traslate("Cost"),
                  dataIndex: "cost",
                  width: 120,
                  sorter: {},
                },
              ]}
              loading={loading}
              dataSource={DiamondCutTableList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: TotalDiamondRecords,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondCutReport;
