import { httpCatalog } from "../helper/http-common";
import { httpApps } from "../helper/http-common";

class AppsRingBuilderService {
  //Diamond Markup
  GetCurrencyList() {
    return httpCatalog.get("/ProductMarkUp/GetCurrencyList");
  }

  GetRoundingList() {
    return httpCatalog.get("/ProductMarkUp/GetRoundingList");
  }

  GetRingBuilderLagacyDetails(inputdata) {
    return httpApps.post("/RingBuilder/GetRingBuilderLagacyDetails", inputdata);
  }
  UpdateRingBuilderLagacyDetails(inputdata) {
    return httpApps.post(
      "/RingBuilder/UpdateRingBuilderLagacyDetails",
      inputdata
    );
  }

  // Vendor && Archive Vendor
  BindDataRingBuilderVendor(inputData) {
    return httpApps.post("/RingBuilder/BindDataRingBuilderVendor", inputData);
  }

  GetRingBuilderNavigationDetails(inputdata) {
    return httpApps.post(
      "/RingBuilder/GetRingBuilderNavigationDetails",
      inputdata
    );
  }
  UpdateRingBuilderNavigationDetails(inputdata) {
    return httpApps.post(
      "/RingBuilder/UpdateRingBuilderNavigationDetails",
      inputdata
    );
  }

  //options
  GetRingBuilderOptionDetails(inputdata) {
    return httpApps.post("/RingBuilder/GetRingBuilderOptionDetails", inputdata);
  }
  UpdateRingBuilderOptionDetails(inputdata) {
    return httpApps.post(
      "/RingBuilder/UpdateRingBuilderOptionDetails",
      inputdata
    );
  }

  // Settings
  GetRingBuilderDropdownData(inputdata) {
    return httpApps.post("/RingBuilder/GetRingBuilderDropdownData", inputdata);
  }
  GetJewelryList(inputdata) {
    return httpApps.post("/RingBuilder/GetJewelryList", inputdata);
  }
  ManageProductFlag(inputdata) {
    return httpApps.post("/RingBuilder/ManageProductFlag", inputdata);
  }
  OnVendorDropDownChange(inputdata) {
    return httpApps.post("/RingBuilder/OnVendorDropDownChange", inputdata);
  }
  GetJewelryListForActiveDeactive(inputData) {
    return httpApps.post(
      "/RingBuilder/GetJewelryListForActiveDeactive",
      inputData
    );
  }

  // Settings Markup
  GetRingBuilderMarkupLoadData(inputdata) {
    return httpApps.post(
      "/RingBuilder/GetRingBuilderMarkupLoadData",
      inputdata
    );
  }
  SaveRingBuilderSettingMarkup(inputdata) {
    return httpApps.post(
      "/RingBuilder/SaveRingBuilderSettingMarkup",
      inputdata
    );
  }
  RemoveRingBuilderSettingMarkup(inputdata) {
    return httpApps.post(
      "/RingBuilder/RemoveRingBuilderSettingMarkup",
      inputdata
    );
  }
  UpdateAllRingBuilderSettingMarkup(inputdata) {
    return httpApps.post(
      "/RingBuilder/UpdateAllRingBuilderSettingMarkup",
      inputdata
    );
  }

  // Mined Diamond Markup and Lab grow Markup

  GetRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/GetRingBuilderLabDiamondMarkupLoadData",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/GetRingBuilderMinedDiamondMarkupLoadData",
        inputData
      );
    }
  }

  GetMakerRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/GetMakerRingBuilderLabDiamondMarkupLoadData",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/GetMakerRingBuilderMinedDiamondMarkupLoadData",
        inputData
      );
    }
  }

  GetCustomerRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/GetCustomerRingBuilderLabDiamondMarkupLoadData",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/GetCustomerRingBuilderMinedDiamondMarkupLoadData",
        inputData
      );
    }
  }

  SaveRingBuilderMinedDiamondDealerMarkupdata(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/SaveRingBuilderLabDiamondDealerMarkupdata",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/SaveRingBuilderMinedDiamondDealerMarkupdata",
        inputData
      );
    }
  }
  SaveRingBuilderMinedDiamondCustomMarkupdata(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/SaveRingBuilderLabDiamondCustomMarkupdata",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/SaveRingBuilderMinedDiamondCustomMarkupdata",
        inputData
      );
    }
  }
  RemoveRingBuilderMinedDiamondDealerMarkupdata(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/RemoveRingBuilderLabDiamondDealerMarkupdata",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/RemoveRingBuilderMinedDiamondDealerMarkupdata",
        inputData
      );
    }
  }
  RemoveRingBuilderMinedDiamondCustomMarkupdata(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/RemoveRingBuilderLabDiamondCustomMarkupdata",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/RemoveRingBuilderMinedDiamondCustomMarkupdata",
        inputData
      );
    }
  }

  GetAllMinedDiamondCostPriceAndSize(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/GetAllLabDiamondCostPriceAndSize",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/GetAllMinedDiamondCostPriceAndSize",
        inputData
      );
    }
  }
  UpdateAllMinedDiamondCostPriceAndSize(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/UpdateAllLabDiamondCostPriceAndSize",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/UpdateAllMinedDiamondCostPriceAndSize",
        inputData
      );
    }
  }
  UpdateAllRingBuilderMinedDiamondMarkupdata(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/RingBuilder/UpdateAllRingBuilderLabDiamondMarkupdata",
        inputData
      );
    } else {
      return httpApps.post(
        "/RingBuilder/UpdateAllRingBuilderMinedDiamondMarkupdata",
        inputData
      );
    }
  }

  ringbuilderBindDealerList(inputdata) {
    return httpApps.post("/RingBuilder/ringbuilderBindDealerList", inputdata);
  }

  UpdateList_Click_ringbuilder(inputdata) {
    return httpApps.post(
      "/RingBuilder/UpdateList_Click_ringbuilder",
      inputdata
    );
  }

  RingBuilderBindDataCommVendor = (inputData) => {
    return httpApps.post(
      "/RingBuilder/RingBuilderBindDataCommVendor",
      inputData
    );
  };

  RingBuilderShowDataToRightNew = (inputData) => {
    return httpApps.post(
      "/RingBuilder/RingBuilderShowDataToRightNew",
      inputData
    );
  };

  GetPendingRequests = (inputData) => {
    return httpApps.post("/RingBuilder/GetPendingRequests", inputData);
  };

  RingBuilderPendingToApprove = (inputData) => {
    return httpApps.post("/RingBuilder/RingBuilderPendingToApprove", inputData);
  };
  RingBuilderPendingToDisApprove = (inputData) => {
    return httpApps.post(
      "/RingBuilder/RingBuilderPendingToDisApprove",
      inputData
    );
  };
  ManageProductFlagList = (inputData) => {
    return httpApps.post("/RingBuilder/ManageProductFlagList", inputData);
  };

  RingBuilder_BindDataCommVendor = (inputData) => {
    return httpApps.post(
      "/RingBuilder/RingBuilder_BindDataCommVendor",
      inputData
    );
  };
  RingBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "RingBuilder/RingBuilderRequestCertificate",
      inputData
    );
  }
}

export default new AppsRingBuilderService();
