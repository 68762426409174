import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DashboardVendor from "./DashboardVendor";
import DashboardAdminRetailer from "./DashboardAdminRetailer";
import { Spin, Modal } from "antd";
import heartImage from "../../assets/images/heart_image.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Select, Button } from "antd";
import { NotificationManager } from "react-notifications";
/*API imports start*/
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import traslate from "../../i18n/translate";
import Conversations from "../contacts/conversation";
import Appointment from "../dashboard/Appointment";
import Moment from "moment";

/*API imports end*/
const Dashboard = () => {
  const initialCreateAppointmentData = {
    contact: "",
    appointmentCalendar: "",
    time: "",
    day: ""
  };
  const initialValidations = {
    contactVal: "",
    appointmentCalendarVal: "",
    timeVal: "",
    dayVal: ""
  };
  const initialDeleteAppointmentData = {
    appointmentID: "",
    messageID: ""
  };
  const { Option } = Select;
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [loginType, setLoginType] = useState("");
  const [dashBoardFeedVendorList, setDashBoardFeedVendorList] = useState([]);
  const [spinning, setLoading] = useState(false);
  const [dealerId, setDealerId] = useState(0);
  const [recordsCount, setRecordsCount] = useState(50);
  const [visible, setVisible] = useState(false);
  const [createAppointmentData, setCreateAppointmentData] = useState(
    initialCreateAppointmentData
  );
  const [validations, setValidations] = useState(initialValidations);
  const [editValidations, setEditValidations] = useState({
    timeVal: "",
    dayVal: ""
  });

  const [customerList, setCustomerList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editAppointmentData, setEditAppointmentData] = useState({
    day: "",
    time: "",
    customerId: "",
    appid: "",
    appType: ""
  });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteAppointmentData, setDeleteAppointmentData] = useState(
    initialDeleteAppointmentData
  );
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [timeList, setTimeList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [datesList, setDatesList] = useState([]);
  const [pageNameType, setpageNameType] = useState("Dashboard");
  const calendarList = [
    {
      name: "Engagement",
      value: "1"
    },
    {
      name: "Wedding Bands",
      value: "3"
    },
    {
      name: "Try On My Favorites",
      value: "5"
    },
    {
      name: "Repair",
      value: "2"
    },
    {
      name: "Appraisal",
      value: "4"
    },
    {
      name: "Cleaning",
      value: "6"
    },
    {
      name: "Schedule A Viewing",
      value: "7"
    }
  ];
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setLoginType(loginDetials.responseData.dealerMemberTypeName);
        setDealerId(loginDetials.responseData.dealerId);
        if (loginDetials.responseData.dealerMemberTypeName == "Admin") {
        } else if (
          loginDetials.responseData.dealerMemberTypeName == "Retailer"
        ) {
        } else if (
          loginDetials.responseData.dealerMemberTypeName == "Vendor" ||
          loginDetials.responseData.dealerMemberTypeName == "VendorDiamond"
        ) {
          handleGetDashBoardFeedVendor(
            loginDetials.responseData.dealerId,
            recordsCount
          );
        }
      }
    }
  }, [loginDetials]);

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setCreateAppointmentData({
        ...createAppointmentData,
        [name]: e
      });

      if (name == "contact") {
        setValidations((prevdata) => ({
          ...prevdata,
          contactVal: ""
        }));
      }
      if (name == "appointmentCalendar") {
        setValidations((prevdata) => ({
          ...prevdata,
          appointmentCalendarVal: ""
        }));
      }
      if (name == "day") {
        handleBindStoreHoursDropDown(e);
        setValidations((prevdata) => ({
          ...prevdata,
          dayVal: ""
        }));
      }
      if (name == "time") {
        setValidations((prevdata) => ({
          ...prevdata,
          timeVal: ""
        }));
      }
    } else {
      setCreateAppointmentData({
        ...createAppointmentData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleOnChangeEditData = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditAppointmentData({
        ...editAppointmentData,
        [name]: e
      });
      if (name == "day") {
        handleBindStoreHoursDropDown(e);
        setEditValidations((prevdata) => ({
          ...prevdata,
          dayVal: ""
        }));
      }
    } else {
      setEditAppointmentData({
        ...editAppointmentData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleValidation = () => {
    const { contact, appointmentCalendar, day, time } = createAppointmentData;

    const validations = {
      contactVal: "",
      appointmentCalendarVal: "",
      timeVal: "",
      dayVal: ""
    };
    let isValid = true;

    if (!contact.trim()) {
      isValid = false;
      validations.contactVal = "Please Select Contact.";
    }
    if (!appointmentCalendar.trim()) {
      isValid = false;
      validations.appointmentCalendarVal = "Please Select Calendar.";
    }
    if (!time.trim()) {
      isValid = false;
      validations.timeVal = "Please Select Time.";
    }
    if (!day.trim()) {
      isValid = false;
      validations.dayVal = "Please Select Day.";
    }

    setValidations(validations);

    return isValid;
  };

  const handleEditValidation = () => {
    const { day, time } = editAppointmentData;

    const editValidations = {
      timeVal: "",
      dayVal: ""
    };
    let isValid = true;

    if (!time.trim()) {
      isValid = false;
      editValidations.timeVal = "Time is Compulsory.";
    }
    if (!day.trim()) {
      isValid = false;
      editValidations.dayVal = "Day is Compulsory.";
    }

    setEditValidations(editValidations);

    return isValid;
  };

  const handleCancel = () => {
    setVisible(false);
    setValidations(initialValidations);
    setCreateAppointmentData(initialCreateAppointmentData);
  };

  const handleCancelEditModal = () => {
    setEditModalVisible(false);
    setValidations(initialValidations);
    setCreateAppointmentData(initialCreateAppointmentData);
  };

  const handleGetDashBoardFeedVendor = async (id, recordsCount) => {
    setLoading(true);
    try {
      let inputData = {
        dealerId: id.toString(),
        pageNo: "1",
        recordOnPage: recordsCount.toString()
      };
      await AdminDashboardDataService.GetDashBoardFeedVendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setDashBoardFeedVendorList(responseData);
              var tempDates = [
                ...new Set(responseData.map(({ formatedDate }) => formatedDate))
              ];
              setDatesList(tempDates);
              if (recordsCount > responseData.length) {
                setLoading(false);
                return;
              }
              setLoading(false);
            } else {
              setDatesList([]);
              setDashBoardFeedVendorList([]);
            }
          } else {
            setDatesList([]);
            setDashBoardFeedVendorList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*API binding Ends*/

  const handleCancelDeleteModal = () => {
    setDeleteAppointmentData(initialDeleteAppointmentData);
    setDeleteModalVisible(false);
  };

  const handleSaveAppointment = async () => {
    var isValid = handleValidation();

    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        appid: "",
        appType: createAppointmentData.appointmentCalendar.toString(),
        message: "",
        appDate: createAppointmentData.day.toString(),
        appTime: createAppointmentData.time.toString(),
        customerId: createAppointmentData.contact.toString(),
        dealerId: dealerId.toString()
      };
      await AdminDashboardDataService.SaveAppointment(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setVisible(false);
            NotificationManager.success("Appointment Added Successfully.");
          } else {
            setVisible(false);
            NotificationManager.error("Appointment Not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
          setVisible(false);
        });
    } catch (error) {
      console.log(error);
      setVisible(false);
    }
  };

  const handleUpdateAppointment = async () => {
    var isValid = handleEditValidation();
    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        appid: editAppointmentData.appid,
        appType: editAppointmentData.appType.toString(),
        message: "",
        appDate: editAppointmentData.day.toString(),
        appTime: editAppointmentData.time.toString(),
        customerId: editAppointmentData.customerId.toString(),
        dealerId: dealerId.toString()
      };
      await AdminDashboardDataService.UpdateAppointment(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setEditModalVisible(false);
            NotificationManager.success("Appointment Updated Successfully.");
          } else {
            setEditModalVisible(false);
            NotificationManager.error("Appointment Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setEditModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setEditModalVisible(false);
    }
  };

  const handleRemoveAppointment = async () => {
    try {
      let inputData = {
        appointmentID: deleteAppointmentData.appointmentID,
        messageID: deleteAppointmentData.messageID
      };
      setDeleteLoading(true);
      await AdminDashboardDataService.RemoveAppointment(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Appointment Deleted Successfully.");
            handleCancelDeleteModal();
            setDeleteLoading(false);
          } else {
            NotificationManager.error("Appointment Not Deleted.");
            setDeleteLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setDeleteLoading(false);
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const handleBindStoreHoursDropDown = async (date) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        sTime: "8 AM",
        eTime: "12 PM",
        dtDate: date ? date : "",
        appTime: ""
      };

      await AdminDashboardDataService.BindStoreHoursDropDown(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setTimeList(responseData);
          } else {
            setTimeList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const label2 = (item) => (
    <>
      <span>{traslate("Customer added")}</span>
      <a className="notifications-link-sp">
        <span className="msg_nametxt">
          {item?.productName ? " " + item?.productName : ""}
        </span>
      </a>{" "}
      {traslate("as favorite through")}{" "}
      <Link
        to={"/retailerProfile"}
        state={{
          key: "Profile",
          id: item?.retailerID
        }}
        className="notifications-link-sp"
      >
        <span className="msg_nametxt">{item?.eventTitle}</span>
      </Link>
    </>
  );

  const label1 = (item) => (
    <>
      <span>{traslate(item?.actionTime)}</span>
      <Link
        to={"/retailerProfile"}
        state={{
          key: "Profile",
          id: item?.retailerID
        }}
        className="notifications-link-sp"
      >
        <span className="msg_nametxt">{item?.eventTitle}</span>
      </Link>{" "}
      {traslate("has sent a")}{" "}
      <Link
        to=""
        // to={"/retailerProfile"}
        // state={{
        //   key: "Profile",
        //   id: item.retailerID,
        // }}
        className="notifications-link-sp"
      >
        <span className="msg_nametxt">{traslate("Request")}</span>
      </Link>{" "}
      {traslate("to access to your inventory.")}
    </>
  );

  const renderControlComponent = (controlTypeId, item) => {
    switch (controlTypeId) {
      case "1":
        return label1(item);
      case "2":
        return label2(item);
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div className="catalog__list dashbo_maindiv">
        {/* Top Nav Starts*/}
        {(loginType == "Admin" ||
          loginType == "Retailer" ||
          loginType == "VendorDiamond" ||
          loginType == "Vendor") && <></>}
        {/* Top Nav Ends*/}

        <div className="hello">
          {loginType == "Vendor" || loginType == "VendorDiamond" ? (
            <DashboardVendor
              setpageNameType={setpageNameType}
              pageNameType={pageNameType}
            />
          ) : null}
          {(loginType == "Admin" ||
            loginType == "Retailer" ||
            loginType == "") && (
            <DashboardAdminRetailer pageNameType={pageNameType} />
          )}

          {/* Notifications Section Starts */}

          {loginType == "Vendor" && pageNameType == "Dashboard" && (
            <div className="notifications-section">
              <div className="col-md-12">
                <h3 className="subheading"> {traslate("Notifications")} </h3>
              </div>
              <div className="col-md-12">
                <Spin spinning={spinning} size="large">
                  <div className="Notif_section">
                    {datesList &&
                      datesList.map((header, index) => {
                        return (
                          <div
                            className="msg_div"
                            key={`${header.toString}+${index}`}
                          >
                            <div>
                              <p className="msg_date">
                                {Moment(header).format("dddd, MMMM DD, YYYY ")}
                              </p>
                            </div>
                            <React.Fragment>
                              {dashBoardFeedVendorList &&
                                dashBoardFeedVendorList
                                  .filter((x) => x.formatedDate == header)
                                  .map((item, i) => {
                                    return (
                                      <div
                                        key={`${item.retailerID}-${i}`}
                                        className="msg_divtext"
                                      >
                                        <span className="msg_txt">
                                          <img src={heartImage} alt="abc" />{" "}
                                          {renderControlComponent(
                                            item?.actiontype,
                                            item
                                          )}
                                        </span>
                                        <span className="msg_timetxt">
                                          {item?.actionTime}
                                        </span>
                                      </div>
                                    );
                                  })}
                            </React.Fragment>
                          </div>
                        );
                      })}
                  </div>
                </Spin>
              </div>
            </div>
          )}

          {/* Notifications Section Ends */}
        </div>
        {pageNameType === "conversation" && (
          <Conversations pageNameType={pageNameType} />
        )}

        {pageNameType === "AppointmentsOnly" && loginType === "Admin" && (
          <Appointment pageNameType={pageNameType} />
        )}

        <Modal
          title={traslate("Create Appointment")}
          className="modal__tradeshow"
          centered
          visible={visible}
          onCancel={() => handleCancel()}
          width={500}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleSaveAppointment}>
              {traslate("Submit")}
            </Button>
          ]}
        >
          <div className="col-lg-12">
            <div className="modal__body">
              <div className="col-lg-12">
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div class="col-lg-12"></div>
                    <div className="col-lg-12">
                      <div className="input__block">
                        <label> {traslate("Contact")} </label>
                        <Select
                          name="vendor"
                          className={
                            validations.contactVal
                              ? "border__red"
                              : "border__grey"
                          }
                          showSearch
                          placeholder="Select Start Time"
                          optionFilterProp="children"
                          value={createAppointmentData.contact}
                          onChange={(e) =>
                            handleOnChange(e, "select", "contact")
                          }
                        >
                          <Option value="">Select Contact</Option>
                          {customerList &&
                            customerList.map((item, i) => {
                              return (
                                <Option
                                  key={`${item.fullName}+${i}`}
                                  value={item.cust_Id}
                                >
                                  {item.fullName}
                                </Option>
                              );
                            })}
                        </Select>
                        {validations.contactVal && (
                          <p className="error-color-red">
                            {validations.contactVal}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input__block">
                        <label>{traslate("Calendar")}</label>
                        <Select
                          name="calender"
                          className={
                            validations.appointmentCalendarVal
                              ? "border__red"
                              : "border__grey"
                          }
                          showSearch
                          placeholder="Select Start Time"
                          optionFilterProp="children"
                          value={createAppointmentData.appointmentCalendar}
                          onChange={(e) =>
                            handleOnChange(e, "select", "appointmentCalendar")
                          }
                        >
                          <Option value="">Select Calendar</Option>
                          {calendarList &&
                            calendarList.map((item, i) => {
                              return (
                                <Option
                                  value={item.value}
                                  key={`${item.value}_${i}`}
                                >
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                        {validations.appointmentCalendarVal && (
                          <p className="error-color-red">
                            {validations.appointmentCalendarVal}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input__block">
                        <label>{traslate("Day")}</label>
                        <Select
                          name="calender"
                          className={
                            validations.dayVal ? "border__red" : "border__grey"
                          }
                          showSearch
                          placeholder="Select Start Time"
                          optionFilterProp="children"
                          value={createAppointmentData.day}
                          onChange={(e) => handleOnChange(e, "select", "day")}
                        >
                          {dayList &&
                            dayList.map((item, i) => {
                              return (
                                <Option
                                  value={item.dtdate}
                                  key={item.dtdate + i}
                                >
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                        {validations.dayVal && (
                          <p className="error-color-red">
                            {validations.dayVal}
                          </p>
                        )}
                      </div>
                    </div>
                    {createAppointmentData.day != "" ? (
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{traslate("Time")}</label>
                          <Select
                            name="calender"
                            className={
                              validations.timeVal
                                ? "border__red"
                                : "border__grey"
                            }
                            showSearch
                            placeholder="Select Start Time"
                            optionFilterProp="children"
                            value={createAppointmentData.time}
                            onChange={(e) =>
                              handleOnChange(e, "select", "time")
                            }
                          >
                            {timeList &&
                              timeList.map((item, i) => {
                                return (
                                  <Option
                                    value={item.name}
                                    key={`${item.name}=${i}`}
                                  >
                                    {item.value}
                                  </Option>
                                );
                              })}
                          </Select>

                          {validations.timeVal && (
                            <p className="error-color-red">
                              {validations.timeVal}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          title={traslate("Edit Appointment")}
          className="modal__tradeshow"
          centered
          visible={editModalVisible}
          onOk={handleUpdateAppointment}
          onCancel={() => handleCancelEditModal()}
          width={500}
          footer={[
            <Button key="back" onClick={handleCancelEditModal}>
              {traslate("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleUpdateAppointment}
            >
              {traslate("Submit")}
            </Button>
          ]}
        >
          <div className="col-lg-12">
            <div className="modal__body">
              <div className="col-lg-12">
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div class="col-lg-12"></div>
                    <div className="col-lg-12">
                      <div className="input__block">
                        <label>{traslate("Day")}</label>
                        <Select
                          name="calender"
                          className={
                            editValidations.dayVal
                              ? "border__red"
                              : "border__grey"
                          }
                          showSearch
                          placeholder="Select Start Time"
                          optionFilterProp="children"
                          value={editAppointmentData.day}
                          onChange={(e) =>
                            handleOnChangeEditData(e, "select", "day")
                          }
                        >
                          {dayList &&
                            dayList.map((item, i) => {
                              return (
                                <Option
                                  value={item.dtdate}
                                  key={`${item.dtdat}${i}`}
                                >
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                        {editValidations.dayVal && (
                          <p className="error-color-red">
                            {editValidations.dayVal}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="input__block">
                        <label>{traslate("Time")}</label>
                        <Select
                          name="calender"
                          className={
                            editValidations.timeVal
                              ? "border__red"
                              : "border__grey"
                          }
                          showSearch
                          placeholder="Select Start Time"
                          optionFilterProp="children"
                          value={editAppointmentData.time}
                          onChange={(e) =>
                            handleOnChangeEditData(e, "select", "time")
                          }
                        >
                          {timeList &&
                            timeList.map((item, i) => {
                              return (
                                <Option value={item.name} key={item.name + i}>
                                  {item.value}
                                </Option>
                              );
                            })}
                        </Select>

                        {editValidations.timeVal && (
                          <p className="error-color-red">
                            {editValidations.timeVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className="datamapping__modal"
          width={400}
          centered
          visible={deleteModalVisible}
          onCancel={handleCancelDeleteModal}
          footer={[
            <Button onClick={handleCancelDeleteModal}>
              {traslate("Cancel")}
            </Button>,

            <Button
              loading={deleteLoading}
              type="primary"
              onClick={handleRemoveAppointment}
            >
              {traslate("Remove This Appointment")}
            </Button>
          ]}
        >
          <div className="form__fields row border-0 p-0">
            <div className="col-lg-12 text-center">
              <p>
                {" "}
                {traslate(
                  "Are your sure you want to remove this appointment?"
                )}{" "}
              </p>
              <h5>
                {" "}
                {traslate("THIS CAN NOT BE UNDONE")} ...{" "}
                {traslate("THERE IS NO UNDO")}
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
