export const CONTACT_CONVERSATION_COUNT = "CONVERSATION_COUNT";
export const CONTACT_APPOINTMENT_COUNT = "APPOINTMENT_COUNT";
export const CONTACT_CUSTOMER_COUNT = "CUSTOMER_COUNT";
export const CONTACT_PAGE_NAME_TYPE = "PAGE_NAME_TYPE";
export const DASHBOARD_PAGE_NAME_TYPE = "DASHBOARD_PAGE_NAME_TYPE";
export const TOGGLE_SIDEMENU_DASHBOARD_DATA = "TOGGLE_SIDEMENU_DASHBOARD_DATA";
export const VENDOR_CONVERSATION_COUNT = "VENDOR_CONVERSATION_COUNT";
export const VENDOR_APPOINTMENT_COUNT = "VENDOR_APPOINTMENT_COUNT";
export const VENDOR_CONVERSATION_COUNT_UNREAD =
  "VENDOR_CONVERSATION_COUNT_UNREAD";
export const VENDOR_CONVERSATION_COUNT_SENT = "VENDOR_CONVERSATION_COUNT_SENT";
export const SET_JEWELRY_TAB = "SET_JEWELRY_TAB";
export const DEALERID_ADMIN_DATA_MAPPING = "DEALERID_ADMIN_DATA_MAPPING";
export const COLUMNID_ADMIN_DATA_MAPPING = "COLUMNID_ADMIN_DATA_MAPPING";
export const PROFILE_ID = "PROFILE_ID";
export const PROFILE_TAB = "PROFILE_TAB";
