import { LOCALES } from "../locales";

export default {
  [LOCALES.ARABIC]: {
    ["Tools"]: "أدوات",
    ["Administration Tools"]: "أدوات الإدارة",
    ["Application Permission"]: "إذن التطبيق",
    ["Report Permissions"]: "أذونات التقرير",
    ["Client Data Maps"]: "خرائط بيانات العميل",
    ["Client Status"]: "حالة العميل",
    ["Create New Client"]: "إنشاء عميل جديد",
    ["3rd Party Diamond Imports"]: "استيراد الماس من جهات خارجية",
    ["SOLR Core Client"]: "عميل SOLR الأساسي",
    ["Vendor Rating"]: "تقييم البائع",
    ["Limits"]: "الحدود",
    ["Legacy"]: "إرث",
    ["Console Upload"]: "تحميل وحدة التحكم",
    ["Open Source Mapping"]: "رسم الخرائط مفتوحة المصدر",
    ["Pending Syncs"]: "المزامنة المعلقة",
    ["Transfer ZZ.Gemfind"]: "نقل ZZ.Gemfind",
    ["Login Preferences"]: "تفضيلات تسجيل الدخول",
    ["Retailer Locator"]: "محدد موقع التاجر",
    ["Block Designers For Demo"]: "مصممي الكتل للعرض التوضيحي",
    ["Settings"]: "إعدادات",
    ["Setting MarkUp"]: "إعداد العلامات",
    ["Permissions"]: "الأذونات",
    ["Email Template"]: "قالب البريد الإلكتروني",
    ["Data Maps"]: "خرائط البيانات",
    ["Metal Market"]: "سوق المعادن",
    ["Trade Shows"]: "المعارض التجارية",
    ["Add Color"]: "إضافة اللون",
    ["Api Access Token"]: "رمز الوصول إلى واجهة برمجة التطبيقات",
    ["Campaigns"]: "الحملات",
    ["Mailing List"]: "قائمة البريد",
    ["Compose"]: "التأليف",
    ["Report"]: "تقرير",
    ["Global Events"]: "الأحداث العالمية",
    ["Syncs"]: "المزامنة",
    ["Tools Reports"]: "أدوات التقارير",
    ["Clients Reports"]: "تقارير العملاء",
    ["Permission Reports"]: "تقارير الأذونات",
    ["Data Related"]: "البيانات ذات الصلة",
    ["Jewelry Log Reports"]: "تقارير سجل المجوهرات",
    ["Diamond Report"]: "تقرير الماس",
    ["Diamond Clicks"]: "نقرات الماس",
    ["Extended Diamond Chart"]: "مخطط الماس الممتد",
    ["Diamond Clarity Report"]: "تقرير وضوح الماس",
    ["Diamond Color Report"]: "تقرير لون الماس",
    ["Diamond Cut Report"]: "تقرير قطع الماس",
    ["Diamond Shape Report"]: "تقرير شكل الماس",
    ["Diamond Certificate Report"]: "تقرير شهادة الماس",
    ["Diamond Size Report"]: "تقرير حجم الماس",
    ["Jewelry Report"]: "تقرير المجوهرات",
    ["Jewelry Chart Report"]: "تقرير مخطط المجوهرات",
    ["Jewelry Category Report"]: "تقرير فئة المجوهرات",
    ["Jewelry Metal Type Report"]: "تقرير عن نوع المعدن المستخدم في المجوهرات",
    ["Jewelry Price Report"]: "تقرير أسعار المجوهرات",
    ["Jewelry Retailer Clicks Report"]: "تقرير نقرات بائعي المجوهرات",
    ["Jewelry Vendor Clicks Report"]: "تقرير نقرات بائعي المجوهرات",
    ["Catalog"]: "الكتالوج",
    ["Diamond"]: "الماس",
    ["Mined Diamond"]: "الماس المستخرج",
    ["Color Diamond"]: "الماس الملون",
    ["Lab Diamond"]: "الماس المختبري",
    ["Fancy Diamond"]: "الماس الفاخر",
    ["Default Filter Setting"]: "إعداد الفلتر الافتراضي",
    ["Upload History"]: "سجل التحميل",
    ["Add Individual Diamond"]: "إضافة الماس فردي",
    ["Add Diamond"]: "إضافة الماس",
    ["Upload Diamond File"]: "تحميل ملف الماس",
    ["Diamond Mapping"]: "رسم خرائط الماس",
    ["Manage FTP Credentials"]: "إدارة بيانات اعتماد FTP",
    ["Download Product File"]: "تنزيل ملف المنتج",
    ["Jewelry"]: "مجوهرات",
    ["Catalog List"]: "قائمة الكتالوج",
    ["View My Product"]: "عرض المنتج الخاص بي",
    ["Add Product"]: "إضافة المنتج",
    ["Edit Product"]: "تعديل المنتج",
    ["Upload Product File"]: "تحميل ملف المنتج",
    ["Manage Collection"]: "إدارة المجموعة",
    ["Product Markup"]: "ترميز المنتج",
    ["Jewelry Markup"]: "أسعار المجوهرات",
    ["Add Collection"]: "إضافة المجموعة",
    ["Data Mapping"]: "تعيين البيانات",
    ["Essential"]: "ضروري",
    ["Item Identity"]: "هوية العنصر",
    ["Configurable Products"]: "المنتجات القابلة للتكوين",
    ["Item Details"]: "تفاصيل العنصر",
    ["Item Pricing"]: "تسعير العناصر",
    ["Relational Pricing"]: "التسعير العلائقي",
    ["Cost Basis Pricing"]: "التسعير على أساس التكلفة",
    ["Item-Promo Specials"]: "عروض خاصة على المنتجات",
    ["Item Media"]: "عنصر الوسائط",
    ["Item Sizing"]: "تحديد حجم العنصر",
    ["Gemstone Details"]: "تفاصيل الأحجار الكريمة",
    ["Semi-Mounts & Ring Builder"]: "صانع الحلقات والتركيبات شبه الدائرية",
    ["Watch Details"]: "تفاصيل المشاهدة",
    ["Additional Details"]: "تفاصيل إضافية",
    ["Purchase Order"]: "أمر شراء",
    ["Purchase Order History"]: "سجل طلبات الشراء",
    ["Update Purchase Order"]: "تحديث طلب الشراء",
    ["Marketing"]: "تسويق",
    ["Upload Marketing Material"]: "تحميل المواد التسويقية",
    ["Appointment"]: "ميعاد",
    ["Apps"]: "التطبيقات",
    ["Diamond Links"]: "روابط الماس",
    ["Application Preview"]: "معاينة التطبيق",
    ["All Departments"]: "جميع الأقسام",
    ["Dealers"]: "التجار",
    ["Mined Diamond MarkUp"]: "رفع أسعار الماس المستخرج",
    ["Fancy Color MarkUp"]: "علامات الألوان الفاخرة",
    ["Lab Grown MarkUp"]: "مارك اب المزروع في المختبر",
    ["Available Filter Settings"]: "إعدادات الفلتر المتاحة",
    ["Initial Filter Settings"]: "إعدادات الفلتر الأولية",
    ["Set Initial Filter Settings"]: "تعيين إعدادات الفلتر الأولية",
    ["Options"]: "خيارات",
    ["Option"]: "خيار",
    ["Shipping"]: "شحن",
    ["Navigation"]: "ملاحة",
    ["Shopping Cart Integration"]: "تكامل عربة التسوق",
    ["Shopping Cart Configuration"]: "تكوين عربة التسوق",
    ["API Endpoint and Authentication"]:
      "نقطة نهاية واجهة برمجة التطبيقات والمصادقة",
    ["API Endpoint Authentication"]: "مصادقة نقطة نهاية API",
    ["Ring Builder"]: "صانع الخواتم",
    ["Vendors"]: "الباعة",
    ["Popular Vendors"]: "الباعة المشهورين",
    ["Archived Vendors"]: "البائعين المؤرشفة",
    ["Archived Vendor"]: "البائع المؤرشف",
    ["Manage Vendors"]: "إدارة البائعين",
    ["Setting Vendors"]: "ضبط البائعين",
    ["Avaliable Filter Settings"]: "إعدادات الفلتر المتاحة",
    ["Diamond Markup"]: "علامة الماس",
    ["Setting Markup"]: "إعداد العلامات",
    ["Master Link"]: "الرابط الرئيسي",
    ["E-mail Templates"]: "قوالب البريد الإلكتروني",
    ["Stud Builder"]: "بناء العوارض الخشبية",
    ["Shopping Cart Integrations"]: "تكاملات عربة التسوق",
    ["Website Sync"]: "مزامنة الموقع",
    ["Sync History"]: "مزامنة التاريخ",
    ["OutBound Mapping"]: "رسم الخرائط الصادرة",
    ["FTP Details"]: "تفاصيل FTP",
    ["Watch Customizer"]: "مُخصّص الساعة",
    ["Time Pieces"]: "قطع الوقت",
    ["Pendant Builder"]: "صانع المعلقات",
    ["Search Feature"]: "ميزة البحث",
    ["Diamond Search"]: "البحث عن الماس",
    ["Mined Diamond Search"]: "البحث عن الماس المستخرج",
    ["Color Diamond Search"]: "البحث عن الماس الملون",
    ["Lab Diamond Search"]: "بحث عن الماس في المختبر",
    ["Watches Search"]: "البحث عن الساعات",
    ["Jewelry Search"]: "البحث عن المجوهرات",
    ["Community"]: "مجتمع",
    ["Vendor Manager"]: "مدير البائعين",
    ["My Vendors"]: "بائعاتي",
    ["Network"]: "شبكة",
    ["File should be less than 2Gb."]:
      "يجب أن يكون حجم الملف أقل من 2 جيجابايت.",
    ["Out Of Network"]: "خارج الشبكة",
    ["New"]: "جديد",
    ["New Vendor"]: "بائع جديد",
    ["Recently Updated"]: "تم التحديث مؤخرا",
    ["Invite Your Vendors"]: "قم بدعوة البائعين",
    ["Invite Your Retailers"]: "قم بدعوة تجار التجزئة لديك",
    ["Popular"]: "بائع شعبي",
    ["Archived"]: "مؤرشفة",
    ["Pending Requests"]: "الطلبات المعلقة",
    ["Retailer"]: "بائع التجزئة",
    ["Jewelry Data & Permissions"]: "بيانات المجوهرات والأذونات",
    ["Pending Jewelry Request"]: "طلب مجوهرات معلق",
    ["Diamond Data & Permission"]: "بيانات الماس وإذنه",
    ["Pending Diamond Request"]: "طلب الماس معلق",
    ["Ring Builder Retailer & Permission"]: "تاجر التجزئة وترخيص بناء الخواتم",
    ["Pending Ring Builder Request"]: "طلب بناء حلقة معلقة",
    ["Retailer Profile"]: "الملف الشخصي للتاجر",
    ["Jewelry Chart Reports"]: "تقارير مخططات المجوهرات",
    ["Help"]: "يساعد",
    ["Report An Issue"]: "الإبلاغ عن مشكلة",
    ["Suggest A New Feature"]: "اقترح ميزة جديدة",
    ["Watch Training Videos"]: "شاهد مقاطع الفيديو التدريبية",
    ["Edit Profile"]: "تعديل الملف الشخصي",
    ["Manage User"]: "إدارة المستخدم",
    ["Manage Account"]: "إدارة الحساب",
    ["Manage Language"]: "إدارة اللغة",
    ["Manage Currency"]: "إدارة العملة",
    ["Logout"]: "تسجيل الخروج",
    ["Top Menu"]: "القائمة العلوية",
    ["Dashboard"]: "لوحة القيادة",
    ["Contact"]: "اتصال",
    ["Contacts"]: "جهات الاتصال",
    ["Admin"]: "مسؤل",
    ["Conversations"]: "المحادثات",
    ["Terms of Service"]: "شروط الخدمة",
    ["Privacy Statement"]: "بيان الخصوصية",
    ["Copyright 2021 GemFind"]: "جميع الحقوق محفوظة لـ GemFind 2021",
    ["-Server Up-Time: Uptime info not available - please try reloading this page"]:
      "-وقت تشغيل الخادم: معلومات وقت التشغيل غير متوفرة - يرجى محاولة إعادة تحميل هذه الصفحة",
    ["JewelCloud Speed Test"]: "اختبار سرعة JewelCloud",
    ["-RapNet Prices Updated:"]: "-تم تحديث أسعار RapNet:",
    ["Client Tools"]: "أدوات العميل",
    ["Select Client Type"]: "حدد نوع العميل",
    ["Client Name"]: "اسم العميل",
    ["Selected Client Tools"]: "أدوات العميل المختارة",
    ["Name"]: "اسم",
    ["Phone"]: "هاتف",
    ["Type"]: "يكتب",
    ["Verified"]: "تم التحقق منه",
    ["Approved Apps"]: "التطبيقات المعتمدة",
    ["Users"]: "المستخدمون",
    ["Username"]: "اسم المستخدم",
    ["Password"]: "كلمة المرور",
    ["Edit"]: "يحرر",
    ["View Profile"]: "عرض الملف الشخصي",
    ["Download Inventory"]: "تنزيل المخزون",
    ["Impersonate JewelCloud"]: "انتحال شخصية JewelCloud",
    ["Return to JewelCloud as Dealer 720"]:
      "العودة إلى JewelCloud بصفتك التاجر 720",
    ["Turn on or off the ability to view this department in MasterLink."]:
      "قم بتشغيل أو إيقاف القدرة على عرض هذا القسم في MasterLink.",
    ["Updated"]: "تم التحديث",
    ["Data Rating"]: "تصنيف البيانات",
    ["Location"]: "موقع",
    ["Active"]: "نشيط",
    ["Product Loves"]: "المنتج المفضل",
    ["Collections"]: "المجموعات",
    ["Items"]: "أغراض",
    ["Contact Name"]: "اسم جهة الاتصال",
    ["Vendor tags"]: "علامات البائع",
    ["No tag to display"]: "لا يوجد علامة لعرضها",
    ["Brands"]: "العلامات التجارية",
    ["Reset Password"]: "إعادة تعيين كلمة المرور",
    ["User Name"]: "اسم المستخدم",
    ["Confirm Password"]: "تأكيد كلمة المرور",
    ["App Activation Control"]: "التحكم في تنشيط التطبيق",
    ["Select Dealer"]: "اختر الوكيل",
    ["Diamond Link"]: "رابط الماس",
    ["Diamond Search Links"]: "روابط البحث عن الماس",
    ["DS Ecommerce"]: "DS التجارة الإلكترونية",
    ["DS SSL"]: "شهادة SSL من DS",
    ["DS Show PoweredBy"]: "عرض DS مدعوم بواسطة",
    ["DS LabGrownDiamonds"]: "الماسات المزروعة في مختبر DS",
    ["Jewel Cloud"]: "جوهرة السحاب",
    ["API"]: "واجهة برمجة التطبيقات",
    ["Direct feed"]: "التغذية المباشرة",
    ["Windows App"]: "تطبيق ويندوز",
    ["ML Legacy"]: "إرث التعلم الآلي",
    ["ML2"]: "مل2",
    ["ML3"]: "مل3",
    ["ML Ecommerce"]: "التجارة الإلكترونية باستخدام التعلم الآلي",
    ["ML SSL"]: "شهادة SSL ML",
    ["ML Show PoweredBy"]: "عرض ML مدعوم بواسطة",
    ["RB Ecommerce"]: "RB التجارة الإلكترونية",
    ["RB SSL"]: "RB SSL",
    ["RB Show PoweredBy"]: "عرض RB مقدم من",
    ["Tryon"]: "تريون",
    ["SB Ecommerce"]: "التجارة الإلكترونية في إس بي",
    ["SB SSL"]: "شهادة SSL من SB",
    ["SB Show PoweredBy"]: "عرض SB مدعوم بواسطة",
    ["PB Ecommerce"]: "التجارة الإلكترونية PB",
    ["PB SSL"]: "شهادة SSL من PB",
    ["PB Show PoweredBy"]: "عرض PB مقدم من",
    ["Gemfind.TV"]: "جيمفايند.تي في",
    ["Purchase Order(PO)"]: "طلب الشراء",
    ["Bridal/Fashion Separation"]: "فصل الزفاف/الموضة",
    ["Diamond Group Discount"]: "خصم مجموعة الماس",
    ["POS System"]: "نظام نقاط البيع",
    ["Submit"]: "يُقدِّم",
    ["Reports Permissions"]: "أذونات التقارير",
    ["All"]: "الجميع",
    ["Diamond Dealers"]: "تجار الماس",
    ["Retailers"]: "تجار التجزئة",
    ["Diamond Reports"]: "تقارير الماس",
    ["Jewelry Reports"]: "تقارير المجوهرات",
    ["Global Diamond Reports"]: "تقارير الماس العالمية",
    ["Global Jewelry Reports"]: "تقارير المجوهرات العالمية",
    ["Client Data Mapping"]: "تعيين بيانات العميل",
    ["Dealer ID"]: "معرف التاجر",
    ["Dealer Company"]: "شركة الوكيل",
    ["Website Address"]: "عنوان الموقع",
    ["Upload"]: "رفع",
    ["InBound Data Map"]: "خريطة البيانات الواردة",
    ["Outgoing Data Map"]: "خريطة البيانات الصادرة",
    ["OutBound Sync"]: "المزامنة الصادرة",
    ["Set Default Mapping"]: "تعيين التعيين الافتراضي",
    ["Search"]: "يبحث",
    ["Reset"]: "إعادة ضبط",
    ["InActive"]: "غير نشط",
    ["Permission Type"]: "نوع الإذن",
    ["Account Type"]: "نوع الحساب",
    ["VendorDiamond"]: "البائع الماسي",
    ["Vendor"]: "بائع",
    ["Vendor Status"]: "حالة البائع",
    ["Profile Only"]: "الملف الشخصي فقط",
    ["Retailer Status"]: "حالة التاجر",
    ["Select Retailer Status"]: "حدد حالة التاجر",
    ["Product Type"]: "نوع المنتج",
    ["Action"]: "فعل",
    ["Cancel"]: "يلغي",
    ["Save"]: "يحفظ",
    ["Save Search"]: "حفظ البحث",
    ["Update Client Status Information"]: "تحديث معلومات حالة العميل",
    ["Update Date on which Client Name is Created"]:
      "تحديث التاريخ الذي تم فيه إنشاء اسم العميل",
    ["Add New Client"]: "إضافة عميل جديد",
    ["Company Name"]: "اسم الشركة",
    ["Street Address"]: "عنوان الشارع",
    ["City"]: "مدينة",
    ["State"]: "ولاية",
    ["Zip Code"]: "الرمز البريدي",
    ["Country"]: "دولة",
    ["Email Address"]: "عنوان البريد الإلكتروني",
    ["Phone Number"]: "رقم التليفون",
    ["Generate Password"]: "إنشاء كلمة المرور",
    ["Create Client"]: "إنشاء عميل",
    ["Enable 3rd Party Diamond Imports"]: "تمكين استيراد الماس من جهات خارجية",
    ["Dealer Name"]: "اسم التاجر",
    ["Enabled"]: "مُمَكَّن",
    ["Add New Retailer To Core"]: "إضافة بائع تجزئة جديد إلى النواة",
    ["Select Core"]: "حدد النواة",
    ["Enter Retailer ID"]: "أدخل معرف التاجر",
    ["Add"]: "يضيف",
    ["id"]: "بطاقة تعريف",
    ["Core Name"]: "الاسم الأساسي",
    ["Retailer ID"]: "معرف التاجر",
    ["Retailer Name"]: "اسم التاجر",
    ["Vendor Ratings"]: "تقييمات البائعين",
    ["Vendor ID"]: "معرف البائع",
    ["Vendor Name"]: "اسم البائع",
    ["System Ratings"]: "تصنيفات النظام",
    ["Manual Ratings"]: "التقييمات اليدوية",
    ["Modified Date"]: "تاريخ التعديل",
    ["Save Vendor Rating"]: "حفظ تقييم البائع",
    ["Limit Retailer To Diamond Dealer"]: "تقييد التاجر على تاجر الماس",
    ["Limit Diamond Dealer To Retailer"]: "تقييد تاجر الماس على بائع التجزئة",
    ["Limit Retailer To Vendor"]: "الحد من بائع التجزئة إلى البائع",
    ["Limit Vendor To Retailer"]: "تحديد البائعين إلى تجار التجزئة",
    ["Apply Filter"]: "تطبيق الفلتر",
    ["Rules"]: "قواعد",
    ["Selected"]: "تم اختياره",
    ["Delete"]: "يمسح",
    ["Create"]: "يخلق",
    ["Add Something"]: "أضف شيئا",
    ["Connect Vendors"]: "ربط البائعين",
    ["Download"]: "تحميل",
    ["Rule Type"]: "نوع القاعدة",
    ["Vendor/Dealer"]: "البائع/التاجر",
    ["Created Date"]: "تاريخ الإنشاء",
    ["Next"]: "التالي",
    ["Diamond Dealer"]: "تاجر الماس",
    ["Are you sure to delete this Rule"]: "هل أنت متأكد من حذف هذه القاعدة؟",
    ["THIS CAN NOT BE UNDONE"]: "لا يمكن التراجع عن هذا",
    ["Delete Limit Rule"]: "حذف قاعدة الحد",
    ["Dealer Markup"]: "هامش ربح التاجر",
    ["(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))"]:
      "(أدوات إدارة قاعدة البيانات لـ DealerID 720 (Alex) و712 (Morgan))",
    ["Add a Dealer to all GFLinks:"]: "إضافة تاجر إلى جميع GFLinks:",
    ["Select the DealerID you want to"]: "حدد معرف التاجر الذي تريده",
    ["to all GFLinks"]: "إلى جميع GFLinks",
    ["Remove a Dealer from all GFLinks:"]: "إزالة التاجر من جميع GFLinks:",
    ["Remove"]: "يزيل",
    ["Add a Dealer to all GFLinks Lab Diamonds:"]:
      "أضف تاجرًا إلى جميع GFLinks Lab Diamonds:",
    ["to all GFLinks Lab Diamonds"]: "إلى جميع GFLinks Lab Diamonds",
    ["Remove a Dealer from all GFLinks Lab Diamonds:"]:
      "إزالة التاجر من جميع GFLinks Lab Diamonds:",
    ["Add DealerID"]: "إضافة معرف التاجر",
    ["Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks."]:
      "إزالة تاجر من جميع GFLinks: حدد معرف التاجر الذي تريد إزالته من جميع GFLinks.",
    ["Remove DealerID"]: "إزالة معرف التاجر",
    ["Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds."]:
      "إضافة تاجر إلى جميع GFLinks Lab Diamonds: حدد معرف التاجر الذي تريد إضافته إلى جميع GFLinks Lab Diamonds.",
    ["Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds."]:
      "إزالة تاجر من جميع GFLinks Lab Diamonds: حدد معرف التاجر الذي تريد إزالته من جميع GFLinks Lab Diamonds.",
    ["Add Console Upload Dealer Data"]: "إضافة بيانات الموزع إلى وحدة التحكم",
    ["Create FTP"]: "إنشاء FTP",
    ["Dealer"]: "تاجر",
    ["FTP User Name"]: "اسم مستخدم FTP",
    ["FTP Password"]: "كلمة مرور FTP",
    ["File Type"]: "نوع الملف",
    ["Path"]: "طريق",
    ["Add Update"]: "إضافة تحديث",
    ["Console Upload Info"]: "معلومات تحميل وحدة التحكم",
    ["Console Upload Edit Info"]: "تحرير معلومات التحميل في وحدة التحكم",
    ["Enter User Name"]: "أدخل اسم المستخدم",
    ["Enter Password"]: "أدخل كلمة المرور",
    ["Enter Folder Name"]: "أدخل اسم المجلد",
    ["Are you sure you want to delete"]: "هل أنت متأكد أنك تريد الحذف؟",
    ["Are You Sure You Want To Delete This Markup ?"]:
      "هل أنت متأكد أنك تريد حذف هذه العلامة؟",
    ["Are you sure you want to remove this override?"]:
      "هل أنت متأكد أنك تريد إزالة هذا التجاوز؟",
    ["Dealer Data"]: "بيانات التاجر",
    ["Dealer Mapping"]: "خريطة التاجر",
    ["Magento Mapping"]: "تخطيط ماجنتو",
    ["API URL"]: "عنوان URL لواجهة برمجة التطبيقات",
    ["API Key"]: "مفتاح API",
    ["IsActive"]: "هل هو نشط",
    ["Edit Megento Mapping Info"]: "تعديل معلومات خريطة Megento",
    ["Edit Open Source Mapping Info"]:
      "تعديل معلومات رسم الخرائط مفتوحة المصدر",
    ["Port No"]: "رقم المنفذ",
    ["Delete Dealer Mapping"]: "حذف خريطة التاجر",
    ["Megento Mapping Info"]: "معلومات عن خريطة ميجنتو",
    ["Open Source Mapping Info"]: "معلومات رسم الخرائط مفتوحة المصدر",
    ["Discard All"]: "تجاهل الكل",
    ["Run Valid Image Application"]: "تشغيل تطبيق الصورة الصالحة",
    ["Sync ID"]: "معرف المزامنة",
    ["Status"]: "حالة",
    ["Discard"]: "ينبذ",
    ["Source JC ID"]: "المصدر معرف JC",
    ["Destination JC ID"]: "معرف الوجهة JC",
    ["Replace All"]: "استبدال الكل",
    ["Add & Update"]: "إضافة وتحديث",
    ["Transfer"]: "تحويل",
    ["Are you sure you want to move data from"]:
      "هل أنت متأكد من أنك تريد نقل البيانات من",
    ["account to"]: "حساب ل",
    ["account ? - You have select"]: "الحساب ؟ - لقد قمت بالاختيار",
    ["Select Designer for New"]: "اختر المصمم الجديد",
    ["Note"]: "ملحوظة",
    ["This will feature a specific vendor for the"]:
      "سيتضمن هذا بائعًا محددًا لـ",
    ["New to JewelCloud"]: "جديد في JewelCloud",
    ["section on the login page"]: "قسم في صفحة تسجيل الدخول",
    ["Upload Logo Image for Designers"]: "تحميل صورة الشعار للمصممين",
    ["Choose Image"]: "اختر الصورة",
    ["No File Choosen"]: "لم يتم اختيار الملف",
    ["Pixels"]: "بكسل",
    ["Png Format"]: "صيغة PNG",
    ["Select Designer"]: "اختر المصمم",
    ["Upload Image"]: "تحميل الصورة",
    ["Select Designer To Show on Login Page"]:
      "حدد المصمم لعرضه في صفحة تسجيل الدخول",
    ["Admin Preferences"]: "تفضيلات المسؤول",
    ["MasterLink Theme"]: "موضوع ماسترلينك",
    ["Default Theme"]: "الموضوع الإفتراضي",
    ["Retailer Locater Black Theme"]: "تحديد موقع التاجر باللون الأسود",
    ["Block Designers For Demo Retailer"]: "مصممو الكتل للمتاجر التجريبية",
    ["Designer Name"]: "اسم المصمم",
    ["Is Blocked ?"]: "هل تم حظره؟",
    ["Update Selection"]: "تحديث الاختيار",
    ["Add New"]: "إضافة جديد",
    ["Page Number"]: "رقم الصفحة",
    ["1 Tier PageName"]: "صفحة اسم الطبقة 1",
    ["2 Tier PageName"]: "اسم الصفحة من مستويين",
    ["3 Tier PageName"]: "اسم الصفحة المكون من 3 طبقات",
    ["Vendor Super"]: "البائع السوبر",
    ["Vendor Admin"]: "مسؤول البائع",
    ["Vendor User"]: "البائع المستخدم",
    ["Vendor-Diamond Super"]: "البائع-الماس السوبر",
    ["Vendor-Diamond Admin"]: "مسؤول البائع الماسي",
    ["Vendor-Diamond User"]: "البائع-مستخدم الماس",
    ["Retailer Super"]: "بائع التجزئة سوبر",
    ["Retailer Admin"]: "مسؤول التاجر",
    ["Retailer User"]: "مستخدم التجزئة",
    ["Admin Super"]: "مشرف سوبر",
    ["Admin Admin"]: "مشرف مشرف",
    ["Admin User"]: "مستخدم إداري",
    ["Combined Super"]: "السوبر المشترك",
    ["Combined Admin"]: "الإدارة المشتركة",
    ["Combined User"]: "المستخدم المشترك",
    ["Organization Super"]: "المنظمة السوبر",
    ["Email Templates"]: "قوالب البريد الإلكتروني",
    ["All Dealers"]: "جميع التجار",
    ["Registration Emails"]: "رسائل البريد الإلكتروني للتسجيل",
    ["New Account"]: "حساب جديد",
    ["Forgot Password"]: "هل نسيت كلمة السر",
    ["Forgot UserName"]: "نسيت اسم المستخدم",
    ["Account Locked"]: "تم قفل الحساب",
    ["Retailer Emails"]: "رسائل البريد الإلكتروني للتجار",
    ["Invite New Retailer"]: "دعوة بائع تجزئة جديد",
    ["Community Emails"]: "رسائل البريد الإلكتروني المجتمعية",
    ["Retailer Request e-Mail to"]:
      "طلب بائع التجزئة عبر البريد الإلكتروني إلى",
    ["ACTIVE"]: "نشيط",
    ["GF Members"]: "أعضاء GF",
    ["Retailer e-Mail to"]: "البريد الإلكتروني للتاجر إلى",
    ["COMMUNITY MANAGED"]: "إدارة المجتمع",
    ["Non GF Members"]: "الأعضاء غير الأعضاء في GF",
    ["PROFILE ONLY"]: "الملف الشخصي فقط",
    ["LIMITED ACCOUNT"]: "حساب محدود",
    ["Vendor Decline eMail"]: "بريد إلكتروني لرفض البائع",
    ["Vendor Accept eMail"]: "البائع يقبل البريد الإلكتروني",
    ["Request to Update Product Data"]: "طلب تحديث بيانات المنتج",
    ["Vendor Appointment"]: "موعد مع البائع",
    ["Invite New Vendor"]: "دعوة بائع جديد",
    ["Contact Emails (MasterLink)"]:
      "رسائل البريد الإلكتروني للاتصال (MasterLink)",
    ["Customer Registration"]: "تسجيل العملاء",
    ["New Appointments"]: "التعيينات الجديدة",
    ["Inquires (Request More Info)"]: "الاستفسارات (طلب المزيد من المعلومات)",
    ["Send Mail to Retailer"]: "إرسال البريد إلى التاجر",
    ["Send Mail to Sender"]: "إرسال البريد إلى المرسل",
    ["Send Email (Email To Friend)"]:
      "إرسال بريد إلكتروني (إرسال بريد إلكتروني إلى صديق)",
    ["Send Mail to Friend"]: "إرسال بريد إلى صديق",
    ["Drop A Hint"]: "أسقط تلميحًا",
    ["Send Mail to Recipient"]: "إرسال البريد إلى المستلم",
    ["New Favorite List"]: "قائمة المفضلة الجديدة",
    ["New Favorite Items Added"]: "تمت إضافة عناصر مفضلة جديدة",
    ["Customer Reset Password"]: "إعادة تعيين كلمة المرور للعميل",
    ["Customer Reset Password (New)"]: "إعادة تعيين كلمة المرور للعميل (جديد)",
    ["Diamond Search Emails"]: "رسائل البريد الإلكتروني للبحث عن الماس",
    ["Request More Info"]: "طلب المزيد من المعلومات",
    ["Email To Friend"]: "إرسال بريد إلكتروني إلى صديق",
    ["Schedule View"]: "عرض الجدول الزمني",
    ["Diamond Detail"]: "تفاصيل الماس",
    ["Diamond Compare"]: "مقارنة الماس",
    ["Diamond Customer Compare"]: "مقارنة عملاء الماس",
    ["Fancy Color Diamond Search Emails"]:
      "رسائل البريد الإلكتروني للبحث عن الماس الملون",
    ["Color Diamond Detail"]: "تفاصيل الماس الملون",
    ["Ring Builder Emails"]: "رسائل البريد الإلكتروني لمنشئ الخواتم",
    ["Drop A Hint (Infinity)"]: "إسقاط تلميح (إنفينيتي)",
    ["Buy Now (Infinity)"]: "اشتري الآن (إنفينيتي)",
    ["Setting Detail"]: "تفاصيل الإعداد",
    ["Complete Ring Specifications"]: "المواصفات الكاملة للحلقة",
    ["Pendant Builder Emails"]:
      "رسائل البريد الإلكتروني الخاصة بصانعي القلادات",
    ["Diamond Request More Info"]: "طلب الماس المزيد من المعلومات",
    ["Complete Pendant Setting"]: "مجموعة القلادة الكاملة",
    ["Stud Builder Emails"]:
      "رسائل البريد الإلكتروني الخاصة ببناة العوارض الخشبية",
    ["Request More Retailer"]: "طلب المزيد من بائع التجزئة",
    ["SB To ScheduleView"]: "SB إلى جدول العرض",
    ["SB Complete Earring To ScheduleView"]: "SB أقراط كاملة للجدولةعرض",
    ["Diamond Email To Friend"]: "رسالة الماس إلى صديق",
    ["Store"]: "محل",
    ["Big Emails"]: "رسائل البريد الإلكتروني الكبيرة",
    ["Email To Customer"]: "البريد الإلكتروني للعميل",
    ["Send Mail to Customer"]: "إرسال بريد إلى العميل",
    ["Send Mail to"]: "إرسال البريد إلى",
    ["Send Email"]: "إرسال بريد إلكتروني",
    ["Default Data Map"]: "خريطة البيانات الافتراضية",
    ["Product ID & Description"]: "معرف المنتج والوصف",
    ["Gemfind Column"]: "عمود البحث عن الجواهر",
    ["Active Status"]: "الحالة النشطة",
    ["Category"]: "فئة",
    ["Categories"]: "فئات",
    ["Attributes"]: "صفات",
    ["Gemstones"]: "الاحجار الكريمة",
    ["Watches"]: "الساعات",
    ["Save Details"]: "حفظ التفاصيل",
    ["Manage Current Metal Market"]: "إدارة سوق المعادن الحالي",
    ["Current Metal Price"]: "سعر المعدن الحالي",
    ["Silver"]: "فضي",
    ["Gold"]: "ذهب",
    ["Platinum"]: "البلاتين",
    ["Palladium"]: "البلاديوم",
    ["Enter New Metal Type"]: "أدخل نوع المعدن الجديد",
    ["Trade Show"]: "معرض تجاري",
    ["Show"]: "يعرض",
    ["Dates"]: "بلح",
    ["Add Trade Show"]: "إضافة معرض تجاري",
    ["Edit Trade Show"]: "تعديل المعرض التجاري",
    ["JPG Format"]: "صيغة JPG",
    ["Show Name"]: "إظهار الاسم",
    ["Website"]: "موقع إلكتروني",
    ["Show Start Time"]: "عرض وقت البدء",
    ["Show End Time"]: "عرض وقت النهاية",
    ["AM"]: "أكون",
    ["Closed"]: "مغلق",
    ["PM"]: "مساءً",
    ["Show Start Dates"]: "إظهار تواريخ البدء",
    ["Show End Dates"]: "إظهار تواريخ النهاية",
    ["Delete Trade Show"]: "حذف المعرض التجاري",
    ["Are You Sure Want to delete"]: "هل أنت متأكد من أنك تريد الحذف؟",
    ["Sr. No."]: "رقم التسلسل",
    ["Color Name"]: "اسم اللون",
    ["Edit Color"]: "تعديل اللون",
    ["API Type"]: "نوع واجهة برمجة التطبيقات",
    ["Update"]: "تحديث",
    ["Mailing Lists"]: "قوائم البريد الإلكتروني",
    ["Alias Address"]: "عنوان الاسم المستعار",
    ["Number Of Recipients"]: "عدد المستفيدين",
    ["Created"]: "مخلوق",
    ["Recipients"]: "المستلمون",
    ["Sync"]: "المزامنة",
    ["SyncHistory"]: "تاريخ المزامنة",
    ["Recipient Address"]: "عنوان المستلم",
    ["Client"]: "عميل",
    ["Select Campaign"]: "حدد الحملة",
    ["Campaign Name"]: "اسم الحملة",
    ["Submitted"]: "مُقَدَّم",
    ["Delivered"]: "تم التوصيل",
    ["Total Opens"]: "مجموع الافتتاحات",
    ["Total Clicks"]: "إجمالي النقرات",
    ["Select Compose"]: "حدد إنشاء",
    ["From Name"]: "من الاسم",
    ["From Email"]: "من البريد الإلكتروني",
    ["Type Your Subject"]: "اكتب موضوعك",
    ["Send"]: "يرسل",
    ["From Date"]: "من تاريخ",
    ["To Date"]: "حتى الآن",
    ["Initiated"]: "تم البدء",
    ["Complete"]: "مكتمل",
    ["Sync Type"]: "نوع المزامنة",
    ["Show Full Syncs"]: "إظهار المزامنة الكاملة",
    ["Show Update Syncs"]: "إظهار مزامنة التحديثات",
    ["Show Delete Syncs"]: "إظهار حذف المزامنة",
    ["Show Price Syncs"]: "عرض مزامنة الأسعار",
    ["Sync Status"]: "حالة المزامنة",
    ["Show Success Syncs"]: "إظهار عمليات المزامنة الناجحة",
    ["Show Failed Syncs"]: "إظهار عمليات المزامنة الفاشلة",
    ["Show Pending Syncs"]: "إظهار المزامنة المعلقة",
    ["Mastelink"]: "ماست لينك",
    ["Watch Builder"]: "صانع الساعات",
    ["Version"]: "إصدار",
    ["Diamonds"]: "الماس",
    ["Designers"]: "المصممين",
    ["Open Requests"]: "طلبات مفتوحة",
    ["Last Updated"]: "آخر تحديث",
    ["Days Old"]: "أيام قديمة",
    ["Details"]: "تفاصيل",
    ["Metal Types"]: "أنواع المعادن",
    ["Metal Colors"]: "الألوان المعدنية",
    ["Diamond Pairs"]: "أزواج الماس",
    ["Default Mapping Values"]: "قيم التعيين الافتراضية",
    ["Heirachy"]: "هرمية",
    ["Count"]: "عدد",
    ["Last Upload Date"]: "تاريخ أخر تحميل",
    ["Select Diamond"]: "اختر الماس",
    ["Top Diamond Attribute Values"]: "أفضل قيم سمات الماس",
    ["Recent Diamond Attribute Searches"]: "عمليات البحث الأخيرة عن سمات الماس",
    ["Depth"]: "عمق",
    ["Table"]: "طاولة",
    ["Measurements"]: "القياسات",
    ["Date"]: "تاريخ",
    ["Shape"]: "شكل",
    ["Size"]: "مقاس",
    ["Color"]: "لون",
    ["Cut"]: "يقطع",
    ["Clarity"]: "الوضوح",
    ["Price"]: "سعر",
    ["Create a Rule"]: "إنشاء قاعدة",
    ["UsersIPAddress"]: "عنوان IP للمستخدمين",
    ["Day & Time"]: "اليوم والوقت",
    ["Certificates"]: "الشهادات",
    ["Retail Price"]: "سعر التجزئة",
    ["Cost"]: "يكلف",
    ["Add Gemstone"]: "إضافة الأحجار الكريمة",
    ["Extended Diamond Chart Reports"]: "تقارير الرسم البياني الماسي الممتد",
    ["Vendor(s)"]: "البائع(ون)",
    ["Retailer(s)"]: "بائع التجزئة",
    ["Diamond Cut Searches"]: "عمليات البحث عن قطع الماس",
    ["Diamond Shape Searches"]: "عمليات البحث عن شكل الماس",
    ["Diamond Clarity Searches"]: "عمليات البحث عن وضوح الماس",
    ["Diamond Certificate Searches"]: "عمليات البحث عن شهادة الماس",
    ["Displays the total number of jewelry clicks through the currently specified date range above"]:
      "يعرض العدد الإجمالي لنقرات المجوهرات خلال نطاق التاريخ المحدد حاليًا أعلاه",
    ["Download CSV"]: "تنزيل ملف CSV",
    ["Total Jewelry Clicks"]: "إجمالي نقرات المجوهرات",
    ["Jewelry Clicks Based on Category"]: "نقرات المجوهرات حسب الفئة",
    ["Jewelry Clicks Based on Price"]: "نقرات المجوهرات بناءً على السعر",
    ["Jewelry Clicks Based on Vendor"]: "نقرات المجوهرات بناءً على البائع",
    ["Product Image"]: "صورة المنتج",
    ["User IP Address"]: "عنوان IP للمستخدم",
    ["Collection"]: "مجموعة",
    ["Style Number"]: "رقم الطراز",
    ["Metal Type"]: "نوع المعدن",
    ["Metal Color"]: "لون المعدن",
    ["Show Filter"]: "إظهار الفلتر",
    ["Hide Filter"]: "إخفاء الفلتر",
    ["Shapes"]: "الاشكال",
    ["Round"]: "دائري",
    ["Oval"]: "بيضاوي",
    ["Circle"]: "دائرة",
    ["Heart"]: "قلب",
    ["Emarald"]: "الزمرد",
    ["Radiant"]: "مشع",
    ["Caret"]: "علامة الإقحام",
    ["Carats"]: "قيراط",
    ["Carat"]: "قيراط",
    ["Polish"]: "بولندي",
    ["Length(mm)"]: "الطول(مم)",
    ["Width(mm)"]: "العرض(مم)",
    ["Height(mm)"]: "الارتفاع(مم)",
    ["Symmetry"]: "التماثل",
    ["Girdle"]: "حزام",
    ["Crown Angle"]: "زاوية التاج",
    ["Pavilion Angle"]: "زاوية الجناح",
    ["Culet"]: "كوليت",
    ["Culet Condition"]: "حالة الكوليت",
    ["Fluorescence"]: "الفلورسنت",
    ["Fancy Color"]: "لون فاخر",
    ["Intensity"]: "شدة",
    ["Overtone"]: "نغمة علوية",
    ["Matched Pairs"]: "أزواج متطابقة",
    ["Has Video"]: "لديه فيديو",
    ["Reset Filters"]: "إعادة تعيين المرشحات",
    ["Apply Filters"]: "تطبيق المرشحات",
    ["You Have"]: "لديك",
    ["View"]: "منظر",
    ["Print"]: "مطبعة",
    ["View Details"]: "عرض التفاصيل",
    ["Contact Dealer"]: "اتصل بالوكيل",
    ["Message to B&H Diamonds"]: "رسالة إلى B&H Diamonds",
    ["Contact the dealer regarding"]: "اتصل بالتاجر بخصوص",
    ["GemFind"]: "البحث عن الجواهر",
    ["Carat Weight"]: "وزن القيراط",
    ["Treatment"]: "علاج",
    ["Crown"]: "تاج",
    ["Lab"]: "مختبر",
    ["Matched Pair Stock"]: "مخزون الزوج المتطابق",
    ["Seller"]: "بائع",
    ["Stock"]: "مخزون",
    ["Stock #"]: "مخزون #",
    ["Off %"]: "عن ٪",
    ["Flour"]: "دقيق",
    ["Dep."]: "قسم",
    ["Measure"]: "يقيس",
    ["Cert."]: "شهادة",
    ["Sym"]: "سم",
    ["Image/Video"]: "الصورة/الفيديو",
    ["Video"]: "فيديو",
    ["Close"]: "يغلق",
    ["Colors"]: "الألوان",
    ["Blue"]: "أزرق",
    ["Orange"]: "البرتقالي",
    ["Green"]: "أخضر",
    ["Yellow"]: "أصفر",
    ["Red"]: "أحمر",
    ["Maroon"]: "كستنائي",
    ["Permission"]: "إذن",
    ["Fancy Intensity"]: "كثافة خيالية",
    ["Overtone 1"]: "نغمة عليا 1",
    ["Overtone 2"]: "نغمة عليا 2",
    ["Overall Price"]: "السعر الإجمالي",
    ["Price per Caret Range"]: "نطاق السعر لكل سهم",
    ["Eye Clean"]: "تنظيف العين",
    ["Fluorescence Intensity"]: "شدة الفلورسنت",
    ["Fluorescence Color"]: "لون الفلورسنت",
    ["L/W Ratio Measurements"]: "قياسات نسبة الطول إلى العرض",
    ["LW Ratio"]: "نسبة الوزن الطولي",
    ["Any"]: "أي",
    ["Square"]: "مربع",
    ["Rectangle"]: "المستطيل",
    ["Shade"]: "ظل",
    ["Central Inclusion Range"]: "نطاق الإدماج المركزي",
    ["Black Inclusion Range"]: "مجموعة تضمين اللون الأسود",
    ["Milky Inclusion Range"]: "مجموعة تضمين الحليب",
    ["Girdle Range"]: "نطاق الحزام",
    ["Crown Angle % Range"]: "نطاق زاوية التاج %",
    ["Pavilion Angle % Range"]: "نطاق زاوية الجناح %",
    ["Key to Symbols"]: "مفتاح الرموز",
    ["Culet Range"]: "مجموعة كوليت",
    ["Culet Condition Range"]: "نطاق حالة الكوليت",
    ["price(Cost)"]: "السعر(التكلفة)",
    ["Material"]: "مادة",
    ["There are"]: "هناك",
    ["results for your search"]: "نتائج بحثك",
    ["Material Type"]: "نوع المادة",
    ["Gender"]: "جنس",
    ["Material Color"]: "لون المادة",
    ["Gemstone"]: "أحجار كريمة",
    ["Retaile Stock #"]: "مخزون التجزئة #",
    ["Retail"]: "بيع بالتجزئة",
    ["Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."]:
      'لا يعد الجنس حقلًا مطلوبًا لبيانات البائع، لذا عند ترك هذا الحقل فارغًا، سيتم اعتبار غالبية نتائج المنتجات "سيدات".',
    ["Please select a vendor before choosing a collection."]:
      "يرجى تحديد البائع قبل اختيار المجموعة.",
    ["Retail Stock #"]: "مخزون التجزئة #",
    ["$ cost"]: "التكلفة",
    ["$ Retail"]: "$التجزئة",
    ["Store Name"]: "اسم المتجر",
    ["Retailer Type"]: "نوع التاجر",
    ["Requested Date"]: "التاريخ المطلوب",
    ["Request Date"]: "تاريخ الطلب",
    ["My Retailer"]: "بائع التجزئة الخاص بي",
    ["My Retailers"]: "تجار التجزئة الخاص بي",
    ["Jewelry API"]: "واجهة برمجة تطبيقات المجوهرات",
    ["All Retailer"]: "جميع تجار التجزئة",
    ["All Retailers"]: "جميع تجار التجزئة",
    ["Profile"]: "حساب تعريفي",
    ["Conversation"]: "محادثة",
    ["Notes"]: "ملحوظات",
    ["Appointments"]: "المواعيد",
    ["Time"]: "وقت",
    ["Buyer Name"]: "اسم المشتري",
    ["Action Request"]: "طلب عمل",
    ["Activity"]: "نشاط",
    ["Customer"]: "عميل",
    ["Load More"]: "تحميل المزيد",
    ["View My Mined Diamond"]: "عرض الماس المستخرج من مناجمي",
    ["Are you sure to delete this diamond"]: "هل أنت متأكد من حذف هذا الماس؟",
    ["View My Lab Diamond"]: "عرض مختبري الماسي",
    ["View My Color Diamond"]: "عرض الماس الملون الخاص بي",
    ["Diamond Upload History"]: "تاريخ تحميل الماس",
    ["Data Upload"]: "تحميل البيانات",
    ["Image Upload"]: "تحميل الصورة",
    ["File"]: "ملف",
    ["Records"]: "السجلات",
    ["Inventory #"]: "جرد #",
    ["Matched Pair Stock #"]: "زوج متطابق رقم المخزون",
    ["Stones (Parcel)"]: "الحجارة (الطرد)",
    ["Separable"]: "قابلة للفصل",
    ["Fancy Codes"]: "رموز خيالية",
    ["Fancy Names"]: "أسماء فاخرة",
    ["Other"]: "آخر",
    ["Cut Grade"]: "درجة القطع",
    ["Certificate #"]: "شهادة #",
    ["Pavillion Angle"]: "زاوية الجناح",
    ["Comments"]: "تعليقات",
    ["Store Location"]: "موقع المتجر",
    ["Employee Name"]: "اسم الموظف",
    ["Employee ID"]: "رقم هوية الموظف",
    ["Out On Memo"]: "خارج المذكرة",
    ["Seen by Other Dealers"]: "تمت رؤيته من قبل تجار آخرين",
    ["MatchingSKUs"]: "مطابقة وحدات SKU",
    ["GroupedProductSKUs"]: "مجموعة المنتجات SKUs",
    ["UpSellSKUs"]: "ترقية وحدات SKU",
    ["Add Video"]: "أضف فيديو",
    ["View all new vendors Or see 20 of the most recently updated vendors."]:
      "عرض جميع البائعين الجدد أو راجع 20 من البائعين الذين تم تحديثهم مؤخرًا.",
    ["View all new vendors"]: "عرض جميع البائعين الجدد",
    ["Or"]: "أو",
    ["see 20 of the most recently updated vendors."]:
      "راجع 20 من البائعين الذين تم تحديثهم مؤخرًا",
    ["Upload Your Data File"]: "قم بتحميل ملف البيانات الخاص بك",
    ["Add Individual"]: "إضافة فرد",
    ["Upload Certs"]: "تحميل الشهادات",
    ["Download Data"]: "تنزيل البيانات",
    ["Add new diamonds in bulk or make mass item updates."]:
      "أضف الماس الجديد بكميات كبيرة أو قم بإجراء تحديثات جماعية للعناصر.",
    ["Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours."]:
      "يرجى تحديث مخزونك باستمرار. سيتم إزالة مخزون الماس الخاص بك إذا لم يتم تحديثه كل 48 ساعة على الأقل.",
    ["Choose File"]: "اختر الملف",
    ["Xls/Txt/Csv Format"]: "تنسيق XLS/Txt/Csv",
    ["Xls/Txt/Csv/Json Format"]: "تنسيق XLS/Txt/Csv/Json",
    ["Upload Data"]: "تحميل البيانات",
    ["Upload Diamond Images & Videos"]: "تحميل صور وفيديوهات الماس",
    ["Jpg/Png Format"]: "صيغة Jpg/Png",
    ["Jpg/Png/Zip Format"]: "صيغة Jpg/Png/Zip",
    ["Compress images together & upload in a .zip format."]:
      "ضغط الصور معًا وتحميلها بتنسيق .zip.",
    ["Download our image guidelines documentation"]:
      "قم بتنزيل وثائق إرشادات الصور الخاصة بنا",
    ["Import Data From 3rd Party Provider"]:
      "استيراد البيانات من مزود تابع لجهة خارجية",
    ["Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit"]:
      "تقتصر عمليات تحميل الماس على 50000 ماسة لضمان سرعة النظام وموثوقيته. سيتم قطع ملفات الماس التي تحتوي على أكثر من 50000 ماسة، لذا يرجى مراجعة بائعي الماس للتأكد من أنك ضمن الحد الأقصى للماس",
    ["Add new diamonds in bulk or make mass item updates"]:
      "أضف الماس الجديد بكميات كبيرة أو قم بإجراء تحديثات جماعية للعناصر",
    ["First time importing?"]: "هل هذه هي المرة الأولى التي تستورد فيها؟",
    ["Import Your Content"]: "استيراد المحتوى الخاص بك",
    ["Choose & upload your data or connect to a 3rd party"]:
      "اختر بياناتك وقم بتحميلها أو الاتصال بطرف ثالث",
    ["Wait For Confirmation"]: "انتظر التأكيد",
    ["You will receive an e-mail confirmation when your content has been uploaded."]:
      "سوف تتلقى رسالة تأكيد عبر البريد الإلكتروني عند تحميل المحتوى الخاص بك.",
    ["Inventory with Dealer Stock # not included in the upload will be deleted."]:
      "سيتم حذف المخزون الذي لا يتضمن رقم مخزون التاجر في التحميل.",
    ["Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."]:
      "سيتم إضافة المخزون الذي يحتوي على رقم مخزون التاجر الجديد وسيتم تحديث رقم مخزون التاجر الحالي. سيظل رقم مخزون التاجر غير المضمن كما هو.",
    ["Select Data file to be"]: "حدد ملف البيانات المراد",
    ["Mapped"]: "تم رسمها على الخريطة",
    ["Upload File"]: "تحميل الملف",
    ["Reset the data map"]: "إعادة تعيين خريطة البيانات",
    ["View / Edit the map"]: "عرض / تحرير الخريطة",
    ["Manage FTP settings"]: "إدارة إعدادات FTP",
    ["Generate File"]: "إنشاء ملف",
    ["GF Column ID"]: "معرف العمود GF",
    ["Your Column"]: "العمود الخاص بك",
    ["Manage FTP Credential"]: "إدارة بيانات اعتماد FTP",
    ["FTP URL"]: "عنوان URL لـ FTP",
    ["Request Access"]: "طلب الوصول",
    ["Your Diamonds"]: "الماس الخاص بك",
    ["Connected Diamonds"]: "الماس المتصل",
    ["Total Diamonds"]: "مجموع الماس",
    ["Lab Diamonds"]: "الماس المختبري",
    ["Color Diamond Count"]: "عدد الماس الملون",
    ["Your Products"]: "منتجاتك",
    ["Connected Products"]: "المنتجات المتصلة",
    ["Active Products"]: "المنتجات النشطة",
    ["EDGE"]: "حافة",
    ["Import Type"]: "نوع الاستيراد",
    ["Update History"]: "تحديث التاريخ",
    ["Create Product"]: "إنشاء المنتج",
    ["More Actions"]: "المزيد من الإجراءات",
    ["Total"]: "المجموع",
    ["Product"]: "منتج",
    ["Select All"]: "حدد الكل",
    ["Images"]: "الصور",
    ["Style No"]: "النمط رقم",
    ["GFinventory No"]: "رقم المخزون",
    ["Product Name"]: "اسم المنتج",
    ["Wholesale Price"]: "سعر الجملة",
    ["No items found reset your filters"]:
      "لم يتم العثور على أي عناصر، قم بإعادة تعيين المرشحات الخاصة بك",
    ["Gemfind#"]: "العثور على جوهرة#",
    ["Feature"]: "ميزة",
    ["Are you sure you want to delete this Product(s)?"]:
      "هل أنت متأكد أنك تريد حذف هذا المنتج؟",
    ["Add Image"]: "إضافة صورة",
    ["Or"]: "أو",
    ["Load Image From Server"]: "تحميل الصورة من الخادم",
    ["Load Image"]: "تحميل الصورة",
    ["Retail Description"]: "وصف التجزئة",
    ["Style #"]: "أسلوب #",
    ["This must be a unique item number identifying the particular item or the"]:
      "يجب أن يكون هذا رقم عنصر فريدًا يحدد العنصر المعين أو",
    ["parent"]: "الوالد",
    ["item which has many variations - if this is a style root then you will want to set this iem as a"]:
      "عنصر يحتوي على العديد من الاختلافات - إذا كان هذا هو جذر النمط، فستحتاج إلى تعيين هذا العنصر كـ",
    ["configurable product"]: "منتج قابل للتكوين",
    ["to configure the variables such as carat weight, metal type, diamond quality, length etc."]:
      "لتكوين المتغيرات مثل وزن القيراط، ونوع المعدن، وجودة الماس، والطول وما إلى ذلك.",
    ["Dealer Stock #"]: "رقم مخزون التاجر",
    ["This is a unique identification number of this particular item."]:
      "هذا هو رقم التعريف الفريد لهذا العنصر المعين.",
    ["Period"]: "فترة",
    ["Visibility"]: "الرؤية",
    ["Additional"]: "إضافي",
    ["Qty On Hand"]: "الكمية المتوفرة",
    ["Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory."]:
      "اسألنا عن كيفية قيامنا بإعداد اتصال تلقائي بنظام المخزون الخاص بك وتتبع المخزون الافتراضي الخاص بك بشكل ديناميكي.",
    ["Display Order"]: "عرض الطلب",
    ["This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive."]:
      "يشير هذا إلى الترتيب الذي سيظهر به العنصر ضمن فئته - الأقل رقمًا أولاً. يتم فرز العناصر التي تحمل نفس الرقم بناءً على الأقل تكلفة إلى الأكثر تكلفة.",
    ["Setting"]: "جلسة",
    ["Finishing Technique"]: "تقنية التشطيب",
    ["Clasp Type"]: "نوع المشبك",
    ["Chain Type"]: "نوع السلسلة",
    ["Back Finding"]: "العودة إلى البحث",
    ["Additional Information"]: "معلومات إضافية",
    ["Additional Information 2"]: "معلومات إضافية 2",
    ["Sizing"]: "تحديد المقاسات",
    ["General"]: "عام",
    ["Weight"]: "وزن",
    ["grams"]: "غرام",
    ["dwt"]: "وزن ثابت",
    ["Thickness"]: "سماكة",
    ["mm"]: "مم",
    ["inches"]: "بوصات",
    ["Width"]: "عرض",
    ["Dimensions"]: "أبعاد",
    ["Unit Of Measure"]: "وحدة القياس",
    ["Finger Size"]: "حجم الاصبع",
    ["Stock Finger Size"]: "حجم الإصبع",
    ["Min Finger Size"]: "الحد الأدنى لحجم الإصبع",
    ["Max Finger Size"]: "الحد الأقصى لحجم الإصبع",
    ["Finger Size Increment"]: "زيادة حجم الإصبع",
    ["Price Type"]: "نوع السعر",
    ["Additional Price"]: "سعر اضافي",
    ["Pricing"]: "التسعير",
    ["Wholesale Price Method"]: "طريقة سعر الجملة",
    ["'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'"]:
      '"يدويًا" يعني أنه يجب عليك تحديد السعر يدويًا. "علائقي" يعني أن نظام GF يستخدم "عامل سعر علائقي" لتعديل السعر بناءً على أسعار سوق المعادن الحالية. "أساس التكلفة" يعني أنك تقدم تفاصيل مكونات العنصر وتعدل عوامل السعر العالمية لإعادة حساب السعر كل يوم بناءً على ظروف سوق المعادن الحالية. إذا كان حقل سعر الجملة أو حقول سعر التجزئة فارغة، فسيعرض النظام "استدعاء السعر"',
    ["Wholesale Base Price"]: "سعر الجملة الأساسي",
    ["Base Metal Market"]: "سوق المعادن الأساسية",
    ["Price Factor"]: "عامل السعر",
    ["Calculate"]: "احسب",
    ["Relational Price"]: "السعر العلائقي",
    ["Relational Metal Market"]: "سوق المعادن العلائقية",
    ["Metal Labor Code"]: "قانون العمل المعدني",
    ["Other Labor Code"]: "قانون العمل الآخر",
    ["Metal Factor Code"]: "كود عامل المعدن",
    ["GPM Code"]: "كود GPM",
    ["Retail Price Method"]: "طريقة سعر التجزئة",
    ["MSRP"]: "السعر الموصى به من الشركة المصنعة",
    ["To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At."]:
      'لتحسين جودة البيانات، يرجى إعلامنا بكيفية رغبتك في إدارة تسعير هذا المنتج. 1 - فرض سعر التجزئة المقترح. 2 - تقديم "سعر التجزئة المقترح" والسماح بزيادات التجزئة. 3 - السماح بزيادات التجزئة. 4 - الاتصال للحصول على السعر. 5 - التسعير يبدأ من.',
    ["Related"]: "متعلق ب",
    ["Matching Styles"]: "مطابقة الأنماط",
    ["Matching Sku's are items from the same ensemble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas."]:
      "تعتبر عناصر SKU المتطابقة عناصر من نفس المجموعة مثل الخاتم والأقراط والقلادة المتطابقة. أدخل معرف التاجر أو رقم الطراز مفصولاً بفواصل.",
    ["Up Sell Styles"]: "أساليب البيع الإضافي",
    ["Up sell Sku's are items that are related by at slightly higher price points."]:
      "تعتبر وحدات SKU التي يتم بيعها بشكل إضافي عبارة عن عناصر مرتبطة بنقاط سعر أعلى قليلاً.",
    ["Grouped Product Styles"]: "أنماط المنتجات المجمعة",
    ["There is no content."]: "لا يوجد محتوى.",
    ["Media"]: "وسائط",
    ["Choose Video"]: "اختر الفيديو",
    ["Load Video From URL"]: "تحميل الفيديو من الرابط",
    ["Load Video"]: "تحميل الفيديو",
    ["Add Video Type"]: "إضافة نوع الفيديو",
    ["Label"]: "ملصق",
    ["Bullet Point 1"]: "النقطة رقم 1",
    ["Bullet Point 2"]: "النقطة رقم 2",
    ["Bullet Point 3"]: "النقطة رقم 3",
    ["Bullet Point 4"]: "النقطة رقم 4",
    ["Bullet Point 5"]: "النقطة رقم 5",
    ["Discount A"]: "خصم أ",
    ["Qty 1"]: "الكمية 1",
    ["Qty 2"]: "الكمية 2",
    ["Qty 3"]: "الكمية 3",
    ["Qty 4"]: "الكمية 4",
    ["Qty 5"]: "الكمية 5",
    ["Retailer Brand Name"]: "اسم العلامة التجارية للتاجر",
    ["Secondary Metal Type"]: "نوع المعدن الثانوي",
    ["Amazon Product"]: "منتج أمازون",
    ["Total Diamond Weight"]: "الوزن الإجمالي للماس",
    ["Total Gemstone Weight"]: "الوزن الإجمالي للأحجار الكريمة",
    ["Gemstone Type"]: "نوع الأحجار الكريمة",
    ["Dimension Unit Of Measure"]: "وحدة قياس البعد",
    ["Number Of Gemstones"]: "عدد الاحجار الكريمة",
    ["Gemstone Shape"]: "شكل الأحجار الكريمة",
    ["Gemstone Origin"]: "أصل الأحجار الكريمة",
    ["Gemstone Carat Weight"]: "وزن قيراط الأحجار الكريمة",
    ["Gemstone Dimensions"]: "أبعاد الأحجار الكريمة",
    ["Gemstone Quality"]: "جودة الأحجار الكريمة",
    ["Watch"]: "يشاهد",
    ["Band Material"]: "مادة الفرقة",
    ["Number Type"]: "نوع الرقم",
    ["Band Type"]: "نوع الفرقة",
    ["Case Material"]: "مادة العلبة",
    ["Case Shape"]: "شكل العلبة",
    ["Comes Packaged In"]: "يأتي معبأ في",
    ["Crystal Type"]: "نوع الكريستال",
    ["Dial Color"]: "لون المينا",
    ["Manufacture Date"]: "تاريخ التصنيع",
    ["Display Type"]: "نوع العرض",
    ["Certification"]: "شهادة",
    ["Movement"]: "حركة",
    ["Energy"]: "طاقة",
    ["Save Product"]: "حفظ المنتج",
    ["Cancel Product"]: "إلغاء المنتج",
    ["Upload Your Images"]: "قم بتحميل صورك",
    ["Please wait while file is Uploading.."]:
      "يرجى الانتظار أثناء تحميل الملف.",
    ["Backgrounds should be white or transparent."]:
      "يجب أن تكون الخلفيات بيضاء أو شفافة.",
    ["Images should be larger than 500 x 500 pixels."]:
      "ينبغي أن تكون الصور أكبر من 500 × 500 بكسل.",
    ["Products should occupy more than 80% of canvas."]:
      "يجب أن تشغل المنتجات أكثر من 80% من القماش.",
    ["Questions? View the"]: "هل لديك أسئلة؟ عرض",
    ["Image Guidelines"]: "إرشادات الصورة",
    ["Help Doc"]: "مساعدة الوثيقة",
    ["Your File is InProgress"]: "ملفك قيد التقدم",
    ["Please click here to see history"]: "الرجاء الضغط هنا لرؤية التاريخ",
    ["Load Images From My Server"]: "تحميل الصور من الخادم الخاص بي",
    ["If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple"]:
      "إذا كنت تريد إضافة صور المجوهرات مباشرة من الخادم مثل: https://www.gemfind.com/zoomimages/80785.jpg، فقم بتحديد مربع الاختيار هذا. وتأكد أيضًا من أنك قمت بتعيين العمود IsImageFromUrl في ملفك. إذا قمت بتعيين 1 لهذا العمود، فسيتم تحميل الصورة مباشرة من الخادم وإذا قمت بتعيين 0، فسيتم التعامل مع مسار الصورة على أنه بسيط",
    ["Jewelry Import"]: "استيراد المجوهرات",
    ["Add new jewelry items in bulk or make mass item updates."]:
      "أضف عناصر مجوهرات جديدة بكميات كبيرة أو قم بإجراء تحديثات جماعية للعناصر.",
    ["Map Your Content"]: "قم بإنشاء خريطة للمحتوى الخاص بك",
    ["Match your categories with the JewelCloud industry standard making your content compatible with our network and applications."]:
      "قم بمطابقة فئاتك مع معيار صناعة JewelCloud مما يجعل المحتوى الخاص بك متوافقًا مع شبكتنا وتطبيقاتنا.",
    ["Required if your data file is not in our format."]:
      "مطلوب إذا لم يكن ملف البيانات الخاص بك بالتنسيق الخاص بنا.",
    ["Download Templates"]: "تنزيل القوالب",
    ["Required Fields Form"]: "نموذج الحقول المطلوبة",
    ["Simple Product Forms"]: "نماذج المنتجات البسيطة",
    ["Ring Builder Data Form"]: "نموذج بيانات منشئ الخاتم",
    ["Configurable Product Form"]: "نموذج المنتج القابل للتكوين",
    ["Help Docs"]: "مستندات المساعدة",
    ["Image Guideline"]: "دليل الصورة",
    ["Progress"]: "تقدم",
    ["Ok"]: "نعم",
    ["Uploaded"]: "تم التحميل",
    ["Uploading file"]: "تحميل الملف",
    ["Create Collection"]: "إنشاء مجموعة",
    ["All Collections"]: "جميع المجموعات",
    ["House Brand Collections"]: "مجموعات العلامة التجارية للمنزل",
    ["Vendor Collections"]: "مجموعات البائعين",
    ["Vendor Extensions"]: "ملحقات البائع",
    ["Collection Name"]: "اسم المجموعة",
    ["Enter Collection Name"]: "أدخل اسم المجموعة",
    ["Brand Name"]: "اسم العلامة التجارية",
    ["Override"]: "تجاوز",
    ["Enable"]: "يُمكَِن",
    ["Markup Override"]: "تجاوز العلامات",
    ["Disable"]: "إبطال",
    ["Percentage"]: "نسبة مئوية",
    ["Delete Collection"]: "حذف المجموعة",
    ["Are you sure , you want to delete this Collection."]:
      "هل أنت متأكد أنك تريد حذف هذه المجموعة؟",
    ["content not provided."]: "المحتوى غير متوفر.",
    ["General Settings"]: "الإعدادات العامة",
    ["Markup By Price Range With Certs"]:
      "تحديد السعر حسب النطاق السعري مع الشهادات",
    ["Currency"]: "عملة",
    ["Rounding"]: "التقريب",
    ["Default Markup"]: "العلامات الافتراضية",
    ["Markup"]: "العلامات التجارية",
    ["Mrk"]: "مارك",
    ["Markup By Price Range"]: "الهامش حسب نطاق السعر",
    ["Markup By Size Range"]: "الترميز حسب نطاق الحجم",
    ["Min Price"]: "الحد الأدنى للسعر",
    ["Max Price"]: "السعر الأقصى",
    ["Mark Up"]: "وضع علامة",
    ["Min Size"]: "الحجم الأدنى",
    ["Max Size"]: "الحجم الأقصى",
    ["Overrides"]: "التجاوزات",
    ["Show More Filters"]: "عرض المزيد من الفلاتر",
    ["Show Less Filters"]: "عرض فلاتر أقل",
    ["Save Pricing"]: "توفير الأسعار",
    ["Image Fills Title"]: "الصورة تملأ العنوان",
    ["Edit Collection"]: "تحرير المجموعة",
    ["Create A Collection"]: "إنشاء مجموعة",
    ["Items you add for your store's personal use. Collections can be added to "]:
      "العناصر التي تضيفها للاستخدام الشخصي في متجرك.  يمكن إضافة المجموعات",
    [" Vendor as an Extension of their official profile or your own House Brand"]:
      "البائع كامتداد لملفه الشخصي الرسمي أو علامتك التجارية الخاصة",
    ["Items you add for your store's personal use. Collections can be added to GemFind Vendor as an Extension of their official profile or your own House Brand."]:
      "العناصر التي تضيفها للاستخدام الشخصي في متجرك. يمكن إضافة المجموعات إلى GemFind Vendor كامتداد لملفهم الشخصي الرسمي أو علامتك التجارية الخاصة.",
    ["Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand"]:
      "العناصر التي تضيفها للاستخدام الشخصي لمتجرك. يمكن إضافة المجموعات إلى بائع JewelCloud كامتداد لملفه الشخصي الرسمي أو علامتك التجارية الخاصة",
    ["Raise or Lower the display order of this department. A value o '1' places it first in MasterLink."]:
      "قم برفع أو خفض ترتيب عرض هذا القسم. القيمة '1' تضعه أولاً في MasterLink.",
    ["View The Training Video"]: "شاهد فيديو التدريب",
    ["Collection Description"]: "وصف المجموعة",
    ["Enter Collection Description"]: "أدخل وصف المجموعة",
    ["Collection Display Order"]: "ترتيب عرض المجموعة",
    ["Enter Collection Order"]: "ترتيب عرض المجموعة",
    ["Navigation Category"]: "فئة الملاحة",
    ["Bridal"]: "زفافي",
    ["Fashion"]: "موضة",
    ["Giftware"]: "هدايا",
    ["Save Collection"]: "حفظ المجموعة",
    ["Import Mapping"]: "استيراد الخرائط",
    ["Export Mapping"]: "تصدير الخرائط",
    ["Add New Column"]: "إضافة عمود جديد",
    ["Attribute"]: "يصف",
    ["JewelCloud defined column name."]:
      "تم تعريف اسم العمود بواسطة JewelCloud.",
    ["This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."]:
      "يحدد هذا نوع البيانات المسموح بها. تعني العلامات الثابتة أنه يجب عليك استخدام قيمة JewelCloud معتمدة. لا يمكن أن تحتوي القيمة الرقمية إلا على أرقام وأرقام عشرية.",
    ["Requirement"]: "متطلبات",
    ["Data uploads will fail if these columns do not contain a value."]:
      "ستفشل عمليات تحميل البيانات إذا كانت هذه الأعمدة لا تحتوي على قيمة.",
    ["Your Attribute"]: "صفتك",
    ["Non Mapped"]: "غير مُصمم على الخريطة",
    ["Accepted Values"]: "القيم المقبولة",
    ["Required"]: "مطلوب",
    ["Example"]: "مثال",
    ["Field Type"]: "نوع الحقل",
    ["Configurable"]: "قابلة للتكوين",
    ["Definition"]: "تعريف",
    ["Remaining"]: "متبقي",
    ["Drag & Drop Your Attributes"]: "اسحب وأفلِت سماتك",
    ["Match Your attributes with the jewel cloud industry standard"]:
      "قم بمطابقة سماتك مع معيار صناعة السحابة الجوهرية",
    ["Attribute Value Map"]: "خريطة قيمة السمة",
    ["Match Your Variables to the jewelCloud Industry Standard"]:
      "قم بمطابقة متغيراتك مع معيار صناعة jewelCloud",
    ["Your sheet just needs your column headers."]:
      "تحتاج ورقتك فقط إلى عناوين الأعمدة الخاصة بك.",
    [".xls | .txt | .csv"]: ".xls | .txt | .csv",
    [".csv"]: ".csv",
    ["Choose Format"]: "اختر التنسيق",
    ["No content"]: "لا يوجد محتوى",
    ["Comma"]: "فاصلة",
    ["Tab"]: "فاتورة غير مدفوعة",
    ["Upload Sheet"]: "تحميل الورقة",
    ["Import Mapping Template"]: "استيراد قالب رسم الخرائط",
    ["Edge TPW"]: "حافة TPW",
    ["Arms"]: "الأسلحة",
    ["Edge EDT"]: "عطر إيدج",
    ["Load File"]: "تحميل الملف",
    ["Attribute A New Mappable Column"]: "سمة عمود قابل للتعيين جديد",
    ["Add A New Mappable Column"]: "إضافة عمود جديد قابل للتعيين",
    ["Add A Single New Column Header."]: "إضافة رأس عمود جديد واحد.",
    ["Column ID"]: "معرف العمود",
    ["Column Name"]: "اسم العمود",
    ["Image Upload Settings"]: "إعدادات تحميل الصورة",
    ["Import Multiple"]: "استيراد متعددة",
    ["True"]: "حقيقي",
    ["False"]: "خطأ شنيع",
    ["Select Delimiter"]: "حدد الفاصل",
    ["( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images."]:
      "( |(علامة الأنبوب) ,(الفاصلة)) استخدم هذا الفاصل الفردي لفصل الصور.",
    ["Add Price Type Override"]: "إضافة تجاوز نوع السعر",
    ["Enforce MSRP"]: "فرض سعر التجزئة المقترح من الشركة المصنعة",
    ["SuggestedRetail Price & Allow Retail Markups"]:
      "سعر التجزئة المقترح وزيادات التجزئة المسموح بها",
    ["Allow Retail Markups"]: "السماح بزيادات أسعار التجزئة",
    ["Call For Price"]: "اتصل للحصول على السعر",
    ["Prices Starting at"]: "الأسعار تبدأ من",
    ["Allow price type override"]: "السماح بتجاوز نوع السعر",
    ["Reset The Map"]: "إعادة تعيين الخريطة",
    ["Yikes...Go Back"]: "يا إلهي...ارجع للخلف",
    ["Remove This Mapping"]: "إزالة هذه الخريطة",
    ["Remove This Appointment"]: "إزالة هذا الموعد",
    ["Are your sure you want to reset the Data Map?"]:
      "هل أنت متأكد أنك تريد إعادة تعيين خريطة البيانات؟",
    ["Are your sure you want to remove this mapping?"]:
      "هل أنت متأكد أنك تريد إزالة هذه الخريطة؟",
    ["Are your sure you want to remove this appointment?"]:
      "هل أنت متأكد أنك تريد إزالة هذا الموعد؟",
    ["THERE IS NO UNDO"]: "لا يوجد تراجع",
    ["Diamond Chart Reports"]: "تقارير مخطط الماس",
    ["Export to PDF"]: "تصدير إلى PDF",
    ["Diamond Clicks for GemFind (720)"]: "نقرات الماس لـ GemFind (720)",
    ["Events"]: "الأحداث",
    ["Download PDF"]: "تنزيل ملف PDF",
    ["IPAddress"]: "عنوان IP",
    ["Lab Grow Diamond"]: "مختبر زراعة الماس",
    ["Round to the nearest 1"]: "التقريب إلى أقرب 1",
    ["Diamond Cut Grade Report"]: "تقرير درجة قطع الماس",
    ["Location Activity"]: "نشاط الموقع",
    ["Types"]: "أنواع",
    ["Accept New Collection"]: "قبول المجموعة الجديدة",
    ["If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"]:
      "إذا تم تحديدك، فسوف تتلقى مجموعات جديدة من هذا البائع، وإذا لم يتم تحديدك، فسوف تتلقى تحديثات للمجموعات الموجودة فقط",
    ["Retailer Affirmation"]: "تأكيد التاجر",
    ["Current Authorized Reseller"]: "الموزع المعتمد الحالي",
    ["By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website."]:
      "من خلال تحديد هذا المربع والنقر فوق زر الإرسال أدناه، أؤكد أن GemFind لديه حساب مفتوح ونشط مع A & D Gem Corp، وأنني بائع معتمد لشركة A & D Gem Corp، وأنني مسموح لي بالحصول على مخزونهم الافتراضي على موقعي على الويب.",
    ["New Account Request"]: "طلب حساب جديد",
    ["I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp."]:
      "أرغب في فتح حساب جديد لدى شركة A & D Gem Corp وأن أصبح بائعًا معتمدًا. من خلال تحديد هذا المربع والنقر فوق زر الإرسال أدناه، سيتم إرسال اسمك ومعلومات المتجر إلى شركة A & D Gem Corp.",
    ["E-Mail Address"]: "عنوان البريد الإلكتروني",
    ["Send Invitation"]: "إرسال دعوة",
    ["Last Requested"]: "آخر طلب",
    ["SKU"]: "رقم الصنف",
    ["InHouse"]: "في المنزل",
    ["Measurement"]: "قياس",
    ["Cert"]: "شهادة",
    ["Mined"]: "تم تعدينه",
    ["Princess"]: "أميرة",
    ["Pear"]: "كُمَّثرَى",
    ["Marquise"]: "ماركيز",
    ["Ideal"]: "مثالي",
    ["Excellent"]: "ممتاز",
    ["Very Good"]: "جيد جدًا",
    ["Good"]: "جيد",
    ["Fair"]: "عدل",
    ["Diamond Preview"]: "معاينة الماس",
    ["Sample Image"]: "صورة العينة",
    ["Stock Number"]: "رقم المخزون",
    ["Add To Compare"]: "أضف إلى المقارنة",
    ["SIMILAR DIAMONDS"]: "الماس مماثل",
    ["Compare"]: "يقارن",
    ["FtpDetails"]: "تفاصيل Ftp",
    ["Fancy Colored"]: "ملون بشكل خيالي",
    ["D"]: "د",
    ["E"]: "هـ",
    ["F"]: "ف",
    ["G"]: "ج",
    ["I"]: "أنا",
    ["J"]: "ج",
    ["K"]: "ك",
    ["L"]: "ل",
    ["M"]: "م",
    ["N"]: "ن",
    ["O"]: "ا",
    ["P"]: "ص",
    ["PO"]: "ب.و",
    ["OutBoundMapping"]: "التخطيط الخارجي",
    ["Lab Growm"]: "نمو المختبر",
    ["No Diamonds Listed In That Range"]: "لا يوجد الماس مدرج في هذا النطاق",
    ["Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs."]:
      "الرجاء إدخال معلومات الاتصال الخاصة بك. سيتصل بك أحد المتخصصين في الماس لدينا ويساعدك في تلبية احتياجاتك.",
    ["First Name"]: "الاسم الأول",
    ["Last Name"]: "اسم العائلة",
    ["Add Comments Here"]: "أضف تعليقات هنا",
    ["Request"]: "طلب",
    ["Please enter the details of the type of diamond you are looking for and your contact information."]:
      "يرجى إدخال تفاصيل نوع الماس الذي تبحث عنه ومعلومات الاتصال الخاصة بك.",
    ["Your information will be submitted to one of our diamond specialists that will contact you."]:
      "سيتم إرسال معلوماتك إلى أحد المتخصصين في الماس لدينا والذي سيتصل بك.",
    ["Advance"]: "يتقدم",
    ["Use your diamond comparison to save all the details of the diamonds you are considering and view them all together"]:
      "استخدم مقارنة الماس الخاصة بك لحفظ جميع تفاصيل الماس الذي تفكر فيه وعرضها جميعًا معًا",
    ["This makes it easy to choose the right diamond."]:
      "وهذا يجعل من السهل اختيار الماس المناسب.",
    ["Mined Diamonds"]: "الماس المستخرج",
    ["Lab Grown"]: "مزروع في المختبر",
    ["Color Diamonds"]: "الماس الملون",
    ["Lab Grown Diamonds"]: "الماس المزروع في المختبر",
    ["ID"]: "بطاقة تعريف",
    ["Certs"]: "الشهادات",
    ["Method"]: "طريقة",
    ["Custom Method"]: "الطريقة المخصصة",
    ["In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%"]:
      "من أجل حماية سلامة صناعة الماس والمجوهرات، يجب ألا يقل الحد الأدنى للهامش على رابط الماس عن 7%",
    ["Markup By Vendor"]: "الترميز حسب البائع",
    ["Markup By PriceRange With Certs"]:
      "تحديد السعر حسب النطاق السعري مع الشهادات",
    ["Markup By SizeRange With Certs"]: "الترميز حسب نطاق الحجم مع الشهادات",
    ["CL"]: "CL",
    ["Update Markup"]: "تحديث العلامات",
    ["GIA"]: "جيا",
    ["AGS"]: "أيه جي أس",
    ["EGL"]: "إي جي إل",
    ["IGI"]: "اي جي اي",
    ["Enable Diamonds with"]: "تمكين الماس مع",
    ["Video Only"]: "فيديو فقط",
    ["Enable Dynamic Range"]: "تمكين النطاق الديناميكي",
    ["EGL Belgium"]: "إي جي إل بلجيكا",
    ["EGL International"]: "إي جي إل الدولية",
    ["EGL Israel"]: "إي جي إل إسرائيل",
    ["EGL South Africa"]: "EGL جنوب افريقيا",
    ["EGL USA"]: "إي جي إل الولايات المتحدة الأمريكية",
    ["HRD"]: "إدارة الموارد البشرية",
    ["GCAL"]: "جي سي أي أل",
    ["None"]: "لا أحد",
    ["FNT"]: "فنت",
    ["MED"]: "ميد",
    ["ST"]: "شارع",
    ["ALL"]: "الجميع",
    ["Configurable Attribute"]: "سمة قابلة للتكوين",
    ["Shipping Charge"]: "رسوم الشحن",
    ["Update Settings"]: "تحديث الإعدادات",
    ["Standard Search"]: "البحث القياسي",
    ["Advanced Search"]: "البحث المتقدم",
    ["Fancy Colored Search"]: "بحث ملون فاخر",
    ["Lab Grown Search"]: "بحث عن المختبر المزروع",
    ["Request a Diamond"]: "اطلب الماس",
    ["Compare Diamonds"]: "مقارنة الماس",
    ["Click Here"]: "انقر هنا",
    ["New Diamond Dealer inventories are automatically added to your Diamond Dealer List. If you wish this to not occur, then"]:
      "تتم إضافة مخزونات تجار الماس الجدد تلقائيًا إلى قائمة تجار الماس الخاصة بك. إذا كنت ترغب في عدم حدوث ذلك، ثم",
    ["Left Navigation for MasterLink"]: "شريط التنقل الأيسر لـ MasterLink",
    ["Left Navigation"]: "التنقل الأيسر",
    ["Loose Diamonds"]: "الماس السائب",
    ["Same Window"]: "نفس النافذة",
    ["New Window"]: "نافذة جديدة",
    ["Inframe"]: "داخل الإطار",
    ["Our Signature Diamonds"]: "الماس المميز لدينا",
    ["Best Value Diamonds"]: "الماس ذو القيمة الأفضل",
    ["Private Reserve Diamonds"]: "الماس الاحتياطي الخاص",
    ["HeartStar Diamonds"]: "الماس هارتستار",
    ["All Vendors"]: "جميع البائعين",
    ["Stuck File"]: "ملف عالق",
    ["Change this value to set the TITLE at the top of the search results page. Leave blank for default value."]:
      "قم بتغيير هذه القيمة لتعيين العنوان في أعلى صفحة نتائج البحث. اتركه فارغًا للقيمة الافتراضية.",
    ["Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value."]:
      "قم بتغيير هذه القيمة لتعيين العنوان في أعلى صفحة تفاصيل الماس. اترك هذا الحقل فارغًا للقيمة الافتراضية.",
    ["Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعيين نص الرابط رقم 1 في أسفل صفحة نتائج البحث. اترك هذا الحقل فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعيين عنوان URL للرابط رقم 1 في أسفل صفحة نتائج البحث. اترك هذا الحقل فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعيين نص الرابط رقم 2 في أسفل صفحة نتائج البحث. اترك هذا الحقل فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعيين عنوان URL للرابط رقم 2 في أسفل صفحة نتائج البحث. اترك هذا الحقل فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعيين نص الرابط رقم 3 في أسفل صفحة نتائج البحث. اترك هذا الحقل فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعيين عنوان URL للرابط رقم 3 في أسفل صفحة نتائج البحث. اترك هذا الحقل فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only)."]:
      "قم بتغيير هذه القيمة للسماح لمستخدمي GFLink بالبحث عن أزواج متطابقة من الأحجار بالإضافة إلى الأحجار الفردية (أيضًا جميع الأحجار). اتركها فارغة للقيمة الافتراضية (الأحجار الفردية فقط).",
    ["Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page."]:
      "استخدم هذا الخيار لتمكين (نعم) أو تعطيل (لا) عرض الشعار (أي عنوان URL لصورة صفحة النتائج) في الجزء العلوي من صفحة نتائج البحث.",
    ["Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "غيّر هذه القيمة لتغيير عنوان URL المرتبط بالصورة المعروضة أعلى صفحة نتائج البحث. اتركها فارغة للقيمة الافتراضية (لا شيء). إذا أرسلت صورتك إلى GemFind لوضعها على خادم GemFind، فيمكنك إدخال اسم الملف فقط.",
    ["Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none)."]:
      "غيّر هذه القيمة لتغيير الرابط التشعبي الفعلي المرتبط بالصورة المعروضة أعلى صفحة نتائج البحث. اتركه فارغًا للقيمة الافتراضية (لا شيء).",
    ["Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "غيّر هذه القيمة لتغيير عنوان URL المرتبط بالصورة المعروضة في منتصف صفحة تفاصيل الماس الافتراضية. اتركها فارغة للقيمة الافتراضية (لا شيء). إذا أرسلت صورتك إلى GemFind لوضعها على خادم GemFind، فيمكنك إدخال اسم الملف فقط.",
    ["Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value."]:
      "قم بتغيير هذه القيمة لتغيير النص المرئي للرابط التشعبي الفعلي لتفاصيل الماس. اتركه فارغًا للقيمة الافتراضية.",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      'بشكل افتراضي، سيعرض بحثك تفاصيل الماس الأساسية (القطع واللون والوضوح والحجم والسعر والشهادة) على صفحة نتائج البحث. يمكنك اختيار عرض معلومات أكثر تفصيلاً (الجدول والعمق والقياسات) بالإضافة إلى المعلومات الأساسية على صفحة نتائج البحث. بغض النظر عن الطريقة التي تختارها، فإن التفاصيل الكاملة لكل ماسة تكون متاحة دائمًا بالنقر فوق "مزيد من المعلومات" على صفحة نتائج البحث.',
    ["You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "يمكنك اختيار البحث حسب درجة القطع إذا كنت ترغب في ذلك. يتم تمكين البحث بالجدول والعمق أيضًا لأنهما مرتبطان بشكل مباشر بدرجة القطع.",
    ["Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "اختر عدد الماسات المعروضة في كل صفحة من نتائج البحث. بالنسبة للقيم غير المعروضة، يجب عليك الاتصال بـ GemFind مباشرة.",
    ["Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form."]:
      "اختر ما إذا كنت تريد إظهار قطع الماس الأكثر غموضًا (asscher، old miner) في نموذج البحث.",
    ["Choose whether to use the interactive Macromedia Flash search form instead of the regular search form."]:
      "اختر ما إذا كنت تريد استخدام نموذج البحث التفاعلي Macromedia Flash بدلاً من نموذج البحث العادي.",
    ["Choose whether to use show popup of diamond details on the diamond inveotry page."]:
      "اختر ما إذا كنت تريد استخدام عرض نافذة منبثقة لتفاصيل الماس على صفحة مخزون الماس.",
    ["Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page."]:
      "اختر ما إذا كنت تريد عرض زر المشاركة (Facebook، Twitter، Digg وما إلى ذلك) على صفحة تفاصيل الماس.",
    ["Choose whether to link diamond search with new ring builder."]:
      "اختر ما إذا كنت تريد ربط البحث عن الماس مع منشئ الخواتم الجديد.",
    ["Choose whether to display Ring Builder image at the right column of new diamond link."]:
      "اختر ما إذا كنت تريد عرض صورة Ring Builder في العمود الأيمن لرابط الماس الجديد.",
    ["Search Page Title"]: "عنوان صفحة البحث",
    ["Diamond Detail page Title"]: "عنوان صفحة تفاصيل الماس",
    ["Hyperlink #1 TEXT at bottom of search results page"]:
      "رابط تشعبي رقم 1 في أسفل صفحة نتائج البحث",
    ["Hyperlink #1 URL at bottom"]: "رابط تشعبي رقم 1 في الأسفل",
    ["Hyperlink #2 TEXT at bottom"]: "رابط تشعبي رقم 2 النص في الأسفل",
    ["Hyperlink #2 URL at bottomof search results page"]:
      "رابط تشعبي رقم 2 في أسفل صفحة نتائج البحث",
    ["Hyperlink #3 TEXT at bottom of search results page"]:
      "رابط تشعبي رقم 3 نص في أسفل صفحة نتائج البحث",
    ["Hyperlink #3 URL at bottom of search results page"]:
      "رابط تشعبي رقم 3 في أسفل صفحة نتائج البحث",
    ["Let users search for MATCHED PAIRS"]:
      "السماح للمستخدمين بالبحث عن الأزواج المتطابقة",
    ["Show Logo on Results Page"]: "إظهار الشعار على صفحة النتائج",
    ["Show Diamond Education on Results Page"]:
      "إظهار Diamond Education على صفحة النتائج",
    ["Results page image URL"]: "رابط صورة صفحة النتائج",
    ["Results page image hyperlink URL"]: "رابط تشعبي لصورة صفحة النتائج",
    ["Diamond details page image URL"]: "رابط صورة صفحة تفاصيل الماس",
    ["Alternate diamond details link text"]: "رابط نصي لتفاصيل الماس البديل",
    ["Enhanced details in search results"]: "تفاصيل محسنة في نتائج البحث",
    ["Search By Cut Grade"]: "البحث حسب درجة القطع",
    ["Number of diamonds displayed per page"]:
      "عدد الماسات المعروضة في كل صفحة",
    ["Show odd diamond cuts on search form"]:
      "إظهار قطع الماس الغريبة في نموذج البحث",
    ["Dynamic Flash search form"]: "نموذج البحث الديناميكي بالفلاش",
    ["Show popup on Diamond inventory display page"]:
      "إظهار النافذة المنبثقة على صفحة عرض مخزون الماس",
    ["Show Share Button on Details Page (for New GF Link only)"]:
      "إظهار زر المشاركة في صفحة التفاصيل (لرابط GF الجديد فقط)",
    ["Use New Ringbuilder"]: "استخدم Ringbuilder الجديد",
    ["Show Ring Builder Image (for New GF Link only)"]:
      "إظهار صورة Ring Builder (لرابط GF الجديد فقط)",
    ["Generate API Key to get data"]: "إنشاء مفتاح API للحصول على البيانات",
    ["Generate"]: "يولد",
    ["API Documentation"]: "توثيق واجهة برمجة التطبيقات",
    ["Markup="]: "العلامة=",
    ["Markups"]: "العلامات التجارية",
    ["Setting #"]: "جلسة #",
    ["Vendor #"]: "بائع #",
    ["Metal"]: "معدن",
    ["$ Price"]: "السعر $",
    ["$ Markup"]: "$العلامة التجارية",
    ["Setup"]: "يثبت",
    ["Ring Builder URL"]: "رابط منشئ الحلقات",
    ["Enter the URL of this tool on your site to enable social sharing and dynamic sizing."]:
      "أدخل عنوان URL لهذه الأداة على موقعك لتمكين المشاركة الاجتماعية والتغيير الديناميكي للحجم.",
    ["Internal Use Link"]: "رابط الاستخدام الداخلي",
    ["Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting."]:
      'اختر الطقس لعرض رابط "للاستخدام الداخلي فقط" أسفل صفحة تفاصيل الإعداد، والذي سيسمح لك بالبحث عن رقم مخزون البائع ومعلومات الاتصال للإعداد المعروض.',
    ["Display"]: "عرض",
    ["RingSize Type"]: "نوع حجم الخاتم",
    ["Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.)."]:
      "اختر تنسيق حجم الخاتم الذي ترغب في عرضه. (أمريكي - 1، 1.5، 2، وما إلى ذلك أو بريطاني - A، A.5، B، B.5 وما إلى ذلك).",
    ["American"]: "امريكي",
    ["British"]: "بريطاني",
    ["Roman"]: "روماني",
    ["Europe"]: "أوروبا",
    ["Diamond Initially Sort Result On"]: "الماس فرز النتيجة الأولية على",
    ["Select a diamond attribute that you would like to initially sort the diamonds by."]:
      "قم باختيار سمة الماس التي ترغب في فرز الماس بناءً عليها مبدئيًا.",
    ["Ascending"]: "تصاعدي",
    ["Descending"]: "تنازلي",
    ["Default Metal Type on Setting page"]:
      "نوع المعدن الافتراضي في صفحة الإعدادات",
    ["14K White Gold"]: "ذهب أبيض عيار 14",
    ["14K Yellow Gold"]: "ذهب أصفر عيار 14",
    ["Show Advanced Search Options By Default"]:
      "إظهار خيارات البحث المتقدمة بشكل افتراضي",
    ["Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "اختر ما إذا كنت تريد عرض التصفية الاختيارية المتقدمة بشكل افتراضي (التلميع، التماثل، التألق، الشهادات... إلخ).",
    ["Rings - Show Schedule A Viewing"]: "حلقات - عرض جدول عرض أ",
    ["Choose Whether to display the link which allows the customer the ability to schedule an appointment."]:
      "اختر ما إذا كنت تريد عرض الرابط الذي يسمح للعميل بتحديد موعد.",
    ["Show Addresses"]: "إظهار العناوين",
    ["Choose Whether to display your company address and contact information within the RingBuilder."]:
      "اختر ما إذا كنت تريد عرض عنوان شركتك ومعلومات الاتصال داخل RingBuilder.",
    ["Show Center Stone Size"]: "إظهار حجم الحجر المركزي",
    ["Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings."]:
      "تحذير: سيؤدي تعطيل هذا الخيار إلى إزالة قيد نطاق القيراط لإعدادات الخاتم، وقد يؤدي إلى زيادة أسعار التكلفة عما هو مذكور لإعداد الخاتم المحدد. لمزيد من المعلومات، تابع مع Overnight Mountings.",
    ["Show Price"]: "عرض السعر",
    ["Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”."]:
      'اختر ما إذا كنت تريد عرض أسعار إعدادات الخاتم أم لا. إذا اخترت لا، فسيتم عرض "الاتصال للحصول على السعر" بدلاً من ذلك.',
    ["Apply GST Tax"]: "تطبيق ضريبة السلع والخدمات",
    ["Toggle the ability to apply international tax."]:
      "قم بتبديل القدرة على تطبيق الضريبة الدولية.",
    ["GST Tax Value"]: "قيمة ضريبة السلع والخدمات",
    ["Set the value when applying the GST Tax option."]:
      "قم بتعيين القيمة عند تطبيق خيار ضريبة السلع والخدمات.",
    ["Social"]: "اجتماعي",
    ["Show Pinterest Share"]: "عرض مشاركة بينتريست",
    ["Choose whether to display the Pinterest “Pin it” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "Pin it" الخاص بـ Pinterest أم لا.',
    ["Show Twitter Share"]: "عرض مشاركة تويتر",
    ["Choose whether to display the Twitter “Tweet” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "تغريد" على تويتر أم لا.',
    ["Show Facebook Share"]: "عرض مشاركة الفيسبوك",
    ["Choose whether to display the Facebook “Share” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "مشاركة" على Facebook أم لا.',
    ["Show Facebook Like"]: "إظهار إعجابك بالفيسبوك",
    ["Choose whether to display the Facebook “Like” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "الإعجاب" على فيسبوك أم لا.',
    ["Show Google Plus"]: "عرض جوجل بلس",
    ["Choose whether to display the Google “G+1” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "G+1" الخاص بـ Google أم لا.',
    ["Choose whether to display the Instagram “Share” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "مشاركة" على Instagram أم لا.',
    ["E-Commerce"]: "التجارة الإلكترونية",
    ["Show Add To Cart Button"]: 'إظهار زر "إضافة إلى سلة التسوق"',
    ["Alternate Shopping Cart"]: "عربة التسوق البديلة",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "قم بتغيير هذه القيمة لتغيير الارتباط التشعبي الفعلي إلى عربة التسوق. يمكن تغيير هذا للارتباط بصفحة عربة التسوق الخاصة بك. يتم تمرير معلمات إضافية لتفاصيل الماس والإعداد في عنوان URL عندما تكون هذه القيمة موجودة. اتركها فارغة للقيمة الافتراضية.",
    ["Show Buy Setting Only"]: "إظهار إعداد الشراء فقط",
    ["Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond."]:
      'اختر ما إذا كنت تريد عرض زر "شراء الإعداد فقط" أم لا. يتيح هذا للعميل استخدام RingBuilder لشراء إعداد الخاتم فقط وليس الخاتم المكتمل بالماس.',
    ["Show Buy Diamond Only"]: "عرض شراء الماس فقط",
    ["Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring."]:
      'اختر ما إذا كنت تريد عرض زر "شراء الماس فقط" أم لا. يتيح هذا للعميل استخدام RingBuilder لشراء الماس فقط وليس الخاتم المكتمل.',
    ["View Cart"]: "عرض عربة التسوق",
    ["Button"]: "زر",
    ["Choose Whether to display the PayPal shopping cart's view button or Not"]:
      "اختر ما إذا كنت تريد عرض زر عرض عربة التسوق الخاصة بـ PayPal أم لا",
    ["No content."]: "لا يوجد محتوى.",
    ["Page Alignment"]: "محاذاة الصفحة",
    ["Center"]: "مركز",
    ["Left"]: "غادر",
    ["Right"]: "يمين",
    ["Show Social Sharing Buttons"]: "إظهار أزرار المشاركة الاجتماعية",
    ["Show View In Store"]: "عرض العرض في المتجر",
    ["Show Pop Up On Diamond Search"]: "إظهار النافذة المنبثقة في بحث الماس",
    ["Diamond Search Result Page Size"]: "حجم صفحة نتائج البحث عن الماس",
    ["Rings - Search Result Page Size"]: "الخواتم - حجم صفحة نتائج البحث",
    ["Rings - Initially Sort Result On"]: "الحلقات - فرز النتيجة مبدئيًا",
    ["Setting Number"]: "إعداد الرقم",
    ["Send Email To Friend"]: "إرسال بريد إلكتروني إلى صديق",
    ["Send Email To Retailer"]: "إرسال بريد إلكتروني إلى التاجر",
    ["Send Email To Sender"]: "إرسال بريد إلكتروني إلى المرسل",
    ["Request A Diamond Email"]: "طلب بريد إلكتروني الماسي",
    ["Request A Diamond"]: "طلب الماس",
    ["Send Mail to Request A Diamond"]: "إرسال بريد إلكتروني لطلب الماس",
    ["Wishlist"]: "قائمة الرغبات",
    ["Bridal Registry"]: "سجل الزفاف",
    ["Shopping Cart"]: "عربة التسوق",
    ["Show Store Hours"]: "عرض ساعات العمل بالمتجر",
    ["Website URL"]: "عنوان الموقع",
    ["Google Analytic ID"]: "معرف Google Analytics",
    ["Show Only MSRP Prices"]: "عرض أسعار التجزئة الموصى بها فقط",
    ["Masterlink Theme"]: "موضوع ماسترلينك",
    ["Show View"]: "عرض العرض",
    ["New MasterLink Use In Iframe?"]: "استخدام MasterLink الجديد في Iframe؟",
    ["Login URL"]: "رابط تسجيل الدخول",
    ["Show WholeSale Price Internal Use Link?"]:
      "إظهار رابط الاستخدام الداخلي لسعر الجملة؟",
    ["Show Address/Phone Details?"]: "إظهار تفاصيل العنوان/الهاتف؟",
    ["Diamond Search / Ringbuilder load from Masterlink?"]:
      "بحث الماس / تحميل Ringbuilder من Masterlink؟",
    ["Diamond Image URL"]: "رابط صورة الماس",
    ["Vendor Details open or closed by default in new masterlink"]:
      "تفاصيل البائع مفتوحة أو مغلقة بشكل افتراضي في الرابط الرئيسي الجديد",
    ["Show Additional PopUp in new masterlink"]:
      "إظهار نافذة منبثقة إضافية في الرابط الرئيسي الجديد",
    ["Shopping Cart page message"]: "رسالة صفحة عربة التسوق",
    ["Hearts On Fire Link"]: "رابط قلوب مشتعلة",
    ["Show Setting Name at Setting Grid"]:
      "إظهار اسم الإعداد في شبكة الإعدادات",
    ["Show Ring Builder with One Step"]: "عرض Ring Builder بخطوة واحدة",
    ["Show Default Ring Size"]: "إظهار حجم الخاتم الافتراضي",
    ["Shop Diamonds"]: "متجر الماس",
    ["Shop Bridal & Engagement"]: "متجر الزفاف والخطوبة",
    ["Shop Designers"]: "مصممي المتاجر",
    ["Shop Jewelry"]: "متجر المجوهرات",
    ["Shop Watches"]: "متجر الساعات",
    ["Grid"]: "شبكة",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."]:
      'اختر ما إذا كنت تريد عرض رابط "للاستخدام الداخلي فقط" أسفل صفحة تفاصيل الماس، والذي سيسمح لك بالبحث عن رقم مخزون البائع ومعلومات الاتصال الخاصة بالماس الذي تم عرضه.',
    ["Sticky Header Height"]: "ارتفاع الرأس اللاصق",
    ["Enter a Sticky Header Height."]: "أدخل ارتفاع الرأس الملصق.",
    ["Platinum Education"]: "التعليم البلاتيني",
    ["Provides a link to copy for platinum education in Ring Builder."]:
      "يوفر رابطًا للنسخ للتعليم البلاتيني في Ring Builder.",
    ["Ring Size Guide URL"]: "دليل مقاسات الخاتم",
    ["Provides a link to copy for ring sizing guides provided by GemFind."]:
      "يوفر رابطًا لنسخ أدلة تحديد مقاسات الخواتم المقدمة من GemFind.",
    ["Show Breadcrumbs"]: "عرض فتات الخبز",
    ["Shows navigation path a customer took to get to page they're on."]:
      "يُظهر مسار التنقل الذي اتخذه العميل للوصول إلى الصفحة التي يتواجد بها.",
    ["Create Profile"]: "إنشاء الملف الشخصي",
    ["text"]: "نص",
    ["Provide alternate text for user registration Button."]:
      "توفير نص بديل لزر تسجيل المستخدم.",
    ["Sort Products by Price Initially By"]:
      "فرز المنتجات حسب السعر مبدئيًا حسب",
    ["Allow Customers to Sort Product By Price."]:
      "السماح للعملاء بفرز المنتج حسب السعر.",
    ["Price: Low - High"]: "السعر: منخفض - مرتفع",
    ["Price: High - Low"]: "السعر: مرتفع - منخفض",
    ["Collection Filtering"]: "تصفية المجموعة",
    ["When navigating Designer Landing pages - show collections instead of categories."]:
      "عند التنقل عبر صفحات الهبوط المخصصة للمصممين - اعرض المجموعات بدلاً من الفئات.",
    ["Schedule Viewing"]: "جدولة المشاهدة",
    ["Allow Customers to schedule a meeting with you."]:
      "السماح للعملاء بتحديد موعد اجتماع معك.",
    ["Designer Tile Layout"]: "تصميم بلاط مصمم",
    ["Show Back to Top Button."]: "إظهار زر العودة إلى الأعلى.",
    ["Enabled Back To Top"]: "تم تمكين العودة إلى الأعلى",
    ["Allow address to show on detailpage"]:
      "السماح بعرض العنوان على صفحة التفاصيل",
    ["Text"]: "نص",
    ["(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."]:
      '(عند إيقاف تشغيل الأسعار، سيتم عرض "الاتصال للحصول على السعر" بشكل افتراضي. يتيح لك هذا الخيار استبدال "الاتصال للحصول على السعر" بنص بديل (على سبيل المثال "الاتصال للحصول على التوفر).',
    ["Starting At Price"]: "بدءا من السعر",
    ["Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."]:
      'قم بتوفير نص البادئة (سيتضمن السعر) عندما يستخدم المنتج نوع السعر 5 (لا يمكن إضافة المنتجات التي تحتوي على نوع السعر "يبدأ من السعر" (5) إلى عربة التسوق).',
    ["instead of the Price"]: "بدلا من السعر",
    ["Hides the retail price for products using Price Type 1,2,or 3 ."]:
      "يخفي سعر التجزئة للمنتجات التي تستخدم نوع السعر 1 أو 2 أو 3.",
    ["Facebook App ID"]: "معرف تطبيق الفيسبوك",
    ["Connect MasterLink to your Facebook page, using your Facebook App ID."]:
      "قم بتوصيل MasterLink بصفحتك على Facebook باستخدام معرف تطبيق Facebook الخاص بك.",
    ["Facebook Like Page URL"]: "رابط صفحة الإعجاب على الفيسبوك",
    ["Allow Like access to your facebook account by providing the URL."]:
      "اسمح لـ Like بالوصول إلى حساب Facebook الخاص بك عن طريق توفير عنوان URL.",
    ["Allow Customers to post your products to their Pinterest boards."]:
      "السماح للعملاء بنشر منتجاتك على لوحات Pinterest الخاصة بهم.",
    ["Allow Customers to share your products to their Twitter account."]:
      "السماح للعملاء بمشاركة منتجاتك على حساباتهم على تويتر.",
    ["Allow Customers to share your products to their Facebook page."]:
      "السماح للعملاء بمشاركة منتجاتك على صفحاتهم على الفيسبوك.",
    ["Allow Customers to like your products directly from your website."]:
      "السماح للعملاء بالإعجاب بمنتجاتك مباشرة من موقع الويب الخاص بك.",
    ["SEO"]: "تحسين محركات البحث",
    ["Masterlink Default Title"]: "عنوان Masterlink الافتراضي",
    ["Enter a title for search engine optimization."]:
      "أدخل عنوانًا لتحسين محرك البحث.",
    ["Masterlink Meta Keywords"]: "كلمات رئيسية في رابط ماستر",
    ["Enter a Keywords for search engine optimization."]:
      "أدخل الكلمات الرئيسية لتحسين محرك البحث.",
    ["Masterlink Meta Description"]: "وصف ميتا لـ Masterlink",
    ["Enter a description for search engine optimization."]:
      "أدخل وصفًا لتحسين محرك البحث.",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."]:
      "قم بتغيير هذه القيمة لتغيير الرابط التشعبي الفعلي إلى عربة التسوق. يمكن تغيير هذا للربط بصفحة عربة التسوق الخاصة بك.",
    ["Enable the View Cart button for customers to review their products selections."]:
      "قم بتفعيل زر عرض سلة التسوق ليتمكن العملاء من مراجعة اختيارات منتجاتهم.",
    ["Keywords"]: "الكلمات الرئيسية",
    ["New Favourite List"]: "قائمة المفضلة الجديدة",
    ["New Favourite Items Added"]: "تمت إضافة عناصر مفضلة جديدة",
    ["Image Fills Tile"]: "صورة تملأ البلاط",
    ["Create Your Own Diamond Studs in 3 easy steps with Stud Builder"]:
      "اصنع أقراطك الماسية بنفسك في 3 خطوات سهلة باستخدام Stud Builder",
    ["Select Your Diamonds"]: "اختر الماس الخاص بك",
    ["Choose Your Mounting"]: "اختر التركيب الخاص بك",
    ["Complete Your Earrings"]: "أكمل أقراطك",
    ["Standard"]: "معيار",
    ["Add To Stud"]: "أضف إلى الدراسة",
    ["Advanced"]: "متقدم",
    ["Diamonds Markup"]: "هامش ربح الماس",
    ["Diamond Drop A Hint"]: "تلميح من الماس",
    ["Stud Builder URL"]: "رابط منشئ الأزرار",
    ["Stud Builder Shopping cart configuration instructions"]:
      "تعليمات تكوين عربة التسوق الخاصة بـ Stud Builder",
    ["Internal Use Link?"]: "رابط الاستخدام الداخلي؟",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."]:
      'اختر ما إذا كنت تريد عرض رابط "للاستخدام الداخلي فقط" أسفل صفحة تفاصيل العنصر، والذي سيسمح لك بالبحث عن رقم مخزون البائع ومعلومات الاتصال الخاصة بزوج الماس الذي تمت مشاهدته.',
    ["Only Diamond Search"]: "البحث عن الماس فقط",
    ["Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."]:
      "اختر ما إذا كنت تريد عرض جزء البحث عن الماس فقط من Stud Builder والذي يجمع بين الماس ذي السمات المتشابهة.",
    ["Show Dealer SKU In Diamond Search"]: "إظهار رمز SKU للتاجر في بحث الماس",
    ["Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."]:
      "اختر ما إذا كنت تريد عرض رقم مخزون التاجر/البائع بدلاً من رقم SKU الفريد الافتراضي لـ GemFind (على سبيل المثال رقم SKU 108545370).",
    ["Choose whether to display the link which allows the customer the ability to schedule an appointment."]:
      "اختر ما إذا كنت تريد عرض الرابط الذي يسمح للعميل بتحديد موعد.",
    ["Allow address to show on detailpage."]:
      "السماح بعرض العنوان على صفحة التفاصيل.",
    ["Choose whether to display “Call for Price” instead of the retail price or not."]:
      'اختر ما إذا كنت تريد عرض "طلب السعر" بدلاً من سعر التجزئة أم لا.',
    ["Shopping Cart Url"]: "رابط عربة التسوق",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "قم بتغيير هذه القيمة لتغيير الارتباط التشعبي الفعلي إلى عربة التسوق. يمكن تغيير هذا للارتباط بصفحة عربة التسوق الخاصة بك. يتم تمرير معلمات تفاصيل الماس الإضافية في عنوان URL عندما تكون هذه القيمة موجودة. اتركها فارغة للقيمة الافتراضية.",
    ["Choose whether to display the “Add to Cart” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "إضافة إلى سلة التسوق" أم لا.',
    ["Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not."]:
      'اختر ما إذا كنت تريد عرض زر "عرض سلة التسوق" الخاص بـ PayPal أم لا.',
    ["Generate Sync"]: "إنشاء المزامنة",
    ["Generate Full Sync"]: "إنشاء مزامنة كاملة",
    ["File Name"]: "اسم الملف",
    ["Folder"]: "مجلد",
    ["Enter FTP Detail for SYNC File Below"]: "أدخل تفاصيل FTP لملف SYNC أدناه",
    ["User Id"]: "معرف المستخدم",
    ["Is SSL?"]: "هل SSL؟",
    ["Configure & Customize Your"]: "تكوين وتخصيص",
    ["Pre-Owned Rolex Watch"]: "ساعة رولكس مستعملة",
    ["E-Mail A Friend"]: "أرسل بريدا إلكترونيا إلى صديق",
    ["Print Details"]: "تفاصيل الطباعة",
    ["Have a question regarding this item? Our specialist are available to assist you. | Call us:"]:
      "هل لديك سؤال بخصوص هذا العنصر؟ متخصصونا متاحون لمساعدتك. | اتصل بنا:",
    ["Items Details"]: "تفاصيل العناصر",
    ["STEP 1 | Size & Bracelet Type"]: "الخطوة 1 | الحجم ونوع السوار",
    ["Choose Watch Type"]: "اختر نوع الساعة",
    ["Men's"]: "مِلك الرجال",
    ["Ladies"]: "سيداتي",
    ["Mid Size"]: "الحجم المتوسط",
    ["Choose Bracelet Style"]: "اختر نمط السوار",
    ["Jubilee"]: "اليوبيل",
    ["Oyster"]: "محار",
    ["President"]: "رئيس",
    ["Choose Bracelet Metal"]: "اختر معدن السوار",
    ["Stainless Steel"]: "الفولاذ المقاوم للصدأ",
    ["18K Y/SS"]: "18 ألف سنة/سنة",
    ["18K Yellow"]: "18 كيلو أصفر",
    ["18K White"]: "18 كيلو أبيض",
    ["STEP 2 | Bezel Type"]: "الخطوة 2 | نوع الإطار",
    ["Choose Bezel Type"]: "اختر نوع الإطار",
    ["Choose Bezel Metal"]: "اختر إطار معدني",
    ["Choose Bezel Gemstone Type"]: "اختر نوع الأحجار الكريمة",
    ["Choose Bezel Gemstone Shape"]: "اختر شكل حجر الكريمة",
    ["Choose Bezel Diamond Quality"]: "اختر جودة الماس في الإطار",
    ["STEP 3 | Dial Type"]: "الخطوة 3 | نوع الاتصال الهاتفي",
    ["Choose Dial Type"]: "اختر نوع الاتصال الهاتفي",
    ["Marker Type"]: "نوع العلامة",
    ["Custom Dial Colors"]: "ألوان الميناء المخصصة",
    ["Specifications"]: "تحديد",
    ["Size & Bracelet Type (STEP 1)"]: "الحجم ونوع السوار (الخطوة 1)",
    ["SKU#"]: "رقم المنتج#",
    ["Watch Type"]: "نوع الساعة",
    ["Men"]: "الرجال",
    ["Bracelet Style"]: "نمط السوار",
    ["Bracelet Metal"]: "سوار معدني",
    ["Bezel Type (STEP 2)"]: "نوع الإطار (الخطوة 2)",
    ["Custom Bezel Type"]: "نوع الإطار المخصص",
    ["Custom Bezel Metal"]: "إطار معدني مخصص",
    ["Custom Bezel Gemstones"]: "أحجار كريمة مخصصة",
    ["Custom Bezel Gemstone Shape"]: "شكل الأحجار الكريمة المخصصة",
    ["Custom Bezel Diamond Quality"]: "جودة الماس في إطار مخصص",
    ["Total Carat Weight"]: "الوزن الإجمالي للقيراط",
    ["Dial Type (STEP 3)"]: "نوع الاتصال الهاتفي (الخطوة 3)",
    ["Dial Type"]: "نوع الاتصال الهاتفي",
    ["Custom Dial Color"]: "لون المينا المخصص",
    ["Your Name"]: "اسمك",
    ["Your E-Mail Address"]: "عنوان بريدك  الإلكتروني",
    ["Your Company Name"]: "اسم شركتك",
    ["Your Phone Number"]: "رقم هاتفك",
    ["Your Store Phone Number"]: "رقم هاتف متجرك",
    ["Write A Personal Message"]: "اكتب رسالة شخصية",
    ["Hint Recipient's Name"]: "تلميح اسم المستلم",
    ["Hint Recipient's E-Mail"]: "تلميح البريد الإلكتروني للمستلم",
    ["Reason For This Gift"]: "سبب هذه الهدية",
    ["Gift Deadline"]: "الموعد النهائي للهدايا",
    ["Add A Personal Message Here"]: "أضف رسالة شخصية هنا",
    ["Friend E-Mail Address"]: "عنوان البريد الإلكتروني لصديق",
    ["Subject"]: "موضوع",
    ["Schedule"]: "جدول",
    ["Your Email"]: "بريدك الإلكتروني",
    ["Appointment Date"]: "تاريخ الموعد",
    ["Add A Message Here"]: "أضف رسالة هنا",
    ["Get Directions"]: "احصل على الاتجاهات",
    ["Watch Customizer Options"]: "خيارات تخصيص الساعة",
    ["Watch Customizer URL"]: "رابط أداة تخصيص الساعة",
    ["Update Setting"]: "تحديث الإعدادات",
    ["Symetry"]: "التماثل",
    ["Choose Your Setting"]: "اختر الإعداد الخاص بك",
    ["Complete Your Pendant"]: "أكمل قلادتك",
    ["Add To Your Pendant"]: "أضف إلى قلادتك",
    ["Pendant Builder URL"]: "رابط منشئ المعلقات",
    ["Enter a URL."]: "أدخل عنوان URL.",
    ["Show Advanced Search As Default"]: "إظهار البحث المتقدم كخيار افتراضي",
    ["Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "اختر ما إذا كنت تريد عرض التصفية الاختيارية المتقدمة بشكل افتراضي (التلميع، التماثل، التألق، الشهادات... إلخ.)",
    ["Choose whether to display the “View Cart” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "عرض سلة التسوق" أم لا.',
    ["Total items"]: "مجموع العناصر",
    ["Total Cost"]: "التكلفة الاجمالية",
    ["Purchase order date"]: "تاريخ طلب الشراء",
    ["Order Confirm Date"]: "تاريخ تأكيد الطلب",
    ["Sale Per"]: "البيع لكل",
    ["Confirm"]: "يتأكد",
    ["Quantity"]: "كمية",
    ["Order Date"]: "تاريخ الطلب",
    ["Folder Path"]: "مسار المجلد",
    ["Choose File To Upload"]: "اختر الملف الذي تريد تحميله",
    ["Filter By Trade Show"]: "تصفية حسب المعرض التجاري",
    ["Trade Show 1"]: "المعرض التجاري 1",
    ["Trade Show 2"]: "المعرض التجاري 2",
    ["Trade Show 3"]: "المعرض التجاري 3",
    ["Buyer"]: "المشتري",
    ["Are you sure to update Permission?"]: "هل أنت متأكد من تحديث الأذونات؟",
    ["Report Permission Control"]: "التحكم في أذونات التقرير",
    ["Update Date on which"]: "تحديث التاريخ الذي",
    ["Is Created"]: "تم إنشاء",
    ["Select Vendor"]: "حدد البائع",
    ["Select Vendor Status"]: "حدد حالة البائع",
    ["Select Status"]: "حدد الحالة",
    ["Company name is compulsory"]: "اسم الشركة إلزامي",
    ["Street address is compulsory"]: "عنوان الشارع إلزامي",
    ["City is compulsory"]: "المدينة إلزامية",
    ["City is invalid"]: "المدينة غير صالحة",
    ["State is compulsory"]: "الدولة ملزمة",
    ["State is invalid"]: "الحالة غير صالحة",
    ["Zip code is compulsory"]: "الرمز البريدي إلزامي",
    ["Zip code is invalid"]: "الرمز البريدي غير صالح",
    ["Kindly select the country"]: "الرجاء اختيار البلد",
    ["Contact name is compulsory"]: "اسم جهة الاتصال إلزامي",
    ["Email address is compulsory"]: "عنوان البريد الإلكتروني إلزامي",
    ["Email address is invalid"]: "عنوان البريد الإلكتروني غير صالح",
    ["Phone number is compulsory"]: "رقم الهاتف إلزامي",
    ["Phone number is invalid"]: "رقم الهاتف غير صالح",
    ["Username is compulsory"]: "اسم المستخدم إلزامي",
    ["Password is compulsory"]: "كلمة المرور إلزامية",
    ["Password should have a spe"]: "يجب أن تحتوي كلمة المرور على حرف خاص",
    ["Kindly select the permission type"]: "الرجاء تحديد نوع الإذن",
    ["Kindly select the retailer status"]: "الرجاء تحديد حالة التاجر",
    ["Kindly select the vendor status"]: "الرجاء تحديد حالة البائع",
    ["RetailerCore 1"]: "ريتيلير كور 1",
    ["RetailerCore 2"]: "ريتيلير كور 2",
    ["RetailerCore 3"]: "ريتيلير كور 3",
    ["RetailerCore 4"]: "ريتيلير كور 4",
    ["RetailerCore 5"]: "ريتيلير كور 5",
    ["RetailerCore 6"]: "ريتيلير كور 6",
    ["RetailerCore 7"]: "ريتيلير كور 7",
    ["RetailerCore 8"]: "ريتيلير كور 8",
    ["RetailerCore 9"]: "ريتيلير كور 9",
    ["RetailerCore 10"]: "ريتيلير كور 10",
    ["RetailerCore 11"]: "ريتيلير كور 11",
    ["RetailerCore 12"]: "ريتيلير كور 12",
    ["RetailerCore 13"]: "ريتيلير كور 13",
    ["Retailer Id is compulsory"]: "معرف التاجر إلزامي",
    ["Retailer Id is Invalid"]: "معرف التاجر غير صالح",
    ["Kindly select the core"]: "يرجى تحديد النواة",
    ["Limit Type is compulsory"]: "نوع الحد إلزامي",
    ["Please first select record to delete."]:
      "الرجاء أولاً تحديد السجل الذي تريد حذفه.",
    ["Kindly select the retailer"]: "الرجاء اختيار التاجر",
    ["Kindly select the Diamond Dealer"]: "الرجاء اختيار تاجر الماس",
    ["Add a Dealer to all GFLinks: Select the DealerID you want to Add to all GFLinks."]:
      "إضافة تاجر إلى جميع GFLinks: حدد معرف التاجر الذي تريد إضافته إلى جميع GFLinks.",
    ["Kindly select the Vendor"]: "الرجاء اختيار البائع",
    ["No Dealer Selected"]: "لم يتم اختيار أي تاجر",
    ["Please Select Dealer."]: "الرجاء اختيار التاجر.",
    ["Select Dealer ID"]: "حدد معرف التاجر",
    ["Select File Type"]: "حدد نوع الملف",
    ["Kindly select Dealer ID"]: "الرجاء تحديد معرف التاجر",
    ["FTP Username is compulsory"]: "اسم مستخدم FTP إلزامي",
    ["FTP Password is compulsory"]: "كلمة مرور FTP إلزامية",
    ["Kindly select File Type"]: "الرجاء تحديد نوع الملف",
    ["Path is Compulsory"]: "المسار إلزامي",
    ["Dealer ID is compulsory"]: "معرف التاجر إلزامي",
    ["Api Url is compulsory"]: "عنوان URL لـ API إلزامي",
    ["Api Key is compulsory"]: "مفتاح API إلزامي",
    ["Are you sure you want to discard ?"]:
      "هل أنت متأكد أنك تريد التخلص منها؟",
    ["Are you sure you want to discard all ?"]:
      "هل أنت متأكد أنك تريد التخلص من الكل؟",
    ["Enter valid DealerID"]: "أدخل معرف التاجر الصحيح",
    ["All Designer"]: "جميع المصممين",
    ["Please select designer."]: "الرجاء اختيار المصمم.",
    ["Please select image."]: "الرجاء اختيار الصورة.",
    ["View Inquiry"]: "عرض الاستفسار",
    ["Export"]: "يصدّر",
    ["Jewelry Retailer Click Report"]: "تقرير النقرات لبائعي المجوهرات",
    ["Jewelry Vendor Click Report"]: "تقرير النقرات لبائعي المجوهرات",
    ["Global Diamond"]: "الماس العالمي",
    ["Extended Diamond Chart Report"]: "تقرير الرسم البياني الماسي الموسع",
    ["Global Jewelry"]: "المجوهرات العالمية",
    ["Jewelry Category Reports"]: "تقارير فئة المجوهرات",
    ["Jewelry Metal Type Reports"]:
      "تقارير عن أنواع المعادن المستخدمة في صناعة المجوهرات",
    ["Page number is compulsory"]: "رقم الصفحة إلزامي",
    ["First Tier Page Name is compulsory"]: "اسم الصفحة الأولى إلزامي",
    ["Second Tier Page Name is compulsory"]: "اسم الصفحة الثانية إلزامي",
    ["Third Tier Page Name is compulsory"]: "اسم الصفحة الثالثة إلزامي",
    [".JPG | .JPEG | .PNG Format"]: "تنسيق .JPG | .JPEG | .PNG",
    ["Select Start Time"]: "حدد وقت البدء",
    ["Select End Time"]: "حدد وقت الانتهاء",
    ["Please select the image"]: "الرجاء اختيار الصورة",
    ["ShowName is compulsory"]: "إظهار الاسم إلزامي",
    ["Website is compulsory"]: "الموقع إلزامي",
    ["Kindly select the Show Start Time"]: "الرجاء تحديد وقت بدء العرض",
    ["Kindly select the Show End Time"]: "الرجاء تحديد وقت انتهاء العرض",
    ["Kindly select the Show Start Date"]: "الرجاء تحديد تاريخ بدء العرض",
    ["Kindly select the Show End Date"]: "الرجاء تحديد تاريخ انتهاء العرض",
    ["Color Name is compulsory"]: "اسم اللون إلزامي",
    ["Select"]: "يختار",
    ["Please select compose."]: "الرجاء تحديد التأليف.",
    ["Please enter name."]: "الرجاء إدخال الاسم.",
    ["Please enter email."]: "الرجاء إدخال البريد الإلكتروني.",
    ["Email is not valid."]: "البريد الإلكتروني غير صالح.",
    ["Please enter subject."]: "الرجاء إدخال الموضوع.",
    ["Please enter summary."]: "الرجاء إدخال الملخص.",
    ["Option 1"]: "الخيار 1",
    ["Option 2"]: "الخيار الثاني",
    ["GIA Website"]: "موقع GIA",
    ["Yes"]: "نعم",
    ["Borderline"]: "خط الحدود",
    ["No"]: "لا",
    ["Very Slight"]: "طفيفة جدا",
    ["Slight"]: "طفيف",
    ["Faint"]: "إِغماء",
    ["Medium"]: "واسطة",
    ["Strong"]: "قوي",
    ["Very Strong"]: "قوي جدا",
    ["No Yellow"]: "لا يوجد اصفر",
    ["No Green"]: "لا يوجد لون أخضر",
    ["No Grey"]: "لا يوجد رمادي",
    ["No Black"]: "لا يوجد اسود",
    ["No Pink"]: "لا يوجد لون وردي",
    ["No Blue"]: "لا يوجد أزرق",
    ["No Brown"]: "لا بني",
    ["Light"]: "ضوء",
    ["Heavy"]: "ثقيل",
    ["Extremely Thin"]: "رفيع للغاية",
    ["Very Thin"]: "نحيف جداً",
    ["Thin"]: "رفيع",
    ["Slightly Thin"]: "رقيقة قليلا",
    ["Slightly Thick"]: "سميكة قليلا",
    ["Thick"]: "سميك",
    ["Very Thick"]: "سميك جدا",
    ["Extremely Thick"]: "سميكة للغاية",
    ["Extra Thin"]: "رفيع للغاية",
    ["Bearding"]: "لحية",
    ["Brown Patch of Color"]: "بقعة بنية اللون",
    ["Bruise"]: "كدمة",
    ["Cavity"]: "تجويف",
    ["Chip"]: "رقاقة",
    ["Cleavage"]: "انقسام",
    ["Cloud"]: "سحاب",
    ["Crystal"]: "كريستال",
    ["Crystal Surface"]: "سطح الكريستال",
    ["Etch Channel"]: "قناة الحفر",
    ["Extra Facet"]: "جانب إضافي",
    ["Feather"]: "ريشة",
    ["Flux Remnant"]: "بقايا التدفق",
    ["Indented Natural"]: "مسنن طبيعي",
    ["Internal Graining"]: "التحبيب الداخلي",
    ["Internal Inscription"]: "النقش الداخلي",
    ["Internal Laser Drilling"]: "الحفر الداخلي بالليزر",
    ["Knot"]: "عقدة",
    ["Laser Drill Hole"]: "ثقب الحفر بالليزر",
    ["Manufacturing Remnant"]: "بقايا التصنيع",
    ["Minor Details of Polish"]: "تفاصيل بسيطة عن اللغة البولندية",
    ["Natural"]: "طبيعي",
    ["Needly"]: "بحاجة لذلك",
    ["No Inclusion"]: "لا يوجد إدماج",
    ["Pinpoint"]: "تحديد الموقع",
    ["Reflecting Surface Graining"]: "حبيبات السطح العاكس",
    ["Surface Graining"]: "حبيبات السطح",
    ["Twinning Wisp"]: "توينينج ويسب",
    ["Small"]: "صغير",
    ["Very Small"]: "صغير جدا",
    ["Large"]: "كبير",
    ["Very Large"]: "كبير جداً",
    ["Abraded"]: "متآكل",
    ["Chipped"]: "مُشَقَّقَة",
    ["Pointed"]: "مدبب",
    ["Laser Drilled"]: "مثقوب بالليزر",
    ["Clarity Enhanced"]: "تم تحسين الوضوح",
    ["Color Enhanced"]: "تحسين اللون",
    ["Irradiated"]: "مُشعع",
    ["HPHT"]: "ارتفاع ضغط الدم الشرياني الحاد",
    ["Search All Stones"]: "البحث عن جميع الأحجار",
    ["Search Single Stone"]: "البحث عن حجر واحد",
    ["Search Match Pairs"]: "البحث عن أزواج مطابقة",
    ["Emerald"]: "زمرد",
    ["SI1"]: "سي1",
    ["VG"]: "نباتي",
    ["B&H Diamonds"]: "الماس B&H",
    ["Chicago, IL"]: "شيكاغو، إلينوي",
    ["Category 1"]: "الفئة 1",
    ["Category 2"]: "الفئة الثانية",
    ["Category 3"]: "الفئة 3",
    ["Material 1"]: "المادة 1",
    ["Material 2"]: "المادة 2",
    ["Material 3"]: "المادة 3",
    ["Child"]: "طفل",
    ["Mens"]: "مِلك الرجال",
    ["Teen"]: "مراهق",
    ["Unisex"]: "للجنسين",
    ["Womens"]: "للنساء",
    ["Vendor 1"]: "البائع 1",
    ["Vendor 2"]: "البائع 2",
    ["Abc"]: "أبجدية عربية",
    ["Xyz"]: "إكس واي زد",
    ["Tom"]: "توم",
    ["Gemstone 1"]: "حجر كريم 1",
    ["Gemstone 2"]: "الأحجار الكريمة 2",
    ["Gemstone 3"]: "الأحجار الكريمة 3",
    ["Collection 1"]: "المجموعة 1",
    ["Collection 2"]: "المجموعة 2",
    ["Collection 3"]: "المجموعة 3",
    ["Collection 4"]: "المجموعة 4",
    ["Sales Rep"]: "مندوب المبيعات",
    ["Jewelry Access"]: "الوصول إلى المجوهرات",
    ["RingBuilder Access"]: "الوصول إلى RingBuilder",
    ["Hide Price"]: "إخفاء السعر",
    ["Auto Sync"]: "المزامنة التلقائية",
    ["Data & Permissions"]: "البيانات والأذونات",
    ["Make Appointment"]: "حدد موعدا",
    ["Store Notes"]: "ملاحظات المتجر",
    ["Disconnect Retailer"]: "قطع الاتصال بالتاجر",
    ["Conversation data is compulsory"]: "بيانات المحادثة إلزامية",
    ["Note Title is compulsory"]: "ملاحظة العنوان إلزامي",
    ["Note Description"]: "وصف الملاحظة",
    ["Appointment data is compulsory"]: "بيانات الموعد إلزامية",
    ["Access"]: "وصول",
    ["Avatar"]: "الصورة الرمزية",
    ["Job Title"]: "مسمى وظيفي",
    ["E-Mail"]: "بريد إلكتروني",
    ["Phone No."]: "رقم الهاتف.",
    ["Locations"]: "المواقع",
    ["Store Bio"]: "المتجر السيرة الذاتية",
    ["Years In Business"]: "سنوات في العمل",
    ["Create A New Note"]: "إنشاء ملاحظة جديدة",
    ["Edit Note"]: "تعديل الملاحظة",
    ["Monday"]: "الاثنين",
    ["Tuesday"]: "يوم الثلاثاء",
    ["December"]: "ديسمبر",
    ["October"]: "أكتوبر",
    ["July"]: "يوليو",
    ["Bhavin Patel"]: "بهافين باتيل",
    ["Started a"]: "بدأت",
    ["with your store."]: "مع متجرك.",
    ["Create Appointment"]: "إنشاء موعد",
    ["Calendars"]: "التقويمات",
    ["Calendar"]: "تقويم",
    ["Day"]: "يوم",
    ["Engagement"]: "ارتباط",
    ["Cleaning"]: "تنظيف",
    ["Repair"]: "بصلح",
    ["Wedding"]: "قِرَان",
    ["Select Date"]: "حدد التاريخ",
    ["Select Calendar"]: "حدد التقويم",
    ["Select Contact"]: "حدد جهة الاتصال",
    ["ABC"]: "أ ب ج",
    ["John"]: "جون",
    ["XYZ"]: "إكس واي زد",
    ["User"]: "مستخدم",
    ["Set Day"]: "حدد اليوم",
    ["Thursday"]: "يوم الخميس",
    ["Friday"]: "جمعة",
    ["Saturday"]: "السبت",
    ["Set Time"]: "ضبط الوقت",
    ["Enter Name."]: "أدخل الاسم.",
    ["Enter Email Address."]: "أدخل عنوان البريد الإلكتروني.",
    ["Enter Friend's Name."]: "أدخل اسم الصديق.",
    ["Enter Friend's Email Address."]: "أدخل عنوان البريد الإلكتروني لصديقك.",
    ["Search all"]: "البحث في الكل",
    ["Inventory is compulsory"]: "الجرد إلزامي",
    ["Matched Pair Stock is compulsory"]: "المخزون المطابق إلزامي",
    ["Stones are compulsory"]: "الحجارة إلزامية",
    ["Size is compulsory"]: "الحجم إلزامي",
    ["Cost is compulsory"]: "التكلفة إلزامية",
    ["Kindly select the shape"]: "الرجاء تحديد الشكل",
    ["Kindly select the color"]: "الرجاء اختيار اللون",
    ["Kindly select the fancy codes"]: "يرجى اختيار الرموز المميزة",
    ["Kindly select the fancy names"]: "الرجاء اختيار الأسماء الفاخرة",
    ["Other is compulsory"]: "الآخر إلزامي",
    ["Kindly select the clarity"]: "الرجاء تحديد الوضوح",
    ["Kindly select the cut grade"]: "يرجى تحديد درجة القطع",
    ["Kindly select the certificate"]: "الرجاء اختيار الشهادة",
    ["Kindly select the certificate number"]: "الرجاء اختيار رقم الشهادة",
    ["Depth is compulsory"]: "العمق إلزامي",
    ["Table is compulsory"]: "الجدول إلزامي",
    ["Measurements are compulsory"]: "القياسات إلزامية",
    ["Kindly select the polish value"]: "يرجى تحديد قيمة البولندية",
    ["Kindly select the symmetry"]: "الرجاء تحديد التماثل",
    ["Kindly select the fluorescence"]: "يرجى تحديد الفلوريسنت",
    ["Kindly select the girdle"]: "الرجاء اختيار الحزام",
    ["Kindly select the culet"]: "يرجى تحديد الكوليت",
    ["Kindly select the culet condition"]: "يرجى تحديد حالة الكوليت",
    ["Crown Angle is compulsory"]: "زاوية التاج إلزامية",
    ["Pavillion Angle is compulsory"]: "زاوية الجناح إلزامية",
    ["Comments are compulsory"]: "التعليقات إلزامية",
    ["Store Location is compulsory"]: "موقع المتجر إلزامي",
    ["Employee Name is compulsory"]: "اسم الموظف إلزامي",
    ["Employee ID is compulsory"]: "رقم هوية الموظف إلزامي",
    ["Employee ID is Invalid"]: "معرف الموظف غير صالح",
    ["Please select file."]: "الرجاء تحديد الملف.",
    ["Option 3"]: "الخيار 3",
    ["Golden"]: "ذهبي",
    ["FB"]: "فيس بوك",
    ["FBGNY"]: "اف بي جي ان واي",
    ["Brown"]: "بني",
    ["FL"]: "فلوريدا",
    ["VVS1"]: "في في اس 1",
    ["Excellent 1"]: "ممتاز 1",
    ["Strong Blue"]: "أزرق قوي",
    ["Medium Blue"]: "أزرق متوسط",
    ["Extremely"]: "لأقصى حد",
    ["Do not include pricing informatin in comments."]:
      "لا تقم بإدراج معلومات التسعير في التعليقات.",
    ["Video Url"]: "رابط الفيديو",
    ["No file chosen"]: "لم يتم اختيار أي ملف",
    ["Please select delimiter."]: "الرجاء تحديد الفاصل.",
    ["Diamond Import"]: "استيراد الماس",
    ["RAPNET"]: "راب نت",
    ["IDEX"]: "آيدكس",
    ["POLYGON"]: "مضلع",
    ["DIRECT LINK"]: "الرابط المباشر",
    ["Use Rapnet Instant Inventory Service."]:
      "استخدم خدمة Rapnet Instant Inventory.",
    ["Standard Diamond Form"]: "شكل الماس القياسي",
    ["Diamond Importing"]: "استيراد الماس",
    ["Alphanumeric"]: "أبجدي رقمي",
    ["ProductName1"]: "اسم المنتج1",
    ["Short title for the product. This will be displayed on product pages. If listing child products, please include color and size in the name to differentiate."]:
      "عنوان مختصر للمنتج. سيتم عرضه على صفحات المنتج. إذا كنت تريد إدراج منتجات فرعية، فيرجى تضمين اللون والحجم في الاسم للتمييز بينهما.",
    ["Accessories"]: "مُكَمِّلات",
    ["Tiaras"]: "التيجان",
    ["Womans Fashion Bag"]: "حقيبة أزياء نسائية",
    ["Atia 81"]: "عطية 81",
    ["Fashion Earrings"]: "أقراط الموضة",
    ["Chandelier"]: "الثريا",
    ["Charms"]: "سحر",
    ["Beads"]: "خرز",
    ["Bracelets"]: "أساور",
    ["Product Name1.xls"]: "اسم المنتج1.xls",
    ["Delivery Time"]: "موعد التسليم",
    ["hours"]: "ساعات",
    ["Featured"]: "مميز",
    ["Product name is compulsory"]: "اسم المنتج إلزامي",
    ["Style Number is compulsory"]: "رقم الطراز إلزامي",
    ["Retail Description is compulsory"]: "وصف التجزئة إلزامي",
    ["Retail Price is compulsory"]: "سعر التجزئة إلزامي",
    ["Kindly select the Material Type"]: "الرجاء تحديد نوع المادة",
    ["Kindly select the Category"]: "الرجاء اختيار الفئة",
    ["Kindly select all the necessary Field from essential"]:
      "يرجى تحديد كافة الحقول الضرورية من الأساسيات",
    ["Fixed"]: "مُثَبَّت",
    ["WholeSale"]: "بالجملة",
    ["Diamond Upload"]: "تحميل الماس",
    ["Please select image file only."]: "الرجاء تحديد ملف الصورة فقط.",
    ["Please select zip file only."]: "الرجاء تحديد ملف zip فقط.",
    ["Please select .csv|xls|txt|json file only."]:
      "يرجى تحديد ملف .csv|xls|txt|json فقط.",
    ["Please select currency."]: "الرجاء تحديد العملة.",
    ["Please select rounding."]: "يرجى تحديد التقريب.",
    ["Please enter default markup."]: "الرجاء إدخال العلامة الافتراضية.",
    ["Please enter default markup value less then 100%."]:
      "الرجاء إدخال قيمة الترميز الافتراضية أقل من 100%.",
    ["Select Currency"]: "حدد العملة",
    ["Select Rounding"]: "حدد التقريب",
    ["Collection name is compulsory"]: "اسم المجموعة إلزامي",
    ["Kindly select the Brand Name"]: "الرجاء اختيار اسم العلامة التجارية",
    ["Need to select atleast one category"]: "يجب تحديد فئة واحدة على الأقل",
    ["Overnight Mountings Ring Builder"]: "بناء حلقات التثبيت بين عشية وضحاها",
    ["Data Ratings"]: "تصنيفات البيانات",
    ["Victor"]: "فيكتور",
    ["Manage Inventory"]: "إدارة المخزون",
    ["Manage Location"]: "إدارة الموقع",
    ["Vendor Type"]: "نوع البائع",
    ["Designer"]: "مصمم",
    ["Watch Brand"]: "ماركة الساعة",
    ["Bridal Designer"]: "مصمم العرائس",
    ["Bridal Designers"]: "مصممي العرائس",
    ["Connection Status"]: "حالة الاتصال",
    ["Vendor Profile"]: "الملف الشخصي للبائع",
    ["Why Choose Overnight?"]: "لماذا تختار Overnight؟",
    ["Inventory"]: "جرد",
    ["Page"]: "صفحة",
    ["of"]: "ل",
    ["pages"]: "الصفحات",
    ["per page"]: "لكل صفحة",
    ["Product Deactivation Guidelines"]: "إرشادات إلغاء تنشيط المنتج",
    ["Activate/Deactivate All"]: "تفعيل/إلغاء تفعيل الكل",
    ["Products"]: "منتجات",
    ["Jack"]: "جاك",
    ["Lucy"]: "لوسي",
    ["One"]: "واحد",
    ["Two"]: "اثنين",
    ["Male"]: "ذكر",
    ["Female"]: "أنثى",
    ["Save Changes"]: "حفظ التغييرات",
    ["Trade Show Appointment"]: "موعد المعرض التجاري",
    ["Preferred"]: "مفضل",
    ["Questions"]: "أسئلة",
    ["I Am Interested In Placing Your Inventory On My Website."]:
      "أنا مهتم بوضع مخزونك على موقعي الإلكتروني.",
    ["Connected"]: "متصل",
    ["Request Update"]: "طلب التحديث",
    ["Image Preview"]: "معاينة الصورة",
    ["By Size"]: "حسب الحجم",
    ["By Price"]: "حسب السعر",
    ["United States - Dollar - $"]: "الولايات المتحدة - دولار - $",
    ["Great Britan - Pound - £"]: "بريطانيا العظمى - جنيه استرليني - £",
    ["Australia - Dollar - $"]: "أستراليا - دولار - $",
    ["size"]: "مقاس",
    ["Custom"]: "مخصص",
    ["US Dollars"]: "دولار امريكي",
    ["Great Britain Pound"]: "الجنيه البريطاني",
    ["Apex Diamonds"]: "قمة الماس",
    ["Asscher"]: "أشر",
    ["Cushion"]: "وسادة",
    ["Best Diamonds"]: "أفضل الماس",
    ["Signature Diamonds"]: "الماس التوقيع",
    ["Normal Diamonds"]: "الماس العادي",
    ["Diamond Link URL"]: "رابط الماس",
    ["Automatically Accept New Diamond Dealers"]:
      "قبول تجار الماس الجدد تلقائيًا",
    ["When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."]:
      'عندما ينضم تاجر ألماس جديد إلى GemFind، سيتم إضافته تلقائيًا إلى قائمة تجار الألماس المتصلين بك. قم بتبديل هذا الخيار إلى "لا"، إذا كنت تفضل عدم إضافة تجار الألماس تلقائيًا إلى حسابك.',
    ["Sort Search Results Initially By"]: "فرز نتائج البحث مبدئيًا حسب",
    ["Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعديل حقل الفرز الأولي لنتائج البحث. اتركه فارغًا للقيمة الافتراضية (لا شيء).",
    ["Order Search Results Initially By"]: "ترتيب نتائج البحث مبدئيًا حسب",
    ["Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."]:
      "قم بتغيير هذه القيمة لتعديل ترتيب الفرز الأولي لحقل الفرز الأولي. اتركه فارغًا للقيمة الافتراضية (لا شيء).",
    ["Show In House Diamonds Column with SKU (for New GF Link Only)"]:
      "عرض عمود الماس الموجود في المنزل مع رمز SKU (لرابط GF الجديد فقط)",
    ["Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."]:
      "اختر ما إذا كنت تريد عرض أعمدة الماسات الداخلية في نتائج البحث. كما سيتم عرض SKU الداخلية في النافذة المنبثقة للنتائج وصفحة التفاصيل.",
    ["Show In House Diamonds First"]: "عرض الماس في المنزل أولاً",
    ["Choose whether to display in house diamonds first in the search result."]:
      "اختر ما إذا كنت تريد عرض الماس الموجود في المنزل أولاً في نتيجة البحث.",
    ["Show GIA report check link"]: "عرض رابط التحقق من تقرير GIA",
    ["When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."]:
      "عند تمكين هذا، سيتم عرض رابط إلى شهادة الماس GIA على موقع GIA على الويب، وعند تعطيله، سيتم عرض رابط إلى صورة شهادة GIA.",
    ["Show Advance options as Default in Diamond Search"]:
      "إظهار خيارات التقدم كخيار افتراضي في بحث الماس",
    ["Show Certificate in Diamond Search"]: "إظهار الشهادة في بحث الماس",
    ["Choose whether to display a link to the diamond certificate or not."]:
      "اختر ما إذا كنت تريد عرض رابط لشهادة الماس أم لا.",
    ["Show EGL Certificate Variants"]: "إظهار متغيرات شهادة EGL",
    ["Choose whether to display EGL Certificate Variants or not."]:
      "اختر ما إذا كنت تريد عرض متغيرات شهادة EGL أم لا.",
    ["Show Request Certificate"]: "عرض طلب الشهادة",
    ["When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."]:
      'عند ضبط "إظهار رابط فحص تقرير GIA" على "لا"، يتيح لك هذا الخيار اختيار ما إذا كنت تريد عرض رابط "طلب الشهادة" أم لا. سيعرض هذا الرابط صورة شهادة GIA (إذا كانت متوفرة) بدلاً من الشهادة الموجودة على موقع GIA الإلكتروني، وإذا تم ضبط "تعطيل روابط الشهادة في بحث Diamond" على "نعم"، فسيؤدي هذا الرابط إلى ظهور نموذج "طلب مزيد من المعلومات".',
    ["Disable Certificate Links in Diamond Search"]:
      "تعطيل روابط الشهادات في بحث Diamond",
    ["Hide certificates and show only request form."]:
      "إخفاء الشهادات وإظهار نموذج الطلب فقط.",
    ["Show Color Items out of Range"]: "إظهار العناصر الملونة خارج النطاق",
    ["Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."]:
      'اختر ما إذا كان زر مرشح لون الماس المعروض الأخير سيعرض أيضًا أي لون متبقي من هذا النوع أو أعلى، وصولاً إلى "Z".',
    ["Show Clarity Items out of Range"]: "إظهار عناصر الوضوح خارج النطاق",
    ["Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."]:
      'اختر ما إذا كان زر مرشح وضوح الماس المعروض الأخير سيعرض أيضًا أي وضوحات متبقية من هذا النوع أو أعلى، وصولاً إلى "I3".',
    ["Accept Total Price for Diamond"]: "قبول السعر الإجمالي للماس",
    ["When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."]:
      "عند تحميل الماس إلى JewelCloud باستخدام ورقة بيانات، تكون العملية الافتراضية لحساب سعر التكلفة هي ضرب CostPerCarat في قيمة القيراط. عند تعيين هذا الخيار على YES، سيتم قبول الأسعار الموجودة في ورقة بيانات الماس التي تم تحميلها كسعر التكلفة الإجمالي.",
    ["Choose whether to display the link which allows the customer the ability to Drop a Hint."]:
      "اختر ما إذا كنت تريد عرض الرابط الذي يسمح للعميل بالقدرة على إسقاط تلميح.",
    ["Email A Friend"]: "أرسل بريدًا إلكترونيًا إلى صديق",
    ["Choose whether to display the link which allows the customer the ability to Email A Friend."]:
      "اختر ما إذا كنت تريد عرض الرابط الذي يسمح للعميل بإرسال بريد إلكتروني إلى صديق.",
    ["Show Origin On Diamond Search"]: "إظهار الأصل في بحث الماس",
    ["Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."]:
      'اختر ما إذا كنت تريد عرض القائمة المنسدلة "الأصل" ضمن مرشحات البحث المتقدم. (على سبيل المثال، الكل، الكندي، المنشأ في المختبر).',
    ["Show vendor comments on details page"]:
      "إظهار تعليقات البائع على صفحة التفاصيل",
    ["Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."]:
      "اختر ما إذا كنت تريد استخدام إظهار التعليقات التي أدخلها بائعو الماس على صفحة التفاصيل لكل حجر في نتائج البحث عن الماس.",
    ["Show Contact Number"]: "إظهار رقم التواصل",
    ["Choose whether to use show contact Number or not."]:
      "اختر ما إذا كنت تريد استخدام إظهار رقم الاتصال أم لا.",
    ["Choose whether to use show addresses or not."]:
      "اختر ما إذا كنت تريد استخدام عناوين العرض أم لا.",
    ["Alternate diamond details link URL"]: "رابط تفاصيل الماس البديل",
    ["Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "قم بتغيير هذه القيمة لتغيير الارتباط التشعبي الفعلي بتفاصيل الماس. يمكن تغيير هذا للارتباط بصفحة تفاصيل الماس الخاصة بك أو عربة التسوق الخاصة بك. يتم تمرير معلمات تفاصيل الماس الإضافية في عنوان URL عند وجود هذه القيمة. اتركها فارغة للقيمة الافتراضية.",
    ["Use Custom Detail URL for Diamonds?"]:
      "هل يمكنك استخدام عنوان URL التفصيلي المخصص للماس؟",
    ["Choose whether to enable or disable the “Alternate diamond details link URL” option."]:
      'اختر ما إذا كنت تريد تمكين أو تعطيل خيار "رابط تفاصيل الماس البديل".',
    ["Show Diamond Prices"]: "عرض أسعار الماس",
    ["You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."]:
      "يمكنك اختيار عدم عرض أسعار الماس في نتائج البحث إذا كنت ترغب في ذلك. لا يتم عرض حقل نطاق السعر في نموذج البحث عندما لا يتم عرض الأسعار.",
    ["Markup Your Own Inventory"]: "قم بترميز مخزونك الخاص",
    ["Choose whether to apply markup on diamond inventory uploaded by you."]:
      "اختر ما إذا كنت ترغب في تطبيق هامش الربح على مخزون الماس الذي قمت بتحميله.",
    ["Show Price Per Carat"]: "عرض السعر لكل قيراط",
    ["Choose whether to display the price per carat value on the diamond details page “Specifications” tab."]:
      'اختر ما إذا كنت تريد عرض سعر القيراط الواحد في علامة التبويب "المواصفات" في صفحة تفاصيل الماس.',
    ["Show Instagram Share"]: "عرض مشاركة الانستجرام",
    ["Choose whether to display Instagram Share or not."]:
      "اختر ما إذا كنت تريد عرض مشاركة Instagram أم لا.",
    ["Call to Action Button"]: "زر الدعوة إلى اتخاذ إجراء",
    ["Choose whether to display the “Add to Cart” button or 'Request More Info' or 'Schedule a Viewing'."]:
      'اختر ما إذا كنت تريد عرض زر "إضافة إلى سلة التسوق" أو "طلب المزيد من المعلومات" أو "جدولة عرض".',
    ["AddToCart Button"]: "زر إضافة إلى سلة التسوق",
    ["Schedule a Viewing"]: "جدولة عرض",
    ["Show 'View Cart' Button"]: 'إظهار زر "عرض سلة التسوق"',
    ["Alternate Shopping Cart URL"]: "رابط عربة التسوق البديلة",
    ["Show AddToCart Button On Search Page"]:
      "إظهار زر AddToCart في صفحة البحث",
    ["Choose whether to display the “Add to Cart” button on Search Page or not."]:
      'اختر ما إذا كنت تريد عرض زر "إضافة إلى سلة التسوق" في صفحة البحث أم لا.',
    ["Request Diamond"]: "طلب الماس",
    ["Compare Diamond"]: "مقارنة الماس",
    ["Price Per Carat Range"]: "نطاق السعر لكل قيراط",
    ["Select Option"]: "حدد الخيار",
    ["Ring Builder Version I"]: "برنامج Ring Builder الإصدار الأول",
    ["Ring Builder Version II"]: "برنامج Ring Builder الإصدار الثاني",
    ["Ring Builder Version III"]: "برنامج Ring Builder الإصدار الثالث",
    ["Ring Builder Version IV"]: "برنامج Ring Builder الإصدار الرابع",
    ["Ring Builder Version V"]: "برنامج Ring Builder الإصدار الخامس",
    ["Earrings"]: "أقراط",
    ["18k Yellow Gold"]: "ذهب أصفر عيار 18",
    ["18k Rose Gold"]: "ذهب وردي عيار 18",
    ["AUD"]: "الدولار الاسترالي",
    ["GBP (Pound)"]: "الجنيه الإسترليني (GBP)",
    ["USD"]: "دولار أمريكي",
    ["Items View"]: "عرض العناصر",
    ["List View"]: "عرض القائمة",
    ["We offer over 50,000 mountings and Semi-mounts to choose from. Overnight has one of the largest"]:
      "نحن نقدم أكثر من 50000 من التركيبات والتركيبات شبه الجاهزة للاختيار من بينها. تمتلك Overnight واحدة من أكبر",
    ["In Stock"]: "في الأوراق المالية",
    ["inventories in the industry. All of our"]:
      "المخزونات في الصناعة. جميع منتجاتنا",
    ["mountings and Semi-mounts can be returned within 10 business days for a full refund. Our entire line is offered in Sterling, 10k, 14k, 18k, 950 Platinum and 950 Palladium. All gold mountings and semi mounts can be ordered in colors White, Yellow or Pink. Castings available within 3 business days, out of stock Polished mountings available within 3-5 business days, and semi mount and finished goods are available within 5-7 business days. Any one of our 50,000+ styles can be ordered as"]:
      "يمكن إرجاع الحوامل والتركيبات شبه النهائية في غضون 10 أيام عمل لاسترداد المبلغ بالكامل. يتم تقديم خطنا بالكامل بالفضة الإسترليني و10 قيراط و14 قيراط و18 قيراط و950 بلاتين و950 بالاديوم. يمكن طلب جميع الحوامل والتركيبات شبه النهائية المصنوعة من الذهب بالألوان الأبيض أو الأصفر أو الوردي. تتوفر المسبوكات في غضون 3 أيام عمل، غير متوفرة في المخزون. تتوفر الحوامل المصقولة في غضون 3-5 أيام عمل، وتتوفر الحوامل شبه النهائية والسلع النهائية في غضون 5-7 أيام عمل. يمكن طلب أي من أنماطنا التي يزيد عددها عن 50000 نمط",
    ["Authentix"]: "أوثينتكس",
    ["Samples. Any style can be modified in any way to meet your customers needs. Overnight's talented"]:
      "العينات. يمكن تعديل أي نمط بأي طريقة لتلبية احتياجات عملائك. موهبة Overnight",
    ["custom crafted department"]: "قسم مخصص الصنع",
    ["can create anything as a new model using CAD and Hand Carved. We employ some of the most talented setters in the industry that can set your stone(s). We offer 7 different diamond qualities including black diamonds and Champagne diamonds. Our customer service department is extremely knowledgeable in all aspects of jewelry and will assist you in a friendly and professional manner. Overnight's product development team is always creating new styles from basics to cutting edge fashion designs ensuring that you will find what you are looking for. All of our Mountings are proudly manufactured in NY and go through a stringent quality control process before leaving our facility, ensuring that you get a great product in a timely manner. Overnight reserves the right to refuse or recall any product information, images or content being used on customer websites and catalogs"]:
      "يمكننا إنشاء أي شيء كنموذج جديد باستخدام CAD والنحت اليدوي. نحن نوظف بعضًا من أكثر المصممين موهبة في الصناعة والذين يمكنهم تركيب الأحجار الخاصة بك. نحن نقدم 7 أنواع مختلفة من الماس بما في ذلك الماس الأسود والماس الشمبانيا. يتمتع قسم خدمة العملاء لدينا بخبرة كبيرة في جميع جوانب المجوهرات وسيساعدك بطريقة ودية ومهنية. يعمل فريق تطوير المنتجات في Overnight دائمًا على إنشاء أنماط جديدة من الأساسيات إلى تصميمات الأزياء المتطورة لضمان أنك ستجد ما تبحث عنه. يتم تصنيع جميع التركيبات الخاصة بنا بفخر في نيويورك وتخضع لعملية مراقبة جودة صارمة قبل مغادرة منشأتنا، مما يضمن حصولك على منتج رائع في الوقت المناسب. تحتفظ Overnight بالحق في رفض أو استدعاء أي معلومات عن المنتج أو صور أو محتوى يتم استخدامه على مواقع الويب والكتالوجات الخاصة بالعملاء",
    ["Please Select Manufacturer"]: "الرجاء تحديد الشركة المصنعة",
    ["Please select markup"]: "الرجاء تحديد العلامة",
    ["Please Enter Min Range"]: "الرجاء إدخال الحد الأدنى للنطاق",
    ["Please Enter Max Range"]: "الرجاء إدخال الحد الأقصى للمدى",
    ["Please Enter Markup"]: "الرجاء إدخال العلامة",
    ["Malaysia - Riggit - $"]: "ماليزيا - ريجيت - $",
    ["New Zealand - Dollar - $"]: "نيوزيلندا - دولار - $",
    ["Canadian - Dollar - $"]: "كندي - دولار - $",
    ["Singapore - Dollar - S$"]: "سنغافورة - الدولار - S$",
    ["Chinese - Yuan - ¥"]: "الصينية - يوان - ين",
    ["Korean - Won - ₩"]: "كوري - وون - ₩",
    ["Denmark - Danish Krone - kr."]: "الدنمارك - كرونة دانمركية - kr.",
    ["South African - Rand - R"]: "جنوب أفريقيا - راند - ر",
    ["Europe - Euro - €"]: "أوروبا - يورو - €",
    ["Swiss - Franc - CHf"]: "سويسري - فرنك - CHf",
    ["Swedish Krona - Sweden - SEK"]: "الكرونة السويدية - السويد - SEK",
    ["Diamond Link Shopping Cart Configuration Instructions"]:
      "تعليمات تكوين عربة التسوق Diamond Link",
    ["We will be sending parameters to the page on your website as below url"]:
      "سوف نقوم بإرسال المعلمات إلى الصفحة الموجودة على موقع الويب الخاص بك على النحو التالي:",
    ["Example Url"]: "مثال على عنوان URL",
    ["To Get Diamond Detail"]: "للحصول على تفاصيل الماس",
    ["Click Here to access API to get diamond detail and checkout how it works online."]:
      "انقر هنا للوصول إلى واجهة برمجة التطبيقات (API) للحصول على تفاصيل الماس ومعرفة كيفية عمله عبر الإنترنت.",
    ["to access API to get diamond detail and checkout how it works online."]:
      "للوصول إلى واجهة برمجة التطبيقات (API) للحصول على تفاصيل الماس ومعرفة كيفية عملها عبر الإنترنت.",
    ["Click Here"]: "انقر هنا",
    ["Click Here to access API to get setting detail and checkout how it works online."]:
      "انقر هنا للوصول إلى واجهة برمجة التطبيقات (API) للحصول على تفاصيل الإعداد ومعرفة كيفية عملها عبر الإنترنت.",
    ["Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)"]:
      "مرر رقم الماس الخاص بالماس. (ستحصل على هذا باعتباره معرف الماس في سلسلة الاستعلام الخاصة بعنوان URL كما هو موضح أعلاه)",
    ["Below is the list of parameters with respective possible values which can be supplied to each parameter."]:
      "فيما يلي قائمة بالمعلمات مع القيم المحتملة التي يمكن توفيرها لكل معلمة.",
    ["DiamondId"]: "معرف الماس",
    ["Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."]:
      "قم بتمرير رقم GemFind المخصص لحسابك. على سبيل المثال، 720 لـ GemFind.",
    ["You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website."]:
      "ستحصل على استجابة بتنسيق XML من استجابة واجهة برمجة التطبيقات. ستحتاج إلى تحليل هذه البيانات واستخدامها لإنشاء الماس كمنتج على موقع الويب الخاص بك.",
    ["DealerLink"]: "رابط التاجر",
    ["Ring Builder Shopping Cart Configuration Instructions"]:
      "تعليمات تكوين عربة التسوق الخاصة بـ Ring Builder",
    ["To Get Setting Detail"]: "للحصول على تفاصيل الإعداد",
    ["to access API to get setting detail and checkout how it works online."]:
      "للوصول إلى واجهة برمجة التطبيقات (API) للحصول على تفاصيل الإعداد ومعرفة كيفية عملها عبر الإنترنت.",
    ["SettingId"]: "معرف الإعداد",
    ["Pass setting number of setting. (SettingID from the query string parameter as shown on example url)"]:
      "قم بتمرير رقم إعداد الإعداد. (SettingID من معلمة سلسلة الاستعلام كما هو موضح في عنوان URL للمثال)",
    ["Masterlink Shopping cart integration instructions"]:
      "تعليمات دمج عربة التسوق Masterlink",
    ["To Get Product Detail"]: "للحصول على تفاصيل المنتج",
    ["You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart."]:
      "يمكنك دمج عربة التسوق الخاصة بك مع منتجات مصممي gemfind عبر واجهة برمجة التطبيقات. يمكنك استخدام واجهة برمجة التطبيقات هذه بحيث يقوم زر إضافة إلى عربة التسوق gemfind بإضافة المنتج إلى عربة التسوق الخاصة بموقعك الإلكتروني.",
    ["JewelryID"]: "معرف المجوهرات",
    ["Pass JewelryID # for which you want to get data."]:
      "قم بتمرير JewelryID # الذي تريد الحصول على بياناته.",
    ["Stud Builder Shopping cart integration instructions"]:
      "تعليمات دمج عربة التسوق في برنامج Stud Builder",
    ["There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones"]:
      "سيكون هناك معرفان ماسيان مفصولان بفواصل، لذا ستحتاج إلى الحصول على تفاصيل الماس مرتين لكل ماسة، حيث يحتوي البرغي على زوج من الأحجار.",
    ["To Get Stud Mounting Detail"]: "للحصول على تفاصيل تركيب مسمار",
    ["to access API to get Stud Mounting detail and checkout how it works online."]:
      "للوصول إلى واجهة برمجة التطبيقات (API) للحصول على تفاصيل تركيب مسمار ومعرفة كيفية عمله عبر الإنترنت.",
    ["Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)"]:
      "قم بتمرير JewelryID # الذي تريد الحصول على بياناته. (DesignerProductID من معلمة سلسلة الاستعلام كما هو موضح في عنوان URL للمثال)",
    ["Jewelry Category"]: "فئة المجوهرات",
    ["Designer Category"]: "فئة المصمم",
    ["Bridal Category"]: "فئة العرائس",
    ["SKU Prefix"]: "بادئة SKU",
    ["Category Mapping"]: "رسم الخرائط حسب الفئة",
    ["Column Mapping"]: "تعيين الأعمدة",
    ["GemFind Category"]: "فئة GemFind",
    ["Website Category"]: "فئة الموقع",
    ["Dealer Stock Number"]: "رقم مخزون التاجر",
    ["Product Description"]: "وصف المنتج",
    ["Designers Mapping"]: "مصممي الخرائط",
    ["GemFind Designers"]: "مصممو GemFind",
    ["Bangles"]: "الأساور",
    ["Big & important Bracelets"]: "أساور كبيرة ومهمة",
    ["Chain Bracelets"]: "أساور السلسلة",
    ["Necklaces"]: "قلادات",
    ["Chains"]: "السلاسل",
    ["Sync Products To Your Website"]: "مزامنة المنتجات مع موقع الويب الخاص بك",
    ["Completed"]: "مكتمل",
    ["Sync Products"]: "منتجات المزامنة",
    ["Force Full Sync"]: "فرض المزامنة الكاملة",
    ["Detail"]: "التفاصيل",
    ["Total Products Processed"]: "إجمالي المنتجات المعالجة",
    ["Out Going Data Map"]: "خريطة البيانات الصادرة",
    ["API Url"]: "عنوان URL لواجهة برمجة التطبيقات",
    ["Enable Sync"]: "تمكين المزامنة",
    ["Attrubute Mapping"]: "تعيين السمات",
    ["Create XML"]: "إنشاء XML",
    ["Notifications"]: "إشعارات",
    ["registered with the website"]: "مسجلة في الموقع",
    ["Jewelry vendors"]: "بائعي المجوهرات",
    ["Active Jewelry"]: "مجوهرات نشطة",
    ["Diamond Vendors"]: "بائعي الماس",
    ["Active Diamonds"]: "الماس النشط",
    ["Registered"]: "مسجل",
    ["Contact Us"]: "اتصل بنا",
    ["Favorites"]: "المفضلة",
    ["New & Recently Updated Vendors"]: "البائعين الجدد والمحدثين مؤخرًا",
    ["View More"]: "عرض المزيد",
    ["Quick Links"]: "روابط سريعة",
    ["View My Retailers"]: "عرض تجار التجزئة الخاصين بي",
    ["Update Inventory"]: "تحديث المخزون",
    ["Invite A Retailer"]: "دعوة بائع التجزئة",
    ["Manage Shows"]: "إدارة العروض",
    ["Ratings"]: "التقييمات",
    ["The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated."]:
      "يعتمد تصنيف النجوم على 7 مجالات رئيسية: الصور، اسم المنتج، وصف المنتج، الفئات/المجموعات، السمات، التسعير وآخر تحديث.",
    ["For details on how you can improve your score, please click here to view this document."]:
      "للحصول على تفاصيل حول كيفية تحسين درجاتك، يرجى النقر هنا لعرض هذه الوثيقة.",
    ["Import Your Data"]: "استيراد بياناتك",
    ["Upload Your Jewelry & Diamonds"]: "قم بتحميل مجوهراتك والماس الخاص بك",
    ["Import Diamonds"]: "استيراد الماس",
    ["Upload Diamond"]: "تحميل الماس",
    ["Import Jewelry"]: "استيراد المجوهرات",
    ["Upload Jewelry"]: "تحميل المجوهرات",
    ["Video/Images"]: "فيديو/صور",
    ["Email"]: "بريد إلكتروني",
    ["Friend's Name"]: "اسم الصديق",
    ["Friend's Email"]: "بريد صديق",
    ["Buyer Name is compulsory"]: "اسم المشتري إلزامي",
    ["Book Appointment"]: "حجز موعد",
    ["Appointment Description"]: "وصف الموعد",
    ["Gemfind"]: "البحث عن الجواهر",
    ["Your recipient will recieve the details on your selected diamonds"]:
      "سيتلقى المتلقي التفاصيل الخاصة بالماس الذي اخترته",
    ["Message"]: "رسالة",
    ["Please select video file only."]: "الرجاء تحديد ملف الفيديو فقط.",
    ["Is Lab Created"]: "هل تم إنشاء المختبر؟",
    ["Please Enter Link"]: "الرجاء إدخال الرابط",
    ["Please Enter Text"]: "الرجاء إدخال النص",
    ["View /Edit Mapping Columns"]: "عرض/تحرير أعمدة التعيين",
    ["Preparing Your Data"]: "إعداد بياناتك",
    ["Resubmit"]: "إعادة الإرسال",
    ["Re-Activate"]: "إعادة التنشيط",
    ["Fancy Color Diamond Link Emails"]:
      "روابط رسائل البريد الإلكتروني ذات الألوان الرائعة",
    ["Diamond Link Emails"]: "رسائل البريد الإلكتروني الماسية",
    ["Time pieces"]: "قطع الوقت",
    ["Click Here for GFLINK ECommerce Link Administration"]:
      "انقر هنا لإدارة رابط التجارة الإلكترونية GFLINK",
    ["View/Update Your Online Dealer Inventory List"]:
      "عرض/تحديث قائمة مخزون التاجر عبر الإنترنت",
    ["Change Inventory Mark-Up Values for Markup BY PRICE"]:
      "تغيير قيم هامش ربح المخزون حسب السعر",
    ["Update Search Page Results Appearance"]: "تحديث مظهر نتائج صفحة البحث",
    ["Update Search Page Results Preferences"]:
      "تحديث تفضيلات نتائج صفحة البحث",
    ["For Advanced Users"]: "للمستخدمين المتقدمين",
    ["Change Your Shipping Charge"]: "تغيير رسوم الشحن الخاصة بك",
    ["Your GFLINK Addresses are"]: "عناوين GFLINK الخاصة بك هي",
    ["ADMIN NOTE"]: "ملاحظة إدارية",
    ["Your GFLink addresses MUST contain the correct domain name, http://platform.stage.jewelcloud.com. Using http://platform.stage.jewelcloud.com or any variation of gemfind.com is NOT correct and no longer functions. Please make sure your GFWatchLink URL is correct. Use the URLs below. The links below display your inventory"]:
      "يجب أن تحتوي عناوين GFLink الخاصة بك على اسم المجال الصحيح، http://platform.stage.jewelcloud.com. إن استخدام http://platform.stage.jewelcloud.com أو أي تنويعة من gemfind.com ليس صحيحًا ولن يعمل بعد الآن. يرجى التأكد من صحة عنوان URL الخاص بـ GFWatchLink. استخدم عناوين URL أدناه. تعرض الروابط أدناه مخزونك",
    ["Marked Up"]: "مُرَشَّح",
    ["a % determined by the Watch'S PRICE."]:
      "نسبة مئوية يتم تحديدها حسب سعر الساعة.",
    ["Search Only"]: "البحث فقط",
    ["Watch Configurator"]: "مُكوِّن الساعة",
    ["Procedure to implement Watch Configurator: To integrate the Watch Configurator directly into one of your web pages on your website, you will need to follow the general procedure below. This procedure will vary depending upon the technology your website uses, and your web developer will need to make adjustments accordingly. This will require custom website development work on your end. You basically have two choices when it comes to seamlessly including Watch Configurator in your website: FRAMEs or IFRAMEs."]:
      "إجراء تنفيذ Watch Configurator: لدمج Watch Configurator مباشرة في إحدى صفحات الويب الخاصة بك على موقع الويب الخاص بك، ستحتاج إلى اتباع الإجراء العام أدناه. سيختلف هذا الإجراء وفقًا للتكنولوجيا التي يستخدمها موقع الويب الخاص بك، وسيحتاج مطور الويب الخاص بك إلى إجراء التعديلات وفقًا لذلك. سيتطلب هذا عمل تطوير موقع ويب مخصص من جانبك. لديك في الأساس خياران عندما يتعلق الأمر بتضمين Watch Configurator بسلاسة في موقع الويب الخاص بك: FRAMEs أو IFRAMEs.",
    ["FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch Configurator may easily be included as one of the FRAME contents. This is one of the simplest methods of embedding results, however it is not as attractive as the other methods and may not be a feasible option for you if you do not already use FRAMEs. Scrollbars may be prgrammatically eliminated for a cleaner look."]:
      "الإطارات: إذا كان موقعك يستخدم الإطارات في مجموعة الإطارات، فيمكن بسهولة تضمين Watch Configurator كأحد محتويات الإطارات. هذه إحدى أبسط طرق تضمين النتائج، إلا أنها ليست جذابة مثل الطرق الأخرى وقد لا تكون خيارًا عمليًا بالنسبة لك إذا كنت لا تستخدم الإطارات بالفعل. يمكن إزالة أشرطة التمرير برمجيًا للحصول على مظهر أكثر نظافة.",
    ["IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding to accomodate Netscape) and place it on your page where you want the Watch Configurator to apear. This will look better than a FRAME defined as part of a FRAMESET. Some attention to the size of the IFRAME must be paid. The IFRAME window is not programmatically 'resizable' once it has been defined on a page, therefore you must create the IFRAME with a HEIGHT and WIDTH that will accommodate the entire contents of the Watch Configurator. The number of results/rows returned by the Watch Configurator is constant and will ensure a constant height (and fit) for your IFRAME window."]:
      "IFRAMEs / LAYERs: قم بتعريف IFRAME عائم (قم بتضمين ترميز LAYER لاستيعاب Netscape) وضعه على صفحتك حيث تريد ظهور Watch Configurator. سيبدو هذا أفضل من FRAME المحدد كجزء من FRAMESET. يجب الانتباه إلى حجم IFRAME. لا يمكن تغيير حجم نافذة IFRAME برمجيًا بمجرد تعريفها على الصفحة، لذلك يجب إنشاء IFRAME بارتفاع وعرض يستوعبان محتويات Watch Configurator بالكامل. عدد النتائج/الصفوف التي تم إرجاعها بواسطة Watch Configurator ثابت وسيضمن ارتفاعًا ثابتًا (وملاءمة) لنافذة IFRAME الخاصة بك.",
    ["Search by GEMFIND INVENTORY ID #"]:
      "البحث عن طريق رقم معرف مخزون GEMFIND",
    ["Enter GEMFIND ID # and CLICK 'GO'"]:
      'أدخل رقم GEMFIND ID # ثم انقر فوق "GO"',
    ["Go"]: "يذهب",
    ["Search by Dealer's Inventory #"]: "البحث حسب رقم مخزون التاجر",
    ["Enter Dealer Inventory # and CLICK 'GO'"]:
      'أدخل رقم مخزون التاجر وانقر على "انتقال"',
    ["A & M Diamonds"]: "الماس ايه اند ام",
    ["A & W Gems"]: "جواهر A & W",
    ["A & F Gems"]: "جواهر A & F",
    ["Pendant Builder Shopping cart integration instructions"]:
      "تعليمات دمج عربة التسوق في Pendant Builder",
    ["To Get Pendant Mounting Detail"]: "للحصول على تفاصيل تركيب القلادة",
    ["Please Enter Password."]: "الرجاء إدخال كلمة المرور.",
    ["Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"]:
      "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل، وحرف كبير واحد على الأقل، ورقم واحد، وحرف خاص واحد",
    ["Please Enter Confirm Password."]: "الرجاء إدخال تأكيد كلمة المرور.",
    ["Password And Confirm Password Do Not Match."]:
      "كلمة المرور وتأكيد كلمة المرور غير متطابقتين.",
    ["Server Up-Time"]: "وقت تشغيل الخادم",
    ["Uptime info not available - please try reloading this page"]:
      "معلومات عن وقت التشغيل غير متوفرة - يرجى محاولة إعادة تحميل هذه الصفحة",
    ["Select Client Name"]: "حدد اسم العميل",
    ["Active - No Profile"]: "نشط - لا يوجد ملف تعريفي",
    ["Select Type"]: "حدد النوع",
    ["API Url is compulsory"]: "عنوان URL لواجهة برمجة التطبيقات إلزامي",
    ["API Key is compulsory"]: "مفتاح API إلزامي",
    ["Provide Proper DealerID"]: "توفير معرف التاجر الصحيح",
    ["account ?"]: "حساب ؟",
    ["You have select"]: "لقد قمت بالاختيار",
    ["Please select .png file only."]: "يرجى تحديد ملف .png فقط.",
    ["Please Select Correct Image Size."]: "الرجاء اختيار حجم الصورة الصحيح.",
    ["All Dealer"]: "جميع التجار",
    ["Please Enter Only Alphabet."]: "الرجاء إدخال الحروف الأبجدية فقط.",
    ["Please Select Only Images."]: "الرجاء تحديد الصور فقط.",
    ["API Access Token"]: "رمز الوصول إلى واجهة برمجة التطبيقات",
    ["Set Your Available Diamonds"]: "قم بضبط الماس المتاح لديك",
    ["This will control default diamond filters when users initially visit your DiamondLink."]:
      "سيؤدي هذا إلى التحكم في مرشحات الماس الافتراضية عندما يقوم المستخدمون بزيارة DiamondLink الخاص بك لأول مرة.",
    ["This will control which diamonds are available in your DiamondLink."]:
      "سيؤدي هذا إلى التحكم في الماس المتوفر في DiamondLink الخاص بك.",
    ["Videos Only"]: "فيديوهات فقط",
    ["Enable Diamonds with Videos Only"]:
      "تمكين الماس باستخدام مقاطع الفيديو فقط",
    ["Lab Setting"]: "إعداد المختبر",
    ["Mined Setting"]: "الإعداد الملغوم",
    ["Ct."]: "كت.",
    ["Please select zip file only were in Compress images together & upload in a .zip format."]:
      "يرجى تحديد ملف zip فقط حيث يتم ضغط الصور معًا وتحميلها بتنسيق .zip.",
    ["Password does not match"]: "كلمة المرور غير متطابقة",
    ["Name is compulsory"]: "الاسم إلزامي",
    ["Name is invalid"]: "الاسم غير صالح",
    ["Email is compulsory"]: "البريد الإلكتروني إلزامي",
    ["Confirm Password is compulsory"]: "تأكيد كلمة المرور إلزامي",
    ["Logins"]: "تسجيلات الدخول",
    ["User Role Detail"]: "تفاصيل دور المستخدم",
    ["New User"]: "مستخدم جديد",
    ["Add New User"]: "إضافة مستخدم جديد",
    ["Access Level"]: "مستوى الوصول",
    ["Edit User"]: "تعديل المستخدم",
    ["Delete User"]: "حذف المستخدم",
    ["Last Login"]: "أخر تسجيل دخول",
    ["User Since"]: "مستخدم منذ",
    ["Select Account type"]: "حدد نوع الحساب",
    ["Company 1"]: "الشركة 1",
    ["Company 2"]: "الشركة 2",
    ["Company 3"]: "الشركة 3",
    ["Apply"]: "يتقدم",
    ["Language Selection for Platform and Tools"]:
      "اختيار اللغة للمنصة والأدوات",
    ["Default Language Is"]: "اللغة الافتراضية هي",
    ["Select Language"]: "اختر اللغة",
    ["Currency Selection for Platform and Tools"]:
      "اختيار العملة للمنصة والأدوات",
    ["Default Currency Is"]: "العملة الافتراضية هي",
    ["Please select Image File Only"]: "الرجاء تحديد ملف الصورة فقط",
    ["Email Address is compulsory"]: "عنوان البريد الإلكتروني إلزامي",
    ["Email Address is Invalid"]: "عنوان البريد الإلكتروني غير صالح",
    ["Image Name"]: "اسم الصورة",
    ["Company Detail"]: "تفاصيل الشركة",
    ["Web Site"]: "موقع إلكتروني",
    ["Company Bio"]: "السيرة الذاتية للشركة",
    ["Update Profile"]: "تحديث الملف الشخصي",
    ["Advertising Samples"]: "عينات إعلانية",
    ["Social Integrations Settings"]: "إعدادات التكاملات الاجتماعية",
    ["Website's Facebook App ID Setup"]:
      "إعداد معرف تطبيق Facebook الخاص بالموقع",
    ["Masterlink Facebook App Setup"]: "إعداد تطبيق Masterlink Facebook",
    ["MasterLink Facebook canvas App ID"]:
      "معرف تطبيق MasterLink Facebook Canvas",
    ["MasterLink Facebook canvas App URL"]:
      "رابط تطبيق MasterLink Facebook Canvas",
    ["RingBuilder Facebook App Setup"]: "إعداد تطبيق RingBuilder Facebook",
    ["RingBuilder Facebook canvas App ID"]:
      "معرف تطبيق RingBuilder Facebook Canvas",
    ["RingBuilder Facebook canvas App URL"]:
      "رابط تطبيق RingBuilder Facebook canvas",
    ["StudBuilder Facebook App Setup"]: "إعداد تطبيق StudBuilder Facebook",
    ["StudBuilder Facebook canvas App ID"]:
      "معرف تطبيق StudBuilder Facebook Canvas",
    ["StudBuilder Facebook canvas App URL"]:
      "رابط تطبيق StudBuilder Facebook Canvas",
    ["PendantBuilder Facebook App Setup"]:
      "إعداد تطبيق PendantBuilder على Facebook",
    ["PendantBuilder Facebook canvas App ID"]:
      "معرف تطبيق Canvas الخاص بـ PendantBuilder على Facebook",
    ["PendantBuilder Facebook canvas App URL"]:
      "عنوان URL لتطبيق لوحة رسم Facebook Canvas Builder",
    ["Diamondlink Facebook App Setup"]: "إعداد تطبيق Diamondlink Facebook",
    ["DiamondLink Facebook canvas App ID"]:
      "معرف تطبيق DiamondLink Facebook Canvas",
    ["DiamondLink Facebook canvas App URL"]:
      "رابط تطبيق Facebook Canvas من DiamondLink",
    ["Basic Information"]: "معلومات اساسية",
    ["Notiﬁcation Settings"]: "إعدادات الإشعارات",
    ["Choose what you want to be notiﬁed about and where."]:
      "اختر ما تريد أن يتم إعلامك به وأين.",
    ["Inquiries"]: "الاستفسارات",
    ["New Favorites List"]: "قائمة المفضلة الجديدة",
    ["New Favorites"]: "المفضلة الجديدة",
    ["Vendor Requests"]: "طلبات البائعين",
    ["Send Upload Report"]: "إرسال تقرير التحميل",
    ["Purchase Orders"]: "طلبات الشراء",
    ["Sales Orders"]: "أوامر المبيعات",
    ["Ignore blank uploads"]: "تجاهل التحميلات الفارغة",
    ["SMTP Settings"]: "إعدادات SMTP",
    ["Send e-mails from your own address."]:
      "إرسال رسائل البريد الإلكتروني من عنوانك الخاص.",
    ["Mail Server"]: "خادم البريد",
    ["Port Number"]: "رقم المنفذ",
    ["Server Requires Authentication"]: "يتطلب الخادم المصادقة",
    ["Use SSL"]: "استخدم SSL",
    ["Test Your SMTP Settings"]: "اختبار إعدادات SMTP الخاصة بك",
    ["TEST"]: "امتحان",
    ["Add Map Here"]: "أضف الخريطة هنا",
    ["Location 1"]: "الموقع 1",
    ["Address"]: "عنوان",
    ["Zip"]: "أَزِيز",
    ["Location E-Mail"]: "الموقع البريد الإلكتروني",
    ["Location Phone"]: "الموقع الهاتف",
    ["Update Location"]: "تحديث الموقع",
    ["Hours of Operation"]: "ساعات العمل",
    ["Sun"]: "شمس",
    ["Mon"]: "الاثنين",
    ["Tues"]: "الثلاثاء",
    ["Wed"]: "تزوج",
    ["Thu"]: "الخميس",
    ["Fri"]: "الجمعة",
    ["Sat"]: "قعد",
    ["Location QR Code"]: "رمز الاستجابة السريعة للموقع",
    ["QR Code Generator"]: "مولد رمز الاستجابة السريعة",
    ["View Inventory"]: "عرض المخزون",
    ["Please Enter Min Price."]: "الرجاء إدخال الحد الأدنى للسعر.",
    ["Please Enter Max Price."]: "الرجاء إدخال السعر الأقصى.",
    ["Please Enter Markup."]: "الرجاء إدخال العلامة.",
    ["Confirmation"]: "تأكيد",
    ["Scheduled Reminder"]: "تذكير مجدول",
    ["Tier Name is Compulsory"]: "اسم المستوى إلزامي",
    ["Tier Value is Compulsory"]: "قيمة المستوى إلزامية",
    ["First Tier Value is Compulsory"]: "قيمة المستوى الأول إلزامية",
    ["Selecting Any Field is compulsory"]: "اختيار أي حقل أمر إلزامي",
    ["Tier Type"]: "نوع الطبقة",
    ["Tier One"]: "المستوى الأول",
    ["Tier Two"]: "المستوى الثاني",
    ["Enter Tier Name"]: "أدخل اسم الطبقة",
    ["View Location"]: "عرض الموقع",
    ["Country is compulsory"]: "البلد إلزامي",
    ["Country is invalid"]: "البلد غير صالح",
    ["Contact name is invalid"]: "اسم جهة الاتصال غير صالح",
    ["Add to Cart Report"]: "أضف إلى سلة التسوق التقرير",
    ["Wish List Report"]: "تقرير قائمة الرغبات",
    ["Please Enter Message."]: "الرجاء إدخال الرسالة.",
    ["View My"]: "عرض خاصتي",
    ["Attribute is compulsory"]: "الصفة إلزامية",
    ["Please Select csv file only"]: "الرجاء تحديد ملف csv فقط",
    ["Reset Mapping"]: "إعادة تعيين الخرائط",
    ["Vary Pricing By Finger Size"]: "تختلف الأسعار حسب حجم الإصبع",
    ["Schedule Reminder"]: "تذكير بالجدول",
    ["Select Finger Size"]: "حدد حجم الإصبع",
    ["Video Type"]: "نوع الفيديو",
    ["This option will override the data."]:
      "سيؤدي هذا الخيار إلى إلغاء البيانات.",
    ["Load Image From URL"]: "تحميل الصورة من الرابط",
    ["Trade show is compulsory"]: "المعرض التجاري إلزامي",
    ["Sales rep is compulsory"]: "مندوب المبيعات إلزامي",
    ["Appointment date is compulsory"]: "موعد الموعد إلزامي",
    ["Buyer name is compulsory"]: "اسم المشتري إلزامي",
    ["Question is compulsory"]: "السؤال اجباري",
    ["Time is compulsory"]: "الوقت إلزامي",
    ["Write the first message ...."]: "أكتب الرسالة الأولى ....",
    ["Refresh"]: "ينعش",
    ["Post"]: "بريد",
    ["Select Sales Rep"]: "حدد مندوب المبيعات",
    ["Location Details"]: "تفاصيل الموقع",
    ["Location Email"]: "الموقع الإلكتروني",
    ["Sunday"]: "الأحد",
    ["AM to"]: "صباحا الى",
    ["No results found"]: "لم يتم العثور على نتائج",
    ["Wednesday"]: "الأربعاء",
    ["No record found"]: "لم يتم العثور على أي سجل",
    ["Select 1 Tier PageName"]: "حدد صفحة اسم المستوى 1",
    ["Select 2 Tier PageName"]: "حدد اسم الصفحة المكونة من مستويين",
    ["Select Location"]: "حدد الموقع",
    ["Use"]: "يستخدم",
    ["Select File"]: "حدد الملف",
    ["Upload Images"]: "تحميل الصور",
    ["Use this Company Name,  and bio as my Brand name,  and Bio on my authorized Retailer websites."]:
      "استخدم اسم الشركة هذا والسيرة الذاتية كاسم علامتي التجارية والسيرة الذاتية على مواقع الويب الخاصة بموزعي التجزئة المعتمدين.",
    ["Click here to configure your different Brand marketing message for use on your authorized Retailer websites."]:
      "انقر هنا لتكوين رسالة التسويق الخاصة بعلامتك التجارية المختلفة لاستخدامها على مواقع الويب الخاصة بتجار التجزئة المعتمدين لديك.",
    ["Since"]: "منذ",
    ["Primary E-mail"]: "البريد الإلكتروني الأساسي",
    ["Primary Phone"]: "الهاتف الأساسي",
    ["Primary Contact Name"]: "اسم جهة الاتصال الأساسية",
    ["Company Logo"]: "شعار الشركة",
    ["Phone no is invalid"]: "رقم الهاتف غير صالح",
    ["Change"]: "يتغير",
    ["Product Details"]: "تفاصيل المنتج",
    ["Jewelery Characteristics"]: "خصائص المجوهرات",
    ["SKU #"]: "رقم المنتج #",
    ["Style"]: "أسلوب",
    ["Cost ($)"]: "التكلفة ($)",
    ["MSRP ($)"]: "السعر الموصى به من الشركة المصنعة ($)",
    ["Width (mm)"]: "العرض (مم)",
    ["Thickness (mm)"]: "السمك (مم)",
    ["Length (in)"]: "الطول (بوصة)",
    ["Weight (gm)"]: "الوزن (جم)",
    ["Last Updated On"]: "آخر تحديث في",
    ["Description"]: "وصف",
    ["Dealer Info"]: "معلومات التاجر",
    ["Dealer Information"]: "معلومات التاجر",
    ["Company"]: "شركة",
    ["Contact Person"]: "الشخص الذي يمكن الاتصال به",
    ["Fax"]: "فاكس",
    ["E-mail"]: "بريد إلكتروني",
    ["Export All"]: "تصدير الكل",
    ["Please select .csv|xls|txt file only."]:
      "الرجاء تحديد ملف .csv|xls|txt فقط.",
    ["Lab Grown Diamond"]: "الماس المزروع في المختبر",
    ["Warning: Changing or modifying your"]: "تحذير: تغيير أو تعديل",
    ["will cause the"]: "سوف يسبب ذلك",
    ["to be reset: Click Yes to proceed"]:
      'لإعادة التعيين: انقر فوق "نعم" للمتابعة',
    ["In Frame"]: "في الإطار",
    ["Update Filters"]: "تحديث المرشحات",
    ["Invalid range"]: "نطاق غير صالح",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price and Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      'بشكل افتراضي، سيعرض بحثك تفاصيل الماس الأساسية (القطع واللون والوضوح والحجم والسعر والشهادة) على صفحة نتائج البحث. يمكنك اختيار عرض معلومات أكثر تفصيلاً (الجدول والعمق والقياسات) بالإضافة إلى المعلومات الأساسية على صفحة نتائج البحث. بغض النظر عن الطريقة التي تختارها، فإن التفاصيل الكاملة لكل ماسة تكون متاحة دائمًا بالنقر فوق "مزيد من المعلومات" على صفحة نتائج البحث.',
    ["You may choose to search by Cut Grade if desired. Table and Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "يمكنك اختيار البحث حسب درجة القطع إذا كنت ترغب في ذلك. يتم تمكين البحث بالجدول والعمق أيضًا لأنهما مرتبطان بشكل مباشر بدرجة القطع.",
    ["Please Select Default Markup"]: "الرجاء تحديد الترميز الافتراضي",
    ["Min Range should be less than 10 digit"]:
      "يجب أن يكون الحد الأدنى للنطاق أقل من 10 أرقام",
    ["Max Range should be less than 10 digit"]:
      "يجب أن يكون النطاق الأقصى أقل من 10 أرقام",
    ["Max Price should be greater than Min Price"]:
      "يجب أن يكون السعر الأقصى أكبر من السعر الأدنى",
    ["Cut/Shape"]: "قطع/شكل",
    ["MaxPrice Should be greater than MinPrice"]:
      "يجب أن يكون السعر الأقصى أكبر من السعر الأدنى",
    ["Please enter Max price up to two decimal places"]:
      "الرجاء إدخال السعر الأقصى حتى رقمين عشريين",
    ["Choose Whether you would like a particular metal type to be set as the default when viewing a setting in RingBuilder."]:
      "اختر ما إذا كنت ترغب في تعيين نوع معدن معين كإعداد افتراضي عند عرض إعداد في RingBuilder.",
    ["Choose Whether to display the “Add to Cart” button or not."]:
      'اختر ما إذا كنت تريد عرض زر "إضافة إلى سلة التسوق" أم لا.',
    ["14k White Gold"]: "ذهب أبيض عيار 14",
    ["14k Yellow Gold"]: "ذهب أصفر عيار 14",
    ["14k Rose Gold"]: "ذهب وردي عيار 14",
    ["18k White Gold"]: "ذهب أبيض عيار 18",
    ["Certificate"]: "شهادة",
    ["Position"]: "موضع",
    ["Negative Values is not Accepted"]: "القيم السلبية غير مقبولة",
    ["Choose whether to display tool alignment as Center, Left, or Right position."]:
      "اختر ما إذا كنت تريد عرض محاذاة الأداة في موضع المركز، أو اليسار، أو اليمين.",
    ["Choose whether to display the share button (FaceBook, Twitter, etc.) on the diamond details page."]:
      "اختر ما إذا كنت تريد عرض زر المشاركة (FaceBook، Twitter، وما إلى ذلك) على صفحة تفاصيل الماس.",
    ["Choose whether to show In Store diamond on the diamond inventory page."]:
      "اختر ما إذا كنت تريد إظهار الماس الموجود في المتجر على صفحة مخزون الماس.",
    ["Choose whether to use the popup of diamond details on the diamond inventory page."]:
      "اختر ما إذا كنت تريد استخدام النافذة المنبثقة لتفاصيل الماس على صفحة مخزون الماس.",
    ["Choose the number of setting displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "اختر عدد الإعدادات المعروضة في كل صفحة من نتائج البحث. بالنسبة للقيم غير المعروضة، يجب عليك الاتصال بـ GemFind مباشرة.",
    ["Choose whether to display settings based on the sort order of Price, Metal Type, or Style Number."]:
      "اختر ما إذا كنت تريد عرض الإعدادات استنادًا إلى ترتيب السعر، أو نوع المعدن، أو رقم الطراز.",
    ["Choose whether to display sorting results in Ascending or Descending order."]:
      "اختر ما إذا كنت تريد عرض نتائج الفرز بترتيب تصاعدي أو تنازلي.",
    ["Approve"]: "يعتمد",
    ["Disapprove"]: "عدم الموافقة",
    ["Connected and Pending Request Details"]: "تفاصيل الطلب المتصل والمعلق",
    ["Hierarchy"]: "تَسَلسُل",
    ["Date Range"]: "نطاق التاريخ",
    ["MasterLink"]: "ماسترلينك",
    ["RingBuilder"]: "صانع الخواتم",
    ["StudBuilder"]: "برنامج بناء الاستاد",
    ["PendantBuilder"]: "صانع المعلقات",
    ["WatchBuilder"]: "صانع الساعات",
    ["No Data"]: "لا يوجد بيانات",
    ["Date Added"]: "تاريخ الإضافة",
    ["Diamond Charts"]: "مخططات الماس",
    ["UserIPAddress"]: "عنوان IP للمستخدم",
    ["RetailerPrice"]: "سعر التجزئة",
    ["Select View"]: "حدد العرض",
    ["Available Online Only"]: "متوفر عبر الإنترنت فقط",
    ["Best Seller"]: "الأكثر مبيعًا",
    ["Clearance / Sale"]: "تصفية / بيع",
    ["Close Out/ Discontinue"]: "إغلاق/إيقاف",
    ["Add Campaign"]: "إضافة حملة",
    ["Name is Compulsory."]: "الإسم إلزامي.",
    ["Vendor is Compulsory."]: "البائع إلزامي.",
    ["Subject is Compulsory."]: "الموضوع إلزامي.",
    ["Body is Compulsory."]: "الجسم إلزامي.",
    ["Content is Compulsory."]: "المحتوى إلزامي.",
    ["Send From"]: "إرسال من",
    ["Mail Content"]: "محتوى البريد",
    ["Body"]: "جسم",
    ["Content"]: "محتوى",
    ["Enter Name"]: "أدخل الاسم",
    ["Change Password"]: "تغيير كلمة المرور",
    ["Email Preferences"]: "تفضيلات البريد الإلكتروني",
    ["Social Integrations"]: "التكاملات الاجتماعية",
    ["Brand"]: "ماركة",
    ["Solr Core Clients"]: "عملاء Solr الأساسيون",
    ["Add color"]: "إضافة اللون",
    ["Extended Diamond Charts"]: "مخططات الماس الممتدة",
    ["Marketing List"]: "قائمة التسويق",
    ["Api Endpoint and Authentication"]: "نقطة نهاية API والمصادقة",
    ["Manage Vendor"]: "إدارة البائع",
    ["MarkUp"]: "ترميز",
    ["Departments"]: "الأقسام",
    ["Manage vendor"]: "إدارة البائع",
    ["My Vendor"]: "بائعي",
    ["Network Vendor"]: "بائع الشبكة",
    ["Data"]: "بيانات",
    ["Import"]: "يستورد",
    ["Connection"]: "اتصال",
    ["New Vendors"]: "بائعين جدد",
    ["Pending Request"]: "طلب معلق",
    ["Popular Vendor"]: "بائع شعبي",
    ["Retailer Group"]: "مجموعة تجار التجزئة",
    ["Manage Dealers"]: "إدارة التجار",
    ["Add Jewelry"]: "أضف المجوهرات",
    ["Jewelry MarkUp"]: "أسعار المجوهرات",
    ["Master Link Settings"]: "إعدادات الرابط الرئيسي",
    ["All Product"]: "جميع المنتجات",
    ["My Product"]: "منتجي",
    ["Shared Product"]: "المنتج المشترك",
    ["Customers"]: "عملاء",
    ["Customer Info"]: "معلومات العميل",
    ["Messages"]: "رسائل",
    ["Registered:"]: "مسجل:",
    ["Other Info"]: "معلومات أخرى",
    ["Most Active"]: "الأكثر نشاطا",
    ["Recent Conversations"]: "المحادثات الأخيرة",
    ["Contact Tags"]: "علامات الاتصال",
    ["Enter Tag Name"]: "أدخل اسم العلامة",
    ["Top Designers"]: "أفضل المصممين",
    ["New Customers"]: "العملاء الجدد",
    ["New Arrivals"]: "الوافدون الجدد",
    ["Note Cannot Be Blank."]: "ملاحظة لا يمكن أن تكون فارغة.",
    ["Data Cannot Be Blank."]: "لا يمكن أن تكون البيانات فارغة.",
    ["Tag Cannot Be Blank."]: "لا يمكن أن تكون العلامة فارغة.",
    ["Please Select Contact."]: "الرجاء تحديد جهة الاتصال.",
    ["Please Select Calendar."]: "الرجاء اختيار التقويم.",
    ["Please Select Time."]: "الرجاء تحديد الوقت.",
    ["Please Select Day."]: "الرجاء تحديد اليوم.",
    ["Time is Compulsory."]: "الوقت إلزامي.",
    ["Day is Compulsory."]: "اليوم إلزامي.",
    ["Reply"]: "رد",
    ["Edit Appointment"]: "تعديل الموعد",
    ["Delete Appointment"]: "حذف الموعد",
    ["Approved Users"]: "المستخدمون المعتمدون",
    ["Pending Users"]: "المستخدمون المعلقون",
    ["Reject"]: "يرفض",
    ["Create New Pair"]: "إنشاء زوج جديد",
    ["Enter Key"]: "مفتاح الإدخال",
    ["Enter Value"]: "أدخل القيمة",
    ["Jewelry Clicks Based on Retailer"]:
      "نقرات المجوهرات بناءً على بائع التجزئة",
    ["Diamond Color Searches"]: "عمليات البحث عن لون الماس",
    ["Diamond Size Searches"]: "عمليات البحث عن حجم الماس",
    ["Diamond CutGrade Searches"]: "عمليات البحث عن درجة قطع الماس",
    ["Diamond Price"]: "سعر الماس",
    ["Add New Email"]: "إضافة بريد إلكتروني جديد",
    ["Enter Alias Address"]: "أدخل عنوان الاسم المستعار",
    ["Add New Recipient"]: "إضافة مستلم جديد",
    ["Jewelry Clicks Based on Metal Type"]:
      "نقرات المجوهرات بناءً على نوع المعدن",
    ["Group Discount Name"]: "اسم الخصم الجماعي",
    ["Discount Value"]: "قيمة الخصم",
    ["Company Name (User Name)"]: "اسم الشركة (اسم المستخدم)",
    ["Group Name"]: "اسم المجموعة",
    ["Add Retailer"]: "إضافة بائع التجزئة",
    ["BrandGroup"]: "مجموعة براند",
    ["Role"]: "دور",
    ["Address2"]: "العنوان2",
    ["Select GroupName"]: "حدد اسم المجموعة",
    ["Retailer Number"]: "رقم التاجر",
    ["Sales Person"]: "موظف المبيعات",
    ["Price Group"]: "مجموعة الأسعار",
    ["Telephone"]: "الهاتف",
    ["FTP Location"]: "موقع FTP",
    ["FTP User Password"]: "كلمة مرور مستخدم FTP",
    ["Update Detail"]: "تحديث التفاصيل",
    ["Attribute Detail"]: "تفاصيل السمة",
    ["Anniversary Band"]: "فرقة الذكرى السنوية",
    ["Mapping Help Docs"]: "وثائق مساعدة في رسم الخرائط",
    ["Please Map Column First"]: "يرجى رسم الخريطة على العمود الأول",
    ["No information available for selected user."]:
      "لا تتوفر معلومات للمستخدم المحدد.",
    ["Display Video"]: "عرض الفيديو",
    ["Display Image"]: "عرض الصورة",
    ["No File Chosen"]: "لم يتم اختيار الملف",
    ["Kindly select the Country"]: "الرجاء اختيار البلد",
    ["Enter Valid Dealer Id"]: "أدخل معرف التاجر الصحيح",
    ["Dealer Not Found"]: "لم يتم العثور على التاجر",
    ["Send Mail To Retailer"]: "إرسال بريد إلى التاجر",
    ["Send Mail To Recipient"]: "إرسال البريد إلى المستلم",
    ["Both the entered password should match"]:
      "يجب أن تتطابق كلمتي المرور المدخلتين",
    ["New Password"]: "كلمة المرور الجديدة",
    ["Change password"]: "تغيير كلمة المرور",
    ["BrandID"]: "معرف العلامة التجارية",
    ["BrandName"]: "العلامة التجارية",
    ["Link"]: "وصلة",
    ["Manage Brand"]: "إدارة العلامة التجارية",
    ["Create Brand"]: "إنشاء العلامة التجارية",
    ["Insert Brand"]: "أدخل العلامة التجارية",
    ["Use Vendor Profile Logo"]: "استخدم شعار ملف تعريف البائع",
    ["Fashion Designer"]: "مصمم أزياء",
    ["Watch Designer"]: "مصمم الساعات",
    ["Showcase Image 1"]: "صورة العرض 1",
    ["Showcase Image 2"]: "صورة العرض 2",
    ["Showcase Image 3"]: "صورة العرض 3",
    ["Use In Showcase"]: "استخدم في العرض",
    ["Designer Small Banner"]: "تصميم لافتة صغيرة",
    ["Video URL"]: "رابط الفيديو",
    ["How To Upload Video"]: "كيفية تحميل الفيديو",
    ["URL"]: "عنوان URL",
    ["About Designer"]: "نبذة عن المصمم",
    ["Tag Line"]: "خط العلامة",
    ["You can enter up to 100 characters."]: "يمكنك إدخال ما يصل إلى 100 حرف.",
    ["Shows"]: "العروض",
    ["Las Vegas Antique Jewelry"]: "مجوهرات لاس فيغاس العتيقة",
    ["Booth #(s)"]: "رقم الكشك",
    ["Notes & Show Specials"]: "ملاحظات وعروض خاصة",
    ["Save Show Details"]: "احفظ عرض التفاصيل",
    ["User Avatar"]: "صورة المستخدم الرمزية",
    ["Current Password"]: "كلمة المرور الحالية",
    ["Confirm New Password"]: "تأكيد كلمة المرور الجديدة",
    ["Location is compulsory"]: "الموقع إلزامي",
    ["Actions"]: "الإجراءات",
    ["Reorder"]: "إعادة ترتيب",
    ["PO Name is Compulsory"]: "اسم الطلب إلزامي",
    ["PO Notes is Compulsory"]: "ملاحظات الشراء إلزامية",
    ["Worksheet"]: "ورقة عمل",
    ["Use this to build and manage your products before sending a purchase order."]:
      "استخدم هذا لإنشاء منتجاتك وإدارتها قبل إرسال طلب الشراء.",
    ["View All"]: "عرض الكل",
    ["Delete the"]: "حذف",
    ["Cancel The"]: "إلغاء",
    ["Order"]: "طلب",
    ["Order#"]: "#طلب",
    ["Generate PO"]: "إنشاء أمر شراء",
    ["PO Name"]: "اسم الطلب",
    ["Purchase Order Summery"]: "ملخص طلب الشراء",
    ["Create RO"]: "إنشاء RO",
    ["Shipping Address"]: "عنوان الشحن",
    ["Manufacturer Address"]: "عنوان الشركة المصنعة",
    ["Order Notes"]: "ملاحظات الطلب",
    ["4 Tier PageName"]: "اسم الصفحة المكون من 4 طبقات",
    ["Add New Tier"]: "إضافة طبقة جديدة",
    ["Kind"]: "عطوف",
    ["Modified"]: "معدل",
    ["Upload Media"]: "تحميل الوسائط",
    ["Add Folder"]: "إضافة مجلد",
    ["Are You Sure You Want To Delete This Folder ?"]:
      "هل أنت متأكد أنك تريد حذف هذا المجلد؟",
    ["THIS CAN NOT BE UNDO"]: "لا يمكن التراجع عن هذا",
    ["Upload Your Media File"]: "لا يمكن التراجع عن هذا",
    ["Questions? View the Media Import Help Doc."]:
      "هل لديك أسئلة؟ اطلع على مستند تعليمات استيراد الوسائط.",
    ["Share marketing materials across your reatailer network."]:
      "شارك المواد التسويقية عبر شبكة تجار التجزئة لديك.",
    ["Please Select Folder"]: "الرجاء تحديد المجلد",
    ["Total Mined Diamonds"]: "إجمالي الماس المستخرج",
    ["Total LabGrown Diamonds"]: "إجمالي الماس المزروع في المختبر",
    ["Total Color Diamonds"]: "مجموع الماس الملون",
    ["Update List"]: "تحديث القائمة",
    ["Our Signature Diamond"]: "الماسة المميزة لدينا",
    ["Network Vendors"]: "بائعي الشبكة",
    ["Stud Builder Email"]: "البريد الإلكتروني لمنشئ العوارض الخشبية",
    ["Add to Worksheet"]: "أضف إلى ورقة العمل",
    ["PriceMethod"]: "طريقة السعر",
    ["Page Not access Please Contect to admin."]:
      "الصفحة غير قابلة للوصول يرجى الاتصال بالمسؤول.",
    ["Customer Profile"]: "الملف الشخصي للعميل",
    ["Add Details"]: "إضافة التفاصيل",
    ["Message Cannot Be Blank."]: "لا يمكن أن تكون الرسالة فارغة.",
    ["Enter E-mail"]: "أدخل البريد الإلكتروني",
    ["Top Designer"]: "أفضل مصمم",
    ["Select Trade Show"]: "اختر المعرض التجاري",
    ["I am interested in opening a new account with"]:
      "أنا مهتم بفتح حساب جديد مع",
    ["Pendant Builder Shopping cart configuration instructions"]:
      "تعليمات تكوين عربة التسوق الخاصة بصانع المعلقات",
    ["Select Files"]: "حدد الملفات",
    ["Insert Brands"]: "إدراج العلامات التجارية",
    ["Designer Logo"]: "شعار المصمم",
    ["Update Brands"]: "تحديث العلامات التجارية",
    ["Sample Showcase Preview"]: "معاينة عرض العينة",
    ["THIS CANNOT BE UNDONE"]: "لا يمكن التراجع عن هذا",
    ["If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor)."]:
      "إذا كانت شركتك تبيع منتجاتها تحت اسم علامة تجارية استهلاكية، فاستخدم هذه الواجهة لتكوين معلومات العلامة التجارية. سيتم إدراج أسماء العلامات التجارية على مواقع الويب الخاصة بالمتاجر في التنقل بين الفئات (ما لم يتم تقييدها من قبل البائع).",
    ["Delete The "]: "حذف",
    ["Brand "]: "ماركة",
    ["Fluor."]: "فلور.",
    ["Measure."]: "يقيس.",
    ["Sym."]: "الرمز",
    ["Brand ID"]: "معرف العلامة التجارية",
    ["Advertisement Status"]: "حالة الإعلان",
    ["Company name is Taken"]: "اسم الشركة مأخوذ",
    ["No Data Available."]: "لا توجد بيانات متاحة.",
    ["Data added succesfully"]: "تمت إضافة البيانات بنجاح",
    ["No Data Found"]: "لم يتم العثور على بيانات",
    ["Folder Name"]: "اسم المجلد",
    ["Reports"]: "التقارير",
    ["Sorry, No Chart Data was found"]:
      "عذرا، لم يتم العثور على بيانات الرسم البياني",
    ["Diamond Price Based Add To Cart Report"]:
      "تقرير سعر الماس بناءً على إضافة إلى سلة التسوق",
    ["Diamond Price Based Wish List Report"]:
      "تقرير قائمة الرغبات بناءً على سعر الماس",
    ["Diamond Vendor based Add To Cart Report"]:
      "تقرير إضافة إلى عربة التسوق بناءً على بائع الماس",
    ["Diamond Vendor based Wish List Report"]:
      "تقرير قائمة الرغبات الخاصة ببائع الماس",
    ["Jewelry Price Based Add To Cart Report"]:
      "تقرير أسعار المجوهرات بناءً على الإضافة إلى سلة التسوق",
    ["Jewelry Price Based Wish List Report"]:
      "تقرير قائمة الرغبات الخاصة بأسعار المجوهرات",
    ["Jewelery Vendor based Add To Cart Report"]:
      "تقرير إضافة إلى عربة التسوق بناءً على بائع المجوهرات",
    ["Jewelery Vendor based Wish List Report"]:
      "تقرير قائمة الرغبات الخاصة ببائعي المجوهرات",
    ["Updated Date"]: "تاريخ التحديث",
    ["MarkUp Overrride"]: "تجاوز الترميز",
    ["Percent"]: "النسبة المئوية",
    ["This shows the collection on your own MasterLink, for preview purposes"]:
      "يعرض هذا المجموعة على MasterLink الخاص بك، لأغراض المعاينة",
    ["This shares the collection with your connected retailers"]:
      "يشارك هذا المجموعة مع تجار التجزئة المتصلين بك",
    ["Please Contact Gemfind to get Access."]:
      "يرجى الاتصال بـGemfind للحصول على حق الوصول.",
    ["Assecher"]: "أسيشر",
    ["Request Certificate"]: "طلب شهادة",
    ["The report for this diamond is not viewable online."]:
      "لا يمكن عرض التقرير الخاص بهذه الماسة عبر الإنترنت.",
    ["Please contact us by telephone or the form below if you are interested in receiving more information about this diamond."]:
      "يرجى الاتصال بنا عبر الهاتف أو النموذج أدناه إذا كنت مهتمًا بتلقي مزيد من المعلومات حول هذا الماس.",
    ["Message to"]: "رسالة إلى",
    ["Your diamonds are deleted.However it will take some moments to remove them from cache.Deleted diamonds will be removed from this page as soon cache gets refreshed."]:
      "تم حذف الماس الخاص بك. ومع ذلك، سوف يستغرق الأمر بعض الوقت لإزالته من ذاكرة التخزين المؤقت. سيتم إزالة الماس المحذوف من هذه الصفحة بمجرد تحديث ذاكرة التخزين المؤقت.",
    ["Your jewellery(s) are deleted.However it will take some moments to remove them from cache.Deleted jewellery(s) will be removed from this page as soon cache gets refreshed."]:
      "تم حذف مجوهراتك. ومع ذلك، سوف يستغرق الأمر بعض الوقت لإزالتها من ذاكرة التخزين المؤقت. سيتم إزالة المجوهرات المحذوفة من هذه الصفحة بمجرد تحديث ذاكرة التخزين المؤقت.",
    ["Not Authorized"]: "غير مصرح به",
    ["Not deleted as those are not your diamonds."]:
      "لم يتم حذفها لأنها ليست الماس الخاص بك.",
    ["Diamond List"]: "قائمة الماس",
    ["Trilliant"]: "تريليانت",
    ["Pink"]: "لون القرنفل",
    ["Gray"]: "رمادي",
    ["Black"]: "أسود",
    ["Purple"]: "أرجواني",
    ["Chameleon"]: "حرباء",
    ["Violet"]: "بنفسجي",
    ["No items found"]: "لم يتم العثور على أي عناصر",
    ["reset"]: "إعادة ضبط",
    ["your filters"]: "مرشحاتك",
    ["Add To WorkSheet"]: "أضف إلى ورقة العمل",
    ["Add Products"]: "إضافة المنتجات",
    ["WorkSheet Name"]: "اسم ورقة العمل",
    ["View WorkSheet"]: "عرض ورقة العمل",
    ["Upload Certificates"]: "تحميل الشهادات",
    ["Zip Format"]: "تنسيق الرمز البريدي",
    ["No Data To Display"]: "لا توجد بيانات لعرضها",
    ["Customer added"]: "تمت إضافة العميل",
    ["as favorite through"]: "كمفضل من خلال",
    ["Create WorkSheet"]: "إنشاء ورقة عمل",
    ["Delete Worksheet"]: "حذف ورقة العمل",
    ["Worksheet Name"]: "اسم ورقة العمل",
    ["Please Connect the Vendor for more information."]:
      "يرجى الاتصال بالبائع للحصول على مزيد من المعلومات.",
    ["Item Count"]: "عدد العناصر",
    ["Reorder PO"]: "إعادة ترتيب طلب الشراء",
    ["View Order"]: "عرض الطلب",
    ["Cancel Order"]: "إلغاء الطلب",
    ["T"]: "ت",
    ["Department Name"]: "اسم القسم",
    ["Total Item"]: "مجموع البند",
    ["SubTotal"]: "المجموع الفرعي",
    ["PO Total"]: "إجمالي الطلب",
    ["Vendor to provide shipping/insurance info."]:
      "يجب على البائع توفير معلومات الشحن/التأمين.",
    ["Diamond Retailers"]: "تجار الماس",
    ["Jewelry Retailer & Permissions"]: "بائع المجوهرات والأذونات",
    ["Blocked Products"]: "المنتجات المحظورة",
    ["Product Restriction"]: "تقييد المنتج",
    ["Collection Access"]: "الوصول إلى المجموعة",
    ["Product Access"]: "الوصول إلى المنتج",
    ["Manage Retailer Access To Your Inventory"]:
      "إدارة وصول بائع التجزئة إلى مخزونك",
    ["Set permissions for retailers to display your virtual inventory on their website."]:
      "قم بتعيين الأذونات للتجار لعرض مخزونك الافتراضي على موقع الويب الخاص بهم.",
    ["Auto Approval"]: "الموافقة التلقائية",
    ["Automatically approve retailers requesting to open an account."]:
      "الموافقة تلقائيًا على طلبات تجار التجزئة لفتح حساب.",
    ["Filter By Sales Rep"]: "تصفية حسب مندوب المبيعات",
    ["From"]: "من",
    ["To"]: "ل",
    ["Upload a datasheet with the column header of StyleNumber. Include one SKU per row of each product you want to block from your chosen connected retailers. Those retailers selected for these SKU's, will not be given access to those products."]:
      "قم بتحميل ورقة بيانات برأس العمود StyleNumber. قم بتضمين SKU واحد لكل صف من كل منتج تريد حظره من تجار التجزئة المتصلين الذين اخترتهم. لن يتم منح تجار التجزئة الذين تم اختيارهم لهذه SKU حق الوصول إلى هذه المنتجات.",
    ["Connected Retailers"]: "تجار التجزئة المتصلين",
    ["Schedule New"]: "جدول جديد",
    ["Print Current List"]: "طباعة القائمة الحالية",
    ["Pending"]: "قيد الانتظار",
    ["No notes added"]: "لم تتم إضافة أي ملاحظات",
    ["Connected Vendors List"]: "قائمة البائعين المتصلين",
    ["Run Campaign"]: "تشغيل الحملة",
    ["Upload History Details"]: "تفاصيل تاريخ التحميل",
    ["Media Preferences"]: "تفضيلات الوسائط",
    ["White Label"]: "العلامة البيضاء",
    ["Create Organization"]: "إنشاء منظمة",
    ["My Catalog"]: "كتالوجي",
    ["You Have Diamonds"]: "لديك الماس",
    ["This is the field that corresponds with the JewelCloud defined column."]:
      "هذا هو الحقل الذي يتوافق مع العمود المحدد بواسطة JewelCloud.",
    ["IF"]: "لو",
    ["Med"]: "ميد",
    ["To define the product styles that are grouped together. Enter Dealer ID or Style #' separated with commas."]:
      "لتحديد أنماط المنتج التي تم تجميعها معًا. أدخل معرف الوكيل أو رقم النمط مفصولاً بفواصل.",
    ["From here the user can upload at the most 7 comma separated images from server at once"]:
      "من هنا لا يمكن للمستخدم تحميل أكثر من 7 صور مفصولة بفواصل من الخادم مرة واحدة",
    ["User can add Video Url from here"]:
      "يمكن للمستخدم إضافة عنوان URL للفيديو من هنا",
    ["Stone Creation Method"]: "طريقة إنشاء الحجر",
    ["Gemstone Lot Code"]: "رمز مجموعة الأحجار الكريمة",
    ["Gemstone Setting Labor Code"]: "وضع الأحجار الكريمة قانون العمل",
    ["Stone Treatment Method"]: "طريقة معالجة الحجر",
    ["Retailer Price"]: "سعر التجزئة",
    ["Total Base Price"]: "إجمالي السعر الأساسي",
    ["Products without a checkbox are not eligible for our Purchase Order program."]:
      "المنتجات التي لا تحتوي على مربع اختيار ليست مؤهلة لبرنامج طلبات الشراء الخاص بنا.",
    ["Order Qty"]: "طلب الكمية",
    ["Suggested Retail"]: "التجزئة المقترحة",
    ["Wholesale Cost"]: "تكلفة الجملة",
    ["Total Estimate"]: "التقدير الإجمالي",
    ["Total Cost"]: "التكلفة الإجمالية",
    ["Create PO"]: "إنشاء أمر الشراء",
    ["Delete Worksheet"]: "حذف ورقة العمل",
    ["Total Qty"]: "إجمالي الكمية",
    ["Item Detail"]: "تفاصيل السلعة",
    ["Purchase Order Detail"]: "تفاصيل أمر الشراء",
    ["Update Detail"]: "تحديث التفاصيل",
    ["Pending"]: "قيد الانتظار",
    ["Shipped"]: "تم الشحن",
    ["Unpaid"]: "غير مدفوعة الأجر",
    ["Completed"]: "مكتمل",
    ["Cancelled"]: "تم الإلغاء",
    ["Retailer Details"]: "تفاصيل بائع التجزئة",
    ["Reorder PO"]: "إعادة طلب أمر الشراء",
    ["Print PO"]: "طباعة ص",
    ["Cancel Order"]: "إلغاء الطلب",
    ["Item"]: "غرض",
    ["Vendor SKU"]: "رمز التخزين التعريفي للمورد",
    ["Qty"]: "الكمية",
    ["Order Notes"]: "ملاحظات الطلب",
    ["Vendor Count"]: "عدد البائعين",
    ["Diamond Clarity"]: "وضوح الماس",
    ["Diamond Certificate"]: "شهادة الماس",
    ["Diamond Color"]: "لون الماس",
    ["Diamond Cut"]: "قطع الماس",
    ["Diamond Shape"]: "شكل الماس",
    ["Diamond Cut Grade"]: "درجة قطع الماس",
    ["Diamond Size"]: "حجم الماس",
    ["Jewelry Category"]: "فئة المجوهرات",
    ["Jewelry Chart"]: "مخطط المجوهرات",
    ["Jewelry Metal Type"]: "نوع المعدن المستخدم في المجوهرات",
    ["Jewelry Price"]: "سعر المجوهرات",
    ["Jewelry Vendor Clicks"]: "نقرات بائع المجوهرات",
    ["Jewelry Retailer Click"]: "بائع المجوهرات انقر هنا",
    ["Price Based Add To Cart"]: "السعر يعتمد على الإضافة إلى سلة التسوق",
    ["Vendor Based Add To Cart"]: "إضافة إلى سلة التسوق حسب البائع",
    ["Vendor Based Wish List"]: "قائمة الرغبات حسب البائع",
    ["Jewelery Report"]: "تقرير المجوهرات",
    ["Vendor Request"]: "طلب البائع",
    ["Designer name is compulsory"]: "اسم المصمم إلزامي",
    ["Template Name"]: "اسم القالب",
    ["Select"]: "يختار",
    ["Email Status"]: "حالة البريد الإلكتروني",
    ["Enter Discount Name"]: "أدخل اسم الخصم",
    ["Enter Discount Value"]: "أدخل قيمة الخصم",
    ["Other Info"]: "معلومات أخرى",
    ["Vendor Details"]: "تفاصيل البائع",
    ["Record Updated Successfully."]: "تم تحديث السجل بنجاح.",
    ["Record already Exists."]: "السجل موجود بالفعل.",
    ["Email is Compulsory."]: "البريد الإلكتروني إلزامي.",
    ["Email address is invalid."]: "عنوان البريد الإلكتروني غير صالح.",
    ["Worksheets"]: "أوراق العمل",
    ["My Worksheet"]: "ورقة العمل الخاصة بي",
    ["My Purchase Orders"]: "طلبات الشراء الخاصة بي",
    ["PO Number"]: "رقم الطلب",
    ["Preview PO"]: "معاينة أمر الشراء",
    ["Select Shipping Address :"]: "حدد عنوان الشحن :",
    ["OR"]: "أو",
    ["Add New Shipping Address"]: "إضافة عنوان شحن جديد",
    ["Shipping Address :"]: "عنوان الشحن :",
    ["Email:"]: "بريد إلكتروني:",
    ["T:"]: "ت:",
    ["Manufacturer Address :"]: "عنوان الشركة المصنعة :",
    ["Order Notes:"]: "ملاحظات الطلب:",
    ["Total Item:"]: "مجموع البند:",
    ["SubTotal:"]: "المجموع الفرعي:",
    ["PO Total:"]: "إجمالي الطلب:",
    ["Vendor to provide shipping/insurance info."]:
      "يجب على البائع توفير معلومات الشحن/التأمين.",
    ["Add Location Name"]: "أضف اسم الموقع",
    ["Location Name"]: "اسم الموقع",
    ["Subtotal"]: "المجموع الفرعي",
    ["PO Has Been Created Successfully."]: "تم إنشاء طلب الشراء بنجاح.",
    ["PO Has Not Been Created."]: "لم يتم إنشاء طلب الشراء.",
    ["Location name should not be empty"]: "لا ينبغي أن يكون اسم الموقع فارغًا",
    ["Add Location"]: "إضافة الموقع",
    ["Address is compulsory"]: "العنوان إلزامي",
    ["Zip is compulsory"]: "الرمز البريدي إلزامي",
    ["Phone is compulsory"]: "الهاتف إلزامي",
    ["Location Updated Successfully."]: "تم تحديث الموقع بنجاح.",
    ["Add Address"]: "إضافة عنوان",
    ["Are you sure?"]: "هل أنت متأكد؟",
    ["Updated Successfully."]: "تم التحديث بنجاح.",
    ["Not Updated."]: "لم يتم التحديث.",
    ["PO name Already Exists."]: "اسم الطلب موجود بالفعل.",
    ["Worksheet Name Already Exists."]: "اسم ورقة العمل موجود بالفعل.",
    ["Worksheet Name Has Been Updated Successfully."]:
      "تم تحديث اسم ورقة العمل بنجاح.",
    ["Worksheet Name Has Not Been Updated."]: "لم يتم تحديث اسم ورقة العمل.",
    ["WorkSheet Has Been Deleted Successfully."]: "تم حذف ورقة العمل بنجاح.",
    ["WorkSheet Has Not Been Deleted."]: "لم يتم حذف ورقة العمل.",
    ["Product Has Been Deleted Successfully."]: "تم حذف المنتج بنجاح.",
    ["Product Has Not Been Deleted."]: "لم يتم حذف المنتج.",
    ["Something Went Wrong."]: "لقد حدث خطأ ما.",
    ["Location Update Failed."]: "فشل تحديث الموقع.",
    ["Location Deleted Successfully."]: "تم حذف الموقع بنجاح.",
    ["Location Delete Failed."]: "فشل حذف الموقع.",
    ["Enter WorkSheet Name"]: "أدخل اسم ورقة العمل",
    ["Back"]: "خلف",
    ["Create Worksheet"]: "إنشاء ورقة عمل",
    ["WorkSheet Has Been Created Successfully."]: "تم إنشاء ورقة العمل بنجاح.",
    ["Worksheet Name Already Exists."]: "اسم ورقة العمل موجود بالفعل.",
    ["WorkSheet Has Not Been Created."]: "لم يتم إنشاء ورقة العمل.",
    ["Status Has Been Updated Successfully."]: "تم تحديث الحالة بنجاح.",
    ["Status Has Not Been Updated."]: "لم يتم تحديث الحالة.",
    ["Order Has Been Successfully ReOrder."]: "تم إعادة طلب الطلب بنجاح.",
    ["Order Has Not Been Cancelled."]: "لم يتم إلغاء الطلب.",
    ["PO Cancelled Successfully."]: "تم إلغاء طلب الشراء بنجاح.",
    ["PO Has Not Been Cancelled."]: "لم يتم إلغاء أمر الشراء.",
    ["Please enter message."]: "الرجاء إدخال الرسالة.",
    ["Record Deleted Successfully."]: "تم حذف السجل بنجاح.",
    ["Record Not Deleted."]: "لم يتم حذف السجل.",
    ["OK"]: "نعم",
    ["InProcess"]: "في طور التنفيذ",
    ["Price should be greater than 0"]: "السعر يجب أن يكون أكبر من 0",
    ["Kindly select all the necessary field from essential"]:
      "يرجى تحديد كافة الحقول الضرورية من الأساسيات",
    ["Please select Data File."]: "الرجاء تحديد ملف البيانات.",
    ["Please Select Given File Format."]: "الرجاء تحديد تنسيق الملف المحدد.",
    ["Match Your Variables to the"]: "قم بمطابقة المتغيرات الخاصة بك مع",
    ["Industry Standard."]: " معيار الصناعة.",
    ["Images Upload Option"]: "خيار تحميل الصور",
    ["Enter one character as Delimiter."]: "أدخل حرفًا واحدًا كمحدد.",
    ["Please Enter Column Name."]: "الرجاء إدخال اسم العمود.",
    ["Please select Format."]: "الرجاء تحديد التنسيق.",
    ["Data Added Successfully."]: "تمت إضافة البيانات بنجاح.",
    ["Data Not Added Successfully."]: "لم تتم إضافة البيانات بنجاح.",
    ["Mail Has Been Sent Successfully."]: "لقد تم إرسال البريد بنجاح.",
    ["Mail Has Not Been Sent"]: "لم يتم إرسال البريد",
    ["Fields should not be empty"]: "لا ينبغي أن تكون الحقول فارغة",
    ["Campaign Name Already Exists"]: "اسم الحملة موجود بالفعل",
    ["Please enter template name."]: "الرجاء إدخال اسم القالب.",
    ["Please enter template subject."]: "الرجاء إدخال موضوع القالب.",
    ["Please add some content."]: "يرجى إضافة بعض المحتوى.",
    ["Record Added Successfully."]: "تمت إضافة السجل بنجاح.",
    ["Record Not Added."]: "لم يتم إضافة السجل.",
    ["Group Name cannot not be empty."]: "لا يمكن أن يكون اسم المجموعة فارغًا.",
    ["Fields should not be empty"]: "لا ينبغي أن تكون الحقول فارغة",
    ["Are you sure you want to delete ?"]: "هل أنت متأكد أنك تريد الحذف؟",
    ["4055"]: "4055",
    ["316 Jewelry and Watch Boutique"]: "316 بوتيك المجوهرات والساعات",
    ["Full"]: "ممتلىء",
    ["Failed"]: "فشل",
    ["03-22-2021 07:06 PM"]: "03-22-2021 07:06 PM",
    ["Color Has Been Updated Successfully"]: "تم تحديث اللون بنجاح",
    ["Color Has Been Added Successfully"]: "تمت إضافة اللون بنجاح",
    ["Color Has Been Deleted Successfully."]: "تم حذف اللون بنجاح.",
    ["Color Has Not Been Deleted."]: "لم يتم حذف اللون.",
    ["Not Added."]: "لم تتم الاضافة.",
    ["Record Saved Successfully."]: "تم حفظ السجل بنجاح.",
    ["Record Not Saved."]: "لم يتم حفظ السجل.",
    ["The key already exists."]: "المفتاح موجود بالفعل.",
    ["Key is compulsory"]: "المفتاح إجباري",
    ["Value is compulsory"]: "القيمة إجبارية",
    ["Email template has been saved successfully."]:
      "تم حفظ قالب البريد الإلكتروني بنجاح.",
    ["Email template has not been updated."]:
      "لم يتم تحديث قالب البريد الإلكتروني.",
    ["Folder Has Been Already Created."]: "تم إنشاء المجلد بالفعل.",
    ["Folder Has Not Been Created."]: "لم يتم إنشاء المجلد.",
    ["Folder Removed Successfully."]: "تمت إزالة المجلد بنجاح.",
    ["Folder Not Removed."]: "لم تتم إزالة المجلد.",
    ["File Removed Successfully."]: "تمت إزالة الملف بنجاح.",
    ["File Not Removed."]: "لم تتم إزالة الملف.",
    ["Please select .csv|.xls|.txt|.json file only."]:
      "يرجى اختيار ملف بصيغة .csv|.xls|.txt|.json فقط.",
    ["File Uploaded Successfully."]: "تم رفع الملف بنجاح.",
    ["File Not Uploaded."]: "لم يتم رفع الملف.",
    ["Upload Your Media File"]: "قم برفع ملف الوسائط الخاص بك",
    ["Image Already Uploaded."]: "تم رفع الصورة بالفعل.",
    ["CSV File Already Uploaded."]: "تم رفع ملف CSV بالفعل.",
    ["XLS File Already Uploaded."]: "تم رفع ملف XLS بالفعل.",
    ["Text Already Uploaded."]: "تم رفع النص بالفعل.",
    ["Json File Already Uploaded."]: "تم رفع ملف JSON بالفعل.",
    ["Pdf File Already Uploaded."]: "تم رفع ملف PDF بالفعل.",
    ["xlsx File Already Uploaded."]: "تم رفع ملف XLSX بالفعل.",
    ["docx File Already Uploaded."]: "تم رفع ملف DOCX بالفعل.",
    ["flv File Already Uploaded."]: "تم رفع ملف FLV بالفعل.",
    ["jfif File Already Uploaded."]: "تم رفع ملف JFIF بالفعل.",
    ["Image Uploaded Successfully."]: "تم رفع الصورة بنجاح.",
    ["CSV File Uploaded Successfully."]: "تم رفع ملف CSV بنجاح.",
    ["XLS File Uploaded Successfully."]: "تم رفع ملف XLS بنجاح.",
    ["Text Uploaded Successfully."]: "تم رفع النص بنجاح.",
    ["Json File Uploaded Successfully."]: "تم رفع ملف JSON بنجاح.",
    ["Pdf File Uploaded Successfully."]: "تم رفع ملف PDF بنجاح.",
    ["xlsx File Uploaded Successfully."]: "تم رفع ملف XLSX بنجاح.",
    ["jfif File Uploaded Successfully."]: "تم رفع ملف JFIF بنجاح.",
    ["docx File Uploaded Successfully."]: "تم رفع ملف DOCX بنجاح.",
    ["flv File Uploaded Successfully."]: "تم رفع ملف FLV بنجاح.",
    ["Record Deleted Successfully"]: "تم حذف السجل بنجاح.",
    ["Record Not Deleted"]: "لم يتم حذف السجل.",
    ["Permission Not Updated."]: "لم يتم تحديث الإذن.",
    ["This folder currently has no files available. Please contact the vendor directly for more information."]:
      "هذا المجلد لا يحتوي على ملفات متوفرة حاليًا. يرجى التواصل مع البائع مباشرة لمزيد من المعلومات.",
    ["Kindly select the End Date greater than the Start Date"]:
      "يرجى اختيار تاريخ النهاية أكبر من تاريخ البداية.",
    ["Trade Show has been Deleted Successfully"]:
      "تم حذف المعرض التجاري بنجاح.",
    ["Trade Show has been Updated Successfully"]:
      "تم تحديث المعرض التجاري بنجاح.",
    ["Trade Show has been Created Successfully"]:
      "تم إنشاء المعرض التجاري بنجاح.",
    ["Trade Show has not been Updated Successfully"]:
      "لم يتم تحديث المعرض التجاري بنجاح.",
    ["Trade Show has not been  Created Successfully"]:
      "لم يتم إنشاء المعرض التجاري بنجاح.",
    ["Solar Client Added Successfully."]: "تم إضافة العميل الشمسي بنجاح.",
    ["Retailer ID Already Exists."]: "معرف البائع موجود بالفعل.",
    ["Invalid Retailer ID"]: "معرف البائع غير صالح",
    ["Solar Client Not Added."]: "لم تتم إضافة العميل الشمسي.",
    ["Select Core"]: "اختر النواة",
    ["RetailerCore 1"]: "نواةالبائع 1",
    ["RetailerCore 2"]: "نواةالبائع 2",
    ["RetailerCore 3"]: "نواةالبائع 3",
    ["RetailerCore 4"]: "نواةالبائع 4",
    ["RetailerCore 5"]: "نواةالبائع 5",
    ["RetailerCore 6"]: "نواةالبائع 6",
    ["RetailerCore 7"]: "نواةالبائع 7",
    ["RetailerCore 8"]: "نواةالبائع 8",
    ["RetailerCore 9"]: "نواةالبائع 9",
    ["RetailerCore 10"]: "نواةالبائع 10",
    ["RetailerCore 11"]: "نواةالبائع 11",
    ["RetailerCore 12"]: "نواةالبائع 12",
    ["RetailerCore 13"]: "نواةالبائع 13",
    ["Impersonating Data Failed"]: "فشل انتحال البيانات",
    ["Impersonating Header Menu Failed"]: "فشل انتحال قائمة الرأس",
    ["Password Has Been Reset Successfully"]:
      "تم إعادة تعيين كلمة المرور بنجاح.",
    ["Password Not Reset."]: "لم يتم إعادة تعيين كلمة المرور.",
    ["Password Must contain Minimum 6 characters, Maximum 15 characters, at least one uppercase letter, one number and one special character and should not contain blank spaces"]:
      "يجب أن تحتوي كلمة المرور على 6 أحرف كحد أدنى، 15 حرفًا كحد أقصى، حرف كبير واحد على الأقل، رقم واحد، ورمز خاص واحد، ولا يجب أن تحتوي على مسافات فارغة.",
    ["Designer Menu Preferences Submited Sucessfully."]:
      "تم إرسال تفضيلات قائمة المصمم بنجاح.",
    ["Designer Menu Preferences Not Submited."]:
      "لم يتم إرسال تفضيلات قائمة المصمم.",
    ["ML Retailer Locator"]: "محدد موقع البائع ML",
    ["Defualt Mapping Created Successfully."]:
      "تم إنشاء التعيين الافتراضي بنجاح.",
    ["Not Set Defualt Mapping."]: "لم يتم تعيين التعيين الافتراضي.",
    ["Are you Sure By Submit Existing Mapping replace with Default mapping. ?"]:
      "هل أنت متأكد من استبدال التعيين الحالي بالتعيين الافتراضي عند الإرسال؟",
    ["Client status Updated Successfully."]: "تم تحديث حالة العميل بنجاح.",
    ["Client status Not Updated."]: "لم يتم تحديث حالة العميل.",
    ["Date Changed Successfully."]: "تم تغيير التاريخ بنجاح.",
    ["Date Not Changed."]: "لم يتم تغيير التاريخ.",
    ["Request Approved."]: "تمت الموافقة على الطلب.",
    ["Request Not Approved"]: "لم تتم الموافقة على الطلب.",
    ["Request Rejected."]: "تم رفض الطلب.",
    ["RECORD ALREADY EXIST"]: "السجل موجود بالفعل.",
    ["Data Added Successfully."]: "تمت إضافة البيانات بنجاح.",
    ["Data Not Added."]: "لم تتم إضافة البيانات.",
    ["Data Updated Successfully."]: "تم تحديث البيانات بنجاح.",
    ["Data Not Updated."]: "لم يتم تحديث البيانات.",
    ["Data Deleted Successfully."]: "تم حذف البيانات بنجاح.",
    ["Data Not Deleted."]: "لم يتم حذف البيانات.",
    ["FTP Created Successfully."]: "تم إنشاء FTP بنجاح.",
    ["FTP Not Created."]: "لم يتم إنشاء FTP.",
    ["Diamond Vendor Has Been Added To All Diamond Links"]:
      "تمت إضافة المورد الماسي إلى جميع روابط الماس.",
    ["Diamond Vendor Has Not Been Added To All Diamond Links"]:
      "لم تتم إضافة المورد الماسي إلى جميع روابط الماس.",
    ["Please first select record to delete."]: "يرجى اختيار سجل للحذف أولاً.",
    ["Limit For This Retailer Is Already Configured."]:
      "تم تكوين الحد لهذا البائع بالفعل.",
    ["Designer Has Been updated Successfully."]: "تم تحديث المصمم بنجاح.",
    ["Selected Designer Has Been Updated Successfully."]:
      "تم تحديث المصمم المختار بنجاح.",
    ["Logo Has Been Added Successfully."]: "تمت إضافة الشعار بنجاح.",
    ["Select Designer"]: "اختر المصمم",
    ["Mapping Updated Successfully."]: "تم تحديث التعيين بنجاح.",
    ["Mapping Not Updated."]: "لم يتم تحديث التعيين.",
    ["Mapping Deleted Successfully."]: "تم حذف التعيين بنجاح.",
    ["Mapping Not Deleted."]: "لم يتم حذف التعيين.",
    ["Mapping Added Successfully."]: "تمت إضافة التعيين بنجاح.",
    ["Mapping Not Added."]: "لم تتم إضافة التعيين.",
    ["File Not Found"]: "الملف غير موجود.",
    ["DownlaodFile"]: "تنزيل ملف",
    ["XLM Created Successfully."]: "تم إنشاء XLM بنجاح.",
    ["XLM Not Created."]: "لم يتم إنشاء XLM.",
    ["RunSyncForce Carried Successfully"]: "تم تنفيذ RunSyncForce بنجاح.",
    ["RunSyncForce Carried Unsuccessfull"]: "لم يتم تنفيذ RunSyncForce بنجاح.",
    ["Discard Carried Successfully"]: "تم الإلغاء بنجاح.",
    ["Discard Carried Unsuccessfull"]: "لم يتم الإلغاء بنجاح.",
    ["MasterLinkTheme Has Been Updated Successfully."]:
      "تم تحديث MasterLinkTheme بنجاح.",
    ["MasterLinkTheme Not Updated."]: "لم يتم تحديث MasterLinkTheme.",
    ["Vendor Rating Updated Successfully."]: "تم تحديث تقييم المورد بنجاح.",
    ["Vendor Rating Not Updated ."]: "لم يتم تحديث تقييم المورد.",
    ["Column Added Successfully."]: "تمت إضافة العمود بنجاح.",
    ["Column Not Added."]: "لم تتم إضافة العمود.",
    ["Column name already exists."]: "اسم العمود موجود بالفعل.",
    ["Attribute Unmapped Successfully."]: "تم إلغاء تعيين السمة بنجاح.",
    ["Attribute Not Unmapped."]: "لم يتم إلغاء تعيين السمة.",
    ["Price Type Saved Sucessfully."]: "تم حفظ نوع السعر بنجاح.",
    ["Price Type Not Saved."]: "لم يتم حفظ نوع السعر.",
    ["Enter Valid Delimeter."]: "أدخل فاصلًا صحيحًا.",
    ["Enter Only One character as Delimiter."]: "أدخل حرفًا واحدًا فقط كفاصل.",
    ["Images Setting Not Save."]: "إعدادات الصور لم تُحفظ.",
    ["Images Settings saved sucessfully."]: "تم حفظ إعدادات الصور بنجاح.",
    ["Images Settings Not Save."]: "إعدادات الصور لم تُحفظ.",
    ["Attribute Mapped Successfully."]: "تم تعيين السمة بنجاح.",
    ["Attribute Not Mapped."]: "لم يتم تعيين السمة.",
    ["Column Updated Successfully."]: "تم تحديث العمود بنجاح.",
    ["Column Not Updated."]: "لم يتم تحديث العمود.",
    ["Attribute Value Saved Successfully."]: "تم حفظ قيمة السمة بنجاح.",
    ["Attribute Value Not Saved."]: "لم يتم حفظ قيمة السمة.",
    ["Data Mapping Has Been Reset Successfully."]:
      "تمت إعادة تعيين تعيين البيانات بنجاح.",
    ["Data Mapping Has Not Been Reset Successfully."]:
      "لم تتم إعادة تعيين تعيين البيانات بنجاح.",
    ["Field Type"]: "نوع الحقل",
    ["Are you sure , you want to delete this Collection?"]:
      "هل أنت متأكد أنك تريد حذف هذه المجموعة؟",
    ["Request Diamond Certificate"]: "طلب شهادة الألماس",
    ["First Name is Compulsory"]: "الاسم الأول إلزامي",
    ["Mail sent successfully."]: "تم إرسال البريد بنجاح.",
    ["Mail not sent."]: "لم يتم إرسال البريد.",
    ["Message not sent."]: "لم يتم إرسال الرسالة.",
    ["Your request has been sent to GemFind. A representative will be contacting you shortly."]:
      "تم إرسال طلبك إلى جيمفايند. سيتصل بك ممثل قريباً.",
    "Group Discount Details Updated successfully":
      "تم تحديث تفاصيل الخصم الجماعي بنجاح",
    "Cannot Update Group Discount Details":
      "لا يمكن تحديث تفاصيل الخصم الجماعي",
    "Sorry! we had an error": "عذراً! حدث خطأ",
    "Group Discount Details Deleted successfully":
      "تم حذف تفاصيل الخصم الجماعي بنجاح",
    "Cannot Delete Group Discount Details": "لا يمكن حذف تفاصيل الخصم الجماعي",
    "Email has not been Sent Successfully.":
      "لم يتم إرسال البريد الإلكتروني بنجاح.",
    "Jewelry Access Has Been Denied.": "تم رفض الوصول إلى المجوهرات.",
    "Jewelry Access Has Been Granted Successfully.":
      "تم منح الوصول إلى المجوهرات بنجاح.",
    "Jewelry Access Has Not Been Granted Successfully.":
      "لم يتم منح الوصول إلى المجوهرات بنجاح.",
    "Auto Sync Has Not Been Activated.": "لم يتم تفعيل المزامنة التلقائية.",
    "Auto Sync Deactivated Successfully.":
      "تم إلغاء تفعيل المزامنة التلقائية بنجاح.",
    "Auto Sync Activated successfully.": "تم تفعيل المزامنة التلقائية بنجاح.",
    "RingBuilder Access Has Not Been Granted Successfully.":
      "لم يتم منح الوصول إلى RingBuilder بنجاح.",
    "RingBuilder Access Has Been Denied.": "تم رفض الوصول إلى RingBuilder.",
    "RingBuilder Access Has Been Granted Successfully.":
      "تم منح الوصول إلى RingBuilder بنجاح.",
    "Enforce MSRP Has Not Activated.": "لم يتم تفعيل MSRP.",
    "Enforce MSRP Deactivated Successfully.": "تم إلغاء تفعيل MSRP بنجاح.",
    "Enforce MSRP Activated successfully.": "تم تفعيل MSRP بنجاح.",
    "Hide Price Has Not Been Activated.": "لم يتم تفعيل إخفاء السعر.",
    "Hide Price Deactivated Successfully.": "تم إلغاء تفعيل إخفاء السعر بنجاح.",
    "Hide Price Activated successfully.": "تم تفعيل إخفاء السعر بنجاح.",
    "Purchase Order Access Has Been Granted Successfully.":
      "تم منح الوصول إلى طلب الشراء بنجاح.",
    "Purchase Order Access Has Been Denied.": "تم رفض الوصول إلى طلب الشراء.",
    "Purchase Order Access Has Not Been Granted Successfully.":
      "لم يتم منح الوصول إلى طلب الشراء بنجاح.",
    "By checking this box and clicking the submit button below I hereby affirm that":
      "بالموافقة على هذه الخانة والنقر على زر الإرسال أدناه أؤكد أن",
    "has an open and active account with": "لديه حساب مفتوح ونشط مع",
    "that I am an authorized reseller of": "أنني موزع معتمد لـ",
    "and that I am permitted to have their virtual inventory on my website.":
      "وأنني مسموح لي بعرض مخزونهم الافتراضي على موقعي.",
    "I am interested in opening a new account with":
      "أنا مهتم بفتح حساب جديد مع",
    "and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to":
      "وأصبح موزعاً معتمداً. عند تحديد هذه الخانة والنقر على زر الإرسال أدناه، سيتم إرسال اسمك ومعلومات المتجر إلى",
    ["No Records Available"]: "لا توجد سجلات متاحة",
    ["This Worksheet does not have any product assigned to it."]: "لا تحتوي ورقة العمل هذه على أي منتج مخصص لها.",
    ["Please go to Catalog/Products to choose products for this worksheet."]: "يرجى الانتقال إلى الكتالوج/المنتجات لاختيار المنتجات لورقة العمل هذه.",
    ["Folder Has Been Successfully Add."]: "تمت إضافة المجلد بنجاح.",
    ["Application Permissions"]: "أذونات التطبيق",
    ["Template Name Already Exists"]: "اسم القالب موجود بالفعل"
  },
};
