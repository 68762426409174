import { httpApps } from "../helper/http-common";
class AppsDiamondLinksService {
  GetOptionsDetails(inputdata) {
    return httpApps.post("/DiamondLink/GetOptionsDetails", inputdata);
  }
  UpdateDiamondLinkOptionDetailsUpload(inputdata) {
    return httpApps.post(
      "/DiamondLink/UpdateDiamondLinkOptionDetailsUpload",
      inputdata
    );
  }
  GetLagacyDetails(inputdata) {
    return httpApps.post("/DiamondLink/GetLagacyDetails", inputdata);
  }
  UpdateDiamondLinkLagacyDetails(inputdata) {
    return httpApps.post(
      "/DiamondLink/UpdateDiamondLinkLagacyDetails",
      inputdata
    );
  }

  // Mined Diamond Markup and Lab grown markup

  GetMineMarkupLoadData(inputData, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/GetLabMarkupLoadData", inputData);
    } else {
      return httpApps.post("/DiamondLink/GetMineMarkupLoadData", inputData);
    }
  }

  GetMakerMineMarkupLoadData(inputData, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/GetMakerLabMarkupLoadData", inputData);
    } else {
      return httpApps.post(
        "/DiamondLink/GetMakerMineMarkupLoadData",
        inputData
      );
    }
  }

  GetCustomerMineMarkupLoadData(inputData, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/DiamondLink/GetCustomerLabMarkupLoadData",
        inputData
      );
    } else {
      return httpApps.post(
        "/DiamondLink/GetCustomerMineMarkupLoadData",
        inputData
      );
    }
  }
  RemoveMIneDealerMarkupdata(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/RemoveLabDealerMarkupdata", inputdata);
    } else {
      return httpApps.post(
        "/DiamondLink/RemoveMIneDealerMarkupdata",
        inputdata
      );
    }
  }

  SaveMIneDealerMarkupdata(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/SaveLabDealerMarkupdata", inputdata);
    } else {
      return httpApps.post("/DiamondLink/SaveMIneDealerMarkupdata", inputdata);
    }
  }
  SaveMIneCustomMarkupdata(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/SaveLabCustomMarkupdata", inputdata);
    } else {
      return httpApps.post("/DiamondLink/SaveMIneCustomMarkupdata", inputdata);
    }
  }
  RemoveMIneCustomMarkupdata(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/RemoveLabCustomMarkupdata", inputdata);
    } else {
      return httpApps.post(
        "/DiamondLink/RemoveMIneCustomMarkupdata",
        inputdata
      );
    }
  }
  GetAllCostPriceAndSize(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/GetAllLabCostPriceAndSize", inputdata);
    } else {
      return httpApps.post("/DiamondLink/GetAllCostPriceAndSize", inputdata);
    }
  }

  UpdateAllCostPriceAndSize(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post(
        "/DiamondLink/UpdateAllLabCostPriceAndSize",
        inputdata
      );
    } else {
      return httpApps.post("/DiamondLink/UpdateAllCostPriceAndSize", inputdata);
    }
  }
  UpdateMIneCustomMarkupdata(inputdata, type) {
    if (type === "LAB") {
      return httpApps.post("/DiamondLink/UpdatelabCustomMarkupdata", inputdata);
    } else {
      return httpApps.post(
        "/DiamondLink/UpdateMIneCustomMarkupdata",
        inputdata
      );
    }
  }
  UpdateDdlCurrency(inputdata, type) {
    return httpApps.post("/DiamondLink/UpdateDdlCurrency", inputdata);
  }

  //Intial filter Settings
  GetDiamondTypeList(inputData) {
    return httpApps.post("/DiamondLink/GetDiamondTypeList", inputData);
  }
  GetDiamondLinkInitialFilterSetting(inputData) {
    return httpApps.post(
      "/DiamondLink/GetDiamondLinkInitialFilterSetting",
      inputData
    );
  }
  SaveDiamondLinkInitialFilterSetting(inputData) {
    return httpApps.post(
      "/DiamondLink/SaveDiamondLinkInitialFilterSetting",
      inputData
    );
  }

  GetDiamondLinkApiEndIntegrationDetails(inputData) {
    return httpApps.post(
      "/DiamondLink/GetDiamondLinkApiEndIntegrationDetails",
      inputData
    );
  }
  // API integration and end point
  CreateApiKey(inputData) {
    return httpApps.post("/DiamondLink/CreateApiKey", inputData);
  }

  // Fancy Color Markup

  GetFancyMarkupLoadData(inputData) {
    return httpApps.post("/DiamondLink/GetFancyMarkupLoadData", inputData);
  }

  GetMakerFancyMarkupLoadData(inputData) {
    return httpApps.post("/DiamondLink/GetMakerFancyMarkupLoadData", inputData);
  }

  GetCustomerFancyMarkupLoadData(inputData) {
    return httpApps.post(
      "/DiamondLink/GetCustomerFancyMarkupLoadData",
      inputData
    );
  }

  SaveFancyDealerMarkupdata(inputData) {
    return httpApps.post("/DiamondLink/SaveFancyDealerMarkupdata", inputData);
  }
  SaveFancyCustomMarkupdata(inputData) {
    return httpApps.post("/DiamondLink/SaveFancyCustomMarkupdata", inputData);
  }
  RemoveFancyDealerMarkupdata(inputData) {
    return httpApps.post("/DiamondLink/RemoveFancyDealerMarkupdata", inputData);
  }
  RemoveFancyCustomMarkupdata(inputData) {
    return httpApps.post("/DiamondLink/RemoveFancyCustomMarkupdata", inputData);
  }
  GetAllFancyCostPriceAndSize(inputData) {
    return httpApps.post("/DiamondLink/GetAllFancyCostPriceAndSize", inputData);
  }
  UpdateAllFancyCostPriceAndSize(inputData) {
    return httpApps.post(
      "/DiamondLink/UpdateAllFancyCostPriceAndSize",
      inputData
    );
  }
  UpdateFancyCustomMarkupdata(inputData) {
    return httpApps.post("/DiamondLink/UpdateFancyCustomMarkupdata", inputData);
  }

  //View Enquiry
  GetviewInquiry(inputData) {
    return httpApps.post("/DiamondLink/viewInquiry", inputData);
  }

  //Dealers
  BindDealerList(inputData, type) {
    if (type == "Diamond Vendor") {
      return httpApps.post("/RingBuilder/ringbuilderBindDealerList", inputData);
    } else {
      return httpApps.post("/DiamondLink/BindDealerList", inputData);
    }
  }

  UpdateListClick(inputData, type) {
    if (type == "Diamond Vendor") {
      return httpApps.post(
        "/RingBuilder/UpdateList_Click_ringbuilder",
        inputData
      );
    } else {
      return httpApps.post("/DiamondLink/UpdateList_Click", inputData);
    }
  }

  ShowDataToRightNew(inputData) {
    return httpApps.post("/DiamondLink/ShowDataToRightNew", inputData);
  }
  //Available filter Settings
  GetDiamondLinkAvailableFilterSetting(inputData) {
    return httpApps.post(
      "/DiamondLink/GetDiamondLinkAvailableFilterSetting",
      inputData
    );
  }
  SaveDiamondLinkAvailableFilterSetting(inputData) {
    return httpApps.post(
      "/DiamondLink/SaveDiamondLinkAvailableFilterSetting",
      inputData
    );
  }
}

export default new AppsDiamondLinksService();
