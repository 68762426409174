import React, { useState, useEffect } from "react";
import { Select, Table, Button, Modal, Spin } from "antd";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";
import AppsStudBuilderService from "../../../services/apps-studbuilder.service";
import PricingGeneralService from "../../../services/catalog-pricing.service";
import AdminManageCurrencyService from "../../../services/admin-manageCurrency.service";
import { useSelector } from "react-redux";
import traslate from "../../../i18n/translate";
import { NotificationManager } from "react-notifications";
import { timeout } from "q";

const PricingGeneral = () => {
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [selectCurrencyDropdowenList, setSelectCurrencyDropdowenList] =
    useState([]);
  const { Option } = Select;
  const initialState = {
    Currency: null,
    Rounding: null,
    DefaultMarkup: null,
    MinPrice: null,
    MaxPrice: null,
    MarkUP: null,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [currencyList, setCurrencyList] = useState([]);
  const [RoundingList, setRoundingList] = useState([]);
  const [DealerId, setDealerId] = useState(null);
  const [MarkUpDataList, setMarkUpDataList] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [savePriceValid, setSavePriceValid] = useState(1);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [customMarkupId, setCustomMarkupId] = useState({
    custommarkupid: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [delRow, setDelRow] = useState({});
  const [editRow, setEditRow] = useState({});
  const [addValid, setAddValid] = useState(1);
  useEffect(() => {
    // handleGetCurrencyList();
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
      }
      handleGetSelectCurrencyDropdowen();
    }
  }, []);

  const handleCancelDeleteModal = () => {
    setDeleteModalVisible(false);
    setCustomMarkupId({
      custommarkupid: "",
    });
  };

  const onChange = (e, name) => {
    let tempState = state;
    if (name == "Currency" || name == "Rounding") {
      tempState[name] = e;
      tempState.Error[name] = "";
    } else {
      tempState[name] = e.target.value;
      tempState.Error[name] = "";
    }

    if (name === "DefaultMarkup") {
      tempState.Error["DefaultMarkup"] = "";
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(false);
      setIsAddDisabled(false);
    }

    var cv = Math.round(parseFloat(tempState.MaxPrice) * 100) / 100;
    var av = Math.round(parseFloat(tempState.MinPrice) * 100) / 100;
    if ((name = "MinPrice" || name == "MaxPrice")) {
      if (cv < av) {
        tempState.Error["MaxPrice"] = traslate(
          "MaxPrice Should be greater than MinPrice"
        );
        setAddValid(0);
        setIsAddDisabled(true);
      } else {
        tempState.Error["MaxPrice"] = "";
        setAddValid(1);
        setIsAddDisabled(false);
      }
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onKeyPressEvent = (event) => {
    if (event.code === "Minus") {
      event.preventDefault();
    }
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleAddMarkUpPrice = () => {
    if (!addValid == 1) {
      return false;
    }
    var isValid = handleValidateMarkup();
    if (!isValid) {
      return false;
    } else {
      handleSavePricingGeneralMarkup();
    }
  };
  const handleValidateMarkup = () => {
    var tempState = state;
    var isValid = true;
    // if (!tempState.MinPrice) {
    //   isValid = false;
    //   tempState.Error["MinPrice"] = traslate("Please Enter Min Price.");
    // } else if (!/^[0-9]*\.[0-9][0-9]$/.test(tempState.MinPrice)) {
    //   isValid = false;
    //   tempState.Error["MinPrice"] =
    //     "Please enter min price up to two decimal places";

    // } else {
    //   tempState.Error["MinPrice"] = "";
    // }

    if (!tempState.MaxPrice) {
      isValid = false;
      tempState.Error["MaxPrice"] = traslate("Please Enter Max Price.");
    } else if (!/^[0-9]*\.[0-9][0-9]$/.test(tempState.MaxPrice)) {
      isValid = false;
      tempState.Error["MaxPrice"] = traslate(
        "Please enter Max price up to two decimal places"
      );
    } else {
      tempState.Error["MaxPrice"] = "";
    }

    if (!tempState.MarkUP) {
      isValid = false;
      tempState.Error["MarkUP"] = traslate("Please Enter Markup.");
    } else if (tempState.MarkUP.includes("-")) {
      isValid = false;
      tempState.Error["MarkUP"] = traslate("Negative Values is not Accepted.");
    } else if (state.MarkUP > 100) {
      tempState.Error["MarkUP"] = traslate(
        "Please enter default markup value less then 100%."
      );
      isValid = false;
    } else {
      tempState.Error["MarkUP"] = "";
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    return isValid;
  };

  const handleValidation = () => {
    var isValid = true;
    if (!state.DefaultMarkup) {
      state.Error["DefaultMarkup"] = traslate("Please enter default markup.");
      isValid = false;
    }
    if (state.DefaultMarkup.includes("-")) {
      state.Error["DefaultMarkup"] = traslate(
        "Negative Values is not Accepted."
      );
      isValid = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };
  // const handleValidation = () => {
  //
  //   var tempState = state;
  //   var isValid = true;

  //   if (!tempState.Currency) {
  //     isValid = false;
  //     tempState.Error["Currency"] = "Please Enter Currency";
  //   } else {
  //     isValid = true;
  //     tempState.Error["Currency"] = "";
  //   }
  //   if (!tempState.Rounding) {
  //     isValid = false;
  //     tempState.Error["Rounding"] = "Please Enter Rounding";
  //   } else {
  //     isValid = true;
  //     tempState.Error["Rounding"] = "";
  //   }
  //   if (!tempState.DefaultMarkup) {
  //     isValid = false;
  //     tempState.Error["DefaultMarkup"] = "Please Enter Default Markup";
  //   } else {
  //     isValid = true;
  //     tempState.Error["DefaultMarkup"] = "";
  //   }

  //   setState((prevState) => ({
  //     ...prevState,
  //     ...tempState,
  //   }));
  //   return isValid;
  // };

  const handleGetCurrencyList = () => {
    try {
      setIsLoading(true);
      AppsRingBuilderService.GetCurrencyList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            //var data =JSON.parse(responseData);
            setCurrencyList(responseData);
            handleGetRoundingList();
          } else {
            setCurrencyList([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleGetRoundingList = () => {
    try {
      AppsRingBuilderService.GetRoundingList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRoundingList(responseData);
            handleGetPricingGeneralMarkupLoadData(
              loginDetials.responseData.dealerId
            );
          } else {
            setRoundingList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleDelete = (row) => {
  //   handleRemoveRingBuilderSettingMarkup(row.custommarkupid);
  // };

  const handleDeleteModal = (row) => {
    setDeleteModalVisible(true);
    setDelRow(row);
  };

  const columns = [
    {
      title: traslate("Markup="),
      dataIndex: "markup",
    },
    {
      title: traslate("Cost"),
      dataIndex: "cost",
    },
    {
      title: traslate("Retail"),
      dataIndex: "retail",
    },
    {
      title: traslate("Mrk"),
      dataIndex: "mrk",
    },
  ];

  const data = [
    {
      markup: <span>100%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>,
    },
    {
      markup: <span>120%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,200</span>,
      mrk: <span>(2.2)</span>,
    },
    {
      markup: <span>150%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,500</span>,
      mrk: <span>(2.5)</span>,
    },
    {
      markup: <span>80%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$1,800</span>,
      mrk: <span>(1.8)</span>,
    },
  ];
  const handleGetSelectCurrencyDropdowen = () => {
    setIsLoading(true);
    try {
      AdminManageCurrencyService.GetSelectCurrencyDropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSelectCurrencyDropdowenList(responseData);
              handleGetRoundingList();
            } else {
              setSelectCurrencyDropdowenList([]);
            }
          } else {
            setSelectCurrencyDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetPricingGeneralMarkupLoadData = async (id) => {
    let inputData = {
      dealerId: id + "",
      pageNumber: 1,
      pageSize: 10,
    };
    try {
      setIsLoading(true);
      await PricingGeneralService.SetDefaultMarkup(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var List = responseData.markUpValLst;
          setIsLoading(false);
          if (message === "Success") {
            state.Currency = parseInt(responseData.currency) || null;
            state.Rounding = parseInt(responseData.roundingoption) || null;
            state.DefaultMarkup = responseData.defaultMarkUp || null;
            if (List && List.length > 0) {
              var data = List.slice(-1);
              var MaxRangeVal = Number(data[0].maxRange);
              state.MinPrice = MaxRangeVal.toFixed(2);
              state.MaxPrice = Number("1000000").toFixed(2);
              state.MarkUP = "";
            } else {
              var num1 = 0;
              var num2 = 1000000;
              state.MinPrice = num1.toFixed(2);
              state.MaxPrice = num2.toFixed(2);
              state.MarkUP = null;
            }
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setMarkUpDataList(List);
            setTotalCount(responseData.totalRecord);
            setIsLoading(false);
          } else {
            state.Currency = null;
            state.Rounding = null;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setIsLoading(false);
            setMarkUpDataList([]);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          state.Currency = null;
          state.Rounding = null;
          setState((prevState) => ({
            ...prevState,
            ...state,
          }));
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSavePricingGeneralMarkup = () => {
    setIsBtnDisable(false);
    if (isEdit) {
      if (MarkUpDataList.length > 0) {
        const temp = MarkUpDataList.map((x) => {
          if (x.custommarkupid === editRow.custommarkupid) {
            return {
              ...x,
              minRange: Number(state.MinPrice).toFixed(2),
              maxRange: Number(state.MaxPrice).toFixed(2),
              markup: Number(state.MarkUP).toFixed(2),
            };
          } else return x;
        });
        setMarkUpDataList(temp);
      } else {
        var tempState = [];
        var custID = Date.now();
        let inputData = {
          custommarkupid: custID,
          dealerID: Number(DealerId),
          minrange: Number(state.MinPrice).toFixed(2),
          maxrange: Number(state.MaxPrice).toFixed(2),
          markup: Number(state.MarkUP).toFixed(2),
        };
        tempState.push(inputData);
        var arr1 = MarkUpDataList || [];
        var arr2 = tempState;
        var arr3 = [...arr1, ...arr2];
        setMarkUpDataList(arr3);
      }
    } else {
      var tempState = [];
      var custID = Date.now();

      let inputData = {
        custommarkupid: custID,
        dealerID: Number(DealerId),
        minrange: Number(state.MinPrice).toFixed(2),
        maxrange: Number(state.MaxPrice).toFixed(2),
        markup: Number(state.MarkUP).toFixed(2),
      };
      tempState.push(inputData);
      var arr1 = MarkUpDataList || [];
      var arr2 = tempState;
      var arr3 = [...arr1, ...arr2];
      setMarkUpDataList(arr3);
    }
    // var data = arr3[arr3.length - 1];
    // state.MinPrice = data.maxrange;
    // const num1 = 1000000;
    // state.MaxPrice = num1.toFixed(2);
    // state.MarkUP = "";
    var data = arr3[arr3.length - 1];
    var num = Number(data.maxrange);
    var newNum = num + 0.01;
    state.MinPrice = newNum.toFixed(2);
    const num1 = 1000000;
    state.MaxPrice = num1.toFixed(2);
    state.MarkUP = "";
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
    setIsEdit(false);
    setEditRow({});
  };

  const handleRemovePricingGeneralMarkup = (row) => {
    setIsBtnDisable(false);
    let tempArray = MarkUpDataList.filter(
      (x) => x.custommarkupid != row.custommarkupid
    );
    setMarkUpDataList(tempArray);
    setDeleteModalVisible(false);
    setIsEdit(false);
    setEditRow({});

    if (MarkUpDataList.length > 0) {
      var data = MarkUpDataList[MarkUpDataList.length - 1];
      state.MinPrice = data.maxrange.toFixed(2);
      var num1 = 1000000;
      state.MaxPrice = num1.toFixed(2);
      state.MarkUP = "";
    } else {
      var num2 = 0;
      var num1 = 1000000;
      state.MinPrice = num2.toFixed(2);
      state.MaxPrice = num1.toFixed(2);
      state.MarkUP = null;
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleUpdateAllPricingGeneralMarkup = () => {
    if (handleValidation()) {
      let inputData = {
        dealerId: DealerId,
        defaultMarkUp: state.DefaultMarkup,
        currency: state.Currency + "",
        roundingoption: state.Rounding + "",
        markupDataList: MarkUpDataList,
      };
      try {
        setIsBtnLoading(true);
        AppsStudBuilderService.UpdateAllStudBuilderSettingMarkup(inputData)
          .then((response) => {
            var message = response.data.message;
            setIsBtnLoading(false);
            if (message === "Success") {
              NotificationManager.success("Markup successfully updated.");
              handleGetPricingGeneralMarkupLoadData(DealerId);
              setIsBtnDisable(true);
              setState({
                ...state,
                MarkUP: "",
              });
            } else {
              NotificationManager.error("Default Markup Not Updated.");
              setIsBtnDisable(true);
            }
          })
          .catch((error) => {
            setIsBtnLoading(false);
            console.log(error);
          });
      } catch (error) {
        setIsBtnLoading(false);
        console.log(error);
      }
    }
  };

  const onKeyPressMinusEvent = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="markups__section">
        <div className="form__fields">
          <div className="col-md-12">
            <Spin spinning={isLoading}>
              <div className="row">
                {/* <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {traslate("Setting Markup")}{" "}
                  </h4>
                </div> */}
                <div className="col-lg-12 col-md-12">
                  <div className="content__div">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="col-lg-12">
                          <div className="head__div">
                            <h4> {traslate("General Settings")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            <div className="col-lg-12 pt-3">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="input__block">
                                    <label>
                                      {traslate("Currency")}
                                      {/* <Tooltip placement="right" title={style}>
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}{" "}
                                      <span>*</span>
                                    </label>
                                    <Select
                                      showSearch
                                      className="border__grey"
                                      placeholder="Select Currency "
                                      optionFilterProp="children"
                                      value={state.Currency}
                                      onChange={(e) => onChange(e, "Currency")}
                                    >
                                      <Option value="">
                                        {" "}
                                        {traslate("Select Currency")}{" "}
                                      </Option>
                                      {/* {currencyList.map((item, i) => {
                                      return (
                                        <Option value={item.currencyId}>
                                          {item.currencyText}
                                        </Option>
                                      );
                                    })} */}
                                      {selectCurrencyDropdowenList.map(
                                        (item) => {
                                          return (
                                            <React.Fragment>
                                              <Option value={item.currencyId}>
                                                {item.currencyType}
                                              </Option>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </Select>
                                    {state.Error ? (
                                      <div className="text-danger">
                                        {state.Error["Currency"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="input__block">
                                    <label>
                                      {traslate("Rounding")}
                                      {/* <Tooltip placement="right" title={style}>
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}{" "}
                                      <span>*</span>
                                    </label>
                                    <Select
                                      showSearch
                                      className="border__grey"
                                      placeholder="Select Rounding "
                                      optionFilterProp="children"
                                      value={state.Rounding}
                                      onChange={(e) => onChange(e, "Rounding")}
                                    >
                                      <Option value="">
                                        {" "}
                                        {traslate("Select Rounding")}{" "}
                                      </Option>
                                      {RoundingList.map((item, i) => {
                                        return (
                                          <Option value={item.roundingId}>
                                            {item.roundingText}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                    {state.Error ? (
                                      <div className="text-danger">
                                        {state.Error["Rounding"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="head__div">
                            <h4> {traslate("Default Markup")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            <div className="col-lg-12 pt-3">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="input__block">
                                    <label>
                                      {traslate("Default Markup")}
                                      {/* <Tooltip placement="right" title={style}>
                                      <img src={UnionIcon} alt="" />
                                    </Tooltip> */}{" "}
                                      <span>*</span>
                                    </label>
                                    <div className="mrkup__inptdiv">
                                      <input
                                        type="number"
                                        placeholder="Default Markup"
                                        className="dmrkup__inpt"
                                        value={state.DefaultMarkup}
                                        onChange={(e) =>
                                          onChange(e, "DefaultMarkup")
                                        }
                                        onKeyPress={onKeyPressMinusEvent}
                                      />
                                      <span className="ampcent__txt ampcent_symbol_right">
                                        %
                                      </span>
                                      {state.Error ? (
                                        <div className="text-danger">
                                          {state.Error["DefaultMarkup"]}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <Table
                                    columns={columns}
                                    dataSource={data}
                                    scroll={{ y: 800 }}
                                    pagination={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-lg-12">
                          <div className="head__div">
                            <h4> {traslate("Markup By Price Range")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            <div className="col-lg-12 pt-3">
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="input__block">
                                    <div className="mrkup__inptdiv">
                                      <span className="dollr__txt">$</span>
                                      <input
                                        type="text"
                                        // className={
                                        //   MarkUpDataList
                                        //     ? MarkUpDataList.filter(
                                        //         (x) => x.maxrange == 1000000.0
                                        //       ).length == 0
                                        //       ? "mrkup__rangeinpt"
                                        //       : "disabled mrkup__rangeinpt"
                                        //     : "mrkup__rangeinpt"
                                        // }
                                        className="disabled mrkup__rangeinpt"
                                        value={state.MinPrice}
                                        placeholder="Min Price"
                                        // onChange={(e) => onChange(e, "MinPrice")}

                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            10
                                          );
                                          onChange(e, "MinPrice");
                                        }}
                                        onKeyPress={onKeyPressEvent}
                                        disabled={true}
                                      />
                                    </div>
                                    {state.Error["MinPrice"] ? (
                                      <span className="error-color-red">
                                        {state.Error["MinPrice"]}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="input__block">
                                    <div className="mrkup__inptdiv">
                                      <span className="dollr__txt">$</span>
                                      <input
                                        type="text"
                                        className={
                                          MarkUpDataList
                                            ? MarkUpDataList.filter(
                                                (x) => x.maxrange == 1000000.0
                                              ).length == 0
                                              ? "mrkup__rangeinpt"
                                              : "disabled mrkup__rangeinpt"
                                            : "mrkup__rangeinpt"
                                        }
                                        value={state.MaxPrice}
                                        placeholder="Max Price"
                                        // onChange={(e) => onChange(e, "MaxPrice")}
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            10
                                          );
                                          onChange(e, "MaxPrice");
                                        }}
                                        disabled={
                                          MarkUpDataList
                                            ? MarkUpDataList.filter(
                                                (x) => x.maxrange == 1000000.0
                                              ).length == 0
                                              ? false
                                              : true
                                            : false
                                        }
                                        onKeyPress={onKeyPressEvent}
                                      />
                                    </div>
                                    {state.Error["MaxPrice"] ? (
                                      <span className="error-color-red">
                                        {state.Error["MaxPrice"]}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="input__block">
                                    <div className="mrkup__inptdiv">
                                      <input
                                        type="text"
                                        className={
                                          MarkUpDataList
                                            ? MarkUpDataList.filter(
                                                (x) => x.maxrange >= 1000000.0
                                              ).length == 0
                                              ? "dmrkup__inpt"
                                              : "disabled dmrkup__inpt"
                                            : "dmrkup__inpt"
                                        }
                                        value={
                                          state.MarkUP == null ||
                                          state.MarkUP == ""
                                            ? ""
                                            : state.MarkUP
                                        }
                                        placeholder="MarkUP"
                                        // onChange={(e) => onChange(e, "MarkUP")}
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            10
                                          );
                                          onChange(e, "MarkUP");
                                        }}
                                        onKeyPress={onKeyPressEvent}
                                        disabled={
                                          MarkUpDataList
                                            ? MarkUpDataList.filter(
                                                (x) => x.maxrange >= 1000000.0
                                              ).length == 0
                                              ? false
                                              : true
                                            : false
                                        }
                                      />
                                      <span className="ampcent__txt">%</span>
                                    </div>
                                    {state.Error["MarkUP"] ? (
                                      <span className="error-color-red">
                                        {state.Error["MarkUP"]}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <Button
                                    disabled={
                                      MarkUpDataList
                                        ? MarkUpDataList.filter(
                                            (x) => x.maxrange >= 1000000.0
                                          ).length == 0
                                          ? false
                                          : true
                                        : false
                                    }
                                    className="primary-btn"
                                    onClick={(e) => handleAddMarkUpPrice(e)}
                                  >
                                    {" "}
                                    {/* <img src={Create_icon} alt="" /> */}
                                    {traslate("Add")}
                                  </Button>
                                </div>
                                <div className="col-lg-12">
                                  <Table
                                    columns={[
                                      {
                                        title: traslate("Min Price"),
                                        dataIndex: "minRange",
                                        render: (row, item) => {
                                          return (
                                            <span> ${item.minRange} </span>
                                          );
                                        },
                                      },
                                      {
                                        title: traslate("Max Price"),
                                        dataIndex: "maxRange",
                                        render: (row, item) => {
                                          return (
                                            <span> ${item.maxRange} </span>
                                          );
                                        },
                                      },
                                      {
                                        title: traslate("Mark Up"),
                                        dataIndex: "markup",
                                        render: (row, item) => {
                                          return <span> {item.markup} % </span>;
                                        },
                                      },
                                      {
                                        title: traslate("Action"),
                                        render: (item, row) => {
                                          return (
                                            <div className="action__btns">
                                              <div className="image__block">
                                                <img
                                                  src={Delete_iconbl}
                                                  alt=""
                                                  onClick={(e) =>
                                                    // handleDelete(row)
                                                    handleDeleteModal(row)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        },
                                      },
                                    ]}
                                    dataSource={MarkUpDataList}
                                    // pagination={{
                                    //   pageSizeOptions: [10, 20, 50, 100],
                                    //   responsive: true,
                                    //   showSizeChanger: true,
                                    // }}

                                    onRow={(record, recordIndex) => ({
                                      onClick: (event) => {
                                        setIsEdit(true);
                                        setEditRow(record);
                                        setState({
                                          ...state,
                                          MinPrice: record.minrange,
                                          MaxPrice: record.maxrange,
                                          MarkUP: record.markup,
                                        });
                                      },
                                    })}
                                    onChange={(
                                      pagination,
                                      filters,
                                      sorters
                                    ) => {
                                      document
                                        .getElementsByClassName(
                                          "content__area"
                                        )[0]
                                        .scroll(0, 0);
                                    }}
                                    pagination={{
                                      showSizeChanger: true,
                                      responsive: true,
                                    }}
                                    scroll={{ y: 800 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <Button
                      className={
                        isBtnDisable ? "disabled-btn mt-3" : "primary-btn mt-3"
                      }
                      disabled={isBtnDisable}
                      loading={isBtnLoading}
                      onClick={() => handleUpdateAllPricingGeneralMarkup()}
                    >
                      {" "}
                      {traslate("Save Pricing")}{" "}
                    </Button>
                  </div>
                </div>
                <Modal
                  title={traslate("Delete")}
                  className="modalconsupld__section"
                  centered
                  visible={deleteModalVisible}
                  onOk={() => handleRemovePricingGeneralMarkup(delRow)}
                  onCancel={handleCancelDeleteModal}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleCancelDeleteModal}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => handleRemovePricingGeneralMarkup(delRow)}
                    >
                      {traslate("Delete")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 text-center">
                              <p>
                                {traslate("Are you sure you want to delete")}
                              </p>
                              <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PricingGeneral;
