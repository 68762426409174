import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Select, Switch, Tooltip, Spin } from "antd";
import { NotificationManager } from "react-notifications";

import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import PendantBuilderService from "../../../services/apps-pendantBuilder.service";

const { Option } = Select;

export const PendantBuilderOptions = (props) => {
  /*React Binding Starts*/
  const [ViewOptionData, setViewOptionData] = useState({
    pendantBuilderUrl: "",
    InternalUseLink: false,
    ShowAdvancedSearch: false,
    ScheduleViewing: true,
    ShowAddress: true,
    ShowCallForPrice: false,
    GSTTax: false,
    GSTTaxValue: "",
    PinterestShare: false,
    TwitterShare: false,
    FacebookShare: false,
    FacebookLike: false,
    ShoppingCartURL: "",
    ShowAddToCartBtn: false,
    ShowViewCart: false,
    showInstagramShare: false,
    isShowEcommerce: true,
  });
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect == "GSTTaxValue") {
      const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
      if (!rx_live.test(e.target.value)) {
        return false;
      }
    }
    setDisableBtn(false);

    if (isSelect === "select") {
      setViewOptionData({
        ...ViewOptionData,
        [name]: e,
      });
    } else {
      setViewOptionData({
        ...ViewOptionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  /*React Binding Ends*/

  /*API Binding Starts*/
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerId, setDealerId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetsPendantBuilderOptiondetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);
  const handleGetsPendantBuilderOptiondetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      PendantBuilderService.GetsPendantBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              if (response.data.responseData.length > 0) {
                setViewOptionData((prevState) => ({
                  ...prevState,
                  pendantBuilderUrl: responseData.pendantBuilderURL,
                  InternalUseLink: responseData.internalLinkPB,
                  ShowAdvancedSearch:
                    responseData.isDefaultAdvanceSearchPendant,
                  ScheduleViewing: responseData.showScheduleView,
                  ShowAddress: responseData.showAddresses,
                  ShowCallForPrice: responseData.callForPricependant,
                  GSTTax: responseData.showGstPrice,
                  GSTTaxValue: responseData.taxRate,
                  PinterestShare: responseData.showPinterestShare,
                  TwitterShare: responseData.showTwitterShare,
                  FacebookShare: responseData.showFacebookShare,
                  FacebookLike: responseData.showFacebookLike,
                  showInstagramShare: responseData.showInstagramShare,
                  ShowAddToCartBtn: responseData.showAddToCartButtonPendant,
                  ShowViewCart: responseData.showViewCartButtonPendant,
                  ShoppingCartURL: responseData.diamondpendentshoppingurl,
                  isShowEcommerce: responseData.isShowEcommerce,
                }));
              }
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      setLoading(true);
      let inputData = {
        dealerID: dealerId,
        type: "PendantBuilder",
        diamondpendentshoppingurl: ViewOptionData.ShoppingCartURL,
        callForPricependant: ViewOptionData.ShowCallForPrice,
        isDefaultAdvanceSearchPendant: ViewOptionData.ShowAdvancedSearch,
        showScheduleView: ViewOptionData.ScheduleViewing,
        showAddToCartButtonPendant: ViewOptionData.ShowAddToCartBtn,
        showViewCartButtonPendant: ViewOptionData.ShowViewCart,
        showAddresses: ViewOptionData.ShowAddress,
        pendantBuilderURL: ViewOptionData.pendantBuilderUrl,
        internalLinkPB: ViewOptionData.InternalUseLink,
        showGstPrice: ViewOptionData.GSTTax,
        taxRate: ViewOptionData.GSTTaxValue ? ViewOptionData.GSTTaxValue : "0",
        showPinterestShare: ViewOptionData.PinterestShare,
        showTwitterShare: ViewOptionData.TwitterShare,
        showFacebookShare: ViewOptionData.FacebookShare,
        showFacebookLike: ViewOptionData.FacebookLike,
        showGooglePlus: true,
        showInstagramShare: ViewOptionData.showInstagramShare,
      };
      setLoading(true);
      PendantBuilderService.UpdatePendantBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Updated successfully.");
            handleGetsPendantBuilderOptiondetails(dealerId);
          } else {
            NotificationManager.error("Data not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setDisableBtn(true);
  };

  /*API Binding Ends*/

  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-1">
              {" "}
              {traslate("Options")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="row ">
          <div className="col-md-12">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Setup")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Pendant Builder URL")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter the URL of this tool on your site to enable social sharing and dynamic sizing."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="pendantBuilderUrl"
                          className="mr-2"
                          value={
                            ViewOptionData.pendantBuilderUrl
                              ? ViewOptionData.pendantBuilderUrl
                              : null
                          }
                          onChange={(e) =>
                            handleOnChange(e, "pendantBuilderUrl")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Internal Use Link")} ?
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="InternalUseLink"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.InternalUseLink}
                          onChange={(e) =>
                            handleOnChange(e, "select", "InternalUseLink")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <label htmlFor="h1"> {traslate("Display")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Advanced Search As Default")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowAdvancedSearch"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowAdvancedSearch}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowAdvancedSearch")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Schedule Viewing")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the link which allows the customer the ability to schedule an appointment."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ScheduleViewing"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ScheduleViewing}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ScheduleViewing")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Addresses")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Allow address to show on detailpage."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowAddress"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowAddress}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowAddress")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Price")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show")} "{traslate("Call For Price")}"{" "}
                          {traslate("instead of the Price")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display “Call for Price” instead of the retail price or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowCallForPrice"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowCallForPrice}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowCallForPrice")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Apply GST Tax")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Toggle the ability to apply international tax."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="GSTTax"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.GSTTax}
                          onChange={(e) =>
                            handleOnChange(e, "select", "GSTTax")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("GST Tax Value")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Set the value when applying the GST Tax option."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          maxLength="2"
                          name="GSTTaxValue"
                          className="mr-2"
                          value={
                            ViewOptionData.GSTTaxValue
                              ? ViewOptionData.GSTTaxValue
                              : ViewOptionData.GSTTaxValue == "0"
                              ? "0"
                              : ""
                          }
                          onChange={(e) => handleOnChange(e, "GSTTaxValue")}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Social")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Pinterest Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Pinterest “Pin it” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="PinterestShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.PinterestShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "PinterestShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Twitter Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Twitter “Tweet” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="TwitterShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.TwitterShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "TwitterShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              `Choose whether to display the Facebook “Share” button or not.`
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="FacebookShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.FacebookShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "FacebookShare")
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Like")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              `Choose whether to display the Facebook “Like” button or not.`
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="FacebookLike"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.FacebookLike}
                          onChange={(e) =>
                            handleOnChange(e, "select", "FacebookLike")
                          }
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {traslate("Show Instagram Share")}
                          <Tooltip
                            placement="right"
                            title={
                              "Choose whether to display the Instagram “Share” button or not."
                            }
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showInstagramShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.showInstagramShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showInstagramShare")
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {ViewOptionData.isShowEcommerce &&
              ViewOptionData.isShowEcommerce == true ? (
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <div>
                      <label> {traslate("E-Commerce")} </label>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Shopping Cart Url")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="ShoppingCartURL"
                            className="mr-2"
                            value={
                              ViewOptionData.ShoppingCartURL
                                ? ViewOptionData.ShoppingCartURL
                                : null
                            }
                            onChange={(e) =>
                              handleOnChange(e, "ShoppingCartURL")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show Add To Cart Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose whether to display the “Add to Cart” button or not."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ShowAddToCartBtn"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.ShowAddToCartBtn}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ShowAddToCartBtn")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show")} {traslate("View Cart")}{" "}
                            {traslate("Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose whether to display the “View Cart” button or not."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ShowViewCart"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.ShowViewCart}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ShowViewCart")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
            </Accordion>
            <div className="col-lg-12 mt-4">
              <button
                className={disableBtn ? "disabled-btn" : "primary-btn"}
                disabled={disableBtn}
                loading={submitLoading}
                onClick={handleSubmit}
              >
                {" "}
                {traslate("Update Settings")}{" "}
              </button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendantBuilderOptions);
