import React, { useState, useEffect } from "react";
import traslate from "../../../i18n/translate";
import { Select, Button, Modal, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import catalogJewelryService from "../../../services/catalog-jewelry.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const MyVendors = (props) => {
  const initialstate = {
    companyName: "",
    city: "",
    state: "",
    country: "",
    contactName: "",
    email: "",
    phoneNumber: "",
  };
  const initialValidationstate = {
    companyName: "",
    city: "",
    state: "",
    country: "",
    contactName: "",
    email: "",
    phoneNumber: "",
  };

  const { Option } = Select;

  const [dealerId, setDealerId] = useState(0);
  const [venderdata, setVenderData] = useState(initialstate);
  const [validations, setValidations] = useState(initialValidationstate);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dealerCompany, setDealerCompany] = useState("");

  // For Company Name
  const [isCompanyName, setIsCompanyName] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isUserName, setIsUserName] = useState(true);

  const [locationDropdownListByCountry, setLocationDropdownListByCountry] =
    useState([]);
  const [locationDropdownListByState, setLocationDropdownListByState] =
    useState([]);
  const [locationDropdownListByCity, setLocationDropdownListByCity] = useState(
    []
  );

  // Modal Email
  const [emailLoading, setEmailLoading] = useState(false);
  const [content, setContent] = useState("");
  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setDealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
        }
      }
    }
    handleGetLocationDropdownDetailsByCountry();
    handleGetLocationDropdownDetailsByState(233, "State");
  }, []);

  const handleCountryStateValue = () => {
    handleGetLocationDropdownDetailsByCountry();
    handleGetLocationDropdownDetailsByState(233, "State");
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setVenderData({
        ...venderdata,
        [name]: e,
      });
      if (name === "country") {
        setValidations((prevdata) => ({
          ...prevdata,
          countryVal: "",
        }));
        setVenderData((prevdata) => ({
          ...prevdata,
          state: "",
        }));

        handleGetLocationDropdownDetailsByState(e, "State");
      }
      if (name === "state") {
        setValidations((prevdata) => ({
          ...prevdata,
          stateVal: "",
        }));
        setVenderData((prevdata) => ({
          ...prevdata,
          city: "",
        }));
        handleGetLocationDropdownDetailsByCity(e, "City");
      }
      if (name === "city") {
        setValidations((prevdata) => ({
          ...prevdata,
          cityVal: "",
        }));
      }
    } else {
      setVenderData({
        ...venderdata,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "companyName") {
        setValidations((prevdata) => ({
          ...prevdata,
          companyNameVal: "",
        }));
      }
      if (e.target.name === "city") {
        setValidations((prevdata) => ({
          ...prevdata,
          cityVal: "",
        }));
      }
      if (e.target.name === "state") {
        setValidations((prevdata) => ({
          ...prevdata,
          stateVal: "",
        }));
      }
      if (e.target.name === "country") {
        setValidations((prevdata) => ({
          ...prevdata,
          countryVal: "",
        }));
      }
      if (e.target.name === "contactName") {
        setValidations((prevdata) => ({
          ...prevdata,
          contactNameVal: "",
        }));
      }
      if (e.target.name === "email") {
        setValidations((prevdata) => ({
          ...prevdata,
          emailVal: "",
        }));
      }
      if (e.target.name === "phoneNumber") {
        setValidations((prevdata) => ({
          ...prevdata,
          phoneNumberVal: "",
        }));
      }
    }
  };

  // Validation
  const handleValidation = () => {
    const {
      companyName,
      city,
      state,
      country,
      contactName,
      email,
      phoneNumber,
    } = venderdata;

    const validations = {
      companyNameVal: "",
      cityVal: "",
      stateVal: "",
      countryVal: "",
      contactNameVal: "",
      emailVal: "",
      phoneNumberVal: "",
    };
    let isValid = true;

    if (!companyName.trim()) {
      isValid = false;
      validations.companyNameVal = traslate("Company name is compulsory");
    } else {
      validations.companyNameVal = "";
    }

    if (locationDropdownListByState.length > 0) {
      if (!state) {
        isValid = false;
        validations.stateVal = traslate("State is compulsory");
      } else {
        validations.stateVal = "";
      }
    }

    if (locationDropdownListByCity.length > 0) {
      if (!city) {
        isValid = false;
        validations.cityVal = traslate("City is compulsory");
      } else {
        validations.cityVal = "";
      }
    }

    if (!country) {
      isValid = false;
      validations.countryVal = traslate("Kindly select the Country");
    } else {
      validations.countryVal = "";
    }
    if (!contactName.trim()) {
      isValid = false;
      validations.contactNameVal = traslate("Contact name is compulsory");
    } else {
      validations.contactNameVal = "";
    }
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validEmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.trim()) {
      isValid = false;
      validations.emailVal = traslate("Email address is compulsory");
    } else if (!email.match(validEmailRegex)) {
      isValid = false;
      validations.emailVal = traslate("Email address is invalid");
    } else {
      if (!email.match(validRegex)) {
        isValid = false;
        validations.emailVal = traslate("Email address is invalid");
      } else {
        validations.emailVal = "";
      }
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is compulsory");
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^[ ()+]*([0-9][ ()+]*){10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is invalid");
    } else {
      validations.phoneNumberVal = "";
    }

    setValidations(validations);
    return isValid;
  };

  const { companyName, city, state, country, contactName, email, phoneNumber } =
    venderdata;
  const {
    companyNameVal,
    cityVal,
    stateVal,
    countryVal,
    contactNameVal,
    emailVal,
    phoneNumberVal,
  } = validations;

  const handleGetLocationDropdownDetailsByCountry = () => {
    let inputData = {
      id: 0,
      dropdownType: "Country",
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setLocationDropdownListByCountry(responseData);
            setVenderData((prevState) => ({
              ...prevState,
              country: 233,
            }));
          } else {
            setLocationDropdownListByCountry([]);
          }
        } else {
          setLocationDropdownListByCountry([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetLocationDropdownDetailsByState = (id, dropdownType) => {
    let inputData = {
      id: id,
      dropdownType: dropdownType,
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDropdownListByState(responseData);
        } else {
          setLocationDropdownListByState([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetLocationDropdownDetailsByCity = (id, dropdownType) => {
    let inputData = {
      id: id,
      dropdownType: dropdownType,
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDropdownListByCity(responseData);
        } else {
          setLocationDropdownListByCity([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckDealerCompanyName = () => {
    if (!venderdata.companyName) {
      return false;
    }
    let inputData = {
      companyName: venderdata.companyName,
    };
    AdminToolsDataService.CheckDealerCompanyName(inputData)
      .then((response) => {
        
        let checkMessage = response.data.responseData.isCompanyExist;

        setValidations((prevState) => ({
          ...prevState,
          companyNameVal: checkMessage ? "Company name is Taken" : "",
        }));
        setIsCompanyName(checkMessage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckDealerEmail = async () => {
    if (!venderdata.email) {
      return false;
    }
    let inputData = {
      email: venderdata.email,
    };

    let isValid = true;
    await AdminToolsDataService.CheckDealerEmail(inputData)
      .then((response) => {
        
        let checkMessage = response.data.responseData.isEmailExist;
        setValidations((prevState) => ({
          ...prevState,
          emailVal: checkMessage ? "Email is Taken" : "",
        }));
        setIsEmail(checkMessage);
      })
      .catch((error) => {
        console.log(error);
      });

    return isValid;
  };

  const handleCheckDealerUser = () => {
    if (!venderdata.contactName) {
      return false;
    }
    let inputData = {
      userName: venderdata.contactName,
    };

    let isValid = true;
    AdminToolsDataService.CheckDealerUser(inputData)
      .then((response) => {
        let checkMessage = response.data.responseData.isUserNameExist;
        isValid = checkMessage;
        setValidations((prevState) => ({
          ...prevState,
          contactNameVal: checkMessage && "Username is Taken",
        }));
        setIsUserName(checkMessage);
      })
      .catch((error) => {
        console.log(error);
      });
    return isValid;
  };

  // Invite vendor
  const handleInviteVendor = async () => {
    let isValid = handleValidation();
    setIsEmail(true);
    setIsCompanyName(true);
    setIsUserName(true);
    if (!isValid) {
      return false;
    } else {
      await handleCheckDealerCompanyName();
      await handleCheckDealerEmail();
      await handleCheckDealerUser();
      if (!isCompanyName && !isEmail && !isUserName) {
        let inputData = {
          dealerId: dealerId,
          companyName: venderdata.companyName.toString(),
          cityId: venderdata.city,
          stateId: venderdata.state,
          countryId: venderdata.country,
          contactName: venderdata.contactName.toString(),
          emailAddress: venderdata.email.toString(),
          phoneNumber: venderdata.phoneNumber.toString(),
          type: "Vendor",
        };
        setSubmitLoading(true);
        AdminToolsDataService.CreateInviteVendorRetailer(inputData)
          .then((response) => {
            let message = response.data.message;
            let responseData = response.data.responseData;
            let returnMessage = responseData.returnMessage;
            if (message === "Success") {
              if (returnMessage == "Invite Added SUCESSFULLY") {
                setVenderData(initialstate);
                handleCountryStateValue();
                NotificationManager.success("Invitation Sent Successfully.");
              } else {
                setVenderData(initialstate);
                handleCountryStateValue();
                NotificationManager.error("Invite Not Sent.");
              }
            } else {
              setVenderData(initialstate);
              handleCountryStateValue();
              NotificationManager.error("Invite Not Added.");
            }
          })
          .catch((error) => {
            console.log(error);
            setSubmitLoading(false);
            setIsModalSendInvitationVisible(false);
          })
          .finally(() => {
            setSubmitLoading(false);
            setIsModalSendInvitationVisible(false);
          });
      }
    }
  };

  const handleSendInvitationModalCancel = () => {
    setIsModalSendInvitationVisible(false);
  };

  const handleSendEmail = () => {
    handleInviteVendor();
  };

  const handleGetEmailContent = () => {
    let isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    setIsModalSendInvitationVisible(true);
    handleGetEmailData();
  };
  const handleGetEmailData = () => {
    let inputData = {
      dealerID: dealerId.toString(),
      type: "InviteNewVendor",
      fromDealerComapny: venderdata.companyName.toString(),
      toDealerCompany: dealerCompany.toString(),
    };
    setEmailLoading(true);
    catalogJewelryService
      .GetInviteNewVendorEmail(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData.emailcontent;
        if (message === "Success") {
          setContent(responseData);
        } else {
          setContent([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setEmailLoading(false);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  return (
    <div className="form__fields">
      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("Invite Your Vendors")}{" "}
            </h4>
          </div>
        </div>
        <div className="col-lg-12 mt-4">
          <div className="row">
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                  {traslate("Company Name")}
                  <span>*</span>
                </label>
                <input
                  type="textbox"
                  name="companyName"
                  value={companyName}
                  placeholder="Enter Company Name"
                  onChange={handleOnChange}
                  className={companyNameVal && "border__red"}
                  onBlur={handleCheckDealerCompanyName}
                  autoComplete="off"
                />
                <div>
                  {companyNameVal && (
                    <p className="error-color-red">{companyNameVal}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="input__block">
                <label>
                  {traslate("Country")}
                  <span>*</span>
                </label>
                <Select
                  className="border__grey"
                  placeholder="Select Country"
                  value={country}
                  name="country"
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "country")}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option>Select Country</Option>
                  {locationDropdownListByCountry.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                <div>
                  {countryVal && (
                    <p className="error-color-red">{countryVal}</p>
                  )}
                </div>
              </div>
            </div>
            {country && (
              <div className="col-lg-4">
                <div className="input__block">
                  <label>
                    {traslate("State")}
                    <span>*</span>
                  </label>
                  <Select
                    showSearch
                    name="state"
                    placeholder="Select State"
                    value={state ? state : null}
                    autocomplete="off"
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "state")}
                    className={stateVal ? "border__red" : "border__grey"}
                  >
                    {locationDropdownListByState.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                  <div>
                    {stateVal && <p className="error-color-red">{stateVal}</p>}
                  </div>
                </div>
              </div>
            )}

            {state && (
              <div className="col-lg-4">
                <div className="input__block">
                  <label>
                    {traslate("City")}
                    <span>*</span>
                  </label>
                  <Select
                    showSearch
                    name="city"
                    placeholder="Select City"
                    value={city ? city : null}
                    autocomplete="off"
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "city")}
                    className={cityVal ? "border__red" : "border__grey"}
                  >
                    {locationDropdownListByCity.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                  <div>
                    {cityVal && <p className="error-color-red">{cityVal}</p>}
                  </div>
                </div>
              </div>
            )}

            <div className="col-lg-4">
              <div className="input__block">
                <label>
                  {traslate("Contact Name")}
                  <span>*</span>
                </label>
                <input
                  type="textbox"
                  name="contactName"
                  value={contactName}
                  placeholder="Enter Contact Name"
                  onChange={handleOnChange}
                  className={contactNameVal && "border__red"}
                  onBlur={handleCheckDealerUser}
                  autoComplete="off"
                />
                <div>
                  {contactNameVal && (
                    <p className="error-color-red">{contactNameVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                  {traslate("E-Mail Address")}
                  <span>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter E-Mail Address"
                  onChange={handleOnChange}
                  className={emailVal && "border__red"}
                  onBlur={handleCheckDealerEmail}
                  autoComplete="off"
                />
                <div>
                  {emailVal && <p className="error-color-red">{emailVal}</p>}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                  {traslate("Phone Number")}
                  <span>*</span>
                </label>
                <input
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="Enter Phone Number"
                  onChange={handleOnChange}
                  className={phoneNumberVal && "border__red"}
                  autoComplete="off"
                />
                <div>
                  {phoneNumberVal && (
                    <p className="error-color-red">{phoneNumberVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <Button onClick={handleGetEmailContent} className="primary-btn">
                {" "}
                {traslate("Send Invitation")}{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Vendor Email Content */}
      <Modal
        title={traslate("Send Email")}
        visible={isModalSendInvitationVisible}
        className="forgot__password"
        onCancel={handleSendInvitationModalCancel}
        footer={[
          <div className="d-block">
            <Button
              loading={submitLoading}
              className="primary-btn"
              key="submit"
              onClick={handleSendEmail}
            >
              {traslate("Send")}
            </Button>
          </div>,
        ]}
      >
        <div className="form__fields p-0 border-0 mh-auto">
          <div className="editor__section">
            <Spin spinning={emailLoading}>
              <CKEditor
                editor={ClassicEditor}
                data={content ? content : ""}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </Spin>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyVendors;
