import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
  Spin
} from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../i18n/translate";
import catalogGroupDiscountService from "../../services/catalog-group-discount.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const { Option } = Select;

const DiamondGroupDiscount = () => {
  const [visible, setVisible] = useState(false);
  const [
    diamondGroupDiscountDetails,
    setDiamondGroupDiscountDetails
  ] = useState([]);
  const [dealerId, setDealerId] = useState();
  const [
    isDiamondGroupDiscoundDetailsLoading,
    setIsDiamondGroupDiscoundDetailsLoading
  ] = useState(false);

  const [
    selectedGroupDiscountDetails,
    setSelectedGroupDiscountDetails
  ] = useState({});

  const [
    selectedGroupDiscountDetailsValidation,
    setSelectedGroupDiscountDetailsValidation
  ] = useState({
    groupNameval: "",
    discountValueval: ""
  });

  const [isSaveAndUpdateLoading, setIsSaveAndUpdateLoading] = useState(false);
  const [modalType, setModalType] = useState("");

  const loginDetials = useSelector(state => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData) {
          if (loginDetials.loginDetials.responseData.dealerId) {
            setDealerId(loginDetials.loginDetials.responseData.dealerId);
            handleGetDiamondGroupDiscountDetails(
              loginDetials.loginDetials.responseData.dealerId
            );
          }
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    setSelectedGroupDiscountDetails({});
    setSelectedGroupDiscountDetailsValidation({
      groupNameval: "",
      discountValueval: ""
    });
  };

  const handleOk = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: traslate("Group Discount Name"),
      dataIndex: "groupName"
    },
    {
      title: traslate("Discount Value"),
      dataIndex: "discountValue",
      render: (item, row) => {
        return <p>{row.discountValue}%</p>;
      }
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Edit_icon}
                alt=""
                onClick={() => handleOpenEditOrAddModal(row, "Edit")}
              />
            </div>
            <div className="image__block">
              <img
                src={Delete_iconbl}
                alt=""
                onClick={() => handleDeleteDiamondGroupDiscount(row.id)}
              />
            </div>
          </div>
        );
      },
      width: 100
    }
  ];

  const data = [
    {
      key: "1",
      GroupDiscountName: <span>Tier3</span>,
      DiscountValue: <span>-3%</span>
    },
    {
      key: "1",
      GroupDiscountName: <span>Tier3</span>,
      DiscountValue: <span>-3%</span>
    },
    {
      key: "1",
      GroupDiscountName: <span>Tier3</span>,
      DiscountValue: <span>-3%</span>
    },
    {
      key: "1",
      GroupDiscountName: <span>Tier3</span>,
      DiscountValue: <span>-3%</span>
    }
  ];

  const handleGetDiamondGroupDiscountDetails = async id => {
    let inputData = {
      dealerID: id.toString()
    };
    setIsDiamondGroupDiscoundDetailsLoading(true);
    try {
      await catalogGroupDiscountService
        .GetDiamondGroupDiscountDetails(inputData)
        .then(response => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            setDiamondGroupDiscountDetails(responseData);
            setIsDiamondGroupDiscoundDetailsLoading(false);
          } else {
            setDiamondGroupDiscountDetails([]);
            setIsDiamondGroupDiscoundDetailsLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setIsDiamondGroupDiscoundDetailsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsDiamondGroupDiscoundDetailsLoading(false);
    }
  };

  const handleCancel1 = () => {
    setVisible(true);
  };

  const handleSaveAndUpdateGroupDiscountDetails = async () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    }

    let inputData = {
      id: modalType == "Edit" ? selectedGroupDiscountDetails.id : 0,
      groupName: selectedGroupDiscountDetails.groupName,
      discountValue: selectedGroupDiscountDetails.discountValue,
      dealerId: dealerId.toString()
    };

    setIsSaveAndUpdateLoading(true);

    try {
      await catalogGroupDiscountService
        .SaveAndUpdateDiamondGroupDiscount(inputData)
        .then(response => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(traslate(
              "Group Discount Details Updated successfully"
            ));
            handleGetDiamondGroupDiscountDetails(dealerId);
            setVisible(false);
            setIsSaveAndUpdateLoading(false);
            setSelectedGroupDiscountDetails({});
            setSelectedGroupDiscountDetailsValidation({
              groupNameval: "",
              discountValueval: ""
            });
          } else {
            NotificationManager.error(traslate("Cannot Update Group Discount Details"));
            setVisible(false);
            setIsSaveAndUpdateLoading(false);
            setSelectedGroupDiscountDetails({});
            setSelectedGroupDiscountDetailsValidation({
              groupNameval: "",
              discountValueval: ""
            });
          }
        })
        .catch(error => {
          console.log(error);
          NotificationManager.error(traslate("Sorry! we had an error"));
          setVisible(false);
          setIsSaveAndUpdateLoading(false);
          setSelectedGroupDiscountDetails({});
          setSelectedGroupDiscountDetailsValidation({
            groupNameval: "",
            discountValueval: ""
          });
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error(traslate("Sorry! we had an error"));
      setVisible(false);
      setIsSaveAndUpdateLoading(false);
      setSelectedGroupDiscountDetails({});
      setSelectedGroupDiscountDetailsValidation({
        groupNameval: "",
        discountValueval: ""
      });
    }
  };

  const handleOpenEditOrAddModal = (row, addOrEdit) => {
    if (addOrEdit == "Edit") {
      setSelectedGroupDiscountDetails(row);
    } else {
      setSelectedGroupDiscountDetails({
        groupName: "",
        discountValue: ""
      });
    }
    setModalType(addOrEdit);
    setVisible(true);
  };

  const onKeyPressMinusEvent = e => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/).test(keyValue))
      e.preventDefault();
    return;
  };

  const handleInputChange = e => {
    if (e.target.name == "discountValue") {
      e.target.value = e.target.value.replace(/-$/, "");
    }
    setSelectedGroupDiscountDetails({
      ...selectedGroupDiscountDetails,
      [e.target.name]: e.target.value
    });
    if (e.target.name == "groupName") {
      setSelectedGroupDiscountDetailsValidation(prevdata => ({
        ...prevdata,
        groupNameval: ""
      }));
    }
    if (e.target.name == "discountValue") {
      setSelectedGroupDiscountDetailsValidation(prevdata => ({
        ...prevdata,
        discountValueval: ""
      }));
    }
  };

  const handleValidation = () => {
    const { groupName, discountValue } = selectedGroupDiscountDetails;

    const selectedGroupDiscountDetailsValidation = {
      groupNameval: "",
      discountValueval: ""
    };
    let isValid = true;

    if (!groupName.trim()) {
      isValid = false;
      selectedGroupDiscountDetailsValidation.groupNameval =
        traslate("Enter Discount Name");
    } else {
      selectedGroupDiscountDetailsValidation.groupNameval = "";
    }

    if (!discountValue.trim()) {
      isValid = false;
      selectedGroupDiscountDetailsValidation.discountValueval =
        traslate("Enter Discount Value");
    } else {
      selectedGroupDiscountDetailsValidation.discountValueval = "";
    }

    setSelectedGroupDiscountDetailsValidation(
      selectedGroupDiscountDetailsValidation
    );
    return isValid;
  };

  const handleDeleteDiamondGroupDiscount = async id => {
    let inputData = {
      id
    };

    setIsDiamondGroupDiscoundDetailsLoading(true);

    try {
      await catalogGroupDiscountService
        .DeleteDiamondGroupDiscount(inputData)
        .then(response => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(
              traslate("Group Discount Details Deleted successfully")
            );
            handleGetDiamondGroupDiscountDetails(dealerId);

            setIsDiamondGroupDiscoundDetailsLoading(false);
          } else {
            NotificationManager.error(traslate("Cannot Delete Group Discount Details"));

            setIsDiamondGroupDiscoundDetailsLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          NotificationManager.error(traslate("Sorry! we had an error"));

          setIsDiamondGroupDiscoundDetailsLoading(false);
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error(traslate("Sorry! we had an error"));

      setIsDiamondGroupDiscoundDetailsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Diamond Group Discount")}
              </h4>
              <button
                className="primary-btn"
                onClick={() => handleOpenEditOrAddModal(null, "Add")}
              >
                <img src={Create_icon} />
                {traslate("Add")}
              </button>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="button__block my-4">
              <button className="primary-btn">Download</button>
            </div>
          </div> */}

          <Spin spinning={isDiamondGroupDiscoundDetailsLoading}>
            <div className="col-lg-12 col-md-12">
              <Table
                className="pendjewelreq__tbl"
                columns={columns}
                dataSource={diamondGroupDiscountDetails}
                scroll={{ x: 600, y: 800 }}
                pagination={{
                  pageSizeOptions: [10, 20, 50, 100],
                  responsive: true,
                  showSizeChanger: true,
                }}
              />
            </div>
          </Spin>
        </div>
      </div>
      <Modal
        title={`${modalType} Retailer`}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSaveAndUpdateGroupDiscountDetails}
            loading={isSaveAndUpdateLoading}
          >
            {traslate("Submit")}
          </Button>
        ]}
        width={400}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      {traslate("Group Discount Name")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      name="groupName"
                      type="text"
                      className={
                        selectedGroupDiscountDetailsValidation.groupNameval &&
                        "border__red"
                      }
                      value={selectedGroupDiscountDetails.groupName}
                      onChange={handleInputChange}
                    />
                    {selectedGroupDiscountDetailsValidation.groupNameval && (
                      <p className="error-color-red">
                        {selectedGroupDiscountDetailsValidation.groupNameval}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      {traslate("Discount Value")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      name="discountValue"
                      type="text"
                      className={
                        selectedGroupDiscountDetailsValidation.discountValueval &&
                        "border__red"
                      }
                      onKeyPress={onKeyPressMinusEvent}
                      value={selectedGroupDiscountDetails.discountValue}
                      onChange={handleInputChange}
                    // onPaste={preventPasteNegative}
                    />
                    {selectedGroupDiscountDetailsValidation.discountValueval && (
                      <p className="error-color-red">
                        {
                          selectedGroupDiscountDetailsValidation.discountValueval
                        }
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DiamondGroupDiscount;
