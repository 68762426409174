import React, { useState, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import MultiRangeSlider from "../../contacts/MultiRangeSlider";

import roundDiamond from "../../../assets/images/Round.jpg";
import princessDiamond from "../../../assets/images/princess.png";
import colorDiamond from "../../../assets/images/yellow-diamond.jpg";

import yourDiamond from "../../../assets/images/your_diamonds.png";
import yourEarrings from "../../../assets/images/your_earrrings.png";
import yourSetting from "../../../assets/images/your_setting.png";
import yourPendant from "../../../assets/images/your_pendant.png";
import deleteIcon from "../../../assets/images/delete_iconbl.png";
import diamondDetailsImg from "../../../assets/images/download.jfif";

import { Accordion } from "react-bootstrap";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const PendantBuilderApplicationPreview = () => {
  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      width: 50,
    },
    {
      title: traslate("Shape"),
      dataIndex: "shape",
    },
    {
      title: traslate("Carats"),
      dataIndex: "carat",
      width: 100,
    },
    {
      title: traslate("Color"),
      dataIndex: "color",
      width: 100,
    },
    {
      title: traslate("Clarity"),
      dataIndex: "clarity",
      width: 100,
    },
    {
      title: traslate("Cut"),
      dataIndex: "cut",
      width: 100,
    },
    {
      title: traslate("Depth"),
      dataIndex: "depth",
      width: 100,
    },
    {
      title: traslate("Table"),
      dataIndex: "table",
      width: 100,
    },
    {
      title: traslate("Polish"),
      dataIndex: "polish",
      width: 100,
    },
    {
      title: traslate("Symetry"),
      dataIndex: "symetry",
      width: 100,
    },
    {
      title: traslate("Measurement"),
      dataIndex: "measurement",
      width: 150,
    },
    {
      title: traslate("Cert"),
      dataIndex: "cert",
      width: 100,
    },
    {
      title: traslate("Price"),
      dataIndex: "price",
      width: 100,
    },
    {
      title: traslate("View"),
      dataIndex: "view",
      width: 100,
    },
  ];

  const data = [
    {
      key: "1",
      checkbox: <input type="checkbox" />,
      shape: (
        <div className="d-flex">
          <img src={roundDiamond} width="20" />
          <span>Round</span>
        </div>
      ),
      carat: <span>0.31</span>,
      color: <span>J</span>,
      clarity: <span>IF</span>,
      cut: <span>Excellent</span>,
      depth: <span>60</span>,
      table: <span>50</span>,
      polish: <span>Good</span>,
      symetry: <span>Very Good</span>,
      measurement: <span>4.86X4.79X2.86</span>,
      cert: <span>GIA</span>,
      price: <span>US$1,358</span>,
      view: <i class="fa fa-eye" aria-hidden="true"></i>,
    },
    {
      key: "1",
      checkbox: <input type="checkbox" />,
      shape: (
        <div>
          <img src={roundDiamond} width="20" />
          <span>Round</span>
        </div>
      ),
      sku: <span>177782295</span>,
      carat: <span>0.31</span>,
      color: <span>J</span>,
      clarity: <span>IF</span>,
      cut: <span>Excellent</span>,
      inhouse: <span>No</span>,
      depth: <span>60</span>,
      table: <span>50</span>,
      polish: <span>Good</span>,
      symetry: <span>Excellent</span>,
      measurement: <span>4.86X4.79X2.86</span>,
      cert: <span>GIA</span>,
      price: <span>US$1,358</span>,
      view: <i class="fa fa-eye" aria-hidden="true"></i>,
    },
  ];

  // for image upload

  const columns2 = [
    {
      title: traslate("Date"),
      dataIndex: "date",
    },
    {
      title: traslate("Time"),
      dataIndex: "time",
    },
    {
      title: traslate("File"),
      dataIndex: "file",
    },
    // {
    //     title: 'Type',
    //     dataIndex: 'type',
    // },
    {
      title: traslate("Status"),
      dataIndex: "status",
    },
    {
      title: traslate("Records"),
      dataIndex: "records",
    },
    {
      title: traslate("Report"),
      dataIndex: "report",
    },
  ];

  const data2 = [
    {
      key: "1",
      date: <span>07/30/2019</span>,
      time: <span>11:50 AM</span>,
      file: <span>720_07302019110702.zip</span>,
      // type: <span>Add & Update</span>,
      status: <span>Completed</span>,
      records: <span>15 of 15</span>,
      report: (
        <a
          id="Download"
          href="https://platform.stage.jewelcloud.com/DownloadReport.aspx?SummaryID=117857&amp;Type=Jewel&amp;DealerID=720"
        >
          Download
        </a>
      ),
    },
  ];

  // for daimond

  const columns3 = [
    {
      title: traslate("Date"),
      dataIndex: "date",
    },
    {
      title: traslate("Time"),
      dataIndex: "time",
    },
    {
      title: traslate("File"),
      dataIndex: "file",
    },
    {
      title: traslate("Type"),
      dataIndex: "type",
    },
    {
      title: traslate("Status"),
      dataIndex: "status",
    },
    {
      title: traslate("Records"),
      dataIndex: "records",
    },
    {
      title: traslate("Report"),
      dataIndex: "report",
    },
  ];

  const data3 = [
    {
      key: "1",
      date: <span>10/06/2020</span>,
      time: <span>12:30 PM</span>,
      file: <span>JsonTestFile2-ItemList.json</span>,
      type: <span>Add & Update</span>,
      status: <span>Completed</span>,
      records: <span>9 of 22</span>,
      report: (
        <a
          id="Download"
          href="https://platform.stage.jewelcloud.com/DownloadReport.aspx?SummaryID=117857&amp;Type=Jewel&amp;DealerID=720"
        >
          Download
        </a>
      ),
    },
  ];

  const resetRange = useRef(null);

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv app__preview p-0 border-0">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {" "}
                    {traslate("Application Preview")}{" "}
                  </h4>
                </div>
              </div>

              <div className="row steps__section">
                <div className="col-md-12">
                  <h4 className="workarea__heading">
                    {" "}
                    {traslate(
                      "Create Your Own Diamond Studs in 3 easy steps with Stud Builder"
                    )}{" "}
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="steps__block">
                    <h4> 1. {traslate("Select Your Diamonds")} </h4>
                    <img src={yourDiamond} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="steps__block">
                    <h4> 2. {traslate("Choose Your Setting")} </h4>
                    <img src={yourSetting} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="steps__block">
                    <h4> 3. {traslate("Complete Your Pendant")} </h4>
                    <img src={yourPendant} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="standard"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                    >
                      <Tab eventKey="standard" title={traslate("Standard")}>
                        <div className="tabs__content">
                          <div className="diamond__details">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="row text__block">
                                  <div className="diamond__imgs col-md-12">
                                    <div className="img__block selected">
                                      <img src={roundDiamond} />
                                      <p> {traslate("Round")} </p>
                                    </div>

                                    <div className="img__block">
                                      <img src={princessDiamond} />
                                      <p> {traslate("Princess")} </p>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Carats")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={22.42}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Price")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Cut")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("Ideal")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("Excellent")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Very Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Fair")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("M")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Clarity")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p>FL</p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p>IF</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I2</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="image__block">
                                  <h3> {traslate("Diamond Preview")} </h3>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <img
                                        src={roundDiamond}
                                        className="w-75"
                                      />
                                      <p>
                                        {" "}
                                        {traslate("Stock Number")} : 78442524{" "}
                                      </p>
                                    </div>
                                  </div>

                                  <h4>US$789</h4>
                                  <a href="#">
                                    {" "}
                                    {traslate("Add To Your Pendant")}{" "}
                                  </a>
                                  <a href="#"> {traslate("View Cart")} </a>
                                  <a href="#"> {traslate("Add To Compare")} </a>
                                  <button className="primary-btn">
                                    {" "}
                                    {traslate("View Details")}{" "}
                                  </button>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="table__block">
                                  <h5> 2103 {traslate("SIMILAR DIAMONDS")} </h5>
                                  <Table
                                    columns={columns}
                                    dataSource={data}
                                    scroll={{ y: 800 }}
                                  />
                                </div>
                                <div className="compare__btn">
                                  <button className="primary-btn">
                                    {" "}
                                    {traslate("Compare")}{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      {/* Lab Grown Tab Starts */}
                      <Tab eventKey="LabGrown" title={traslate("Lab Grown")}>
                        <div className="tabs__content">
                          <div className="diamond__details">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="row text__block">
                                  <div className="diamond__imgs col-md-12">
                                    <div className="img__block selected">
                                      <img src={roundDiamond} />
                                      <p> {traslate("Round")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={princessDiamond} />
                                      <p> {traslate("Princess")} </p>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Carats")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Price")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Cut")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("Ideal")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("Excellent")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Very Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Fair")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("M")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Clarity")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p>FL</p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p>IF</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I2</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <p className="mt-5">Please enter the details of the type of diamond you are looking for and your contact information.</p>
                                                                    <p>Your information will be submitted to one of our diamond specialists that will contact you.</p>
                                                                </div>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                      </Tab>
                      {/* Lab Grown Tab Ends */}
                      <Tab eventKey="Advanced" title={traslate("Advanced")}>
                        <div className="tabs__content">
                          <div className="diamond__details">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="row text__block">
                                  <div className="diamond__imgs col-md-12">
                                    <div className="img__block selected">
                                      <img src={roundDiamond} />
                                      <p> {traslate("Round")} </p>
                                    </div>

                                    <div className="img__block">
                                      <img src={princessDiamond} />
                                      <p> {traslate("Princess")} </p>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Carats")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Price")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Cut")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("Ideal")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("Excellent")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Very Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Fair")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("M")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Clarity")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p>FL</p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p>IF</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I2</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 mt-4">
                                    <label> {traslate("Polish")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("Excellent")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("Very Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Good")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 mt-4">
                                    <label> {traslate("Symmetry")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("Excellent")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("Very Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Good")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 mt-4">
                                    <label> {traslate("Fluorescence")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p>N</p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p>FNT</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>MED</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>ST</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3 mt-4">
                                    <div className="input__block">
                                      <label> {traslate("Depth")} </label>
                                      <input type="number" />
                                    </div>
                                  </div>

                                  <div className="col-md-3 mt-4">
                                    <div className="input__block">
                                      <label> {traslate("Table")} </label>
                                      <input type="number" />
                                    </div>
                                  </div>

                                  <div className="col-md-6 mt-4">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Certificates")}{" "}
                                      </label>
                                      <Select
                                        className="border__grey"
                                        placeholder="Certificates"
                                        name="productType"
                                        optionFilterProp="children"
                                      >
                                        <Option value="Option 1">
                                          {" "}
                                          {traslate("Option 1")}{" "}
                                        </Option>
                                        <Option value="Option 2">
                                          {" "}
                                          {traslate("Option 2")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="image__block">
                                  <p class="mt-5">
                                    {" "}
                                    {traslate(
                                      "Please enter the details of the type of diamond you are looking for and your contact information."
                                    )}{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {traslate(
                                      "Your information will be submitted to one of our diamond specialists that will contact you."
                                    )}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="table__block">
                                  <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("First Name")}{" "}
                                        </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label> {traslate("Last Name")} </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Phone Number")}{" "}
                                        </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Email Address")}{" "}
                                        </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Add Comments Here")}{" "}
                                        </label>
                                        <textarea></textarea>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="save__form">
                                        <button className="primary-btn mr-2">
                                          {" "}
                                          {traslate("Reset")}{" "}
                                        </button>
                                        <button className="primary-btn mr-2">
                                          {" "}
                                          {traslate("Cancel")}{" "}
                                        </button>
                                        <button className="primary-btn">
                                          {" "}
                                          {traslate("Request")}{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="Request" title={traslate("Request")}>
                        <div className="tabs__content">
                          <div className="diamond__details">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="row text__block">
                                  <div className="diamond__imgs col-md-12">
                                    <div className="img__block selected">
                                      <img src={roundDiamond} />
                                      <p> {traslate("Round")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={princessDiamond} />
                                      <p> {traslate("Princess")} </p>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Carats")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 mb-5">
                                    <div className="sliderrange__maindiv">
                                      <label> {traslate("Price")} </label>
                                      <div className="col-md-12 mt-3">
                                        <MultiRangeSlider
                                          min={0}
                                          max={37440}
                                          resetRange={resetRange}
                                          onChange={({ min, max }) =>
                                            console.log(
                                              `min = ${min}, max = ${max}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Cut")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("Ideal")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("Excellent")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Very Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Good")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("Fair")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {traslate("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {traslate("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("M")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("N")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("O")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {traslate("P")} </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label> {traslate("Clarity")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p>FL</p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p>IF</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VVS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>VS2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI2</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI3</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>SI4</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I1</p>
                                      </div>
                                      <div className="selection__box col">
                                        <p>I2</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="image__block">
                                  <p class="mt-5">
                                    {" "}
                                    {traslate(
                                      "Please enter the details of the type of diamond you are looking for and your contact information."
                                    )}{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {traslate(
                                      "Your information will be submitted to one of our diamond specialists that will contact you."
                                    )}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="table__block">
                                  <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("First Name")}{" "}
                                        </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label> {traslate("Last Name")} </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Phone Number")}{" "}
                                        </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Email Address")}{" "}
                                        </label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Add Comments Here")}{" "}
                                        </label>
                                        <textarea></textarea>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="save__form">
                                        <button className="primary-btn mr-2">
                                          {" "}
                                          {traslate("Reset")}{" "}
                                        </button>
                                        <button className="primary-btn mr-2">
                                          {" "}
                                          {traslate("Cancel")}{" "}
                                        </button>
                                        <button className="primary-btn">
                                          {" "}
                                          {traslate("Request")}{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      {/* Compare Tab Starts */}
                      <Tab eventKey="Compare" title={traslate("Compare")}>
                        <div className="tabs__content">
                          <div className="diamond__details">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="text__block">
                                  <p>
                                    {" "}
                                    {traslate(
                                      "Use your diamond comparison to save all the details of the diamonds you are considering and view them all together"
                                    )}{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {traslate(
                                      "This makes it easy to choose the right diamond."
                                    )}{" "}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="image__block">
                                  <img src={diamondDetailsImg} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="vert-table">
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label> {traslate("SKU#")} </label>
                                    </div>
                                    <div className="col-md-5">
                                      <label>15-3174</label>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="action__btns">
                                        <div class="image__block">
                                          <img src={deleteIcon} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label> {traslate("Date Added")} </label>
                                    </div>
                                    <div className="col-md-5">
                                      <label>05/03/2021</label>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="action__btns">
                                        <div class="image__block">
                                          <img src={deleteIcon} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label> {traslate("Price")} </label>
                                    </div>
                                    <div className="col-md-5">
                                      <label>US$605</label>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="action__btns">
                                        <div class="image__block">
                                          <img src={deleteIcon} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label> {traslate("Shape")} </label>
                                    </div>
                                    <div className="col-md-5">
                                      <label> {traslate("Round")} </label>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="action__btns">
                                        <div class="image__block">
                                          <img src={deleteIcon} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label> {traslate("Carat")} </label>
                                    </div>
                                    <div className="col-md-5">
                                      <label>0.20</label>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="action__btns">
                                        <div class="image__block">
                                          <img src={deleteIcon} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="compare-btn-section">
                                  <div className="compare__btn">
                                    <button className="primary-btn">
                                      {" "}
                                      {traslate("View")}{" "}
                                    </button>
                                  </div>
                                  <div className="compare__btn">
                                    <button className="primary-btn">
                                      {" "}
                                      {traslate("Delete")}{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      {/* Compare Tab Ends */}
                    </Tabs>

                    {/* <div class="col-md-6 mt-3">
                                        <button class="primary-btn">Save Details</button>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendantBuilderApplicationPreview;
