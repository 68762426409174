import React, { useState, useEffect } from "react";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import { Button, Switch, Modal, Table, Spin } from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../../i18n/translate";

const initailColorData = {
  metalColorID: 0,
  metalColor: "",
  enabled: true,
  deleteRow: 0,
};

const Addcolor = () => {
  const [getColorData, setgetColorData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [getloading, setGetLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [colorData, setColorData] = useState(initailColorData);
  const [isEdit, setIsEdit] = useState(false);
  const [validations, setValidations] = useState({
    colorNameVal: "",
  });
  const [delData, setDelData] = useState({
    metalColorID: 0,
  });

  useEffect(() => {
    handleGetMetalColors();
  }, []);

  const handleCancel = () => {
    setVisible(false);
    setColorData(initailColorData);
    setValidations({
      colorNameVal: "",
    });
    setIsEdit(false);
  };

  const handleGetMetalColors = (deleteCount) => {
    let inputData = {
      metalColorID: 0,
    };
    setGetLoading(true);
    AdminSettingsDataService.GetMetalColors(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;

        if (message === "Success") {
          setgetColorData(responseData);
          if (deleteCount) {
            var count = responseData?.length % 10;

            if (count == 0) {
              setPage(page - 1);
            }
          }
        } else {
          setgetColorData([]);
        }
      })
      .catch((error) => {
        setGetLoading(false);
        console.log(error);
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setColorData({
        ...colorData,
        [name]: e,
      });
    } else {
      setColorData({
        ...colorData,
        [e.target.name]: e.target.value,
      });
      setValidations({
        colorNameVal: "",
      });
    }
  };

  const handleValidation = () => {
    const { metalColor } = colorData;
    const validations = {
      colorNameVal: "",
    };
    let isValid = true;

    if (!metalColor.trim()) {
      isValid = false;
      validations.colorNameVal = traslate("Color Name is compulsory");
    } else {
      validations.colorNameVal = "";
    }
    setValidations(validations);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    let inputData = {
      metalColorID: colorData.metalColorID,
      metalColor: colorData.metalColor,
      enabled: colorData.enabled,
      isEdit: isEdit == true ? true : false,
    };
    AdminSettingsDataService.SaveMetalColor(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          if (isEdit) {
            NotificationManager.success(traslate("Color Has Been Updated Successfully"));
            handleCancel();
            handleGetMetalColors();
          } else {
            NotificationManager.success(traslate("Color Has Been Added Successfully"));
            handleCancel();
            handleGetMetalColors();
          }
        } else {
          setValidations({
            colorNameVal: traslate("Data Already Exists"),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditModal = (row) => {
    setVisible(true);
    setIsEdit(true);
    setColorData({
      metalColorID: row.metalColorID,
      metalColor: row.metalColor,
      enabled: row.enabled,
    });
  };

  const handleDeleteModal = (row) => {
    setVisible2(true);
    setDelData({
      metalColorID: row.metalColorID,
    });
  };

  const handleCancel2 = () => {
    setVisible2(false);
    setDelData({
      metalColorID: 0,
    });
  };

  const handleDelSubmit = () => {
    let inputData = {
      metalColorID: parseInt(delData.metalColorID),
    };
    AdminSettingsDataService.DeleteMetalColors(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Color Has Been Deleted Successfully."));
          handleGetMetalColors("1");
        } else {
          NotificationManager.error(traslate("Color Has Not Been Deleted."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleCancel2();
  };

  const { metalColor, enabled } = colorData;

  return (
    <React.Fragment>
      <div className="addcolor__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {" "}
                    {traslate("Add Color")}{" "}
                  </h4>
                </div>
                <div className="col-lg-6">
                  <div className="addcolor__btndiv text-left-mobile">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible(true)}
                    >
                      {" "}
                      <img src={Create_icon} alt="" /> {traslate("Add Color")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <div className="addcolor_tbldiv">
                <Spin spinning={getloading} size="large">
                  <Table
                    pagination={{
                      onChange(current, pageSize) {
                        setPage(current);
                        setPageSize(pageSize);
                      },
                      showSizeChanger: true,
                      responsive: true,
                    }}
                    columns={[
                      {
                        title: traslate("Sr. No."),
                        width: 100,
                        render: (value, item, index) =>
                          (page - 1) * pageSize + index + 1,
                      },
                      {
                        title: traslate("Color Name"),
                        dataIndex: "metalColor",
                      },
                      {
                        title: traslate("Active"),
                        dataIndex: "enabled",
                        Key: "enabled",
                        render: (item, row) => {
                          return item ? "Yes" : "No";
                        },
                        width: 190,
                      },
                      {
                        title: traslate("Action"),
                        dataIndex: "action",
                        width: 100,
                        render: (index, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => {
                                  handleEditModal(row);
                                }}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    dataSource={getColorData}
                    scroll={{ x: 600, y: 800 }}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={
                  isEdit === false
                    ? traslate("Add Color")
                    : traslate("Edit Color")
                }
                className="modal__addcolor"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={handleCancel}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleSubmit}>
                    {isEdit === false ? traslate("Add") : traslate("Update")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Color Name")} </label>
                              <input
                                type="text"
                                placeholder="Add Color"
                                name="metalColor"
                                value={metalColor}
                                onChange={handleOnChange}
                                className={
                                  validations.colorNameVal && "border__red"
                                }
                              />
                              <div>
                                {validations.colorNameVal && (
                                  <p className="error-color-red">
                                    {validations.colorNameVal}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Active")}</label>
                                <Switch
                                  name="enabled"
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={enabled}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "enabled")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                className="datamapping__modal"
                centered
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={() => setVisible2(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel2}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleDelSubmit}>
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p className="subheading">
                              {traslate("Are you sure you want to delete")} ?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Addcolor;
