import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Select, Switch, Spin,Tooltip } from "antd";
import AppsDiamondLinksService from "../../../services/apps-diamondlinks.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
const { Option } = Select;

const DiamondLinkLegacy = () => {
  // Initial State

  const initialState = {
    searchTitle: "",
    diamondTitle: "",
    textBottom1: "",
    urlBottom1: "",
    textBottom2: "",
    urlBottom2: "",
    textBottom3: "",
    urlBottom3: "",
    matchedPairs: true,
    logoResultPage: true,
    diamondResultPage: true,
    resultImageUrl: "",
    resultHyperlinkUrl: "",
    diamondImageUrl: "",
    alternateDiamondLink: "",
    detailsSearchResult: true,
    searchByCut: true,
    diamondDisplayPerPage: "",
    oddDiamondsCuts: true,
    dynamicFlash: true,
    showPopup: true,
    showShareButton: true,
    ringBuilder: "",
    showRingBuilder: true,
  };

  const [initialData, setInitialData] = useState(initialState);
  const [dealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);

  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetLagacyDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleGetLagacyDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      AppsDiamondLinksService.GetLagacyDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setInitialData((prevState) => ({
                ...prevState,
                searchTitle: responseData.resultsPageName,
                diamondTitle: responseData.detailPageName,
                textBottom1: responseData.hyper1TextAtBottom,
                urlBottom1: responseData.hyper1UrlAtBottom,
                textBottom2: responseData.hyper2TextAtBottom,
                urlBottom2: responseData.hyper2UrlAtBottom,
                textBottom3: responseData.hyper3TextAtBottom,
                urlBottom3: responseData.hyper3UrlAtBottom,
                matchedPairs: responseData.letUserSearchMatchedPairs,
                logoResultPage: responseData.showLogoOnGFLink,
                diamondResultPage: responseData.showEducationInGFLink,
                resultImageUrl: responseData.resultPageImageUrl,
                resultHyperlinkUrl: responseData.resultPageImageHyperLink,
                diamondImageUrl: responseData.diamondDetailsPageImage,
                alternateDiamondLink: responseData.alternateDiamondLinkText,
                detailsSearchResult: responseData.enhancedDetailsSearch,
                searchByCut: responseData.searchTableDepthCut,
                diamondDisplayPerPage: responseData.numberOfDiamondDisplayed,
                oddDiamondsCuts: responseData.showOddCutsOnSearchForm,
                dynamicFlash: responseData.dynamicFlashSearch,
                showPopup: responseData.showPopupOnDiamondInventoryPage,
                showShareButton: responseData.showShareButtonInGFLinkDetail,
                ringBuilder: responseData.useNewRingBuilder.toString(),
                showRingBuilder: responseData.showRingBuilderIcon,
              }));
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setBtnVisible(false);
      setInitialData({
        ...initialData,
        [name]: e,
      });
    } else {
      setBtnVisible(false);
      setInitialData({
        ...initialData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Submit

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        resultsPageName: initialData.searchTitle,
        detailPageName: initialData.diamondTitle,
        hyper1TextAtBottom: initialData.textBottom1,
        hyper1UrlAtBottom: initialData.urlBottom1,
        hyper2TextAtBottom: initialData.textBottom2,
        hyper2UrlAtBottom: initialData.urlBottom2,
        hyper3TextAtBottom: initialData.textBottom3,
        hyper3UrlAtBottom: initialData.urlBottom3,
        letUserSearchMatchedPairs: initialData.matchedPairs,
        showLogoOnGFLink: initialData.logoResultPage,
        showEducationInGFLink: initialData.diamondResultPage,
        resultPageImageUrl: initialData.resultImageUrl,
        resultPageImageHyperLink: initialData.resultHyperlinkUrl,
        diamondDetailsPageImage: initialData.diamondImageUrl,
        alternateDiamondLinkText: initialData.alternateDiamondLink,
        enhancedDetailsSearch: initialData.detailsSearchResult,
        searchTableDepthCut: initialData.searchByCut,
        numberOfDiamondDisplayed: initialData.diamondDisplayPerPage,
        showOddCutsOnSearchForm: initialData.oddDiamondsCuts,
        dynamicFlashSearch: initialData.dynamicFlash,
        showPopupOnDiamondInventoryPage: initialData.showPopup,
        showShareButtonInGFLinkDetail: initialData.showShareButton,
        useNewRingBuilder: initialData.ringBuilder,
        showRingBuilderIcon: initialData.showRingBuilder,
      };
      setLoading(true);
      AppsDiamondLinksService.UpdateDiamondLinkLagacyDetails(inputData)
        .then((response) => {
          var message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Data Updated Successfully.");
            handleGetLagacyDetails(dealerId);
          } else {
            NotificationManager.success("Data not Updated");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }

    setBtnVisible(true);
  };

  return (
    <React.Fragment>
      <div className="navigation__section dlegacy__section">
        <div className="form__fields col-md-12 pt-2">
          <div className="col-md-12 mt-2">
            <h4 className="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("Legacy")}{" "}
            </h4>
          </div>
          <Spin spinning={loading}>
            <div className="col-lg-6 mt-4">
              <div className="form__section">
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {traslate("Search Page Title")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={
                          initialData.searchTitle ? initialData.searchTitle : ""
                        }
                        name="searchTitle"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the TITLE at the top of the search results page. Leave blank for default value.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Diamond Detail page Title")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.diamondTitle}
                        name="diamondTitle"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Hyperlink #1 TEXT at bottom of search results page"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.textBottom1}
                        name="textBottom1"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                {/* <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("Hyperlink #1 URL at bottom")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <input type="textbox" placeholder="" 
                        value={initialData.urlBottom1} 
                        name="urlBottom1"
                        onChange={handleOnChange} />
                        <Tooltip className="ml-1" placement="right" title={hyplink1url}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div> */}

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Hyperlink #2 TEXT at bottom")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.textBottom2}
                        name="textBottom2"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Hyperlink #2 URL at bottom of search results page"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.urlBottom2}
                        name="urlBottom2"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Hyperlink #3 TEXT at bottom of search results page"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.textBottom3}
                        name="textBottom3"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Hyperlink #3 URL at bottom of search results page"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.urlBottom3}
                        name="urlBottom3"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Let users search for MATCHED PAIRS")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        // defaultChecked
                        // name="matchedPairs"
                        onChange={(e) =>
                          handleOnChange(e, "select", "matchedPairs")
                        }
                        checked={initialData.matchedPairs}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Logo on Results Page")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        // defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "logoResultPage")
                        }
                        checked={initialData.logoResultPage}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show Diamond Education on Results Page")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "diamondResultPage")
                        }
                        checked={initialData.diamondResultPage}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Results page image URL")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder="1559595546Index.jpeg"
                        value={initialData.resultImageUrl}
                        name="resultImageUrl"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Results page image hyperlink URL")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.resultHyperlinkUrl}
                        name="resultHyperlinkUrl"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none).")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Diamond details page image URL")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.diamondImageUrl}
                        name="diamondImageUrl"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Alternate diamond details link text")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <input
                        type="textbox"
                        placeholder=""
                        value={initialData.alternateDiamondLink}
                        name="alternateDiamondLink"
                        onChange={handleOnChange}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Enhanced details in search results")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "detailsSearchResult")
                        }
                        checked={initialData.detailsSearchResult}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price and Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Search By Cut Grade")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "searchByCut")
                        }
                        checked={initialData.searchByCut}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("You may choose to search by Cut Grade if desired. Table and Depth searching is enabled as well as they are directly related to Cut Grade.")}>
                            <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Number of diamonds displayed per page")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block d-flex align-items-center">
                      <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="25"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "diamondDisplayPerPage")
                        }
                        value={initialData.diamondDisplayPerPage}
                      >
                        <Option value="10">10</Option>
                        <Option value="15">15</Option>
                        <Option value="20">20</Option>
                        <Option value="25">25</Option>
                        <Option value="30">30</Option>
                        <Option value="35">35</Option>
                        <Option value="40">40</Option>
                        <Option value="45">45</Option>
                        <Option value="50">50</Option>
                        <Option value="55">55</Option>
                        <Option value="60">60</Option>
                        <Option value="65">65</Option>
                        <Option value="70">70</Option>
                        <Option value="75">75</Option>
                        <Option value="80">80</Option>
                        <Option value="85">85</Option>
                        <Option value="90">90</Option>
                        <Option value="95">95</Option>
                        <Option value="100">100</Option>
                      </Select>
                      <Tooltip className="ml-1" placement="right" title={traslate("Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly.")}>
                          <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Show odd diamond cuts on search form")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "oddDiamondsCuts")
                        }
                        checked={initialData.oddDiamondsCuts}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Dynamic Flash search form")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "dynamicFlash")
                        }
                        checked={initialData.dynamicFlash}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Choose whether to use the interactive Macromedia Flash search form instead of the regular search form.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                {/* <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("Show popup on Diamond inventory display page")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e)=>handleOnChange(e,"select","showPopup")}
                        
                        checked={initialData.showPopup}
                        />
                        <Tooltip className="ml-1" placement="right" title={traslate("Choose whether to use show popup of diamond details on the diamond inveotry page.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div> */}

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Show Share Button on Details Page (for New GF Link only)"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "showShareButton")
                        }
                        checked={initialData.showShareButton}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate("Use New Ringbuilder")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block d-flex align-items-center">
                      <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="Ring Builder Version I"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "ringBuilder")
                        }
                        // name="ringBuilder"
                        value={
                          initialData.ringBuilder
                            ? initialData.ringBuilder
                            : null
                        }
                      >
                        <Option value="4">
                          {" "}
                          {traslate("Ring Builder Version I")}{" "}
                        </Option>
                        <Option value="0">
                          {" "}
                          {traslate("Ring Builder Version II")}{" "}
                        </Option>
                        <Option value="1">
                          {" "}
                          {traslate("Ring Builder Version III")}{" "}
                        </Option>
                        <Option value="2">
                          {" "}
                          {traslate("Ring Builder Version IV")}{" "}
                        </Option>
                        <Option value="3">
                          {" "}
                          {traslate("Ring Builder Version V")}{" "}
                        </Option>
                      </Select>
                      <Tooltip className="ml-1" placement="right" title={traslate("Choose whether to link diamond search with new ring builder.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {traslate(
                        "Show Ring Builder Image (for New GF Link only)"
                      )}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) =>
                          handleOnChange(e, "select", "showRingBuilder")
                        }
                        checked={initialData.showRingBuilder}
                      />
                      <Tooltip className="ml-1" placement="right" title={traslate("Choose whether to display Ring Builder image at the right column of new diamond link.")}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-4">
                  <button
                    class={btnVisible ? "disabled-btn" : "primary-btn"}
                    disabled={btnVisible}
                    loading={submitLoading}
                    onClick={handleSubmit}
                  >
                    {traslate("Update Settings")}{" "}
                  </button>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondLinkLegacy;
