import React, { Fragment } from "react";
import Network from "./Network";

export const RecentlyUpdated = () => {
  return (
    <Fragment>
      <Network type="Recently Updated" />
    </Fragment>
  );
};

export default RecentlyUpdated;
