import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
} from "antd";
import traslate from "../../i18n/translate";

const ManageAccount = () => {
  const { Option } = Select;
  const [AccountData, setAccountData] = useState({
    AccountType: null,
  });

  const [disableBtn, setDisableBtn] = useState(true);

  const handleSubmit = async (e) => {};

  const handleCancel = async (e) => {
    setAccountData({
      AccountType: null,
    });
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);

    if (isSelect === "select") {
      setAccountData({
        ...AccountData,
        [name]: e,
      });
    } else {
      setAccountData({
        ...AccountData,
        [e.target.name]: e.target.value,
      });
    }
  };

  function onSearch(val) {}

  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h4 className="workarea__heading mb-0">
                {" "}
                {traslate("Manage Account")}{" "}
              </h4>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-6 mt-4">
                      <div className="input__block">
                        <label>{traslate("Select Account type")}</label>
                        <Select
                          className="border__grey"
                          showSearch
                          placeholder="Choose Any"
                          name="AccountType"
                          value={
                            AccountData.AccountType
                              ? AccountData.AccountType
                              : []
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "AccountType")
                          }
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="Company 1">
                            {" "}
                            {traslate("Company 1")}{" "}
                          </Option>
                          <Option value="Company 1">
                            {" "}
                            {traslate("Company 2")}{" "}
                          </Option>
                          <Option value="Company 1">
                            {" "}
                            {traslate("Company 3")}{" "}
                          </Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <button
                    className={
                      disableBtn ? "disabled-btn   me-4" : "primary-btn  me-4"
                    }
                    onClick={handleSubmit}
                  >
                    {" "}
                    {traslate("Apply")}{" "}
                  </button>
                  <button
                    className={disableBtn ? "disabled-btn   " : "primary-btn  "}
                    onClick={handleCancel}
                  >
                    {" "}
                    {traslate("Cancel")}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageAccount;
