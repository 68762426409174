import * as React from "react"
const CakeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    viewBox="0 0 50 50"
    {...props}
    style={{
        marginRight:"10px"
    }}
  >
    <path d="m25 .094-.781 1C23.516 1.992 20 6.578 20 9a5.019 5.019 0 0 0 4 4.906V10c0-.55.45-1 1-1s1 .45 1 1v3.906A5.019 5.019 0 0 0 30 9c0-2.422-3.516-7.008-4.219-7.906ZM23 15c-1.652 0-3 1.348-3 3v8h10v-8c0-1.652-1.348-3-3-3ZM11 28c-2.82 0-5.238 1.684-6.344 4.094.57 1.504 1.149 2.304 1.157 2.312C6.702 35.594 8.39 37 11.405 37c2.457 0 4.281-.844 5.594-2.594l.75-1.031.813 1C20.043 36.152 22.152 37 25 37c2.77 0 5-.898 6.438-2.625l.812-1 .75 1.031C34.313 36.156 36.137 37 38.594 37c2.457 0 4.281-.844 5.593-2.594.028-.035.778-.992 1.188-2.281A6.988 6.988 0 0 0 39 28Zm-7 7.313V42h42v-6.656c-.125.18-.207.265-.219.281C44.113 37.848 41.68 39 38.594 39c-2.653 0-4.809-.832-6.438-2.5C30.754 37.785 28.5 39 25 39c-2.96 0-5.36-.836-7.156-2.5-1.63 1.664-3.79 2.5-6.438 2.5-3.844 0-6.054-1.855-7.219-3.406A14.57 14.57 0 0 1 4 35.312ZM0 44v1c0 5 4.89 5 6.5 5h37c1.605 0 6.5 0 6.5-5v-1Z" />
  </svg>
)
export default CakeIcon
