import { httpApps } from "../helper/http-common";

class WatchCustomizerService {
    GetWatchOptionUrl(inputData) {
        return httpApps.post("/WatchCistomizer/WatchOptionUrl", inputData);
    }
    GetUpdateOptionUrl(inputData) {
        return httpApps.post("/WatchCistomizer/updatewatchoptionurl", inputData);
    }
}

export default new WatchCustomizerService();
