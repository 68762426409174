import React, { useState, useEffect } from "react";
import { Select, Button, Spin, Table, Popconfirm, Pagination } from "antd";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import traslate from "../../../i18n/translate";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import { useSelector } from "react-redux";
const ComposeMail = () => {
  const initialState = {
    TemplateName: null,
    ComposeID: null,
    FromName: null,
    FromEmail: null,
    Subject: null,
    Summary: null,
    Error: {}
  };
  const [state, setState] = useState(initialState);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [campaignDropList, setcampaignDropList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [composeId, setomposeId] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [dealerId, setDealerId] = useState(null);
  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalTemplateListCount, setTotalTemplateListCount] = useState(0);
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateFromName, setTemplateFromName] = useState("");

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  useEffect(() => {
    // handleCampaignfromgrid();
    handleGetCampaignTemplateList(pageNo, pageSize);
    if (loginDetials?.responseData?.dealerId) {
      setDealerId(loginDetials.responseData.dealerId);
    }
  }, []);

  const handleInpuOnChange = (e, name) => {
    let tempState = state;
    tempState.Error[name] = null;
    if (name === "TemplateName") {
      setTemplateName(e.target.value);
    } else if (name === "FromName") {
      setTemplateFromName(e.target.value);
    } else if (name === "TempleteSubject") {
      setTemplateSubject(e.target.value);
    } else {
      tempState[name] = e.target.value;
      setState((prevState) => ({
        ...prevState,
        ...tempState
      }));
    }
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;
    tempState.Error[name] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));

    let Email = campaignDropList
      .filter(function (x) {
        return x.dealerName == e;
      })
      .map(function (x) {
        return x.dealerEmail;
      });

    let Id = campaignDropList
      .filter(function (x) {
        return x.dealerName == e;
      })
      .map(function (x) {
        return x.dealerid;
      });

    setToEmail(Email);
    setomposeId(Id);
  };

  const handleCampaignfromgrid = () => {
    setLoading(true);
    AdminCampaignsService.Campaignfromgrid()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setcampaignDropList(responseData);
          setLoading(false);
        } else {
          setcampaignDropList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleValidation = () => {
    // const regex =
    //   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var isValid = true;
    // if (!state.ComposeID) {
    //   state.Error["ComposeID"] = traslate("Please select compose.");
    //   isValid = false;
    // }
    // if (!state.FromName) {
    //   state.Error["FromName"] = traslate("Please enter name.");
    //   isValid = false;
    // } else if (!state.FromName.trim()) {
    //   state.Error["FromName"] = traslate("Please enter name.");
    //   isValid = false;
    // }
    // if (!state.FromEmail) {
    //   state.Error["FromEmail"] = traslate("Please enter email.");
    //   isValid = false;
    // } else if (!state.FromEmail.trim()) {
    //   state.Error["FromEmail"] = traslate("Please enter email.");
    //   isValid = false;
    // } else if (regex.test(state.FromEmail) === false) {
    //   state.Error["FromEmail"] = traslate("Email is not valid.");
    //   isValid = false;
    // }
    // if (!state.Subject) {
    //   state.Error["Subject"] = traslate("Please enter subject.");
    //   isValid = false;
    // } else if (!state.Subject.trim()) {
    //   state.Error["Subject"] = traslate("Please enter subject.");
    //   isValid = false;
    // }

    if (!templateName) {
      state.Error["TemplateName"] = traslate("Please enter template name.");
      isValid = false;
    } else if (!templateName.trim()) {
      state.Error["TemplateName"] = traslate("Please enter template name.");
      isValid = false;
    }
    if (!templateSubject) {
      state.Error["TempleteSubject"] = traslate(
        "Please enter template subject."
      );
      isValid = false;
    } else if (!templateSubject.trim()) {
      state.Error["TempleteSubject"] = traslate(
        "Please enter template subject."
      );
      isValid = false;
    }
    if (!state.Summary) {
      state.Error["Summary"] = traslate("Please add some content.");
      isValid = false;
    } else if (!state.Summary.trim()) {
      state.Error["Summary"] = traslate("Please add some content.");
      isValid = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...state
    }));

    return isValid;
  };

  const handleCreateUpdateDeleteTemplates = (flagStatus, row) => {
    if (flagStatus == "add" ? handleValidation() : true) {
      if (flagStatus !== "delete") {
        setSubmitLoading(true);
      }
      let inputData = {
        templateName: row ? row.templateName : templateName,
        subject: row ? row.templateSubject : templateSubject,
        message: row ? row.message : state.Summary,
        FromName: row ? row.FromName : templateFromName,
        templateID: row ? row?.templateID : "",
        flag: flagStatus,
        DealerID: dealerId?.toString()
      };
      AdminCampaignsService.CreateUpdateDeleteTemplates(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            if (
              response?.data?.responseData == traslate("Template Name Already Exists")
            ) {
              NotificationManager.error(response.data.responseData);
            } else {
              NotificationManager.success(response.data.responseData);
              setSelectedRow(null);
              setTemplateName("");
              setTemplateSubject("");
              setTemplateFromName("");
              let tempState = state;
              tempState["Summary"] = "";
              setState({
                ...state,
                tempState
              });
              setIsEditTemplate(false);
              handleGetCampaignTemplateList(1, pageSize);
            }
          } else {
            NotificationManager.error(message);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        })
        .finally(() => setSubmitLoading(false));
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadImg", file);
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleEditTemplate = (row) => {
    let tempData = state;
    setTemplateName(row.templateName);
    setTemplateSubject(row.subject);
    setTemplateFromName(row.fromName);
    tempData["Summary"] = row.message;
    setState({
      ...state,
      tempData
    });
    setIsEditTemplate(true);
    setSelectedRow(row);
  };

  const handleDeleteTemplate = (row) => {
    setSelectedRow(row);
    handleCreateUpdateDeleteTemplates("delete", row);
  };

  const handleGetCampaignTemplateList = (page, size) => {
    let inputData = {
      pageno: page,
      pagesize: size
    };
    setLoading(true);
    AdminCampaignsService.GetCampaignTemplateList(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          let list = response?.data?.responseData?.campaignTemplatelist;
          if (list) {
            setTemplateList(list);
            setLoading(false);
            setTotalTemplateListCount(
              response?.data?.responseData?.totalrecords
            );
          }
        } else {
          NotificationManager.error(message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleAddOrEditTemplate = () => {
    let flagStatus = isEditTemplate ? "edit" : "add";
    let row = selectedRow;
    if (row) {
      row["message"] = state.Summary;
      row["templateName"] = templateName;
      row["templateSubject"] = templateSubject;
      row["FromName"] = templateFromName;
    }
    handleCreateUpdateDeleteTemplates(flagStatus, row);
  };

  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetCampaignTemplateList(page, pageSize);
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetCampaignTemplateList(current, size);
  }

  return (
    <React.Fragment>
      <div className="compmail__section">
        <Spin spinning={loading}>
          <div className="form__fields">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="workarea__heading mt-0 mb-0">
                  {traslate("Compose")}
                </h4>
              </div>

              <div className="col-lg-12">
                <div className="row mt-3">
                  <div className="col-lg-3  mobile-m-0">
                    {/* <div className="input__block mobile-m-0">
                      <label>
                        
                        {traslate("Select Campaign")}
                        <span>*</span>
                      </label>
                      <Select
                        className="border__grey"
                        showSearch
                        placeholder="Select Campaign"
                        optionFilterProp="children"
                        value={state.ComposeID}
                        onChange={(e) => onChangeDrop(e, "ComposeID")}
                        filterOption={(input, option) => {
                          if (
                            option?.children &&
                            typeof option?.children == "string" &&
                            input
                          )
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                            );
                        }}
                      >
                        <Option value="">
                          
                          {traslate("Select Campaign")}
                        </Option>
                        {campaignDropList &&
                          campaignDropList.map((item, i) => {
                            return (
                              <Option value={item.dealerName}>
                                {item.dealerName}
                              </Option>
                            );
                          })}
                      </Select>
                      {state.Error ? (
                        <div className="text-danger">
                          {state.Error["ComposeID"]}
                        </div>
                      ) : null}
                    </div> */}

                    <div className="col-lg-12 mt-3 ">
                      <div className="input__block mobile-m-0">
                        <label>
                          {traslate("Template Name")} <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Template Name"
                          onChange={(e) =>
                            handleInpuOnChange(e, "TemplateName")
                          }
                          value={templateName}
                        />
                        {state.Error ? (
                          <div className="text-danger">
                            {state.Error["TemplateName"]}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3 mobile-m-0">
                    <div className="input__block mobile-m-0">
                      <label>
                        {traslate("Subject")} <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Template subject"
                        onChange={(e) =>
                          handleInpuOnChange(e, "TempleteSubject")
                        }
                        value={templateSubject}
                      />
                      {state.Error ? (
                        <div className="text-danger">
                          {state.Error["TempleteSubject"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3 ">
                    <div className="input__block mobile-m-0">
                      <label>{traslate("From Name")}</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        onChange={(e) => handleInpuOnChange(e, "FromName")}
                        value={templateFromName}
                      />
                      {/* {state.Error ? (
                        <div className="text-danger">
                          {state.Error["FromName"]}
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                  {/*  <div className="col-lg-3 mt-3">
                    <div className="input__block mobile-m-0">
                      <label>
                        
                        {traslate("From Email")} <span>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        onChange={(e) => handleInpuOnChange(e, "FromEmail")}
                        value={state.FromEmail}
                      />
                      {state.Error ? (
                        <div className="text-danger">
                          {state.Error["FromEmail"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="input__block mobile-m-0">
                      <label>
                        
                        {traslate("Type Your Subject")} <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Subject"
                        onChange={(e) => handleInpuOnChange(e, "Subject")}
                        value={state.Subject}
                      />
                      {state.Error ? (
                        <div className="text-danger">
                          {state.Error["Subject"]}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-12 mt-4">
                <div className="editor__section">
                  <CKEditor
                    config={{
                      extraPlugins: [uploadPlugin]
                    }}
                    editor={ClassicEditor}
                    data={state.Summary}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      state.Error["Summary"] = null;
                      const data = editor.getData();
                      setState({
                        ...state,
                        Summary: data
                      });
                    }}
                  />
                  {state.Error ? (
                    <div className="text-danger">{state.Error["Summary"]}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12 my-4">
                <div className="compmail__btndiv">
                  <Button
                    className="primary-btn"
                    onClick={handleAddOrEditTemplate}
                    loading={submitLoading}
                  >
                    {traslate(isEditTemplate ? "Save" : "Create")}
                  </Button>
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                {/* <h4 className="workarea__heading mt-0 mb-0">
                  
                  {traslate("Template List")}
                </h4> */}
                <div className="mailist_tbldiv">
                  <Spin spinning={loading}>
                    <Table
                      const
                      columns={[
                        {
                          title: traslate("Template Subject"),
                          dataIndex: "subject",
                          key: "subject"
                        },
                        {
                          title: traslate("Template Name"),
                          dataIndex: "templateName",
                          key: "templateName"
                        },
                        {
                          title: traslate("Actions"),
                          dataIndex: "save",
                          render: (text, row) => (
                            <div className="action__btns">
                              <div className="image__block">
                                <img
                                  src={Edit_icon}
                                  onClick={() => handleEditTemplate(row)}
                                  alt=""
                                />
                              </div>
                              <div className="image__block">
                                <Popconfirm
                                  title={traslate(
                                    "Are you sure you want to delete ?"
                                  )}
                                  onConfirm={() => handleDeleteTemplate(row)}
                                  placement="left"
                                >
                                  <img src={Delete_iconbl} alt="" />
                                </Popconfirm>
                              </div>
                            </div>
                          ),
                          width: 100
                        }
                      ]}
                      // onRow={(record, recordIndex) => ({
                      //   onClick: (event) => {
                      //     handleOnRowClick(record);
                      //   },
                      // })}
                      dataSource={templateList}
                      scroll={{ x: 600, y: 800 }}
                      // pagination={{
                      //   responsive: true,
                      //   showSizeChanger: true,
                      // }}
                      pagination={false}
                    />
                    <div
                      className="col-md-12 mt-3"
                      style={{ textAlign: "end" }}
                    >
                      <Pagination
                        current={pageNo}
                        pageSize={pageSize}
                        total={totalTemplateListCount}
                        onChange={handlePageNoChange}
                        onShowSizeChange={handlePageSizeChange}
                        showSizeChanger="true"
                      />
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default ComposeMail;
