import { httpDealer } from "../helper/http-common";
class ManageUserService {
  GetCompanyManagerUserDetails(inputData) {
    return httpDealer.post("/Dealer/GetCompanyManagerUserDetails", inputData);
  }
  GetCompanyRoleMasterDetails() {
    return httpDealer.post("/Dealer/GetCompanyRoleMasterDetails");
  }
  GetCompanyManagerUserDetailsByUserId(inputData) {
    return httpDealer.post(
      "/Dealer/GetCompanyManagerUserDetailsByUserId",
      inputData
    );
  }
  UpdateUserDetails_New(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/MangeUser/UpdateUserDetails_New", inputData, {
      headers,
    });
  }
  SaveUserDetails_New(inputData) {
    return httpDealer.post("/MangeUser/SaveUserDetails_New", inputData);
  }
  InsertNewUserDetail(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/MangeUser/InsertNewUserDetail", inputData, {
      headers,
    });
  }
  DeleteManageUserDetail(inputData) {
    return httpDealer.post("/MangeUser/DeleteManageUserDetail", inputData);
  }
}

export default new ManageUserService();
