import { httpAdminReport } from "../helper/http-common";

class AdminJewelryReportService {
  GetVendorDropdownList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_VendorDetails",
      inputData
    );
  }
  GetRetailerDropdownList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_RetailerDetails",
      inputData
    );
  }
  ChangeRetailerList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Bind_RetailerDetails",
      inputData
    );
  }
  ChangeVendorList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Bind_VendorDetails",
      inputData
    );
  }
  GetClicksBasedOnCategory(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryCategoryChartColunmData",
      inputData
    );
  }
  GetClicksCount(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/AdminJewelryReportTableChart_Count",
      inputData
    );
  }
  GetClicksBasedOnRetailer(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryTopRetailerChartColunm",
      inputData
    );
  }
  GetClicksBasedOnPrice(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryPriceChartColunmData",
      inputData
    );
  }
  GetClicksBasedOnVendor(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryTopVendorRetailerChartColunm",
      inputData
    );
  }
  GetForDealer(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/CheckDealermembers",
      inputData
    );
  }
  GetTableChartData(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryReportForTableChart",
      inputData
    );
  }
  GetMetalTypeData(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryMetaltypeChartColunmData",
      inputData
    );
  }
}

export default new AdminJewelryReportService();
