import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Chart } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import traslate from "../../../i18n/translate";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { useSelector } from "react-redux";
import moment from "moment";

Chart.register(ChartDataLabels);
const DiamondReportsColorComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer,
}) => {
  /*React Binding Starts*/
  const loginDetials = useSelector((state) => state.loginReducer);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [diamondColorSearchesLabels, setdiamondColorSearchesLabels] = useState(
    []
  );
  const [DiamondColorSearchesList, setDiamondColorSearchesList] = useState([]);
  const [DiamondColorYAxisList, setDiamondColorYAxisList] = useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleLoadExtendedDiamondChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/

  const handleLoadExtendedDiamondChartReports = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        reportName: "ExtDiamondChrt",
      };
      setLoadChartReportsLoading(true);

      ReportsDiamondService.LoadExtendedDiamondChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondColorSearchesList = responseData.DiamondColorSearches;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setDiamondColorSearchesList(DiamondColorSearchesList);
              var DiamondColorSearchesLabelArr = DiamondColorSearchesList.map(
                (item) => {
                  return item.Color;
                }
              );
              setdiamondColorSearchesLabels(DiamondColorSearchesLabelArr);
              var DiamondColorYAxisList = DiamondColorSearchesList.map(
                (item) => {
                  return item.CNT;
                }
              );
              setDiamondColorYAxisList(DiamondColorYAxisList);

              var totalValueForPercentage = DiamondColorYAxisList.reduce(
                (a, b) => a + b,
                0
              );

              setTotalValueForPercentage(totalValueForPercentage);
              setKey(!key);
            }
          } else {
            setDiamondColorSearchesList([]);
            setDiamondColorYAxisList([]);
          }
        })
        .catch((error) => {
          setDiamondColorSearchesList([]);
          setDiamondColorYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondColorSearchesLabels([]);
        });
    } catch (error) {
      setDiamondColorSearchesList([]);
      setDiamondColorYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondColorSearchesLabels([]);
    }
  };
  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {traslate("Diamond Color Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            {DiamondColorSearchesList.length > 0 ? (
              <Pie
                key={key}
                data={{
                  labels: diamondColorSearchesLabels,
                  datasets: [
                    {
                      label: "Clarity Description",
                      data: DiamondColorYAxisList,
                      backgroundColor: [
                        "rgb(14, 220, 232)",
                        "rgb(16, 150, 24)",
                        "rgb(255, 153, 0)",
                        "rgb(153, 0, 153)",
                        "rgb(0, 153, 198)",
                        "rgb(51, 102, 204)",
                        "rgb(220, 57, 18)",
                        "rgb(102, 170, 0)",
                        "rgb(221, 68, 119)",
                        "rgb(237, 237, 1)",
                      ],
                      borderColor: [
                        "rgb(14, 220, 232)",
                        "rgb(16, 150, 24)",
                        "rgb(255, 153, 0)",
                        "rgb(153, 0, 153)",
                        "rgb(0, 153, 198)",
                        "rgb(51, 102, 204)",
                        "rgb(220, 57, 18)",
                        "rgb(102, 170, 0)",
                        "rgb(221, 68, 119)",
                        "rgb(237, 237, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    datalabels: {
                      display: true,
                      align: "end",
                      color: "rgb(255, 255, 255)",
                      font: {
                        size: 12,
                      },
                      formatter: (value) => {
                        if (totalValueForPercentage) {
                          let finalPercentage = (
                            (value / totalValueForPercentage) *
                            100
                          ).toFixed(2);
                          setTooltipPercentage(finalPercentage);
                          if (finalPercentage >= 5) {
                            return finalPercentage + "%";
                          } else {
                            return "";
                          }
                        }
                      },
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (context) {
                          let label = context.label || "";
                          let formattedValue = context.formattedValue || "";

                          if (label) {
                            label =
                              label +
                              " " +
                              formattedValue +
                              " (" +
                              TooltipPercentage +
                              "%" +
                              ")";
                          }
                          return label;
                        },
                      },
                    },
                    legend: {
                      position: "left",
                    },
                  },
                }}
              />
            ) : (
              <div className="no-report-render">
                <div className="desc-space">
                  <h4 className="text-center">{traslate("No Data Found")}</h4>
                </div>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondReportsColorComp;
