import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Bar } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { useSelector } from "react-redux";
import moment from "moment";

const DiamondReportsShapeComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer,
}) => {
  /*React Binding Starts*/
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondShapeSearchesLabels, setDiamondShapeSearchesLabels] = useState(
    []
  );
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [DiamondShapeColorData, setDiamondShapeColorData] = useState([]);
  const [DiamondShapeYAxisList, setDiamondShapeYAxisList] = useState([]);
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleLoadExtendedDiamondChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/

  const handleLoadExtendedDiamondChartReports = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        reportName: "ExtDiamondChrt",
      };
      setLoadChartReportsLoading(true);

      ReportsDiamondService.LoadExtendedDiamondChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondShapeSearchesList = responseData.DiamondShapeSearches;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              var DiamondShapeSearchesLabelArr = DiamondShapeSearchesList.map(
                (item) => {
                  return item.Cut;
                }
              );
              var DiamondShapeYAxisList = DiamondShapeSearchesList.map(
                (item) => {
                  return item.CNT;
                }
              );
              setDiamondShapeSearchesLabels(DiamondShapeSearchesLabelArr);
              setDiamondShapeYAxisList(DiamondShapeYAxisList);
              diamondShapeColorHandler(DiamondShapeSearchesLabelArr);
              setKey(!key);
            }
          } else {
            setDiamondShapeYAxisList([]);
          }
        })
        .catch((error) => {
          setLoadChartReportsLoading(false);
          setDiamondShapeYAxisList([]);
          setDiamondShapeSearchesLabels([]);
        });
    } catch (error) {
      setLoadChartReportsLoading(false);
      setDiamondShapeYAxisList([]);
      setDiamondShapeSearchesLabels([]);
    }
  };
  /*API Binding Ends*/

  const diamondShapeColorHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setDiamondShapeColorData(color);
  };

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {traslate("Diamond Shape Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            <Bar
              key={key}
              data={{
                labels: diamondShapeSearchesLabels,
                datasets: [
                  {
                    label: "Count",
                    data: DiamondShapeYAxisList,
                    backgroundColor: DiamondShapeColorData,
                    borderColor: DiamondShapeColorData,
                    borderWidth: 1,
                  },
                ],
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: true,
                      text: "Shape",
                      font: {
                        size: 16,
                      },
                    },
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                onClick: function (evt, element) {},
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondReportsShapeComp;
