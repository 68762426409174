import React, { useState, useEffect, useRef } from "react";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Table, Space, Spin } from "antd";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ReactStars from "react-stars";

const VendorRatings = () => {
  const [vendorRatingData, setVendorRatingData] = useState([]);
  const ref = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dealerId, setDealerId] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);
  useEffect(
    (e) => {
      const input = ref.current;
      if (input) input.setSelectionRange(cursor, cursor);
    },
    [ref, vendorRatingData]
  );

  useEffect(() => {
    handleGetVendorRatingList(pageNo, pageSize);
  }, []);

  const handleGetVendorRatingList = (pageNo, pageSize) => {
    let inputData = {
      pageNumber: pageNo,
      pageSize: pageSize,
    };
    setLoading(true);
    AdminToolsDataService.GetVendorRatingList(inputData)
      .then((response) => {
        setLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData.vendorRatingPageResponse) {
            const temp = responseData.vendorRatingPageResponse.map((x) => {
              return { ...x, isdisable: true };
            });
            setVendorRatingData(temp);
            setTotalCount(responseData.totalRecord);
          } else {
            setVendorRatingData([]);
            setTotalCount(0);
          }
        } else {
          setVendorRatingData([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSave = (row) => {
    let inputData = {
      id: row.id,
      vendorID: row.vendorID,
      systemRating: row.systemRating,
      manualRating: parseFloat(row.manualRating),
      dealerId: dealerId,
      ipAddress: "",
    };
    setLoading(true);
    AdminToolsDataService.SaveVendorRating(inputData)
      .then((response) => {
        setLoading(false);
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Vendor Rating Updated Successfully."));
          handleGetVendorRatingList(pageNo, pageSize);
        } else {
          NotificationManager.success(traslate("Vendor Rating Not Updated ."));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangeRating = (row, newRating) => {
    const temp = vendorRatingData.map((x) => {
      if (x.id === row.id) {
        return { ...x, manualRating: newRating, isdisable: false };
      } else return x;
    });

    setVendorRatingData(temp);
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetVendorRatingList(page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetVendorRatingList(current, size);
  }

  return (
    <React.Fragment>
      <div className="vendorate_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Vendor Ratings")}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="vendor_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "vendorID",
                        title: traslate("Vendor ID"),
                        width: 100,
                      },
                      {
                        dataIndex: "dealerName",
                        title: traslate("Vendor Name"),
                        width: 200,
                      },
                      {
                        dataIndex: "systemRating",
                        title: traslate("System Ratings"),
                        width: 100,
                      },
                      {
                        dataIndex: "manualRating",
                        width: 150,
                        title: traslate("Manual Ratings"),
                        render: (item, row) => {
                          return (
                            <ReactStars
                              value={row.manualRating}
                              color1="#fff"
                              color2="#1883b8"
                              size={24}
                              starSpacing="0px"
                              onChange={(e) => {
                                handleChangeRating(row, e);
                              }}
                              half={true}
                              count={5}
                              name="rating"
                            />
                          );
                        },
                      },
                      {
                        dataIndex: "dateModified",
                        title: traslate("Modified Date"),
                        width: 100,
                      },

                      {
                        title: traslate("Save Vendor Rating"),
                        key: "action",
                        dataIndex: "isdisable",
                        width: 100,
                        render: (text, row) => (
                          <Space size="middle">
                            <button
                              className={
                                row.isdisable == true
                                  ? "disabled-btn"
                                  : "primary-btn"
                              }
                              disabled={row.isdisable == true ? true : false}
                              onClick={() => {
                                handleSave(row);
                              }}
                            >
                              {traslate("Save")}
                            </button>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={vendorRatingData}
                    onChange={(pagination, filters, sorters) => {
                      document
                        .getElementsByClassName("content__area")[0]
                        .scroll(0, 0);
                    }}
                    pagination={{
                      total: totalCount,
                      pageNo: pageNo,
                      pageSize: pageSize,
                      pageSizeOptions: [10, 20, 50, 100],
                      responsive: true,
                      showSizeChanger: true,
                      onChange: handlePageNoChange,
                      onShowSizeChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 900, y: 800 }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VendorRatings;
