import { combineReducers } from "redux";
import loginReducer from "../reducers/login/login.reducers";
import headerMenuReducer from "./headerMenu/headerMenu";
import manageCollectionReducer from "./manageCollection/manageCollection";
import pageParameterReducer from "./PageParameter/PageParameter";
import contactReducer from "./Contact/contact";
import RetailerReducer from "./Retailer/retailer";
import manageInventoryReducer from "./Community/manageInventory";
export default combineReducers({
  loginReducer,
  headerMenuReducer,
  manageCollectionReducer,
  pageParameterReducer,
  contactReducer,
  manageInventoryReducer,
  RetailerReducer,
});
