import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Line } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { useSelector } from "react-redux";
import moment from "moment";

const DiamondReportsCertificateComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer,
}) => {
  /*React Binding Starts*/

  const loginDetials = useSelector((state) => state.loginReducer);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [
    diamondCertificateSearchesLabels,
    setdiamondCertificateSearchesLabels,
  ] = useState([]);
  const [DiamondCertificateYAxisList, setDiamondCertificateYAxisList] =
    useState([]);
  const [key, setKey] = useState(true);

  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleLoadExtendedDiamondChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/

  const handleLoadExtendedDiamondChartReports = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        reportName: "ExtDiamondChrt",
      };
      setLoadChartReportsLoading(true);
      ReportsDiamondService.LoadExtendedDiamondChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondCertificateSearchesList =
            responseData.DiamondCertificateSearches;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              var DiamondCertificateSearchesLabelArr =
                DiamondCertificateSearchesList.map((item) => {
                  return item.Certificate;
                });
              setdiamondCertificateSearchesLabels(
                DiamondCertificateSearchesLabelArr
              );
              var DiamondCertificateYAxisList =
                DiamondCertificateSearchesList.map((item) => {
                  return item.CNT;
                });
              setDiamondCertificateYAxisList(DiamondCertificateYAxisList);
              setKey(!key);
            }
          } else {
            setDiamondCertificateYAxisList([]);
          }
        })
        .catch((error) => {
          setDiamondCertificateYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondCertificateSearchesLabels([]);
        });
    } catch (error) {
      setDiamondCertificateYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondCertificateSearchesLabels([]);
    }
  };
  /*API Binding Ends*/

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {traslate("Diamond Certificate Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            <Line
              key={key}
              data={{
                labels: diamondCertificateSearchesLabels,
                datasets: [
                  {
                    label: "Count",
                    data: DiamondCertificateYAxisList,
                    fill: false,
                    borderColor: "rgb(66, 133, 244)",
                    borderWidth: 1,
                  },
                ],
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: true,
                      text: "Certificate",
                      font: {
                        size: 16,
                      },
                    },
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondReportsCertificateComp;
