//---------------Demo Config----------------

// let config = {
//   AdminAPIURL: "https://gem-adminapi-demo.brainvire.net/api",
//   DealerAPIURL: "https://gem-dealerapi-demo.brainvire.net/api",
//   CatalogAPIURL: "https://gem-ctlgapi-demo.brainvire.net/api",
//   CommunityAPIURL: "https://gem-commntapi-demo.brainvire.net/api",
//   AppsAPIURL: "https://gem-appsapi-demo.brainvire.net/api",
//   BlobURL: "https://jewelcloudstagingdiag.blob.core.windows.net//",
//   PurchaseOrderAPIURL: "https://gem-findapipo-demo.brainvire.net/api",
//   MarketingUploadAPIURL: "https://gem-mrktmg-api.brainvire.net/api",
//   IdleTimer: 3600000,
//   AdminReportURL: "https://gem-reportapi-demo.brainvire.net/api",
// };

//---------------Migration Config--------------------

// let config = {
//   AdminAPIURL: "https://jc2-admin-api.mgr.brainvire.net/api",
//   DealerAPIURL: "https://jc2-dlr-api.mgr.brainvire.net/api",
//   CatalogAPIURL: "https://jc2-ctlg-api.mgr.brainvire.net/api",
//   CommunityAPIURL: "https://jc2-cmnt-api.mgr.brainvire.net/api",
//   AppsAPIURL: "https://jc2-apps-api.mgr.brainvire.net/api",
//   PurchaseOrderAPIURL: "https://jc2-ipo-api.mgr.brainvire.net/api",
//   MarketingUploadAPIURL: "https://jc2-mrktg-api.mgr.brainvire.net/api",
//   AdminReportURL: "https://jc2-report-api.mgr.brainvire.net/api",
//   BlobURL: "https://jewelcloudstagingdiag.blob.core.windows.net//",
//   IdleTimer: 3600000,
// };

//---------------Stage Config--------------------

let config = {
  AdminAPIURL: "https://jc2-admin-api.stage.jewelcloud.com/api",
  DealerAPIURL: "https://jc2-dlr-api.stage.jewelcloud.com/api",
  CatalogAPIURL: "https://jc2-ctlg-api.stage.jewelcloud.com/api",
  CommunityAPIURL: "https://jc2-cmnt-api.stage.jewelcloud.com/api",
  AppsAPIURL: "https://jc2-apps-api.stage.jewelcloud.com/api",
  PurchaseOrderAPIURL: "https://jc2-ipo-api.stage.jewelcloud.com/api",
  MarketingUploadAPIURL: "https://jc2-mrktg-api.stage.jewelcloud.com/api",
  AdminReportURL: "https://jc2-report-api.stage.jewelcloud.com/api",
  BlobURL: "https://jewelcloudstagingdiag.blob.core.windows.net/",
  IdleTimer: 3600000
};

export default config;
