import React from "react";
import StudBuilderDaimondMarkup from './StudBuilderDaimondMarkup'

const StudbuilderLabGrowMarkup = () => {  
    
  return (
    <React.Fragment>
      <StudBuilderDaimondMarkup type="LAB" />
    </React.Fragment>
  );
  
}
export default StudbuilderLabGrowMarkup;
