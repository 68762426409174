import { httpAdminReport } from "../helper/http-common";
class ReportsDiamondService {
  DiamondChartForCutGrade(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/DiamondChartForCutGrade",
      inputData
    );
  }
  LoadExtendedDiamondChartReports(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/LoadExtendedDiamondChartReports",
      inputData
    );
  }
  CheckDealermembers(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/CheckDealermembers",
      inputData
    );
  }
  GetDiamondColumnChartData(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/GetDiamondColumnChartData",
      inputData
    );
  }
  GetDiamondReportForTableChart(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/GetDiamondReportForTableChart",
      inputData
    );
  }
  GetDiamondReportForTableChartAll(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/GetDiamondReportForTableChartAll",
      inputData
    );
  }
  DimondChart_SortingBindData(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/DimondChart_SortingBindData",
      inputData
    );
  }
  ExportToPDF(inputData) {
    return httpAdminReport.post("/DiamondReport/ExportToPDF", inputData, {
      responseType: "arraybuffer",
    });
  }
  ExportToCSV(inputData) {
    return httpAdminReport.post("/DiamondReport/ExportToCSV", inputData, {
      responseType: "arraybuffer",
    });
  }

  ExportDataToCSV(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/GetDiamondReportForTableChartAll_CSV_Download",
      inputData
    );
  }

  ExportTableDataToPDF(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/GetDiamondReportForTableChart_PDF",
      inputData,
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }
    );
  }

  AddToCartOrWishlistReportForTableChart(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/AddToCartOrWishlistReportForTableChart",
      inputData
    );
  }
  AddToCartOrWishlistChartColunmData(inputData) {
    return httpAdminReport.post(
      "/DiamondReport/AddToCartOrWishlistChartColunmData",
      inputData
    );
  }
}

export default new ReportsDiamondService();
