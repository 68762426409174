import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import ConversationsDashboard from "./ConversationsDashboard";
import AppointmentsDashboard from "./AppointmentsDashboard";
import { Link, useNavigate } from "react-router-dom";
import traslate from "../../i18n/translate";
import {
  setChecklinkHeaderMenuData,
  setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";
import { Spin } from "antd";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import {
  setVendorConversationCount,
  setVendorAppointmentCount,
  setVendorConversationCountSent,
  setVendorConversationCountUnRead,
} from "../../actions/Contact/Contact";
import StarRatings from "react-star-ratings";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRedirect = (record) => {
      navigate("/retailerProfile", {
        state: { key: "Profile", id: record.toString() },
      });
      dispatch(setChecklinkHeaderMenuData("Retailers"));
      dispatch(setSelectSideMenu(["jewelryRetailerPermission"]));
    };
    return props.loadVendorDashBoardList ? (
      <GoogleMap
        key={"AIzaSyAc6HbqpQfHx_SEUcGRHYiQ-tDOoNqtb0g"}
        defaultZoom={3}
        defaultCenter={{ lat: 37.717564, lng: -88.18988 }}
      >
        {props.loadVendorDashBoardList
          ? props.loadVendorDashBoardList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Marker
                    onClick={() => handleRedirect(item.DealerID)}
                    position={{ lat: Number(item.log), lng: Number(item.lat) }}
                    title={item.DealerCompany + "::" + item.FullAddress}
                  ></Marker>
                </React.Fragment>
              );
            })
          : null}
      </GoogleMap>
    ) : null;
  })
);
const DashboardVendor = ({ pageNameType, setpageNameType }) => {
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const ContactDetails = useSelector((state) => state.contactReducer);
  const [loadVendorDashBoardList, setLoadVendorDashBoardList] = useState([]);
  const [
    dashBoardVendorLeftPanelInfoList,
    setDashBoardVendorLeftPanelInfoList,
  ] = useState({});
  const dispatch = useDispatch();
  const [updateSalesRepData, setUpdateSalesRepData] = useState({
    vendorId: "",
    retailerID: "",
    msgID: "",
    id: "",
  });
  const [initialCount, setInitialCount] = useState(0);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [conversionCount, setConversionCount] = useState("0");

  // For Navigate Button Schedule New
  let navigate = useNavigate();

  const [QuickLinksLoading, setQuickLinksLoading] = useState(false);
  const [conversationCommonCount, setConversationCommonCount] = useState({});

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        handleGetLoadVendorDashBoard(loginDetials.responseData.dealerId);
        handleGetRetailerAppointmentData(loginDetials.responseData.dealerId);
        handleGetDashBoardVendorLeftPanelInfo(
          loginDetials.responseData.dealerId
        );
      }
      handleGetConversionCount(loginDetials.responseData.dealerId);
    }
  }, []);

  useEffect(() => {
    if (ContactDetails) {
      setConversionCount(ContactDetails.vendorConversionCountUnRead);
      setAppointmentCount(ContactDetails.vendorAppointmentCount);
    }
  }, [ContactDetails]);

  /*API binding starts*/
  const handleGetLoadVendorDashBoard = async (id) => {
    try {
      let inputData = {
        dealerId: id.toString(),
        type: "1",
      };
      await AdminDashboardDataService.GetLoadVendorDashBoard(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.data.Table;
          if (message === "Success") {
            if (responseData) {
              setLoadVendorDashBoardList(responseData);
            } else {
              setLoadVendorDashBoardList([]);
            }
          } else {
            setLoadVendorDashBoardList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetDashBoardVendorLeftPanelInfo = async (id) => {
    setQuickLinksLoading(true);
    try {
      let inputData = {
        dealerId: id.toString(),
      };
      await AdminDashboardDataService.GetDashBoardVendorLeftPanelInfo(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var dashBoardVendorLeftPanelInfoList = responseData;
          if (message === "Success") {
            setQuickLinksLoading(false);
            if (responseData) {
              setDashBoardVendorLeftPanelInfoList(
                dashBoardVendorLeftPanelInfoList
              );
            } else {
              setDashBoardVendorLeftPanelInfoList();
            }
          } else {
            setDashBoardVendorLeftPanelInfoList();
          }
        })
        .catch((error) => {
          console.log(error);
          setQuickLinksLoading(false);
        });
    } catch (error) {
      console.log(error);
      setQuickLinksLoading(false);
    }
  };

  // Table API
  const handleGetRetailerAppointmentData = async (
    id,
    salesRep,
    tradeShowId,
    fromDate,
    toDate
  ) => {
    try {
      let inputData = {
        dealerId: id.toString(),
        salesRep: salesRep ? salesRep : "",
        tradeShowId: tradeShowId ? tradeShowId : "",
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        orderBy: "",
      };
      await AdminDashboardDataService.GetRetailerAppointmentData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var initialRecord = responseData[0];

          if (message === "Success") {
            if (responseData) {
              dispatch(setVendorAppointmentCount(responseData.length));

              if (initialCount == 0) {
                handleGetRightSideForAppointment(
                  initialRecord.retailerId,
                  initialRecord.vendorId,
                  initialRecord.id,
                  initialRecord.messageId
                );
                setUpdateSalesRepData({
                  vendorId: initialRecord.vendorId,
                  retailerID: initialRecord.retailerId,
                  msgID: initialRecord.messageId,
                  id: initialRecord.id,
                });
                setInitialCount(1);
              } else {
                handleGetRightSideForAppointment(
                  updateSalesRepData.retailerID,
                  updateSalesRepData.vendorId,
                  updateSalesRepData.id,
                  updateSalesRepData.msgID
                );
              }
            } else {
              setAppointmentCount(0);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // RightSide Data API
  const handleGetRightSideForAppointment = async (
    retailerID,
    vendorId,
    typeId,
    msgId
  ) => {
    try {
      let inputData = {
        dealerID: vendorId,
        retailerID: retailerID,
        typeId: 1,
        msgID: msgId,
      };
      await AdminDashboardDataService.GetRightSideForAppointment(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setInitialCount(1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetConversionCount = async (dealerId) => {
    try {
      //
      let inputData = {
        vendorId: dealerId,
      };
      await AdminDashboardDataService.GetConversionCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setConversationCommonCount(responseData);
              dispatch(setVendorConversationCount(responseData.allCount));
              dispatch(setVendorConversationCountSent(responseData.allSent));
              dispatch(
                setVendorConversationCountUnRead(responseData.allUnReadCount)
              );
            } else {
              setConversationCommonCount({});
              setConversionCount("0");
            }
          } else {
            setConversationCommonCount({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewMyRetailersLink = () => {
    dispatch(setChecklinkHeaderMenuData("Retailers"));
    dispatch(setSelectSideMenu(["jewelryRetailerPermission"]));
    navigate("/jewelryRetailerPermission");
  };
  const handleNavigateManageShows = () => {
    navigate("/manageCompanyShows");
  };

  return (
    <React.Fragment>
      {/* Only Dashboard Starts */}
      <div className="catalog__topnav">
        <ul>
          <li onClick={() => setpageNameType("Dashboard")}>
            {" "}
            <p
              className={
                pageNameType == "Dashboard"
                  ? "topNavLinks linkText"
                  : "topNavLinks"
              }
            >
              {traslate("Dashboard")}
            </p>
          </li>
          <li onClick={() => setpageNameType("ConversationsOnly")}>
            {" "}
            <p
              className={
                pageNameType == "ConversationsOnly"
                  ? "topNavLinks linkText"
                  : "topNavLinks"
              }
            >
              {traslate("Conversations")}{" "}
              <span>{conversionCount ? conversionCount : "0"}</span>
            </p>
          </li>
          {!(
            loginDetials.responseData.dealerMemberTypeName == "Vendor" ||
            loginDetials.responseData.dealerMemberTypeName == "VendorDiamond"
          ) && (
            <li>
              {" "}
              <p className="topNavLinks">
                {traslate("Vendor Requests")} <span>02</span>
              </p>
            </li>
          )}
          <li onClick={() => setpageNameType("AppointmentsOnly")}>
            {" "}
            <p
              className={
                pageNameType == "AppointmentsOnly"
                  ? "topNavLinks linkText"
                  : "topNavLinks"
              }
            >
              {traslate("Appointments")} <span>{appointmentCount}</span>
            </p>
          </li>
        </ul>
      </div>
      {pageNameType == "Dashboard" && (
        <div className="dashboard-only-section">
          <div
            className="dashboard-map"
            style={{ height: "auto", width: "100%", paddingTop: "40px" }}
          >
            <MyMapComponent
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAc6HbqpQfHx_SEUcGRHYiQ-tDOoNqtb0g&v=3.exp"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              loadVendorDashBoardList={loadVendorDashBoardList}
            />
          </div>
          <div className="col-md-12">
            <h3 className="subheading"> </h3>
          </div>

          <div className="col-md-12">
            <div className="vendors_section diamond__dealerdb">
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="quicklink__sections">
                    <h4 className="subheading"> {traslate("Quick Links")} </h4>
                    <ul>
                      <li>
                        <span
                          onClick={() => handleViewMyRetailersLink()}
                          className="linkText"
                        >
                          {traslate("View My Retailers")}
                        </span>
                      </li>
                      <li>
                        <Link to="/updateInventoryImport">
                          {traslate("Update Inventory")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/inviteYourRetailer">
                          {traslate("Invite A Retailer")}
                        </Link>
                      </li>
                      <li>
                        <span
                          className="linkText"
                          onClick={() => handleNavigateManageShows()}
                        >
                          {traslate("Manage Shows")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}

                {loginDetials?.responseData?.dealerMemberTypeName ===
                  "Vendor" && (
                  <div className="col-md-12">
                    <Spin spinning={QuickLinksLoading}>
                      <div className="quicklink__sections">
                        <h4 className="subheading"> {traslate("Ratings")}</h4>
                        <ul>
                          <li>
                            <StarRatings
                              rating={Number(
                                dashBoardVendorLeftPanelInfoList?.starRating ??
                                  0
                              )}
                              starRatedColor="#1883b8"
                              starDimension="20px"
                              starSpacing="0px"
                              numberOfStars={5}
                            />
                          </li>
                          <li>
                            The star Rating is based on 7 key areas. Image,
                            Product, Name, Product Description,
                            Categories/Collections, Attributes, Pricing, and
                            Last Update.
                          </li>
                          <li>
                            <span className="linkText">
                              <a
                                href="https://gemfind.zendesk.com/auth/v2/login/signin"
                                target="_blank"
                              >
                                For details on how you can improve your score,
                                please click here to view this document.
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Spin>
                  </div>
                )}

                {/* {loginDetials.responseData.dealerMemberTypeName ==
                  "VendorDiamond" && (
                  <div className="col-md-4">
                    <Spin spinning={QuickLinksLoading}>
                      <div className="quicklink__sections">
                        <h4 className="subheading"> {traslate("Details")} </h4>
                        <ul>
                          <li>
                            {" "}
                            {traslate("Last Updated")}:{" "}
                            {dashBoardVendorLeftPanelInfoList.lastUpdated}
                          </li>
                          <li>
                            {" "}
                            {traslate("Diamonds")}:{" "}
                            {dashBoardVendorLeftPanelInfoList.diamonds}
                          </li>
                          <li>
                            {" "}
                            {traslate("Retailers")}:{" "}
                            {dashBoardVendorLeftPanelInfoList.retailers}
                          </li>
                        </ul>
                      </div>
                    </Spin>
                  </div>
                )} */}

                {/* {loginDetials?.responseData?.dealerMemberTypeName ===
                  "VendorDiamond" && (
                  <div className="col-md-4">
                    <Spin spinning={QuickLinksLoading}>
                      <div className="quicklink__sections">
                        <h4 className="subheading">
                          {" "}
                          {traslate("Ratings")} 123
                        </h4>
                        <ul>
                          <li>
                            <StarRatings
                              rating={Number(
                                dashBoardVendorLeftPanelInfoList?.starRating ??
                                  0
                              )}
                              starRatedColor="#1883b8"
                              starDimension="20px"
                              starSpacing="0px"
                              numberOfStars={5}
                            />
                          </li>
                          <li>
                            The star Rating is based on 7 key areas. Image,
                            Product,Name, Product Description ,
                            Categories/collections, Attributes, Pricing, and
                            Last Update.
                          </li>
                          <li>
                            <span className="linkText">
                              <a
                                href="https://gemfind.zendesk.com/auth/v2/login/signin"
                                target="_new"
                              >
                                For details on how you can improve your score,
                                please click here to view this document.
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Spin>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* vendor */}
      {pageNameType == "ConversationsOnly" && (
        <ConversationsDashboard
          pageNameType={pageNameType}
          conversationCommonCount={conversationCommonCount}
        />
      )}

      {pageNameType == "AppointmentsOnly" && (
        <AppointmentsDashboard pageNameType={pageNameType} />
      )}
    </React.Fragment>
  );
};

export default DashboardVendor;
