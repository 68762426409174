import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
} from "antd";

import prodImg from "../../../assets/images/prod__img.jpg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut, Bar } from "react-chartjs-2";
import traslate from "../../../i18n/translate";

const GlobalJewerlyWishListReport = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  function onSearch(val) {}

  function onChange(e) {}

  const columns = [
    // {
    //   title: traslate("Product Image"),
    //   dataIndex: "ProductImage",
    //   width: 120
    // },
    {
      title: traslate("User IP Address"),
      dataIndex: "UserIPAddress",
      width: 120,
    },
    {
      title: traslate("Date"),
      dataIndex: "Date",
      width: 120,
    },
    {
      title: traslate("Shape"),
      dataIndex: "Shape",
      width: 120,
    },
    {
      title: traslate("Size"),
      dataIndex: "Size",
      width: 120,
    },
    {
      title: traslate("Color"),
      dataIndex: "Color",
      width: 120,
    },
    // {
    //   title: traslate("Date"),
    //   dataIndex: "Date",
    //   width: 120
    // },
    {
      title: traslate("Cut"),
      dataIndex: "Cut",
      width: 120,
    },
    {
      title: traslate("Clarity"),
      dataIndex: "Clarity",
      width: 120,
    },
    {
      title: traslate("Certification"),
      dataIndex: "Certification",
      width: 120,
    },
    {
      title: traslate("Retail Price"),
      dataIndex: "RetailPrice",
      width: 120,
    },
  ];

  const data = [
    {
      key: "1",
      // ProductImage: <span><img src={prodImg} className="product__img" /></span>,
      UserIPAddress: <span>175.100.179.26</span>,
      Date: <span>01/01/2022</span>,
      Shape: <span>Round</span>,
      Size: <span>12</span>,
      Color: <span>ABC</span>,
      // Date: <span>01/01/2022</span>,
      Cut: <span>F1626-6.5X4.5-18KW-1</span>,
      Clarity: <span>18K Gold</span>,
      Certification: <span>ABC</span>,
      RetailPrice: <span>Call For Price</span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            {/* <h4 className="workarea__heading">{traslate("Jewelry Category Report")}</h4> */}
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Wish List Report")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("Download CSV")}
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("From Date")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("To Date")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder="All "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {traslate("All")} </Option>
                <Option value="lucy"> {traslate("Mined Diamond")} </Option>
                <Option value="lucy">{traslate("Lab Grown Diamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-2">
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <b> {traslate("Total Jewelry Clicks")} : 32</b>
                <Tooltip
                  placement="right"
                  title={traslate(
                    "Displays the total number of jewelry clicks through the currently specified date range above"
                  )}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="chart__section">
              <Bar
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange",
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalJewerlyWishListReport;
