import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Modal,
  Spin,
} from "antd";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import { useLocation } from "react-router-dom";
// import prodImg from "../../assets/images/prod__img.jpg";
// import UnionIcon from "../../assets/images/icons/Union_5.svg";

import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import CompanyLocationService from "../../services/company-location.service";
import AdminToolsDataService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";

const CompanyLocation = () => {
  const { Option } = Select;
  const [dealerID, setDealerID] = useState("");
  const [locationDetails, setLocationDetails] = useState();
  const [UserLocationID, setUserLocationID] = useState("");
  // const [selectedLocation, setSelectedLocation] = useState({});
  // const [selectedLocation, setSelectedLocation] = useState({
  //   Address: "",
  //   City: "",
  //   Country: "",
  //   EmailID: "",
  //   Phone: "",
  //   State: "",
  //   Website: "",
  //   Zip: "",
  // });
  const [selectedLocation, setSelectedLocation] = useState({
    Address: "",
    City: "",
    Country: "",
    EmailID: "",
    Phone: "",
    State: "",
    Website: "",
    Zip: "",
  });
  const [selectedLocationTimingDD, setSelectedLocationTimingDD] = useState({});
  const [completeAddress, setCompleteAddress] = useState("");
  const [countryDD, setCountryDD] = useState([]);
  const [updatedLocationName, setUpdatedLocationName] = useState({});
  const [isHide, setIsHide] = useState({});
  const [initialFlag, setInitialFlag] = useState(true);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [newNameLocation, setNewNameLocation] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [delModalVisible, setDelModalVisible] = useState(false);
  const [QRModalVisible, setQRModalVisible] = useState(false);
  const [LocationDetailsLoader, setLocationDetailsLoader] = useState(true);
  const [qrCodeText, setQRCodeText] = useState("");
  const [qrCodeImg, setQRCodeImg] = useState("");
  const [countryListLoading, setCountryListLoading] = useState(false);
  const [validations, setValidations] = useState({
    LocationAddressVal: "",
    LocationCityVal: "",
    LocationStateVal: "",
    LocationZipVal: "",
    LocationCountryVal: "",
    LocationEmailVal: "",
    LocationPhoneVal: "",
    LocationWebsiteVal: "",
  });
  //const loginDetials = useSelector((state) => state.loginReducer);
  Geocode.setApiKey("AIzaSyAc6HbqpQfHx_SEUcGRHYiQ-tDOoNqtb0g");
  const morningDD = [
    "8 AM",
    "8:30 AM",
    "9 AM",
    "9:30 AM",
    "10 AM",
    "10:30 AM",
    "11 AM",
    "11:30 AM",
    "12 PM",
    "Closed",
  ];
  const morningMONTOFRIDD = [
    "8 AM",
    "8:30 AM",
    "9 AM",
    "9:30 AM",
    "10 AM",
    "10:30 AM",
    "11 AM",
    "11:30 AM",
    "Closed",
  ];
  const noonDD = [
    "12 PM",
    "12:30 PM",
    "1 PM",
    "1:30 PM",
    "2 PM",
    "2:30 PM",
    "3 PM",
    "3:30 PM",
    "4 PM",
    "4:30 PM",
    "5 PM",
    "5:30 PM",
    "6 PM",
    "6:30 PM",
    "7 PM",
    "7:30 PM",
    "8 PM",
    "8:30 PM",
    "9 PM",
    "9:30 PM",
    "10 PM",
    "10:30 PM",
  ];
  const location = useLocation();
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerID(loginDetials.loginDetials.responseData.dealerId);
          if (location.state) {
            if (location.state.id) {
              handleGetLocationDetails(location.state.id);
            }
          } else {
            handleGetLocationDetails(
              loginDetials.loginDetials.responseData.dealerId
            );
          }
          handleGetLocationDropdownDetailsByCountry();
        }
      }
    }
  }, []);

  const handleGetLocationDetails = (id) => {
    try {
      let inputData = {
        dealerID: id, //2948
      };
      setLocationDetailsLoader(true);
      //dealerID = id
      CompanyLocationService.GetLocationDetails(inputData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDetailsLoader(false);
          setLocationDetails(responseData);
          getLocationDataHandler(responseData.Table[0].LocationId, id);
          setInitialFlag(false);
          if (Object.keys(responseData.Table[0]).length !== 0)
            setCompleteAddress(
              `${
                typeof responseData.Table[0].Address !== "object"
                  ? responseData.Table[0].Address
                  : ""
              } ${
                typeof responseData.Table[0].City !== "object"
                  ? responseData.Table[0].City
                  : ""
              } ${
                typeof responseData.Table[0].Zip !== "object"
                  ? responseData.Table[0].Zip
                  : ""
              } ${
                typeof responseData.Table[0].Country !== "object"
                  ? responseData.Table[0].Country
                  : ""
              }`
            );

          Geocode.fromAddress(
            `${
              typeof responseData.Table[0].Address !== "object"
                ? responseData.Table[0].Address.length < 15
                  ? responseData.Table[0].Address +
                    " " +
                    responseData.Table[0].City +
                    " " +
                    responseData.Table[0].State +
                    " " +
                    responseData.Table[0].Zip +
                    " " +
                    responseData.Table[0].Country
                  : responseData.Table[0].Address
                : ""
            }`
          ).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              setLatitude(lat);
              setLongitude(lng);
            },
            (error) => {
              console.error(error);
            }
          );
          // setCountryDD(responseData.Country);
          responseData.Table.map((item, index) => {
            setIsHide((prevState) => ({
              ...prevState,
              [item.LocationId]: true,
            }));
          });
          setLocationDetailsLoader(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleGetLocationDropdownDetailsByCountry = () => {
    try {
      let inputData = {
        id: 0,
        dropdownType: "Country",
      };
      setCountryListLoading(true);
      AdminToolsDataService.GetLocationDropdownDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setCountryDD(responseData);
              setCountryListLoading(false);
            } else {
              setCountryDD([]);
              setCountryListLoading(false);
            }
          } else {
            setCountryDD([]);
            setCountryListLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCountryListLoading(false);
        });
    } catch (error) {
      console.log(error);
      setCountryListLoading(false);
    }
  };
  const selectLocationHandler = (id) => {
    if (locationDetails !== undefined) {
      locationDetails.Table.map((item, index) => {
        if (item.LocationId === id) {
          setSelectedLocation(item);
        }
      });
    }
  };
  const formChangeHandler = (e, name, validationName) => {
    let value = "";

    if (name === "Country") {
      value = e;
    } else {
      value = e.target.value;
    }

    if (name === "Address") {
      setValidations((prevState) => ({
        ...prevState,
        LocationAddressVal: "",
      }));
      setCompleteAddress(value);
    } else {
      if (name == "City") {
        setValidations((prevState) => ({
          ...prevState,
          LocationCityVal: "",
        }));
      }
      if (name == "State") {
        setValidations((prevState) => ({
          ...prevState,
          LocationStateVal: "",
        }));
      }
      if (name == "Zip") {
        setValidations((prevState) => ({
          ...prevState,
          LocationZipVal: "",
        }));
      }
      if (name == "Country") {
        setValidations((prevState) => ({
          ...prevState,
          LocationCountryVal: "",
        }));
      }
      if (name == "EmailID") {
        setValidations((prevState) => ({
          ...prevState,
          LocationEmailVal: "",
        }));
      }
      if (name == "Phone") {
        setValidations((prevState) => ({
          ...prevState,
          LocationPhoneVal: "",
        }));
      }
      if (name == "Website") {
        setValidations((prevState) => ({
          ...prevState,
          LocationWebsiteVal: "",
        }));
      }
      setSelectedLocation((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const locationNameChangeHandler = (e, id) => {
    const { value } = e.target;
    setUpdatedLocationName((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const getLocationDataHandler = (locationid, dId) => {
    setValidations({
      LocationAddressVal: "",
      LocationCityVal: "",
      LocationStateVal: "",
      LocationZipVal: "",
      LocationCountryVal: "",
      LocationEmailVal: "",
      LocationPhoneVal: "",
      LocationWebsiteVal: "",
    });
    try {
      selectLocationHandler(locationid);
      let inputData = {
        dealerID: dId, //2948
        locationID: locationid,
      };
      setCompleteAddress("");
      CompanyLocationService.GetLocationData(inputData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          Geocode.fromAddress(
            `${
              typeof responseData.Table[0].address !== "object"
                ? responseData.Table[0].address
                : ""
            }`
          ).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              setLatitude(lat);
              setLongitude(lng);
            },
            (error) => {
              console.error(error);
            }
          );

          if (Object.keys(responseData).length !== 0) {
            if (responseData.Table.length !== 0) {
              setSelectedLocation({
                Address: responseData.Table[0].address,
                City: responseData.Table[0].city,
                Country: responseData.Table[0].countryName,
                EmailID: responseData.Table[0].emailID,
                Phone: responseData.Table[0].phone,
                State: responseData.Table[0].state,
                Website: responseData.Table[0].website,
                Zip: responseData.Table[0].zip,
              });
              setCompleteAddress(responseData.Table[0].address);
              setUserLocationID(responseData.Table[0].locationId);
              setQRCodeImg(responseData.Table[0].qrCodeImagepath);
            }
            if (responseData.Table1.length !== 0) {
              setSelectedLocationTimingDD(responseData.Table1[0]);
            } else {
              setSelectedLocationTimingDD({});
            }
          }
        } else {
          setSelectedLocationTimingDD({});
          setCompleteAddress("");
          setUserLocationID("");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const updateLocationNameHandler = (id) => {
    try {
      let inputData = {
        dealerID: dealerID, //2948,
        locationID: id,
        locationName: updatedLocationName[id],
      };
      CompanyLocationService.UpdateLocationName(inputData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          handleGetLocationDetails(dealerID);
          NotificationManager.success("Location Updated Successfully.");
        } else {
          NotificationManager.error("Location Update Failed.");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const updateTimeDD = (e, name) => {
    var e1 = "";
    var e2 = "";
    var name1 = "";
    var name2 = "";
    var name3 = "";
    if (name === "sundayStart" || name === "sundayEnd") {
      name1 = "storeClosedSun";
      name2 = "sundayStart";
      name3 = "sundayEnd";
    } else if (name === "mondayStart" || name === "mondayEnd") {
      name1 = "storeClosedMon";
      name2 = "mondayStart";
      name3 = "mondayEnd";
    } else if (name === "tuesdayStart" || name === "tuesdayEnd") {
      name1 = "storeClosedTue";
      name2 = "tuesdayStart";
      name3 = "tuesdayEnd";
    } else if (name === "wednesdayStart" || name === "wednesdayEnd") {
      name1 = "storeClosedWed";
      name2 = "wednesdayStart";
      name3 = "wednesdayEnd";
    } else if (name === "thursdayStart" || name === "thursdayEnd") {
      name1 = "storeClosedThu";
      name2 = "thursdayStart";
      name3 = "thursdayEnd";
    } else if (name === "fridayStart" || name === "fridayEnd") {
      name1 = "storeClosedFri";
      name2 = "fridayStart";
      name3 = "fridayEnd";
    } else if (name === "saturdayStart" || name === "saturdayEnd") {
      name1 = "storeClosedSat";
      name2 = "saturdayStart";
      name3 = "saturdayEnd";
    }
    if (e === "Closed") {
      e1 = "Yes";
      e2 = "";
      e = "";
      setSelectedLocationTimingDD((prevState) => ({
        ...prevState,
        [name1]: e1,
        [name2]: e,
        [name3]: e2,
      }));
    } else {
      e1 = "";
      setSelectedLocationTimingDD((prevState) => ({
        ...prevState,
        [name1]: e1,
        [name]: e,
      }));
    }
  };
  const updateLocationHandler = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      if (
        selectedLocation.City !== "" &&
        selectedLocation.State !== "" &&
        selectedLocation.Zip !== "" &&
        selectedLocation.Country !== ""
      ) {
        let inputData = {
          txtAddress: completeAddress,
          txtCity: selectedLocation.City,
          txtState: selectedLocation.State,
          txtZip: selectedLocation.Zip,
          drpcountry: selectedLocation.Country.toString(),
          txtPhone: selectedLocation.Phone,
          txtEmail: selectedLocation.EmailID,
          ddlStartSunday:
            selectedLocationTimingDD.storeClosedSun === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.sundayStart,
          ddlEndSunday:
            selectedLocationTimingDD.storeClosedSun === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.sundayEnd,
          ddlMondayStart:
            selectedLocationTimingDD.storeClosedMon === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.mondayStart,
          ddlMondayEnd:
            selectedLocationTimingDD.storeClosedMon === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.mondayEnd,
          ddlTuesdayStart:
            selectedLocationTimingDD.storeClosedTue === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.tuesdayStart,
          ddlTuesdayEnd:
            selectedLocationTimingDD.storeClosedTue === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.tuesdayEnd,
          ddlWednesdayStart:
            selectedLocationTimingDD.storeClosedWed === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.wednesdayStart,
          ddlWednesdayEnd:
            selectedLocationTimingDD.storeClosedWed === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.wednesdayEnd,
          ddlThursdayStart:
            selectedLocationTimingDD.storeClosedThu === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.thursdayStart,
          ddlThursdayEnd:
            selectedLocationTimingDD.storeClosedThu === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.thursdayEnd,
          ddlFridayStart:
            selectedLocationTimingDD.storeClosedFri === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.fridayStart,
          ddlFridayEnd:
            selectedLocationTimingDD.storeClosedFri === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.fridayEnd,
          ddlSaturdayStart:
            selectedLocationTimingDD.storeClosedSat === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.saturdayStart,
          ddlSaturdayEnd:
            selectedLocationTimingDD.storeClosedSat === "Yes"
              ? "Closed"
              : selectedLocationTimingDD.saturdayEnd,
          txtWebsite: selectedLocation.Website,
          dealerID: dealerID.toString(),
          // locationID: selectedLocation.LocationId.toString(),
          locationID: UserLocationID ? UserLocationID.toString() : "",
        };
        CompanyLocationService.UpdateLocation(inputData).then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success("Location Updated Successfully.");
            // handleGetLocationDetails(dealerID);
            getLocationDataHandler(UserLocationID, dealerID);
          } else {
            NotificationManager.error("Location Update Failed.");
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const MyMapComponent = useMemo(
    () =>
      withScriptjs(
        withGoogleMap((props) => {
          return (
            <GoogleMap
              key={"AIzaSyAc6HbqpQfHx_SEUcGRHYiQ-tDOoNqtb0g"}
              defaultZoom={5}
              defaultCenter={{
                lat: Number(latitude === "" ? 37.717564 : latitude),
                lng: Number(longitude === "" ? -88.18988 : longitude),
              }}
            >
              <React.Fragment>
                <Marker
                  position={{
                    lat: Number(latitude === "" ? 37.717564 : latitude),
                    lng: Number(longitude === "" ? -88.18988 : longitude),
                  }}
                  title={"DealerCompany" + "::" + "FullAddress"}
                ></Marker>
              </React.Fragment>
            </GoogleMap>
          );
        })
      ),
    [latitude, longitude]
  );
  const onNewNameChaneHandler = (e) => {
    let { value } = e.target;
    setNewNameLocation(value);
  };
  const newLocationHandler = () => {
    try {
      let inputData = {
        txtAddLocation: newNameLocation,
        DealerID: dealerID.toString(),
      };
      CompanyLocationService.AddNewLocation(inputData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          handleGetLocationDetails(dealerID);
          NotificationManager.success("New Location Added Successfully.");
          setNewNameLocation("");
        } else {
          NotificationManager.error("New Location Adding Failed.");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const deleteLocationDataHandler = () => {
    try {
      // selectLocationHandler(locationid);
      let inputData = {
        dealerID: dealerID ? dealerID.toString() : "", //2948
        locationID: UserLocationID ? UserLocationID : "",
      };
      // setCompleteAddress("");
      CompanyLocationService.DeleteLocation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success("Location Deleted Successfully.");
            handleGetLocationDetails(dealerID);
          } else {
            NotificationManager.error("Location Delete Failed.");
          }
        })
        .catch((error) => {
          NotificationManager.error("Location Delete Failed.");
        });
    } catch (e) {
      console.log(e);
    }
    setDelModalVisible(false);
  };
  const updateQRCodeHandler = () => {
    try {
      // selectLocationHandler(locationid);
      let inputData = {
        dealerID: dealerID ? dealerID.toString() : "", //2948
        locationID: UserLocationID ? UserLocationID : "",
        txtQRDetails: qrCodeText ? qrCodeText : "",
      };
      // setCompleteAddress("");
      CompanyLocationService.QRCodeTextUpdate(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success("QR Code Generated");
            setQRCodeText("");
            handleGetLocationDetails(dealerID);
          } else {
            NotificationManager.error("QR Code Generate Failed.");
          }
        })
        .catch((error) => {
          NotificationManager.error("QR Code Generate Failed.");
        });
    } catch (e) {
      console.log(e);
    }
    setQRModalVisible(false);
  };
  const handleDeleteUser = (e) => {
    setDelModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setDelModalVisible(false);
  };
  const handleQRCOdeGenerator = () => {
    setQRModalVisible(true);
    setQRCodeText("");
  };
  const handleCloseQRCOdeGenerator = () => {
    setQRModalVisible(false);
  };

  const handleValidation = () => {
    const validations = {};
    var validEmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    if (!completeAddress) {
      isValid = false;
      validations.LocationAddressVal = "Address is compulsory";
    } else {
      validations.LocationAddressVal = "";
    }
    if (!selectedLocation.City) {
      isValid = false;
      validations.LocationCityVal = traslate("City is compulsory");
    } else {
      validations.LocationCityVal = "";
    }
    if (!selectedLocation.State) {
      isValid = false;
      validations.LocationStateVal = traslate("State is compulsory");
    } else {
      validations.LocationStateVal = "";
    }
    if (!selectedLocation.Zip) {
      isValid = false;
      validations.LocationZipVal = traslate("Zip is compulsory");
    } else {
      validations.LocationZipVal = "";
    }
    if (!selectedLocation.Country) {
      isValid = false;
      validations.LocationCountryVal = traslate("Country is compulsory");
    } else {
      validations.LocationCountryVal = "";
    }
    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!selectedLocation.EmailID) {
      isValid = false;
      validations.LocationEmailVal = traslate("Email is compulsory");
    } else if (!selectedLocation.EmailID.match(validRegex)) {
      isValid = false;
      validations.LocationEmailVal = traslate("Email address is Invalid");
    } else {
      validations.LocationEmailVal = "";
    }
    if (!selectedLocation.Phone) {
      isValid = false;
      validations.LocationPhoneVal = traslate("Phone is compulsory");
    } else if (
      (selectedLocation.Phone.length < 15 &&
        selectedLocation.Phone.includes("-") &&
        /^[0-9]+$/.test(selectedLocation.Phone)) ||
      (selectedLocation.Phone.length < 15 &&
        selectedLocation.Phone.includes("+") &&
        /^[0-9]+$/.test(selectedLocation.Phone))
    ) {
      validations.LocationPhoneVal = "";
    } else if (
      /^0*$/.test(selectedLocation.Phone) ||
      /[a-zA-Z@{}#$%?/^*&]/g.test(selectedLocation.Phone)
    ) {
      isValid = false;
      validations.LocationPhoneVal = traslate("Phone number is invalid");
    } else if (
      selectedLocation.Phone.length > 15 ||
      selectedLocation.Phone.length < 10
    ) {
      isValid = false;
      validations.LocationPhoneVal = traslate("Phone number is invalid");
    } else {
      validations.LocationPhoneVal = "";
    }
    // if (!selectedLocation.Website) {
    //   isValid = false;
    //   validations.LocationWebsiteVal = traslate("Website is compulsory");
    // } else {
    //   validations.LocationWebsiteVal = "";
    // }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping permission__section">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {" "}
                {traslate("Locations")}{" "}
              </h4>
            </div>
          </div>

          <div className="col-md-12 mb-5">
            <div className="map__section">
              {/* <p> {traslate("Add Map Here")} </p> */}
              <MyMapComponent
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAc6HbqpQfHx_SEUcGRHYiQ-tDOoNqtb0g&v=3.exp"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `300px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block mb-0">
              <label> {traslate("Locations")} </label>
              <div className="row">
                <div className="col-9">
                  <input
                    type="text"
                    onChange={(e) => onNewNameChaneHandler(e)}
                    value={newNameLocation}
                  />
                </div>
                <div className="col-3">
                  <button className="primary-btn">
                    <i
                      className="fa fa-plus m-0"
                      onClick={newLocationHandler}
                    ></i>
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </div>

            <div className="location-box">
              <Spin spinning={LocationDetailsLoader}>
                {locationDetails &&
                  Object.keys(locationDetails).length > 0 &&
                  locationDetails.Table.map((item, index) => {
                    return (
                      <>
                        <div className="location__blocks" key={item.LocationId}>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="location__details">
                                <label
                                  // className={`active pe-auto ${selectedLocation.LocationId ===
                                  //     item.LocationId
                                  //     ? "linkText"
                                  //     : ""
                                  //   }`}
                                  className={`active pe-auto ${
                                    (selectedLocation.LocationId ||
                                      UserLocationID) == item.LocationId
                                      ? "linkText"
                                      : "pointer"
                                  }`}
                                  onClick={() =>
                                    getLocationDataHandler(
                                      item.LocationId,
                                      dealerID
                                    )
                                  }
                                >
                                  {" "}
                                  {item.LocationName}{" "}
                                </label>
                                <div className="action__btns">
                                  <div className="image__block">
                                    <img
                                      src={Edit_icon}
                                      alt=""
                                      onClick={() =>
                                        setIsHide((prevState) => ({
                                          ...prevState,
                                          [item.LocationId]:
                                            !isHide[item.LocationId],
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row my-3 ${
                              isHide[item.LocationId] ? "d-none" : ""
                            }`}
                          >
                            <div className="col-9">
                              <div className="input__block mb-0">
                                <input
                                  type="text"
                                  value={
                                    Object.keys(updatedLocationName).length !==
                                      0 &&
                                    updatedLocationName[item.LocationId] !==
                                      undefined
                                      ? updatedLocationName[item.LocationId]
                                      : item.LocationName
                                  }
                                  //value={item.LocationName}
                                  onChange={(e) =>
                                    locationNameChangeHandler(
                                      e,
                                      item.LocationId
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-3 topspace text-right">
                              <span
                                className="linkText d-block"
                                onClick={() => {
                                  setIsHide((prevState) => ({
                                    ...prevState,
                                    [item.LocationId]: !isHide[item.LocationId],
                                  }));

                                  setUpdatedLocationName((prevState) => ({
                                    ...prevState,
                                    [item.LocationId]: item.LocationName,
                                  }));
                                }}
                              >
                                {" "}
                                {traslate("Cancel")}{" "}
                              </span>
                              <span
                                className="linkText d-block"
                                onClick={() =>
                                  updateLocationNameHandler(item.LocationId)
                                }
                              >
                                {" "}
                                {traslate("Update")}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Spin>
            </div>
            {/* <div className="location__blocks">
              <div className="row">
                  <div className="col-md-12">
                      <div className="location__details">
                        <label className="active"> {traslate("Location 1")} </label>
                        <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                alt=""
                              />
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div className="row d-none">
                  <div className="col-9">
                    <div className="input__block mb-0">
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-3 topspace">
                      <span className="linkText d-block"> {traslate("Cancel")} </span>
                      <span className="linkText d-block"> {traslate("Update")} </span>
                  </div>
                </div>
            </div> */}
          </div>

          <div className="col-md-6 form__section">
            <div className="row">
              <div className="col-md-12">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Address")}
                    <span className="mandatory">*</span>
                  </label>
                  <textarea
                    className={
                      !completeAddress && validations.LocationAddressVal
                        ? "border__red"
                        : ""
                    }
                    value={completeAddress}
                    onChange={(e) =>
                      formChangeHandler(e, "Address", "LocationAddressVal")
                    }
                  ></textarea>
                  {!completeAddress && validations.LocationAddressVal ? (
                    <div className="text-danger">
                      {validations.LocationAddressVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("City")}
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    // className={validations.LocationCityVal ?
                    //   "border__red" :
                    //   ""
                    // }
                    className={
                      (!selectedLocation.City ||
                        Object.keys(selectedLocation.City).length == 0) &&
                      validations.LocationCityVal
                        ? "border__red"
                        : ""
                    }
                    value={
                      typeof selectedLocation.City !== "object"
                        ? selectedLocation.City
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "City", "LocationCityVal")
                    }
                  />
                  {validations.LocationCityVal ? (
                    <div className="text-danger">
                      {validations.LocationCityVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("State")}
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      typeof selectedLocation.State !== "object"
                        ? selectedLocation.State
                        : ""
                    }
                    className={
                      (!selectedLocation.State ||
                        Object.keys(selectedLocation.State).length == 0) &&
                      validations.LocationStateVal
                        ? "border__red"
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "State", "LocationStateVal")
                    }
                  />
                  {validations.LocationStateVal ? (
                    <div className="text-danger">
                      {validations.LocationStateVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Zip")}
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (!selectedLocation.Zip ||
                        Object.keys(selectedLocation.Zip).length == 0) &&
                      validations.LocationZipVal
                        ? "border__red"
                        : ""
                    }
                    value={
                      typeof selectedLocation.Zip !== "object"
                        ? selectedLocation.Zip
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "Zip", "LocationZipVal")
                    }
                  />
                  {validations.LocationZipVal ? (
                    <div className="text-danger">
                      {validations.LocationZipVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Country")}
                    <span className="mandatory">*</span>
                  </label>
                  <Select
                    showSearch
                    className={
                      (!selectedLocation.Country ||
                        Object.keys(selectedLocation.Country).length == 0) &&
                      validations.LocationCountryVal
                        ? "border__red"
                        : "border__grey"
                    }
                    // className="border__grey"
                    placeholder=""
                    optionFilterProp="children"
                    // value={
                    //   typeof selectedLocation.Country !== "object"
                    //     ? selectedLocation.Country
                    //     : ""
                    // }
                    value={
                      typeof selectedLocation.Country !== "object"
                        ? selectedLocation.Country
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "Country", "LocationCountryVal")
                    }
                  >
                    {countryDD.length > 0 &&
                      countryDD.map((item, index) => {
                        return (
                          <Option key={item.id} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                  {validations.LocationCountryVal ? (
                    <div className="text-danger">
                      {validations.LocationCountryVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Location E-Mail")}
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    type="email"
                    className={
                      (!selectedLocation.EmailID ||
                        Object.keys(selectedLocation.EmailID).length == 0) &&
                      validations.LocationEmailVal
                        ? "border__red"
                        : ""
                    }
                    value={
                      typeof selectedLocation.EmailID !== "object"
                        ? selectedLocation.EmailID
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "EmailID", "LocationEmailVal")
                    }
                  />
                  {validations.LocationEmailVal ? (
                    <div className="text-danger">
                      {validations.LocationEmailVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Location Phone")}
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (!selectedLocation.Phone ||
                        Object.keys(selectedLocation.Phone).length == 0) &&
                      validations.LocationPhoneVal
                        ? "border__red"
                        : ""
                    }
                    value={
                      typeof selectedLocation.Phone !== "object"
                        ? selectedLocation.Phone
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "Phone", "LocationPhoneVal")
                    }
                  />
                  {validations.LocationPhoneVal ? (
                    <div className="text-danger">
                      {validations.LocationPhoneVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Website")}
                    {/* <span className="mandatory">*</span> */}
                  </label>
                  <input
                    type="text"
                    className={
                      (!selectedLocation.Website ||
                        Object.keys(selectedLocation.Website).length == 0) &&
                      validations.LocationWebsiteVal
                        ? "border__red"
                        : ""
                    }
                    value={
                      typeof selectedLocation.Website !== "object"
                        ? selectedLocation.Website
                        : ""
                    }
                    onChange={(e) =>
                      formChangeHandler(e, "Website", "LocationWebsiteVal")
                    }
                  />
                  {validations.LocationWebsiteVal ? (
                    <div className="text-danger">
                      {validations.LocationWebsiteVal}
                    </div>
                  ) : null}
                </div>
              </div>

              <div class="col-md-12">
                <button
                  type="button"
                  class="primary-btn mr-2"
                  onClick={() => updateLocationHandler()}
                >
                  {" "}
                  {traslate("Update Location")}{" "}
                </button>
                <button
                  type="button"
                  className="primary-btn"
                  onClick={() => handleDeleteUser()}
                >
                  {" "}
                  {traslate("Delete")}{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="right__section">
              <div className="row m-0">
                <div className="col-md-12 p-0">
                  <div className="input__block">
                    <label> {traslate("Hours of Operation")} </label>
                  </div>
                </div>
                <div className="col-md-12 p-0 hour__operation">
                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Sun")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedSun === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.sundayStart !== null
                          ? selectedLocationTimingDD.sundayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "sundayStart")}
                    >
                      {morningDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedSun !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedSun !== "Yes"
                            ? selectedLocationTimingDD.sundayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "sundayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>

                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Mon")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedMon === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.mondayStart !== null
                          ? selectedLocationTimingDD.mondayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "mondayStart")}
                    >
                      {morningMONTOFRIDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedMon !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedMon !== "Yes"
                            ? selectedLocationTimingDD.mondayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "mondayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>

                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Tues")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedTue === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.tuesdayStart !== null
                          ? selectedLocationTimingDD.tuesdayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "tuesdayStart")}
                    >
                      {morningMONTOFRIDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedTue !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedTue !== "Yes"
                            ? selectedLocationTimingDD.tuesdayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "tuesdayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>

                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Wed")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedWed === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.wednesdayStart !== null
                          ? selectedLocationTimingDD.wednesdayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "wednesdayStart")}
                    >
                      {morningMONTOFRIDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedWed !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedWed !== "Yes"
                            ? selectedLocationTimingDD.wednesdayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "wednesdayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>

                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Thu")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedThu === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.thursdayStart !== null
                          ? selectedLocationTimingDD.thursdayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "thursdayStart")}
                    >
                      {morningMONTOFRIDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedThu !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedThu !== "Yes"
                            ? selectedLocationTimingDD.thursdayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "thursdayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Fri")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedFri === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.fridayStart !== null
                          ? selectedLocationTimingDD.fridayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "fridayStart")}
                    >
                      {morningMONTOFRIDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedFri !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedFri !== "Yes"
                            ? selectedLocationTimingDD.fridayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "fridayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>

                  <div className="d-flex input__block">
                    <label className="mr-2"> {traslate("Sat")} </label>
                    <Select
                      className="border__grey mr-2"
                      placeholder="--"
                      optionFilterProp="children"
                      value={
                        Object.keys(selectedLocationTimingDD).length !== 0 &&
                        selectedLocationTimingDD.storeClosedSat === "Yes"
                          ? "Closed"
                          : selectedLocationTimingDD.saturdayStart !== null
                          ? selectedLocationTimingDD.saturdayStart
                          : ""
                      }
                      onChange={(e) => updateTimeDD(e, "saturdayStart")}
                    >
                      {morningDD.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                    {selectedLocationTimingDD.storeClosedSat !== "Yes" && (
                      <Select
                        className="border__grey"
                        placeholder="--"
                        optionFilterProp="children"
                        value={
                          selectedLocationTimingDD.storeClosedSat !== "Yes"
                            ? selectedLocationTimingDD.saturdayEnd
                            : ""
                        }
                        onChange={(e) => updateTimeDD(e, "saturdayEnd")}
                      >
                        {noonDD.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>

                  <hr />
                </div>

                {/* <div className="col-md-12 p-0">
                  <div className="input__block">
                    <label> {traslate("Location QR Code")} </label>
                  </div>
                  <div className="input__block">
                    <div className="image_block border-0 p-0">
                    </div>
                    <p className="text-center linkText mt-2" onClick={() => handleQRCOdeGenerator()}>
                      {" "}
                      {traslate("QR Code Generator")}{" "}
                    </p>
                    <div className="qr-img-section">
                      <img src={qrCodeImg} alt="" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <Modal
              title={traslate("Delete")}
              className="modalconsupld__section"
              centered
              visible={delModalVisible}
              onCancel={handleDeleteCancel}
              width={400}
              footer={[
                <Button key="back" onClick={handleDeleteCancel}>
                  {traslate("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={deleteLocationDataHandler}
                >
                  {traslate("Delete")}
                </Button>,
              ]}
            >
              <div className="col-lg-12">
                <div className="modal__body">
                  <div className="col-lg-12">
                    <div className="form__fields border-0 p-0">
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <p>
                            {traslate(
                              "Are you sure you want to delete this LOCATION ?"
                            )}{" "}
                          </p>
                          <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="col-md-12">
            <Modal
              title={traslate("Upload Text")}
              className="modalconsupld__section"
              centered
              visible={QRModalVisible}
              onCancel={handleCloseQRCOdeGenerator}
              width={400}
              footer={[
                <Button key="back" onClick={handleCloseQRCOdeGenerator}>
                  {traslate("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={updateQRCodeHandler}
                >
                  {traslate("Update")}
                </Button>,
              ]}
            >
              <div className="col-lg-12">
                <div className="modal__body">
                  <div className="col-lg-12">
                    <div className="form__fields border-0 p-0">
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <p>
                            <textarea
                              onChange={(e) => setQRCodeText(e.target.value)}
                            ></textarea>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyLocation;
