import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";

const FTPCredential = (props) => {
  const [ftpData, setFtpData] = useState({
    locationData: "",
    userName: "",
    password: "",
    id: "",
  });

  const { locationData, userName, password } = ftpData;
  const loginDetials = useSelector((state) => state.loginReducer);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleMangeFtpDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, [props]);

  const handleMangeFtpDetails = (Id) => {
    try {
      let inputData = {
        dealerID: Id,
        type: props.type,
      };
      setloading(true);
      CatalogDiamondDataService.MangeFtpDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData && responseData.length > 0) {
              setloading(false);
              setFtpData({
                locationData: responseData[0].location,
                userName: responseData[0].userName,
                password: responseData[0].password,
                id: responseData[0].id,
              });
            } else {
              setFtpData({
                locationData: "",
                userName: "",
                password: "",
                id: "",
              });
              setloading(false);
            }
          } else {
            setFtpData({
              locationData: "",
              userName: "",
              password: "",
              id: "",
            });
            setloading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  /* Custom Binding Ends */
  return (
    <div className="prodetail__section">
      <div className="form__fields">
        <div className="col-md-12">
          <div className="prod__maindiv data__maping diamond__mapping">
            <Spin spinning={loading} size="large">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {traslate("Manage FTP Credential")}{" "}
                  </h4>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>{traslate("FTP Location")}</label>
                    <input
                      type="text"
                      placeholder="Enter FTP Location"
                      name="locationData"
                      value={locationData}
                    />
                  </div>

                  <div className="input__block">
                    <label>{traslate("FTP User Name")}</label>
                    <input
                      type="text"
                      placeholder="Enter FTP User Name"
                      name="userName"
                      value={userName}
                    />
                  </div>

                  <div className="input__block">
                    <label>{traslate("FTP User Password")}</label>
                    <input
                      type="text"
                      placeholder="Enter FTP User Password"
                      name="password"
                      autoComplete="new-password"
                      value={password}
                    />
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FTPCredential;
