import React from "react";
import UploadProductFile from "../../Catlog/UploadProductFile";

const UploadAdminProductFile = () => {
  return (
    <React.Fragment>
      <UploadProductFile />
    </React.Fragment>
  );
};

export default UploadAdminProductFile;
