import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import AppsDiamondLinksService from "../../../services/apps-diamondlinks.service";
import { URLToDownloadFile } from "../../../helper/commanFunction";
import config from "../../../helper/config";

const RingBuilderApiEndPoint = (props) => {
  const [dealerId, setDealerId] = useState();
  const [data, setData] = useState({
    genAPI: "",
  });
  const [loading, setLoading] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetDiamondLinkApiEndIntegrationDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleGetDiamondLinkApiEndIntegrationDetails = (id) => {
    let inputData = {
      dealerID: id,
      type: props.type || "Jewelry",
    };
    setLoading(true);
    try {
      AppsDiamondLinksService.GetDiamondLinkApiEndIntegrationDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];

          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setData({
                genAPI: responseData.accessToken,
              });
            }else{
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCreateApiKey = () => {
    try {
      let inputData = {
        dealerID: dealerId,
        type: props.type || "Jewelry",
        accessToken: "",
      };
      setLoading(true);
      AppsDiamondLinksService.CreateApiKey(inputData)
        .then((response) => {
          let message = response.data.message;
          setLoading(true);
          if (message === "Success") {
            handleGetDiamondLinkApiEndIntegrationDetails(dealerId);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleDownloadAPIDocument = () => {
    URLToDownloadFile(
      config.BlobURL + "gemfind2staging/StaticFiles/Apps/GemfindWebApi.docx",
      "GemfindWebApi.docx"
    );
  };
  return (
      <div className="form__fields apiendpoint">
        <div className="row">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0 mb-3">
              {traslate("API Endpoint and Authentication")}
            </h4>
          </div>
          <Spin spinning={loading} size="large">
            <div className="col-md-6">
              <div className="input__block">
                <label> {traslate("Generate API Key to get data")} </label>
                <input
                  type="text"
                  name="genAPI"
                  value={data.genAPI ? data.genAPI : ""}
                />
              </div>
              <div className="button__block">
                <button
                  onClick={handleCreateApiKey}
                  className="primary-btn mr-2 mobile__bottomspace"
                >
                  {" "}
                  {traslate("Generate")}{" "}
                </button>
                <button
                  className="primary-btn"
                  onClick={handleDownloadAPIDocument}
                >
                  {" "}
                  {traslate("API Documentation")}{" "}
                </button>
              </div>
            </div>
          </Spin>
        </div>
      </div>
  );
};

export default RingBuilderApiEndPoint;
