import React, { useState, useEffect, useRef } from "react";
import { Select, Table, Spin } from "antd";
import { Chart } from "chart.js";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import traslate from "../../i18n/translate";
import ReportsDiamondService from "../../services/reports-Diamond.service";
import GlobalDiamondReportService from "../../services/reports-globalDiamond-service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

Chart.register(ChartDataLabels);
const DiamondColorIndComp = () => {
  const DiamondColorChartRef = useRef();
  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  /*Initial State for Binding Ends*/
  /*React Binding Starts*/
  const [TableForAllList, setTableForAllList] = useState([]);
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TableForAllLoading, setTableForAllLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondColorSearchesLabels, setdiamondColorSearchesLabels] = useState(
    []
  );
  const [DiamondColorSearchesList, setDiamondColorSearchesList] = useState([]);
  const [DiamondColorYAxisList, setDiamondColorYAxisList] = useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [TableShapeValue, setTableShapeValue] = useState("");
  const [TableClarityValue, setTableClarityValue] = useState("");
  const [TableCutGradeValue, setTableCutGradeValue] = useState("");
  const [TableColorValue, setTableColorValue] = useState("");
  const [TableSizeValue, setTableSizeValue] = useState("");
  const [TableCertificateValue, setTableCertificateValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortType, setSortType] = useState("desc");
  const [sortColumnName, setSortColumnName] = useState("GT.MODIFIED");
  const [key, setKey] = useState(true);
  const [tableKey, setTableKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (TableColorValue) {
      setTableKey(!tableKey);
      setPageSize(10);
      handleGetDiamondReportForTableChartAll(dealerId, forDealer);
    }
  }, [TableColorValue]);

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    let inputData = {
      dealerID: id,
    };
    GlobalDiamondReportService.CheckDealermembers(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var forDealer = responseData.forDealer;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setForDealer(forDealer);
          } else {
            setForDealer("");
          }
          handleLoadChartReports(id, forDealer);
          handleGetDiamondReportForTableChartAll(
            id,
            forDealer,
            pageNo,
            pageSize,
            sortColumnName,
            sortType
          );
        } else {
          setForDealer("");
        }
      })
      .catch((error) => {
        setForDealer("");
      });
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      type: "",
      reportName: "Color",
    };
    setLoadChartReportsLoading(true);
    GlobalDiamondReportService.LoadChartReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var DiamondColorSearchesList = responseData.DiamondColorSearches;
        var TotalDiamondClicks =
          responseData.TotalDiamondClicks[0].TotalClickCnt;
        setLoadChartReportsLoading(false);
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setTotalDiamondClicks(TotalDiamondClicks);
            setDiamondColorSearchesList(DiamondColorSearchesList);
            var DiamondColorSearchesLabelArr = DiamondColorSearchesList.map(
              (item) => {
                return item.Color;
              }
            );
            setdiamondColorSearchesLabels(DiamondColorSearchesLabelArr);
            var DiamondColorYAxisList = DiamondColorSearchesList.map((item) => {
              return item.CNT;
            });
            setDiamondColorYAxisList(DiamondColorYAxisList);
            var totalValueForPercentage = DiamondColorYAxisList.reduce(
              (a, b) => a + b,
              0
            );
            setTotalValueForPercentage(totalValueForPercentage);
            setKey(!key);
          }
        } else {
          setTotalDiamondClicks(0);
          setDiamondColorSearchesList([]);
          setDiamondColorYAxisList([]);
        }
      })
      .catch((error) => {
        setTotalDiamondClicks(0);
        setDiamondColorSearchesList([]);
        setDiamondColorYAxisList([]);
        setLoadChartReportsLoading(false);
        setdiamondColorSearchesLabels([]);
      })
      .finally(() => {
        setLoadChartReportsLoading(false);
      });
  };
  const handleGetDiamondReportForTableChartAll = (
    dealerId,
    forDealer,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    ColorValue
  ) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      clarity: TableClarityValue,
      cutGrade: TableCutGradeValue,
      shape: TableShapeValue,
      color: ColorValue ? ColorValue : TableColorValue,
      size: TableSizeValue,
      certificate: TableCertificateValue,
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      pageNumber: pageNo ? pageNo : 1,
      pageSize: pageSize ? pageSize : 10,
      sortOrderCol: sortColumnName || "GT.MODIFIED",
      sortOrderType: sortOrder || "DESC",
      reporttype: "Global",
      reportName: "Color",
    };
    setTableForAllLoading(true);
    ReportsDiamondService.GetDiamondReportForTableChartAll(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var TableForAllList = responseData.modelTableChartAllList;
        var TotalEventsCount = responseData.totalCount;
        if (message === "Success") {
          if (
            responseData?.modelTableChartAllList &&
            responseData?.modelTableChartAllList !== null
          ) {
            if (Object.keys(responseData).length !== 0) {
              setTotalCount(TotalEventsCount);
              setTableForAllList(TableForAllList);
            }
          } else {
            setTotalCount(0);
            setTableForAllList([]);
          }
        } else {
          setTotalCount(0);
          setTableForAllList([]);
        }
      })
      .catch((error) => {
        setTotalCount(0);
        setTableForAllList([]);
        setTableForAllLoading(false);
      })
      .finally(() => setTableForAllLoading(false));
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "DESC";
      column = "GT.MODIFIED";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*API Binding Ends*/
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e,
    });
  };

  const handleApplyFilter = () => {
    setTableKey(!tableKey);
    setPageSize(10);
    setTableColorValue("");
    handleLoadChartReports(dealerId, forDealer);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      1,
      10,
      "GT.MODIFIED",
      "DESC",
      ""
    );
  };
  /*DatePicker Binding Ends*/
  return (
    <React.Fragment>
      <div className="form__fields magento__mapping">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Diamond Color Searches")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                className="border__grey"
                placeholder="All"
                showSearch
                name="SelectedDiamondValue"
                optionFilterProp="children"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All">{traslate("All")}</Option>
                <Option value="Mined">{traslate("Mined Diamond")}</Option>
                <Option value="Lab">{traslate("Lab Grown Diamond")}</Option>
              </Select>
            </div>
          </div>

          {DiamondColorSearchesList.length > 0 &&
            TableForAllList.length > 0 && (
              <div className="col-lg-12 col-md-6 mt-4">
                <div className="input__block">
                  <label>
                    <Spin spinning={LoadChartReportsLoading} size="small">
                      <b>
                        {" "}
                        {traslate("Total Clicks")} :{" "}
                        {TotalDiamondClicks ? TotalDiamondClicks : 0}
                      </b>
                    </Spin>
                  </label>
                </div>
              </div>
            )}

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="chart__section">
            <Spin spinning={LoadChartReportsLoading} size="large">
              {DiamondColorSearchesList.length > 0 &&
              TableForAllList.length > 0 ? (
                <Pie
                  key={key}
                  onClick={(event) => {
                    const element = getElementAtEvent(
                      DiamondColorChartRef.current,
                      event
                    );
                    if (!element.length) return;

                    const { index } = element[0];
                    setTableColorValue(diamondColorSearchesLabels[index]);
                  }}
                  ref={DiamondColorChartRef}
                  data={{
                    labels: diamondColorSearchesLabels,
                    datasets: [
                      {
                        data: DiamondColorYAxisList,
                        backgroundColor: [
                          "rgb(14, 220, 232)",
                          "rgb(16, 150, 24)",
                          "rgb(255, 153, 0)",
                          "rgb(153, 0, 153)",
                          "rgb(0, 153, 198)",
                          "rgb(51, 102, 204)",
                          "rgb(220, 57, 18)",
                          "rgb(102, 170, 0)",
                          "rgb(221, 68, 119)",
                          "rgb(237, 237, 1)",
                        ],
                        borderColor: [
                          "rgb(14, 220, 232)",
                          "rgb(16, 150, 24)",
                          "rgb(255, 153, 0)",
                          "rgb(153, 0, 153)",
                          "rgb(0, 153, 198)",
                          "rgb(51, 102, 204)",
                          "rgb(220, 57, 18)",
                          "rgb(102, 170, 0)",
                          "rgb(221, 68, 119)",
                          "rgb(237, 237, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={300}
                  height={300}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                      datalabels: {
                        display: true,
                        align: "end",
                        color: "rgb(255, 255, 255)",
                        font: {
                          size: 12,
                        },
                        formatter: (value) => {
                          if (totalValueForPercentage) {
                            let finalPercentage = (
                              (value / totalValueForPercentage) *
                              100
                            ).toFixed(2);
                            setTooltipPercentage(finalPercentage);
                            if (finalPercentage >= 5) {
                              return finalPercentage + "%";
                            } else {
                              return "";
                            }
                          }
                        },
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {
                            let label = context.label || "";
                            let formattedValue = context.formattedValue || "";

                            if (label) {
                              label =
                                label +
                                " " +
                                formattedValue +
                                " (" +
                                TooltipPercentage +
                                "%" +
                                ")";
                            }
                            return label;
                          },
                        },
                      },
                      legend: {
                        position: "left",
                      },
                    },
                  }}
                />
              ) : (
                <div className="no-report-render">
                  <div className="desc-space">
                    <h4 className="text-center">{traslate("No Data Found")}</h4>
                  </div>
                </div>
              )}
            </Spin>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              key={tableKey}
              className="pendjewelreq__tbl"
              loading={TableForAllLoading}
              columns={[
                {
                  title: traslate("UserIPAddress"),
                  dataIndex: "usersIPAddress",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Date"),
                  sorter: (a, b) => {},
                  render: (item, row) => {
                    return moment(row.date).format("MM-DD-YYYY, hh:mm:ss A");
                  },
                },
                {
                  title: traslate("Shape"),
                  dataIndex: "shape",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Size"),
                  dataIndex: "size",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Color"),
                  dataIndex: "color",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Cut"),
                  dataIndex: "cutGrade",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("Clarity"),
                  dataIndex: "clarity",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (item.clarity) {
                      return (
                        <React.Fragment>
                          <span>{item.clarity}</span>
                        </React.Fragment>
                      );
                    }
                  },
                },
                {
                  title: traslate("Certificate"),
                  dataIndex: "certificate",
                  sorter: (a, b) => {},
                },
                {
                  title: traslate("RetailerPrice"),
                  dataIndex: "retailPrice",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    return (
                      <React.Fragment>
                        <span>
                          {item.retailPrice ? (
                            <span>
                              {/* {loginDetials.currency ? "" : "$"} */}
                              {item.retailPrice}
                            </span>
                          ) : (
                            "Call For Price"
                          )}
                        </span>
                      </React.Fragment>
                    );
                  },
                },
                {
                  title: traslate("Cost"),
                  dataIndex: "cost",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    return (
                      <React.Fragment>
                        <span>
                          {item.cost ? (
                            <span>
                              {/* {loginDetials.currency ? "" : "$"} */}
                              {item.cost}
                            </span>
                          ) : (
                            "Call For Price"
                          )}
                        </span>
                      </React.Fragment>
                    );
                  },
                },
              ]}
              dataSource={TableForAllList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondColorIndComp;
