import { httpAdminCompanyLocation } from "../helper/http-common";

class ManageBrandsService {
    GetBrandData(inputData) {
        return httpAdminCompanyLocation.post('Profile/BindBrandData', inputData);
    }
    BindFirstRowData(inputData) {
        return httpAdminCompanyLocation.post(`Profile/bindFirstRow`, inputData);
    }
    LoadEditBrand(inputData) {
        // return httpAdminCompanyLocation.post(`Profile/LoadEditBrand=${inputData.DealerID}&DesignerID=${inputData.DesignerID}`)
        return httpAdminCompanyLocation.post(`Profile/LoadEditBrand`, inputData);
    }
    SubmitEditBrandData(inputData) {
        const headers = {
            "Content-Type": "multipart/form-data"
        };
        return httpAdminCompanyLocation.post("Profile/BrandSaveData", inputData, {
            headers
        })
    }
    DeleteBrandData(inputData) {
        // return httpAdminCompanyLocation.post(`Profile/DeleteBrand?DealerID=${dealerID}&DesignerID=${id}`)
        return httpAdminCompanyLocation.post("Profile/DeleteBrand", inputData)
    }
}

export default new ManageBrandsService()