import React, { Fragment } from "react";
import Network from "./Network";

export const OutOfNetwork = () => {
  return (
    <Fragment>
      <Network type="Out Of Network" />
    </Fragment>
  );
};

export default OutOfNetwork;
