import {
  CONTACT_CONVERSATION_COUNT,
  CONTACT_APPOINTMENT_COUNT,
  CONTACT_CUSTOMER_COUNT,
  CONTACT_PAGE_NAME_TYPE,
  DASHBOARD_PAGE_NAME_TYPE,
  TOGGLE_SIDEMENU_DASHBOARD_DATA,
  VENDOR_CONVERSATION_COUNT,
  VENDOR_APPOINTMENT_COUNT,
  VENDOR_CONVERSATION_COUNT_UNREAD,
  VENDOR_CONVERSATION_COUNT_SENT,
  SET_JEWELRY_TAB,
  DEALERID_ADMIN_DATA_MAPPING,
  COLUMNID_ADMIN_DATA_MAPPING,
  PROFILE_ID,
  PROFILE_TAB,
} from "./types";

export const setContactConversationCount = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_CONVERSATION_COUNT,
      payload: data,
    });
  } catch (err) {}
};

export const setContactAppointmentCount = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_APPOINTMENT_COUNT,
      payload: data,
    });
  } catch (err) {}
};

export const setContactCustomerCount = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_CUSTOMER_COUNT,
      payload: data,
    });
  } catch (err) {}
};

export const setContactPageNameType = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_PAGE_NAME_TYPE,
      payload: data,
    });
  } catch (err) {}
};

export const setDashboardPageNameType = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_PAGE_NAME_TYPE,
      payload: data,
    });
  } catch (err) {}
};

export const setSideMenuDashboardData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_SIDEMENU_DASHBOARD_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setVendorConversationCount = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_CONVERSATION_COUNT,
      payload: data,
    });
  } catch (err) {}
};

export const setVendorAppointmentCount = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_APPOINTMENT_COUNT,
      payload: data,
    });
  } catch (err) {}
};

export const setVendorConversationCountUnRead = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_CONVERSATION_COUNT_UNREAD,
      payload: data,
    });
  } catch (err) {}
};

export const setVendorConversationCountSent = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_CONVERSATION_COUNT_SENT,
      payload: data,
    });
  } catch (err) {}
};

export const setJewelryTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_JEWELRY_TAB,
      payload: data,
    });
  } catch (err) {}
};

export const setDealerIdAdminDataMapping = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DEALERID_ADMIN_DATA_MAPPING,
      payload: data,
    });
  } catch (err) {}
};

export const setColumnIdAdminDataMapping = (data) => async (dispatch) => {
  try {
    dispatch({
      type: COLUMNID_ADMIN_DATA_MAPPING,
      payload: data,
    });
  } catch (err) {}
};

// Profile Page Redux
export const setProfileID = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_ID,
      payload: data,
    });
  } catch (err) {}
};

export const setProfileTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_TAB,
      payload: data,
    });
  } catch (err) {}
};
