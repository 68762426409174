export const SizingData = [
  {
    ID: 0,
    size: "3",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "3.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "3.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "3.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "4",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "4.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "4.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "4.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "5.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "5.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },

  {
    ID: 0,
    size: "5.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "6",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "6.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "6.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "7",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "7.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "7.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "8",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "8.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "8.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "8.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "9",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "9.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "9.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "9.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "10",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "10.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "10.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "10.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "11",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "11.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "11.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "11.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "12",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "12.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "12.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    ID: 0,
    size: "12.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "13",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "13.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "13.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "13.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "14",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "14.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "14.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "14.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "15",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "15.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "15.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "15.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },

  {
    ID: 0,
    size: "16",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "16.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "16.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "16.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "17",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "17.25",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "17.5",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
  {
    ID: 0,
    size: "17.75",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },

  {
    ID: 0,
    size: "18",
    priceType: "Fixed",
    additionalPrice: "",
    ischecked: false,
  },
];

export const RingbuilderData = [
  {
    key: "1",

    stoneShapes: "Round",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "2",

    stoneShapes: "Princess",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "3",

    stoneShapes: "Radiant",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "4",

    stoneShapes: "Emerald Cut",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "5",

    stoneShapes: "Pear",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "6",

    stoneShapes: "Oval",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "7",

    stoneShapes: "Marquise",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "8",

    stoneShapes: "Asscher",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "9",
    stoneShapes: "Cushion",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "10",

    stoneShapes: "Heart",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
];
export const NavigationData = [
  {
    subMenuID: "1",
    data: "Loose Daimonds",
    TextBox: "",
    Dropdown: null,
  },
  {
    subMenuID: "2",
    data: "Our Signature Daimonds",
    TextBox: "",
    Dropdown: null,
  },
  {
    subMenuID: "3",
    data: "Best Value Daimonds",
    TextBox: "",
    Dropdown: null,
  },
  {
    subMenuID: "4",
    data: "Private Reserve Daimonds",
    TextBox: "",
    Dropdown: null,
  },
  {
    subMenuID: "62",
    data: "HeartStar Daimonds",
    TextBox: "",
    Dropdown: null,
  },
];

export const MinMaxMarkupPrice = [
  { Markup: "costUp250", min: "0.00", max: "250.00" },
  { Markup: "costUp500", min: "250.01", max: "500.00" },
  { Markup: "costUp1000", min: "500.01", max: "1000.00" },
  { Markup: "costUp1500", min: "1000.01", max: "1500.00" },
  { Markup: "costUp2000", min: "1500.01", max: "2000.00" },
  { Markup: "costUp2500", min: "2000.01", max: "2500.00" },
  { Markup: "costUp3000", min: "2500.01", max: "3000.00" },
  { Markup: "costUp3500", min: "3000.01", max: "3500.00" },
  { Markup: "costUp4000", min: "3500.01", max: "4000.00" },
  { Markup: "costUp4500", min: "4000.01", max: "4500.00" },
  { Markup: "costUp5000", min: "4500.01", max: "5000.00" },
  { Markup: "costUp5500", min: "5000.01", max: "5500.00" },
  { Markup: "costUp6000", min: "5500.01", max: "6000.00" },
  { Markup: "costUp6500", min: "6000.01", max: "6500.00" },
  { Markup: "costUp7000", min: "6500.01", max: "7000.00" },
  { Markup: "costUp7500", min: "7000.01", max: "7500.00" },
  { Markup: "costUp8000", min: "7500.01", max: "8000.00" },
  { Markup: "costUp8500", min: "8000.01", max: "8500.00" },
  { Markup: "costUp9000", min: "8500.01", max: "9000.00" },
  { Markup: "costUp9500", min: "9000.01", max: "9500.00" },
  { Markup: "costUp10000", min: "9500.01", max: "10000.00" },
  { Markup: "costUp12500", min: "10000.01", max: "12500.00" },
  { Markup: "costUp15000", min: "12500.01", max: "15000.00" },
  { Markup: "costUp17500", min: "15000.01", max: "17500.00" },
  { Markup: "costUp20000", min: "17500.01", max: "20000.00" },
  { Markup: "costUp22500", min: "20000.01", max: "22500.00" },
  { Markup: "costUp25000", min: "22500.01", max: "25000.00" },
  { Markup: "costUp27500", min: "25000.01", max: "27500.00" },
  { Markup: "costUp30000", min: "27500.01", max: "30000.00" },
  { Markup: "costUp35000", min: "30000.01", max: "35000.00" },
  { Markup: "costUp40000", min: "35000.01", max: "40000.00" },
  { Markup: "costUp45000", min: "40000.01", max: "45000.00" },
  { Markup: "costUp50000", min: "45000.01", max: "50000.00" },
  { Markup: "costUp50000Plus", min: "50000.01", max: "" },
];

export const MinMaxSize = [
  { Markup: "markUP049", min: ".01ct", max: ".50ct" },
  { Markup: "markUP099", min: ".51ct", max: ".99ct" },
  { Markup: "markUP149", min: "1.00ct", max: "1.49ct" },
  { Markup: "markUP199", min: "1.50ct", max: "1.99ct" },
  { Markup: "markUP249", min: "2.00ct", max: "2.49ct" },
  { Markup: "markUP299", min: "2.50ct", max: "2.99ct" },
  { Markup: "markUP349", min: "3.00ct", max: "3.49ct" },
  { Markup: "markUP399", min: "3.50ct", max: "3.99ct" },
  { Markup: "markUP449", min: "4.00ct", max: "4.49ct" },
  { Markup: "markUP499", min: "4.50ct", max: "4.99ct" },
  { Markup: "markUP549", min: "5.00ct", max: "5.49ct" },
  { Markup: "markUP599", min: "5.50ct", max: "5.99ct" },
  { Markup: "markUP6Plus", min: "6.00ct", max: "" },
];

// Ring Builder

export const RingMinMaxMarkupPrice = [
  { Markup: "costUp250", min: "0.00", max: "250.00" },
  { Markup: "costUp500", min: "250.01", max: "500.00" },
  { Markup: "costUp1000", min: "500.01", max: "1000.00" },
  { Markup: "costUp1500", min: "1000.01", max: "1500.00" },
  { Markup: "costUp2000", min: "1500.01", max: "2000.00" },
  { Markup: "costUp2500", min: "2000.01", max: "2500.00" },
  { Markup: "costUp3000", min: "2500.01", max: "3000.00" },
  { Markup: "costUp3500", min: "3000.01", max: "3500.00" },
  { Markup: "costUp4000", min: "3500.01", max: "4000.00" },
  { Markup: "costUp4500", min: "4000.01", max: "4500.00" },
  { Markup: "costUp5000", min: "4500.01", max: "5000.00" },
  { Markup: "costUp5500", min: "5000.01", max: "5500.00" },
  { Markup: "costUp6000", min: "5500.01", max: "6000.00" },
  { Markup: "costUp6500", min: "6000.01", max: "6500.00" },
  { Markup: "costUp7000", min: "6500.01", max: "7000.00" },
  { Markup: "costUp7500", min: "7000.01", max: "7500.00" },
  { Markup: "costUp8000", min: "7500.01", max: "8000.00" },
  { Markup: "costUp8500", min: "8000.01", max: "8500.00" },
  { Markup: "costUp9000", min: "8500.01", max: "9000.00" },
  { Markup: "costUp9500", min: "9000.01", max: "9500.00" },
  { Markup: "costUp10000", min: "9500.01", max: "10000.00" },
  { Markup: "costUp12500", min: "10000.01", max: "12500.00" },
  { Markup: "costUp15000", min: "12500.01", max: "15000.00" },
  { Markup: "costUp17500", min: "15000.01", max: "17500.00" },
  { Markup: "costUp20000", min: "17500.01", max: "20000.00" },
  { Markup: "costUp22500", min: "20000.01", max: "22500.00" },
  { Markup: "costUp25000", min: "22500.01", max: "25000.00" },
  { Markup: "costUp27500", min: "25000.01", max: "27500.00" },
  { Markup: "costUp30000", min: "27500.01", max: "30000.00" },
  { Markup: "costUp35000", min: "30000.01", max: "35000.00" },
  { Markup: "costUp40000", min: "35000.01", max: "40000.00" },
  { Markup: "costUp45000", min: "40000.01", max: "45000.00" },
  { Markup: "costUp50000", min: "45000.01", max: "50000.00" },
  { Markup: "costUp50000Plus", min: "50000.01", max: "" },
];

export const RingMinMaxSize = [
  { Markup: "markUP049", min: ".01ct", max: ".50ct" },
  { Markup: "markUP099", min: ".51ct", max: ".99ct" },
  { Markup: "markUP149", min: "1.00ct", max: "1.49ct" },
  { Markup: "markUP199", min: "1.50ct", max: "1.99ct" },
  { Markup: "markUP249", min: "2.00ct", max: "2.49ct" },
  { Markup: "markUP299", min: "2.50ct", max: "2.99ct" },
  { Markup: "markUP349", min: "3.00ct", max: "3.49ct" },
  { Markup: "markUP399", min: "3.50ct", max: "3.99ct" },
  { Markup: "markUP449", min: "4.00ct", max: "4.49ct" },
  { Markup: "markUP499", min: "4.50ct", max: "4.99ct" },
  { Markup: "markUP549", min: "5.00ct", max: "5.49ct" },
  { Markup: "markUP599", min: "5.50ct", max: "5.99ct" },
  { Markup: "markUP6Plus", min: "6.00ct", max: "" },
];

// Fancy Markup

export const FancyMinMaxMarkupPrice = [
  { Markup: "costUp250", min: "0.00", max: "250.00" },
  { Markup: "costUp500", min: "250.01", max: "500.00" },
  { Markup: "costUp1000", min: "500.01", max: "1000.00" },
  { Markup: "costUp1500", min: "1000.01", max: "1500.00" },
  { Markup: "costUp2000", min: "1500.01", max: "2000.00" },
  { Markup: "costUp2500", min: "2000.01", max: "2500.00" },
  { Markup: "costUp3000", min: "2500.01", max: "3000.00" },
  { Markup: "costUp3500", min: "3000.01", max: "3500.00" },
  { Markup: "costUp4000", min: "3500.01", max: "4000.00" },
  { Markup: "costUp4500", min: "4000.01", max: "4500.00" },
  { Markup: "costUp5000", min: "4500.01", max: "5000.00" },
  { Markup: "costUp5500", min: "5000.01", max: "5500.00" },
  { Markup: "costUp6000", min: "5500.01", max: "6000.00" },
  { Markup: "costUp6500", min: "6000.01", max: "6500.00" },
  { Markup: "costUp7000", min: "6500.01", max: "7000.00" },
  { Markup: "costUp7500", min: "7000.01", max: "7500.00" },
  { Markup: "costUp8000", min: "7500.01", max: "8000.00" },
  { Markup: "costUp8500", min: "8000.01", max: "8500.00" },
  { Markup: "costUp9000", min: "8500.01", max: "9000.00" },
  { Markup: "costUp9500", min: "9000.01", max: "9500.00" },
  { Markup: "costUp10000", min: "9500.01", max: "10000.00" },
  { Markup: "costUp12500", min: "10000.01", max: "12500.00" },
  { Markup: "costUp15000", min: "12500.01", max: "15000.00" },
  { Markup: "costUp17500", min: "15000.01", max: "17500.00" },
  { Markup: "costUp20000", min: "17500.01", max: "20000.00" },
  { Markup: "costUp22500", min: "20000.01", max: "22500.00" },
  { Markup: "costUp25000", min: "22500.01", max: "25000.00" },
  { Markup: "costUp27500", min: "25000.01", max: "27500.00" },
  { Markup: "costUp30000", min: "27500.01", max: "30000.00" },
  { Markup: "costUp35000", min: "30000.01", max: "35000.00" },
  { Markup: "costUp40000", min: "35000.01", max: "40000.00" },
  { Markup: "costUp45000", min: "40000.01", max: "45000.00" },
  { Markup: "costUp50000", min: "45000.01", max: "50000.00" },
  { Markup: "costUp50000Plus", min: "50000.01", max: "" },
];

export const FancyMinMaxSize = [
  { Markup: "markUP049", min: ".01ct", max: ".50ct" },
  { Markup: "markUP099", min: ".51ct", max: ".99ct" },
  { Markup: "markUP149", min: "1.00ct", max: "1.49ct" },
  { Markup: "markUP199", min: "1.50ct", max: "1.99ct" },
  { Markup: "markUP249", min: "2.00ct", max: "2.49ct" },
  { Markup: "markUP299", min: "2.50ct", max: "2.99ct" },
  { Markup: "markUP349", min: "3.00ct", max: "3.49ct" },
  { Markup: "markUP399", min: "3.50ct", max: "3.99ct" },
  { Markup: "markUP449", min: "4.00ct", max: "4.49ct" },
  { Markup: "markUP499", min: "4.50ct", max: "4.99ct" },
  { Markup: "markUP549", min: "5.00ct", max: "5.49ct" },
  { Markup: "markUP599", min: "5.50ct", max: "5.99ct" },
  { Markup: "markUP6Plus", min: "6.00ct", max: "" },
];

export const ColorList = [
  { id: 68, label: "D", value: 68 },
  { id: 69, label: "E", value: 69 },
  { id: 70, label: "F", value: 70 },
  { id: 71, label: "G", value: 71 },
  { id: 71, label: "H", value: 72 },
  { id: 73, label: "I", value: 73 },
  { id: 74, label: "J", value: 74 },
  { id: 75, label: "K", value: 75 },
  { id: 76, label: "L", value: 76 },
  { id: 77, label: "M", value: 77 },
  { id: 78, label: "N", value: 78 },
  { id: 79, label: "O", value: 79 },
  { id: 80, label: "P", value: 80 },
];
export const ShapeList = [
  { id: "Round", label: "Round", value: "Round" },
  { id: "Radiant", label: "Radiant", value: "Radiant" },
  { id: "Princess", label: "Princess", value: "Princess" },
  { id: "Pear", label: "Pear", value: "Pear" },
  { id: "Oval", label: "Oval", value: "Oval" },
  { id: "Marquise", label: "Marquise", value: "Marquise" },
  { id: "Heart", label: "Heart", value: "Heart" },
  { id: "Emerald", label: "Emerald", value: "Emerald" },
  { id: "Cushion", label: "Cushion", value: "Cushion" },
  { id: "Asscher", label: "Asscher", value: "Asscher" },
];
export const CutList = [
  { id: 1, label: "Ideal", value: 1 },
  { id: 2, label: "Excellent", value: 2 },
  { id: 3, label: "VeryGood", value: 3 },
  { id: 4, label: "Good", value: 4 },
  { id: 5, label: "Fair", value: 5 },
];
export const ClarityList = [
  { id: 1, label: "FL", value: 1 },
  { id: 2, label: "IF", value: 2 },
  { id: 3, label: "VVS1", value: 3 },
  { id: 4, label: "VVS2", value: 4 },
  { id: 5, label: "VS1", value: 5 },
  { id: 6, label: "VS2", value: 6 },
  { id: 7, label: "SI1", value: 7 },
  { id: 8, label: "SI2", value: 8 },
  { id: 9, label: "SI3", value: 9 },
  { id: 10, label: "I1", value: 10 },
  { id: 11, label: "I2", value: 11 },
];

export const PolishList = [
  { id: 1, label: "Excellent", value: 1 },
  { id: 2, label: "VeryGood", value: 2 },
  { id: 3, label: "Good", value: 3 },
  { id: 4, label: "Fair", value: 4 },
];
export const SymmetryList = [
  { id: 1, label: "Excellent", value: 1 },
  { id: 2, label: "VeryGood", value: 2 },
  { id: 3, label: "Good", value: 3 },
  { id: 4, label: "Fair", value: 4 },
];
export const FluorescenceList = [
  { id: 1, label: "N", value: 1 },
  { id: 2, label: "FNT", value: 2 },
  { id: 3, label: "Med", value: 3 },
  { id: 4, label: "ST", value: 4 },
  { id: 5, label: "VST", value: 5 },
];
