import {
  MANAGE_INVENTORY_TABNAME,
  MANAGE_INVENTORY_VENDOR_ID,
  VENDOR_PROFILE_PAGE_NAME,
  BIND_ACTIVE_PAGE_NAME,
} from "./../../actions/Community/types";

const initialState = {
  vendorId: 0,
  tabName: "View",
  pageName: "",
  bindActivePageName: "",
};

function manageInventoryReducer(inventoryState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MANAGE_INVENTORY_VENDOR_ID:
      return { ...inventoryState, vendorId: payload };
    case MANAGE_INVENTORY_TABNAME:
      return { ...inventoryState, tabName: payload };
    case VENDOR_PROFILE_PAGE_NAME:
      return { ...inventoryState, pageName: payload };
    case BIND_ACTIVE_PAGE_NAME:
      return { ...inventoryState, bindActivePageName: payload };
    default:
      return inventoryState;
  }
}

export default manageInventoryReducer;
