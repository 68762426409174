export const SET_HEADERMENU_DATA = "SET_HEADERMENU_DATA";
export const SET_IMPERSONATE_HEADERMENU_DATA = "SET_HEADERMENU_DATA";
export const SET_ADMIN_HEADERMENU_DATA = "SET_ADMIN_HEADERMENU_DATA";
export const SET_CHECKLINK_HEADERMENU_DATA = "SET_CHECKLINK_HEADERMENU_DATA";
export const SET_OPENKEY_HEADERMENU_DATA = "SET_OPENKEY_HEADERMENU_DATA";
export const SET_SIDEMENU_DATA = "SET_SIDEMENU_DATA";
export const RESET_STATE = "RESET_STATE";
export const SELECT_SIDE_MENU = "SELECT_SIDE_MENU";
export const EDIT_FOURTIER_MENU = "EDIT_FOURTIER_MENU";
export const EDIT_THIRD_MENU = "EDIT_THIRD_MENU";
export const ORGINAL_FOURTIER_MENU = "ORGINAL_FOURTIER_MENU";
export const OLD_URL_ARRAY = "OLD_URL_ARRAY";
export const ADMIN_MENU_ARRAY = "ADMIN_MENU_ARRAY";
export const CONTACT_PAGE_NAME_TYPE = "CONTACT_PAGE_NAME_TYPE";

//For Iframe
export const RESET_IFRAME = "RESET_IFRAME";

// Multilingual
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_SELECTED_VENDOR = "SET_SELECTED_VENDOR";