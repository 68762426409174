import React, { useState, useEffect } from "react";
import { Switch, Spin } from "antd";
import { useSelector } from "react-redux";
import traslate from "../../../i18n/translate";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";

const FtpDetails = () => {
  const loginDetials = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [dealerId, setDealerId] = useState(0);
  
  const [ftpDetails, setFtpDetails] = useState({
    path: "",
    userId: "",
    password: "",
    portNo: "",
    IsSSL: true,
  });

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetMegentoMappingForXML(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleGetMegentoMappingForXML = (id) => {
    let inputData = {
      dealerId: id | 0,
    };
    try {
      setLoading(true);
      AdminToolsDataService.GetMegentoMappingForXML(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setFtpDetails((prevState) => ({
                ...prevState,
                path: responseData.apiURL,
                userId: responseData.userName,
                password: responseData.password,
                portNo: responseData.portNo,
                IsSSL: responseData.enableSync,
              }));
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setDisableBtn(false);
      setFtpDetails({
        ...ftpDetails,
        [name]: e,
      });
    } else {
      setDisableBtn(false);
      setFtpDetails({
        ...ftpDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        apiUrl: ftpDetails.path ? ftpDetails.path : "",
        portNo: ftpDetails.portNo,
        username: ftpDetails.userId ? ftpDetails.userId : "",
        password: ftpDetails.password ? ftpDetails.password : "",
        enablessl: ftpDetails.IsSSL,
      };
      setLoading(true);
      AdminToolsDataService.SaveFtpDetailsSync(inputData)
        .then((response) => {
          let message = response.data.message;
          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Data Updated Successfully.");
            handleGetMegentoMappingForXML(dealerId);
          } else {
            NotificationManager.error("Data not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setDisableBtn(true);
  };

  return (
    
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping diamond__mapping">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {" "}
                    {traslate("Enter FTP Detail for SYNC File Below")}{" "}
                  </h4>
                </div>

                <Spin spinning={loading}>
                  <div className="col-md-6">
                    <div className="input__block">
                      <lable> {traslate("Path")} </lable>
                      <input
                        type="text"
                        name="path"
                        value={ftpDetails.path}
                        onChange={handleOnChange}
                      />
                    </div>

                    <div className="input__block">
                      <lable> {traslate("User Id")} </lable>
                      <input
                        type="text"
                        name="userId"
                        value={ftpDetails.userId}
                        onChange={handleOnChange}
                      />
                    </div>

                    <div className="input__block">
                      <lable> {traslate("Password")} </lable>
                      <input
                        type="text"
                        name="password"
                        autoComplete="new-password"
                        value={ftpDetails.password}
                        onChange={handleOnChange}
                      />
                    </div>

                    <div className="input__block">
                      <lable> {traslate("Port No")} </lable>
                      <input
                        type="text"
                        name="portNo"
                        value={ftpDetails.portNo}
                        onChange={handleOnChange}
                      />
                    </div>

                    <div className="input__block">
                      <lable className="d-block mb-2">
                        {" "}
                        {traslate("Is SSL?")}{" "}
                      </lable>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={(e) => handleOnChange(e, "select", "IsSSL")}
                        checked={ftpDetails.IsSSL}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button
                      className={disableBtn ? "disabled-btn" : "primary-btn"}
                      disabled={disableBtn}
                      loading={submitLoading}
                      onClick={handleSubmit}
                    >
                      {" "}
                      {traslate("Save Details")}{" "}
                    </button>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default FtpDetails;
