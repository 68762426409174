import React, { useState, useEffect } from "react";

import { NotificationManager } from "react-notifications";
import { Button, Switch, Modal, Table, Spin } from "antd";

import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../i18n/translate";
import catalogDiamondService from "../../services/catalog-diamond.service";
import { useSelector } from "react-redux";

const StuckFile = (props) => {
  const [getColorData, setgetColorData] = useState([]);
  const [page, setPage] = useState(1);
  const [getloading, setGetLoading] = useState(false);
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const [delData, setDelData] = useState({
    metalColorID: 0,
  });
  const [loading, setLoading] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const [dealerId, setDealerId] = useState(null);
  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleGetStuckFile(
            loginDetials.loginDetials.responseData.dealerId,
            props.type
          );
          setVendorStatusID(loginDetials.loginDetials.responseData.vendorStatusID ? loginDetials.loginDetials.responseData.vendorStatusID : 0  )
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  const handleGetStuckFile = (id, type) => {
    let inputData = {
      dealerID: id.toString(),
    };
    try {
      setGetLoading(true);
      catalogDiamondService
        .GetStuckFile(inputData, type)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setgetColorData(responseData);
            setGetLoading(false);
          } else {
            setgetColorData([]);
            setGetLoading(false);
          }
        })
        .catch((error) => {
          setGetLoading(false);
          console.log(error);
        });
    } catch (error) {
      setGetLoading(false);
      console.log(error);
    }
  };

  const handleDeleteModal = (row) => {
    setVisible2(true);
    setDelData(row);
  };

  const handleCancel2 = () => {
    setVisible2(false);
    setDelData({});
  };

  const handleDelSubmit = () => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        summaryID: delData.summaryID,
        type: props.type == "Jewelry" ? "jewelry" : "diamond",
      };
      setLoading(true);
      catalogDiamondService
        .DeleteStuckFile(inputData)
        .then((response) => {
          var message = response.data.message;
          var returnMessage = response.data.responseData.returnMessage;
          setLoading(true);
          if (message === "Success") {
            NotificationManager.success(returnMessage);
            handleGetStuckFile(dealerId, props.type);
            setLoading(false);
          } else {
            NotificationManager.error(returnMessage);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel2();
  };

  return (
    <React.Fragment>
      <div className="addcolor__section">
      {
        vendorStatusID == 3 ? (
          <>{traslate("Please Contact Gemfind to get Access.")}</>
        ) : (
      <div> 
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {props.type == "Jewelry"
                      ? traslate("Jewelry Stuck File")
                      : traslate("Diamond Stuck File")}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <div className="addcolor_tbldiv">
                <Spin spinning={getloading} size="large">
                  <Table
                    pagination={{
                      showSizeChanger: true,
                      responsive: true,
                    }}
                    columns={[
                      {
                        title: traslate("Date"),
                        width: 100,
                        dataIndex: "dateOnly",
                      },
                      {
                        title: traslate("Time"),
                        dataIndex: "timeOnly",
                      },
                      {
                        title: traslate("File"),
                        dataIndex: "fileName",
                      },
                      {
                        title: traslate("Records"),
                        dataIndex: "record",
                      },
                      {
                        title: traslate("Action"),
                        dataIndex: "action",
                        width: 100,
                        render: (index, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    dataSource={getColorData}
                    scroll={{ x: 600,y: 800 }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="datamapping__modal"
          centered
          visible={visible2}
          onOk={() => setVisible2(false)}
          onCancel={() => setVisible2(false)}
          width={400}
          footer={[
            <Button key="back" onClick={handleCancel2}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleDelSubmit}>
              {traslate("Delete")}
            </Button>,
          ]}
        >
          <div className="col-lg-12">
            <div className="modal__body">
              <div className="col-lg-12">
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <p className="subheading">
                        {traslate("Are you sure you want to delete")} ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StuckFile;
