import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Select, Switch, Tooltip, Spin } from "antd";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";

const { Option } = Select;

export const RingBuilderOptions = (props) => {
  const [dealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [ViewOptionData, setViewOptionData] = useState({
    RingBuilderUrl: "",
    InternalUseLink: true,
    RingSizeType: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
    DefaultMetalType: null,
    ShowAdvancedSearch: true,
    RingsShow: true,
    ShowAddress: true,
    ShowCenterStone: true,
    ShowPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    GooglePlus: true,
    InstagramShare: true,
    AddToCart: true,
    AltShoppingCart: "",
    ShowBuySetting: true,
    ShowBuyDiamond: true,
    ShowViewCart: true,
    isShowEcommerce: false,
  });

  const loginDetials = useSelector((state) => state.loginReducer);

  const DefaultMetalTypeList = [
    {
      title: traslate("14k White Gold"),
      value: "14k White Gold",
    },
    {
      title: traslate("14k Yellow Gold"),
      value: "14k Yellow Gold",
    },
    {
      title: traslate("14k Rose Gold"),
      value: "14k Rose Gold",
    },
    {
      title: traslate("18k White Gold"),
      value: "18k White Gold",
    },
    {
      title: traslate("18k Yellow Gold"),
      value: "18k Yellow Gold",
    },
    {
      title: traslate("18k Rose Gold"),
      value: "18k Rose Gold",
    },
    {
      title: traslate("Platinum"),
      value: "Platinum",
    },
  ];
  const InitiallySortbox2List = [
    {
      title: traslate("Ascending"),
      value: "ASC",
    },
    {
      title: traslate("Descending"),
      value: "DESC",
    },
  ];

  const InitiallySortbox1List = [
    {
      title: traslate("Price"),
      value: "Price",
    },
    {
      title: traslate("Carat"),
      value: "Carat",
    },
    {
      title: traslate("Color"),
      value: "Color",
    },
    {
      title: traslate("Clarity"),
      value: "Clarity",
    },
    {
      title: traslate("Cut Grade"),
      value: "Cut Grade",
    },
    {
      title: traslate("Certificate"),
      value: "Certificate",
    },
  ];

  const RingSizeTypeList = [
    {
      title: traslate("American"),
      value: "American",
    },
    {
      title: traslate("British"),
      value: "British",
    },
    {
      title: traslate("Roman"),
      value: "Roman",
    },
    {
      title: traslate("Europe"),
      value: "Europe",
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetOptionsDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };
    setLoading(true);
    try {
      AppsRingBuilderService.GetRingBuilderOptionDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setViewOptionData((prevState) => ({
                ...prevState,
                RingBuilderUrl: responseData.ringBuilderURL,
                InternalUseLink: responseData.internalLinkRB,
                RingSizeType: responseData.ringSizeType,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
                DefaultMetalType: responseData.defaultMetalType,
                ShowAdvancedSearch: responseData.showAdvanceRingBuilder,
                RingsShow: responseData.showScheduleView,
                ShowAddress: responseData.showAddresses,
                ShowCenterStone: responseData.showCenterStoneSize,
                ShowPrice: responseData.showPricesOnSearchPage,
                GSTTax: responseData.showGstPrice,
                GSTTaxValue: responseData.taxRate,
                PinterestShare: responseData.showPinterestShare,
                TwitterShare: responseData.showTwitterShare,
                FacebookShare: responseData.showFacebookShare,
                FacebookLike: responseData.showFacebookLike,
                GooglePlus: responseData.showGooglePlus,
                InstagramShare: responseData.showInstagramShare,
                AddToCart: responseData.showAddToCartButton,
                AltShoppingCart: responseData.shoppingCartURL,
                ShowBuySetting: responseData.showBuyWithoutCenterStone,
                ShowBuyDiamond: responseData.buyDiamondOnly,
                ShowViewCart: responseData.showViewCartButtonRB,
                isShowEcommerce: responseData.isShowEcommerce,
              }));
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        type: "RingBuilder",
        ringBuilderURL: ViewOptionData.RingBuilderUrl,
        internalLinkRB: ViewOptionData.InternalUseLink,
        ringSizeType: ViewOptionData.RingSizeType,
        ringInitiallySort1: ViewOptionData.InitiallySortbox1,
        ringInitiallySortDesc: ViewOptionData.InitiallySortbox2,
        defaultMetalType: ViewOptionData.DefaultMetalType,
        showAdvanceRingBuilder: ViewOptionData.ShowAdvancedSearch,
        showScheduleView: ViewOptionData.RingsShow,
        showAddresses: ViewOptionData.ShowAddress,
        showCenterStoneSize: ViewOptionData.ShowCenterStone,
        showPricesOnSearchPage: ViewOptionData.ShowPrice,
        showGstPrice: ViewOptionData.GSTTax,
        taxRate: ViewOptionData.GSTTaxValue ? ViewOptionData.GSTTaxValue : 0,
        showPinterestShare: ViewOptionData.PinterestShare,
        showTwitterShare: ViewOptionData.TwitterShare,
        showFacebookShare: ViewOptionData.FacebookShare,
        showFacebookLike: ViewOptionData.FacebookLike,
        showGooglePlus: ViewOptionData.GooglePlus,
        showInstagramShare: ViewOptionData.InstagramShare,
        showAddToCartButton: ViewOptionData.AddToCart,
        shoppingCartURL: ViewOptionData.AltShoppingCart,
        showBuyWithoutCenterStone: ViewOptionData.ShowBuySetting,
        buyDiamondOnly: ViewOptionData.ShowBuyDiamond,
        showViewCartButtonRB: ViewOptionData.ShowViewCart,
      };
      setLoading(true);
      AppsRingBuilderService.UpdateRingBuilderOptionDetails(inputData)
        .then((response) => {
          let message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Data Updated Successfully.");
            handleGetOptionsDetails(dealerId);
          } else {
            NotificationManager.error("Data not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setDisableBtn(true);
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);

    if (isSelect === "select") {
      setViewOptionData({
        ...ViewOptionData,
        [name]: e,
      });
    } else {
      setViewOptionData({
        ...ViewOptionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-3">
              {" "}
              {traslate("Options")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <div className="row ">
        <Spin spinning={loading}>
          <div className="col-md-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Setup")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Ring Builder URL")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter the URL of this tool on your site to enable social sharing and dynamic sizing."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          className="mr-2"
                          name="RingBuilderUrl"
                          value={
                            ViewOptionData.RingBuilderUrl
                              ? ViewOptionData.RingBuilderUrl
                              : null
                          }
                          onChange={(e) => handleOnChange(e, "RingBuilderUrl")}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Internal Use Link?")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="InternalUseLink"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.InternalUseLink}
                          // onChange={handleSwitch}
                          onChange={(e) =>
                            handleOnChange(e, "select", "InternalUseLink")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div>
                    <label htmlFor="h1"> {traslate("Display")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("RingSize Type")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          className="border__grey selct__inpt "
                          showSearch
                          placeholder="Choose Any"
                          name="RingSizeType"
                          value={
                            ViewOptionData.RingSizeType
                              ? ViewOptionData.RingSizeType
                              : []
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "RingSizeType")
                          }
                        >
                          <Option value="">Select Type</Option>
                          {RingSizeTypeList?.map((item, i) => {
                            return (
                              <Option value={item.value}>{item.title}</Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="input__block row ml-0 mr-0">
                        <label className="col-md-12">
                          {traslate("Diamond Initially Sort Result On")}
                        </label>
                        <div className="col-md-6 d-flex align-items-center">
                          <Select
                            className="border__grey selct__inpt"
                            showSearch
                            placeholder="Choose Any"
                            name="InitiallySortbox1"
                            value={
                              ViewOptionData.InitiallySortbox1
                                ? ViewOptionData.InitiallySortbox1
                                : []
                            }
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleOnChange(e, "select", "InitiallySortbox1")
                            }
                          >
                            {InitiallySortbox1List?.map((item, i) => {
                              return (
                                <Option value={item.value}>{item.title}</Option>
                              );
                            })}
                          </Select>
                          <Tooltip
                            placement="right"
                            className="ml-1 info__icon"
                            title={traslate(
                              "Select a diamond attribute that you would like to initially sort the diamonds by."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </div>

                        <div className="col-md-6 d-flex align-items-center mobile__topspace">
                          <Select
                            className="border__grey selct__inpt"
                            showSearch
                            placeholder="Choose Any"
                            name="InitiallySortbox2"
                            value={
                              ViewOptionData.InitiallySortbox2
                                ? ViewOptionData.InitiallySortbox2
                                : []
                            }
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleOnChange(e, "select", "InitiallySortbox2")
                            }
                          >
                            {InitiallySortbox2List?.map((item, i) => {
                              return (
                                <Option value={item.value}>{item.title}</Option>
                              );
                            })}
                          </Select>
                          <Tooltip
                            placement="right"
                            className="ml-1 info__icon"
                            title={traslate(
                              "Select the desired initial sort order for your diamond attribute; choosing either Ascending or Descending sort order."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Default Metal Type on Setting page")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose Whether you would like a particular metal type to be set as the default when viewing a setting in RingBuilder."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          className="border__grey selct__inpt "
                          showSearch
                          placeholder="Choose Any"
                          name="DefaultMetalType"
                          value={
                            ViewOptionData.DefaultMetalType
                              ? ViewOptionData.DefaultMetalType
                              : []
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "DefaultMetalType")
                          }
                        >
                          {DefaultMetalTypeList?.map((item, i) => {
                            return (
                              <Option value={item.value}>{item.title}</Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Advanced Search Options By Default")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowAdvancedSearch"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowAdvancedSearch}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowAdvancedSearch")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Rings - Show Schedule A Viewing")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose Whether to display the link which allows the customer the ability to schedule an appointment."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="RingsShow"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.RingsShow}
                          onChange={(e) =>
                            handleOnChange(e, "select", "RingsShow")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Addresses")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose Whether to display your company address and contact information within the RingBuilder."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowAddress"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowAddress}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowAddress")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Center Stone Size")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowCenterStone"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowCenterStone}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowCenterStone")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Price")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Price")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowPrice"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowPrice}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowPrice")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Apply GST Tax")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Toggle the ability to apply international tax."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="GSTTax"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.GSTTax}
                          onChange={(e) =>
                            handleOnChange(e, "select", "GSTTax")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("GST Tax Value")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Set the value when applying the GST Tax option."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          className="mr-2"
                          name="GSTTaxValue"
                          value={
                            ViewOptionData.GSTTaxValue
                              ? ViewOptionData.GSTTaxValue
                              : null
                          }
                          onChange={(e) => {
                            e.target.value = e.target.value.slice(0, 2);
                            handleOnChange(e, "GSTTaxValue");
                          }}
                          onKeyPress={onKeyPressEvent}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Social")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Pinterest Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Pinterest “Pin it” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="PinterestShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.PinterestShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "PinterestShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Twitter Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Twitter “Tweet” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="TwitterShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.TwitterShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "TwitterShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Share” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="FacebookShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.FacebookShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "FacebookShare")
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Like")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Like” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="FacebookLike"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.FacebookLike}
                          onChange={(e) =>
                            handleOnChange(e, "select", "FacebookLike")
                          }
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Instagram Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Instagram “Share” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="InstagramShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.InstagramShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "InstagramShare")
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {ViewOptionData?.isShowEcommerce ? (
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <div>
                      <label> {traslate("E-Commerce")} </label>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show Add To Cart Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose Whether to display the “Add to Cart” button or not."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="AddToCart"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.AddToCart}
                            onChange={(e) =>
                              handleOnChange(e, "select", "AddToCart")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Alternate Shopping Cart")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            className="mr-2"
                            name="AltShoppingCart"
                            value={
                              ViewOptionData.AltShoppingCart
                                ? ViewOptionData.AltShoppingCart
                                : null
                            }
                            onChange={(e) =>
                              handleOnChange(e, "AltShoppingCart")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show Buy Setting Only")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ShowBuySetting"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.ShowBuySetting}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ShowBuySetting")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show Buy Diamond Only")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ShowBuyDiamond"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.ShowBuyDiamond}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ShowBuyDiamond")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show")} "{traslate("View Cart")}"{" "}
                            {traslate("Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose Whether to display the PayPal shopping cart's “view button” or Not"
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ShowViewCart"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.ShowViewCart}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ShowViewCart")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
            </Accordion>

            <div className="col-lg-12 mt-4">
              <button
                className={disableBtn ? "disabled-btn" : "primary-btn"}
                disabled={disableBtn}
                loading={submitLoading}
                onClick={handleSubmit}
              >
                {" "}
                {traslate("Update Settings")}{" "}
              </button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default RingBuilderOptions;
