import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import {  Button, Switch, Tooltip, Spin } from "antd";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import AppsStudBuilderService from "../../../services/apps-studbuilder.service";
import traslate from "../../../i18n/translate";

const initialOptionsData = {
  studbuilderLinkURL: "",
  internalUseLink: false,
  showOnlyDiamondSearch: false,
  showDealerDiamondsSKU: false,
  showScheduleViewing: false,
  showAddress: false,
  showCallForPrice: false,
  applyGstTax: false,
  gstTaxValue: "",
  showPinterestShare: false,
  showTwitterShare: false,
  showFacebookShare: false,
  showFacebookLike: false,
  showAddToCartButton: false,
  ShoppingCartURL: "",
  showViewCartButton: false,
  ShowInstagramPlus: false,
  isShowEcommerce: true,
};

const initialState = {
  dealerId: null,
  posSystem: null,
  appActivationControl: {},
};

export const StudBuilderOptions = (props) => {
  const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);
  const [dealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);
  const [state, setState] = useState(initialState);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [posSystemList, setPosSystemList] = useState([]);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetOptionsDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleOnChange = (e, isSelect, name) => {
    if (name == "gstTaxValue") {
      const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
      if (!rx_live.test(e.target.value)) {
        return false;
      }
    }

    if (isSelect === "select") {
      setBtnVisible(false);
      setMyOptionsData({
        ...myOptionsData,
        [name]: e,
      });
    } else {
      setBtnVisible(false);
      setMyOptionsData({
        ...myOptionsData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      AppsStudBuilderService.GetStudOptionsDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              if (response.data.responseData.length > 0) {
                setMyOptionsData((prevState) => ({
                  ...prevState,
                  studbuilderLinkURL: responseData.studBuilderURL,
                  internalUseLink: responseData.internalLinkSB,
                  showOnlyDiamondSearch: responseData.onlyDiamondSearch,
                  showDealerDiamondsSKU: responseData.stud_ShowSKU,
                  showScheduleViewing: responseData.showScheduleView,
                  showAddress: responseData.showAddresses,
                  showCallForPrice: responseData.callForPricestud,
                  applyGstTax: responseData.showGstPrice,
                  gstTaxValue:
                    responseData.taxRate == 0 ? "0" : responseData.taxRate,
                  showPinterestShare: responseData.showPinterestShare,
                  showTwitterShare: responseData.showTwitterShare,
                  showFacebookShare: responseData.showFacebookShare,
                  showFacebookLike: responseData.showFacebookLike,
                  showAddToCartButton: responseData.showAddToCartButtonStud,
                  ShoppingCartURL: responseData.diamondstudshoppingurl,
                  showViewCartButton: responseData.showViewCartButtonStud,
                  ShowInstagramPlus: responseData.showInstagramShare,
                  isShowEcommerce: responseData.isShowEcommerce,
                }));
              }
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        type: "StudBuilder",
        studBuilderURL: myOptionsData.studbuilderLinkURL,
        internalLinkSB: myOptionsData.internalUseLink,
        onlyDiamondSearch: myOptionsData.showOnlyDiamondSearch,
        cStud_ShowSKU: myOptionsData.showDealerDiamondsSKU,
        showScheduleView: myOptionsData.showScheduleViewing,
        showAddresses: myOptionsData.showAddress,
        callForPricestud: myOptionsData.showCallForPrice,
        showGstPrice: myOptionsData.applyGstTax,
        taxRate: myOptionsData.gstTaxValue ? myOptionsData.gstTaxValue : "0",
        showPinterestShare: myOptionsData.showPinterestShare,
        showTwitterShare: myOptionsData.showTwitterShare,
        showFacebookShare: myOptionsData.showFacebookShare,
        showFacebookLike: myOptionsData.showFacebookLike,
        showAddToCartButtonStud: myOptionsData.showAddToCartButton,
        diamondstudshoppingurl: myOptionsData.ShoppingCartURL,
        showViewCartButtonStud: myOptionsData.showViewCartButton,
        showInstagramShare: myOptionsData.ShowInstagramPlus,
      };
      setLoading(true);
      AppsStudBuilderService.UpdateStudOptionsDetails(inputData)
        .then((response) => {
          let message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Updated successfully.");
            handleGetOptionsDetails(dealerId);
          } else {
            NotificationManager.error("Data not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setBtnVisible(true);
  };

  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-3">
              {" "}
              {traslate("Options")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <div className="row ">
        <Spin spinning={loading}>
          <div className="col-md-12">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Setup")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Stud Builder URL")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter the URL of this tool on your site to enable social sharing and dynamic sizing."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="studbuilderLinkURL"
                          value={myOptionsData.studbuilderLinkURL}
                          onChange={(e) =>
                            handleOnChange(e, "", "studbuilderLinkURL")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Internal Use Link?")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="internalUseLink"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.internalUseLink}
                          onChange={(e) =>
                            handleOnChange(e, "select", "internalUseLink")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <label htmlFor="h1"> {traslate("Display")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show")} "{traslate("Only Diamond Search")}"
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showOnlyDiamondSearch"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showOnlyDiamondSearch}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showOnlyDiamondSearch")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Dealer SKU In Diamond Search")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showDealerDiamondsSKU"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showDealerDiamondsSKU}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showDealerDiamondsSKU")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Schedule Viewing")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the link which allows the customer the ability to schedule an appointment."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showScheduleViewing"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showScheduleViewing}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showScheduleViewing")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Addresses")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Allow address to show on detailpage."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showAddress"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showAddress}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showAddress")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Price")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show")} "{traslate("Call For Price")}"{" "}
                          {traslate("instead of the Price")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display “Call for Price” instead of the retail price or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showCallForPrice"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showCallForPrice}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showCallForPrice")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Apply GST Tax")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Toggle the ability to apply international tax."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="applyGstTax"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.applyGstTax}
                          onChange={(e) =>
                            handleOnChange(e, "select", "applyGstTax")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("GST Tax Value")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Set the value when applying the GST Tax option."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          maxLength="2"
                          name="gstTaxValue"
                          value={
                            myOptionsData.gstTaxValue
                              ? myOptionsData.gstTaxValue
                              : ""
                          }
                          onChange={(e) => handleOnChange(e, "", "gstTaxValue")}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Social")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Pinterest Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Pinterest “Pin it” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showPinterestShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showPinterestShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showPinterestShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Twitter Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Twitter “Tweet” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showTwitterShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showTwitterShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showTwitterShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Share” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showFacebookShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showFacebookShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showFacebookShare")
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Like")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Like” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showFacebookLike"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.showFacebookLike}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showFacebookLike")
                          }
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Instagram Share")}
                          <Tooltip
                            placement="right"
                            title={
                              "Choose whether to display the Instagram “Share” button or not."
                            }
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowInstagramPlus"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={myOptionsData.ShowInstagramPlus}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowInstagramPlus")
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {myOptionsData?.isShowEcommerce && (
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <div>
                      <label> {traslate("E-Commerce")} </label>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Shopping Cart Url")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="ShoppingCartURL"
                            value={myOptionsData.ShoppingCartURL}
                            onChange={(e) =>
                              handleOnChange(e, "", "ShoppingCartURL")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show Add To Cart Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose whether to display the “Add to Cart” button or not."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="showAddToCartButton"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={myOptionsData.showAddToCartButton}
                            onChange={(e) =>
                              handleOnChange(e, "select", "showAddToCartButton")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show")} "{traslate("View Cart")}"{" "}
                            {traslate("Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                `Choose whether to display the PayPal Shopping Cart's "View Cart" Button or not.`
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="showViewCartButton"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={myOptionsData.showViewCartButton}
                            onChange={(e) =>
                              handleOnChange(e, "select", "showViewCartButton")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
            <div className="row">
              <div className="col-md-12 mt-4">
                <Button
                  className={btnVisible ? "disabled-btn " : "primary-btn"}
                  disabled={btnVisible}
                  loading={submitLoading}
                  onClick={handleSubmit}
                >
                  {traslate("Update Settings")}
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StudBuilderOptions);
