import { httpAdminReport } from "../helper/http-common";
class AdminReportDataService {

    // REPORTS MODULE STARTS
    GetToolsReportsGridData(inputData) {
        return httpAdminReport.post("/AdminReport/GetToolsReportsGridData", inputData);
    }
    downloadToolDataCSV_ServerClick(inputData) {
        return httpAdminReport.post("/AdminReport/downloadToolDataCSV_ServerClick", inputData, {
            responseType: "arraybuffer",
        });
    }
    GetClientVendorRetailerReportsData(inputData) {
        return httpAdminReport.post("/AdminReport/ClientVendorRetailerReportsData", inputData);
    }
    LoadDataByClientID(inputData) {
        return httpAdminReport.post("/AdminReport/LoadDataByClientID", inputData);
    }
    downloadClientreportCSV_ServerClick(inputData) {
        return httpAdminReport.post("/AdminReport/downloadClientreportCSV_ServerClick", inputData);
    }
    GetDataRelatedRecord(inputData) {
        return httpAdminReport.post("/AdminReport/GetDataRelatedRecord", inputData);
    }
    DatarealatedDownload(inputData) {
        return httpAdminReport.post("/AdminReport/DatarealatedDownload", inputData);
    }
    GetJewelryLogReports(inputData) {
        return httpAdminReport.post("/AdminReport/GetJewelryLogReports", inputData);
    }
    jwellarylogDownload() {
        return httpAdminReport.post("/AdminReport/jwellarylogDownload");
    }
    permissionreportDownload() {
        return httpAdminReport.post("/AdminReport/permissionreportDownload", {
            responseType: "arraybuffer",
        });
    }
    GetSyncsClientList() {
        return httpAdminReport.post("/AdminReport/GetSyncsClientList");
    }
    SyncReportsData(inputData) {
        return httpAdminReport.post("/AdminReport/SyncReportsData", inputData);
    }
    downloadDataCSV_Synclist(inputData) {
        return httpAdminReport.post("/AdminReport/downloadDataCSV_Synclist", {...inputData,
            responseType: "arraybuffer",
        });
    }
    GetGlobalSyncData(inputData){
        return httpAdminReport.post("/AdminReport/GlobalReportData", inputData);
    }
    downloadDataCSV_Synclist
    // REPORTS MODULE ENDS

    // GLOBAL DIAMOND REPORT MODULE STARTS
    // GetSyncsClientList() {
    //     return httpAdminReport.post("/Admin_JewelryReport/CheckDealermembers");
    // }
    // GLOBAL DIAMOND REPORT ENDS
}

export default new AdminReportDataService();
