import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Input,
  Select,
  Button,
  Table,
  Modal,
  Switch,
  Space,
  Spin,
  Tooltip,
  Radio,
  Empty,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Create_icon from "../../assets/images/create_icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";
import moment from "moment";
import {
  setSelectSideMenu,
  setEditCollectionTierMenu,
} from "../../actions/headerMenu/headerMenu";

const { Option } = Select;

export const ManageCollection = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("All");
  const [allFilterValue, setAllFilterValue] = useState("");
  const [collectionList, setCollectionList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [delConsoleData, setDelConsoleData] = useState({
    collectionID: "",
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [dealerId, setDealerId] = useState(0);
  const [collectionDrpList, setCollectionDrpList] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [sortColumnName, setSortColumnName] = useState("collection");
  const [sortType, setSortType] = useState("asc");
  const [activeExpRow, setActiveExpRow] = useState();
  const [image, setImage] = useState("");
  const [isImageModal, setIsImageModal] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  const selectedDesignerName = localStorage.getItem("vendorDataForCollection")

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetCollectionsList(
            loginDetials.loginDetials.responseData.dealerId,
            pageNo,
            pageSize,
            "collection",
            "asc",
            filterValue,
            allFilterValue
          );
          handleGetBrandListbyDealer(
            loginDetials.loginDetials.responseData.dealerId
          );
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );

        }
      }
    }
  }, []);

  useEffect(() => {
    if (filterValue === "HomeOnly") {
      setAllFilterValue("");
    }
    if (filterValue === "VendorExtension") {
      setAllFilterValue("");
    }
  });

  const handleApplyFilters = () => {
    setTableKey(tableKey => tableKey + 1);
    setFilterValue(filterValue);
    setAllFilterValue(allFilterValue);
    handleGetCollectionsList(
      dealerId,
      pageNo,
      pageSize,
      sortColumnName,
      sortType,
      filterValue,
      allFilterValue
    );
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search..`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()

          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  const handleGetBrandListbyDealer = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    CatalogJewelryDataService.GetBrandListbyDealer(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setBrandList(responseData);
          setAllFilterValue(selectedDesignerName)
        } else {
          setBrandList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetCollectionsCount = (id) => {
    let inputData = {
      dealerID: id,
    };

    CatalogJewelryDataService.GetCollectionsCount(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData.collectionsCount;

        if (message === "Success") {
          setCollectionDrpList(responseData);
        } else {
          setCollectionDrpList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetCollectionsList = async (
    id,
    pageNo,
    pageSize,
    sortColumnName,
    sortType,
    filterValue,
    allFilterValue,
    filters
  ) => {
    let inputData = {
      dealerID: id,
      collectionType: filterValue,
      brandID: 0,
      sortBy: allFilterValue ? allFilterValue :  selectedDesignerName ? selectedDesignerName : "",
      pageNumber: pageNo,
      pageSize: pageSize,
      sortColName: sortColumnName || "collection",
      sortOrder: sortType || "ASC",
      searchBy: filters ? filters : selectedDesignerName ? selectedDesignerName : "",
      role: loginDetials.loginDetials.responseData.dealerMemberTypeName.toString(),
    };
    setLoading(true);
    if (filterValue === "All" || filterValue == "Vendor") {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    localStorage.removeItem("vendorDataForCollection")
    CatalogJewelryDataService.GetCollectionsList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setCollectionList(responseData);
          handleGetCollectionsCount(id);
          setDisableBtn(true);
        } else {
          setCollectionList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteModal = (row) => {
    setIsModalVisible(true);
    setDelConsoleData({
      collectionID: row.collectionID,
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDelConsoleData({});
  };

  const DeleteConsoleUploadData = () => {
    let inputData = {
      collectionID: parseInt(delConsoleData.collectionID),
      dealerID: dealerId.toString(),
    };

    CatalogJewelryDataService.DeleteCollectionStatusById(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Record Deleted Successfully."));
          handleGetCollectionsList(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            filterValue,
            allFilterValue
          );
        } else {
          NotificationManager.error(traslate("Record Not Deleted."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleCancel();
  };

  const handleIsSelectChange = (saveRow) => {
    let inputData = {
      collectionID: parseInt(saveRow.collectionID),
      // dealerID: parseInt(saveRow.dealerID),
      dealerID: parseInt(dealerId),
      status: parseInt(saveRow.isSelected === false ? 0 : 1),
    };
    CatalogJewelryDataService.UpdateVisibleStatus(inputData)
      .then((response) => {
        let message = response.data.message;

        if (message === "Success") {
          NotificationManager.success(traslate("Record Updated Successfully."));
          handleGetCollectionsList(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            filterValue,
            allFilterValue
          );
        } else {
          NotificationManager.error(traslate("Record Not Updated."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCollectionVendorEnable = (saveRow) => {
    let inputData = {
      collectionID: parseInt(saveRow.collectionID),
      retailerID: parseInt(saveRow.dealerID),
      enable: !saveRow.enabled_ ? 0 : 1,
    };
    CatalogJewelryDataService.ChangeCollectionVendorEnable(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Record Updated Successfully."));
          handleGetCollectionsList(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            filterValue,
            allFilterValue
          );
        } else {
          NotificationManager.error(traslate("Record Not Updated."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePricingVendorChange = (saveRow) => {
    let inputData = {
      collectionID: parseInt(saveRow.collectionID),
      retailerID: parseInt(dealerId)   ,
      dealerID:  String(saveRow.dealerID) || "",
      override: parseInt(
        !saveRow.overrideEnabled
          ? 0
          : saveRow.markupOverride
          ? saveRow.markupOverride
          : saveRow.override
      ),
      pricing: parseInt(saveRow.pricing === false ? 0 : 1),
      overrideValue: saveRow.overrideEnabled === false ? "0" : "1",
    };
    CatalogJewelryDataService.ChangeCollectionVendorPricing(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Record Updated Successfully."));
          handleGetCollectionsList(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            filterValue,
            allFilterValue
          );
        } else {
          NotificationManager.error(traslate("Record Not Updated."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnChange = (row, e, name) => {
    if (name === "isSelected") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return { ...x, isSelected: e };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionID == row.collectionID
      )[0];
      setCollectionList(temp);
      handleIsSelectChange(saveRow);
    }
    if (name === "pricing") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return { ...x, pricing: e };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionID == row.collectionID
      )[0];
      setCollectionList(temp);
      handlePricingVendorChange(saveRow);
    }
    if (name === "enabled") {
      const temp = collectionList.map((x) => {
        if (x.collectionID == row.collectionID) {
          return { ...x, enabled_: e };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionID == row.collectionID
      )[0];
      setCollectionList(temp);
      handleCollectionVendorEnable(saveRow);
    }
    if (name === "overrideEnabled") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return {
            ...x,
            overrideEnabled: e.target.value === "percentage",
          };
        } else return x;
      });
      setCollectionList(temp);
    }
    if (name === "markupOverride") {
      if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
        const temp = collectionList.map((x) => {
          if (x.collectionID === row.collectionID) {
            return {
              ...x,
              markupOverride: e.target.value,
            };
          } else return x;
        });
        setCollectionList(temp);
      } else {
        e.preventDefault();
      }
    }
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleOnSelectChange = (e, name) => {
    setDisableBtn(false);
    if (name == "filterValue") {
      setFilterValue(e);
    }
    if (name == "allFilterValue") {
      setAllFilterValue(e);
    }
  };

  const modifiedData = collectionList.map((item) => ({
    ...item,
    key: item.collectionID,
  }));

  const handleOpenImageModal = (row) => {
    setImage(row.collectionImage);
    setIsImageModal(true);
  };

  const handleCloseImageModal = (row) => {
    setImage("");
    setIsImageModal(false);
  };

  const handleEditRedirect = (id, value) => {
    dispatch(setEditCollectionTierMenu("true"));
    dispatch(setSelectSideMenu(["editCollection"]));
    navigate("/editCollection", { state: { key: id, filterVal: value } });
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 className="workarea__heading mt-0 mb-0">
              {traslate("Manage Collection")}
              {"(" + collectionList.length + ")"}
            </h4>
            <button className="primary-btn d-flex align-items-center">
              {" "}
              <img src={Create_icon} alt="" />{" "}
              <Link to={"/addCollection"} className="manage__Link">
                {traslate("Create Collection")}
              </Link>
            </button>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div
              className={
                dealerMemberTypeName != "Vendor" ? "input__block col-md-4" : ""
              }
            >
              {dealerMemberTypeName != "Vendor" ? (
                <Select
                  name="filterValue"
                  placeholder="Select Collections"
                  optionFilterProp="children"
                  className="border__grey"
                  onChange={(e) => handleOnSelectChange(e, "filterValue")}
                  value={filterValue}
                >
                  {
                    collectionDrpList?.map((item) => {
                      return (
                        <Option value={item.collectionID}>
                          {item.collectionCount}
                        </Option>
                      );
                    })}
                </Select>
              ) : null}
            </div>
            {(filterValue === "All") | (filterValue === "Vendor") &&
            dealerMemberTypeName != "Vendor" ? (
              <div
                className={
                  (filterValue === "All") | (filterValue === "Vendor") &&
                  dealerMemberTypeName != "Vendor"
                    ? "input__block col-md-4"
                    : ""
                }
              >
                <Select
                  name="allFilterValue"
                  optionFilterProp="children"
                  placeholder="Select Vendor"
                  className="border__grey"
                  onChange={(e) => handleOnSelectChange(e, "allFilterValue")}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={allFilterValue}
                >
                  <Option value=""> {traslate("Vendor")} </Option>
                  {brandList.map((item) => {
                    return (
                      <Option key={item.designerName} value={item.designerName}>
                        {item.designerName}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}
            <div
              className={
                dealerMemberTypeName != "Vendor"
                  ? "col-md-4 aplyfilt__btndiv"
                  : ""
              }
            >
              {dealerMemberTypeName != "Vendor" ? (
                <button
                  className={disableBtn ? "disabled-btn" : "primary-btn"}
                  disabled={disableBtn}
                  onClick={handleApplyFilters}
                >
                  {" "}
                  <i className="fa fa-search"></i> {traslate("Apply Filter")}
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div
            className={
              dealerMemberTypeName != "Vendor" ? "divForm row" : "divForm"
            }
          >
            <Spin spinning={loading} size="large">
              <div className="table__scroll mobile__topspace">
                <Table
                  key={tableKey}
                  pagination={{
                    showSizeChanger: true,
                    responsive: true,
                  }}
                  className="vdiamond__table"
                  columns={[
                    {
                      title: "",
                      dataIndex: "collectionImage",
                      width: 40,
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <div
                              className="infoIcon_ManageCollection"
                              onClick={() => handleOpenImageModal(row)}
                            >
                              <i className="fa fa-info-circle"></i>
                            </div>
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      title: traslate("Collection Name"),
                      dataIndex: "collection",
                      sorter: (a, b) =>
                        a.collection.localeCompare(b.collection),
                      showSorterTooltip: false,
                    },

                    {
                      title:
                        dealerMemberTypeName == "Vendor"
                          ? traslate("Brand Name")
                          : traslate("Vendor"),
                      dataIndex: "brandName",
                      ...getColumnSearchProps("brandName"),
                      key: "brandName",
                      sorter: (a, b) => a.brandName.localeCompare(b.brandName),
                      showSorterTooltip: false,
                    },

                    ...(dealerMemberTypeName == "Vendor"
                      ? [
                          {
                            title: traslate("Category"),
                            dataIndex: "collectionCategory",
                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            // width: 100,
                            sorter: (a, b) =>
                              a.collectionCategory.localeCompare(
                                b.collectionCategory
                              ),
                            render: (index, row) => (
                              <span>{row.collectionCategory}</span>
                            ),
                          },
                        ]
                      : []),

                    ...(dealerMemberTypeName != "Vendor"
                      ? [
                          {
                            title: traslate("Override"),
                            dataIndex: "override",
                            key: "override",
                            showSorterTooltip: false,
                            sorter: (a, b) => a.override - b.override,
                            render: (index, row) => (
                              <span>
                                {row.overrideEnabled == false
                                  ? "Disabled"
                                  : row.override + "%"}
                              </span>
                            ),
                          },
                        ]
                      : []),
                    {
                      title: traslate("Items"),
                      dataIndex: "items",
                      sorter: (a, b) => a.items - b.items,
                      showSorterTooltip: false,
                    },
                    {
                      title: traslate("Pricing"),
                      dataIndex: "pricing",
                      render: (item, row) => (
                        <div className="action__btns">
                          <Switch
                            name="pricing"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={row.pricing}
                            onChange={(e) => handleOnChange(row, e, "pricing")}
                          />
                        </div>
                      ),
                    },

                    {
                      title: (
                        <span className="font__bold d-flex">
                          {traslate("Visibility")}
                          {dealerMemberTypeName == "Retailer" ||
                          dealerMemberTypeName == "Admin" ? null : (
                            <Tooltip
                              placement="right"
                              className="ml-1"
                              title={traslate(
                                "This shows the collection on your own MasterLink, for preview purposes"
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          )}
                        </span>
                      ),
                      dataIndex: "isSelected",
                      render: (item, row) => (
                        <div className="action__btns">
                          <Switch
                            name="isSelected"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={row.isSelected}
                            onChange={(e) =>
                              handleOnChange(row, e, "isSelected")
                            }
                          />
                        </div>
                      ),
                    },
                    ...(dealerMemberTypeName == "Vendor"
                      ? [
                          {
                            title: (
                              <span
                                className={
                                  dealerMemberTypeName == "Vendor"
                                    ? "d-flex font__bold"
                                    : ""
                                }
                              >
                                {traslate("Enabled")}
                                <Tooltip
                                  placement="right"
                                  className="ml-1"
                                  title={traslate(
                                    "This shares the collection with your connected retailers"
                                  )}
                                >
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>
                              </span>
                            ),
                            dataIndex: "enabled_",
                            render: (item, row) => (
                              <div className="action__btns">
                                <Switch
                                  name="enabled"
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={row.enabled_ == true ? true : false}
                                  onChange={(e) =>
                                    handleOnChange(row, e, "enabled")
                                  }
                                />
                              </div>
                            ),
                          },
                        ]
                      : []),
                    ...(dealerMemberTypeName == "Vendor" &&
                    filterValue == "All" &&
                    !isFilter
                      ? [
                          {
                            title: traslate("Action"),
                            render: (index, row) => (
                              <div className="action__btns">
                                <div className="image__block">
                                  <div
                                    onClick={() =>
                                      handleEditRedirect(
                                        row.collectionID,
                                        filterValue
                                      )
                                    }
                                  >
                                    <img src={Edit_icon} alt="" />
                                  </div>
                                </div>
                                <div className="image__block">
                                  <img
                                    src={Delete_iconbl}
                                    onClick={() => {
                                      handleDeleteModal(row);
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ),
                          },
                        ]
                      : filterValue == "HomeOnly" && isFilter
                      ? [
                          {
                            title: traslate("Action"),
                            render: (index, row) => (
                              <div className="action__btns">
                                <div className="image__block">
                                  <div
                                    onClick={() =>
                                      handleEditRedirect(
                                        row.collectionID,
                                        filterValue
                                      )
                                    }
                                  >
                                    <img src={Edit_icon} alt="" />
                                  </div>
                                </div>
                                <div className="image__block">
                                  <img
                                    src={Delete_iconbl}
                                    onClick={() => {
                                      handleDeleteModal(row);
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ),
                          },
                        ]
                      : isFilter
                      ? [
                          {
                            title: traslate("Action"),
                            render: (index, row) => (
                              <div className="action__btns">
                                <div className="image__block">
                                  <div
                                    onClick={() =>
                                      handleEditRedirect(
                                        row.collectionID,
                                        filterValue
                                      )
                                    }
                                  >
                                    <img src={Edit_icon} alt="" />
                                  </div>
                                </div>
                                <div className="image__block">
                                  <img
                                    src={Delete_iconbl}
                                    onClick={() => {
                                      handleDeleteModal(row);
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ),
                          },
                        ]
                      : filterValue == "All" &&
                        !isFilter &&
                        dealerMemberTypeName !== "Vendor"
                      ? []
                      : filterValue == "Vendor" && isFilter
                      ? []
                      : filterValue == "VendorExtension" && isFilter
                      ? []
                      : []),
                  ]}
                  expandable={{
                    expandedRowRender: (row) => (
                      <div className="d-flex align-items-center">
                        <p className="m-0">
                          <label className="fontSize_12px">
                            {traslate("Updated Date")} :{" "}
                          </label>
                          <span className="mx-2">
                            {moment(row.modifiedDate).format("MM/DD/YYYY")}
                          </span>
                        </p>

                        <div
                          className={
                            dealerMemberTypeName == "Vendor"
                              ? "display__none"
                              : "display__tablecell"
                          }
                        >
                          <div className="managecollection__overide mx-3">
                            <label className="fontSize_12px">
                              {traslate("MarkUp Overrride")} :{" "}
                            </label>
                            <div className="radio__block mx-2">
                              <Radio
                                checked={row.overrideEnabled == false}
                                onChange={(e) =>
                                  handleOnChange(row, e, "overrideEnabled")
                                }
                                value="disabled"
                                option="Disable"
                              >
                                {traslate("Disable")}
                              </Radio>
                            </div>
                            <div className="radio__block">
                              <Radio
                                checked={row.overrideEnabled == true}
                                onChange={(e) =>
                                  handleOnChange(row, e, "overrideEnabled")
                                }
                                value="percentage"
                                option="Percentage"
                              >
                                {traslate("Percent")}
                              </Radio>
                            </div>
                            <div className="input__block m-0">
                              <div className="input__content">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  step="1"
                                  min="0"
                                  max="100"
                                  disabled={
                                    row.overrideEnabled == false ? true : false
                                  }
                                  onChange={(e) => {
                                    e.target.value = e.target.value.slice(0, 3);
                                    handleOnChange(row, e, "markupOverride");
                                  }}
                                  value={
                                    row.overrideEnabled == false
                                      ? ""
                                      : row.markupOverride === undefined
                                      ? row.override
                                      : row.markupOverride !== "" ||
                                        row.markupOverride === ""
                                      ? row.markupOverride
                                      : row.override
                                  }
                                  className={
                                    row.overrideEnabled == false
                                      ? "disabled"
                                      : ""
                                  }
                                />
                                <span className="ml-1">%</span>
                              </div>
                            </div>
                            <div className="">
                              <div className="save__btn">
                                <i
                                  class="fa fa-check"
                                  aria-hidden="true"
                                  onClick={() => {
                                    handlePricingVendorChange(row);
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                    rowExpandable: (record) => true,
                    expandedRowKeys: activeExpRow,
                    onExpand: (expanded, record) => {
                      const keys = [];
                      if (expanded) {
                        keys.push(record.collectionID);
                      }
                      setActiveExpRow(keys);
                    },
                  }}
                  onChange={(pagination, filters, sorters) => {
                    document
                      .getElementsByClassName("content__area")[0]
                      .scroll(0, 0);
                  }}
                  dataSource={modifiedData}
                  scroll={{ y: 800 }}
                />
              </div>
            </Spin>
          </div>
        </div>

        <Modal
          title={traslate("Delete Collection")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={DeleteConsoleUploadData}
            >
              {traslate("Delete")}
            </Button>,
          ]}
        >
          {traslate("Are you sure , you want to delete this Collection?")}
        </Modal>
        <Modal
          title={traslate("Image")}
          className="modalvdiamond__section"
          centered
          visible={isImageModal}
          onOk={handleCloseImageModal}
          onCancel={handleCloseImageModal}
          footer={[
            <Button
              key="back"
              className="primary-btn"
              onClick={handleCloseImageModal}
            >
              {traslate("Cancel")}
            </Button>,
          ]}
        >
          {image == "" ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <img style={{ width: "100%" }} src={image} alt="" />
          )}
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCollection);
