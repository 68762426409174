import {
  RETAILER_ID,
  MESSAGE_ID,
  TAB_NAME,
  DEFAULT_MESSAGE,
  PAGE_TAB,
} from "./types";

export const setReduxRetailerId = (data) => async (dispatch) => {
  try {
    dispatch({
      type: RETAILER_ID,
      payload: data,
    });
  } catch (err) {}
};

export const setReduxMessageId = (data) => async (dispatch) => {
  try {
    dispatch({
      type: MESSAGE_ID,
      payload: data,
    });
  } catch (err) {}
};

export const setReduxTabName = (data) => async (dispatch) => {
  try {
    dispatch({
      type: TAB_NAME,
      payload: data,
    });
  } catch (err) {}
};

export const setReduxDefaultMessage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DEFAULT_MESSAGE,
      payload: data,
    });
  } catch (err) {}
};

export const setPageTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PAGE_TAB,
      payload: data,
    });
  } catch (err) {}
};
