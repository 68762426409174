import React, { useEffect, useState } from "react";
import { Select, Button, Modal, Table, Spin } from "antd";
import home_icns from "../../../assets/images/home_icns.jpg";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ManageUploadServices from "../../../services/manageupload.service";
const { Option } = Select;
const RetailerMarketing = () => {
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [fileDeleteData, setFileDeleteData] = useState({
    FolderName: "",
    Type: "",
    Id: "",
  });
  const [loading, setLoading] = useState(false);
  const [manageUploadDetails, setManageUploadDetails] = useState([]);
  const [manageUploadDetailsById, setManageUploadDetailsById] = useState([]);
  const [manageUserDetails, setManageUserDetails] = useState({
    folderNameUserDetails: "",
    folderFileCountUserDetails: "",
    folderFileId: "",
  });
  const [dealerID, setDealerId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [recordId, setRecord] = useState({});
  const [headername, setHeaderName] = useState("");
  const [disableFileData, setDisableFileData] = useState(true);
  const [disableFileDataById, setDisableFileDataById] = useState(false);
  const [disableUploadMedia, setDisableUploadMedia] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const [fixDropValue, setFixDRopValue] = useState("");
  const [dropDownListSelect, setDropDownListSelect] = useState({
    HomeShowName: "",
    dealerIdSet: 0,
  });

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleLoadMarketingData(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleGetFileData = (id, dealerFolerId) => {
    setDisableFileData(true);
    setDisableFileDataById(false);
    let inputData = {
      dealerId: id.toString(),
      dealerFolerId: dealerFolerId,
    };
    setLoading(true);
    try {
      ManageUploadServices.LoadMarketingData_Vendor(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setManageUploadDetails(responseData);
            setLoading(false);
          } else {
            setManageUploadDetails([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: traslate("Name"),
      dataIndex: "folderName",
      key: "folderName",
      render: (item, row) => {
        return (
          <div>
            <i class="fa fa-folder mr-2" aria-hidden="true"></i>
            <span>{row.folderName}</span>
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dropDownListSelect.dealerIdSet, record);
          },
        };
      },
    },
    {
      title: traslate("Kind"),
      dataIndex: "type",
      key: "type",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dropDownListSelect.dealerIdSet, record);
          },
        };
      },
    },
    {
      title: traslate("Modified"),
      dataIndex: "lastWriteTime",
      key: "lastWriteTime",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dropDownListSelect.dealerIdSet, record);
          },
        };
      },
    },
    {
      title: traslate("Size"),
      dataIndex: "sizeinMB",
      key: "sizeinMB",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dropDownListSelect.dealerIdSet, record);
          },
        };
      },
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      render: (item, row) => {
        return (
          <React.Fragment>
            <Link
              to=""
              onClick={() => {
                handleGetDownloadFolderZip(row);
              }}
            >
              {traslate("Download")}
            </Link>
          </React.Fragment>
        );
      },
    },
  ];

  const handleGetDownloadFolderZip = (row) => {
    let inputData = {
      folderName: row.folderName.toString(),
      id: row.id.toString(),
      dealerFolderName: row.dealerFolderName.toString(),
      dealerID: dealerID.toString(),
    };
    setLoading(true);
    try {
      ManageUploadServices.DownloadMarketingZipFiler(inputData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let filename =
            row.folderName +
            ".zip";
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns1 = [
    {
      title: traslate("Name"),
      dataIndex: "folderName",
      key: "folderName",
      render: (item, row) => {
        return (
          <div>
            <i class="fa fa-folder mr-2" aria-hidden="true"></i>
            <span className="linkText">{row.folderName}</span>
          </div>
        );
      },
    },
    {
      title: traslate("Kind"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: traslate("Modified"),
      dataIndex: "lastWriteTime",
      key: "lastWriteTime",
    },
    {
      title: traslate("Size"),
      dataIndex: "sizeinMB",
      key: "sizeinMB",
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      render: (item, row) => {
        return (
          <React.Fragment>
            <Link
              to=""
              onClick={() => {
                handleGetDownloadImage(row);
              }}
            >
              {traslate("Download")}
            </Link>
          </React.Fragment>
        );
      },
    },
  ];

  const handleGetDownloadImage = (row) => {
    window.open(row.path);
  };

  const handleShowDataFileByIdNew = (dealerid, record) => {
    setRecord(record);
    setHeaderName(record.folderName);
    setDisableFileData(false);
    setDisableFileDataById(true);
    try {
      let inputData = {
        dealerFolerId: record.id.toString(),
        dealerId: dealerid.toString(),
      };
      setLoading(true);
      ManageUploadServices.LoadMarketingData_Vendor(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setManageUploadDetailsById(responseData);
              setLoading(false);
            } else {
              setManageUploadDetailsById([]);
              setLoading(false);
            }
          } else {
            setManageUploadDetailsById([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const DeleteFileDelaerData = () => {
    try {
      let inputData = {
        fileNamePath: "",
        folderName: fileDeleteData.FolderName,
        id: fileDeleteData.Id,
        type: fileDeleteData.Type,
        dealerId: "",
        dealerFolerId: "",
      };

      ManageUploadServices.RemoveFileandFolder(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            handleGetFileData(dealerID, manageUserDetails.folderFileId);
            NotificationManager.success(traslate("Folder Removed Successfully."));
          } else {
            NotificationManager.error(traslate("Folder Not Removed."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setDeleteVisible(false);
    setFileDeleteData({});
  };

  //  delete api for ImageId

  const handleCancel4 = () => {
    setDisableFileData(true);
    setDisableFileDataById(false);
    setDisableUploadMedia(false);
    setHeaderName("");
  };

  // DropDown API

  const handleLoadMarketingData = (id) => {
    let inputData = {
      dealerId: id,
      dealerFolerId: 0,
    };
    setLoading(true);
    try {
      ManageUploadServices.BindFolders(inputData)
        .then((response) => {
          let message = response.data.message;
          let bindFoldersListData = response.data.responseData.bindFoldersList;
          if (message === "Success") {
            setLoading(false);
            setDropDownList(bindFoldersListData);
            if (response.data.responseData.bindFoldersList.length > 0) {
              handleGetFileData(
                response.data.responseData.bindFoldersList[0].dealerID,
                response.data.responseData.bindFoldersList[0].id
              );
              setFixDRopValue(response.data.responseData.bindFoldersList[0].id);
              setDropDownListSelect({
                dealerIdSet:
                  response.data.responseData.bindFoldersList[0].dealerID,
              });
            }
          } else {
            setDropDownList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDropDownChange = (e, key) => {
    setFixDRopValue(e);
    setHeaderName("");
    handleGetFileData(key.key, e);
    setDropDownListSelect({
      dealerIdSet: key.key,
    });
  };
  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("Marketing")}
                  </h4>
                </div>

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-6 text-right d-flex align-items-center justify-content-end">
                      <label>{traslate("Connected Vendors List")}</label>
                    </div>

                    <div className="col-md-6">
                      <div className="input__block mb-0">
                        <Select
                          showSearch
                          className="border__grey"
                          placeholder="Select Option"
                          optionFilterProp="children"
                          value={fixDropValue}
                          onChange={(e, key) => handleDropDownChange(e, key)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {dropDownList &&
                            dropDownList.map((item, i) => {
                              return (
                                <Option value={item.id} key={item.dealerID}>
                                  {`${item.folderName}(${item.folderFileCount})`}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li
                        class="breadcrumb-item linkText"
                        onClick={handleCancel4}
                      >
                        <img src={home_icns} alt="" onClick={handleCancel4} />
                      </li>
                      {manageUserDetails.folderNameUserDetails ? (
                        <li
                          class="breadcrumb-item linkText"
                          onClick={handleCancel4}
                        >
                          {manageUserDetails.folderNameUserDetails
                            ? manageUserDetails.folderNameUserDetails
                            : null}{" "}
                        </li>
                      ) : null}
                      {headername ? (
                        <li class="breadcrumb-item linkText">
                          {headername ? headername : null}
                        </li>
                      ) : null}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="tradeshow_tbldiv table-responsive">
                {disableFileData ? (
                  <Spin spinning={loading}>
                    <Table
                      locale={{
                        emptyText:
                          traslate("This folder currently has no files available. Please contact the vendor directly for more information."),
                      }}
                      dataSource={manageUploadDetails}
                      columns={columns}
                      scroll={{ y: 800 }}
                    />
                  </Spin>
                ) : null}

                {disableFileDataById ? (
                  <Spin spinning={loading}>
                    <Table
                      dataSource={manageUploadDetailsById}
                      columns={columns1}
                      scroll={{ y: 800 }}
                    />
                  </Spin>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        className="modalconsupld__section"
        centered
        visible={deleteVisible}
        onOk={() => setDeleteVisible(false)}
        onCancel={() => setDeleteVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={() => setDeleteVisible(false)}>
            {traslate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={DeleteFileDelaerData}>
            {traslate("Delete")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 mt-4 text-center">
                    <p>
                      {traslate(
                        "Are You Sure You Want To Delete This Folder ?"
                      )}
                    </p>
                    <h5>{traslate("THIS CAN NOT BE UNDO")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RetailerMarketing;
