import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import {
  setChecklinkHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
  handleResetIframe,
} from "../../../actions/headerMenu/headerMenu";

const DepartmentsMasterLink = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Receiving the message from child Masterlink
    window.onpageshow = () => {
      dispatch(handleResetIframe(false));
    };
    window.addEventListener("message", handleIframeTask);
    const iframe = document.querySelector("iframe");
    iframe.contentWindow.postMessage("Show", "*");
  }, []);

  const handleIframeTask = (e) => {
    if (e.data === "navigate to MyVendors") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu("myvendors"));
      navigate("/myvendors");
    }
    if (e.data === "navigate to MasterLinkOptions") {
      dispatch(setSelectSideMenu("masterlinkOptions"));
      navigate("/masterlinkOptions");
    }
    if (e.data === "submitted form") {
      document.getElementsByClassName("content__area")[0].scroll(0, 0);
    }
  };
  //   Mgr Departments https://gemmasterlink.brainvire.net/
  // const src = `https://jc2-mlink.mgr.brainvire.net/#/department/${loginDetials.responseData.dealerId}`;

  const src = `https://gemmasterlink.brainvire.net/#/department/${loginDetials.responseData.dealerId}`;
  const hideSpinner = () => {
    setLoading(false);
  };
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <iframe
          onLoad={() => hideSpinner()}
          width="100%"
          className="application__preview"
          //height="1000px"
          src={src}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        ></iframe>
      </Spin>
    </React.Fragment>
  );
};

export default DepartmentsMasterLink;
