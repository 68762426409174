import React, { useState, useEffect } from "react";
import { Select, Checkbox, Switch, Table, Button, Modal, Spin } from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import { NotificationManager } from "react-notifications";
import AdminToolsDataService from "../../../services/admin-tools.service";
import traslate from "../../../i18n/translate";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import { useSelector } from "react-redux";

const initialNewPermission = {
  id: 0,
  pageNumber: "",
  firstTierPageName: "",
  secondTierPageName: "",
  thirdTierPageName: "",
  fourTierPageName: "",
  vendorSuperSwitch: true,
  vendorAdminSwitch: true,
  vendorUserSwitch: true,
  vendorDiamondSuperSwitch: true,
  VendorDiamondAdminSwitch: true,
  VendorDiamondUserSwitch: true,
  retailerAdminSwitch: true,
  retailerSuperSwitch: true,
  retailerUserSwitch: true,
  adminAdminSwitch: true,
  adminSuperSwitch: true,
  adminUserSwitch: true,
  combinerAdminSwitch: true,
  combinerSuperSwitch: true,
  combinerUserSwitch: true,
  organizationSuperSwitch: true,
};
const initialValidations = {
  pageNumberVal: "",
  firstTierPageNameVal: "",
  secondTierPageNameVal: "",
  thirdTierPageNameVal: "",
  fourTierPageNameVal: "",
  allEmptyVal: "",
};

const Permissions = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [isedit, setIsEdit] = useState(true);
  const [PermissionData, setPermissionData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [tierVisible, setTierVisible] = useState(false);
  const [newPermission, setNewPermission] = useState(initialNewPermission);
  const [validations, setValidations] = useState(initialValidations);
  const [filterVal, setFilterVal] = useState({
    tierOne: "Admin",
    tierTwo: "Tools",
  });
  const [tierOneList, setTierOneList] = useState([]);
  const [tierTwoList, setTierTwoList] = useState([]);
  const [visible3, setVisible3] = useState(false);

  const [delPermissionData, setDelPermissionData] = useState({
    id: "",
  });
  const [tierEntry, setTierEntry] = useState({
    tierPageNameType: "",
    firstTierDep: "",
    tierPageNameValue: "",
  });
  const [tiervalidations, setTierValidations] = useState({
    firstTierDepVal: "",
    tierPageNameTypeVal: "",
    tierPageNameValueVal: "",
  });
  const [dealerId, setDealerId] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
    handleGetDynamicPageAccessDetailsList(pageNo, pageSize);
    handleGetTierDtlsByType();
    handleGetTierTwoDtlsByType(filterVal.tierOne);
  }, []);

  const { Option } = Select;

  const handleModalOpen = () => {
    setVisible(true);
    setIsEdit(false);
  };

  const handleModalTierOpen = () => {
    setTierVisible(true);
  };

  const handleModalTierCancel = () => {
    setTierVisible(false);
    setTierEntry({
      tierPageNameType: "",
      tierPageNameValue: "",
    });
    setTierValidations({
      tierPageNameTypeVal: "",
      tierPageNameValueVal: "",
    });
  };

  const handleTierSave = async () => {
    const isValid = handleTierValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        id: 0,
        tierName: tierEntry.tierPageNameValue,
        firstTierName: tierEntry.firstTierDep ? tierEntry.firstTierDep : "",
        type: tierEntry.tierPageNameType,
        dealerID: dealerId,
      };
      setLoading(true);
      await AdminToolsDataService.AddUpdateTierData(inputData)
        .then((response) => {
          var message = response.data.message;
          var returnId = response.data.responseData.returnId;
          setLoading(false);
          if (message === "Success") {
            if (returnId == 1) {
              NotificationManager.success(traslate("Record Updated Successfully."));
              handleGetTierDtlsByType();
            } else if (returnId == 2) {
              NotificationManager.error(traslate("Record already Exists."));
            }
          } else {
            NotificationManager.error(traslate("Record Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleModalTierCancel();
  };

  const handleInputChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setTierEntry({
        ...tierEntry,
        [name]: e,
      });
    } else {
      setTierEntry({
        ...tierEntry,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleTierValidation = () => {
    const tiervalidations = {
      tierPageNameTypeVal: "",
      tierPageNameValueVal: "",
      firstTierDepVal: "",
    };
    let isValid = true;
    if (!tierEntry.tierPageNameType) {
      isValid = false;
      tiervalidations.tierPageNameTypeVal = traslate("Tier Name is Compulsory");
    }
    if (!tierEntry.tierPageNameValue || tierEntry.tierPageNameValue !== "") {
      isValid = false;
      tiervalidations.tierPageNameValueVal = traslate(
        "Tier Value is Compulsory"
      );
    }
    if (tierEntry.tierPageNameType == "Second") {
      if (!tierEntry.firstTierDep) {
        isValid = false;
        tiervalidations.firstTierDepVal = traslate(
          "First Tier Value is Compulsory"
        );
      }
    }
    if (!isValid) {
      setTierValidations(tiervalidations);
    }
    return isValid;
  };

  const handleCancel = () => {
    setVisible(false);
    setIsEdit(true);
    setNewPermission(initialNewPermission);
    setValidations(initialValidations);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setNewPermission({
        ...newPermission,
        [name]: e,
      });
    } else {
      setNewPermission({
        ...newPermission,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "pageNumber") {
        setValidations((prevdata) => ({
          ...prevdata,
          pageNumberVal: "",
        }));
      }
      if (e.target.name === "firstTierPageName") {
        setValidations((prevdata) => ({
          ...prevdata,
          firstTierPageNameVal: "",
        }));
      }
      if (e.target.name === "secondTierPageName") {
        setValidations((prevdata) => ({
          ...prevdata,
          secondTierPageNameVal: "",
        }));
      }
      if (e.target.name === "thirdTierPageName") {
        setValidations((prevdata) => ({
          ...prevdata,
          thirdTierPageNameVal: "",
        }));
      }
      if (e.target.name === "fourTierPageName") {
        setValidations((prevdata) => ({
          ...prevdata,
          fourTierPageNameVal: "",
        }));
      }
    }
  };

  const handleOnSelectChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (name === "tierOne") {
      handleGetTierTwoDtlsByType(e);
      setFilterVal({
        tierOne: e,
        tierTwo: "",
      });
    } else {
      if (isSelect === "select") {
        setFilterVal({
          ...filterVal,
          [name]: e,
        });
      } else {
        setFilterVal({
          ...filterVal,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleGetTierDtlsByType = async () => {
    let inputData = {
      type: "one",
    };
    setLoading(true);
    await AdminToolsDataService.GetTierDtlsByType(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setTierOneList(responseData);
        } else {
          setTierOneList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelCancel = () => {
    setVisible3(false);
    setDelPermissionData({
      id: "",
    });
  };

  const handleDeleteTierDetails = async () => {
    let inputData = {
      permisssionID: parseInt(delPermissionData.id),
    };
    setLoading(true);
    await AdminToolsDataService.DeleteTierDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Record Deleted Successfully"));
          handleGetDynamicPageAccessDetailsList(pageNo, pageSize);
          setVisible3(false);
        } else {
          NotificationManager.success(traslate("Record Not Deleted"));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetTierTwoDtlsByType = async (id) => {
    let inputData = {
      type: "two",
      parentvalue: id,
    };
    setLoading(true);
    await AdminToolsDataService.GetTierDtlsByType(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setTierTwoList(responseData);
        } else {
          setTierTwoList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValidation = () => {
    const {
      pageNumber,
      firstTierPageName,
      secondTierPageName,
      thirdTierPageName,
      fourTierPageName,
    } = newPermission;
    const validations = {
      pageNumberVal: "",
      firstTierPageNameVal: "",
      secondTierPageNameVal: "",
      thirdTierPageNameVal: "",
      fourTierPageNameVal: "",
      allEmptyVal: "",
    };
    let isValid = true;

    if (secondTierPageName !== "" || thirdTierPageName !== "") {
      if (!firstTierPageName.trim()) {
        isValid = false;
        validations.firstTierPageNameVal = traslate(
          "First Tier Page Name is compulsory"
        );
      }
    }

    if (thirdTierPageName !== "") {
      if (!secondTierPageName.trim()) {
        isValid = false;
        validations.secondTierPageNameVal = traslate(
          "Second Tier Page Name is compulsory"
        );
      }
    }

    if (
      pageNumber == "" &&
      firstTierPageName == "" &&
      secondTierPageName == "" &&
      thirdTierPageName == ""
    ) {
      isValid = false;
      validations.allEmptyVal = traslate("Selecting Any Field is compulsory");
    }

    if (!isValid) {
      setValidations(validations);
    }
    return isValid;
  };

  const handleGetDynamicPageAccessDetailsList = (pageNo, pageSize) => {
    let inputData = {
      oneTierPageName: filterVal.tierOne,
      twoTierPageName: filterVal.tierTwo,
    };
    setLoading(true);
    AdminToolsDataService.GetDynamicPageAccessDetailsList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setPermissionData(responseData);
          setDisableBtn(true);
          if (tableLoading) {
            setTableLoading(false);
          }
        } else {
          if (tableLoading) {
            setTableLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (tableLoading) {
          setTableLoading(false);
        }
      })
      .finally(() => {
        setTableLoading(false);
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    if (!isedit) {
      const isValid = handleValidation();
      if (!isValid) {
        return false;
      }
    }
    try {
      let inputData = {
        id: parseInt(id),
        pageNumber: 0,
        oneTierPageName: firstTierPageName,
        twoTierPageName: secondTierPageName,
        threeTierPageName: thirdTierPageName,
        fourTierPageName: fourTierPageName,
        vendorSuper: vendorSuperSwitch,
        vendorAdmin: vendorAdminSwitch,
        vendorUser: vendorUserSwitch,
        retailerSuper: retailerSuperSwitch,
        retailerAdmin: retailerAdminSwitch,
        retailerUser: retailerUserSwitch,
        adminSuper: adminSuperSwitch,
        adminAdmin: adminAdminSwitch,
        adminUser: adminUserSwitch,
        vendorDiamondSuper: vendorDiamondSuperSwitch,
        vendorDiamondAdmin: VendorDiamondAdminSwitch,
        vendorDiamondUser: VendorDiamondUserSwitch,
      };
      setLoading(true);
      await AdminToolsDataService.SaveDynamicPageAccessDetails(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success("Permission Updated Successfully.");

            handleGetDynamicPageAccessDetailsList(pageNo, pageSize);
          } else {
            if ((responseData.returnId = 1)) {
              NotificationManager.error(responseData.returnMessage);
            } else {
              NotificationManager.error(traslate("Permission Not Updated."));
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();
  };

  const {
    id,
    pageNumber,
    firstTierPageName,
    secondTierPageName,
    thirdTierPageName,
    fourTierPageName,
    vendorSuperSwitch,
    vendorAdminSwitch,
    vendorUserSwitch,
    vendorDiamondSuperSwitch,
    VendorDiamondAdminSwitch,
    VendorDiamondUserSwitch,
    retailerAdminSwitch,
    retailerSuperSwitch,
    retailerUserSwitch,
    adminAdminSwitch,
    adminSuperSwitch,
    adminUserSwitch,
  } = newPermission;

  const {
    pageNumberVal,
    firstTierPageNameVal,
    secondTierPageNameVal,
    thirdTierPageNameVal,
    fourTierPageNameVal,
  } = validations;

  const handleEditModal = (row) => {
    setVisible(true);
    setNewPermission({
      id: row.id,
      pageNumber: row.pageNumber,
      firstTierPageName: row.oneTierPageName,
      secondTierPageName: row.twoTierPageName,
      thirdTierPageName: row.threeTierPageName,
      fourTierPageName: row.fourTierPageName,
      vendorSuperSwitch: row.vendorSuper,
      vendorAdminSwitch: row.vendorAdmin,
      vendorUserSwitch: row.vendorUser,
      vendorDiamondSuperSwitch: row.vendorDiamondSuper,
      VendorDiamondAdminSwitch: row.vendorDiamondAdmin,
      VendorDiamondUserSwitch: row.vendorDiamondUser,
      retailerAdminSwitch: row.retailerAdmin,
      retailerSuperSwitch: row.retailerSuper,
      retailerUserSwitch: row.retailerUser,
      adminAdminSwitch: row.adminAdmin,
      adminSuperSwitch: row.adminSuper,
      adminUserSwitch: row.adminUser,
    });
  };

  const handleDeleteModal = (row) => {
    setVisible3(true);
    setDelPermissionData({
      id: row.id,
      dealerID: row.dealerID,
    });
  };

  const handleApplyFilters = () => {
    handleGetDynamicPageAccessDetailsList(pageNo, pageSize);
  };

  return (
    <React.Fragment>
      <div className="permission_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("Permissions")}
                  </h4>
                </div>
                <div className="col-lg-6">
                  <div className="add__btndiv d-flex justify-content-end">
                    <button
                      className="primary-btn d-flex align-items-center"
                      onClick={handleModalOpen}
                    >
                      {" "}
                      <img src={Create_icon} alt="" />
                      <span>{traslate("Add New")} </span>
                    </button>
                    <button
                      className="primary-btn ml-2 d-flex align-items-center"
                      onClick={handleModalTierOpen}
                    >
                      {" "}
                      <img src={Create_icon} alt="" />
                      <span>{traslate("Add New Tier")} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="permission_tbldiv">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("Select 1 Tier PageName")}
                        <span className="mandatory">*</span>{" "}
                      </label>
                      <Select
                        value={filterVal.tierOne ? filterVal.tierOne : null}
                        name="tierOne"
                        onChange={(e) =>
                          handleOnSelectChange(e, "select", "tierOne")
                        }
                        placeholder="Select Type"
                        showSearch
                        optionFilterProp="children"
                        className={"border__grey"}
                      >
                        <Option value=""> {traslate("Select Type")} </Option>

                        {tierOneList &&
                          tierOneList.map((item) => {
                            return (
                              <Option key={item?.id} value={item.id}>
                                {item.value}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("Select 2 Tier PageName")}
                        <span className="mandatory">*</span>{" "}
                      </label>
                      <Select
                        value={filterVal.tierTwo ? filterVal.tierTwo : null}
                        name="tierTwo"
                        onChange={(e) =>
                          handleOnSelectChange(e, "select", "tierTwo")
                        }
                        placeholder="Select Type"
                        showSearch
                        optionFilterProp="children"
                        className={"border__grey"}
                      >
                        <Option value=""> {traslate("Select Type")} </Option>

                        {tierTwoList &&
                          tierTwoList.map((item) => {
                            return (
                              <Option key={item?.id} value={item.id}>
                                {item.value}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-4 aplyfilt__btndiv">
                    <button
                      className={
                        disableBtn
                          ? "disabled-btn mt-2rem mobile__bottomspace"
                          : "primary-btn mt-2rem mobile__bottomspace"
                      }
                      disabled={disableBtn}
                      onClick={() => handleApplyFilters()}
                    >
                      {" "}
                      <i className="fa fa-search"></i>{" "}
                      {traslate("Apply Filter")}
                    </button>
                  </div>
                </div>
                <Spin
                  spinning={tableLoading ? tableLoading : loading}
                  size="large"
                >
                  <Table
                    columns={[
                      {
                        title: traslate("3 Tier PageName"),
                        dataIndex: "threeTierPageName",
                        Key: "threeTierPageName",
                        // fixed: "left",
                        render: (item, row) => {
                          return (
                            <div className="input__block">
                              <p className="font__bold">
                                {row.threeTierPageName}
                              </p>
                            </div>
                          );
                        },
                        width: 150,
                      },
                      {
                        title: traslate("Vendor Super"),
                        dataIndex: "vendorSuper",
                        Key: "vendorSuper",
                        render: (item, row) => {
                          return <Checkbox checked={row.vendorSuper} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor Admin"),
                        dataIndex: "vendorAdmin",
                        Key: "vendorAdmin",
                        render: (item, row) => {
                          return <Checkbox checked={row.vendorAdmin} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor User"),
                        dataIndex: "vendorUser",
                        Key: "vendorUser",
                        render: (item, row) => {
                          return <Checkbox checked={row.vendorUser} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor-Diamond Super"),
                        dataIndex: "vendorDiamondSuper",
                        Key: "vendorDiamondSuper",
                        render: (item, row) => {
                          return <Checkbox checked={row.vendorDiamondSuper} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor-Diamond Admin"),
                        dataIndex: "vendorDiamondAdmin",
                        Key: "vendorDiamondAdmin",
                        render: (item, row) => {
                          return <Checkbox checked={row.vendorDiamondAdmin} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor-Diamond User"),
                        dataIndex: "vendorDiamondUser",
                        Key: "vendorDiamondUser",
                        render: (item, row) => {
                          return <Checkbox checked={row.vendorDiamondUser} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Retailer Super"),
                        dataIndex: "retailerSuper",
                        Key: "retailerSuper",
                        render: (item, row) => {
                          return <Checkbox checked={row.retailerSuper} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Retailer Admin"),
                        dataIndex: "retailerAdmin",
                        Key: "retailerAdmin",
                        render: (item, row) => {
                          return <Checkbox checked={row.retailerAdmin} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Retailer User"),
                        dataIndex: "retailerUser",
                        Key: "retailerUser",
                        render: (item, row) => {
                          return <Checkbox checked={row.retailerUser} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Admin Super"),
                        dataIndex: "adminSuper",
                        Key: "adminSuper",
                        render: (item, row) => {
                          return <Checkbox checked={row.adminSuper} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Admin Admin"),
                        dataIndex: "adminAdmin",
                        Key: "adminAdmin",
                        render: (item, row) => {
                          return <Checkbox checked={row.adminAdmin} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Admin User"),
                        dataIndex: "adminUser",
                        Key: "adminUser",
                        render: (item, row) => {
                          return <Checkbox checked={row.adminUser} />;
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Actions"),
                        dataIndex: "save",
                        render: (text, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => {
                                  handleEditModal(row);
                                }}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                        width: 100,
                      },
                    ]}
                    dataSource={PermissionData}
                    onChange={(pagination, filters, sorters) => {
                      document
                        .getElementsByClassName("content__area")[0]
                        .scroll(0, 0);
                    }}
                    pagination={{
                      pageSizeOptions: [10, 20, 50, 100],
                      responsive: true,
                      showSizeChanger: true,
                    }}
                    scroll={{ y: 300 }}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={traslate("Permissions")}
                className="modal__permission"
                centered
                visible={visible}
                width={600}
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleSubmit}>
                    {traslate("Submit")}
                  </Button>,
                ]}
              >
                {validations.allEmptyVal && (
                  <p className="error-color-red">{validations.allEmptyVal}</p>
                )}
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Page Number")} :</label>
                              <input
                                type="int"
                                placeholder="Enter pageNumber"
                                name="pageNumber"
                                value={pageNumber}
                                onChange={handleOnChange}
                                className={pageNumberVal && "border__red"}
                              />
                              <div>
                                {pageNumberVal && (
                                  <p className="error-color-red">
                                    {pageNumberVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Select 1 Tier PageName")}
                              </label>
                              <Select
                                value={
                                  firstTierPageName ? firstTierPageName : null
                                }
                                name="firstTierPageName"
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "firstTierPageName"
                                  )
                                }
                                placeholder="Select Type"
                                showSearch
                                optionFilterProp="children"
                                className={
                                  firstTierPageNameVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                              >
                                <Option value="">
                                  {" "}
                                  {traslate("Select Type")}{" "}
                                </Option>

                                {tierOneList &&
                                  tierOneList.map((item) => {
                                    return (
                                      <Option key={item?.id} value={item.id}>
                                        {item.value}
                                      </Option>
                                    );
                                  })}
                              </Select>

                              <div>
                                {firstTierPageNameVal && (
                                  <p className="error-color-red">
                                    {firstTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Select 2 Tier PageName")}
                              </label>
                              <Select
                                value={
                                  secondTierPageName ? secondTierPageName : null
                                }
                                name="secondTierPageName"
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "secondTierPageName"
                                  )
                                }
                                placeholder="Select Type"
                                showSearch
                                optionFilterProp="children"
                                className={
                                  secondTierPageNameVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                              >
                                <Option value="">
                                  {" "}
                                  {traslate("Select Type")}{" "}
                                </Option>

                                {tierTwoList &&
                                  tierTwoList.map((item) => {
                                    return (
                                      <Option key={item?.id} value={item.id}>
                                        {item.value}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              <div>
                                {secondTierPageNameVal && (
                                  <p className="error-color-red">
                                    {secondTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("3 Tier PageName")}</label>
                              <input
                                type="text"
                                placeholder="Enter 3rd Tier PageName: "
                                name="thirdTierPageName"
                                value={thirdTierPageName}
                                onChange={handleOnChange}
                                className={
                                  thirdTierPageNameVal && "border__red"
                                }
                              />
                              <div>
                                {thirdTierPageNameVal && (
                                  <p className="error-color-red">
                                    {thirdTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("4 Tier PageName")}</label>
                              <input
                                type="text"
                                placeholder="Enter 4 Tier PageName"
                                name="fourTierPageName"
                                value={fourTierPageName}
                                onChange={handleOnChange}
                                className={fourTierPageNameVal && "border__red"}
                              />
                              <div>
                                {fourTierPageNameVal && (
                                  <p className="error-color-red">
                                    {fourTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>{traslate("Vendor Super")} :</label>
                              <Switch
                                name="vendorSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Vendor Admin")} :</label>
                              <Switch
                                name="vendorAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Vendor User")} :</label>
                              <Switch
                                name="vendorUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Vendor-Diamond Super")} :
                              </label>
                              <Switch
                                name="vendorDiamondSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorDiamondSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorDiamondSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Vendor-Diamond Admin")} :
                              </label>
                              <Switch
                                name="VendorDiamondAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={VendorDiamondAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "VendorDiamondAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Vendor-Diamond User")} :
                              </label>
                              <Switch
                                name="VendorDiamondUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={VendorDiamondUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "VendorDiamondUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Retailer Super")} :</label>
                              <Switch
                                name="retailerSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={retailerSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "retailerSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Retailer Admin")} :</label>
                              <Switch
                                name="retailerAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={retailerAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "retailerAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Retailer User")} :</label>
                              <Switch
                                name="retailerUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={retailerUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "retailerUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Admin Admin")} :</label>
                              <Switch
                                name="adminAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={adminAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "adminAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Admin Super")} :</label>
                              <Switch
                                name="adminSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={adminSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "adminSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Admin User")} :</label>
                              <Switch
                                name="adminUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={adminUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "adminUserSwitch")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                title={traslate("Delete")}
                className="modalconsupld__section"
                centered
                visible={visible3}
                onOk={() => setVisible3(false)}
                onCancel={() => setVisible3(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleDelCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleDeleteTierDetails}
                  >
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p>{traslate("Are you sure you want to delete")}</p>
                            <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title={traslate("Create Tier")}
                className="modalconsupld__section"
                centered
                visible={tierVisible}
                onCancel={handleModalTierCancel}
                width={400}
                footer={[
                  <Button key="back" onClick={handleModalTierCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleTierSave}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="input__block">
                            <label>
                              {" "}
                              {traslate("Tier Type")} : <span>*</span>
                            </label>
                            <Select
                              name="tierPageNameType"
                              value={
                                tierEntry.tierPageNameType
                                  ? tierEntry.tierPageNameType
                                  : null
                              }
                              showSearch
                              placeholder=" Select Tier Type"
                              optionFilterProp="children"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "select",
                                  "tierPageNameType"
                                )
                              }
                              className={
                                tiervalidations.tierPageNameTypeVal
                                  ? "border__red"
                                  : "border__grey"
                              }
                            >
                              <Option value="FirstTier">
                                {" "}
                                {traslate("Tier One")}{" "}
                              </Option>
                              <Option value="SecondTier">
                                {" "}
                                {traslate("Tier Two")}{" "}
                              </Option>
                            </Select>
                            <div>
                              {tiervalidations.tierPageNameTypeVal && (
                                <p className="error-color-red">
                                  {tiervalidations.tierPageNameTypeVal}
                                </p>
                              )}
                            </div>
                          </div>
                          {tierEntry.tierPageNameType == "SecondTier" ? (
                            <div className="col-lg-12">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Select 1 Tier PageName")}
                                  <span className="mandatory">*</span>{" "}
                                </label>
                                <Select
                                  value={
                                    tierEntry.firstTierDep
                                      ? tierEntry.firstTierDep
                                      : null
                                  }
                                  name="firstTierDep"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "select",
                                      "firstTierDep"
                                    )
                                  }
                                  placeholder="Select Type"
                                  showSearch
                                  optionFilterProp="children"
                                  className={
                                    tiervalidations.firstTierDepVal
                                      ? "border__red"
                                      : "border__grey"
                                  }
                                >
                                  <Option value="">
                                    {" "}
                                    {traslate("Select Type")}{" "}
                                  </Option>

                                  {tierOneList &&
                                    tierOneList.map((item) => {
                                      return (
                                        <Option key={item?.id} value={item.id}>
                                          {item.value}
                                        </Option>
                                      );
                                    })}
                                </Select>

                                <div>
                                  {tiervalidations.firstTierDepVal && (
                                    <p className="error-color-red">
                                      {tiervalidations.firstTierDepVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Enter Tier Name")} : <span>*</span>
                              </label>
                              <input
                                name="tierPageNameValue"
                                value={
                                  tierEntry.tierPageNameValue
                                    ? tierEntry.tierPageNameValue
                                    : ""
                                }
                                onChange={handleInputChange}
                                type="text"
                                placeholder="Enter Tier Name"
                                className={
                                  tiervalidations.tierPageNameValueVal &&
                                  "border__red"
                                }
                              />
                              <div>
                                {tiervalidations.tierPageNameValueVal && (
                                  <p className="error-color-red">
                                    {tiervalidations.tierPageNameValueVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Permissions;
