import { SET_PAGE_PARAMETER } from "../../actions/PageParameter/types";
const initialState = {
  PageParameterID: {},
  PageParameterPageNameTab: {},
};

function pageParameterReducer(pageParameterState = initialState, action) {
  const { type } = action;
  switch (type) {
    // payload FORMAT = {KEY:VALUE} -- tO EXTRACT THE NEED KEY AND VALUE FROM OBJECT
    // case SET_PAGE_PARAMETER:
    //   return { SET THE WHOLE NEW STATE NEW (ADD AND REPLACE) };

    default:
      return pageParameterState;
  }
}
export default pageParameterReducer;
