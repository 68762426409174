import { httpCatalog } from "../helper/http-common";
class SearchJewelryDataService {
    GetALLJewelryDataList(inputData) {
        return httpCatalog.post("/Products/GetALLJewelryDataList", inputData);
    }
    GetALLJewelryDataListSecond(inputData) {
        return httpCatalog.post("/Products/GetALLJewelryDataListSecond", inputData);
    }
    GetALLJewelryDataListThird(inputData) {
        return httpCatalog.post("/Products/GetALLJewelryDataListThird", inputData);
    }
    GetALLJewelryDataListCollections(inputData) {
        return httpCatalog.post("/Products/GetALLJewelryDataListCollections", inputData);
    }
    GetALLJewelryDataListVendorname(inputData) {
        return httpCatalog.post("/Products/GetALLJewelryDataListVendorname", inputData);
    }
    GetALLJewelryDataListVendornameforjewelrysearch(inputData) {
        return httpCatalog.post("/Products/GetALLJewelryDataListVendornameforjewelrysearch", inputData);
    }
    GetProductCategoryList(inputData) {
        return httpCatalog.post("/Products/GetProductCategoryList", inputData);
    }
    ViewALLProduct(inputData) {
        return httpCatalog.post("/Products/ViewALLProduct", inputData);
    }
}

export default new SearchJewelryDataService();
