import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoImg from "../../assets/images/logo-img.png";
import logoSmallImg from "../../assets/images/logo-small.png";
import { Menu, Spin, Tooltip } from "antd";
import {
  NavLink,
  useNavigate,
  useLocation,
  useHistory,
} from "react-router-dom";
import helpIcon from "../../assets/images/icons/help-icon.svg";
import quickLinkIcon from "../../assets/images/icons/dash-quick-link.svg";

import NewCustomers from "../../assets/images/NewCustomers.svg";
import Appointments from "../../assets/images/Appointments.svg";
import Newarrival from "../../assets/images/sidemenu-icons/newarrival.svg";
import TopDesigners from "../../assets/images/TopDesigners.svg";
import supportIcon from "../../assets/images/icons/support-icon.png";
import ideaIcon from "../../assets/images/icons/idea-icon.png";
import videoIcon from "../../assets/images/icons/video-icon.png";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import AdminReportDataService from "../../services/admin-report.service";
import traslate from "./../../i18n/translate";
import { BlobToDownloadFile } from "../../helper/commanFunction";
import {
  setDealerIdAdminDataMapping,
  setColumnIdAdminDataMapping,
  setJewelryTab,
} from "../../actions/Contact/Contact";
// sidemenu icons

import Tools from "../../assets/images/sidemenu-icons/tools.svg";
import Settings from "../../assets/images/sidemenu-icons/settings.svg";
import Campaigns from "../../assets/images/sidemenu-icons/campaigns.svg";
import ReportIcon1 from "../../assets/images/sidemenu-icons/report1.svg";
import Reports from "../../assets/images/sidemenu-icons/Report.svg";
import DiamondReport from "../../assets/images/sidemenu-icons/diamond-report.svg";
import JewelryReport from "../../assets/images/sidemenu-icons/jewelry-report.svg";
import SearchFeature from "../../assets/images/sidemenu-icons/search-feature.svg";
import PurchaseOrder from "../../assets/images/sidemenu-icons/purchase-order.svg";
import MarketingIcon from "../../assets/images/sidemenu-icons/marketing.svg";
import AppsIcon from "../../assets/images/sidemenu-icons/apps.svg";
import CommunityIcon from "../../assets/images/sidemenu-icons/community.svg";
import CatalogIcon from "../../assets/images/sidemenu-icons/catalog.svg";
import Contacts from "../../assets/images/sidemenu-icons/contacts.svg";
import RetailerIcon from "../../assets/images/sidemenu-icons/retailer.svg";
import {
  setHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
  setEditTierMenu,
  setoldArrayUrlMenu,
  setEditCollectionTierMenu,
  setSelectedVendor,
} from "../../actions/headerMenu/headerMenu";
import { setChecklinkHeaderMenuData } from "../../actions/headerMenu/headerMenu";
import { Fragment } from "react";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import { setBindActivePageName } from "../../actions/Community/ManageInventory";
import { NotificationManager } from "react-notifications";
import { useRef } from "react";
import headerMenuReducer from "../../reducers/headerMenu/headerMenu";
import { store } from "../../redux/store";

const { SubMenu } = Menu;

// import chevronDown from "../../assets/images/icons/chevron-down.svg"

const Sidemenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submenu, setSubmenu] = useState();
  const [selectedmenu, setSelectedmenu] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const [loginUser, setLoginUser] = useState({});
  const [tierTwo, setTierTwo] = useState([]);
  const [tierThree, setTierThree] = useState([]);
  const [tierFour, setTierFour] = useState([]);
  const [reportPermisstion, setReportPermisstion] = useState({
    diamondCharts: false,
    globalDiamondCharts: false,
    globalJewelryCharts: false,
    jewelryCharts: false,
  });
  const [applicationPermisstionResponse, setApplicationPermisstionResponse] =
    useState({
      diamondLink: false,
      masterLink: false,
      pendantBuilder: false,
      ringBuilder: false,
      studBuilder: false,
      watchCustomizer: false,
    });

  const [FourTier, setFourTier] = useState([]);
  const [ProductToogle, setProdcuctToogle] = useState("false");
  const [CollectionToogle, setCollectionToogle] = useState("false");
  const ContactDetails = useSelector(
    (state) => state.contactReducer.toggleSideMenuData
  );
  const headerDetials = useSelector((state) => state.headerMenuReducer);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [leftSideData, setLeftSideData] = useState({});
  const [leftSideLoading, setLeftSideLoading] = useState(false);
  const [dealerMemberTypeName, setDealerMembershipType] = useState("");
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [QuickLinksLoading, setQuickLinksLoading] = useState(false);
  const [
    dashBoardVendorLeftPanelInfoList,
    setDashBoardVendorLeftPanelInfoList,
  ] = useState({});
  const WhiteLabeling = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        setLoginUser(loginDetials.responseData);
        setDealerMembershipType(loginDetials.responseData.dealerMemberTypeName);
        handleGetDashBoardVendorLeftPanelInfo(
          loginDetials.responseData.dealerId
        );
      }
    }
  }, []);

  useEffect(() => {
    if (headerDetials.checkLink == "Dashboard") {
      handleGetLeftSideMenuConservationDetails(
        loginDetials.responseData.dealerId
      );
    }
  }, [headerDetials.checkLink]);

  // Toggle

  useEffect(() => {
    if (headerDetials.checkLink == "Dashboard") {
      handleGetLeftSideMenuConservationDetails(
        loginDetials.responseData.dealerId
      );
    }
  }, [ContactDetails]);

  useEffect(() => {
    if (window.location.href.indexOf("editProducts") > -1) {
    } else {
      dispatch(setEditTierMenu("false"));
    }

    if (window.location.href.indexOf("editCollection") > -1) {
    } else {
      dispatch(setEditCollectionTierMenu("false"));
    }

    // For bindActive Value

    if (window.location.href.indexOf("vendorProfile") > -1) {
    } else {
      dispatch(setBindActivePageName(""));
    }
  }, [selectedmenu]);

  useEffect(() => {
    if (headerDetials) {
      if (headerDetials.sideMenuTierTwoDetails) {
        headerDetials.sideMenuTierTwoDetails.forEach((element) => {
          if (
            element.twoTierPageName == "Diamond Report" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive = headerDetials.reportPermisstion[0]
              ? headerDetials.reportPermisstion[0]["diamondCharts"]
              : false;
          }
          if (
            element.twoTierPageName == "Jewelery Report" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive = headerDetials.reportPermisstion[0]
              ? headerDetials.reportPermisstion[0]["jewelryCharts"]
              : false;
          }
          if (
            element.twoTierPageName == "Global Diamond" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive = headerDetials.reportPermisstion[0]
              ? headerDetials.reportPermisstion[0]["globalDiamondCharts"]
              : false;
          }
          if (
            element.twoTierPageName == "Global Jewelry" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive = headerDetials.reportPermisstion[0]
              ? headerDetials.reportPermisstion[0]["globalJewelryCharts"]
              : false;
          }
          if (
            element.twoTierPageName == "Diamond Link" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive =
              headerDetials.applicationPermisstionResponse["diamondLink"] ||
              false;
          }
          if (
            element.twoTierPageName == "Master Link" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive =
              headerDetials.applicationPermisstionResponse["masterLink"] ||
              false;
          }
          if (
            element.twoTierPageName == "Ring Builder" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive =
              headerDetials.applicationPermisstionResponse["ringBuilder"] ||
              false;
          }
          if (
            element.twoTierPageName == "Stud Builder" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive =
              headerDetials.applicationPermisstionResponse["studBuilder"] ||
              false;
          }
          if (
            element.twoTierPageName == "Pendant Builder" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive =
              headerDetials.applicationPermisstionResponse["pendantBuilder"] ||
              false;
          }
          if (
            element.twoTierPageName == "Watch Customizer" &&
            headerDetials.reportPermisstion
          ) {
            element.isActive =
              headerDetials.applicationPermisstionResponse["watchCustomizer"] ||
              false;
          }
        });
        setTierTwo(headerDetials.sideMenuTierTwoDetails);
      }
      if (headerDetials.sideMenuTierThreeDetails) {
        setTierThree(headerDetials.sideMenuTierThreeDetails);
      }
      if (headerDetials.sideMenuTierFourDetails) {
        setTierFour(headerDetials.sideMenuTierFourDetails);
      }
      if (headerDetials.reportPermisstion) {
        setReportPermisstion(headerDetials.reportPermisstion[0]);
      }
      if (headerDetials.reportPermisstion) {
        setReportPermisstion(headerDetials.reportPermisstion[0]);
      }
      if (headerDetials.applicationPermisstionResponse) {
        setApplicationPermisstionResponse(
          headerDetials.applicationPermisstionResponse
        );
      }
      if (headerDetials.ToogleValueProduct) {
        setProdcuctToogle(headerDetials.applicationPermisstionResponse);
      }
      if (headerDetials.ToogleCollectionValue) {
        setCollectionToogle(headerDetials.applicationPermisstionResponse);
      }
    }
  }, [headerDetials, ProductToogle, CollectionToogle]);

  useEffect(() => {
    handleSetmenu();
  }, [props.checkLink]);

  useEffect(() => {
    handlekey(splitLocation[1]);
    setSelectedmenu(headerDetials.selectSideMenu);
    dispatch(setSelectSideMenu(headerDetials.selectSideMenu));

    // if(!pathname == "/viewCatalog"){
    //   setSelectedmenu(headerDetials.selectSideMenu);
    //   dispatch(setSelectSideMenu(headerDetials.selectSideMenu));
    // }
  }, [pathname]);

  // Browser Back Issues Solution

  useEffect(() => {
    if (window.location.href.indexOf("adminDataMapping") > -1) {
    } else {
      dispatch(setDealerIdAdminDataMapping(null));
      dispatch(setColumnIdAdminDataMapping(null));
    }
    if (window.location.href.indexOf("sharedProduct") > -1) {
    } else {
      dispatch(setSelectedVendor(""));
    }
    if (window.location.href.indexOf("jewelryRetailerPermission") > -1) {
    } else {
      dispatch(setJewelryTab(""));
    }
    if (window.location.pathname == "/dashboard") {
      dispatch(setChecklinkHeaderMenuData("Dashboard"));
    } else if (window.location.pathname == "/contact") {
      dispatch(setChecklinkHeaderMenuData("Contacts"));
    }

    // For Admin Link Tools ToolsData Mapping
    else if (window.location.pathname == "/adminDataMapping") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`, `${"ToolsData Mapping"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu([""]));
    } else if (window.location.pathname == "/administrationTools") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["administrationTools"]));
    } else if (window.location.pathname == "/diamondRapNetPermission") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondRapNetPermission"]));
    } else if (window.location.pathname == "/applicationPermissions") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["applicationPermissions"]));
    } else if (window.location.pathname == "/blockDesignersForDemo") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["blockDesignersForDemo"]));
    } else if (window.location.pathname == "/clientDataMapping") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["clientDataMapping"]));
    } else if (window.location.pathname == "/clientStatus") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["clientStatus"]));
    } else if (window.location.pathname == "/consoleUpload") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["consoleUpload"]));
    } else if (window.location.pathname == "/createNewClient") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["createNewClient"]));
    } else if (window.location.pathname == "/legacy") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["legacy\r\n"]));
    } else if (window.location.pathname == "/limit") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["limit\r\n"]));
    } else if (window.location.pathname == "/loginPreferences") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["loginPreferences"]));
    } else if (window.location.pathname == "/") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu([""]));
    } else if (window.location.pathname == "/openSourceMapping") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["openSourceMapping"]));
    } else if (window.location.pathname == "/pendingSync") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendingSync"]));
    } else if (window.location.pathname == "/reportPermissions") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["reportPermissions"]));
    } else if (window.location.pathname == "/retailerlocator") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["retailerlocator"]));
    } else if (window.location.pathname == "/addNewRetailerToCore") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["addNewRetailerToCore"]));
    } else if (window.location.pathname == "/transferZZGemfind") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["transferZZGemfind"]));
    } else if (window.location.pathname == "/vendorRating") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Tools"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendorRating\r\n"]));
    }

    // Admin Settings
    else if (window.location.pathname == "/addcolor") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Settings"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["addcolor"]));
    } else if (window.location.pathname == "/apiAccessToken") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Settings"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["apiAccessToken"]));
    } else if (window.location.pathname == "/dataMaps") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Settings"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["dataMaps\r\n"]));
    } else if (window.location.pathname == "/emailTemplate") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Settings"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["emailTemplate\r\n"]));
    } else if (window.location.pathname == "/permissions") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Settings"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["permissions\r\n"]));
    } else if (window.location.pathname == "/tradeShows") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Settings"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["tradeShows\r\n"]));
    }
    // Admin Reports
    else if (window.location.pathname == "/clientsReports") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["clientsReports"]));
    } else if (window.location.pathname == "/dataRelated") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["dataRelated"]));
    } else if (window.location.pathname == "/globalEvents") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalEvents"]));
    } else if (window.location.pathname == "/jewelryLogReports") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryLogReports"]));
    } else if (window.location.pathname == "/sync") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["sync"]));
    } else if (window.location.pathname == "/toolsreports") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["toolsreports"]));
    }

    //Admin Diamond Report
    else if (window.location.pathname == "/DiamondClicks") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondClicks"]));
    } else if (window.location.pathname == "/ExtendedDiamondChartReports") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ExtendedDiamondChartReports"]));
    } else if (window.location.pathname == "/DiamondClarityReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondClarityReport"]));
    } else if (window.location.pathname == "/DiamondColor") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondColor"]));
    } else if (window.location.pathname == "/DiamondCutReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondCutReport"]));
    } else if (window.location.pathname == "/DiamondShapeReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondShapeReport"]));
    } else if (window.location.pathname == "/DiamondCertificateReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondCertificateReport"]));
    } else if (window.location.pathname == "/DiamondSizeReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Diamond Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["DiamondSizeReport"]));
    }

    // Admin Jewelry Report
    else if (window.location.pathname == "/jewelryCategoryReportAdmin") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Jewelry Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryCategoryReportAdmin"]));
    } else if (window.location.pathname == "/jewelryChartReportAdmin") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Jewelry Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryChartReportAdmin"]));
    } else if (window.location.pathname == "/jewelryMetalTypeReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Jewelry Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryMetalTypeReport"]));
    } else if (window.location.pathname == "/jewelryPriceReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Jewelry Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryPriceReport"]));
    } else if (window.location.pathname == "/jewelryRetailerClicksReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Jewelry Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryRetailerClicksReport"]));
    } else if (window.location.pathname == "/jewelryVendorClicksReport") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Jewelry Reports"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryVendorClicksReport"]));
    }

    // Admin Campaign
    else if (window.location.pathname == "/addCampaign") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Campaigns"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["addCampaign"]));
    } else if (window.location.pathname == "/campaignData") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Campaigns"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["campaignData"]));
    } else if (window.location.pathname == "/composeMail") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Campaigns"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["composeMail"]));
    } else if (window.location.pathname == "/mailingLists") {
      dispatch(setChecklinkHeaderMenuData("Admin"));
      var MenuData = [`${"Campaigns"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["mailingLists"]));
    }
    //My Catalog Vendors
    else if (window.location.pathname == "/vendors") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      // var MenuData = [`${"Collections"}`];
      // dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendors"]));
    }
    // My Catalog Collection
    else if (window.location.pathname == "/addCollection") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Collections"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["addCollection"]));
    } else if (window.location.pathname == "/manageCollection") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Collections"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["manageCollection"]));
    } else if (window.location.pathname == "/cataloglist") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu([]));
    }

    // My Catalog Data=>Connection
    else if (window.location.pathname == "/vendorManagerArchived") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendorManagerArchived"]));
    } else if (window.location.pathname == "/inviteyourvendor") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["inviteyourvendor"]));
    } else if (window.location.pathname == "/myvendors") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myvendors"]));
    } else if (window.location.pathname == "/network") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["network"]));
    } else if (window.location.pathname == "/new") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["new"]));
    } else if (window.location.pathname == "/outOfNetwork") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["outOfNetwork"]));
    } else if (window.location.pathname == "/vendorManagerPendingRequests") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendorManagerPendingRequests"]));
    } else if (window.location.pathname == "/vendorManagerPopular") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendorManagerPopular"]));
    } else if (window.location.pathname == "/recentlyUpdated") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["recentlyUpdated"]));
    }

    // My Catalog Group Discount
    else if (window.location.pathname == "/diamondGroupDiscount") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Diamond Group Discount"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondGroupDiscount"]));
    } else if (window.location.pathname == "/retailerGroup") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Diamond Group Discount"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["retailerGroup"]));
    }

    // My Catalog Import Diamond
    else if (window.location.pathname == "/addSingleDiamond") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["addSingleDiamond"]));
    } else if (window.location.pathname == "/diamondMapping") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondMapping"]));
    } else if (window.location.pathname == "/ftpCredential") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ftpCredential"]));
    } else if (window.location.pathname == "/uploadDiamondFile") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["uploadDiamondFile"]));
    } else if (window.location.pathname == "/diamonduploadHistory") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamonduploadHistory"]));
    }

    // My Catalog Import Jewelery
    else if (window.location.pathname == "/addProducts") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["addProducts"]));
    } else if (window.location.pathname == "/editProducts") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["editProducts"]));
    } else if (window.location.pathname == "/productMarkup") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["productMarkup"]));
    } else if (window.location.pathname == "/uploadProductFile") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["uploadProductFile"]));
    } else if (window.location.pathname == "/uploadHistory") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["uploadHistory"]));
    } else if (window.location.pathname == "/FtpCredentials") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["FtpCredentials"]));
    }

    // My Catalog Data Mapping
    // else if (window.location.pathname == "/DataMaping") {
    //   dispatch(setChecklinkHeaderMenuData("My Catalog"));
    //   var MenuData = [`${"Import"}`, `${"ImportJewelry"}`, `${"datamapping"}`];
    //   dispatch(setOpenKeyHeaderMenuData(MenuData));
    //   dispatch(setSelectSideMenu(["uploadHistory"]));
    // }

    // My Catalog Products Diamond
    else if (window.location.pathname == "/viewMyColorDiamond") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["viewMyColorDiamond"]));
    } else if (window.location.pathname == "/viewMyLabDiamond") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["viewMyLabDiamond"]));
    } else if (window.location.pathname == "/viewMyMinedDiamond") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsDiamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["viewMyMinedDiamond"]));
    }

    // My Catalog Products Jewelery
    else if (window.location.pathname == "/allProducts") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["allProducts"]));
    } else if (window.location.pathname == "/myProducts") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myProducts"]));
    } else if (window.location.pathname == "/sharedProduct") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Products"}`, `${"ProductsJewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["sharedProduct"]));
    }

    // My Catalog Sync
    else if (window.location.pathname == "/FtpDetails") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Sync"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["FtpDetails"]));
    } else if (window.location.pathname == "/viewCatalog") {
      // const value = store.getState().headerMenuReducer
      // let sign = "/";
      // let URL = value?.selectSideMenu[0]

      // let result = sign.concat(URL);

      setTimeout(() => {
        window.history.back();
        // window.history.pushState(null, null, result);
        // window.location.reload();
      }, 1000);
    } else if (window.location.pathname == "/OutBoundMapping") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Sync"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["OutBoundMapping"]));
    } else if (window.location.pathname == "/SyncHistory") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Sync"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["SyncHistory"]));
    }

    // search
    else if (window.location.pathname == "/diamondSearch") {
      dispatch(setChecklinkHeaderMenuData("Search"));
      var MenuData = [`${"Diamond Search"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondSearch"]));
    } else if (window.location.pathname == "/colorDiamondSearch") {
      dispatch(setChecklinkHeaderMenuData("Search"));
      var MenuData = [`${"Diamond Search"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["colorDiamondSearch"]));
    } else if (window.location.pathname == "/JewelrySearch") {
      dispatch(setChecklinkHeaderMenuData("Search"));
      var MenuData = [`${"Jewelry Search"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["JewelrySearch"]));
    } else if (window.location.pathname == "/labDiamondSearch") {
      dispatch(setChecklinkHeaderMenuData("Search"));
      var MenuData = [`${"Diamond Search"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["labDiamondSearch"]));
    }

    // Reports Diamond Report
    else if (window.location.pathname == "/diamondCertificateReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondCertificateReportMain"]));
    } else if (window.location.pathname == "/diamondChartReportsMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondChartReportsMain"]));
    } else if (window.location.pathname == "/diamondClarityReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondClarityReportMain"]));
    } else if (window.location.pathname == "/diamondColorMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondColorMain"]));
    } else if (window.location.pathname == "/diamondCutGradeReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondCutGradeReportMain"]));
    } else if (window.location.pathname == "/diamondShapeReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondShapeReportMain"]));
    } else if (window.location.pathname == "/diamondSizeReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondSizeReportMain"]));
    } else if (window.location.pathname == "/extendedDiamondChartReportsMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Diamond Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["extendedDiamondChartReportsMain"]));
    }

    // Reports Global Diamond
    else if (window.location.pathname == "/globalDiamondCertificateReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalDiamondCertificateReport"]));
    } else if (window.location.pathname == "/globalDiamondClarityReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalDiamondClarityReport"]));
    } else if (window.location.pathname == "/globalDiamondColorReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalDiamondColorReport"]));
    } else if (window.location.pathname == "/globalDiamondCutReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalDiamondCutReport"]));
    } else if (window.location.pathname == "/globalDiamondShapeReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalDiamondShapeReport"]));
    } else if (window.location.pathname == "/globalDiamondSizeReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalDiamondSizeReport"]));
    } else if (
      window.location.pathname == "/globalExtendedDiamondChartReports"
    ) {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Diamond"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalExtendedDiamondChartReports"]));
    }

    //Reports Global Jewelry
    else if (window.location.pathname == "/globalJewelryCategoryReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Jewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalJewelryCategoryReport"]));
    } else if (window.location.pathname == "/globalJewelryChartReports") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Jewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalJewelryChartReports"]));
    } else if (window.location.pathname == "/globalJewelryMetalTypeReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Jewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalJewelryMetalTypeReport"]));
    } else if (window.location.pathname == "/globalJewelryPriceReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Global Jewelry"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["globalJewelryPriceReport"]));
    }

    // Reports Jewelery Report
    else if (window.location.pathname == "/jewelryCategoryReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Jewelery Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryCategoryReport"]));
    } else if (window.location.pathname == "/jewelryChartReport") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Jewelery Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryChartReport"]));
    } else if (window.location.pathname == "/jewelryMetaltypeReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Jewelery Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryMetaltypeReportMain"]));
    } else if (window.location.pathname == "/JewelryPriceReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Jewelery Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["JewelryPriceReportMain"]));
    } else if (window.location.pathname == "/jewelryRetailerClickReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Jewelery Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryRetailerClickReportMain"]));
    } else if (window.location.pathname == "/jewelryVendorClickReportMain") {
      dispatch(setChecklinkHeaderMenuData("Reports"));
      var MenuData = [`${"Jewelery Report"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["jewelryVendorClickReportMain"]));
    }

    // Marketing
    else if (window.location.pathname == "/uploadMarketingMaterial") {
      dispatch(setChecklinkHeaderMenuData("Marketing"));
      var MenuData = [`${"Marketing List"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["marketing"]));
    }

    // Company Profile Company
    else if (window.location.pathname == "/companyProfile") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"company"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["companyProfile"]));
    } else if (window.location.pathname == "/manageBrands") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"company"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["brand"]));
    } else if (window.location.pathname == "/companyLocation") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"company"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["companyLocation"]));
    } else if (window.location.pathname == "/manageUser") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"company"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["manageUser"]));
    } else if (window.location.pathname == "/manageCompanyShows") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"company"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["manageCompanyShows"]));
    }

    // Company Profile Profile
    else if (window.location.pathname == "/userProfile") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"Profile"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["userProfile"]));
    } else if (window.location.pathname == "/changePassword") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"Profile"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["changePassword"]));
    }

    // Company Profile Settings
    else if (window.location.pathname == "/userSettings") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"Setting"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["emailPreferences"]));
    } else if (window.location.pathname == "/socialIntegrations") {
      dispatch(setChecklinkHeaderMenuData("Profile"));
      var MenuData = [`${"Setting"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["socialIntegrations"]));
    }

    // Retailer PO Dashboard
    else if (window.location.pathname == "/PODashboard") {
      dispatch(setChecklinkHeaderMenuData("PO"));
      var MenuData = [`${"Dashboard"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["PODashboard"]));
    } else if (window.location.pathname == "/workSheet") {
      dispatch(setChecklinkHeaderMenuData("PO"));
      var MenuData = [`${"Dashboard"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["workSheet"]));
    } else if (window.location.pathname == "/purchaseOrder") {
      dispatch(setChecklinkHeaderMenuData("PO"));
      var MenuData = [`${"Dashboard"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["purchaseOrder"]));
    }
    // Retailer
    else if (window.location.pathname == "/jewelryRetailerPermission") {
      dispatch(setChecklinkHeaderMenuData("Retailers"));
      dispatch(setSelectSideMenu(["jewelryRetailerPermission"]));
    } else if (window.location.pathname == "/pendingJewelryRequest") {
      dispatch(setChecklinkHeaderMenuData("Retailers"));
      dispatch(setSelectSideMenu(["pendingJewelryRequest"]));
    }

    // Purchase Order
    else if (window.location.pathname == "/myPurchaseOrder") {
      dispatch(setChecklinkHeaderMenuData("PO"));
      var MenuData = [`${"Purchase Orders"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myPurchaseOrder"]));
    } else if (window.location.pathname == "/myWorksheet") {
      dispatch(setChecklinkHeaderMenuData("PO"));
      var MenuData = [`${"Worksheets"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myWorksheet"]));
    } else if (window.location.pathname == "/workSheetDetails") {
      dispatch(setChecklinkHeaderMenuData("PO"));
      var MenuData = [`${"Worksheets"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myWorksheet"]));
    }

    // Contacts
    else if (window.location.pathname == "/profileNew") {
      dispatch(setChecklinkHeaderMenuData("Contacts"));
      var MenuData = [];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu([]));
    }

    // Diamond Link
    else if (window.location.pathname == "/manageVendor") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["manageVendor"]));
    }
    /* ----------------------------------------------------------------------------------------------*/
    // Apps Solution

    // Diamond Link
    else if (window.location.pathname == "/allapps") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu([]));
    } else if (window.location.pathname == "/dealerProfile") {
      // dispatch(setChecklinkHeaderMenuData("Apps"));
      // var MenuData = [`${"Diamond Link"}`];
      // dispatch(setOpenKeyHeaderMenuData(MenuData));
      // dispatch(setSelectSideMenu(["manageVendor"]));
    } else if (window.location.pathname == "/manageVendor") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["manageVendor"]));
    } else if (window.location.pathname == "/diamondLinkApiEndPoint") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondLinkApiEndPoint"]));
    } else if (window.location.pathname == "/applicationPreviewDiamondLink") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["applicationPreviewDiamondLink"]));
    } else if (window.location.pathname == "/availableFilterSettings") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["availableFilterSettings"]));
    } else if (window.location.pathname == "/diamondLinksEmailTemplate") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondLinksEmailTemplate"]));
    } else if (window.location.pathname == "/initialFilterSettings") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["initialFilterSettings"]));
    } else if (window.location.pathname == "/diamondLinkLegacy") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondLinkLegacy"]));
    } else if (window.location.pathname == "/FtpCredential") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["FtpCredential"]));
    } else if (window.location.pathname == "/fancyColorMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`, `${"Diamond LinkMarkUp"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["fancyColorMarkup"]));
    } else if (window.location.pathname == "/labGrowMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`, `${"Diamond LinkMarkUp"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["labGrowMarkup"]));
    } else if (window.location.pathname == "/minedDiamondMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`, `${"Diamond LinkMarkUp"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["minedDiamondMarkup"]));
    } else if (window.location.pathname == "/navigation") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["navigation"]));
    } else if (window.location.pathname == "/options") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["options"]));
    } else if (window.location.pathname == "/shoppingIntegrations") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Diamond Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["shoppingIntegrations"]));
    }

    //Master Link
    else if (window.location.pathname == "/MasterLinkApplicationPreview") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Master Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["MasterLinkApplicationPreview"]));
    } else if (window.location.pathname == "/MasterLinkDepartments") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Master Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["MasterLinkDepartments"]));
    } else if (window.location.pathname == "/masterlinkEmailTemplate") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Master Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["masterlinkEmailTemplate"]));
    } else if (window.location.pathname == "/masterlinkLegacy") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Master Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["masterlinkLegacy"]));
    } else if (window.location.pathname == "/masterlinkOptions") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Master Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["masterlinkOptions"]));
    } else if (window.location.pathname == "/masterlinkShoppingConfiguration") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Master Link"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["masterlinkShoppingConfiguration"]));
    }

    //Pendant Builder
    else if (window.location.pathname == "/applicationPreviewPedantBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["applicationPreviewPedantBuilder"]));
    } else if (window.location.pathname == "/pendantBuilderEmailTemplate") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendantBuilderEmailTemplate"]));
    } else if (window.location.pathname == "/myVendorsPendantBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Pendant Builder"}`,
        `${"Pendant BuilderManage Vendor"}`,
        `${"settingvendorspendant"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myVendorsPendantBuilder"]));
    } else if (window.location.pathname == "/vendorPendantBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Pendant Builder"}`,
        `${"Pendant BuilderManage Vendor"}`,
        `${"settingvendorspendant"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendorPendantBuilder"]));
    } else if (window.location.pathname == "/pedantBuilderDiamondVendors") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Pendant Builder"}`,
        `${"Pendant BuilderManage Vendor"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pedantBuilderDiamondVendors"]));
    } else if (window.location.pathname == "/PendantBuilderLabGrowMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`, `${"Pendant BuilderMarkup"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["PendantBuilderLabGrowMarkup"]));
    } else if (window.location.pathname == "/pendantBuilderDiamondMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`, `${"Pendant BuilderMarkup"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendantBuilderDiamondMarkup"]));
    } else if (window.location.pathname == "/pendantBuilderSettingMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`, `${"Pendant BuilderMarkup"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendantBuilderSettingMarkup"]));
    } else if (window.location.pathname == "/pendantBuilderNavigation") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendantBuilderNavigation"]));
    } else if (window.location.pathname == "/pendantBuilderOptions") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendantBuilderOptions"]));
    } else if (
      window.location.pathname == "/pendantBuilderShoppingConfiguration"
    ) {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Pendant Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["pendantBuilderShoppingConfiguration"]));
    }

    //Ring Builder
    else if (window.location.pathname == "/ringBuilderApiEndPoint") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderApiEndPoint"]));
    } else if (window.location.pathname == "/applicationPreviewRingBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["applicationPreviewRingBuilder"]));
    } else if (window.location.pathname == "/ringBuilderEmailTemplate") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderEmailTemplate"]));
    } else if (window.location.pathname == "/ringbuilderLegacy") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringbuilderLegacy"]));
    } else if (window.location.pathname == "/RBFtpCredentials") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["RBFtpCredentials"]));
    } else if (window.location.pathname == "/diamondVendorsRingBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`, `${"Ring BuilderManage Vendor"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["diamondVendorsRingBuilder"]));
    } else if (window.location.pathname == "/myVendorsRingBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Ring Builder"}`,
        `${"Ring BuilderManage Vendor"}`,
        `${"Setting Vendors"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myVendorsRingBuilder"]));
    } else if (window.location.pathname == "/NetworkVendorRingBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Ring Builder"}`,
        `${"Ring BuilderManage Vendor"}`,
        `${"Setting Vendors"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["NetworkVendorRingBuilder"]));
    } else if (window.location.pathname == "/ringBuilderLabGrowMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`, `${"Ring BuilderMarkUp"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderLabGrowMarkup"]));
    } else if (window.location.pathname == "/ringBuilderDaimondMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`, `${"Ring BuilderMarkUp"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderDaimondMarkup"]));
    } else if (window.location.pathname == "/ringBuilderSettingsMarkups") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`, `${"Ring BuilderMarkUp"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderSettingsMarkups"]));
    } else if (window.location.pathname == "/ringbuilderNavigation") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringbuilderNavigation"]));
    } else if (window.location.pathname == "/ringBuilderOptions") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderOptions"]));
    } else if (window.location.pathname == "/ringBuilderSettings") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringBuilderSettings"]));
    } else if (window.location.pathname == "/ringbuilderShoppingIntegrations") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Ring Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["ringbuilderShoppingIntegrations"]));
    }

    //Stud Builder
    else if (window.location.pathname == "/applicationPreviewStudBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["applicationPreviewStudBuilder"]));
    } else if (window.location.pathname == "/studBuilderEmailTemplate") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderEmailTemplate"]));
    } else if (window.location.pathname == "/studBuilderDiamondVendors") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`, `${"Stud BuilderManage Vendor"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderDiamondVendors"]));
    } else if (window.location.pathname == "/myVendorsStudBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Stud Builder"}`,
        `${"Stud BuilderManage Vendor"}`,
        `${"settingvendorsstud"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myVendorsStudBuilder"]));
    } else if (window.location.pathname == "/vendorStudBuilder") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [
        `${"Stud Builder"}`,
        `${"Stud BuilderManage Vendor"}`,
        `${"settingvendorsstud"}`,
      ];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["vendorStudBuilder"]));
    } else if (window.location.pathname == "/StudbuilderLabGrowMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`, `${"Stud BuilderMarkup"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["StudbuilderLabGrowMarkup"]));
    } else if (window.location.pathname == "/studBuilderDaimondMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`, `${"Stud BuilderMarkup"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderDaimondMarkup"]));
    } else if (window.location.pathname == "/studBuilderSettingsMarkup") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`, `${"Stud BuilderMarkup"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderSettingsMarkup"]));
    } else if (window.location.pathname == "/studBuilderNavigation") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderNavigation"]));
    } else if (window.location.pathname == "/studBuilderOptions") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderOptions"]));
    } else if (
      window.location.pathname == "/studBuilderShoppingConfiguration"
    ) {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Stud Builder"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["studBuilderShoppingConfiguration"]));
    }

    //Watch Customizer
    else if (window.location.pathname == "/applicationPreviewWatchCustomizer") {
      dispatch(setChecklinkHeaderMenuData("Apps"));
      var MenuData = [`${"Watch Customizer"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["applicationPreviewWatchCustomizer"]));
    }

    // End
  }, [window.location.pathname]);

  const handleSetmenu = () => {
    navigate(window.location.pathname);
  };

  useEffect(() => {
    // if (window.location.pathname == "/dashboard") {
    //   setSubmenu(["quickLinks"]);
    // } else {
    //   setSubmenu(headerDetials.openKey);
    // }
    setSubmenu(headerDetials.openKey);
  }, [headerDetials.openKey]);

  useEffect(() => {
    setSelectedmenu(headerDetials.selectSideMenu);
  }, [headerDetials.selectSideMenu]);
  const handlekey = (key, item, e) => {
    props?.closeMobileToggle();
    if (item) {
      if (
        item.threeTierPageName == "Jewelry" &&
        item.fourTierPageName == "Download Product File" &&
        loginDetials.responseData.vendorStatusID != 3
      ) {
        handleJewelryDownloadProdctFile();
      }
      if (
        item.threeTierPageName == "Diamond" &&
        item.fourTierPageName == "Download Product File"
      ) {
      }
      if (
        item.twoTierPageName == "Report" &&
        item.threeTierPageName == "Permission Reports"
      ) {
        handlePermissionReportDownload();
      }
    }

    dispatch(setSelectSideMenu([`${key}`]));
    setSelectedmenu([`${key}`]);

    // if(!key == "viewCatalog"){
    //   dispatch(setSelectSideMenu([`${key}`]));
    //   setSelectedmenu([`${key}`]);
    // }
  };

  useEffect(() => {
    if (props.checkLink) {
      if (headerDetials) {
        if (headerDetials.sideMenuTierTwoDetails) {
          setTierTwo(headerDetials.sideMenuTierTwoDetails);
        }
        if (headerDetials.sideMenuTierThreeDetails) {
          setTierThree(headerDetials.sideMenuTierThreeDetails);
        }
        if (headerDetials.sideMenuTierFourDetails) {
          setTierFour(headerDetials.sideMenuTierFourDetails);
        }
      }
    }
  }, [props.checkLink]);

  const handleLogoRedirection = () => {
    dispatch(setChecklinkHeaderMenuData("Dashboard"));
    navigate("/dashboard");
  };

  const [loading, setLoading] = useState(false);
  const handleJewelryDownloadProdctFile = () => {
    try {
      setLoading(true);
      let inputData = {
        dealerId: dealerId,
        jewelryUploads: 0,
        jewelryLink: 0,
      };
      NotificationManager.info("File has being downloading.", "", 1000);
      CatalogJewelryDataService.DownloadProductFile(inputData)
        .then((response) => {
          if (response.data.responseData) {
            window.open(response.data.responseData);
            NotificationManager.success("File has been download Successfully.");
            setLoading(false);
          } else {
            NotificationManager.error("File has not been downloaded.");
            setLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("File has not been downloaded.");
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("File has not been downloaded.");
      setLoading(false);
      console.log(error);
    }
  };
  const handlePermissionReportDownload = () => {
    try {
      AdminReportDataService.permissionreportDownload()
        .then((response) => {
          var fileName = "ReportFileRetailerDetail.csv";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDispatchOpenKey = (openKey) => {
    setSubmenu(openKey);
    dispatch(setOpenKeyHeaderMenuData(openKey));
  };

  // Side Menu For Dashboard

  const handleGetLeftSideMenuConservationDetails = (id) => {
    let inputData = {
      dealerID: id ? id.toString() : "",
    };
    setLeftSideLoading(true);
    try {
      AdminDashboardDataService.GetLeftSideMenuConservationDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setLeftSideData(responseData);
            setLeftSideLoading(false);
          } else {
            setLeftSideData({});
            setLeftSideLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLeftSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLeftSideLoading(false);
    }
  };

  const handleRedirection = (type, id) => {
    if (type == "Conversation") {
      dispatch(setChecklinkHeaderMenuData("Contacts"));
      navigate("/profileNew", {
        state: {
          key: "Conversation",
          id: id ? id : 0,
        },
      });
    } else if (type == "Profile") {
      dispatch(setChecklinkHeaderMenuData("Contacts"));
      navigate("/profileNew", {
        state: {
          key: "Profile",
          id: id ? id : 0,
        },
      });
    } else if (type == "VendorProfile") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      navigate("/vendorProfile", {
        state: {
          key: "Profile",
          id: id ? id : 0,
        },
      });
    }
  };

  const handleGetDashBoardVendorLeftPanelInfo = async (id) => {
    setQuickLinksLoading(true);
    try {
      let inputData = {
        dealerId: id.toString(),
      };
      await AdminDashboardDataService.GetDashBoardVendorLeftPanelInfo(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var dashBoardVendorLeftPanelInfoList = responseData;
          if (message === "Success") {
            setQuickLinksLoading(false);
            if (responseData) {
              setDashBoardVendorLeftPanelInfoList(
                dashBoardVendorLeftPanelInfoList
              );
            } else {
              setDashBoardVendorLeftPanelInfoList();
            }
          } else {
            setDashBoardVendorLeftPanelInfoList();
          }
        })
        .catch((error) => {
          console.log(error);
          setQuickLinksLoading(false);
        });
    } catch (error) {
      console.log(error);
      setQuickLinksLoading(false);
    }
  };

  // for Image Hover

  const handleMouseEnter = (path) => {
    setTooltipMessage(path);
  };

  const handleViewMyRetailersLink = () => {
    dispatch(setChecklinkHeaderMenuData("Retailers"));
    dispatch(setSelectSideMenu(["jewelryRetailerPermission"]));
    navigate("/jewelryRetailerPermission");
  };

  const handleNavigateManageShows = () => {
    navigate("/manageCompanyShows");
  };

  return (
    <React.Fragment>
      <div className="sidemenu__section">
        <div className="logo__section">
          <img
            src={WhiteLabeling?.whiteLebelData?.[0]?.logo || ""}
            className="logobig logo-redirect-sp"
            onClick={handleLogoRedirection}
          />
          <img src={logoSmallImg} className="logosmall" />
        </div>
        <div className="main__menu">
          <Menu
            mode="inline"
            theme="dark"
            inlineCollapsed={props.toggle}
            selectedKeys={selectedmenu}
            openKeys={submenu}
            onOpenChange={(openKeys) => {
              handleDispatchOpenKey(openKeys);
            }}
          >
            {tierTwo &&
              tierTwo
                .filter((x) => x.oneTierPageName == props.checkLink)
                .map((item, index) => {
                  return tierThree.filter(
                    (obj) =>
                      obj.twoTierPageName == item.twoTierPageName &&
                      obj.threeTierPageName
                  ).length > 0 ? (
                    <React.Fragment>
                      <SubMenu
                        key={item.twoTierPageName}
                        title={traslate(item.twoTierPageName)}
                        disabled={item.isActive === false ? true : false}
                        icon={
                          item.icon ? (
                            <img src={item.icon} />
                          ) : (
                            <img src={TopDesigners} />
                          )
                        }
                      >
                        {tierThree.filter(
                          (obj) => obj.twoTierPageName == item.twoTierPageName
                        ).length > 0
                          ? tierThree
                              .filter(
                                (obj) =>
                                  obj.twoTierPageName == item.twoTierPageName
                              )
                              .map((titem, index) => {
                                return (
                                  <React.Fragment>
                                    {tierFour.filter(
                                      (obj) =>
                                        obj.threeTierPageName ==
                                          titem.threeTierPageName &&
                                        obj.fourTierPageName &&
                                        obj.twoTierPageName ==
                                          item.twoTierPageName
                                    ).length > 0 ? (
                                      <React.Fragment>
                                        {window.location.href.indexOf(
                                          "adminDataMapping"
                                        ) > -1 &&
                                        titem.threeTierPageName ==
                                          "Data Mapping" ? (
                                          <SubMenu
                                            key={
                                              item.twoTierPageName +
                                              titem.threeTierPageName
                                            }
                                            title={traslate(titem.threeTierPageName)}
                                          >
                                            {tierFour
                                              .filter(
                                                (obj) =>
                                                  obj.threeTierPageName ==
                                                    titem.threeTierPageName &&
                                                  obj.fourTierPageName &&
                                                  obj.twoTierPageName ==
                                                    item.twoTierPageName
                                              )
                                              .map((fitem, index) => {
                                                return (
                                                  <React.Fragment>
                                                    <Menu.Item
                                                      key={
                                                        fitem.fourTierPageName
                                                      }
                                                    >
                                                      <NavLink
                                                        to={
                                                          fitem.navigateTo ||
                                                          "#"
                                                        }
                                                        state={{
                                                          columnID:
                                                            fitem.fourTierPageName,
                                                        }}
                                                        onClick={() =>
                                                          handlekey(
                                                            fitem.navigateTo,
                                                            fitem
                                                          )
                                                        }
                                                      >
                                                        <label>
                                                          {traslate(
                                                            fitem.fourTierPageName
                                                          )}
                                                        </label>
                                                      </NavLink>
                                                    </Menu.Item>
                                                  </React.Fragment>
                                                );
                                              })}
                                          </SubMenu>
                                        ) : titem.threeTierPageName !=
                                          "Data Mapping" ? (
                                          <SubMenu
                                            key={
                                              item.twoTierPageName +
                                              titem.threeTierPageName
                                            }
                                            title={traslate(titem.threeTierPageName)}
                                          >
                                            {tierFour
                                              .filter(
                                                (obj) =>
                                                  obj.threeTierPageName ===
                                                    titem.threeTierPageName &&
                                                  obj.fourTierPageName &&
                                                  obj.twoTierPageName ===
                                                    item.twoTierPageName
                                              )
                                              .map((fitem, index) => {
                                                if (
                                                  fitem.fourTierPageName ===
                                                  "Jewelry Mapping"
                                                ) {
                                                  return (
                                                    <React.Fragment>
                                                      <SubMenu
                                                        key="datamapping"
                                                        title={traslate(
                                                          "Data Mapping"
                                                        )}
                                                      >
                                                        <Menu.Item key="Essential">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Essential",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "Essential"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Essential"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="ItemIdentity">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Item Identity",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "ItemIdentity"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Item Identity"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="ConfigurableProducts">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Configurable Products",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "ConfigurableProducts"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Configurable Products"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="ItemDetails">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Item Details",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "ItemDetails"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Item Details"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="ItemPricing">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Item Pricing",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "ItemPricing"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Item Pricing"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="RelationalPricing">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Relational Pricing",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "RelationalPricing"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Relational Pricing"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="CostBasisPricing">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Cost Basis Pricing",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "CostBasisPricing"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Cost Basis Pricing"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="Item-PromoSpecials">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Item-Promo Specials",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "Item-PromoSpecials"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Item-Promo Specials"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="ItemMedia">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Item Media",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "ItemMedia"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Item Media"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="ItemSizing">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Item Sizing",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "ItemSizing"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Item Sizing"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="GemstoneDetails">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Gemstone Details",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "GemstoneDetails"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Gemstone Details"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="Semi-Mounts&RingBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Semi-Mounts & Ring Builder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "Semi-Mounts&RingBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Semi-Mounts & Ring Builder"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="WatchDetails">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Watch Details",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "WatchDetails"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Watch Details"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="AdditionalDetails">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/DataMaping",
                                                            }}
                                                            state={{
                                                              columnID:
                                                                "Additional Details",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "AdditionalDetails"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Additional Details"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                      </SubMenu>
                                                    </React.Fragment>
                                                  );
                                                } else if (
                                                  fitem.fourTierPageName ===
                                                  "Setting Vendors"
                                                ) {
                                                  return (
                                                    <Fragment>
                                                      <SubMenu
                                                        key="Setting Vendors"
                                                        title={traslate(
                                                          "Setting Vendors"
                                                        )}
                                                      >
                                                        <Menu.Item key="myVendorsRingBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/myVendorsRingBuilder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "myVendorsRingBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "My Vendors"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="NetworkVendorRingBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/NetworkVendorRingBuilder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "NetworkVendorRingBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Network Vendors"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                      </SubMenu>
                                                    </Fragment>
                                                  );
                                                } else if (
                                                  fitem.fourTierPageName ===
                                                  "Setting Vendors Stud"
                                                ) {
                                                  return (
                                                    <Fragment>
                                                      <SubMenu
                                                        key="settingvendorsstud"
                                                        title={traslate(
                                                          "Setting Vendors"
                                                        )}
                                                      >
                                                        <Menu.Item key="myVendorsStudBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/myVendorsStudBuilder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "myVendorsStudBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "My Vendors"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="vendorStudBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/vendorStudBuilder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "vendorStudBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Network Vendors"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                      </SubMenu>
                                                    </Fragment>
                                                  );
                                                } else if (
                                                  fitem.fourTierPageName ===
                                                  "Setting Vendors Pendant"
                                                ) {
                                                  return (
                                                    <Fragment>
                                                      <SubMenu
                                                        key="settingvendorspendant"
                                                        title={traslate(
                                                          "Setting Vendors"
                                                        )}
                                                      >
                                                        <Menu.Item key="myVendorsPendantBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/myVendorsPendantBuilder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "myVendorsPendantBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "My Vendors"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item key="vendorPendantBuilder">
                                                          <NavLink
                                                            to={{
                                                              pathname:
                                                                "/vendorPendantBuilder",
                                                            }}
                                                            onClick={() =>
                                                              handlekey(
                                                                "vendorPendantBuilder"
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {traslate(
                                                                "Network Vendors"
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                      </SubMenu>
                                                    </Fragment>
                                                  );
                                                } else {
                                                  if (
                                                    fitem.fourTierPageName ==
                                                    "Add Jewelry"
                                                  ) {
                                                    return (
                                                      <React.Fragment>
                                                        <Menu.Item
                                                          key={
                                                            fitem.fourTierPageName ==
                                                            "Add Jewelry"
                                                              ? window.location.href.indexOf(
                                                                  "editProducts"
                                                                ) > -1 ||
                                                                ProductToogle ==
                                                                  "true"
                                                                ? "editProducts"
                                                                : "addProducts"
                                                              : fitem.navigateTo
                                                          }
                                                        >
                                                          <NavLink
                                                            to={
                                                              fitem.fourTierPageName ==
                                                              "Add Jewelry"
                                                                ? window.location.href.indexOf(
                                                                    "editProducts"
                                                                  ) > -1 ||
                                                                  ProductToogle ==
                                                                    "true"
                                                                  ? "/editProducts"
                                                                  : fitem.navigateTo
                                                                : fitem.navigateTo
                                                            }
                                                            onClick={(e) =>
                                                              handlekey(
                                                                fitem.fourTierPageName ==
                                                                  "Add Jewelry"
                                                                  ? window.location.href.indexOf(
                                                                      "editProducts"
                                                                    ) > -1 ||
                                                                    ProductToogle ==
                                                                      "true"
                                                                    ? "editProducts"
                                                                    : "addProducts"
                                                                  : fitem.navigateTo,
                                                                fitem,
                                                                "",
                                                                e
                                                              )
                                                            }
                                                          >
                                                            <label className="menu__label">
                                                              {fitem.fourTierPageName ==
                                                              "Add Jewelry"
                                                                ? window.location.href.indexOf(
                                                                    "editProducts"
                                                                  ) > -1 ||
                                                                  ProductToogle ==
                                                                    "true"
                                                                  ? "Edit Jewelry"
                                                                  : "Add Jewelry"
                                                                : fitem.fourTierPageName}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                      </React.Fragment>
                                                    );
                                                  } else {
                                                    return (
                                                      <React.Fragment>
                                                        <Menu.Item
                                                          key={fitem.navigateTo}
                                                        >
                                                          <NavLink
                                                            to={
                                                              fitem.navigateTo ||
                                                              "#"
                                                            }
                                                            onClick={() =>
                                                              handlekey(
                                                                fitem.navigateTo,
                                                                fitem
                                                              )
                                                            }
                                                          >
                                                            <label>
                                                              {traslate(
                                                                fitem.fourTierPageName
                                                              )}
                                                            </label>
                                                          </NavLink>
                                                        </Menu.Item>
                                                      </React.Fragment>
                                                    );
                                                  }
                                                }
                                              })}
                                          </SubMenu>
                                        ) : null}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <Menu.Item
                                          key={
                                            titem.threeTierPageName ==
                                            "Add Collection"
                                              ? window.location.href.indexOf(
                                                  "editCollection"
                                                ) > -1 ||
                                                CollectionToogle == "true"
                                                ? "editCollection"
                                                : "addCollection"
                                              : titem.navigateTo
                                          }
                                        >
                                          <NavLink
                                            to={
                                              titem.threeTierPageName ==
                                              "Add Collection"
                                                ? window.location.href.indexOf(
                                                    "editCollection"
                                                  ) > -1 ||
                                                  CollectionToogle == "true"
                                                  ? "editCollection"
                                                  : "addCollection"
                                                : titem.navigateTo == ""
                                                ? window.location.pathname
                                                : titem.navigateTo
                                            }
                                            onClick={(e) =>
                                              handlekey(
                                                titem.threeTierPageName ==
                                                  "Add Collection"
                                                  ? window.location.href.indexOf(
                                                      "editCollection"
                                                    ) > -1 ||
                                                    CollectionToogle == "true"
                                                    ? "editCollection"
                                                    : "addCollection"
                                                  : titem.navigateTo,
                                                titem,
                                                "",
                                                e
                                              )
                                            }
                                          >
                                            <label>
                                              {titem.threeTierPageName ==
                                              "Add Collection"
                                                ? window.location.href.indexOf(
                                                    "editCollection"
                                                  ) > -1 ||
                                                  CollectionToogle == "true"
                                                  ?   traslate("Edit Collection")
                                                  :   traslate("Add Collection")
                                                : traslate(
                                                    titem.threeTierPageName
                                                  )}
                                            </label>
                                          </NavLink>
                                        </Menu.Item>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                );
                              })
                          : null}
                      </SubMenu>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Menu.Item
                        key={item.navigateTo}
                        disabled={item.isActive === false ? true : false}
                        icon={
                          item.icon ? (
                            <img src={item.icon} />
                          ) : (
                            <img src={TopDesigners} />
                          )
                        }
                      >
                        <NavLink
                          to={item.isActive === false ? "#" : item.navigateTo}
                          onClick={() => handlekey(item.navigateTo || "#")}
                        >
                          <label>{traslate(item.twoTierPageName)}</label>
                        </NavLink>
                      </Menu.Item>
                    </React.Fragment>
                  );
                })}
            {props.checkLink == "Profile" && (
              <React.Fragment>
                <SubMenu
                  key="company"
                  icon={<img src={SearchFeature} />}
                  title={traslate("Company")}
                >
                  <Menu.Item key="companyProfile">
                    <NavLink
                      to="/companyProfile"
                      onClick={() => handlekey("companyProfile")}
                    >
                      <label className="menu__label">
                        {traslate("Profile")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="brand">
                    <NavLink
                      to="/manageBrands"
                      onClick={() => handlekey("brand")}
                    >
                      <label className="menu__label">{traslate("Brand")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="companyLocation">
                    <NavLink
                      to="/companyLocation"
                      onClick={() => handlekey("companyLocation")}
                    >
                      <label className="menu__label">
                        {traslate("Location")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="manageUser">
                    <NavLink
                      to="/manageUser"
                      onClick={() => handlekey("manageUser")}
                    >
                      <label className="menu__label">{traslate("Users")}</label>
                    </NavLink>
                  </Menu.Item>
                  {dealerMemberTypeName == "Admin" && (
                    <Menu.Item key="whiteLabel">
                      <NavLink
                        to="/whiteLabel"
                        onClick={() => handlekey("whiteLabel")}
                      >
                        <label className="menu__label">
                          {traslate("White Label")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {dealerMemberTypeName == "Admin" && (
                    <Menu.Item key="CreateOrg">
                      <NavLink
                        to="/createOrganization"
                        onClick={() => handlekey("CreateOrg")}
                      >
                        <label className="menu__label">
                          {traslate("Create Organization")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {dealerMemberTypeName == "Retailer" ? null : (
                    <Menu.Item key="manageCompanyShows">
                      <NavLink
                        to="/manageCompanyShows"
                        onClick={() => handlekey("manageCompanyShows")}
                      >
                        <label className="menu__label">
                          {traslate("Shows")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {dealerMemberTypeName == "Admin" ? (
                    <Menu.Item key="changePassword">
                      <NavLink
                        to="/changePassword"
                        onClick={() => handlekey("changePassword")}
                      >
                        <label className="menu__label">
                          {traslate("Change Password")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                {/* <SubMenu
                  key="Profile"
                  icon={<img src={SearchFeature} />}
                  title={traslate("Profile")}
                >
                  <Menu.Item key="userProfile">
                    <NavLink
                      to="/userProfile"
                      onClick={() => handlekey("userProfile")}
                    >
                      <label className="menu__label">
                        {traslate("Basic Information")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="changePassword">
                    <NavLink
                      to="/changePassword"
                      onClick={() => handlekey("changePassword")}
                    >
                      <label className="menu__label">
                        {traslate("Change Password")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu> */}
                <SubMenu
                  key="Setting"
                  icon={<img src={SearchFeature} />}
                  title={traslate("Setting")}
                >
                  <Menu.Item key="emailPreferences">
                    <NavLink
                      to="/userSettings"
                      onClick={() => handlekey("emailPreferences")}
                    >
                      <label className="menu__label">
                        {traslate("Email Preferences")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  {dealerMemberTypeName !== "VendorDiamond" && (
                    <Menu.Item key="socialIntegrations">
                      <NavLink
                        to="/socialIntegrations"
                        onClick={() => handlekey("socialIntegrations")}
                      >
                        <label className="menu__label">
                          {traslate("Social Integrations")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              </React.Fragment>
            )}

            {/* <SubMenu
                  key="TopDesigner"
                  icon={<img src={TopDesigners} />}
                  title={traslate("Top Designer")}
                >
                  {leftSideData.topDesigners
                    ? leftSideData.topDesigners.length > 0 &&
                      leftSideData.topDesigners.map((item, i) => {
                        return (
                          <Menu.Item
                            key={item.dealername}
                            onClick={() =>
                              handleRedirection("VendorProfile", item.dealerid)
                            }
                          >
                            <label className="menu__label">
                              {item.dealername + " (" + item.productCount + ")"}
                            </label>
                          </Menu.Item>
                        );
                      })
                    : null}
                </SubMenu> */}

            {/* Common tabs for side menu starts  */}

            {props.checkLink === "Dashboard" &&
            dealerMemberTypeName !== "Vendor" &&
            dealerMemberTypeName !== "VendorDiamond" ? (
              <React.Fragment>
                <SubMenu
                  key="NewCustomers"
                  icon={<img src={NewCustomers} />}
                  title={traslate("New Customers")}
                >
                  <Spin spinning={leftSideLoading}>
                    {leftSideData.newCustomers
                      ? leftSideData.newCustomers.length > 0 &&
                        leftSideData.newCustomers.map((item, i) => {
                          return (
                            <Menu.Item
                              key={item.fullName}
                              onClick={() =>
                                handleRedirection("Profile", item.cust_id)
                              }
                            >
                              <label className="menu__label">
                                {item.fullName}
                              </label>
                            </Menu.Item>
                          );
                        })
                      : null}
                  </Spin>
                </SubMenu>

                <SubMenu
                  key="New Arrivals"
                  icon={<img src={Newarrival} />}
                  title={traslate("New Arrivals")}
                  className="newArrivalImgs"
                >
                  <Spin spinning={leftSideLoading}>
                    {leftSideData.newArrivals
                      ? leftSideData.newArrivals.length > 0 &&
                        leftSideData.newArrivals.map((item, i) => {
                          return (
                            // <Menu.Item key={item.productName}>
                            //   <label className="menu__label">
                            //     {item.productName}
                            //   </label>
                            // </Menu.Item>
                            <Menu.Item key={i} className="tool_tip_span">
                              <Tooltip
                                placement="right"
                                title={item.productName}
                              >
                                <label className="menu__label">
                                  <img
                                    src={item.imagePath}
                                    className="new__arrival__image"
                                  />
                                </label>
                              </Tooltip>
                            </Menu.Item>
                          );
                        })
                      : null}
                  </Spin>
                </SubMenu>

                <SubMenu
                  key={traslate("Appointments")}
                  icon={<img src={Appointments} />}
                  title={traslate("Appointments")}
                >
                  <Spin spinning={leftSideLoading}>
                    {leftSideData.appointments
                      ? leftSideData.appointments.length > 0 &&
                        leftSideData.appointments.map((item, i) => {
                          return (
                            <Menu.Item key={item.id}>
                              <label className="menu__label">
                                {item.dayDate}
                                {" - "}
                                {item.appointmentTime}
                                {" - "}
                                {item.fullName}
                              </label>
                            </Menu.Item>
                          );
                        })
                      : null}
                  </Spin>
                </SubMenu>
              </React.Fragment>
            ) : null}
            {props.checkLink == "Dashboard" ||
            props.checkLink == "My Catalog" ||
            props.checkLink == "Apps" ? (
              <React.Fragment>
                <SubMenu
                  key="Help"
                  className="help__menu"
                  icon={<img src={helpIcon} />}
                  title={traslate("Help")}
                >
                  <Menu.Item key="25">
                    <a
                      href="https://gemfind.zendesk.com/home"
                      target={"_blank"}
                      rel="ReportAnIssue"
                    >
                      <button className="menu__button">
                        <img src={supportIcon} alt={"supportIcon"} />
                        <span>{traslate("Report An Issue")}</span>
                      </button>
                    </a>
                  </Menu.Item>
                  <Menu.Item key="26">
                    <a
                      href="https://jewelcloud.uservoice.com/"
                      target={"_blank"}
                      rel="ideaIcon"
                    >
                      <button className="menu__button">
                        <img src={ideaIcon} />
                        <span>{traslate("Suggest A New Feature")}</span>
                      </button>
                    </a>
                  </Menu.Item>
                  <Menu.Item key="27">
                    <a
                      href="https://www.gemfind.tv/show/training-videos"
                      target={"_blank"}
                      rel="videoIcon"
                    >
                      <button className="menu__button">
                        <img src={videoIcon} />
                        <span>{traslate("Watch Training Videos")}</span>
                      </button>
                    </a>
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            ) : null}

            {props.checkLink === "Dashboard" &&
            dealerMemberTypeName !== "Retailer" &&
            dealerMemberTypeName !== "Admin" ? (
              <SubMenu
                key="quickLinks"
                title={traslate("Quick Links")}
                icon={<img src={quickLinkIcon} />}
              >
                <Menu.Item key="viewMyRetailers">
                  <NavLink
                    to="/jewelryRetailerPermission"
                    onClick={() => handleViewMyRetailersLink()}
                  >
                    <label className="menu__label">
                      {traslate("View My Retailers")}
                    </label>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="updateInventory">
                  <NavLink to="/updateInventoryImport">
                    {traslate("Update Inventory")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="inviteRetailer">
                  <NavLink to="/inviteYourRetailer">
                    {traslate("Invite A Retailer")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="manageShows">
                  <NavLink
                    to="/manageCompanyShows"
                    onClick={() => handleNavigateManageShows()}
                  >
                    {traslate("Manage Shows")}
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            ) : null}
          </Menu>

          {props.checkLink === "Dashboard" &&
            dealerMemberTypeName == "Vendor" && (
              <div className="container p-4">
                <Spin spinning={QuickLinksLoading}>
                  <div className="">
                    <h4 className="quick__subheading">
                      {" "}
                      {traslate("Details")}{" "}
                    </h4>
                    <ul className="list-unstyled">
                      <li>
                        {traslate("Last Updated")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.lastUpdated}
                        </span>
                      </li>
                      <li>
                        {traslate("Jewelry")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.jewelry}
                        </span>
                      </li>
                      <li>
                        {traslate("Diamonds")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.diamonds}
                        </span>
                      </li>
                      <li>
                        {traslate("Retailers")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.retailers}
                        </span>
                      </li>
                    </ul>
                  </div>
                </Spin>
              </div>
            )}

          {props.checkLink === "Dashboard" &&
            dealerMemberTypeName == "VendorDiamond" && (
              <div className="container p-4">
                <Spin spinning={QuickLinksLoading}>
                  <div className="quicklink__sections">
                    <h4 className="quick__subheading">
                      {" "}
                      {traslate("Details")}{" "}
                    </h4>
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                        {traslate("Last Updated")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.lastUpdated}
                        </span>
                      </li>
                      <li>
                        {" "}
                        {traslate("Diamonds")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.diamonds}
                        </span>
                      </li>
                      <li>
                        {" "}
                        {traslate("Retailers")}:{" "}
                        <span style={{ color: "#24abe2" }}>
                          {dashBoardVendorLeftPanelInfoList.retailers}
                        </span>
                      </li>
                    </ul>
                  </div>
                </Spin>
              </div>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidemenu;
