import { http, httpDealer, httpCatalog, httpApps, httpWithoutToken } from "../helper/http-common";
class AdminToolsDataService {
  GetGFLinkDealer() {
    return http.post("/Tools/GetGFLinkDealer");
  }
  AddGFLinksDealer(inputData) {
    return http.post("/Tools/AddGFLinksDealer", inputData);
  }
  RemoveGFLinksDealer(inputData) {
    return http.post("/Tools/RemoveGFLinksDealer", inputData);
  }
  AddGFLinksLabDiamondDealer(inputData) {
    return http.post("/Tools/AddGFLinksLabDiamondDealer", inputData);
  }
  RemoveGFLinksLabDiamondDealer(inputData) {
    return http.post("/Tools/RemoveGFLinksLabDiamondDealer", inputData);
  }
  FillDealerDropDown() {
    return http.post("/Tools/FillDealerDropDown");
  }
  CreateInviteVendorRetailer(inputData) {
    return http.post("/Tools/CreateInviteVendorRetailer", inputData);
  }
  GetMenuList(inputData) {
    return http.post("/Tools/GetMenuList", inputData);
  }
  SaveMenuList(inputData) {
    return http.post("/Tools/SaveMenuList", inputData);
  }
  GetReportPermissionsList(inputData) {
    return http.post("/Tools/GetReportPermissionsList", inputData);
  }
  SaveReportPermissions(inputData) {
    return http.post("/Tools/SaveReportPermissions", inputData);
  }
  GetBlockDesignersRetailer(inputData) {
    return http.post("/Tools/GetBlockDesignersRetailer", inputData);
  }
  SaveBlockDesignersRetailer(inputData) {
    return http.post("/Tools/SaveBlockDesignersRetailer", inputData);
  }
  SaveMasterLinkTheme(inputData) {
    return http.post("/Tools/SaveMasterLinkTheme", inputData);
  }
  GetMasterlinkThemvalue(inputData) {
    return http.post("/Tools/GetMasterlinkThemvalue", inputData);
  }
  GetDesignerList() {
    return http.get("/Tools/GetDesignerForLoginPage");
  }
  AddDesignerToLogInPage(inputData) {
    return http.post("/Tools/AddDesignerToLogInPage", inputData);
  }
  SetUnsetDesignerForLoginPage(inputData) {
    return http.post("/Tools/SetUnsetDesignerForLoginPage", inputData);
  }
  UploadLogoForDesignerForLoginPage(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.post("/Tools/UploadLogoForDesignerForLoginPage", inputData, {
      headers,
    });
  }
  /* For Solar Core client  */
  GetRetailerCoreList(inputData) {
    return http.post("/Tools/GetRetailerCoreList", inputData);
  }
  SaveRetailerCore(inputData) {
    return http.post("/Tools/SaveRetailerCore", inputData);
  }
  GetDealerDetails(inputData) {
    return httpDealer.post("/Dealer/GetDealerDetails", inputData);
  }

  /*Vendor Rating*/
  GetVendorRatingList(inputData) {
    return http.post("/Tools/GetVendorRatingList", inputData);
  }
  SaveVendorRating(inputData) {
    return http.post("/Tools/SaveVendorRating", inputData);
  }

  /* Settings -> Permissions  */
  GetDynamicPageAccessDetailsList(inputData) {
    return http.post("/Setting/GetDynamicPageAccessDetailsList", inputData);
  }
  SaveDynamicPageAccessDetails(inputData) {
    return http.post("/Setting/SaveDynamicPageAccessDetails", inputData);
  }
  GetTierDtlsByType(inputData) {
    return http.post("/Setting/GetTierDtlsByType", inputData);
  }
  DeleteTierDetails(inputData) {
    return http.post("/Setting/DeleteTierDetails", inputData);
  }
  AddUpdateTierData(inputData) {
    return http.post("/Setting/AddUpdateTierData", inputData);
  }

  /* Magento Mapping */
  GetOpenSourceMappingList(inputData) {
    return http.post("/Tools/GetOpenSourceMappingList", inputData);
  }
  SaveOpenSourceMapping(inputData) {
    return http.post("/Tools/SaveOpenSourceMapping", inputData);
  }
  UpdateOpenSourceMapping(inputData) {
    return http.post("/Tools/UpdateOpenSourceMapping", inputData);
  }
  DeleteOpenSourceMapping(inputData) {
    return http.post("/Tools/DeleteOpenSourceMapping", inputData);
  }

  /* Limits  */
  GetDealerForCreate(inputData) {
    return http.post("/Tools/GetDealerForCreate", inputData);
  }

  GetDealerLimits(inputData) {
    return http.post("/Tools/GetDealerLimits", inputData);
  }
  CreateDealerLimit(inputData) {
    return http.post("/Tools/CreateDealerLimit", inputData);
  }
  DeleteDealerLimit(inputData) {
    return http.post("/Tools/DeleteDealerLimit", inputData);
  }

  // DownloadDealerLimits(inputData) {
  //   return http.post("/Tools/DownloadDealerLimits", inputData);
  // }
  DownloadDealerLimits(inputData) {
    return http.get(
      "/Tools/DownloadDealerLimits?dealerId=" +
        inputData.dealerId.toString() +
        "&filterBy=" +
        inputData.filterBy.toString(),
      {
        responseType: "arraybuffer",
      }
    );
  }

  /*Create New Client  */
  GetPermisssionTypeMaster() {
    return http.get("/Tools/GetPermisssionTypeMaster");
  }
  GetRetailerStatusMaster() {
    return http.get("/Tools/GetRetailerStatusMaster");
  }
  GetVendorStatusMaster() {
    return http.get("/Tools/GetVendorStatusMaster");
  }
  GetHTMLMailTemplet(inputData) {
    return http.post("/Tools/GetHTMLMailTemplet", inputData);
  }
  CreateNewClient(inputData) {
    return http.post("/Tools/CreateNewClient", inputData);
  }
  GetCountryDetail() {
    return httpDealer.post("Dealer/GetCountryDetail");
  }
  CheckDealerCompanyName(inputData) {
    return httpDealer.post("Comman/CheckDealerCompanyName", inputData);
  }
  CheckDealerEmail(inputData) {
    return httpDealer.post("Comman/CheckDealerEmail", inputData);
  }
  CheckDealerUser(inputData) {
    return httpDealer.post("Comman/CheckDealerUser", inputData);
  }
  /*Console Upload */
  GetConsoleUploadList(inputData) {
    return http.post("/Tools/GetConsoleUploadList", inputData);
  }

  SaveConsoleUpload(inputData) {
    return http.post("/Tools/SaveConsoleUpload", inputData);
  }
  UpdateConsoleUpload(inputData) {
    return http.post("/Tools/UpdateConsoleUpload", inputData);
  }
  DeleteConsoleUpload(inputData) {
    return http.post("/Tools/DeleteConsoleUpload", inputData);
  }

  CreateFTP(inputData) {
    return http.post("/Tools/CreateFTP", inputData);
  }

  /*Client Status */
  GetClientStatus(inputData) {
    return http.post("/Tools/GetClientStatus", inputData);
  }

  UpdateClientStatus(inputData) {
    return http.post("/Tools/UpdateClientStatus", inputData);
  }

  ChangeDealerCreatedDate(inputData) {
    return http.post("/Tools/ChangeDealerCreatedDate", inputData);
  }
  /*Diamond imports  */
  GetDiamondImportRightsList(inputData) {
    return http.post("/Tools/GetDiamondImportRightsList", inputData);
  }

  SaveDiamondImportRights(inputData) {
    return http.post("Tools/SaveDiamondImportRights", inputData);
  }
  GetClientDataMappingList(inputData) {
    return http.post("/Tools/GetClientDataMappingList", inputData);
  }
  SetDefaultMapping(inputData) {
    return http.post("/Tools/SetDefaultMapping", inputData);
  }

  // Administration Tools
  GetUsersList(inputData) {
    return http.post("/Tools/GetUsersList", inputData);
  }
  GetUsersListByDealerId(inputData) {
    return http.post("/Tools/GetUsersListByDealerId", inputData);
  }
  ImporsonateToJewelCloud(inputData) {
    return http.post("/Tools/ImporsonateToJewelCloud", inputData);
  }

  GetAdminDealerProfile(inputData) {
    return http.post("/Tools/GetAdminDealerProfile", inputData);
  }

  AdminToolsDownloadInventoryFile(inputData) {
    return http.post("/Tools/AdminToolsDownloadInventoryFile", inputData, {
      responseType: "arraybuffer",
    });
  }

  AdminToolsLogin(inputData) {
    return httpWithoutToken.post("/Identity/LogIn", inputData);
  }

  //Client Data Maps
  SyncProducts(inputData) {
    return http.post("/Tools/SyncProducts", inputData);
  }
  SyncProductsForce(inputData) {
    return http.post("/Tools/SyncProductsForce", inputData);
  }
  GetUpdateHistoryList(inputData) {
    return http.post("/Tools/GetUpdateHistoryList ", inputData);
  }
  UploadMagentoMapping(inputData) {
    return http.post("/Tools/UploadMagentoMapping", inputData);
  }
  GetUpdateHistory(inputData) {
    return http.post("/Tools/GetUpdateHistory", inputData);
  }
  CreateXML(inputData) {
    return http.post("/Tools/CreateXML", inputData);
  }
  GetMegentoMappingForXML(inputData) {
    return http.post("/Tools/GetMegentoMappingForXML", inputData);
  }
  SaveMegentoMappingForXML(inputData) {
    return http.post("/Tools/SaveMegentoMappingForXML", inputData);
  }
  /*Pending Sync */
  GetDealerDropDownList(inputData) {
    return httpDealer.post("/Dealer/GetDealerDropDownList", inputData);
  }

  GetPendingJewelrySyncList(inputData) {
    return http.post("/Tools/GetPendingJewelrySyncList", inputData);
  }

  UpdateDiscardStatus(inputData) {
    return http.post("/Tools/UpdateDiscardStatus", inputData);
  }
  RunSyncForce(inputData) {
    return http.post("/Tools/RunSyncForce", inputData);
  }

  //Login Preferenc
  GetDesignerData() {
    return http.post("/Tools/GetDesignerData");
  }

  //Transfer ZZ Gemfind
  TransferBrand(inputData) {
    return http.post("/Tools/TransferBrand", inputData);
  }
  TransferRequests(inputData) {
    return http.post("/Tools/TransferRequests", inputData);
  }
  TransferCollection(inputData) {
    return http.post("/Tools/TransferCollection", inputData);
  }
  TransferProfile(inputData) {
    return http.post("/Tools/TransferProfile", inputData);
  }
  TransferColumnAndAttributemapping(inputData) {
    return http.post("/Tools/TransferColumnAndAttributemapping", inputData);
  }
  TransferProductData(inputData) {
    return http.post("/Tools/TransferProductData", inputData);
  }
  TransferProductImagesVideo(inputData) {
    return http.post("/Tools/TransferProductImagesVideo", inputData);
  }

  //Forgot Password
  ForgotPasswordGeneration(inputData) {
    return http.post("/Identity/ForgotPasswordGeneration", inputData);
  }
  ForgotPasswordChangeNewPassword(inputData) {
    return http.post("/Identity/ForgotPasswordChangeNewPassword", inputData);
  }

  // <--- Header Nav links ---->
  GetAllMenuDtlsByPermission(inputData) {
    return http.post("/Setting/GetAllMenuDtlsByPermission", inputData);
  }

  // Add Login Details
  AddLoginDetails(inputData) {
    return http.post("/Identity/AddLoginDetails", inputData);
  }
  //Register Cascading Dropdown Starts
  GetLocationDropdownDetails(inputData) {
    return httpDealer.post("Comman/GetLocationDropdownDetails", inputData);
  }
  //Register Cascading Dropdown Ends

  // Client Status

  GetnotVerifiedClientStatus(inputData) {
    return http.post("Tools/GetnotVerifiedClientStatus", inputData);
  }
  ApproveClientStatusAsync(inputData) {
    return http.post("Tools/ApproveClientStatusAsync", inputData);
  }
  rejectVerifedClientStatus(inputData) {
    return http.post("Tools/rejectVerifedClientStatus", inputData);
  }
  SaveFtpDetailsSync(inputData) {
    return httpCatalog.post("Sync/SaveFtpDetailsSync", inputData);
  }
  GetSyncList(inputData) {
    return httpCatalog.post("/Sync/GetSyncList", inputData);
  }
  SyncGetConnectd_ProductCount(inputData) {
    return httpCatalog.post("/Sync/SyncGetConnectd_ProductCount", inputData);
  }
  SyncInsertProductdata(inputData) {
    return httpCatalog.post("/Sync/SyncInsertProductdata", inputData);
  }
  SyncGetConnected_TotalProduct(inputData) {
    return httpCatalog.post("/Sync/SyncGetConnected_TotalProduct", inputData);
  }
  SyncBindDropdownProductLimitation(inputData) {
    return httpCatalog.post(
      "/Sync/SyncBindDropdownProductLimitation",
      inputData
    );
  }
  SyncPageLoadData(inputData) {
    return httpCatalog.post("/Sync/SyncPageLoadData", inputData);
  }
  SyncProductsDetails(inputData) {
    return httpCatalog.post("/Sync/SyncProducts", inputData);
  }
  ForceFullSync(inputData) {
    return httpCatalog.post("/Sync/ForceFullSync", inputData);
  }
  DownloadReportSync(inputData) {
    return httpCatalog.post("/Sync/DownloadReportSync", inputData);
  }
  SyncErrorMsg(inputData) {
    return httpCatalog.post("/Sync/SyncErrorMsg", inputData);
  }
  GetDealerSyncDetails() {
    return httpApps.post("/studBuilder/GetDealerSyncDetails");
  }
}

export default new AdminToolsDataService();
