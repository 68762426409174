import React, { useState, useEffect } from "react";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Button, Modal } from "antd";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";

const initialMessageState = {
  TransferBrand: "",
  SuccessBrandTransfer: "",
  TransferRequests: "",
  TransferSuccessful: "",
  TransferCollection: "",
  TransferCollectionSuccessful: "",
  TransferProfile: "",
  TransferProfileSuccessful: "",
  TransferColumnAndAttributemapping: "",
  TransferColumnAndAttributemappingSuccessful: "",
  TransferProductData: "",
  TransferProductDataSuccessful: "",
  ImagesTransfer: "",
  ImagesTransferSuccessful: ""
};

const initialState = {
  sourceJCID: "",
  destinationJCID: "",
  sourceJCName: null,
  destinationJCName: null,
  type: 2,
  isReplace: false,
  isAddUpdate: true,
  errorMessage: {}
};

const TransferZZGemfind = () => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [transerModal, setTranserModal] = useState(false);
  const [messageState, setMessageState] = useState(initialMessageState);
  // Enabling/Disabling Button starts
  const [checkSourceJCName, setCheckSourceJCName] = useState(false);
  const [destinationJCName, setDestinationJCName] = useState(false);
  // Enabling/Disabling Button ends
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerId, setDealerId] = useState(null);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);
  console.log(state, "STATE***");
  const onChange = (e, name, newName) => {
    let tempState = state;
    if (newName == "sourceJCName") {
      handleGetDealerDropDownList(e.target.value, newName);
    }
    if (newName == "destinationJCName") {
      handleGetDealerDropDownListDes(e.target.value, newName);
    }
    if (name == "type") {
      if (e.target.value == "1") {
        tempState["isReplace"] = true;
        tempState["isAddUpdate"] = false;
      } else {
        tempState["isReplace"] = false;
        tempState["isAddUpdate"] = true;
      }
    }
    tempState[name] = e.target.value;
    tempState["errorMessage"][name] = false;
    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));
  };

  const handleValidation = () => {
    var valid = true;
    var tempState = state;
    if (tempState["sourceJCID"]) {
      tempState["errorMessage"].sourceJCID = false;
    } else {
      tempState["errorMessage"].sourceJCID = true;
      valid = false;
    }
    if (tempState["destinationJCID"]) {
      tempState["errorMessage"].destinationJCID = false;
    } else {
      tempState["errorMessage"].destinationJCID = true;
      valid = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));
    return valid;
  };

  const handleTransferOpenModal = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    if (
      state.sourceJCName == "Dealer Not Found" ||
      state.destinationJCName == "Dealer Not Found" ||
      state.sourceJCName == "Enter Valid Dealer Id" ||
      state.destinationJCName == "Enter Valid Dealer Id"
    ) {
      state["errorMessage"].providerName = true;
      return false;
    }
    setTranserModal(true);
    state["errorMessage"].providerName = false;
  };

  const handleTransferCloseModal = () => {
    setTranserModal(false);
  };

  const handleGetDealerDropDownList = (id, type) => {
    var letters = /^[A-Za-z]+$/;
    if (id == "") {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }
    if (id == null) {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }
    if (id.match(letters)) {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }
    if (id.length < 3) {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }

    var inputData = {
      dealerID: id
    };

    AdminToolsDataService.GetDealerDropDownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        let tempState = state;
        if (message === "Success") {
          tempState[type] = responseData[0].dealerCompany || null;
          setState(tempState);
          setCheckSourceJCName(true);
        } else {
          tempState[type] = "Dealer Not Found";
          setState(tempState);
          setCheckSourceJCName(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetDealerDropDownListDes = (id, type) => {
    var letters = /^[A-Za-z]+$/;
    if (id == "") {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }
    if (id == null) {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }
    if (id.match(letters)) {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }
    if (id.length < 3) {
      state[type] = "Enter Valid Dealer Id";
      return false;
    }

    var inputData = {
      dealerID: id
    };

    AdminToolsDataService.GetDealerDropDownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        let tempState = state;
        if (message === "Success") {
          tempState[type] = responseData[0].dealerCompany || null;
          setState(tempState);
          setDestinationJCName(true);
        } else {
          tempState[type] = "Dealer Not Found";
          setState(tempState);
          setDestinationJCName(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTransferBrand = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        TransferBrand: "Started Transfer Brand..."
      }));

      AdminToolsDataService.TransferBrand(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message === "Success") {
            handleTransferCollection();
            setMessageState((prevState) => ({
              ...prevState,
              SuccessBrandTransfer: "Brand Transfer Successful..."
            }));
          } else {
            NotificationManager.error("Brand Transfer Failed.");
          }
          setTranserModal(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTransferRequests = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        TransferRequests: "Started Transfer Requests..."
      }));

      AdminToolsDataService.TransferRequests(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message === "Success") {
            handleTransferProfile();
            setMessageState((prevState) => ({
              ...prevState,
              TransferSuccessful: "Requests Transfer Successful..."
            }));
          } else {
            NotificationManager.error("Requests Transfer Failed.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTransferProfile = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        TransferProfile: "Started Transfer Profile..."
      }));

      AdminToolsDataService.TransferProfile(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message === "Success") {
            handleTransferColumnAndAttributemapping();
            setMessageState((prevState) => ({
              ...prevState,
              TransferProfileSuccessful: "Profile Transfer Successful..."
            }));
          } else {
            NotificationManager.error("Profile Transfer Failed.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTransferCollection = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        TransferCollection: "Started Transfer Collection..."
      }));

      AdminToolsDataService.TransferCollection(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            handleTransferRequests();
            setMessageState((prevState) => ({
              ...prevState,
              TransferCollectionSuccessful: "Collection Transfer Successful..."
            }));
          } else {
            NotificationManager.error("Profile Transfer Failed.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTransferColumnAndAttributemapping = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        TransferColumnAndAttributemapping:
          "Started Transfer Column And Attribute mapping..."
      }));
      AdminToolsDataService.TransferColumnAndAttributemapping(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            handleTransferProductData();
            setMessageState((prevState) => ({
              ...prevState,
              TransferColumnAndAttributemappingSuccessful:
                "Column And Attribute mapping transfer successful..."
            }));
          } else {
            NotificationManager.error(
              "Column And Attribute Mapping Transfer Failed."
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTransferProductData = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        TransferProductData: "Started Transfer Product Data..."
      }));

      AdminToolsDataService.TransferProductData(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message === "Success") {
            handleTransferProductImagesVideo();
            setMessageState((prevState) => ({
              ...prevState,
              TransferProductDataSuccessful:
                "Product Data Transfer Successful...(Wait for sometime to get indexed)"
            }));
            setTimeout(() => {
              // setState(initialState);
              setCheckSourceJCName(false);
              setDestinationJCName(false);
              setMessageState(initialMessageState);
            }, 5000);
          } else {
            NotificationManager.error("Product Data Transfer Failed.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTransferProductImagesVideo = () => {
    if (handleValidation()) {
      setLoading(true);
      let inputData = {
        srcDealerId: Number(state.sourceJCID),
        destDealerId: Number(state.destinationJCID),
        tranType: parseInt(state.type),
        transactionDoneBy: Number(dealerId)
      };
      setMessageState((prevState) => ({
        ...prevState,
        ImagesTransfer: "Started Transfer Product Images & Videos..."
      }));
      AdminToolsDataService.TransferProductImagesVideo(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setMessageState((prevState) => ({
              ...prevState,
              ImagesTransfer:
                "Product Images & Videos Transfer Successful... (Wait for sometime to get uploaed to CDN)"
            }));
            let tempState = initialState;
            tempState["sourceJCID"] = "";
            tempState["sourceJCName"] = null;
            setState((prevState) => ({
              ...prevState,
              ...tempState
            }));
            // setState(initialState);
            handleTransferCloseModal();
          } else {
            NotificationManager.error(
              "Product Images & Videos Transfer Failed."
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="transfzz__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Transfer ZZ.Gemfind")}
              </h4>
            </div>
            <div className="col-lg-12">
              <div className="col-lg-3 mt-3 mobile-m-0">
                <div className="input__block">
                  <label>
                    {traslate("Source JC ID")} <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="mb-2"
                    placeholder="Enter Source JC ID"
                    onChange={(e) => onChange(e, "sourceJCID", "sourceJCName")}
                    value={state.sourceJCID || ""}
                  />
                  <span
                    className={
                      state.sourceJCName === "Dealer Not Found" ||
                      state.sourceJCName === "Enter Valid Dealer Id"
                        ? "error-color-red"
                        : null
                    }
                  >
                    {state.sourceJCName}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 mt-4">
                <div className="input__block">
                  <label>
                    {traslate("Destination JC ID")} <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="mb-2"
                    placeholder="Enter Destination JC ID"
                    onChange={(e) =>
                      onChange(e, "destinationJCID", "destinationJCName")
                    }
                    value={state.destinationJCID || ""}
                  />
                  <span
                    className={
                      state.destinationJCName === "Dealer Not Found" ||
                      state.destinationJCName === "Enter Valid Dealer Id"
                        ? "error-color-red"
                        : null
                    }
                  >
                    {state.destinationJCName}
                  </span>
                  {state.errorMessage["destinationJCID"] ? (
                    <span className="error-color-red">
                      {traslate("Enter valid DealerID")}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-7 mt-4">
                <div className="replall__radidiv">
                  <div>
                    <input
                      type="radio"
                      id="rpa"
                      name="abc"
                      className="mr-1"
                      value={"1"}
                      checked={state.isReplace}
                      onChange={(e) => onChange(e, "type")}
                    />
                    <label htmlFor="rpa"> {traslate("Replace All")} </label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="adu"
                      name="abc"
                      className="mr-1"
                      value={"2"}
                      checked={state.isAddUpdate}
                      onChange={(e) => onChange(e, "type")}
                    />
                    <label htmlFor="adu"> {traslate("Add & Update")}</label>
                  </div>
                </div>
              </div>
              {state.errorMessage["providerName"] ? (
                <span className="error-color-red">
                  {traslate("Provide Proper DealerID")}
                </span>
              ) : null}
              <div className="col-lg-12 mt-4">
                <div className="addcons__btndiv">
                  <Button
                    className={
                      checkSourceJCName == true && destinationJCName == true
                        ? "primary-btn "
                        : "primary-btn disabled-btn"
                    }
                    loading={loading}
                    onClick={handleTransferOpenModal}
                    disabled={
                      checkSourceJCName == true && destinationJCName == true
                        ? false
                        : true
                    }
                  >
                    {traslate("Transfer")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="consupld_tbldiv"></div>
            </div>
          </div>
          <div className="display__block">
            <p>{messageState.TransferBrand && messageState.TransferBrand}</p>
            <p>
              {messageState.SuccessBrandTransfer &&
                messageState.SuccessBrandTransfer}
            </p>
            <p>
              {messageState.TransferRequests && messageState.TransferRequests}
            </p>
            <p>
              {messageState.TransferSuccessful &&
                messageState.TransferSuccessful}
            </p>
            <p>
              {messageState.TransferProfile && messageState.TransferProfile}
            </p>
            <p>
              {messageState.TransferProfileSuccessful &&
                messageState.TransferProfileSuccessful}
            </p>
            <p>
              {messageState.TransferCollection &&
                messageState.TransferCollection}
            </p>
            <p>
              {messageState.TransferCollectionSuccessful &&
                messageState.TransferCollectionSuccessful}
            </p>
            <p>
              {messageState.TransferColumnAndAttributemapping &&
                messageState.TransferColumnAndAttributemapping}
            </p>
            <p>
              {messageState.TransferColumnAndAttributemappingSuccessful &&
                messageState.TransferColumnAndAttributemappingSuccessful}
            </p>
            <p>
              {messageState.TransferProductData &&
                messageState.TransferProductData}
            </p>
            <p>
              {messageState.TransferProductDataSuccessful &&
                messageState.TransferProductDataSuccessful}
            </p>
            <p>{messageState.ImagesTransfer && messageState.ImagesTransfer}</p>
            <p>
              {messageState.ImagesTransferSuccessful &&
                messageState.ImagesTransferSuccessful}
            </p>
          </div>
        </div>
      </div>

      <Modal
        className="datamapping__modal"
        width={"55%"}
        visible={transerModal}
        onOk={handleTransferBrand}
        onCancel={handleTransferCloseModal}
        footer={[
          <Button
            key="submit"
            loading={loading}
            type="primary"
            onClick={handleTransferBrand}
          >
            {traslate("Transfer")}
          </Button>,
          <Button key="close" onClick={handleTransferCloseModal}>
            {traslate("Cancel")}
          </Button>
        ]}
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12">
            <div className="alert__popup">
              <p>
                {traslate("Are you sure you want to move data from")}{" "}
                <b>
                  {state.sourceJCName} ({Number(state.sourceJCID)})
                </b>{" "}
                {traslate("account to")}{" "}
                <b>
                  {" "}
                  {state.destinationJCName} ( {Number(state.destinationJCID)} )
                </b>{" "}
                {traslate("account ?")}
              </p>
              <span>
                {" "}
                - {traslate("You have select")}{" "}
                <b> {state.type == 1 ? " Replace All " : " Add & Update "}</b>
                {traslate("Option")}.{" "}
                {state.type == 1
                  ? " This Option Will remove Existing data and Transfer new data From Source Account."
                  : " This Option Will update Existing data and Transfer new data From Source Account."}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TransferZZGemfind;
