import { httpCatalog } from "../helper/http-common";
class CatalogDiamondDataService {
  DeleteDiamondByIds(inputData) {
    return httpCatalog.post("/Diamond/DeleteDiamondByIds", inputData);
  }
  GetFancyDiamondList(inputData) {
    return httpCatalog.post("/Diamond/GetFancyDiamondList", inputData); //API for Initial Page load and apply filters
  }
  GetDealersForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetDealersForDiamondCatalogList",
      inputData
    );
  }
  GetCertificatesForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetCertificatesForDiamondCatalogList",
      inputData
    );
  }
  GetOverToneForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetOverToneForDiamondCatalogList",
      inputData
    );
  }
  GetPermissionsForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetPermissionsForDiamondCatalogList",
      inputData
    );
  }

  GetAllDropdownData(inputData) {
    return httpCatalog.post("/Diamond/GetAllDropdownData", inputData);
  }
  GetAllMINEDropDownList(inputData) {
    return httpCatalog.post("/Diamond/GetAllMINEDropDownList", inputData);
  }
  SendInviteMailForDiamonds(inputData) {
    return httpCatalog.post("/Diamond/SendInviteMailForDiamonds", inputData);
  }
  GetAllFancyDropDownList() {
    return httpCatalog.post("/Diamond/GetAllFancyDropDownList");
  }
  GetLocationsForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetLocationsForDiamondCatalogList",
      inputData
    );
  }
  SaveDiamond(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Diamond/SaveDiamond", inputData, {
      headers,
    });
  }
  GetDiamonds(inputData, type) {
    if (type === "LAB") {
      return httpCatalog.post("/Diamond/GetLabGrownDiamondList", inputData);
    } else {
      return httpCatalog.post("/Diamond/GetMinedDiamonds", inputData);
    }
  }
  GetDynamicData(inputData) {
    return httpCatalog.post("/Diamond/GetDynamicData", inputData);
  }

  // Manage Ftp Credentials
  MangeFtpDetails(inputData) {
    return httpCatalog.post("MangeFtp/MangeFtpDetails", inputData);
  }
  UpdateFtpDetails(inputData) {
    return httpCatalog.post("MangeFtp/UpdateFtpDetails", inputData);
  }

  // Stuck File

  GetStuckFile(inputData, type) {
    const path =
      type == "Jewelry" ? "/Jewelry/JewelryStuck" : "/Diamond/DiamondStuck";
    return httpCatalog.post(path, inputData);
  }

  DeleteStuckFile(inputData) {
    return httpCatalog.post("/Diamond/DeleteStuckFile", inputData);
  }
  // Data Mapping
  GetDiamondMappingDropdownDetails(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDiamondMappingDropdownDetails",
      inputData
    );
  }
  GetDiProductList(inputData) {
    return httpCatalog.post("/Mapping/GetDiProductList", inputData);
  }
  ResetDiamondDataMapping(inputData) {
    return httpCatalog.post("/Mapping/ResetDiamondDataMapping", inputData);
  }
  UploadDiamondMappingFile(inputData) {
    return httpCatalog.post("/Mapping/UploadDiamondMappingFile", inputData);
  }
  GenerateDiamondMappingFile(inputData) {
    return httpCatalog.post("/Mapping/GenerateDiamondMappingFile", inputData);
  }

  GetDiamondMappAttributesList(inputData) {
    return httpCatalog.post("/Mapping/GetDiamondMappAttributesList", inputData);
  }

  GetDiamondNotMappAttributesList(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDiamondNotMappAttributesList",
      inputData
    );
  }
  GetDiamondMappNotMappCount(inputData) {
    return httpCatalog.post("/Mapping/GetDiamondMappNotMappCount", inputData);
  }
  GetDiamondMappingList(inputData) {
    return httpCatalog.post("/Mapping/GetDiamondMappingList", inputData);
  }

  RemoveMappDiamondDealerColumns(inputData) {
    return httpCatalog.post(
      "/Mapping/RemoveMappDiamondDealerColumns",
      inputData
    );
  }
  MappDiamondDealerColumns(inputData) {
    return httpCatalog.post("/Mapping/MappDiamondDealerColumns", inputData);
  }
  GetDiamondAttributeDetailsList(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDiamondAttributeDetailsList",
      inputData
    );
  }

  DownloadDiamondAttributesMapFile(inputData) {
    return httpCatalog.post(
      "/Mapping/DownloadDiamondAttributesMapFile",
      inputData,
      {
        responseType: "arraybuffer",
      }
    );
  }
  UploadDiamondMappSheet(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Mapping/UploadDiamondMappSheet", inputData, {
      headers,
    });
  }
  /* ---------  */

  GetDiamondColumnsOfDealerUpload(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDiamondColumnsOfDealerUpload",
      inputData
    );
  }
  UpdateDiamondColumnsOfDealerUpload(inputData) {
    return httpCatalog.post(
      "/Mapping/UpdateDiamondColumnsOfDealerUpload",
      inputData
    );
  }
  SaveDiProductList(inputData) {
    return httpCatalog.post("/Mapping/SaveDiProductList", inputData);
  }
  SendMsg(inputData) {
    return httpCatalog.post("/Diamond/SendMsg", inputData);
  }
  CertificateLoad(inputData) {
    return httpCatalog.post("/Diamond/CertificateLoad", inputData);
  }
  SendFriendemail(inputData) {
    return httpCatalog.post("/Diamond/SendFriendemail", inputData);
  }
  CreateCSVDiamonds(inputData) {
    return httpCatalog.post("/Diamond/CreateCSVDiamonds", inputData, {
      responseType: "arraybuffer",
    });
  }
  UploadDiamondImageFiles(inputData) {
    return httpCatalog.post("/Diamond/UploadDiamondImageFiles", inputData);
  }
  UploadDiamondDataFile(inputData) {
    return httpCatalog.post("/Diamond/UploadDiamondDataFile", inputData);
  }
  DiamondUpload3rdParty(inputData) {
    return httpCatalog.post("/Diamond/DiamondUpload3rdParty", inputData);
  }
  Get3rdPartyProvider(inputData) {
    return httpCatalog.post("/Diamond/Get3rdPartyProvider", inputData);
  }
  Get3rdPartyProviderHeader(inputData) {
    return httpCatalog.post("/Diamond/Get3rdPartyProviderHeader", inputData);
  }
  GetDealersForDiamondSearch(inputData) {
    return httpCatalog.post(
      "/Diamond/GetDealersForDiamondSearch",
      inputData
    );
  }
}

export default new CatalogDiamondDataService();
