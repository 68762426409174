import { httpCommunity } from "../helper/http-common";

class CommunityInventoryService {

    GetOneVendorProduct(inputData) {
        return httpCommunity.post("/Inventory/GetOneVendorProduct", inputData);
    }
    LoadLeftRightSideData(inputData) {
        return httpCommunity.post("/Inventory/LoadLeftRightSideData", inputData);
    }
    GetOneProductDetail(inputData) {
        return httpCommunity.post("/Inventory/GetOneProductDetail", inputData);
    }
    GeneratePopupForAffirmation(inputData) {
        return httpCommunity.post("/Inventory/GeneratePopupForAffirmation", inputData);
    }
    GeneratePopup(inputData) {
        return httpCommunity.post("/Inventory/GeneratePopup", inputData);
    }
    LoadVendorProfile(inputData) {
        return httpCommunity.post("/Inventory/LoadVendorProfile", inputData);
    }
    SendEmailToAllEmailType(inputData) {
        return httpCommunity.post("/Inventory/SendEmailToAllEmailType", inputData);
    }
    SaveUpdateFlag(inputData) {
        return httpCommunity.post("/Inventory/SaveUpdateFlag", inputData);
    }
    SaveUpdateFlagAll(inputData) {
        return httpCommunity.post("/Inventory/SaveUpdateFlagAll", inputData);
    }
}

export default new CommunityInventoryService();
