import React, { useState, useEffect, useRef } from "react";
import { Select, Table, Modal, Spin, Tooltip, Button } from "antd";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ReportJewelryReportService from "../../../services/reports-Jewelry-service";
import moment from "moment";
import { DatePicker } from "antd";
import DefaultImage from "../../../assets/images/icons/sampleimage.jpg";

const { RangePicker } = DatePicker;
const initialEventState = {
  dealerID: "",
  forDealer: "",
  dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
  dataRangeTo: moment().format("YYYY-MM-DD"),
  retailerIds: [],
  vendorIds: [],
  category: "",
};

const JewelryCategoryReport = () => {
  const JewelCategoryChartRef = useRef();
  const { Option } = Select;

  const [vendorList, setVendorList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [defaultVisible, setDefaultVisible] = useState(false);
  const [retailerList, setRetailerList] = useState([]);
  const [categoryColumnData, setCategoryColumnData] = useState([]);
  const [countCategoryColumnData, setCountCategoryColumnData] = useState([]);
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [showLoader, setShowLoader] = useState(false);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [JewelryCategoryTableList, setJewelryCategoryTableList] = useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [key, setKey] = useState(true);
  const [dealerMembershipType, setDealerMembershiptype] = useState("");
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrderCol, setsortOrderCol] = useState("userIPAddress");
  const [sortOrderType, setsortOrderType] = useState("desc");
  const [totalRecords, setTotalRecords] = useState(0);
  const [downloadCSVDataloading, setDownloadCSVDataloading] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
          setDealerMembershiptype(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (selectedData.category)
      handleGetTableChartData(
        dealerId,
        loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
          ? 1
          : 3,
        1,
        10,
        sortOrderCol,
        sortOrderType
      );
  }, [selectedData]);

  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id,
      };
      setShowLoader(true);
      ReportJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            let forDealerValue;
            if (
              loginDetials.loginDetials.responseData.dealerMemberTypeName ===
              "Vendor"
            ) {
              forDealerValue = 1;
            } else {
              forDealerValue = 3;
            }
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer,
            }));
            handleGetVendorDropdownData();
            handleGetRetailerDropdownData();
            handleGetTableChartData(
              id,
              forDealerValue,
              pageNo,
              pageSize,
              sortOrderCol,
              sortOrderType
            );
            handleGetClicksBasedOnCategory(id, forDealerValue);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetVendorDropdownData = () => {
    try {
      const dealerId = loginDetials.loginDetials.responseData.dealerId;
      let inputData = {
        dealerId: dealerId,
        reporttype: "Dealer",
      };
      ReportJewelryReportService.GetVendorDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetRetailerDropdownData = () => {
    try {
      const dealerId = loginDetials.loginDetials.responseData.dealerId;
      let inputData = {
        dealerId: dealerId,
        reporttype: "Dealer",
      };
      ReportJewelryReportService.GetRetailerDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const changeRetailerListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        vendorIds: data,
      };
      ReportJewelryReportService.ChangeRetailerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const changeVendorListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        retailerIds: data,
      };
      ReportJewelryReportService.ChangeVendorList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId,
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e,
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId,
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e,
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };

  const handleGetClicksBasedOnCategory = (DealerId, forDealerId) => {
    try {
      let inputData = {
        dealerID: DealerId.toString(),
        forDealer: forDealerId ? forDealerId.toString() : "",
        fromDate: selectedData.dataRangeFrom
          ? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: selectedData.dataRangeTo
          ? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        reporttype: "Dealer",
      };
      setShowLoader(true);
      ReportJewelryReportService.GetClicksBasedOnCategory(inputData).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.Table;
          if (message === "Success") {
            let category = [];
            let count = [];
            for (let i of responseData) {
              category.push(i.Category);
              count.push(i.count);
            }
            let totalValuePercentage = count.reduce(
              (a, b) => (a = Number(a) + Number(b)),
              0
            );
            setCountCategoryColumnData(count);
            setCategoryColumnData(category);
            setTotalValueForPercentage(totalValuePercentage);
            setShowLoader(false);
            setKey(!key);
          } else {
            setShowLoader(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  const [totalCount, setTotalCount] = useState(0);

  const handleGetTableChartData = (
    DealerId,
    forDealerId,
    pageNumber,
    pageSize,
    sortColumn,
    sorttype
  ) => {
    try {
      let inputData = {
        dealerID: DealerId.toString(),
        forDealer: forDealerId ? forDealerId.toString() : "",
        fromDate: selectedData.dataRangeFrom
          ? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: selectedData.dataRangeTo
          ? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: selectedData.category,
        metalType: "",
        metalColorType: "",
        price: "",
        vendor: "",
        retailer: "",
        pageIndex: pageNumber ? pageNumber : 1,
        pageSize: pageSize ? pageSize : 10,
        sortColumnName:
          sortColumn == "UserIPAddress"
            ? "UserIPAddress"
            : sortColumn == "vendorName"
            ? "VendorName"
            : sortColumn == "retailerName"
            ? "RetailerName"
            : sortColumn == "collectionNew"
            ? "CollectionNew"
            : sortColumn == "category"
            ? "Category"
            : sortColumn == "date"
            ? "Date"
            : sortColumn == "styleNumber"
            ? "StyleNumber"
            : sortColumn == "metalType"
            ? "MetalType"
            : sortColumn == "metalColor"
            ? "MetalColor"
            : sortColumn == "price"
            ? "Price"
            : "date",
        sortOrder: sorttype ? sorttype.toString() : sortOrderType.toString(),
        reporttype: "Dealer",
      };
      setShowTableLoader(true);
      ReportJewelryReportService.GetJewelryTableChartData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            const totalRecords = responseData?.[0]?.totalRecords || 0;
            setTotalRecords(totalRecords);
            setShowTableLoader(false);
            setJewelryCategoryTableList(responseData);
            setTotalCount(responseData?.[0]?.totalRecords || 0);
          } else {
            setTotalCount(0);
            setJewelryCategoryTableList([]);
            setShowTableLoader(false);
          }
        })
        .catch((error) => {
          setTotalCount(0);
          setShowTableLoader(false);
          console.log(error);
        });
    } catch (error) {
      setTotalCount(0);
      console.log(error);
      setShowTableLoader(false);
    }
  };

  const handleApplyFilter = () => {
    setPageNo(1);
    setPageSize(10);
    handleGetForDealer(dealerId);
    setIsApplyFilter(!isApplyFilter);
  };

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };

  const handleExportToCSV = (sortColumn, sorttype) => {
    try {
      let forDealerValue = "";
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
      ) {
        forDealerValue = 1;
      } else {
        forDealerValue = 3;
      }
      const inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealerValue.toString(),
        fromDate: selectedData.dataRangeFrom
          ? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: selectedData.dataRangeTo
          ? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: "",
        metalType: "",
        metalColorType: "",
        price: "",
        vendor: "",
        retailer: "",
        pageIndex: pageNo ? pageNo : 1,
        pageSize: pageSize ? pageSize : 10,
        sortColumnName:
          sortColumn === "UserIPAddress"
            ? "UserIPAddress"
            : sortColumn === "vendorName"
            ? "VendorName"
            : sortColumn === "retailerName"
            ? "RetailerName"
            : sortColumn === "collectionNew"
            ? "CollectionNew"
            : sortColumn === "category"
            ? "Category"
            : sortColumn === "date"
            ? "Date"
            : sortColumn === "styleNumber"
            ? "StyleNumber"
            : sortColumn === "metalType"
            ? "MetalType"
            : sortColumn === "metalColor"
            ? "MetalColor"
            : sortColumn === "price"
            ? "Price"
            : "UserIPAddress",
        sortOrder: sorttype ? sorttype.toString() : sortOrderType.toString(),
        reporttype: "Dealer",
      };
      setDownloadCSVDataloading(true);
      ReportJewelryReportService.ExportDataToCSV(inputData)
        .then((response) => {
          setDownloadCSVDataloading(false);
          var fileName =
            "JewelryCategoryReport_" +
            new Date().toJSON().slice(0, 10).split("-").reverse().join("/") +
            ".csv";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          setDownloadCSVDataloading(false);
        });
    } catch (error) {
      setDownloadCSVDataloading(false);
    }
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var obj = {};
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setsortOrderType(type);
      setsortOrderCol(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setsortOrderType(type);
      setsortOrderCol(column);
    } else {
      type = "DESC";
      column = "date";
      setsortOrderCol(column);
      setsortOrderType(type);
    }
    const forDealerValue =
      loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
        ? 1
        : 3;
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      dealerId,
      forDealerValue,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Jewelry Category Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          {dealerMembershipType === "Vendor" ||
          dealerMembershipType === "VendorDiamond" ? null : (
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Vendor(s)")} </label>
                <Select
                  className="border__grey"
                  showSearch
                  mode="multiple"
                  showArrow
                  placeholder="Select One or More Vendors"
                  optionFilterProp="children"
                  value={selectedData.vendorIds}
                  onChange={(e) => handleOnChange(e, "select", "vendors")}
                >
                  {vendorList.map((item, key) => {
                    return (
                      <Option key={item.dealerID} value={item.dealerID}>
                        {" "}
                        {item.dealerCompany}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          {dealerMembershipType === "Retailer" ? null : (
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Retailer(s)")} </label>
                <Select
                  showSearch
                  showArrow
                  className="border__grey"
                  placeholder="Select one or more retailer "
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "retailers")}
                  notFoundContent="No Data Found"
                  mode="multiple"
                  value={selectedData.retailerIds}
                >
                  {retailerList.map((item, key) => {
                    return (
                      <Option key={key} value={item.dealerID}>
                        {" "}
                        {item.dealerCompany}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          <div className="col-md-4 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <b>
                  {" "}
                  {traslate("Total Jewelry Clicks")} : {totalRecords}{" "}
                </b>
                <Tooltip
                  placement="right"
                  title={traslate(
                    "Displays the total number of jewelry clicks through the currently specified date range above"
                  )}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>

          <Spin spinning={showLoader}>
            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Category")} </b>
              </label>
              <div className="chart__section">
                {categoryColumnData.length > 0 ? (
                  categoryColumnData !== null &&
                  categoryColumnData.length > 0 &&
                  countCategoryColumnData.length > 0 &&
                  totalValueForPercentage !== 0 && (
                    <Pie
                      key={key}
                      onClick={(event) => {
                        const element = getElementAtEvent(
                          JewelCategoryChartRef.current,
                          event
                        );
                        if (!element.length) return;

                        const { index } = element[0];

                        setSelectedData((prevState) => ({
                          ...prevState,
                          category: categoryColumnData[index],
                        }));
                      }}
                      ref={JewelCategoryChartRef}
                      data={{
                        labels: categoryColumnData,
                        datasets: [
                          {
                            data: countCategoryColumnData,
                            backgroundColor: [
                              "rgb(14, 220, 232)",
                              "rgb(16, 150, 24)",
                              "rgb(255, 153, 0)",
                              "rgb(153, 0, 153)",
                              "rgb(0, 153, 198)",
                              "rgb(51, 102, 204)",
                              "rgb(220, 57, 18)",
                              "rgb(102, 170, 0)",
                              "rgb(221, 68, 119)",
                              "rgb(237, 237, 1)",
                            ],
                            borderColor: [
                              "rgb(14, 220, 232)",
                              "rgb(16, 150, 24)",
                              "rgb(255, 153, 0)",
                              "rgb(153, 0, 153)",
                              "rgb(0, 153, 198)",
                              "rgb(51, 102, 204)",
                              "rgb(220, 57, 18)",
                              "rgb(102, 170, 0)",
                              "rgb(221, 68, 119)",
                              "rgb(237, 237, 1)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      width={300}
                      height={300}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          datalabels: {
                            display: true,
                            align: "end",
                            color: "rgb(255, 255, 255)",
                            font: {
                              size: 12,
                            },
                            formatter: (value) => {
                              if (totalValueForPercentage) {
                                let finalPercentage = (
                                  (value / totalValueForPercentage) *
                                  100
                                ).toFixed(2);
                                setTooltipPercentage(finalPercentage);
                                if (finalPercentage >= 5) {
                                  return finalPercentage + "%";
                                } else {
                                  return "";
                                }
                              }
                            },
                          },
                          tooltip: {
                            enabled: true,
                            callbacks: {
                              label: function (context) {
                                let label = context.label || "";
                                let formattedValue =
                                  context.formattedValue || "";

                                if (label) {
                                  label =
                                    label +
                                    " " +
                                    formattedValue +
                                    " (" +
                                    TooltipPercentage +
                                    "%" +
                                    ")";
                                }
                                return label;
                              },
                            },
                          },
                          legend: {
                            position: "left",
                          },
                        },
                      }}
                    />
                  )
                ) : (
                  <div className="no-report-render">
                    <div className="desc-space">
                      <h4 className="text-center">
                        {traslate("No Data Found")}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Spin>

          <div className="col-lg-12 mt-3">
            <div className="options__div">
              <div className="row mb-4">
                <div className="col-lg-10 rules__div"></div>
                <div className="col-lg-2 crud__div">
                  <Button
                    className="primary-btn"
                    onClick={() => handleExportToCSV()}
                    loading={downloadCSVDataloading}
                  >
                    {" "}
                    <i className="fa fa-download"></i>{" "}
                    {traslate("Download CSV")}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="table__scroll">
              <Table
                className="pendjewelreq__tbl"
                columns={[
                  {
                    title: traslate("Product Image"),
                    dataIndex: "imagePathNew",
                    width: 90,
                    render: (item, data) => (
                      <>
                        {data.imagePathNew ? (
                          <span
                            onClick={() => imageClickHandler(data.imagePathNew)}
                          >
                            <img
                              src={data.imagePathNew}
                              alt="img"
                              className="product__img"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        ) : (
                          <span onClick={() => setDefaultVisible(true)}>
                            <img
                              src={DefaultImage}
                              alt="img"
                              className="product__img"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        )}
                      </>
                    ),
                  },
                  {
                    title: traslate("User IP Address"),
                    dataIndex: "userIPAddress",
                    sorter: (a, b) => {},
                    width: 90,
                  },
                  ...(dealerMembershipType !== "Vendor" &&
                  dealerMembershipType !== "VendorDiamond"
                    ? [
                        {
                          title: traslate("Vendor"),
                          dataIndex: "vendorName",
                          width: 120,
                          sorter: (a, b) => {},
                        },
                      ]
                    : []),
                  ...(dealerMembershipType !== "Retailer"
                    ? [
                        {
                          title: traslate("Retailer"),
                          dataIndex: "retailerName",
                          width: 120,
                          sorter: (a, b) => {},
                        },
                      ]
                    : []),
                  {
                    title: traslate("Collection"),
                    dataIndex: "collectionNew",
                    width: 120,
                    sorter: (a, b) => {},
                  },
                  {
                    title: traslate("Category"),
                    dataIndex: "category",
                    width: 120,
                    sorter: (a, b) => {},
                  },
                  {
                    title: traslate("Date"),
                    dataIndex: "date",
                    width: 120,
                    sorter: (a, b) => {},
                    render: (row, item) => {
                      if (Object.keys(item.date).length !== 0) {
                        return (
                          <React.Fragment>
                            <span>
                              {moment(item.date).format(
                                "MM-DD-YYYY, hh:mm:ss A"
                              )}
                            </span>
                          </React.Fragment>
                        );
                      }
                    },
                  },
                  {
                    title: traslate("Style Number"),
                    dataIndex: "styleNumber",
                    width: 120,
                    sorter: (a, b) => {},
                  },
                  {
                    title: traslate("Metal Type"),
                    dataIndex: "metalType",
                    width: 100,
                    sorter: (a, b) => {},
                  },
                  {
                    title: traslate("Metal Color"),
                    dataIndex: "metalColor",
                    width: 100,
                    sorter: (a, b) => {},
                  },
                  {
                    title: traslate("Price"),
                    dataIndex: "price",
                    sorter: (a, b) => {},
                    width: 100,
                  },
                ]}
                loading={showTableLoader}
                dataSource={JewelryCategoryTableList}
                scroll={{ x: 600, y: 800 }}
                pagination={{
                  total: totalCount,
                  pageNo: pageNo,
                  pageSize: pageSize,
                  pageSizeOptions: [10, 20, 50, 100],
                  responsive: true,
                  showSizeChanger: true,
                  current: pageNo,
                }}
                onChange={handleTableASCDES}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} className="img-fluid" />
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={defaultVisible}
        onOk={() => setDefaultVisible(false)}
        onCancel={() => setDefaultVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={DefaultImage} alt="img" className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default JewelryCategoryReport;
