import { httpDealer } from "../helper/http-common";

class ManageWhiteLabelService {
  GetCompanyWhiteLabel(inputData) {
    return httpDealer.get("/Dashboard/CompanyWhileLabel", inputData);
  }

  UpdateWhiteLabel(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Dashboard/UpdateWhiteLabel", inputData, {
      headers,
    });
  }

  GetOrganizationList(inputData) {
    return httpDealer.post("/Dashboard/GetWhiteLabelDatabase", inputData);
  }

  AddNewOrganization(inputData) {
    return httpDealer.post(
      "/Dashboard/InsertUpdateWhiteLabelDatabase",
      inputData
    );
  }

  CreateOrganizationDB(id) {
    return httpDealer.get(`/Dashboard/CreateDatabase?id=${id}`);
  }
}

export default new ManageWhiteLabelService();
