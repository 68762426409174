import React, { useEffect, useState, useRef } from "react";
import { Select, Tooltip, Table, Spin, Modal } from "antd";
import { useSelector } from "react-redux";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { DatePicker } from "antd";
import traslate from "../../../i18n/translate";
import moment from "moment";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import DoughnutChart from "./Doughnut";

/*Initial State for Binding Starts*/
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: [],
  price: "",
  price1: "",
  price2: "",
};
/*Initial State for Binding Ends*/
const { RangePicker } = DatePicker;
const { Option } = Select;

const JewelryChartReportAdmin = () => {
  const JewelPriceChartRef = useRef();
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [categoryColumnData, setCategoryColumnData] = useState([]);
  const [countCategoryColumnData, setCountCategoryColumnData] = useState([]);
  const [countColumnData, setCountColumnData] = useState([]);
  const [topRetailerDealer, setTopRetailerDealer] = useState([]);
  const [topRetailerTotal, setTopRetailerTotal] = useState([]);
  const [priceRange, setpriceRange] = useState([]);
  const [priceTotal, setPriceTotal] = useState([]);
  const [topVendorDealer, setTopVendorDealer] = useState([]);
  const [topVendorTotal, setTopVendorTotal] = useState([]);
  const [showChartVendor, setShowChartVendor] = useState(false);
  const [showChartRetailer, setShowChartRetailer] = useState(false);
  const [showChartPrice, setShowChartPrice] = useState(false);
  const [showChartCategory, setShowChartCategory] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [tableChartData, setTableChartData] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [showTableLoader, setShowTableLoader] = useState(true);
  const [retailerTotalValuePercentage, setRetailerTotalValuePercentage] =
    useState(0);
  const [categoryTotalValuePercentage, setCategoryTotalValuePercentage] =
    useState(0);
  const [priceTotalValuePercentage, setPriceTotalValuePercentage] = useState(0);
  const [vendorTotalValuePercentage, setVendorTotalValuePercentage] =
    useState(0);
  const [key, setKey] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortType, setSortType] = useState("desc");
  const [chartType, setChartType] = useState("");
  const [chartSection, setChartSection] = useState("");

  const data1 = [];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setSelectedData((prevState) => ({
            ...prevState,
            dealerID: loginDetials.loginDetials.responseData.dealerId,
          }));
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
  }, []);

  const handleGetForDealer = (id) => {
    const initialEventState = {
      dealerID: id,
    };
    setShowLoader(true);
    AdminJewelryReportService.GetForDealer(initialEventState).then(
      (response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setSelectedData((prevState) => ({
            ...prevState,
            forDealer: responseData.forDealer,
          }));
          handleGetClicksBasedOnVendor(id, responseData.forDealer);
          handleGetClicksBasedOnPrice(id, responseData.forDealer);
          handleGetTopRetailerCoulmnData(id, responseData.forDealer);
          handleGetTotalCount(id, responseData.forDealer);
          handleGetClicksBasedOnCategory(id, responseData.forDealer);
          handleGetTableChartData(id, responseData.forDealer);
        }
      }
    );
  };
  const handleGetClicksBasedOnVendor = (DealerId, forDealerId) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate
        ? moment(selectedData.fromDate).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: selectedData.toDate
        ? moment(selectedData.toDate).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
      reporttype: "Global",
    };
    setShowLoader(true);
    setTopVendorTotal([]);
    setTopVendorDealer([]);
    AdminJewelryReportService.GetClicksBasedOnVendor(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let dealerCompany = [];
          let total = [];
          let showResp = responseData.every((element) => {
            return element.total == "0";
          });
          setShowChartVendor(showResp);
          for (let i of responseData) {
            dealerCompany.push(i.dealerCompany);
            total.push(i.total);
          }
          let totalValuePercentage = total.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );
          setTopVendorTotal(total);
          setTopVendorDealer(dealerCompany);
          setVendorTotalValuePercentage(totalValuePercentage);
          setKey(!key);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoader(false));
  };
  const handleGetClicksBasedOnPrice = (DealerId, forDealerId) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate
        ? moment(selectedData.fromDate).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: selectedData.toDate
        ? moment(selectedData.toDate).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
    };
    setShowLoader(true);
    setPriceTotal([]);
    setpriceRange([]);
    AdminJewelryReportService.GetClicksBasedOnPrice(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let showResp = responseData.every((element) => {
            return element.total == "0";
          });
          setShowChartPrice(showResp);
          let range = [];
          let total = [];
          for (let i of responseData) {
            range.push(i.range);
            total.push(i.total);
          }
          let totalValuePercentage = total.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );
          setPriceTotal(total);
          setpriceRange(range);
          setPriceTotalValuePercentage(totalValuePercentage);
          setKey(!key);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoader(false));
  };
  const handleGetTopRetailerCoulmnData = (DealerId, forDealerId) => {
    const initialEventState = {
      // ...selectedData,
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate
        ? moment(selectedData.fromDate).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: selectedData.toDate
        ? moment(selectedData.toDate).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
      reporttype: "Global",
    };
    setShowLoader(true);
    setTopRetailerTotal([]);
    setTopRetailerDealer([]);
    AdminJewelryReportService.GetClicksBasedOnRetailer(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let dealerName = [];
          let total = [];
          let showResp = responseData.every((element) => {
            return element.total == "0";
          });
          setShowChartRetailer(showResp);
          for (let i of responseData) {
            dealerName.push(i.dealerCompany);
            total.push(i.total);
          }
          let totalValuePercentage = total.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );
          setTopRetailerTotal(total);
          setTopRetailerDealer(dealerName);
          setRetailerTotalValuePercentage(totalValuePercentage);
          setKey(!key);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoader(false));
  };
  const handleGetClicksBasedOnCategory = (DealerId, forDealerId) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate
        ? moment(selectedData.fromDate).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: selectedData.toDate
        ? moment(selectedData.toDate).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
    };
    setShowLoader(true);
    setCountCategoryColumnData([]);
    setCategoryColumnData([]);
    AdminJewelryReportService.GetClicksBasedOnCategory(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let category = [];
          let count = [];
          let showResp = responseData.every((element) => {
            return element.count == "0";
          });
          setShowChartCategory(showResp);
          for (let i of responseData) {
            category.push(i.category);
            count.push(i.count);
          }
          let totalValuePercentage = count.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );
          setCountCategoryColumnData(count);
          setCategoryColumnData(category);
          setCategoryTotalValuePercentage(totalValuePercentage);
          setKey(!key);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoader(false));
  };
  const handleGetTotalCount = (DealerId, forDealerId) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate
        ? moment(selectedData.fromDate).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: selectedData.toDate
        ? moment(selectedData.toDate).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
    };
    setShowLoader(true);
    AdminJewelryReportService.GetClicksCount(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setCountColumnData(responseData[0].totalCount);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoader(false));
  };
  const handleGetTableChartData = async (
    DealerId,
    forDealerId,
    type,
    section,
    pageNumber,
    pageSize,
    sortColumn,
    sorttype
  ) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate, //?moment(
      toDate: selectedData.toDate, // ? moment(
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
      category: section == "category" && type ? type : "",
      metalType: "",
      metalColorType: "",
      price: selectedData.price,
      price1: selectedData.price1.trim().toString(),
      price2: selectedData.price2.trim().toString(),
      vendor: section == "vendor" && type ? type : "",
      retailer: section == "retailer" && type ? type : "",
      reporttype: "Global",
      pageIndex: pageNumber ? pageNumber : 1,
      pageSize: pageSize ? pageSize : 10,
      sortColumnName:
        sortColumn == "UserIPAddress"
          ? "UserIPAddress"
          : sortColumn == "Vendor"
          ? "VendorName"
          : sortColumn == "Retailer"
          ? "RetailerName"
          : sortColumn == "Collection"
          ? "CollectionNew"
          : sortColumn == "Category"
          ? "Category"
          : sortColumn == "Date"
          ? "Date"
          : sortColumn == "StyleNumber"
          ? "StyleNumber"
          : sortColumn == "MetalType"
          ? "MetalType"
          : sortColumn == "MetalColor"
          ? "MetalColor"
          : sortColumn == "Price"
          ? "Price"
          : "date",
      sortOrder: sorttype ? sorttype.toString() : sortType.toString(),
    };
    setShowTableLoader(true);
    AdminJewelryReportService.GetTableChartData(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setTotalCount(responseData?.[0]?.totalRecords || 0);
          responseData.map((elem, index) => {
            data1.push({
              key: index,
              ProductImage: (
                <span onClick={() => imageClickHandler(elem.imagePathNew)}>
                  <img src={elem.imagePathNew} className="product__img" />
                </span>
              ),
              UserIPAddress: <span>{elem.userIPAddress}</span>,
              Vendor: (
                <span>{elem.vendorName === null ? "" : elem.vendorName}</span>
              ),
              Retailer: (
                <span>
                  {elem.retailerName === null ? "" : elem.retailerName}
                </span>
              ),
              Collection: <span>{elem.collectionNew}</span>,
              Category: <span>{elem.category}</span>,
              Date: <span>{elem.dateTime}</span>,
              StyleNumber: <span>{elem.styleNumber}</span>,
              MetalType: <span>{elem.metalType}</span>,
              MetalColor: <span>{elem.metalColor}</span>,
              Price: <span>{elem.price}</span>,
            });
          });
          setTableChartData(data1);
        } else {
          setTotalCount(0);
          setSelectedData((prevState) => ({
            ...prevState,
            price: "",
            price1: "",
            price2: "",
          }));
        }
      })
      .catch((error) => {
        setTotalCount(0);
        console.log(error);
        setShowTableLoader(false);
        setSelectedData((prevState) => ({
          ...prevState,
          price: "",
          price1: "",
          price2: "",
        }));
      })
      .finally(() => {
        setShowTableLoader(false);
      });
  };
  const handleGetVendorDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "Dealer",
    };
    AdminJewelryReportService.GetVendorDropdownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetRetailerDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "Dealer",
    };
    AdminJewelryReportService.GetRetailerDropdownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeRetailerListHandler = (data) => {
    data = data.toString();
    let inputData = {
      vendorIds: data,
    };
    AdminJewelryReportService.ChangeRetailerList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeVendorListHandler = (data) => {
    data = data.toString();
    let inputData = {
      retailerIds: data,
    };
    AdminJewelryReportService.ChangeVendorList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(), //moment("2015-08-11T13:00:00.000000Z", "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format()
        toDate: moment.utc(dates[1]).toISOString(),
      }));
    }
  };
  /*DatePicker Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId,
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e,
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId,
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e,
        }));
      }
      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };
  const applyFilterHandler = () => {
    handleGetClicksBasedOnVendor(selectedData.dealerID, selectedData.forDealer);
    handleGetClicksBasedOnPrice(selectedData.dealerID, selectedData.forDealer);
    handleGetTopRetailerCoulmnData(
      selectedData.dealerID,
      selectedData.forDealer
    );
    handleGetTotalCount(selectedData.dealerID, selectedData.forDealer);
    handleGetClicksBasedOnCategory(
      selectedData.dealerID,
      selectedData.forDealer
    );
    var obj = selectedData;
    obj["price"] = "";
    obj["price1"] = "";
    obj["price2"] = "";
    setSelectedData((prevState) => ({
      ...prevState,
      price: "",
      price1: "",
      price2: "",
    }));
    setPageNo(1);
    handleGetTableChartData(selectedData.dealerID, selectedData.forDealer);
    setPageSize(10);
  };
  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
    } else {
      type = "desc";
      column = "date";
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      chartType,
      chartSection,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  const columns = [
    {
      title: traslate("Product Image"),
      dataIndex: "ProductImage",
      width: 90,
    },
    {
      title: traslate("User IP Address"),
      dataIndex: "UserIPAddress",
      sorter: (a, b) => {},
      width: 90,
    },
    {
      title: traslate("Vendor"),
      dataIndex: "Vendor",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: traslate("Retailer"),
      dataIndex: "Retailer",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: traslate("Collection"),
      dataIndex: "Collection",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: traslate("Category"),
      dataIndex: "Category",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: traslate("Date"),
      dataIndex: "Date",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: traslate("Style Number"),
      dataIndex: "StyleNumber",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: traslate("Metal Type"),
      dataIndex: "MetalType",
      sorter: (a, b) => {},
      width: 100,
    },
    {
      title: traslate("Metal Color"),
      dataIndex: "MetalColor",
      sorter: (a, b) => {},
      width: 100,
    },
    {
      title: traslate("Price"),
      dataIndex: "Price",
      sorter: (a, b) => {},
      width: 120,
    },
  ];
  const tableSortHandler = (type, section) => {
    debugger;
    var obj = selectedData;
    if (section == "price") {
      const price = type == "Call For Price" ? type : "";
      const price1 =
        type == "Call For Price"
          ? ""
          : type.includes("Under")
          ? "0"
          : type.split("-")[0].split("$")[1];
      const price2 =
        type == "Call For Price"
          ? ""
          : type.includes("Under")
          ? type.split("$")[1]
          : type.split("-").length > 1
          ? type.split("-")[1].split("$")[1]
          : type;

      obj["price"] = price;
      obj["price1"] = price1;
      obj["price2"] = price2;
    }
    setPageNo(1);
    setPageSize(10);
    setChartType(type);
    setChartSection(section);
    setSelectedData(obj);
    handleGetTableChartData(obj.dealerID, obj.forDealer, type, section);
    obj["price"] = "";
    obj["price1"] = "";
    obj["price2"] = "";
    obj["category"]="";
  };
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Jewelry Chart Reports")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Date Range")} </label>

              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Vendor(s)")} </label>
              <Select
                className="border__grey"
                showSearch
                mode="multiple"
                showArrow
                placeholder="Select One or More Vendors"
                optionFilterProp="children"
                value={selectedData.vendorIds}
                onChange={(e) => handleOnChange(e, "select", "vendors")}
              >
                {vendorList.map((item, key) => {
                  return (
                    <Option key={item.dealerID} value={item.dealerID}>
                      {" "}
                      {item.dealerCompany}{" "}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Retailer(s)")} </label>
              <Select
                showSearch
                showArrow
                className="border__grey"
                placeholder="Select One or More Retailers"
                optionFilterProp="children"
                onChange={(e) => handleOnChange(e, "select", "retailers")}
                notFoundContent="No Data Found"
                mode="multiple"
                value={selectedData.retailerIds}
              >
                {retailerList.map((item, key) => {
                  return (
                    <Option key={key} value={item.dealerID}>
                      {" "}
                      {item.dealerCompany}{" "}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <button className="primary-btn mt-2" onClick={applyFilterHandler}>
                {traslate("Apply Filter")}
              </button>
            </div>
          </div>
          <Spin spinning={showLoader}>
            <div className="col-lg-12 col-md-6">
              <div className="input__block">
                <label>
                  <b>
                    {traslate("Total Jewelry Clicks")} : {countColumnData}
                  </b>
                  <Tooltip
                    placement="right"
                    title={traslate(
                      "Displays the total number of jewelry clicks through the currently specified date range above"
                    )}
                  >
                    <img src={UnionIcon} alt="img" />
                  </Tooltip>
                </label>
              </div>
            </div>
            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Category")} </b>
              </label>
              <div className="chart__section">
                {categoryColumnData !== null &&
                categoryColumnData.length >= 1 &&
                countCategoryColumnData.length >= 1 &&
                categoryTotalValuePercentage !== 0 &&
                !showChartCategory ? (
                  <DoughnutChart
                    key={key}
                    label={categoryColumnData}
                    data={countCategoryColumnData}
                    typeGetter={tableSortHandler}
                    section={"category"}
                    totalValuePercentage={categoryTotalValuePercentage}
                  />
                ) : (
                  <div className="no-report-render">
                    <div className="desc-space">
                      <h4 className="text-center">
                        {traslate("No Data Found")}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Price")} </b>
              </label>
              <div className="chart__section">
                {priceRange !== null &&
                priceRange.length >= 1 &&
                priceTotal.length >= 1 &&
                priceTotalValuePercentage !== 0 &&
                !showChartPrice ? (
                  <DoughnutChart
                    key={key}
                    ref={JewelPriceChartRef}
                    label={priceRange}
                    data={priceTotal}
                    typeGetter={tableSortHandler}
                    section={"price"}
                    totalValuePercentage={priceTotalValuePercentage}
                  />
                ) : (
                  <div className="no-report-render">
                    <div className="desc-space">
                      <h4 className="text-center">
                        {traslate("No Data Found")}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Vendor")} </b>
              </label>
              <div className="chart__section">
                {topRetailerDealer !== null &&
                topRetailerDealer.length >= 1 &&
                topRetailerTotal.length >= 1 &&
                retailerTotalValuePercentage !== 0 &&
                !showChartRetailer ? (
                  <DoughnutChart
                    key={key}
                    label={topRetailerDealer}
                    data={topRetailerTotal}
                    typeGetter={tableSortHandler}
                    section={"retailer"}
                    totalValuePercentage={retailerTotalValuePercentage}
                  />
                ) : (
                  <div className="no-report-render">
                    <div className="desc-space">
                      <h4 className="text-center">
                        {traslate("No Data Found")}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Retailer")} </b>
              </label>
              <div className="chart__section">
                {topVendorDealer !== null &&
                topVendorDealer.length >= 1 &&
                topVendorTotal.length >= 1 &&
                vendorTotalValuePercentage !== 0 &&
                !showChartVendor ? (
                  <DoughnutChart
                    key={key}
                    label={topVendorDealer}
                    data={topVendorTotal}
                    typeGetter={tableSortHandler}
                    section={"vendor"}
                    totalValuePercentage={vendorTotalValuePercentage}
                  />
                ) : (
                  <div className="no-report-render">
                    <div className="desc-space">
                      <h4 className="text-center">
                        {traslate("No Data Found")}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <Table
                className="pendjewelreq__tbl"
                columns={columns}
                dataSource={tableChartData}
                scroll={{ x: 600, y: 800 }}
                loading={showTableLoader}
                pagination={{
                  total: totalCount,
                  pageSizeOptions: [10, 20, 50, 100],
                  responsive: true,
                  showSizeChanger: true,
                  pageSize: pageSize,
                  current: pageNo,
                }}
                onChange={handleTableASCDES}
              />
            </div>
          </Spin>
        </div>
      </div>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default JewelryChartReportAdmin;
