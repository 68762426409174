import React, { useState, useEffect } from "react";
import {
  Select,
  Table,
  Button,
  Tooltip,
  Slider,
  Checkbox,
  Input,
  Spin,
} from "antd";
import { useSelector } from "react-redux";
import Rectangle from "../../../assets/images/Rectangle.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";
import space__icon from "../../../assets/images/space__icon.png";
import { NotificationManager } from "react-notifications";
import config from "../../../helper/config";

const { Option } = Select;
//Initial Watch Data
const myWatchData = {
  category: null,
  materialType: null,
  genderSelect: null,
  vendor: null,
  materialColor: null,
  collectionSelect: null,
  priceMin: 0,
  priceMax: 99999,
};

const Settings = () => {
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [visible, setvisible] = useState(true);
  /*Custom Binding Starts*/
  const [filterWatchValue, setfilterWatchValue] = useState({
    category: null,
    materialType: null,
    genderSelect: null,
    vendor: null,
    materialColor: null,
    collectionSelect: null,
    priceMin: 0,
    priceMax: 99999,
  });
  /*Custom Binding Ends*/
  const gender = (
    <span>
      {traslate(
        "Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."
      )}
    </span>
  );

  const collection = (
    <span>
      {" "}
      {traslate("Please select a vendor before choosing a collection.")}{" "}
    </span>
  );

  const [dealerId, setDealerId] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [materialColorList, setMaterialColorList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [sliderValue, setSliderValue] = useState([0, 99999]);
  const [isLoading, setIsLoading] = useState(false);
  const [settingsList, setSettingsList] = useState([]);
  const [gfInventoryIDList, setGfInventoryIDList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchValue, SetSearchValue] = useState("");
  const [mainCheckVal, setMainCheckVal] = useState(false);
  const [filterLoading, setApplyFilterLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const loginDetials = useSelector((state) => state.loginReducer);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency?.substring(currency.lastIndexOf("-") + 1);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetJewelryList(
            loginDetials.loginDetials.responseData.dealerId,
            pageNo,
            pageSize,
            searchValue,
            "NullVal"
          );
        }
      }
    }
  }, []);

  const onSearch = (value) => {
    SetSearchValue(value);
    handleGetJewelryList(dealerId, 1, 10, value, "");
  };

  /* API for DropdownList */

  const handleGetRingBuilderDropdownData = async (
    id,
    list,
    selectedFilter,
    updatedFilterWatchValue
  ) => {
    let inputData = {
      dealerID: id.toString(),
      category: updatedFilterWatchValue.category
        ? updatedFilterWatchValue.category.toString()
        : "",
      materialType: updatedFilterWatchValue.materialType
        ? updatedFilterWatchValue.materialType.toString()
        : "",
      gender: updatedFilterWatchValue.genderSelect
        ? updatedFilterWatchValue.genderSelect.toString()
        : "",
      vendor: updatedFilterWatchValue.vendor
        ? updatedFilterWatchValue.vendor.toString()
        : "",
      materialColor: updatedFilterWatchValue.materialColor
        ? updatedFilterWatchValue.materialColor.toString()
        : "",
      collection: updatedFilterWatchValue.collectionSelect
        ? updatedFilterWatchValue.collectionSelect.toString()
        : "",
      collectionID: "",
      minRange: updatedFilterWatchValue.priceMin
        ? updatedFilterWatchValue.priceMin.toString()
        : "0",
      maxRange: updatedFilterWatchValue.priceMax
        ? updatedFilterWatchValue.priceMax.toString()
        : "99999",
      noOfRows: "50",
      offset: "1",
      orderBy: "GFInventoryID",
      order: "ASC",
      styleNumber: "",
      dealerType: "",
      flgComm_VR: "",
      gemstoneType: "",
      strSelectedInvID: "",
      image: "",
    };
    setApplyFilterLoading(true);

    try {
      const response = await AppsRingBuilderService.GetRingBuilderDropdownData(
        inputData
      );
      let message = response.data.message;
      let responseData = response.data.responseData;

      if (message === "Success" && responseData) {
        if (selectedFilter !== "category")
          setCategoryList(responseData.categoryListForJewelryList || []);
        if (selectedFilter !== "materialType")
          setMaterialList(responseData.materialListForJewelryList || []);
        if (selectedFilter !== "genderSelect")
          setGenderList(responseData.genderListForJewelryList || []);
        if (selectedFilter !== "vendor")
          setVendorList(responseData.vendorListForJewelryList || []);
        if (selectedFilter !== "materialColor")
          setMaterialColorList(
            responseData.materialColorListForJewelryList || []
          );
        if (selectedFilter !== "collectionSelect")
          setCollectionList(responseData.collectionListForJewelryList || []);
      } else {
        setCategoryList([]);
        setMaterialList([]);
        setGenderList([]);
        setVendorList([]);
        setMaterialColorList([]);
        setCollectionList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setApplyFilterLoading(false);
    }
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  /*React Binding starts*/
  //Apply Filters
  const applyFilters = () => {
    setPageNo(1);
    setPageSize(10);
    handleGetJewelryList(dealerId, 1, 10, searchValue, "filter");
  };

  //Reset Filters
  const resetFilters = () => {
    let tempState = filterWatchValue;
    tempState["category"] = null;
    tempState["materialType"] = null;
    tempState["genderSelect"] = null;
    tempState["vendor"] = null;
    tempState["collectionSelect"] = null;
    tempState["vendor"] = null;
    tempState["materialColor"] = null;

    setfilterWatchValue((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (sliderValue[0]) sliderValue[0] = filterWatchValue.priceMin;
    if (sliderValue[1]) sliderValue[1] = filterWatchValue.priceMax;
    setPageNo(1);
    setPageSize(10);
    setSliderValue([filterWatchValue.priceMin, filterWatchValue.priceMax]);
    handleGetJewelryList(dealerId, 1, 10, searchValue, "NullVal");
  };

  const handleOnChange = (value, filterName) => {
    setvisible(false);
    setSelectedFilter(filterName);
    let tempState = { ...filterWatchValue, [filterName]: value };
    setfilterWatchValue(tempState);
    handleGetRingBuilderDropdownData(
      dealerId,
      gfInventoryIDList,
      filterName,
      tempState
    );
  };

  function checkEmpty(val) {
    return val.retailerIsVisible !== "";
  }

  /*React Binding ends*/

  const handleGetJewelryList = async (
    id,
    pageNo,
    pageSize,
    searchValueVal,
    Val,
    columnName,
    sortBy
  ) => {
    let category,
      vendor,
      materialType,
      gender,
      collection,
      materialColor,
      minRange,
      maxRange;

    if (filterWatchValue.category) {
      category = filterWatchValue.category;
    } else {
      category = "";
    }
    if (filterWatchValue.vendor) {
      vendor = filterWatchValue.vendor;
    } else {
      vendor = "";
    }
    if (filterWatchValue.materialType) {
      materialType = filterWatchValue.materialType;
    } else {
      materialType = "";
    }
    if (filterWatchValue.genderSelect) {
      gender = filterWatchValue.genderSelect;
    } else {
      gender = "";
    }
    if (filterWatchValue.collectionSelect) {
      collection = filterWatchValue.collectionSelect;
    } else {
      collection = "";
    }
    if (filterWatchValue.materialColor) {
      materialColor = filterWatchValue.materialColor;
    } else {
      materialColor = "";
    }
    if (sliderValue) {
      minRange = sliderValue[0];
    } else {
      minRange = `0`;
    }
    if (sliderValue) {
      maxRange = sliderValue[1];
    } else {
      maxRange = `99999#`;
    }
    let option =
      category +
      "#" +
      vendor +
      "#" +
      materialType +
      "#" +
      gender +
      "#" +
      collection +
      "#" +
      materialColor +
      "#" +
      minRange +
      "#" +
      maxRange +
      "#" +
      searchValueVal;
    let inputData = {
      dealerID: Number(id),
      offset: "1",
      orderBy: columnName ? columnName : "GFInventoryID",
      filterOption: option,
      order: sortBy ? sortBy : "ASC",
      dealerType: "All",
      pageNumber: pageNo,
      pageSize: pageSize,
    };
    setIsLoading(true);
    try {
      await AppsRingBuilderService.GetJewelryList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          setIsLoading(false);
          if (message === "Success") {
            if (responseData.getJewelryList) {
              setSettingsList(responseData.getJewelryList);
              var gflist = responseData.getJewelryList
                .map((x) => x.gfInventoryID)
                .join(",");
              setGfInventoryIDList(gflist);
              setvisible(true);
              var val = responseData.getJewelryList.every(checkEmpty);
              if (val) {
                setMainCheckVal(false);
              } else {
                setMainCheckVal(true);
              }
              if (Val !== "filter") {
                const updatedFilterWatchValue = {
                  ...filterWatchValue,
                  [Val]: val,
                };
                handleGetRingBuilderDropdownData(
                  loginDetials.loginDetials.responseData.dealerId,
                  gflist,
                  Val,
                  updatedFilterWatchValue
                );
              }
              setTotalCount(responseData.totalRecords);
            } else {
              setSettingsList([]);
              setTotalCount(0);
            }
          } else {
            setSettingsList([]);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setTotalCount(0);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setTotalCount(0);
    }
  };

  const handleManageProductFlag = async (e, row) => {
    let inputData = {
      dealerID: dealerId + "",
      productID: row.gfInventoryID + "",
      flagValue: "AC",
      value: e.target.checked ? "1" : "0",
      // value: "1",
      vendorId: row.dealerID + "",
      UpdateFlag: "",
    };
    setIsLoading(true);
    try {
      await AppsRingBuilderService.ManageProductFlag(inputData)
        .then((response) => {
          let message = response.data.message;
          setIsLoading(false);

          if (message === "Success") {
            if (e.target.checked) {
              NotificationManager.success("Product is Unblocked Successfully.");
            } else {
              NotificationManager.success("Product is Blocked Successfully.");
            }
            handleGetJewelryList(
              dealerId,
              pageNo,
              pageSize,
              searchValue,
              "filter"
            );
          } else {
            NotificationManager.error("Product Updated Issue.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleOnCheckGridAll = (e) => {
    setMainCheckVal(e.target.checked);
    handleManageProductFlagList(dealerId, e);
  };

  const firstsetPriceSlideValue = (e, num) => {
    let newArr = [...sliderValue];

    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      filterWatchValue.priceMax
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setSliderValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(e.target.value.replace(/,/g, "")));
        setSliderValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setSliderValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(e.target.value.replace(/,/g, "")));
        setSliderValue(newArr);
      }
    }
    setvisible(false);
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleManageProductFlagList = async (dealerId, e) => {
    let inputData = {
      dealerID: dealerId + "",
      productID: "",
      flagValue: "AC",
      value: e.target.checked ? "1" : "0",
      vendorId: "",
      UpdateFlag: "All",
    };

    setIsLoading(true);

    try {
      await AppsRingBuilderService.ManageProductFlag(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            if (e.target.checked) {
              NotificationManager.success("Product is Unblocked Successfully.");
            } else {
              NotificationManager.success("Product is Blocked Successfully.");
            }
            handleGetJewelryList(
              dealerId,
              pageNo,
              pageSize,
              searchValue,
              "filter"
            );
            setIsLoading(false);
          } else {
            NotificationManager.error("Unable to update data");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Sorry! We had an error");
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Sorry! We had an error");
      setIsLoading(false);
    }
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
    } else {
      type = "ASC";
      column = "dealerId";
    }

    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetJewelryList(
      dealerId,
      pagination.current,
      pagination.pageSize,
      searchValue,
      "NullVal",
      sorter.field == "settings"
        ? "GFInventoryID"
        : sorter.field == "styleNumber"
        ? "StyleNumber"
        : sorter.field == "metalType"
        ? "MetalType"
        : sorter.field == "wholesalePrice"
        ? "MSRP"
        : sorter.field == "msrp"
        ? "wholesalePrice"
        : "GFInventoryID",
      type
    );
  };

  const { Search } = Input;
  return (
    <div className="allprod_section settings__section form__fields">
      <div className="row">
        <div className="col-md-12">
          <div className="filter_section m-0 p-0">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("Settings")}
                  </h4>
                </div>
                <div className="col-md-6 text-right">
                  <div className="setting__btn">
                    <Search
                      placeholder="Retail Stock #"
                      allowClear
                      onSearch={onSearch}
                      style={{
                        width: 220,
                      }}
                    />

                    <button
                      style={{
                        height: "33px",
                      }}
                      className="primary-btn ml-2"
                      onClick={handleHideShowFilter}
                    >
                      {hideShowFilter ? (
                        <span>
                          {" "}
                          {traslate("Show Filter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {traslate("Hide Filter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* <div className="row d-none">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 col-12">
                      <Search
                        placeholder="Retail Stock #"
                        allowClear
                        onSearch={onSearch}
                        style={{
                          width: 220,
                        }}
                      />
                    </div>
                    <div className="text-right col-md-4 col-12 mobile__topspace text-left-mobile">
                      <button
                        className="primary-btn"
                        onClick={handleHideShowFilter}
                      >
                        {hideShowFilter ? (
                          <span>
                            {" "}
                            {traslate("Show Filter")}{" "}
                            <i className="fa fa-angle-down"></i>
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {traslate("Hide Filter")}{" "}
                            <i className="fa fa-angle-up"></i>
                          </span>
                        )}
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div
                className={
                  hideShowFilter
                    ? "form__fields filter_inpsection hide"
                    : "form__fields filter_inpsection"
                }
              >
                <div className="col-md-12">
                  <Spin spinning={filterLoading}>
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Category")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Category"
                            optionFilterProp="children"
                            onChange={(eValue) =>
                              handleOnChange(eValue, "category")
                            }
                            value={
                              filterWatchValue.category
                                ? filterWatchValue.category
                                : null
                            }
                          >
                            <Option value={null}> {traslate("All")} </Option>
                            {categoryList.map((item, i) => {
                              return (
                                <Option value={item.jewelryCategoryID}>
                                  {item.jewelryCategoryValues}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Material Type")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Material Type"
                            optionFilterProp="children"
                            value={
                              filterWatchValue.materialType
                                ? filterWatchValue.materialType
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "materialType")
                            }
                          >
                            <Option value={null}> {traslate("All")} </Option>
                            {materialList.map((item, i) => {
                              return (
                                <Option value={item.metalTypeID}>
                                  {item.metalTypeValues}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {traslate("Gender")}
                            <Tooltip placement="right" title={gender}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Gender"
                            optionFilterProp="children"
                            value={
                              filterWatchValue.genderSelect
                                ? filterWatchValue.genderSelect
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "genderSelect")
                            }
                          >
                            <Option value={null}> {traslate("All")} </Option>
                            {genderList.map((item, i) => {
                              return (
                                <Option value={item.genderID}>
                                  {item.gender}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Vendor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Vendor"
                            optionFilterProp="children"
                            value={
                              filterWatchValue.vendor
                                ? filterWatchValue.vendor
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "vendor")
                            }
                          >
                            <Option value={null}> {traslate("All")} </Option>
                            {vendorList.map((item, i) => {
                              return (
                                <Option value={item.designerID}>
                                  {item.designerName}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Material Color")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Material Color"
                            optionFilterProp="children"
                            value={
                              filterWatchValue.materialColor
                                ? filterWatchValue.materialColor
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "materialColor")
                            }
                          >
                            <Option value={null}> {traslate("All")} </Option>
                            {materialColorList.map((item, i) => {
                              return (
                                <Option value={item.metalColorID}>
                                  {item.metalColorValues}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {traslate("Collection")}
                            <Tooltip placement="right" title={collection}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Collection"
                            optionFilterProp="children"
                            value={
                              filterWatchValue.collectionSelect
                                ? filterWatchValue.collectionSelect
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "collectionSelect")
                            }
                          >
                            <Option value={null}> {traslate("All")} </Option>
                            {collectionList?.map((item, i) => {
                              return (
                                <Option value={item.collectionID}>
                                  {item.collectionNamesValues}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="sliderrange__maindiv">
                          <label> {traslate("Price")} </label>
                          <div className="col-md-12 mt-3">
                            <Slider
                              range
                              value={sliderValue}
                              min={filterWatchValue.priceMin}
                              max={filterWatchValue.priceMax}
                              onChange={(e) => {
                                setSliderValue(e);
                                setvisible(false);
                              }}
                            />
                            <div className="rangeSlider__values">
                              <div className="input__block">
                                <input
                                  type="text"
                                  value={
                                    currencySymbol +
                                    sliderValue[0].toLocaleString("en-US")
                                  }
                                  onChange={(e) => {
                                    firstsetPriceSlideValue(e, 0);
                                  }}
                                  onKeyPress={onKeyPressEvent1}
                                />
                              </div>
                              <div>
                                <img
                                  src={space__icon}
                                  className="img-fluid space__icon"
                                />
                              </div>
                              <div className="input__block">
                                <input
                                  type="text"
                                  value={
                                    currencySymbol +
                                    sliderValue[1].toLocaleString("en-US")
                                  }
                                  onChange={(e) => {
                                    firstsetPriceSlideValue(e, 1);
                                  }}
                                  onKeyPress={onKeyPressEvent1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 filtbtn_div">
                        <Button
                          className="primary-btn mr-2"
                          onClick={resetFilters}
                        >
                          {" "}
                          {traslate("Reset Filters")}
                        </Button>
                        <Button
                          className={visible ? "disabled-btn" : "primary-btn"}
                          onClick={applyFilters}
                          disabled={visible}
                        >
                          {traslate("Apply Filters")}
                        </Button>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="settings__tbl table__scroll">
                <Table
                  columns={[
                    {
                      title: "",
                      dataIndex: "image",
                      width: 60,
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <img
                              src={
                                row?.imagepath
                                  ? config.BlobURL +
                                    "gemfind2staging/productmedia/JewelryImages/" +
                                    row.imagepath
                                  : Rectangle
                              }
                              className="prod__img"
                              alt=""
                              width={"50px"}
                            />
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      title: traslate("Setting #"),
                      dataIndex: "settings",
                      width: 350,
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <p className="mb-0">{row.productName}</p>
                            <p className="linkText mb-0">{row.gfInventoryID}</p>
                            {row.productType == 1 && (
                              <span className="danger_text">Configurable</span>
                            )}
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      title: traslate("Vendor #"),
                      dataIndex: "styleNumber",
                      width: 100,
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (item, row) => {
                        return (
                          <React.Fragment>{row.styleNumber}</React.Fragment>
                        );
                      },
                    },
                    {
                      title: traslate("Metal"),
                      dataIndex: "metalType",
                      width: 100,
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate(`Price`),
                      dataIndex: "wholesalePrice",
                      width: 100,
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (item, row) => {
                        return row.wholesalePrice == "0" ? (
                          <p>Call For Price</p>
                        ) : (
                          <p>
                            {row.currencycode +
                              row.currencySymbol +
                              " " +
                              row.wholesalePrice}
                          </p>
                        );
                      },
                    },
                    {
                      title: traslate(`Markup`),
                      dataIndex: "msrp",
                      width: 100,
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (item, row) => {
                        return row.msrp == "0" ? (
                          <p>Call For Price</p>
                        ) : (
                          <p>
                            {row.currencycode +
                              row.currencySymbol +
                              " " +
                              row.msrp}
                          </p>
                        );
                      },
                    },
                    {
                      title: (
                        <span className="font__bold d-flex align-items-center">
                          <i className="fa fa-eye mr-1" aria-hidden="true"></i>
                          <Checkbox
                            type="checkbox"
                            id="sel"
                            onChange={(e) => handleOnCheckGridAll(e)}
                            checked={mainCheckVal}
                          />

                          <span className="ml-1 font__bold">
                            {traslate("Action")}
                          </span>
                          <Tooltip
                            placement="right"
                            className="ml-1"
                            title={traslate(
                              "Use this toggle to hide (unchecked) or unhide (checked) products."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </span>
                      ),
                      width: 120,
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <div className="responsive-margin">
                              <Checkbox
                                checked={row.retailerIsVisible ? false : true}
                                onChange={(e) => {
                                  handleManageProductFlag(e, row);
                                }}
                              />
                            </div>
                          </React.Fragment>
                        );
                      },
                    },
                  ]}
                  dataSource={settingsList}
                  loading={{ spinning: isLoading, size: "large" }}
                  onChange={handleTableASCDES}
                  pagination={{
                    current: pageNo,
                    total: totalCount,
                    pageNo,
                    pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                  }}
                  scroll={{ y: 800 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
