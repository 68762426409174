import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Spin,
} from "antd";
import traslate from "../../i18n/translate";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PendingJewelryRequest = (props) => {
  const [pendingJewelryRequest, setPendingJewelryRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  // Side Bar

  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [SalesRepId, setSalesRepId] = useState("");
  const [rowDetails, setRowDetails] = useState({});
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [SalesRep, setSalesRep] = useState([]);
  const [rowlength, setRowLength] = useState(false);

  const openInNewTab = () => {
    var url = rowDetails.websiteAddress;
    if (rowDetails.websiteAddress.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.websiteAddress.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const handleOnSelectChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setSalesRepId({
        ...SalesRepId,
        [name]: e,
      });
      if (e == null || e == "") {
        handleSaveSalesReps("");
      } else {
        handleSaveSalesReps(e);
      }
    } else {
      setSalesRepId({
        ...SalesRepId,
        [e.target.name]: e.target.value,
      });
    }
  };

  function onSearch(val) {}

  const handleGetRetailerProfile = (id, showBtn, dealerId) => {
    try {
      let inputData = {
        retailerID: id,
        showBtn: showBtn,
        dealerId: dealerId,
      };
      setSelectedColumnId(id);
      setLoadingProfileData(true);
      RetailerDataService.GetRetailerProfile(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message === "Success") {
            if (responseData) {
              setSalesRepId(
                responseData.salesUserId ? responseData.salesUserId : ""
              );
              setRowDetails(responseData);
              setLoadingProfileData(false);
            } else {
              setRowDetails({});
              setLoadingProfileData(false);
            }
          } else {
            setRowDetails({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  const GetSalesRepsList = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };
      RetailerDataService.GetSalesRepsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSalesRep(responseData);
            } else {
              setSalesRep([]);
            }
          } else {
            setSalesRep([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveSalesReps = (id) => {
    try {
      let inputData = {
        dealerID: dealerId,
        retailerID: rowDetails.dealerID,
        salesRepId: id,
      };
      RetailerDataService.SaveSalesReps(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Sales Rep Added Successfully");
            handleGetPendingRequests(dealerId);
          } else {
            NotificationManager.success("Sales Rep Not Added");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);
          if (props.type) {
          } else {
            handleGetPendingRequests(
              loginDetials.loginDetials.responseData.dealerId
            );
            GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);
          }
        }
      }
    }
  }, []);

  const columns = [
    {
      title: traslate("Store Name"),
      dataIndex: "dealerCompany",
      width: 100,
      sorter: (a, b) => a.dealerCompany.localeCompare(b.dealerCompany),
      showSorterTooltip: false,
    },
    {
      title: traslate("Retailer Type"),
      dataIndex: "retailerType",
      sorter: (a, b) => a.retailerType.localeCompare(b.retailerType),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: traslate("City"),
      dataIndex: "dealerCity",
      sorter: (a, b) => a.dealerCity.localeCompare(b.dealerCity),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
      sorter: (a, b) => a.dealerState.localeCompare(b.dealerState),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: traslate("Sales Rep"),
      dataIndex: "salesReps",
      sorter: (a, b) => a.salesReps.localeCompare(b.salesReps),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: traslate("Requested Date"),
      dataIndex: "reqdDate",
      sorter: (a, b) => a.reqdDate.localeCompare(b.reqdDate),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: traslate("Action Request"),
      width: 200,
      render: (index, row) => (
        <div className="action__btns">
          <div className="icn__div">
            {/* <i
              className="fa fa-check chck_icn"
              onClick={() => {
                handleApproveRequest(row.dealerID);
              }}
              alt=""
            ></i> */}
            <span
              className="linkText"
              onClick={() => {
                handleApproveRequest(row);
              }}
            >
              {traslate("Approve")}
            </span>
          </div>
          <div className="icn__div ml-2">
            {/* <i
              className="fa fa-close clse_icn"
              onClick={() => {
                handleDeclineRequest(row.dealerID);
              }}
              alt=""
            ></i> */}
            <span
              className="linkText"
              onClick={() => {
                handleDeclineRequest(row);
              }}
            >
              {traslate("Reject")}
            </span>
          </div>
        </div>
      ),
      width: 100,
    },
  ];

  const handleGetPendingRequests = (id) => {
    try {
      let inputData = {
        dealerID: id,
        sales: "",
      };
      setLoading(true);
      setSelectedColumnId(id);
      setLoadingProfileData(true);

      RetailerDataService.GetPendingRequests(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setPendingJewelryRequest(responseData);
              setLoading(false);
              setRowDetails({});
              handleGetRetailerProfile(
                responseData[0].dealerID,
                responseData[0].showBtn,
                id
              );
            }
            if (responseData?.length > 0) {
              setRowLength(true);
            } else {
              setRowLength(false);
            }
          } else {
            setRowLength(false);
            setPendingJewelryRequest([]);
            setLoading(false);
            setRowDetails({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setRowDetails({});
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setRowDetails({});
      setLoadingProfileData(false);
    }
  };

  const handleApproveRequest = (row) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerID: row.dealerID,
        moduleType: row.moduleType,
      };
      setLoading(true);
      RetailerDataService.PendingToApprove(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Request Approved");
            handleGetPendingRequests(dealerId);
          } else {
            NotificationManager.error("Request Not Approved");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeclineRequest = (row) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerID: row.dealerID,
        moduleType: row.moduleType,
      };
      setLoading(true);
      RetailerDataService.PendingToDisApprove(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Request Declined");
            handleGetPendingRequests(dealerId);
          } else {
            NotificationManager.error("Request Not Declined");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="form__fields">
          <div className="prod__maindiv prodmarkup__secion permission__section">
            <div className="col-md-12">
              <h4 className="workarea__heading mb-3 mt-0">
                {traslate("Pending")}{" "}
                {props.type
                  ? "Diamond Request"
                  : "Jewelry Request" +
                    " " +
                    "(" +
                    pendingJewelryRequest.length +
                    ")"}{" "}
                {/* {traslate("Pending Jewelry Request")} */}
              </h4>
            </div>
            <div className="row">
              <div
                // className="col-lg-12 col-md-12"
                className={
                  rowlength == true
                    ? "col-lg-9 col-md-9"
                    : "col-lg-12 col-md-12"
                }
              >
                <Spin spinning={loading}>
                  <Table
                    className="pendjewelreq__tbl"
                    columns={columns}
                    dataSource={pendingJewelryRequest}
                    scroll={{ x: 600, y: 800 }}
                    pagination={{
                      showSizeChanger: true,
                      responsive: true,
                    }}
                    onRow={(record, recordIndex) => ({
                      onClick: (event) => {
                        handleGetRetailerProfile(
                          record.dealerID,
                          record.showBtn,
                          dealerId
                        );
                      },
                    })}
                  />
                </Spin>
              </div>

              {Object.keys(rowDetails).length > 1 && (
                <div className="col-lg-3 col-md-3">
                  <Spin spinning={loadingprofileData}>
                    <div className="right__section">
                      <div className="profile__block">
                        <span href="#" className="retailer__name">
                          {rowDetails.dealerCompany
                            ? rowDetails.dealerCompany
                            : null}
                        </span>
                        <Link
                          to={"/retailerProfile"}
                          state={{
                            key: "Profile",
                            id: selectedColumnId,
                            page:
                              rowDetails.showBtn == true
                                ? "ShowAccess"
                                : "DonotshowAccess",
                          }}
                        >
                          <div className="logoImg__block p-0">
                            <img src={rowDetails?.companylogo || ""} />
                          </div>
                        </Link>

                        <hr />

                        <p className="activeFrom">
                          {traslate("Active")} |{" "}
                          {rowDetails.activity ? rowDetails.activity : null}
                        </p>
                      </div>

                      <div className="links__block">
                        <ul>
                          <li>
                            <Link
                              to={"/retailerProfile"}
                              state={{
                                key: "Profile",
                                id: selectedColumnId,

                                page:
                                  rowDetails.showBtn == true
                                    ? "ShowAccess"
                                    : "DonotshowAccess",
                              }}
                            >
                              <i
                                class="fa fa-address-book-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {traslate("View Profile")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/retailerProfile"}
                              state={{
                                key: "Conversation",
                                id: selectedColumnId,
                                page:
                                  rowDetails.retailerStatusID == 0
                                    ? "ShowAccess"
                                    : "DonotshowAccess",
                              }}
                            >
                              <i
                                class="fa fa-comments-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {traslate("Conversation")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/makeAppoitments"}
                              state={{
                                key: "Appointments",
                                id: selectedColumnId,

                                page:
                                  rowDetails.retailerStatusID == 0
                                    ? "ShowAccess"
                                    : "DonotshowAccess",
                              }}
                            >
                              <i
                                class="fa fa-calendar-check-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {traslate("Make Appointment")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/retailerProfile"}
                              state={{
                                key: "Notes",
                                id: selectedColumnId,
                                page:
                                  rowDetails.retailerStatusID == 0
                                    ? "ShowAccess"
                                    : "DonotshowAccess",
                              }}
                            >
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {traslate("Store Notes")}
                            </Link>
                          </li>
                        </ul>
                        <hr />
                      </div>

                      <div className="bottom__block">
                        <div className="input__block">
                          <label> {traslate("Sales Rep")} </label>
                          <Select
                            placeholder="Select Sales Rep"
                            value={SalesRepId ? SalesRepId : null}
                            name="userId"
                            showSearch
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleOnSelectChange(e, "select", "userId")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="null">Sales Rep</Option>
                            {SalesRep.map((item) => {
                              return (
                                <Option value={item.userId}>
                                  {item.name}{" "}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="retailer__dtls mb-4">
                        <p>
                          {rowDetails.dealerName ? rowDetails.dealerName : null}
                        </p>
                        <a href={`mailto:${rowDetails.dealerEmail}`}>
                          {rowDetails.dealerEmail
                            ? rowDetails.dealerEmail
                            : null}
                        </a>
                        <p>
                          {rowDetails.dealerPhone
                            ? rowDetails.dealerPhone
                            : null}
                        </p>
                        <span
                          className="linkText"
                          onClick={() => openInNewTab()}
                        >
                          {" "}
                          {rowDetails.websiteAddress}
                        </span>

                        <hr />
                      </div>
                      {rowDetails.showBtn == true && (
                        <>
                          <div className="manage-retailer-access-desc">
                            <h4>
                              {traslate(
                                "Manage Retailer Access To Your Inventory"
                              )}
                            </h4>
                            <p>
                              {traslate(
                                "Set permissions for retailers to display your virtual inventory on their website."
                              )}
                            </p>
                          </div>

                          <hr />
                        </>
                      )}
                    </div>
                  </Spin>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendingJewelryRequest;
