import CryptoJS from "crypto-js";

export default function useEncryptData() {
  const secretKey = "gemfind_react_site";

  function EncryptData(token) {
    const encrypted = CryptoJS.AES.encrypt(
      token,
      secretKey.substring(0, 16)
    ).toString();
    return encrypted;
  }
  return EncryptData;
}
