import { httpDealer } from "../helper/http-common";
class AdminDashboardDataService {
  // GetAdminDashboardData(inputData) {
  //   return httpDealer.post("/Dashboard/GetDashboardData", inputData);
  // }
  // GetRetailerDashboardData(inputData) {
  //   return httpDealer.post("/Retailer/GetDashboardData", inputData);
  // }
  // GetDashBoardFeedVendor(inputData) {
  //   return httpDealer.post(
  //     "/VendorDashboard/GetDashBoardFeedVendor",
  //     inputData
  //   );
  // }
  // GetLoadVendorDashBoard(inputData) {
  //   return httpDealer.post(
  //     "/VendorDashboard/GetLoadVendorDashBoard",
  //     inputData
  //   );
  // }
  // GetDashBoardVendorLeftPanelInfo(inputData) {
  //   return httpDealer.post(
  //     "/VendorDashboard/GetDashBoardVendorLeftPanelInfo",
  //     inputData
  //   );
  // }
  UploadBlockedProduct(inputData) {
    return httpDealer.post("/Retailer/UploadBlockedProduct", inputData);
  }
  GetDropDownFilterData(inputData) {
    return httpDealer.post("/Retailer/GetDropDownFilterData", inputData);
  }
  GetRetailerAppointmentData(inputData) {
    return httpDealer.post("/Retailer/GetRetailerAppointmentData", inputData);
  }
  AddSalesRepsFromAppointment(inputData) {
    return httpDealer.post("/Retailer/AddSalesRepsFromAppointment", inputData);
  }
  GetRightSideForAppointment(inputData) {
    return httpDealer.post("/Retailer/GetRightSideForAppointment", inputData);
  }

  GetAdminDashboardData(inputData) {
    return httpDealer.post("/Dashboard/GetDashboardData", inputData);
  }
  GetRetailerDashboardData(inputData) {
    return httpDealer.post("/Retailer/GetDashboardData", inputData);
  }
  GetDashBoardFeedVendor(inputData) {
    return httpDealer.post(
      "/VendorDashboard/GetDashBoardFeedVendor",
      inputData
    );
  }
  GetLoadVendorDashBoard(inputData) {
    return httpDealer.post(
      "/VendorDashboard/GetLoadVendorDashBoard",
      inputData
    );
  }
  GetDashBoardVendorLeftPanelInfo(inputData) {
    return httpDealer.post(
      "/VendorDashboard/GetDashBoardVendorLeftPanelInfo",
      inputData
    );
  }
  /*Conversation Service Starts*/
  GetRetailerConversationByType(inputData) {
    return httpDealer.post(
      "/Retailer/GetRetailerConversationByType",
      inputData
    );
  }
  GetConversionCount(inputData) {
    return httpDealer.post("/Retailer/GetConversionCount", inputData);
  }
  GetBindSalesReps(inputData) {
    return httpDealer.post("/Retailer/BindSalesReps", inputData);
  }
  DeleteComments(inputData) {
    return httpDealer.post("/Retailer/DeleteComments", inputData);
  }
  LoadUnreadConversationofRetailer(inputData) {
    return httpDealer.post(
      "/Retailer/LoadUnreadConversationofRetailer",
      inputData
    );
  }
  GetAppNoteData(inputData) {
    return httpDealer.post("/Retailer/GetAppNoteData", inputData);
  }
  GetSalesRepUserList(inputData) {
    return httpDealer.post("/Retailer/GetSalesRepUserList ", inputData);
  }
  /*Conversation Service Ends*/
  GetMyRetailerListforUploadBlock(inputData) {
    return httpDealer.post(
      "/Retailer/GetMyRetailerListforUploadBlock",
      inputData
    );
  }

  // Appointment API

  GetCustomersAppointments(inputData) {
    return httpDealer.post("/Dashboard/GetCustomersAppointments", inputData);
  }
  GetCustomerDropDown(inputData) {
    return httpDealer.post("/Dashboard/GetCustomerDropDown", inputData);
  }
  SaveAppointment(inputData) {
    return httpDealer.post("/Dashboard/SaveAppointment", inputData);
  }
  UpdateAppointment(inputData) {
    return httpDealer.post("/Dashboard/UpdateAppointment", inputData);
  }
  ShowCustomerRightSide(inputData) {
    return httpDealer.post("/Dashboard/ShowCustomerRightSide", inputData);
  }
  RemoveAppointment(inputData) {
    return httpDealer.post("/Dashboard/RemoveAppointment", inputData);
  }
  PostMsg(inputData) {
    return httpDealer.post("/Dashboard/PostMsg", inputData);
  }
  BindStoreHoursDropDown(inputData) {
    return httpDealer.post("/Dashboard/BindStoreHoursDropDown", inputData);
  }
  BindCustomerAppointments(inputData) {
    return httpDealer.post("/Dashboard/BindCustomerAppointments", inputData);
  }

  // Contact

  GetCustomerContactDetails(inputData) {
    return httpDealer.post("/Dashboard/GetCustomerContactDetails", inputData);
  }
  GetCustomerContactDetailsById(inputData) {
    return httpDealer.post(
      "/Dashboard/GetCustomerContactDetailsById",
      inputData
    );
  }
  InsertNoteMsg(inputData) {
    return httpDealer.post("/Dashboard/InsertNoteMsg", inputData);
  }
  InsertAddFavoriteList(inputData) {
    return httpDealer.post("/Dashboard/InsertAddFavoriteList", inputData);
  }
  GetFavoriteListDetails(inputData) {
    return httpDealer.post("/Dashboard/GetFavoriteListDetails", inputData);
  }
  UpdateUserCoversationIsReadDetails(inputData) {
    return httpDealer.post(
      "/Dashboard/UpdateUserCoversationIsReadDetails",
      inputData
    );
  }
  InsertTagsList(inputData) {
    return httpDealer.post("/Dashboard/InsertTagsList", inputData);
  }
  GetTagsListDetails(inputData) {
    return httpDealer.post("/Dashboard/GetTagsListDetails", inputData);
  }
  GetContactCoversionListDetails(inputData) {
    return httpDealer.post(
      "/Dashboard/GetContactCoversionListDetails",
      inputData
    );
  }
  GetCustomerCount(inputData) {
    return httpDealer.post("/Dashboard/GetCustomerCount", inputData);
  }
  GetCustomerExportDetails(inputData) {
    return httpDealer.post("/Dashboard/GetCustomerExportDetails", inputData);
  }
  GetNotesDetailsById(inputData) {
    return httpDealer.post("/Dashboard/GetNotesDetailsById", inputData);
  }
  UpdateNotesAppointment(inputData) {
    return httpDealer.post("/Dashboard/UpdateNotesAppointment", inputData);
  }
  GetAppoinmentDetailsById(inputData) {
    return httpDealer.post("/Dashboard/GetAppoinmentDetailsById", inputData);
  }
  RemoveCustomerConversation(inputData) {
    return httpDealer.post("/Dashboard/RemoveCustomerConversation", inputData);
  }
  RemoveVendorCustomerConversation(inputData) {
    return httpDealer.post(
      "/Dashboard/RemoveVendorCustomerConversation",
      inputData
    );
  }

  InsertAddUserProfileList(inputData) {
    return httpDealer.post("/Dashboard/InsertAddUserProfileList", inputData);
  }
  GetCurrentInterestList(inputData) {
    return httpDealer.post("/Dashboard/GetCurrentInterestList", inputData);
  }
  InsertConversionDiamondMail(inputData) {
    return httpDealer.post("/Dashboard/InsertConversionDiamondMail", inputData);
  }
  GetFavoriteMetalList(inputData) {
    return httpDealer.post("/Dashboard/GetFavoriteMetalList", inputData);
  }
  InsertMultipleAddUserProfileList(inputData) {
    return httpDealer.post(
      "/Dashboard/InsertMultipleAddUserProfileList",
      inputData
    );
  }
  GetUserProfileAdressDetails(inputData) {
    return httpDealer.post("/Dashboard/GetUserProfileAdressDetails", inputData);
  }
  UpdateUserSwitchDetails(inputData) {
    return httpDealer.post("/Dashboard/UpdateUserSwitchDetails", inputData);
  }
  AddUserImage(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Dashboard/AddUserImage", inputData, {
      headers,
    });
  }
  GetLeftSideMenuDetails(inputData) {
    return httpDealer.post("/Dashboard/GetLeftSideMenuDetails", inputData);
  }
  GetLeftSideMenuConservationDetails(inputData) {
    return httpDealer.post(
      "/Dashboard/GetLeftSideMenuConservationDetails",
      inputData
    );
  }
  GetDashBoardAdminCount(inputData) {
    return httpDealer.post("/Dashboard/GetDashBoardAdminCount", inputData);
  }

  GetDashBoardAdminSlider(inputData) {
    return httpDealer.post("/Dashboard/GetDashboardSlider", inputData);
  }


  BIndAppointmentCalender() {
    return httpDealer.get("/Dashboard/BIndAppointmentCalender");
  }
  DashBoardConservationDetailsList(inputData) {
    return httpDealer.post(
      "/Dashboard/DashBoardConservationDetailsList",
      inputData
    );
  }
  CompanyWhileLabel(){
    return httpDealer.get("/Dashboard/CompanyWhileLabel");
  }
}

export default new AdminDashboardDataService();
