
import 'bootstrap/dist/css/bootstrap.min.css';
import DataMaping from "../Catlog/DataMapping/DataMaping"
import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";


const InboundClientDataMaping = () => {

  
  return (
    <React.Fragment>
        <DataMaping/>      
    </React.Fragment>
  );
}

export default InboundClientDataMaping;