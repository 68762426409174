import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Moment from "moment";
import { Select, Button, Table, Modal, Spin } from "antd";
import logoImg from "../../assets/images/JewelCloud_Logo_Black.jpg";
import footerlogoImg from "../../assets/images/GemFind-footer.jpg";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import PurchaseOrderServices from "../../services/purchaseOrder.service";

const { Option } = Select;

const PurchaseOrderDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [purchaseOrdersHistory, SetPurchaseOrderHistory] = useState([]);
  const [purchaseOrdersProductDetails, SetPurchaseOrderProductDetails] =
    useState([]);
  const [purchaseOrdersDtRetailer, SetPurchaseOrderDtRetailer] = useState([]);
  const [purchaseOrdersDtVendor, SetPurchaseOrderDtVendor] = useState([]);
  const [purchaseOrdersProductPrice, SetPurchaseOrderProductPrice] = useState(
    {}
  );
  const [dealerId, setDealerId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [disableBtn, setDisableBtn] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [updateVendorDropdown, setupdateVendorDropdown] = useState(false);
  const [visible, setVisible] = useState(false);
  const [poOrderById, setpoOrderById] = useState(0);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [generateOrderModalVisible, setgenerateOrderModalVisible] =
    useState(false);
  const [reorderData, setReorderData] = useState({
    PoName: "",
    notes: "",
  });
  const [vendorType, setVendorType] = useState("");
  const [validation, setValidations] = useState({
    PONameVal: "",
    notesVal: "",
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      if (location.state.POOrderID) {
        setpoOrderById(location.state.POOrderID);
      }
    }
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            GetMyPurchaseOrderHistoryDetails(
              loginDetials.loginDetials.responseData.dealerId,
              ""
            );
          } else {
            GetMyPurchaseOrderHistoryDetails(
              loginDetials.loginDetials.responseData.dealerId,
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
            setVendorType(
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
          }
        }
      }
    }
  }, []);

  const GetMyPurchaseOrderHistoryDetails = (id, vendorType) => {
    let inputData = {
      dealerID: id,
      orderID: location.state.POOrderID ? location.state.POOrderID : 0,
      pOname: location.state.POOrderName ? location.state.POOrderName : "",
      type: vendorType ? vendorType : "",
    };
    setLoading(true);
    try {
      PurchaseOrderServices.purchaseorderhistotry(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrderHistory(responseData.purchaseOrder[0]);
            SetPurchaseOrderProductDetails(responseData.productDetail);
            SetPurchaseOrderDtRetailer(responseData.dtRetailer[0]);
            SetPurchaseOrderDtVendor(responseData.dtVendor[0]);
            SetPurchaseOrderProductPrice(responseData.productprice);
            setLoading(false);
          } else {
            SetPurchaseOrderHistory([]);
            SetPurchaseOrderProductDetails([]);
            SetPurchaseOrderDtRetailer([]);
            SetPurchaseOrderDtVendor([]);
            SetPurchaseOrderProductPrice({});
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateOrderStatus = () => {
    let inputData = {
      dealerID: dealerId.toString(),
      orderID: poOrderById ? poOrderById.toString() : "",
      status: "2",
    };

    try {
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setCancelModalVisible(false);
            NotificationManager.success(
              "Order Has Been Successfully Cancelled."
            );
            GetMyPurchaseOrderHistoryDetails(dealerId, vendorType);
          } else {
            setCancelModalVisible(false);
            NotificationManager.error("Order Has Not Been Cancelled.");
          }
        })
        .catch((error) => {
          console.log(error);
          setCancelModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setCancelModalVisible(false);
    }
  };

  const handleCancelModal = () => {
    setCancelModalVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setgenerateOrderModalVisible(true);
  };
  const handleCancel1 = () => {
    setgenerateOrderModalVisible(false);
  };
  function handlePrintDiamondList() {
    var disp_setting =
      "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    var docprint = window.open("", "", disp_setting);
    docprint.document.open();
    var htmlContent = document.getElementById("printsection").innerHTML;
    htmlContent +=
      '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    htmlContent +=
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';
    docprint.document.write(htmlContent);
    docprint.document.close();

    setTimeout(() => {
      docprint.print();
    }, 500);
    docprint.focus();
  }

  const handleValidation = () => {
    const { PoName, notes } = reorderData;

    const validation = {
      PONameVal: "",
      notesVal: "",
    };
    let isValid = true;

    if (!PoName.trim()) {
      isValid = false;
      validation.PONameVal = "PO Name is Compulsory";
    } else {
      validation.PONameVal = "";
    }

    if (!notes.trim()) {
      isValid = false;
      validation.notesVal = "PO Notes is Compulsory";
    } else {
      validation.notesVal = "";
    }

    setValidations(validation);
    return isValid;
  };

  const handleVendorDropdownopen = () => {
    setupdateVendorDropdown(true);
  };
  const handleVendorDropdownClose = () => {
    setupdateVendorDropdown(false);
  };

  const handleSubmitStatus = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId.toString(),
        orderID: poOrderById ? poOrderById.toString() : "",
        status: purchaseOrdersHistory.status,
      };
      setLoading(true);
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          setSubmitLoading(false);
          setupdateVendorDropdown(false);
          if (message === "Success") {
            NotificationManager.success("Status Updated Successfully.");
            GetMyPurchaseOrderHistoryDetails(dealerId, vendorType);
          } else {
            NotificationManager.error("Status not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
          setupdateVendorDropdown(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
      setupdateVendorDropdown(false);
    }
    setDisableBtn(true);
  };

  const handleCancelOrder = () => {
    setCancelModalVisible(true);
  };

  const handleCreateReOrder = () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    }

    let inputData = {
      dealerID: dealerId.toString(),
      poid: poOrderById ? poOrderById.toString() : "",
      poName: reorderData.PoName ? reorderData.PoName.toString() : "",
      poNote: reorderData.notes ? reorderData.notes.toString() : "",
    };
    setSubmitLoading(true);
    try {
      PurchaseOrderServices.CreateReOrder(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success("Order Has Been Successfully ReOrder.");
            setgenerateOrderModalVisible(false);
            setVisible(false);
            setSubmitLoading(false);
          } else {
            NotificationManager.error("Order Has Not Been Cancelled.");
            setSubmitLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setDisableBtn(false);
      SetPurchaseOrderHistory({
        ...purchaseOrdersHistory,
        [name]: e,
      });
    } else {
      setDisableBtn(false);
      SetPurchaseOrderHistory({
        ...purchaseOrdersHistory,
        [e.target.name]: e.target.value,
      });
    }

    if (isSelect === "select") {
      setReorderData({
        ...reorderData,
        [name]: e,
      });
    } else {
      setReorderData({
        ...reorderData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name == "PoName") {
        setValidations((prevdata) => ({
          ...prevdata,
          PONameVal: "",
        }));
      }
      if (e.target.name == "notes") {
        setValidations((prevdata) => ({
          ...prevdata,
          notesVal: "",
        }));
      }
    }
  };

  const RetailerColumns = [
    {
      title: traslate("Item"),
      dataIndex: "path",
      key: "path",
      render: (item, row) => {
        return (
          <img
            className="img-fluid"
            style={{ width: "50px", height: "50px" }}
            src={row.path ? row.path : ""}
          />
        );
      },
      width: 100,
    },
    {
      title: traslate("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: traslate("SKU"),
      dataIndex: "retailerStockNumber",
      key: "retailerStockNumber",
    },
    {
      title: traslate("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: traslate("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: traslate("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: traslate("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  const VendorColumns = [
    {
      title: traslate("Item"),
      dataIndex: "path",
      key: "path",
      render: (item, row) => {
        return (
          <img
            className="img-fluid"
            style={{ width: "50px", height: "50px" }}
            src={row.path ? row.path : ""}
          />
        );
      },
      width: 100,
    },
    {
      title: traslate("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: traslate("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: traslate("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: traslate("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: traslate("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  const RetailerPrintPO = [
    {
      title: traslate("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: traslate("SKU"),
      dataIndex: "retailerStockNumber",
      key: "retailerStockNumber",
    },
    {
      title: traslate("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: traslate("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: traslate("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: traslate("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  const VendorPrintPO = [
    {
      title: traslate("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: traslate("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: traslate("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: traslate("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: traslate("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  return (
    <React.Fragment>
      <div className="form__fields">
        <Spin spinning={loading}>
          {!loading && (
            <div className="row magento__mapping">
              {purchaseOrdersHistory.statusValue === "Cancelled" ||
              purchaseOrdersHistory.statusValue ===
                "Completed" ? null : loginDetials.loginDetials.responseData
                  .dealerMemberTypeName == "Retailer" || vendorType == "" ? (
                <div className="row">
                  <div className="col-md-12 heading__block mb-3">
                    <div className="col-md-8">
                      <h4 class="workarea__heading mt-0 mb-0">
                        {" "}
                        {traslate("Purchase Order Detail")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-6 col-sm-5 d-flex">
                      <div className="input__block mb-0 mr-2 col-md-6">
                        <Select
                          className="border__grey"
                          placeholder="Slected"
                          name="status"
                          value={
                            purchaseOrdersHistory.status == 2
                              ? "2"
                              : purchaseOrdersHistory.status == 0
                              ? "0"
                              : "0"
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "status")
                          }
                        >
                          <Option value="0">{traslate("Submitted")}</Option>
                          <Option value="2">{traslate("Cancelled")}</Option>
                        </Select>
                      </div>
                      <button
                        className={disableBtn ? "disabled-btn" : "primary-btn"}
                        disabled={disableBtn}
                        loading={submitLoading}
                        onClick={handleSubmitStatus}
                      >
                        {traslate("Update Detail")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 heading__block mb-3">
                    <div className="col-md-8 ">
                      <h4 class="workarea__heading mt-0 mb-0">
                        {" "}
                        {traslate("Purchase Order Detail")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-6 col-sm-5 d-flex">
                      <div className="input__block mb-0 mr-2 col-md-6">
                        <Select
                          className="border__grey"
                          placeholder="Slected"
                          name="status"
                          value={
                            purchaseOrdersHistory.status == 0
                              ? "0"
                              : purchaseOrdersHistory.status == 4
                              ? "4"
                              : purchaseOrdersHistory.status == 3
                              ? "3"
                              : purchaseOrdersHistory.status == 1
                              ? "1"
                              : purchaseOrdersHistory.status == 2
                              ? "2"
                              : purchaseOrdersHistory.status == 5
                              ? "5"
                              : ""
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "status")
                          }
                        >
                          <Option value="3">{traslate("Open")}</Option>
                          <Option value="0">{traslate("Pending")}</Option>
                          <Option value="4">{traslate("Shipped")}</Option>
                          <Option value="5">{traslate("Unpaid")}</Option>
                          <Option value="1">{traslate("Completed")}</Option>
                          <Option value="2">{traslate("Cancelled")}</Option>
                        </Select>
                      </div>
                      <button
                        className={disableBtn ? "disabled-btn" : "primary-btn"}
                        disabled={disableBtn}
                        onClick={handleVendorDropdownopen}
                      >
                       {traslate(" Update Detail")}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <h3 className="subheading mt-0">
                  {traslate("Order")}{" "}
                  {purchaseOrdersHistory.poName
                    ? purchaseOrdersHistory.poName
                    : null}
                </h3>
                <p className="mb-0">
                  {traslate("Status")}:{" "}
                  <span className="font__bold">
                    {purchaseOrdersHistory.statusValue
                      ? purchaseOrdersHistory.statusValue
                      : ""}
                  </span>
                </p>
                <p className="mb-0">
                  {traslate("Order Date")}:{" "}
                  {purchaseOrdersHistory.createdDate
                    ? Moment(purchaseOrdersHistory.createdDate).format(
                        "MMMM DD,YYYY"
                      )
                    : ""}{" "}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">{traslate("Retailer Details")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {traslate("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">{traslate("Vendor Details")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                {traslate("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-3 text-right">
                {loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                  "Retailer" || vendorType == "" ? (
                  <button
                    className="primary-btn mr-2"
                    onClick={() => setVisible(true)}
                  >
                    {traslate("Reorder PO")}
                  </button>
                ) : null}

                <button
                  className="primary-btn mr-2"
                  onClick={() => {
                    handlePrintDiamondList();
                  }}
                >
                  {traslate("Print PO")}
                </button>
                {purchaseOrdersHistory.statusValue == "Cancelled" ? null : (
                  <button className="primary-btn" onClick={handleCancelOrder}>
                    {traslate("Cancel Order")}
                  </button>
                )}
              </div>
              <div className="col-md-12">
                <p>
                  {traslate("Order Notes:")}{" "}
                  {purchaseOrdersHistory.poNote
                    ? purchaseOrdersHistory.poNote
                    : ""}
                </p>
              </div>

              <div className="divForm">
                <Table
                  columns={
                    loginDetials.loginDetials.responseData
                      .dealerMemberTypeName == "Retailer" || vendorType == ""
                      ? RetailerColumns
                      : VendorColumns
                  }
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />

                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">{traslate("Total Item")}:</span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{traslate("SubTotal")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{traslate("PO Total")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                  </div>
                </div>
                <p>{traslate("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          )}
        </Spin>

        <Modal
          title="Reorder"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Generate PO
            </Button>,
          ]}
          width={400}
        >
          <div className="form__fields p-0 border-0">
            <div className="input__block">
              <label>PO Name</label>
              <input
                type="text"
                name="PoName"
                value={reorderData.PoName}
                onChange={handleOnChange}
                className={validation.PONameVal && "border__red"}
              />
              {validation.PONameVal && (
                <p className="error-color-red">{validation.PONameVal}</p>
              )}
            </div>

            <div className="input__block">
              <label>Notes</label>
              <textarea
                name="notes"
                value={reorderData.notes}
                onChange={handleOnChange}
                className={validation.notesVal && "border__red"}
              ></textarea>
              {validation.notesVal && (
                <p className="error-color-red">{validation.notesVal}</p>
              )}
            </div>
          </div>
        </Modal>
      </div>

      {/* Generate PO Modal */}

      <Modal
        title="Purchase Order Summary"
        centered
        visible={generateOrderModalVisible}
        onOk={handleCreateReOrder}
        onCancel={handleCancel1}
        footer={[
          <Button key="back" onClick={handleCancel1}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateReOrder}
            loading={submitLoading}
          >
            Submit PO
          </Button>,
        ]}
        width={1000}
      >
        <div className="form__fields">
          <Spin spinning={loading}>
            <div className="row magento__mapping">
              <div className="col-md-4 mb-4">
                <h5 className="subheading">Shipping Address :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                {traslate("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">Manufacturer Address :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                {traslate("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>
              <div className="col-md-12">
                <p>
                  {traslate("Order Notes")}:{" "}
                  {purchaseOrdersHistory.poNote
                    ? purchaseOrdersHistory.poNote
                    : ""}
                </p>
              </div>
              <div className="divForm">
                <Table
                  columns={vendorType == "" ? RetailerPrintPO : VendorPrintPO}
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />

                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">{traslate("Total Item")}:</span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{traslate("SubTotal")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{traslate("PO Total")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                  </div>
                </div>
                <p>{traslate("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
      {/* End Modal */}

      {/* Cancel Order Modal */}
      <Modal
        className="delete_conv_modal"
        centered
        visible={cancelModalVisible}
        onCancel={handleCancelModal}
        width={400}
        footer={[
          <Button type="primary" onClick={handleUpdateOrderStatus}>
            {traslate("Cancel Order")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 className="reset-pass-conf-heading">
                      {traslate("Cancel The")} "{purchaseOrdersHistory.poName}" {traslate("Order")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Cancel Order Modal */}

      <Modal
        className="modalconsupld__section"
        centered
        visible={updateVendorDropdown}
        onCancel={handleVendorDropdownClose}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleVendorDropdownClose}>
            {traslate("No")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={submitLoading}
            onClick={handleSubmitStatus}
          >
            {traslate("Yes")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="input__block">
                  <label>Are you sure you want to change status ?</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <React.Fragment>
        {/* // code for print section //  */}
        <div id="printsection" style={{ padding: "20px", display: "none" }}>
          <div
            className="form__fields print__section"
            style={{ padding: "10px" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="top__header">
                  <div className="header__logo">
                    <img src={logoImg} />
                  </div>
                  <div className="header__text">
                    <h3 className="subheading">Invoice</h3>
                  </div>
                  <div className="header__date">
                    <p>7/22/2022</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <p className="mb-0">
                  Status:{" "}
                  <span className="font__bold">
                    {purchaseOrdersHistory.statusValue
                      ? purchaseOrdersHistory.statusValue
                      : ""}
                  </span>
                </p>
                <p className="mb-0">
                  Order Date:{" "}
                  {purchaseOrdersHistory.createdDate
                    ? purchaseOrdersHistory.createdDate
                    : ""}{" "}
                </p>
              </div>
              <div className="col-md-4 mb-4">
                <h5 className="subheading">Retailer Details :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  Email:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">{traslate("Vendor Details")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  Email:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-12">
                <p>
                {traslate("Order Notes:")}{" "}
                  {purchaseOrdersHistory.poNote
                    ? purchaseOrdersHistory.poNote
                    : ""}
                </p>
              </div>

              <div className="col-lg-12 col-md-12 mt-4">
                <div className="table__block">
                  <table class="table table-bordered table-striped">
                    <thead class="thead-light">
                      <tr>
                        <th width={"5%"}>{traslate("Item")}</th>
                        <th width={"5%"}>{traslate("Product Description")}</th>
                        {loginDetials.loginDetials.responseData
                          .dealerMemberTypeName == "Retailer" ||
                        vendorType == "" ? (
                          <th width={"3%"}>SKU</th>
                        ) : null}

                        <th width={"3%"}>{traslate("Vendor")} {traslate("SKU")}</th>
                        <th width={"3%"}>{traslate("Price")}</th>
                        <th width={"3%"}>{traslate("Qty")}</th>
                        <th width={"4%"}>{traslate("SubTotal")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrdersProductDetails.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <p className="table__content">
                                {" "}
                                <img
                                  className="img-fluid"
                                  style={{ width: "50px", height: "50px" }}
                                  src={item.path ? item.path : ""}
                                />
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {item.productDesc ? item.productDesc : ""}
                              </p>
                            </td>
                            {loginDetials.loginDetials.responseData
                              .dealerMemberTypeName == "Retailer" ||
                            vendorType == "" ? (
                              <td>
                                <p className="table__content">
                                  {item.retailerStockNumber
                                    ? item.retailerStockNumber
                                    : ""}
                                </p>
                              </td>
                            ) : null}
                            <td>
                              <p className="table__content">
                                {" "}
                                {item.retailerStyle ? item.retailerStyle : ""}
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {item.strPurchasePrice
                                  ? item.strPurchasePrice
                                  : ""}
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {item.quantity ? item.quantity : ""}
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {" "}
                                $
                                {item.strEXTWholeSalePrice
                                  ? item.strEXTWholeSalePrice
                                  : ""}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="divForm col-md-12">
                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">{traslate("Total Item")}:</span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{traslate("SubTotal")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{traslate("PO Total")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                  </div>
                </div>
                <p>{traslate("Vendor to provide shipping/insurance info.")}</p>
              </div>
              <div className="col-md-12">
                <div className="bottom__footer">
                  <img src={footerlogoImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderDetails);
