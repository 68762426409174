import { httpCatalog } from "../helper/http-common";
class CatalogJewelryDataService {
  // Catalog List
  GetCatalogCount(inputData) {
    return httpCatalog.post("/CatalogCount/GetCatalogCount", inputData);
  }

  GetDataUploadList(inputData) {
    return httpCatalog.post("/Jewelry/GetDataUploadList", inputData);
  }
  GetImageUploadList(inputData) {
    return httpCatalog.post("/Jewelry/GetImageUploadList", inputData);
  }
  GetDiamondUploadList(inputData) {
    return httpCatalog.post("/Jewelry/GetDiamondUploadList", inputData);
  }
  DownloadReportSummary(inputData) {
    return httpCatalog.post("/Jewelry/DownloadReportSummary", inputData);
  }
  DownloadReport(inputData) {
    return httpCatalog.post("/Jewelry/DownloadReport", inputData);
  }
  DownloadProductFile(inputData) {
    return httpCatalog.post("/Jewelry/DownloadProductFile", inputData);
  }
  CreateDiscardCollection(inputData) {
    return httpCatalog.post("/Jewelry/CreateDiscardCollection", inputData);
  }
  GetDataUploadListDetails(inputData) {
    return httpCatalog.post("/Jewelry/GetDataUploadListDetails", inputData);
  }
  ///Product MarkUp
  GetCurrencyList() {
    return httpCatalog.get("/ProductMarkUp/GetCurrencyList");
  }
  GetRoundingList() {
    return httpCatalog.get("/ProductMarkUp/GetRoundingList");
  }
  GetDefaultDataMarkupForDealerList(inputData) {
    return httpCatalog.post(
      "/ProductMarkUp/GetDefaultDataMarkupForDealerList",
      inputData
    );
  }
  GetMarkUpDataList(inputData) {
    return httpCatalog.post("/ProductMarkUp/GetMarkUpDataList", inputData);
  }
  GetOverRideDataList(inputData) {
    return httpCatalog.post("/ProductMarkUp/GetOverRideDataList", inputData);
  }

  UpdateOverrideValue(inputData) {
    return httpCatalog.post("/ProductMarkUp/UpdateOverrideValue", inputData);
  }

  DeleteOverrideValue(inputData) {
    return httpCatalog.post("/ProductMarkUp/DeleteOverrideValue", inputData);
  }

  SaveMarkUpDataList(inputData) {
    return httpCatalog.post("/ProductMarkUp/SaveMarkUpDataList", inputData);
  }
  GetProductCategoryList() {
    return httpCatalog.post("/Products/GetProductCategoryList");
  }

  //Vendors

  CatalogBindDataForVendorCount(inputData) {
    return httpCatalog.post("/Vendor/CatalogBindDataForVendorCount", inputData);
  }
  CatalogBindDataForVendor(inputData) {
    return httpCatalog.post("/Vendor/CatalogBindDataForVendor", inputData);
  }
  UpdatePricing(inputData) {
    return httpCatalog.post("/Vendor/UpdatePricing", inputData);
  }
  UpdateAcceptCollections(inputData) {
    return httpCatalog.post("/Vendor/UpdateAcceptCollections", inputData);
  }
  UpdateVisibility(inputData) {
    return httpCatalog.post("/Vendor/UpdateVisibility", inputData);
  }
  UpdateOverride(inputData) {
    return httpCatalog.post("/Vendor/UpdateOverride", inputData);
  }
  GetInviteNewVendorEmail(inputData) {
    return httpCatalog.post("/Vendor/GetInviteNewVendorEmail", inputData);
  }

  /// collection
  SaveCollection(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Collections/SaveCollection", inputData, {
      headers,
    });
  }

  GetCollectionsList(inputData) {
    return httpCatalog.post("/Collections/GetCollectionsList", inputData);
  }
  DeleteCollectionStatusById(inputData) {
    return httpCatalog.post(
      "/Collections/DeleteCollectionStatusById",
      inputData
    );
  }
  GetCollectionStatusById(inputData) {
    return httpCatalog.post("/Collections/GetCollectionStatusById", inputData);
  }
  GetBrandList(inputData) {
    return httpCatalog.post("/Collections/GetBrandList", inputData);
  }
  GetBrandListbyDealer(inputData) {
    return httpCatalog.post("/Collections/GetBrandListbyDealer", inputData);
  }
  UpdateVisibleStatus(inputData) {
    return httpCatalog.post("/Collections/UpdateVisibleStatus", inputData);
  }
  ChangeCollectionVendorPricing(inputData) {
    return httpCatalog.post(
      "/Collections/ChangeCollectionVendorPricing",
      inputData
    );
  }
  ChangeCollectionVendorEnable(inputData) {
    return httpCatalog.post(
      "/Collections/ChangeCollectionVendorEnable",
      inputData
    );
  }

  GetCollectionsCount(inputData) {
    return httpCatalog.post("/Collections/GetCollectionsCount", inputData);
  }

  //Add Products Page
  GetAdditionalToGemStoneTabData(inputData) {
    return httpCatalog.post(
      "/Products/GetAdditionalToGemStoneTabData",
      inputData
    );
  }
  GetEssentialTabData(inputData) {
    return httpCatalog.post("/Products/GetEssentialTabData", inputData);
  }
  GetWatchToAttributeListTabData() {
    return httpCatalog.post("/Products/GetWatchToAttributeListTabData");
  }

  ManageEditProduct(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Products/ManageEditProduct", inputData, {
      headers,
    });
  }
  GetProductDetailsByID(inputData) {
    return httpCatalog.post("/Products/GetProductDetailsByID", inputData);
  }
  //Upload Product File
  UploadJewelryImageFiles(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Jewelry/UploadJewelryImageFiles", inputData, {
      headers,
    });
  }
  UploadJewelryDataFile(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Jewelry/UploadJewelryDataFile", inputData, {
      headers,
    });
  }

  //Data Mapping

  GetDealerMappedAttributesList(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDealerMappedAttributesList",
      inputData
    );
  }
  GetDealerNotMappedAttributesList(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDealerNotMappedAttributesList",
      inputData
    );
  }
  GetDealerColumnListByColumnId(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDealerColumnListByColumnId",
      inputData
    );
  }
  GetMappedUnMappedColumnCount(inputData) {
    return httpCatalog.post("/Mapping/GetMappedUnMappedColumnCount", inputData);
  }
  SaveNewDealerColumn(inputData) {
    return httpCatalog.post("/Mapping/SaveNewDealerColumn", inputData);
  }
  RemoveColumnMapping(inputData) {
    return httpCatalog.post("/Mapping/RemoveColumnMapping", inputData);
  }
  ManageJewelryPriceTypeColumn(inputData) {
    return httpCatalog.post("/Mapping/ManageJewelryPriceTypeColumn", inputData);
  }
  SaveImagePathSettings(inputData) {
    return httpCatalog.post("/Mapping/SaveImagePathSettings", inputData);
  }
  GetAttributeListByColumnId(inputData) {
    return httpCatalog.post("/Mapping/GetAttributeListByColumnId", inputData);
  }
  GetJewelryImagePathValueList(inputData) {
    return httpCatalog.post("/Mapping/GetJewelryImagePathValueList", inputData);
  }
  MapDealerStockNumberColumn(inputData) {
    return httpCatalog.post("/Mapping/MapDealerStockNumberColumn", inputData);
  }
  MapDealerColumn(inputData) {
    return httpCatalog.post("/Mapping/MapDealerColumn", inputData);
  }
  UpdateDealerColumn(inputData) {
    return httpCatalog.post("/Mapping/UpdateDealerColumn", inputData);
  }
  GetAttributeColumnData(inputData) {
    return httpCatalog.post("/Mapping/GetAttributeColumnData", inputData);
  }
  SaveDefaultValue(inputData) {
    return httpCatalog.post("/Mapping/SaveDefaultValue", inputData);
  }
  // UploadSheetDemo(inputData) {
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //   };
  //   return httpCatalog.post("/Mapping/UploadSheetDemo", inputData, { headers });
  // }
  UploadSheet(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Mapping/UploadSheet", inputData, { headers });
  }
  DownloadAttributesMapFile(inputData) {
    return httpCatalog.post("/Mapping/DownloadAttributesMapFile", inputData, {
      responseType: "arraybuffer",
    });
  }
  DownloadAllAttributesMapFile(inputData) {
    return httpCatalog.post(
      "/Mapping/DownloadAllAttributesMapFile",
      inputData,
      {
        responseType: "arraybuffer",
      }
    );
  }
  //View My Product

  GetStyleNumber(inputData) {
    return httpCatalog.post("/Products/GetStyleNumber", inputData);
  }

  GetJewelryDataList(inputData) {
    return httpCatalog.post(
      "/Products/GetJewelryProductsDynamicDropdown",
      inputData
    );
  }

  GetDealerInfo(inputData) {
    return httpCatalog.post("/Products/GetDealerInfo", inputData);
  }

  GetInfo(inputData) {
    return httpCatalog.post("/Products/GetInfo", inputData);
  }

  ViewProduct(inputData, type) {
    if (type == "MergeProduct") {
      return httpCatalog.post("/Products/SharedProduct", inputData);
    } else if (type == "AllProduct") {
      return httpCatalog.post("/Products/ViewALLProduct", inputData);
    } else {
      return httpCatalog.post("/Products/ViewProduct", inputData);
    }
  }
  GetViewProductVedioUrl(inputData) {
    return httpCatalog.post("/Products/GetViewProductVedioUrl", inputData);
  }

  UpdateProductFlag(inputData) {
    return httpCatalog.post("/Jewelry/UpdateProductFlag", inputData);
  }
  UpdateJewelryStatusOrDeleteJewelry(inputData) {
    return httpCatalog.post(
      "/Products/UpdateJewelryStatusOrDeleteJewelry",
      inputData
    );
  }
  ChangeJewelryDisplayOrder(inputData) {
    return httpCatalog.post("/Products/ChangeJewelryDisplayOrder", inputData);
  }
  ChangeJewelryPricingType(inputData) {
    return httpCatalog.post("/Products/ChangeJewelryPricingType", inputData);
  }

  GetAttributeDetailListByColumnId(inputData) {
    return httpCatalog.post(
      "/Mapping/GetAttributeDetailListByColumnId",
      inputData
    );
  }
  GetItemPricingDetails(inputData) {
    return httpCatalog.post("/Mapping/GetItemPricingDetails", inputData);
  }
}

export default new CatalogJewelryDataService();
