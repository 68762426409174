import React, { useState } from "react";
import traslate from "../../../i18n/translate";

const MetalMarket = () => {
  return (
    <React.Fragment>
      <div className="metal__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0"> {traslate("Manage Current Metal Market")} </h4>
            </div>
            <div className="col-lg-12 mt-3">
            <div className="row">
            <div className="col-md-6">
              <div className="metal__maindiv">
                <div className="head__div">
                  <div>
                    <span> {traslate("Metal Type")} </span>
                  </div>
                  <div>
                    <span> {traslate("Current Metal Price")} </span>
                  </div>
                </div>
                <div className="content__div">
                  <div>
                    <span> {traslate("Silver")} </span>
                  </div>
                  <div>
                    <input type="number" placeholder="17.00" />
                  </div>
                </div>
                <div className="content__div">
                  <div>
                    <span> {traslate("Gold")} </span>
                  </div>
                  <div>
                    <input type="number" placeholder="18.00" />
                  </div>
                </div>
                <div className="content__div">
                  <div>
                    <span> {traslate("Platinum")} </span>
                  </div>
                  <div>
                    <input type="number" placeholder="20.00" />
                  </div>
                </div>
                <div className="content__div">
                  <div>
                    <span> {traslate("Palladium")} </span>
                  </div>
                  <div>
                    <input type="number" placeholder="21.00" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="metal__maindiv">
                <div className="head__div">
                  <div>
                    <span> {traslate("Enter New Metal Type")} :</span>
                  </div>
                </div>
                <div className="content__div">
                  <div>
                    <input type="text" placeholder="Iron" />
                  </div>
                  <div>
                    <input type="number" placeholder="25.00" />
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            <div className="col-lg-12 mt-5">
              <button className="primary-btn"> {traslate("Save")} </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MetalMarket;
