import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Table, Modal, Spin } from "antd";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import { NotificationManager } from "react-notifications";

export const PurchaseOrder = (props) => {
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [purchaseOrders, SetPurchaseOrder] = useState([]);
  const [dealerID, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [vendorType, setVendorType] = useState("");
  const [generateOrderModalVisible, setgenerateOrderModalVisible] =
    useState(false);
  const [cancelOrederData, setCancelOrederData] = useState({
    orderID: "",
    poName: "",
    status: 0
  });
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [reorderData, setReorderData] = useState({
    PoName: "",
    notes: ""
  });
  const [purchaseOrdersProductDetails, SetPurchaseOrderProductDetails] =
    useState([]);
  const [purchaseOrdersDtRetailer, SetPurchaseOrderDtRetailer] = useState([]);
  const [purchaseOrdersDtVendor, SetPurchaseOrderDtVendor] = useState([]);
  const [purchaseOrdersProductPrice, SetPurchaseOrderProductPrice] = useState(
    {}
  );
  const [historyLoading, setHistoryLoading] = useState(false);
  const [validation, setValidations] = useState({
    PONameVal: "",
    notesVal: ""
  });
  const [cancelOrderVisible, setCancelOrderVisible] = useState(true);
  const [isCancelOrderDisabled, setIsCancelOrderDisabled] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            GetMyPurchaseOrderDetails(
              loginDetials.loginDetials.responseData.dealerId,
              ""
            );
          } else {
            GetMyPurchaseOrderDetails(
              loginDetials.loginDetials.responseData.dealerId,
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
            setVendorType(
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
          }
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    setReorderData({
      PoName: "",
      notes: ""
    });
    setValidations({
      PONameVal: "",
      notesVal: ""
    });
  };

  const handleCancel1 = () => {
    setgenerateOrderModalVisible(false);
  };
  const handleOk = () => {
    setgenerateOrderModalVisible(true);
    GetMyPurchaseOrderHistoryDetails(dealerID);
  };

  const handleCancelOrder = () => {
    if (cancelOrederData.status === 1) {
      setIsCancelOrderDisabled(true);
    } else {
      setIsCancelOrderDisabled(false);
      if (purchaseOrders.length > 0) {
        setCancelModalVisible(true);
      } else {
        NotificationManager.error(traslate("No Records Available"));
      }
    }
  };

  const handleCancelModal = () => {
    setCancelModalVisible(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setReorderData({
        ...reorderData,
        [name]: e
      });
    } else {
      setReorderData({
        ...reorderData,
        [e.target.name]: e.target.value
      });

      if (e.target.name == "PoName") {
        setValidations((prevdata) => ({
          ...prevdata,
          PONameVal: ""
        }));
      }
      if (e.target.name == "notes") {
        setValidations((prevdata) => ({
          ...prevdata,
          notesVal: ""
        }));
      }
    }
  };

  const handleValidation = () => {
    const { PoName, notes } = reorderData;

    const validation = {
      PONameVal: "",
      notesVal: ""
    };
    let isValid = true;

    if (!PoName.trim()) {
      isValid = false;
      validation.PONameVal = "PO Name is Compulsory";
    } else {
      validation.PONameVal = "";
    }

    if (!notes.trim()) {
      isValid = false;
      validation.notesVal = "PO Notes is Compulsory";
    } else {
      validation.notesVal = "";
    }

    setValidations(validation);
    return isValid;
  };

  const GetMyPurchaseOrderDetails = (id, vendorType) => {
    let inputData = {
      delarId: id,
      type: vendorType ? vendorType : ""
    };
    setLoading(true);
    try {
      PurchaseOrderServices.GetMyPurchaseOrder(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrder(responseData);
            setCancelOrederData({
              orderID: responseData[0].poid,
              poName: responseData[0].poName,
              status: responseData[0].status
            });
            if (responseData[0].poid != "") {
              setCancelOrderVisible(false);
            }
            setLoading(false);
          } else {
            SetPurchaseOrder([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateOrderStatus = () => {
    let inputData = {
      dealerID: dealerID.toString(),
      orderID: cancelOrederData.orderID.toString(),
      status: "2"
    };

    try {
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setCancelModalVisible(false);
            NotificationManager.success(
              traslate("Order Has Been Successfully Cancelled.")
            );
            GetMyPurchaseOrderDetails(dealerID, vendorType);
          } else {
            setCancelModalVisible(false);
            NotificationManager.error(traslate("Order Has Not Been Cancelled."));
          }
        })
        .catch((error) => {
          console.log(error);
          setCancelModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setCancelModalVisible(false);
    }
  };

  const GetMyPurchaseOrderHistoryDetails = (id) => {
    let inputData = {
      dealerID: id,
      orderID: cancelOrederData.orderID,
      pOname: "",
      type: ""
    };
    setHistoryLoading(true);
    try {
      PurchaseOrderServices.purchaseorderhistotry(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrderProductDetails(responseData.productDetail);
            SetPurchaseOrderDtRetailer(responseData.dtRetailer[0]);
            SetPurchaseOrderDtVendor(responseData.dtVendor[0]);
            SetPurchaseOrderProductPrice(responseData.productprice);
            setHistoryLoading(false);
          } else {
            SetPurchaseOrderProductDetails([]);
            SetPurchaseOrderDtRetailer([]);
            SetPurchaseOrderDtVendor([]);
            SetPurchaseOrderProductPrice({});
            setHistoryLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setHistoryLoading(false);
        });
    } catch (error) {
      console.log(error);
      setHistoryLoading(false);
    }
  };

  const handleCreateReOrder = () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    }

    let inputData = {
      dealerID: dealerID.toString(),
      poid: cancelOrederData.orderID.toString(),
      poName: reorderData.PoName ? reorderData.PoName.toString() : "",
      poNote: reorderData.notes ? reorderData.notes.toString() : ""
    };
    setSubmitLoading(true);
    try {
      PurchaseOrderServices.CreateReOrder(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(traslate("Order Has Been Successfully ReOrder."));
            GetMyPurchaseOrderDetails(dealerID, vendorType);
            setgenerateOrderModalVisible(false);
            setVisible(false);
            setSubmitLoading(true);
          } else {
            NotificationManager.error(traslate("Order Has Not Been Cancelled."));
            setSubmitLoading(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(true);
        });
    } catch (error) {
      console.log(error);
      setSubmitLoading(true);
    }
  };

  const RetailerColumns = [
    {
      title: traslate("Purchase Order"),
      dataIndex: "poName",
      key: "poName",
      render: (item, row) => {
        return (
          <Link
            to={"/purchaseOrderDetails"}
            state={{ POOrderID: row.poid, POOrderName: row.poName }}
          >
            {" "}
            <span className="linkText">{row.poName}</span>{" "}
          </Link>
        );
      }
    },
    {
      title: traslate("Total items"),
      dataIndex: "variation",
      key: "variation"
    },
    {
      title: traslate("Total Cost"),
      dataIndex: "poTotal",
      render: (item, row) => {
        return <span>${row.poTotal}</span>;
      }
    },
    {
      title: traslate("Updated"),
      dataIndex: "lastModifiedDate",
      render: (item, row) => {
        return (
          <span> {moment(item.lastModifiedDate).format("MM/DD/yyyy")}</span>
        );
      }
    },
    {
      title: traslate("Status"),
      dataIndex: "status",
      render: (item, row) => {
        return item == 2
          ? "Cancelled"
          : item == 1
          ? "Completed"
          : item == 0
          ? "Submited"
          : item == 3
          ? "Open"
          : item == 4
          ? "Shipped"
          : item == 5
          ? "Unpaid"
          : "";
      }
    }
  ];

  const VendorColumns = [
    {
      title: traslate("Purchase Order"),
      dataIndex: "poName",
      key: "poName",
      render: (item, row) => {
        return (
          <Link
            to={"/purchaseOrderDetails"}
            state={{ POOrderID: row.poid, POOrderName: row.poName }}
          >
            <span className="linkText">{row.poName}</span>
          </Link>
        );
      }
    },
    {
      title: traslate("Total items"),
      dataIndex: "variation",
      key: "variation"
    },
    {
      title: traslate("Total Cost"),
      dataIndex: "poTotal",
      render: (item, row) => {
        return <span>${row.poTotal}</span>;
      }
    },
    {
      title: traslate("Updated"),
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate"
    },
    {
      title: traslate("Status"),
      dataIndex: "status",
      render: (item, row) => {
        return item == 2
          ? "Cancelled"
          : item == 1
          ? "Completed"
          : item == 0
          ? "Pending"
          : item == 3
          ? "Open"
          : item == 5
          ? "Unpaid"
          : item == 4
          ? "Shipped"
          : "";
      }
    }
  ];

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("Purchase Order")}{" "}
            </h4>
            <div className="d-flex w-40 justify-content-end mobile-justify-content-start">
              {loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                "Retailer" || vendorType == "" ? (
                <Button
                  disabled={cancelOrderVisible}
                  className={
                    cancelOrederData.orderID == ""
                      ? "disabled-btn mr-2"
                      : "primary-btn mr-2"
                  }
                  onClick={() => setVisible(true)}
                >
                  {traslate("Reorder PO")}
                </Button>
              ) : null}

              <Link
                to={"/purchaseOrderDetails"}
                state={{
                  POOrderID: cancelOrederData.orderID,
                  POOrderName: cancelOrederData.poName
                }}
              >
                <Button
                  disabled={cancelOrederData.orderID == "" ? true : false}
                  className={
                    cancelOrederData.orderID == ""
                      ? "disabled-btn mr-2"
                      : "primary-btn mr-2"
                  }
                >
                  {traslate("View Order")}
                </Button>
              </Link>
              {loginDetials.loginDetials.responseData.dealerMemberTypeName ===
                "Retailer" || vendorType === "" ? (
                <Button
                  disabled={
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? true
                      : false
                  }
                  className={
                    cancelOrederData.orderID === "" ||
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? "disabled-btn"
                      : "primary-btn"
                  }
                  onClick={handleCancelOrder}
                >
                  {traslate("Cancel Order")}
                </Button>
              ) : (
                <Button
                  disabled={
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? true
                      : false
                  }
                  className={
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? "disabled-btn"
                      : "primary-btn"
                  }
                  onClick={handleCancelOrder}
                >
                  {traslate("Cancel Order")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="divForm">
          <Spin spinning={loading}>
            <Table
              columns={
                loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                  "Retailer" || vendorType == ""
                  ? RetailerColumns
                  : VendorColumns
              }
              dataSource={purchaseOrders}
              scroll={{ x: 600, y: 800 }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  setCancelOrederData({
                    orderID: record.poid,
                    poName: record.poName,
                    status: record.status
                  });
                }
              })}
              rowClassName={(record) =>
                record.poid == cancelOrederData.orderID
                  ? "row-active-highlight"
                  : ""
              }
            />
          </Spin>
        </div>
      </div>
      <Modal
        title={traslate("Reorder")}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {traslate("Generate PO")}
          </Button>
        ]}
        width={400}
      >
        <div className="form__fields p-0 border-0">
          <div className="input__block">
            <label>{traslate("PO Name")}</label>
            <input
              type="text"
              name="PoName"
              value={reorderData.PoName}
              onChange={handleOnChange}
              className={validation.PONameVal && "border__red"}
            />
            {validation.PONameVal && (
              <p className="error-color-red">{validation.PONameVal}</p>
            )}
          </div>

          <div className="input__block">
            <label>{traslate("Notes")}</label>
            <textarea
              name="notes"
              value={reorderData.notes}
              onChange={handleOnChange}
              className={validation.notesVal && "border__red"}
            ></textarea>
            {validation.notesVal && (
              <p className="error-color-red">{validation.notesVal}</p>
            )}
          </div>
        </div>
      </Modal>

      {/* Generate PO Modal */}

      <Modal
        title={traslate("Purchase Order Summary")}
        centered
        visible={generateOrderModalVisible}
        onOk={handleCreateReOrder}
        onCancel={handleCancel1}
        footer={[
          <Button key="back" onClick={handleCancel1}>
            {traslate("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateReOrder}
            loading={submitLoading}
          >
            {traslate("Submit PO")}
          </Button>
        ]}
        width={1000}
      >
        <div className="form__fields">
          <Spin spinning={historyLoading}>
            <div className="row magento__mapping">
              <div className="col-md-4 mb-4">
                <h5 className="subheading">{traslate("Shipping Address")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  {traslate("T")}:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {traslate("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">
                  {traslate("Manufacturer Address")} :
                </h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  {traslate("T")}:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {traslate("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-12">
                <p>
                  {traslate("Order Notes")}:{" "}
                  {purchaseOrdersProductPrice.poName
                    ? purchaseOrdersProductPrice.poName
                    : ""}
                </p>
              </div>

              <div className="divForm">
                <Table
                  columns={[
                    {
                      title: traslate("Item"),
                      dataIndex: "id",
                      key: "id",
                      width: 100
                    },
                    {
                      title: traslate("Product Description"),
                      dataIndex: "productDesc",
                      key: "productDesc"
                    },
                    {
                      title: traslate("SKU"),
                      dataIndex: "retailerStockNumber",
                      key: "retailerStockNumber"
                    },
                    {
                      title: traslate("Vendor SKU"),
                      dataIndex: "retailerStyle",
                      key: "retailerStyle"
                    },
                    {
                      title: traslate("Price"),
                      dataIndex: "strPurchasePrice",
                      key: "strPurchasePrice"
                    },
                    {
                      title: traslate("Qty"),
                      dataIndex: "quantity",
                      key: "quantity"
                    },
                    {
                      title: traslate("SubTotal"),
                      dataIndex: "strEXTWholeSalePrice",
                      key: "strEXTWholeSalePrice"
                    }
                  ]}
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 350 }}
                  pagination={false}
                />

                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">
                        {traslate("Total Item")}:
                      </span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">
                        {traslate("SubTotal")}:
                      </span>{" "}
                      $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">
                        {traslate("PO Total")}:
                      </span>{" "}
                      $
                      {purchaseOrdersProductPrice.poTotal
                        ? purchaseOrdersProductPrice.poTotal
                        : 0}
                    </p>
                  </div>
                </div>
                <p>{traslate("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
      {/* End Modal */}

      {/* Cancel Order Modal */}
      <Modal
        className="delete_conv_modal"
        centered
        visible={cancelModalVisible}
        onCancel={handleCancelModal}
        width={400}
        footer={[
          <Button type="primary" onClick={handleUpdateOrderStatus}>
            {traslate("Cancel Order")}
          </Button>
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 className="reset-pass-conf-heading">
                      {traslate("Cancel The")} "{cancelOrederData.poName}"{" "}
                      {traslate("Order")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Cancel Order Modal */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);
