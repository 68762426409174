import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip } from "antd";

import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import userIcon from "../../../assets/images/user-icon.png";

import close_red from "../../../assets/images/icons/close_red.svg";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const { Option } = Select;
export const LoginPreferences = (props) => {
  const initialState = {
    designerID: null,
    designerID2: null,
    fileName: null,
    fileN: [],
    Image: null,
    Error: {},
    checkValue: {},
  };
  const [designerList, setdesignerList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [state, setState] = useState(initialState);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [designerLoading, setDesignerLoading] = useState(false);

  const style = (
    <span>
      <p>
        <b> {traslate("Note")}: </b>
        {traslate("This will feature a specific vendor for the")} "{" "}
        {traslate("New to JewelCloud")} "{traslate("section on the login page")}
      </p>
    </span>
  );
  useEffect(() => {
    handleGetDesignerList();
  }, []);

  const handleGetDesignerList = () => {
    setDesignerLoading(true);
    AdminToolsDataService.GetDesignerList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setdesignerList(responseData);
          if (responseData.filter((x) => x.isAsNewDesigner === true)[0]) {
            state.designerID = responseData.filter(
              (x) => x.isAsNewDesigner === true
            )[0].designerID;
          }
          setState((prevState) => ({
            ...prevState,
            ...state,
          }));
        } else {
          setdesignerList([]);
        }
      })
      .catch((error) => {
        setIsFirstTime(false);
        setDesignerLoading(false);
        console.log(error);
      })
      .finally(() => {
        setIsFirstTime(false);
        setDesignerLoading(false);
      });
  };

  const handleAddDesigner = () => {
    let inputData = {
      designerID: state.designerID,
    };
    AdminToolsDataService.AddDesignerToLogInPage(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(
            traslate("Designer Has Been updated Successfully.")
          );
        } else {
          NotificationManager.error(traslate("Record Not Added."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetUnsetDesigner = (isSelect, ID) => {
    let inputData = {
      designerID: ID,
      isSelected: isSelect,
    };
    AdminToolsDataService.SetUnsetDesignerForLoginPage(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        NotificationManager.success(
          traslate("Selected Designer Has Been Updated Successfully.")
        );
        if (message === "Success") {
          handleGetDesignerList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUploadLogoForDesigner = () => {
    if (handleValidation()) {
      setSubmitLoading(true);
      const formData = new FormData();
      formData.append("designerID", state.designerID2);
      formData.append("logoImage", state.fileN[0]);

      AdminToolsDataService.UploadLogoForDesignerForLoginPage(formData)
        .then((response) => {
          var message = response.data.message;
          NotificationManager.success(traslate("Logo Has Been Added Successfully."));
          if (message === "Success") {
            handleGetDesignerList();
            state["fileN"] = null;
            state["fileName"] = null;
            state["Image"] = null;
            state.Error["file"] = null;
            state.designerID2 = null;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };
  const handleValidation = () => {
    var designerID2 = state.designerID2;
    var file = state.fileN;
    var isValid = true;
    if (designerID2 === null || designerID2 === 0) {
      state.Error["designerID2"] = traslate("Please select designer.");
      isValid = false;
    }
    if (file === null) {
      state.Error["file"] = traslate("Please select image.");
      isValid = false;
    } else {
      if (file.length === 0) {
        state.Error["file"] = traslate("Please select image.");
        isValid = false;
      }
    }
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
    return isValid;
  };

  const handleCancelImage = () => {
    state["fileN"] = null;
    state["fileName"] = null;
    state["Image"] = null;
    state.Error["file"] = null;

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };
  function onChange(name, e) {
    let tempState = state;
    tempState[name] = e;
    if (name === "designerID2") {
      if (e == null) {
        tempState.Error["designerID2"] = null;
        tempState["Image"] = null;
      } else {
        tempState.Error["designerID2"] = null;
        tempState["Image"] =
          designerList.filter((x) => x.designerID === e)[0].logoImage || null;
      }
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name === "designerID") {
      setTimeout(() => {
        handleAddDesigner();
      }, 500);
    }
  }
  const handleCheckboxChange = (e, ID) => {
    const temp = designerList.map((x) => {
      if (x.designerID === ID) {
        return { ...x, isSelected: e.target.checked };
      } else return x;
    });

    setdesignerList(temp);
    handleSetUnsetDesigner(e.target.checked, ID);
  };

  const fileUpload = async (e) => {
    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (e.target.files[0].name.match(/.(png)$/i)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        if (img.width <= 200 && img.height <= 80) {
          if (selectedFiles) {
            allFiles.push(selectedFiles);

            tempState["Image"] = URL.createObjectURL(selectedFiles);
            tempState["fileN"] = allFiles;
            tempState["fileName"] = allFiles[0].name;
            tempState.Error["file"] = null;
          }
        } else {
          tempState.Error["file"] = traslate(
            "Please Select Correct Image Size."
          );
          tempState["fileN"] = null;
          tempState["fileName"] = null;
        }
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
      };
    } else {
      tempState.Error["file"] = traslate("Please select .png file only.");
      tempState["fileN"] = null;
      tempState["fileName"] = null;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  return (
    <div className="form__fields loginpreference__section">
      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-3">
              {traslate("Login Preferences")}
            </h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          {state.designerID == null && isFirstTime == false && (
            <span className="mandatory-text">{traslate("Please Select Designer")}</span>
          )}
          <div className="input__block">
            <label>
              {traslate("Select Designer for New")}
              <Tooltip placement="right" title={style}>
                <img src={UnionIcon} alt="" />
              </Tooltip>
            </label>
            <Select
              loading={designerLoading}
              className="border__grey"
              showSearch
              placeholder="All Dealers"
              optionFilterProp="children"
              value={state.designerID}
              onChange={(e) => onChange("designerID", e)}
            >
              <Option value={null}>{traslate("Select Designer")}</Option>
              {designerList.map((item, i) => {
                return (
                  <Option value={item.designerID}>
                    {item.designername + " " + "(" + item.designerID + ")"}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="input__block">
            <label> {traslate("Upload Logo Image for Designers")} </label>
            <div className="col-md-12 image_block">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  {state.Image ? (
                    <div className="divimage_block">
                      <span onClick={handleCancelImage} className="close_image">
                        <img src={close_red} />
                      </span>
                      <div className="img__div">
                        <img src={state.Image} className="img-fluid" />
                      </div>
                    </div>
                  ) : (
                    <div className="divimage_block">
                      <label
                        htmlFor="file-upload"
                        class="primary-btn choose_file_upload"
                      >
                        {traslate("Choose Image")}
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={(e) => fileUpload(e)}
                      />
                      {state.Error ? (
                        <div className="text-danger">{state.Error["file"]}</div>
                      ) : null}
                      <span className="mb-4">
                        {traslate("No File Choosen")}{" "}
                        <span className="requires_txt">*</span>
                      </span>
                      <span className="">{state.fileName}</span>
                      <span className="">200 X 80 {traslate("Pixels")}</span>
                      <span className="">{traslate("Png Format")}</span>
                    </div>
                  )}

                  <div className="preview__img choose_file_divimg d-none">
                    <img src={userIcon} alt="img" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-12">
                  <label className="mt-2">
                    {" "}
                    {traslate("Select Designer")}{" "}
                  </label>
                  <Select
                    className="border__grey"
                    showSearch
                    placeholder="Select Designer"
                    optionFilterProp="children"
                    value={state.designerID2}
                    onChange={(e) => onChange("designerID2", e)}
                  >
                    <Option value={null}> {traslate("Select Designer")} </Option>
                    {designerList.map((item, i) => {
                      return (
                        <Option value={item.designerID}>
                          {item.designername +
                            " " +
                            "(" +
                            item.designerID +
                            ")"}
                        </Option>
                      );
                    })}
                  </Select>
                  {state.Error ? (
                    <div className="text-danger">
                      {state.Error["designerID2"]}
                    </div>
                  ) : null}
                  <Button
                    className="primary-btn mt-3"
                    onClick={handleUploadLogoForDesigner}
                    loading={submitLoading}
                  >
                    {traslate("Upload Image")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="input__block">
            <label> {traslate("Select Designer To Show on Login Page")} </label>
          </div>
          <div className="designer__list">
            {designerList.map((item, i) => {
              return (
                <div className="designer__block">
                  <input
                    type="checkbox"
                    id={item.designerID}
                    checked={item.isSelected}
                    value={state.checkValue[item.designerID]}
                    onChange={(e) => handleCheckboxChange(e, item.designerID)}
                  />
                  <Tooltip
                    placement="right"
                    title={<img src={item.logoImage} width="200" height="80" />}
                  >
                    <label for={item.designerID}>
                      {" "}
                      {item.designername + " (" + item.dealerID + ")"}
                    </label>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPreferences;
