import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip, Modal, Popconfirm } from "antd";
import { ProgressBar } from "react-bootstrap";
import userIcon from "../../../assets/images/user-icon.png";
import ImageFormatIcon from "../../../assets/images/imageformaticon.png";
import HelpBook from "../../../assets/images/help_docs.png";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { httpManageUpload } from "../../../helper/http-common";
import traslate from "../../../i18n/translate";
import Config from "./../../../helper/config";

const MarketingUploadVendor = (props) => {
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: "COMMA",
    IsServer: false,
    rbtnRemove: false,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [stateFile, setstateFile] = useState({
    FileNameIdDetails: 0,
  });
  const [validation, setValidations] = useState({
    foldernameVal: "",
  });
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [DealerId, setDealerId] = useState(null);

  const { Option } = Select;
  const [imageMessage1, setImageMessage1] = useState(false);
  const [imageProgress, setImageProgress] = useState();
  const [progressStart, setProgressStart] = useState(false);
  const [FileDataName, setFileDataName] = useState(null);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const propFileNameData = props.userData;

  // for Popup
  const [visible, setVisible] = useState(false);
  // end

  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setDealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setDealerId(loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  // For Popup
  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirm = () => {
    // let tempState = state;
    setVisible(false);
  };
  // end

  const handleValidation = (upName) => {
    const { FileNameIdDetails } = stateFile;
    const validation = {
      foldernameVal: "",
    };
    var isValid = true;
    if (!FileNameIdDetails) {
      isValid = false;
      validation.foldernameVal = traslate("Please Select Folder");
    } else {
      validation.foldernameVal = "";
    }
    setValidations(validation);
    return isValid;
  };
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setstateFile({
        ...stateFile,
        [name]: e,
      });
    } else {
      setstateFile({
        ...stateFile,
        [e.target.name]: e.target.value,
      });
      if (e.target.name == "FileNameIdDetails") {
        setValidations((prevdata) => ({
          ...prevdata,
          foldernameVal: "",
        }));
      }
    }
  };

  const fileUpload = async (e, name) => {
    var selectedFiles = e.target.files[0];
    setState({
      ...state,
      ImagefileName: selectedFiles.name,
    });
    setFileDataName(selectedFiles);
  };
  const option = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.round((100 * loaded) / total);
      setImageProgress(percent);
    },
  };

  const handleUploadJewelryImageFiles = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      setImageMessage1(true);
      setProgressStart(true);
      const formData = new FormData();
      formData.append("file", FileDataName);
      formData.append("parentFolderId", stateFile.FileNameIdDetails);
      formData.append("fileType", "");

      httpManageUpload
        .post(
          "/Marketing/UploadMedia",
          formData,

          option
        )
        .then((response) => {
          var message = response.data.message;
          if (message == "Error : File already exist") {
            if (FileDataName.name.match(/.(png|jpg|zip|webm)$/i)) {
              NotificationManager.error(traslate("Image Already Uploaded."));
            }
            if (FileDataName.name.match(/.(csv)$/i)) {
              NotificationManager.error(traslate("CSV File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(xls)$/i)) {
              NotificationManager.error(traslate("XLS File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(txt)$/i)) {
              NotificationManager.error(traslate("Text Already Uploaded."));
            }
            if (FileDataName.name.match(/.(json)$/i)) {
              NotificationManager.error(traslate("Json File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(pdf)$/i)) {
              NotificationManager.error(traslate("Pdf File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(xlsx)$/i)) {
              NotificationManager.error(traslate("xlsx File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(docx)$/i)) {
              NotificationManager.error(traslate("docx File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(flv)$/i)) {
              NotificationManager.error(traslate("flv File Already Uploaded."));
            }
            if (FileDataName.name.match(/.(jfif)$/i)) {
              NotificationManager.error(traslate("jfif File Already Uploaded."));
            }
          } else {
            if (message === "Success") {
              if (FileDataName.name.match(/.(png|jpg|zip|webm)$/i)) {
                NotificationManager.success(traslate("Image Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(csv)$/i)) {
                NotificationManager.success(traslate("CSV File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(xls)$/i)) {
                NotificationManager.success(traslate("XLS File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(txt)$/i)) {
                NotificationManager.success(traslate("Text Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(json)$/i)) {
                NotificationManager.success(traslate("Json File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(pdf)$/i)) {
                NotificationManager.success(traslate("Pdf File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(xlsx)$/i)) {
                NotificationManager.success(traslate("xlsx File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(jfif)$/i)) {
                NotificationManager.success(traslate("jfif File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(docx)$/i)) {
                NotificationManager.success(traslate("docx File Uploaded Successfully."));
              }
              if (FileDataName.name.match(/.(flv)$/i)) {
                NotificationManager.success(traslate("flv File Uploaded Successfully."));
              }
              state["ImagefileName"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            } else {
              NotificationManager.error(traslate("File Not Uploaded."));
              setFileDataName(null);
              state["ImagefileName"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          }
        })
        .catch((error) => {
          setSubmitLoading1(false);
          setProgressStart(false);
          setImageMessage1(false);
          console.log(error);
        })
        .finally(() => {
          setSubmitLoading1(false);
          setProgressStart(false);
          setImageMessage1(false);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping uplaod__file">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {/* {traslate("Upload Your Media File")} */}
                  </h4>
                </div>

                <div className="col-lg-12">
                  <div className="input__block">
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-4 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("Choose File")}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={(e) => fileUpload(e, "Image")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Imagefile"]}
                              </div>
                            ) : null}
                            {imageMessage1 ? (
                              <span className="">
                                {" "}
                                {traslate(
                                  "Please wait while file is Uploading.."
                                )}{" "}
                              </span>
                            ) : null}

                            {state.ImagefileName ? (
                              ""
                            ) : (
                              <span className="mb-4">
                                {traslate("No File Choosen")}{" "}
                              </span>
                            )}
                            <span className="">{state.ImagefileName}</span>
                          </div>

                          <div className="preview__img choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div className="input__block">
                            <label> {traslate("Folder")} </label>
                            <Select
                              value={
                                stateFile.FileNameIdDetails
                                  ? stateFile.FileNameIdDetails
                                  : []
                              }
                              name="FileNameIdDetails"
                              placeholder="Select Folder"
                              onChange={(e) =>
                                handleOnChange(e, "select", "FileNameIdDetails")
                              }
                              showSearch
                              optionFilterProp="children"
                              className="border__grey"
                            >
                              <Option value="">
                                {" "}
                                {traslate("Select Folder")}{" "}
                              </Option>
                              {propFileNameData &&
                                propFileNameData.map((item, i) => {
                                  return (
                                    <Option value={item.id}>
                                      {item.folderName}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {validation.foldernameVal && (
                              <p className="error-color-red">
                                {validation.foldernameVal}
                              </p>
                            )}
                          </div>
                          <p>
                            -{" "}
                            {traslate(
                              "Share marketing materials across your reatailer network."
                            )}
                          </p>
                          <div className="help-doc-sp">
                            <img src={HelpBook} width="15px" height="15px" />
                            <p>
                              {traslate("Questions? View the")}
                              <a
                                href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf`}
                                target="_blank"
                                download
                              >
                                {" "}
                                Media Import
                              </a>{" "}
                              {traslate("Help Doc")}.
                            </p>
                          </div>

                          <Button
                            className="primary-btn"
                            onClick={(e) =>
                              handleUploadJewelryImageFiles("Upload1")
                            }
                            loading={submitLoading1}
                          >
                            {traslate("Upload Media")}
                          </Button>
                        </div>

                        <div className="col-md-2">
                          <div className="format__img">
                            <img src={ImageFormatIcon} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Popup */}
                  <Popconfirm
                    title={traslate("Are you sure to update Permission?")}
                    visible={visible}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  ></Popconfirm>

                  {/* End */}
                </div>
              </div>

              <Modal
                title={traslate("Progress")}
                className="modalconsupld__section"
                centered
                visible={progressStart}
                onOk={() => setProgressStart(false)}
                onCancel={() => setProgressStart(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={() => setProgressStart(false)}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setProgressStart(false)}
                  >
                    {traslate("Ok")}
                  </Button>,
                ]}
              >
                <div>
                  <ProgressBar
                    now={imageProgress}
                    label={`${imageProgress}%`}
                  />
                  <span>
                    {" "}
                    {traslate("Uploaded")} {imageProgress}% {traslate("Total")}{" "}
                    100%
                  </span>
                  <br></br>
                  <span>
                    {" "}
                    {traslate("Uploading file")}: {state.ImagefileName}
                  </span>
                </div>
              </Modal>
              <Modal
                title={traslate("Confirmation")}
                centered
                visible={visibleConfirmModal}
                onCancel={() => setVisibleConfirmModal(false)}
                width={400}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setVisibleConfirmModal(false)}
                  >
                    {traslate("Ok")}
                  </Button>,
                ]}
              >
                <div className="text-center">
                  <h5> {traslate("This option will override the data.")} </h5>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MarketingUploadVendor;
