import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Modal,
  Spin,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const { Option } = Select;

const InviteARetailer = () => {
  const [DealerId, setDealerId] = useState();
  const [userId, setUserID] = useState();
  const [loading, setLoading] = useState(false);
  const [btnvisible, setBtnVisible] = useState(true);
  const [btnloading, setBtnLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [isEdit, setIsEdit] = useState(true);

  const loginDetials = useSelector((state) => state.loginReducer);

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv app__preview p-0 border-0">
              <div className="row">
                <div className="col-md-12">
                  {/* <h4 className="workarea__heading mt-0 mb-0">Basic Information</h4> */}
                  <h4 className="workarea__heading mt-0 mb-3">
                    Invite A Retailer
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InviteARetailer;
