import React, { useState, useEffect } from "react";
import {
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  Tooltip,
  Radio,
  Spin,
  Empty,
} from "antd";
import { Tabs, Tab, Accordion } from "react-bootstrap";
import Create_icon from "../../../assets/images/create_icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import noun_upload_import from "../../../assets/images/icons/noun_upload_import.svg";
import noun_upload_export from "../../../assets/images/icons/noun_upload_export.svg";
import MappingHelpDocs from "../../../assets/images/maping_help.png";
import close from "../../../assets/images/icons/close.svg";
import close_red from "../../../assets/images/icons/close_red.svg";
import Excel_icon from "../../../assets/images/Excel_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CatalogJewelryDataService from "../../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import menuIconBlack from "../../../assets/images/icons/noun_hamburger.svg";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import traslate from "../../../i18n/translate";
import {
  setDealerIdAdminDataMapping,
  setColumnIdAdminDataMapping,
} from "../../../actions/Contact/Contact";
import Config from "../../../helper/config";

const { Option } = Select;

export const DataMaping = (props) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitAllAttrLoading, setSubmitAllAttrLoading] = useState(false);
  const [mappedAttributesList, setMappedAttributesList] = useState([]);
  const [notMappedAttributesList, setNotMappedAttributesList] = useState([]);
  const [dataMappingList, setDataMappingList] = useState([]);
  const [rowDetails, setRowDetails] = useState({});
  const [mapCount, setMapCount] = useState(0);
  const [notMapCount, setNotMapCount] = useState(0);
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [newColumnName, setNewColumnName] = useState(null);
  const [errorNewColumnName, setErrorNewColumnName] = useState(null);
  const [addnewLoading, setAddnewLoading] = useState(false);
  const [dealerColumnList, setDealerColumnList] = useState([]);
  const [loadingDealerColumnList, setLoadingDealerColumnList] = useState(false);
  const [editSelectedDealerColumn, setEditSelectedDealerColumn] = useState(0);
  const [columnId, setColumnId] = useState(14);
  const [imageUploadSetting, setImageUploadSetting] = useState(false);
  const [importMultiple, setImportMultiple] = useState(false);
  const [delimiterImages, setDelimiterImages] = useState("");
  const [errorDelimiterImages, setErrorDelimiterImages] = useState("");
  const [attributeColumnData, setAttributeColumnData] = useState([]);
  const [selectedAttibutes, setSelectedAttributes] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [attributeLoading, setAttributeLoading] = useState(false);
  const [priceTypeModal, setPriceTypeModal] = useState(false);
  const [priceType, setPriceType] = useState(null);
  const [allowOverride, setAllowOverride] = useState(false);
  const [importMappingFile, setImportMappingFile] = useState(null);
  const [chooseFormate, setChooseFormate] = useState(null);
  const [importMappingTemplate, setImportMappingTemplate] =
    useState("jewelcloud");
  const [errorImportMappingTemplate, setErrorImportMappingTemplate] =
    useState(null);

  const [dealerId, setDealerId] = useState(0);
  const [uploadMappingModal, setUploadMappingModal] = useState(false);
  const [uploadType, setUploadType] = useState(false);
  const [errorChooseFormate, setErrorChooseFormate] = useState(null);
  const [activeTab, setActiveTab] = useState("mapped");
  const [removeattribute, setRemoveattribute] = useState({
    jewelryColumnMappingId: "",
    hasValueMapping: "",
  });

  const [removeAttributeModal, setRemoveAttributeModal] = useState(false);
  const [countSpin, setCountSpin] = useState(false);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const DealerIDDataMap = useSelector(
    (state) => state.contactReducer.dealerIdAdminDataMap
  );
  const ColumnIDDataMap = useSelector(
    (state) => state.contactReducer.columnIdAdminDataMap
  );
  const [addNewColumnSpin, setAddNewColumnSpin] = useState(false);
  const [unmappedDetails, setUnmappedDeatils] = useState({
    dealerColumnName: "",
  });
  const [mapLoading, setMapLoading] = useState(false);
  const [additionalOption, setAdditionalOption] = useState("");
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const dispatch = useDispatch();

  const WhiteLabeling = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        if (DealerIDDataMap) {
          setDealerId(DealerIDDataMap);
          setColumnId(ColumnIDDataMap);

          handleGetAttributeListByColumnId(DealerIDDataMap, ColumnIDDataMap);
          handleGetDealerMappedAttributesList(DealerIDDataMap);
          handleGetDealerNotMappedAttributesList(DealerIDDataMap);
          handleGetMappedUnMappedColumnCount(DealerIDDataMap);
        } else if (location.state) {
          if (location.state.key) {
            if (location.state.columnID) {
              var columnID = 14;
              if (location.state.columnID == "Item Identity") {
                columnID = 1;
              } else if (location.state.columnID == "Configurable Products") {
                columnID = 2;
              } else if (location.state.columnID == "Item Details") {
                columnID = 3;
              } else if (location.state.columnID == "Item Pricing") {
                columnID = 4;
              } else if (location.state.columnID == "Relational Pricing") {
                columnID = 5;
              } else if (location.state.columnID == "Cost Basis Pricing") {
                columnID = 6;
              } else if (location.state.columnID == "Item-Promo Specials") {
                columnID = 7;
              } else if (location.state.columnID == "Item Media") {
                columnID = 8;
              } else if (location.state.columnID == "Item Sizing") {
                columnID = 9;
              } else if (location.state.columnID == "Gemstone Details") {
                columnID = 10;
              } else if (
                location.state.columnID == "Semi-Mounts & Ring Builder"
              ) {
                columnID = 11;
              } else if (location.state.columnID == "Watch Details") {
                columnID = 12;
              } else if (location.state.columnID == "Additional Details") {
                columnID = 13;
              } else {
                columnID = 14;
              }
              setDealerId(location.state.key);
              dispatch(setDealerIdAdminDataMapping(location.state.key));
              dispatch(setColumnIdAdminDataMapping(columnID));
              handleGetDealerMappedAttributesList(location.state.key);
              handleGetDealerNotMappedAttributesList(location.state.key);
              handleGetMappedUnMappedColumnCount(location.state.key);
              setColumnId(columnID);
              handleGetAttributeListByColumnId(location.state.key, columnID);
            }
          } else {
            if (location.state.columnID) {
              var columnID = 14;
              if (location.state.columnID == "Item Identity") {
                columnID = 1;
              } else if (location.state.columnID == "Configurable Products") {
                columnID = 2;
              } else if (location.state.columnID == "Item Details") {
                columnID = 3;
              } else if (location.state.columnID == "Item Pricing") {
                columnID = 4;
              } else if (location.state.columnID == "Relational Pricing") {
                columnID = 5;
              } else if (location.state.columnID == "Cost Basis Pricing") {
                columnID = 6;
              } else if (location.state.columnID == "Item-Promo Specials") {
                columnID = 7;
              } else if (location.state.columnID == "Item Media") {
                columnID = 8;
              } else if (location.state.columnID == "Item Sizing") {
                columnID = 9;
              } else if (location.state.columnID == "Gemstone Details") {
                columnID = 10;
              } else if (
                location.state.columnID == "Semi-Mounts & Ring Builder"
              ) {
                columnID = 11;
              } else if (location.state.columnID == "Watch Details") {
                columnID = 12;
              } else if (location.state.columnID == "Additional Details") {
                columnID = 13;
              } else {
                columnID = 14;
              }
              setColumnId(columnID);
              setDealerId(loginDetials.responseData.dealerId);
              handleGetAttributeListByColumnId(
                loginDetials.responseData.dealerId,

                columnID
              );
              handleGetDealerMappedAttributesList(
                loginDetials.responseData.dealerId
              );
              handleGetDealerNotMappedAttributesList(
                loginDetials.responseData.dealerId
              );
              handleGetMappedUnMappedColumnCount(
                loginDetials.responseData.dealerId
              );
            }
          }
        } else {
          setDealerId(loginDetials.responseData.dealerId);
          handleGetDealerMappedAttributesList(
            loginDetials.responseData.dealerId
          );
          handleGetDealerNotMappedAttributesList(
            loginDetials.responseData.dealerId
          );
          handleGetMappedUnMappedColumnCount(
            loginDetials.responseData.dealerId
          );
          handleGetAttributeListByColumnId(
            loginDetials.responseData.dealerId,
            14
          );
        }
      }
    }
    setFirstTime(true);
  }, []);
  const [firstTime, setFirstTime] = useState(false);
  useEffect(() => {
    if (firstTime) {
      if (location.state) {
        if (location.state.columnID) {
          var columnID = 14;

          if (location.state.columnID == "Item Identity") {
            columnID = 1;
          } else if (location.state.columnID == "Configurable Products") {
            columnID = 2;
          } else if (location.state.columnID == "Item Details") {
            columnID = 3;
          } else if (location.state.columnID == "Item Pricing") {
            columnID = 4;
          } else if (location.state.columnID == "Relational Pricing") {
            columnID = 5;
          } else if (location.state.columnID == "Cost Basis Pricing") {
            columnID = 6;
          } else if (location.state.columnID == "Item-Promo Specials") {
            columnID = 7;
          } else if (location.state.columnID == "Item Media") {
            columnID = 8;
          } else if (location.state.columnID == "Item Sizing") {
            columnID = 9;
          } else if (location.state.columnID == "Gemstone Details") {
            columnID = 10;
          } else if (location.state.columnID == "Semi-Mounts & Ring Builder") {
            columnID = 11;
          } else if (location.state.columnID == "Watch Details") {
            columnID = 12;
          } else if (location.state.columnID == "Additional Details") {
            columnID = 13;
          } else {
            columnID = 14;
          }

          handleGetAttributeListByColumnId(
            // location.state.key,
            dealerId,
            columnID
          );
          setColumnId(columnID);
        }
      } else {
      }
    }
  }, [location]);

  const handleRemoveAttribute = (row, type) => {
    setRemoveAttributeModal(true);
    var jewelryColumnMappingId = "";
    if (type == "Categories1") {
      jewelryColumnMappingId = row.jewelryColumnMappingId.split(",")[0];
    } else if (type == "Categories2") {
      jewelryColumnMappingId = row.jewelryColumnMappingId.split(",")[1];
    } else {
      jewelryColumnMappingId = row.jewelryColumnMappingId;
    }
    setRemoveattribute({
      jewelryColumnMappingId: jewelryColumnMappingId,
      hasValueMapping: row.haveColumnValue,
    });
  };
  const handleCancelRemoveAtrributeModal = () => {
    setRemoveAttributeModal(false);
    setRemoveattribute({
      jewelryColumnMappingId: "",
      hasValueMapping: "",
    });
  };

  // Get API for PriceType

  const handleGetItemPricingDetails = () => {
    try {
      let inputData = {
        dealerId: dealerId || 0,
      };
      CatalogJewelryDataService.GetItemPricingDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setPriceType(responseData.priceType);
            setAllowOverride(responseData.allowOverride);
          } else {
            setPriceType("");
            setAllowOverride("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDealerMappedAttributesList = (id) => {
    try {
      let inputData = {
        dealerid: id || 0,
      };
      setMapLoading(true);
      CatalogJewelryDataService.GetDealerMappedAttributesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setMappedAttributesList(responseData);
              setMapLoading(false);
            } else {
              setMappedAttributesList([]);
              setMapLoading(false);
            }
          } else {
            setMappedAttributesList([]);
            setMapLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setMapLoading(false);
        });
    } catch (error) {
      console.log(error);
      setMapLoading(false);
    }
  };
  const handleGetDealerNotMappedAttributesList = (id) => {
    try {
      let inputData = {
        dealerid: id || 0,
      };
      setMapLoading(true);
      CatalogJewelryDataService.GetDealerNotMappedAttributesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setNotMappedAttributesList(responseData);
              setMapLoading(false);
            } else {
              setNotMappedAttributesList([]);
              setMapLoading(false);
            }
          } else {
            setNotMappedAttributesList([]);
            setMapLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setMapLoading(false);
        });
    } catch (error) {
      console.log(error);
      setMapLoading(false);
    }
  };
  const handleGetDealerColumnListByColumnId = () => {
    try {
      let inputData = {
        // dealerid: 3852,
        dealerid: dealerId,
      };
      setVisible2(true);
      setLoadingDealerColumnList(true);
      CatalogJewelryDataService.GetDealerColumnListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setLoadingDealerColumnList(false);
            if (responseData) {
              setDealerColumnList(responseData);
            } else {
              setDealerColumnList([]);
            }
          } else {
            setDealerColumnList([]);
          }
        })
        .catch((error) => {
          setLoadingDealerColumnList(false);
          console.log(error);
        });
    } catch (error) {
      setLoadingDealerColumnList(false);
      console.log(error);
    }
  };
  const handleGetDealerColumnListByColumnIdOnCancel = () => {
    try {
      let inputData = {
        // dealerid: 3852,
        dealerid: dealerId,
      };
      setLoadingDealerColumnList(true);
      CatalogJewelryDataService.GetDealerColumnListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setLoadingDealerColumnList(false);
            if (responseData) {
              setDealerColumnList(responseData);
            } else {
              setDealerColumnList([]);
            }
          } else {
            setDealerColumnList([]);
          }
        })
        .catch((error) => {
          setLoadingDealerColumnList(false);
          console.log(error);
        });
    } catch (error) {
      setLoadingDealerColumnList(false);
      console.log(error);
    }
  };

  const handleSaveNewDealerColumn = () => {
    if (!newColumnName) {
      setErrorNewColumnName(traslate("Please Enter Column Name."));
      return false;
    } else {
      setErrorNewColumnName(null);
    }
    setAddnewLoading(true);
    try {
      let inputData = {
        dealerId: dealerId,
        columnName: newColumnName,
      };
      CatalogJewelryDataService.SaveNewDealerColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.returnMessage == "RECORD SAVED SUCESSFULLY") {
              if (responseData) {
                setNewColumnName("");
                handleGetDealerColumnListByColumnId();
                NotificationManager.success(
                  traslate("Column Added Successfully.")
                );

                handleRefershData();
                setAddnewLoading(false);
              } else {
                NotificationManager.error(traslate("Column Not Added."));
                setAddnewLoading(false);
              }
            } else if (
              responseData.returnMessage == "Column name already exists"
            ) {
              setNewColumnName("");
              NotificationManager.error(
                traslate("Column name already exists.")
              );
              setAddnewLoading(false);
            }
          } else {
            NotificationManager.error(traslate("Column Not Added."));
            setAddnewLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setAddnewLoading(false);
        });
    } catch (error) {
      console.log(error);
      setAddnewLoading(false);
    }
  };
  const handleRemoveColumnMapping = (
    jewelryColumnMappingId,
    hasValueMapping
  ) => {
    try {
      let inputData = {
        jewelryColumnMappingId: jewelryColumnMappingId
          ? jewelryColumnMappingId
          : removeattribute.jewelryColumnMappingId,
        hasValueMapping: hasValueMapping
          ? hasValueMapping
          : removeattribute.hasValueMapping,
      };
      CatalogJewelryDataService.RemoveColumnMapping(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              if (jewelryColumnMappingId && hasValueMapping) {
                return false;
              }

              handleGetAttributeListByColumnId(dealerId, columnId);
              handleRefershData();
              setRemoveattribute({
                jewelryColumnMappingId: "",
                hasValueMapping: "",
              });
              NotificationManager.success(
                traslate("Attribute Unmapped Successfully.")
              );
              setRemoveAttributeModal(false);
              handleRefershData();
            } else {
              NotificationManager.error(traslate("Attribute Not Unmapped."));
            }
          } else {
            NotificationManager.error(traslate("Attribute Not Unmapped."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleManageJewelryPriceTypeColumn = () => {
    try {
      let inputData = {
        dealerId: dealerId,
        priceType: Number(priceType),
        allowOverride: allowOverride,
      };
      setSubmitLoading(true);
      CatalogJewelryDataService.ManageJewelryPriceTypeColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success(
              traslate("Price Type Saved Sucessfully.")
            );
            setPriceTypeModal(false);
          } else {
            NotificationManager.error(traslate("Price Type Not Saved."));
            setPriceTypeModal(false);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleSaveImagePathSettings = () => {
    var fieldRegx = /[,;|]/;
    if (!delimiterImages) {
      setErrorDelimiterImages(traslate("Enter one character as Delimiter."));
      return false;
    } else if (!delimiterImages.match(fieldRegx)) {
      setErrorDelimiterImages(traslate("Enter Valid Delimeter."));
      return false;
    } else if (delimiterImages.length > 1) {
      setErrorDelimiterImages(
        traslate("Enter Only One character as Delimiter.")
      );
      return false;
    } else {
      setErrorDelimiterImages(null);
    }
    try {
      let inputData = {
        dealerId: dealerId,
        multiImageTrueFalse: importMultiple,
        imageDelimiter: delimiterImages,
      };
      setSubmitLoading(true);
      CatalogJewelryDataService.SaveImagePathSettings(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message === "Success") {
            if (responseData) {
              handleCloseImageuploadSettingModal();
              NotificationManager.success(
                traslate("Images Settings saved sucessfully.")
              );
            } else {
              NotificationManager.success(traslate("Images Setting Not Save."));
            }
          } else {
            NotificationManager.success(traslate("Images Settings Not Save."));
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleGetAttributeListByColumnId = (id, cid) => {
    try {
      let inputData = {
        dealerId: id,
        columnID: cid,
      };
      setLoading(true);
      CatalogJewelryDataService.GetAttributeListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData?.length > 0) {
              setDataMappingList(responseData);
              handleGetAttributeDetailListByColumnId(responseData[0].columnId);
            } else {
              setDataMappingList([]);
              setRowDetails({});
            }
          } else {
            setDataMappingList([]);
            setRowDetails({});
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetAttributeDetailListByColumnId = (id) => {
    try {
      let inputData = {
        columnID: id,
      };
      setSelectedColumnId(id);
      CatalogJewelryDataService.GetAttributeDetailListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setRowDetails(responseData[0]);
            } else {
              setRowDetails({});
            }
          } else {
            setRowDetails({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetJewelryImagePathValueList = () => {
    try {
      let inputData = {
        dealerId: dealerId,
      };
      CatalogJewelryDataService.GetJewelryImagePathValueList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setImportMultiple(responseData[0].hasMultiImages);
              setDelimiterImages(responseData[0].multiImagesDelimiter);
            } else {
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMapDealerColumn = (gColumnId, dColumnId, dropColumnId) => {
    try {
      let inputData = {
        dealerId: dealerId,
        gemFindColumnId: gColumnId,
        dealerColumnId: dColumnId,
        jewelryColumnMappingId: dropColumnId,
      };

      setLoading(true);
      CatalogJewelryDataService.MapDealerColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              NotificationManager.success(
                traslate("Attribute Mapped Successfully.")
              );
              setLoading(false);
              // handleRefershData();
              handleGetAttributeListByColumnId(dealerId, columnId);
              handleRefershData();
            } else {
              setLoading(false);
            }
          } else {
            NotificationManager.error(traslate("Attribute Not Mapped."));
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleUpdateDealerColumn = () => {
    if (editSelectedDealerColumn) {
      var tempData = dealerColumnList.filter(
        (x) => x.columnId == editSelectedDealerColumn
      );
      try {
        let inputData = {
          columnID: tempData[0].columnId,
          columnName: tempData[0].columnName,
        };
        setAddNewColumnSpin(true);
        CatalogJewelryDataService.UpdateDealerColumn(inputData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            if (message === "Success") {
              if (responseData) {
                setAddNewColumnSpin(false);
                setDealerColumnList(responseData);
                setEditSelectedDealerColumn(null);
                NotificationManager.success(
                  traslate("Column Updated Successfully.")
                );
                // handleGetDealerColumnListByColumnId();
                setVisible2(false);
              } else {
                setAddNewColumnSpin(false);
                NotificationManager.error(traslate("Column Not Updated."));
                setVisible2(false);
              }
            } else {
              setAddNewColumnSpin(false);
              NotificationManager.error(traslate("Column Not Updated."));
              setVisible2(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setAddNewColumnSpin(false);
            setVisible2(false);
          });
      } catch (error) {
        console.log(error);
        setAddNewColumnSpin(false);
        setVisible2(false);
      }
    } else {
      setVisible2(false);
    }
    setVisible2(false);
  };
  const handleGetMappedUnMappedColumnCount = (id) => {
    try {
      let inputData = {
        dealerid: id,
      };
      setCountSpin(true);
      CatalogJewelryDataService.GetMappedUnMappedColumnCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setCountSpin(false);
          if (message === "Success") {
            if (responseData) {
              setMapCount(responseData.mappedColumnCount);
              setNotMapCount(responseData.unMappedColumnCount);
              setCountSpin(false);
              setAdditionalOption(responseData.additionalOption);
            } else {
              setMapCount(0);
              setNotMapCount(0);
              setCountSpin(false);
            }
          } else {
            setMapCount(0);
            setNotMapCount(0);
            setCountSpin(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCountSpin(false);
        });
    } catch (error) {
      console.log(error);
      setCountSpin(false);
    }
  };

  const handleRefershData = () => {
    handleGetDealerMappedAttributesList(dealerId);
    handleGetDealerNotMappedAttributesList(dealerId);
    handleGetMappedUnMappedColumnCount(dealerId);
  };
  const handleEditDealerColumn = (id) => {
    setEditSelectedDealerColumn(id);
  };
  const handleEditDealerColumnValue = (id, e) => {
    const tempDealerColumnList = dealerColumnList.map((x) => {
      if (x.columnId === id) {
        return { ...x, columnName: e.target.value };
      } else return x;
    });
    setDealerColumnList(tempDealerColumnList);
  };
  const handleCloseAddNewColumnModal = () => {
    setVisible2(false);
    setNewColumnName(null);
    setErrorNewColumnName(null);
    setErrorChooseFormate(null);
    setDealerColumnList([]);
    setSelectedColumnId(null);
    handleGetDealerColumnListByColumnIdOnCancel();
    setEditSelectedDealerColumn(0);
  };
  const [mapId, setMapId] = useState();
  const handleOpenModal = (e, row) => {
    e.stopPropagation();
    if (row.columnName === "ImagePath") {
      handleOpenImageuploadSettingModal();
      handleGetJewelryImagePathValueList();
    } else if (row.columnName === "PriceType") {
      setPriceTypeModal(true);
      handleGetItemPricingDetails(dealerId);
    } else if (row.dealerColumnName == "") {
      setUnmappedDeatils({
        dealerColumnName: row.dealerColumnName,
      });
      setVisible(true);
      handleGetAttributeColumnData(
        row.columnName,
        row.typeId,
        row.jewelryColumnMappingId.split(",")
      );
      setMapId(row.jewelryColumnMappingId.split(","));
    } else {
      setUnmappedDeatils({
        dealerColumnName: row.dealerColumnName,
      });
      setVisible(true);
      handleGetAttributeColumnData(
        row.columnName,
        row.typeId,
        row.jewelryColumnMappingId.split(",")
      );
      setMapId(row.jewelryColumnMappingId.split(","));
    }
  };
  const handleOpenImageuploadSettingModal = () => {
    setImageUploadSetting(true);
  };
  const handleCloseImageuploadSettingModal = () => {
    setImageUploadSetting(false);
    setDelimiterImages(null);
    setErrorDelimiterImages(null);
    setSubmitLoading(false);
  };
  const handleGetAttributeColumnData = (name, id, jewelryColumnMappingId) => {
    try {
      let inputData = {
        name: name,
        typeId: id,
        MappedColumnID: jewelryColumnMappingId
          ? jewelryColumnMappingId[0].toString()
          : "0",
      };
      setSelectedAttributes(name);
      setAttributeLoading(true);
      CatalogJewelryDataService.GetAttributeColumnData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setAttributeLoading(false);
          if (message === "Success") {
            if (responseData) {
              if (name !== "Categories") {
                setAttributeColumnData(responseData);
              } else {
                setAttributeColumnData(responseData);
              }
            } else {
            }
          } else {
          }
        })
        .catch((error) => {
          setAttributeLoading(false);
          console.log(error);
        });
    } catch (error) {
      setAttributeLoading(false);
      console.log(error);
    }
  };
  const handleCloseAttributModal = () => {
    setMapId();
    setVisible(false);
    setAttributeColumnData([]);
    setSearchValue("");
  };
  const handleClosePriceTypeModal = () => {
    setPriceTypeModal(false);
    setPriceType(null);
    // setAllowOverride(false);
  };
  const handleSetImportMappingFile = () => {
    setImportMappingFile(null);
    document.getElementById("file-upload").value = "";
  };
  const handleImportFileUpload = (e) => {
    if (e.target.files[0].name.match(/.(xls|txt|csv)$/i)) {
      setImportMappingFile(e.target.files[0]);
      setErrorImportMappingTemplate(null);
    } else {
      setImportMappingFile(null);
      setErrorImportMappingTemplate(
        traslate("Please Select Given File Format.")
      );
    }
  };
  const handleCloseImportMappingModal = () => {
    setVisible1(false);
    setImportMappingFile(null);
    document.getElementById("file-upload").value = "";
    setChooseFormate(null);
    setImportMappingTemplate("JewelCloud");
    setErrorImportMappingTemplate(null);
    setErrorChooseFormate(null);
    // setChooseFormate(null);
    setUploadType(false);
  };
  const handleCloseUploadMappingModal = () => {
    setUploadMappingModal(false);
  };
  const handleOpenUploadMappingModal = (type) => {
    if (type === "LoadFile") {
      var isValid = true;
      // if (!importMappingFile) {
      //   setErrorImportMappingTemplate("Please select Data File.");
      //   isValid = false;
      // } else {
      //   setErrorImportMappingTemplate("");
      // }
      // if (!chooseFormate) {
      //   isValid = false;
      //   setErrorChooseFormate("Please select Format.");
      // } else {
      //   setErrorChooseFormate("");
      // }
      if (isValid) {
        setUploadMappingModal(true);
        setUploadType(false);
      } else {
        return false;
      }
    } else {
      var isValid = true;
      if (!importMappingFile) {
        setErrorImportMappingTemplate(traslate("Please select Data File."));
        isValid = false;
      } else {
        setErrorImportMappingTemplate("");
      }
      if (!chooseFormate) {
        isValid = false;
        setErrorChooseFormate(traslate("Please select Format."));
      } else {
        setErrorChooseFormate("");
      }
      if (isValid) {
        setUploadMappingModal(true);
        setUploadType(true);
      } else {
        return false;
      }
    }
  };
  const handleSaveDefaultValue = () => {
    try {
      setSubmitLoading(true);
      let inputData = [];
      attributeColumnData.forEach((element) => {
        if (selectedAttibutes == "Categories") {
          element.headerName = "Categories";
        }
        element.dValueID = Number(element.dValueID);
        inputData.push(element);
      });
      let inputPayload = {
        saveDefaultValuelist: attributeColumnData,
        columnMappingID: mapId ? mapId[0].toString() : "0",
      };
      // CatalogJewelryDataService.SaveDefaultValue(attributeColumnData)
      CatalogJewelryDataService.SaveDefaultValue(inputPayload)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message === "Success") {
            handleCloseAttributModal();
            NotificationManager.success(
              traslate("Attribute Value Saved Successfully.")
            );
          } else {
            NotificationManager.error(traslate("Attribute Value Not Saved."));
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleAttributeValueChanges = (e, id) => {
    var tempattributeColumnData = [];
    if (selectedAttibutes === "Categories") {
      tempattributeColumnData = attributeColumnData.map((x) => {
        if (x.dValueID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
    } else {
      tempattributeColumnData = attributeColumnData.map((x) => {
        if (x.id === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
    }
    setAttributeColumnData(tempattributeColumnData);
  };
  const handleUploadSheetDemo = () => {
    try {
      const formData = new FormData();
      formData.append("ExeclFileName", uploadType ? importMappingFile : null);
      formData.append("FileFormatName", uploadType ? chooseFormate : null);
      formData.append("DealerID", dealerId);
      // formData.append("ColumnId", columnId);
      formData.append("hdnLoadFromTemplate", uploadType);
      formData.append(
        "MappingTemplateddlSelectedValue",
        additionalOption ? additionalOption : null
      );
      // formData.append(
      //   "MappingTemplateddlSelectedValue",
      //   uploadType ? null : importMappingTemplate
      // );
      setSubmitLoading(true);
      CatalogJewelryDataService.UploadSheet(formData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);

          if (uploadType == false) {
            if (message === "Success") {
              handleCloseUploadMappingModal();
              handleCloseImportMappingModal();
              NotificationManager.success(
                traslate("Data Mapping Has Been Reset Successfully.")
              );
              handleGetAttributeListByColumnId(dealerId, columnId);
              handleRefershData();
            } else {
              handleCloseUploadMappingModal();
              handleCloseImportMappingModal();
              NotificationManager.error(
                traslate("Data Mapping Has Not Been Reset Successfully.")
              );
            }
          } else {
            if (message == "Data Update Successfully") {
              handleCloseUploadMappingModal();
              handleCloseImportMappingModal();
              NotificationManager.success(
                traslate("Data Mapping Has Been Reset Successfully.")
              );
              handleGetAttributeListByColumnId(dealerId, columnId);
              handleRefershData();
            } else {
              handleCloseUploadMappingModal();
              handleCloseImportMappingModal();
              NotificationManager.error(
                traslate("Data Mapping Has Not Been Reset Successfully.")
              );
            }
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleDownloadAttributesMapFile = () => {
    try {
      let inputData = {
        dealerID: Number(dealerId),
        columnID: Number(columnId),
      };
      setSubmitLoading(true);
      CatalogJewelryDataService.DownloadAttributesMapFile(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var fileName =
            columnId == 1
              ? "ItemIdentity_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 2
              ? "ConfigurableProducts_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 3
              ? "ItemDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 4
              ? "ItemPricing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 5
              ? "RelationalPricing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 6
              ? "CostBasisPricing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 7
              ? "Item-PromoSpecials_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 8
              ? "ItemMedia_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 9
              ? "ItemSizing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 10
              ? "GemstoneDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 11
              ? "Semi-Mounts&Ring Builder_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 12
              ? "WatchDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 13
              ? "AdditionalDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 14
              ? "Essential_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : "";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  /*All attributes download starts*/
  const handleDownloadAllAttributesMapFile = () => {
    try {
      let inputData = {
        dealerID: Number(dealerId),
      };
      // setSubmitLoading(true);
      setSubmitAllAttrLoading(true);
      CatalogJewelryDataService.DownloadAllAttributesMapFile(inputData)
        .then((response) => {
          setSubmitAllAttrLoading(false);
          var fileName =
            "All_Attributes(" +
            dealerId +
            ")_" +
            new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            ".xlsx";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          // setSubmitLoading(false);
          setSubmitAllAttrLoading(false);
          console.log(error);
        });
    } catch (error) {
      // setSubmitLoading(false);
      setSubmitAllAttrLoading(false);
      console.log(error);
    }
  };
  // const handleDropList = (e, dropColumnId, columnId, type) => {
  //
  //   e.preventDefault();

  //   var data = e.dataTransfer.getData("text", e.target.id);
  //   let tempAttributesList = {};
  //   let objTemp = {};

  //   if (activeTab == "mapped") {
  //     tempAttributesList = mappedAttributesList;
  //     objTemp = tempAttributesList.filter((x) => x.columnId == data)[0];
  //   } else {
  //     tempAttributesList = notMappedAttributesList;
  //     objTemp = tempAttributesList.filter((x) => x.columnId == data)[0];
  //   }
  //   var tempdataMappingList = dataMappingList;
  //   var mapcolID = "";
  //   var mapcolName = "";

  //   if (type == "Categories1") {
  //
  //     mapcolName = tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].dealerColumnName;

  //     if (mapcolName) {
  //       var tempnamec1 = mapcolName.split(",");

  //       tempnamec1[0] = objTemp.columnName;

  //       mapcolName = tempnamec1.join(",");
  //     } else {
  //       mapcolName = objTemp.columnName;
  //     }

  //     tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].dealerColumnName = mapcolName;

  //     mapcolID =
  //       tempdataMappingList.filter(
  //         (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //       )[0].mappedColumnID + "";
  //     if (mapcolID) {
  //       var tempIdc1 = mapcolID.split(",");

  //       tempIdc1[0] = objTemp.columnId;

  //       mapcolID = tempIdc1.join(",");
  //     } else {
  //       mapcolID = objTemp.columnId;
  //     }
  //     tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].mappedColumnID = mapcolID;
  //   } else if (type == "Categories2") {
  //     mapcolName = tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].dealerColumnName;

  //     if (mapcolName) {
  //       var tempname = mapcolName.split(",");
  //       if (tempname.length == 2) {
  //         tempname[1] = objTemp.columnName;
  //       } else {
  //         tempname.push(objTemp.columnName);
  //       }
  //       mapcolName = tempname.join(",");
  //     } else {
  //       mapcolName = objTemp.columnName;
  //     }

  //     tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].dealerColumnName = mapcolName;

  //     mapcolID =
  //       tempdataMappingList.filter(
  //         (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //       )[0].mappedColumnID + "";
  //     if (mapcolID) {
  //       var tempId = mapcolID.split(",");
  //       if (tempId.length == 2) {
  //         tempId[1] = objTemp.columnId;
  //       } else {
  //         tempId.push(objTemp.columnId);
  //       }
  //       mapcolID = tempId.join(",");
  //     } else {
  //       mapcolID = objTemp.columnId;
  //     }
  //     tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].mappedColumnID = mapcolID;
  //   } else {
  //     tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].dealerColumnName = objTemp.columnName;
  //     tempdataMappingList.filter(
  //       (x) => x.mappedColumnID == dropColumnId && x.columnId == columnId
  //     )[0].mappedColumnID = data;
  //     mapcolID = data;
  //   }
  //   handleMapDealerColumn(columnId, mapcolID);
  //   setDataMappingList([...tempdataMappingList]);
  // };
  const handleDropList = (e, dropColumnId, columnId, type) => {
    e.preventDefault();

    var data = e.dataTransfer.getData("text", e.target.id);
    let tempAttributesList = {};
    let objTemp = {};

    if (activeTab == "mapped") {
      tempAttributesList = mappedAttributesList;
      objTemp = tempAttributesList.filter((x) => x.columnId == data)[0];
    } else {
      tempAttributesList = notMappedAttributesList;
      objTemp = tempAttributesList.filter((x) => x.columnId == data)[0];
    }
    var tempdataMappingList = dataMappingList;
    var mapcolID = "";
    var mapcolName = "";
    if (type == "Categories1") {
      mapcolName = tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].dealerColumnName;
      let tempremoveDate = tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0];
      if (tempremoveDate.jewelryColumnMappingId)
        if (mapcolName) {
          // handleRemoveColumnMapping(
          //   tempremoveDate.jewelryColumnMappingId.split(",")[0],
          //   tempremoveDate.haveColumnValue
          // );
          var tempnamec1 = mapcolName.split(",");

          tempnamec1[0] = objTemp.columnName;

          mapcolName = tempnamec1.join(",");
        } else {
          mapcolName = objTemp.columnName;
        }

      tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].dealerColumnName = mapcolName;

      mapcolID =
        tempdataMappingList.filter(
          (x) =>
            x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
        )[0].mappedColumnID + "";
      if (mapcolID) {
        var tempIdc1 = mapcolID.split(",");

        tempIdc1[0] = objTemp.columnId;

        mapcolID = tempIdc1.join(",");
        var drpID = dropColumnId.split(",");
        handleMapDealerColumn(
          columnId,
          objTemp.columnId + "",
          drpID[0] ? drpID[0] : 0
        );
      } else {
        mapcolID = objTemp.columnId;
        handleMapDealerColumn(
          columnId,
          objTemp.columnId + "",
          drpID[0] ? drpID[0] : 0
        );
      }
      tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].jewelryColumnMappingId = mapcolID;
    } else if (type == "Categories2") {
      let tempremoveDate = tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0];
      if (tempremoveDate.jewelryColumnMappingId) {
        if (tempremoveDate.jewelryColumnMappingId.split(",")[1]) {
          // handleRemoveColumnMapping(
          //   tempremoveDate.jewelryColumnMappingId.split(",")[1],
          //   tempremoveDate.haveColumnValue
          // );
        }
      }

      mapcolName = tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].dealerColumnName;

      if (mapcolName) {
        var tempname = mapcolName.split(",");
        if (tempname.length == 2) {
          tempname[1] = objTemp.columnName;
        } else {
          tempname.push(objTemp.columnName);
        }
        mapcolName = tempname.join(",");
      } else {
        mapcolName = objTemp.columnName;
      }

      tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].dealerColumnName = mapcolName;

      mapcolID =
        tempdataMappingList.filter(
          (x) =>
            x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
        )[0].jewelryColumnMappingId + "";
      if (mapcolID) {
        var tempId = mapcolID.split(",");
        if (tempId.length == 2) {
          tempId[1] = objTemp.columnId;
        } else {
          tempId.push(objTemp.columnId);
        }
        mapcolID = tempId.join(",");
        var drpId = dropColumnId.split(",");
        handleMapDealerColumn(
          columnId,
          objTemp.columnId + "",
          drpId[1] ? drpId[1] : 0
        );
      } else {
        mapcolID = objTemp.columnId;
        handleMapDealerColumn(
          columnId,
          objTemp.columnId + "",
          drpId[1] ? drpId[1] : 0
        );
      }
      tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].jewelryColumnMappingId = mapcolID;
    } else {
      let tempremoveDate = tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0];
      // handleRemoveColumnMapping(
      //   tempremoveDate.jewelryColumnMappingId,
      //   tempremoveDate.haveColumnValue
      // );
      tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].dealerColumnName = objTemp.columnName;
      tempdataMappingList.filter(
        (x) =>
          x.jewelryColumnMappingId == dropColumnId && x.columnId == columnId
      )[0].jewelryColumnMappingId = data;
      mapcolID = data;
      handleMapDealerColumn(columnId, data, dropColumnId);
    }

    setDataMappingList([...tempdataMappingList]);
  };

  const handleDragList = (e) => {
    e.dataTransfer.setData("text", e.target.id);
  };
  const handleAllowDrop = (e) => {
    e.preventDefault();
  };

  const templist = attributeColumnData.filter(
    (x) =>
      x.value.toLowerCase().includes(searchValue) ||
      x.value.includes(searchValue)
  );

  const finalList = [
    ...new Map(templist.map((item) => [item["parentCategory"], item])).values(),
  ];

  // On Tab Change

  const handlTabChanges = (e) => {
    setActiveTab(e);

    if (e == "mapped") {
      handleGetDealerMappedAttributesList(dealerId);
    } else if (e == "nonmapped") {
      handleGetDealerNotMappedAttributesList(dealerId);
    }
  };

  return (
    <div className="prodetail__section">
      {vendorStatusID == 3 ? (
        <>{traslate("Please Contact Gemfind to get Access.")}</>
      ) : (
        <div className="form__fields">
          <div className="prod__maindiv prodmarkup__secion essential__section ">
            <div className="row magento__mapping">
              <div className="col-md-12">
                <div className="heading__block mb-3">
                  <h4 class="workarea__heading mt-0 mb-0">
                    {columnId == 1
                      ? traslate("Item Identity")
                      : columnId == 2
                      ? traslate("Configurable Products")
                      : columnId == 3
                      ? traslate("Item Details")
                      : columnId == 4
                      ? traslate("Item Pricing")
                      : columnId == 5
                      ? traslate("Relational Pricing")
                      : columnId == 6
                      ? traslate("Cost Basis Pricing")
                      : columnId == 7
                      ? traslate("Item-Promo Specials")
                      : columnId == 8
                      ? traslate("Item Media")
                      : columnId == 9
                      ? traslate("Item Sizing")
                      : columnId == 10
                      ? traslate("Gemstone Details")
                      : columnId == 11
                      ? traslate("Semi-Mounts & Ring Builder")
                      : columnId == 12
                      ? traslate("Watch Details")
                      : columnId == 13
                      ? traslate("Additional Details")
                      : columnId == 14
                      ? traslate("Essential")
                      : ""}
                  </h4>
                  <div className="btns__div">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible1(true)}
                    >
                      <img src={noun_upload_export} alt="" />{" "}
                      {traslate("Import Mapping")}
                    </button>

                    <Button
                      type="primary"
                      className="primary-btn ml-2"
                      onClick={() => handleDownloadAttributesMapFile()}
                      loading={submitLoading}
                    >
                      <img src={noun_upload_import} alt="" />{" "}
                      {traslate("Export Mapping")}
                    </Button>

                    <button
                      className="primary-btn ml-2"
                      onClick={() => handleGetDealerColumnListByColumnId()}
                    >
                      <img src={Create_icon} alt="" />{" "}
                      {traslate("Add New Column")}
                    </button>
                    <Button
                      type="primary"
                      className="primary-btn ml-2"
                      onClick={() => handleDownloadAllAttributesMapFile()}
                      loading={submitAllAttrLoading}
                    >
                      <img src={noun_upload_import} alt="" />{" "}
                      {traslate("Export All")}
                    </Button>
                  </div>
                </div>

                {/* <div className="row">
                <div className="col-lg-3 mt-2">
                  <h4 class="workarea__heading mt-0 mb-0">
                    {columnId == 1
                      ? traslate("Item Identity")
                      : columnId == 2
                      ? traslate("Configurable Products")
                      : columnId == 3
                      ? traslate("Item Details")
                      : columnId == 4
                      ? traslate("Item Pricing")
                      : columnId == 5
                      ? traslate("Relational Pricing")
                      : columnId == 6
                      ? traslate("Cost Basis Pricing")
                      : columnId == 7
                      ? traslate("Item-Promo Specials")
                      : columnId == 8
                      ? traslate("Item Media")
                      : columnId == 9
                      ? traslate("Item Sizing")
                      : columnId == 10
                      ? traslate("Gemstone Details")
                      : columnId == 11
                      ? traslate("Semi-Mounts & Ring Builder")
                      : columnId == 12
                      ? traslate("Watch Details")
                      : columnId == 13
                      ? traslate("Additional Details")
                      : columnId == 14
                      ? traslate("Essential")
                      : ""}
                  </h4>
                </div>
                <div className="col-lg-9 mt-2">
                  <div className="btns__div">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible1(true)}
                    >
                      <img src={noun_upload_export} alt="" />{" "}
                      {traslate("Import Mapping")}
                    </button>
                   
                    <Button
                      type="primary"
                      className="primary-btn ml-2"
                      onClick={() => handleDownloadAttributesMapFile()}
                      loading={submitLoading}
                    >
                      <img src={noun_upload_import} alt="" />{" "}
                      {traslate("Export Mapping")}
                    </Button>

                    <button
                      className="primary-btn ml-2"
                      onClick={() => handleGetDealerColumnListByColumnId()}
                    >
                      <img src={Create_icon} alt="" />{" "}
                      {traslate("Add New Column")}
                    </button>
                    <Button
                      type="primary"
                      className="primary-btn ml-2"
                      onClick={() => handleDownloadAllAttributesMapFile()}
                      loading={submitAllAttrLoading}
                    >
                      <img src={noun_upload_import} alt="" />{" "}
                      {traslate("Export All")}
                    </Button>
                  </div>
                </div>
              </div> */}
              </div>

              <div className="col-lg-12 mt-4">
                <div className="row">
                  <div className="col-lg-9 mt-4">
                    <Table
                      className="datamapping__tbl"
                      columns={[
                        {
                          title: (
                            <span className="font__bold">
                              {traslate("Attribute")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={traslate(
                                  "JewelCloud defined column name."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "columnName",
                          // width: 200,
                        },
                        {
                          title: (
                            <span className="font__bold">
                              {traslate("Type")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={traslate(
                                  "This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "attributeType",
                          // width: 140,
                        },
                        {
                          title: (
                            <span className="font__bold">
                              {traslate("Requirement")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={traslate(
                                  "Data uploads will fail if these columns do not contain a value."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "fieldValidation",
                          // width: 140,
                        },
                        {
                          title: (
                            <span className="font__bold">
                              {traslate("Your Attribute")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={
                                  "This is the field that corresponds with the JewelCloud defined column."
                                }
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "dealerColumnName",
                          width: "35%",
                          render: (item, row) => {
                            return (
                              <ul className="tags__ul">
                                {row.columnName === "Categories" ? (
                                  <React.Fragment>
                                    <li
                                      className="tags__lbl"
                                      key={"Categories1"}
                                      onDragOver={handleAllowDrop}
                                      id={"Categories1"}
                                      onDrop={(e) =>
                                        handleDropList(
                                          e,
                                          row.jewelryColumnMappingId,
                                          row.columnId,
                                          "Categories1"
                                        )
                                      }
                                    >
                                      <span className="tag__name">
                                        {item ? item.split(",")[0] : ""}
                                      </span>
                                      {item ? (
                                        item.split(",")[0] ? (
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}
                                          >
                                            <img
                                              onClick={() =>
                                                // handleRemoveColumnMapping(row)
                                                handleRemoveAttribute(
                                                  row,
                                                  "Categories1"
                                                )
                                              }
                                              src={close}
                                              alt=""
                                              className="close__icn"
                                            />
                                          </span>
                                        ) : null
                                      ) : null}
                                    </li>
                                    <li
                                      className="tags__lbl"
                                      key={"Categories2"}
                                      onDragOver={handleAllowDrop}
                                      id={"Categories2"}
                                      onDrop={(e) =>
                                        handleDropList(
                                          e,
                                          row.jewelryColumnMappingId,
                                          row.columnId,
                                          "Categories2"
                                        )
                                      }
                                    >
                                      <span className="tag__name">
                                        {item ? item.split(",")[1] : ""}
                                      </span>
                                      {item ? (
                                        item.split(",")[1] ? (
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}
                                          >
                                            <img
                                              onClick={() =>
                                                // handleRemoveColumnMapping(row)
                                                handleRemoveAttribute(
                                                  row,
                                                  "Categories2"
                                                )
                                              }
                                              src={close}
                                              alt=""
                                              className="close__icn"
                                            />
                                          </span>
                                        ) : null
                                      ) : null}
                                    </li>
                                  </React.Fragment>
                                ) : (
                                  <li
                                    className="tags__lbl"
                                    key={row.jewelryColumnMappingId}
                                    onDragOver={handleAllowDrop}
                                    id={row.jewelryColumnMappingId}
                                    onDrop={(e) =>
                                      handleDropList(
                                        e,
                                        row.jewelryColumnMappingId,
                                        row.columnId,
                                        ""
                                      )
                                    }
                                  >
                                    <span className="tag__name">{item}</span>
                                    {item ? (
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                      >
                                        <img
                                          onClick={() =>
                                            // handleRemoveColumnMapping(row)
                                            handleRemoveAttribute(row)
                                          }
                                          src={close}
                                          alt=""
                                          className="close__icn"
                                        />
                                      </span>
                                    ) : null}
                                  </li>
                                )}
                              </ul>
                            );
                          },
                        },
                        {
                          title: "",
                          dataIndex: "hamb__icn",
                          width: "5%",
                          render: (item, row) => {
                            return (
                              <React.Fragment>
                                {row.haveColumnValue ? (
                                  <div className="text-right">
                                    <img
                                      src={menuIconBlack}
                                      className="hamb__icn"
                                      onClick={(e) => handleOpenModal(e, row)}
                                    />
                                  </div>
                                ) : // <i
                                //   class="fa fa-align-justify"
                                //   onClick={(e) => handleOpenModal(e, row)}
                                // ></i>
                                null}
                              </React.Fragment>
                            );
                          },
                        },
                      ]}
                      onRow={(record, recordIndex) => ({
                        onClick: (event) => {
                          handleGetAttributeDetailListByColumnId(
                            record.columnId
                          );
                        },
                      })}
                      rowClassName={(record) =>
                        record.columnId == selectedColumnId ? "row-active" : ""
                      }
                      loading={loading}
                      dataSource={dataMappingList}
                      scroll={{ y: 800 }}
                      pagination={false}
                    />
                  </div>
                  <div className="col-lg-3 mt-4">
                    <Spin spinning={mapLoading}>
                      <div className="prodesc__div">
                        <Tabs
                          defaultActiveKey="mapped"
                          id="uncontrolled-tab-example"
                          className="tab__div"
                          onSelect={(e) => handlTabChanges(e)}
                          activeKey={activeTab}
                        >
                          <Tab eventKey="mapped" title={traslate("Mapped")}>
                            <div className="tab__contentdiv">
                              <div className="col-lg-12">
                                <ul
                                  className="tags__ul"
                                  onDragOver={handleAllowDrop}
                                >
                                  {mappedAttributesList.map((item, index) => {
                                    return (
                                      <React.Fragment>
                                        <li
                                          className="tags__lbl active"
                                          draggable={true}
                                          onDragStart={handleDragList}
                                          key={index}
                                          id={item.columnId}
                                        >
                                          <span
                                            className="tag__name"
                                            title={item.columnName}
                                          >
                                            {item.columnName}
                                          </span>
                                        </li>
                                      </React.Fragment>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Tab>
                          <Tab
                            eventKey="nonmapped"
                            title={traslate("Non Mapped")}
                          >
                            <div className="tab__contentdiv">
                              <div className="col-lg-12">
                                <ul className="tags__ul">
                                  {notMappedAttributesList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <li
                                          className="tags__lbl active"
                                          draggable={true}
                                          onDragStart={handleDragList}
                                          key={item.columnId}
                                          id={item.columnId}
                                        >
                                          <span
                                            className="tag__name"
                                            title={item.columnName}
                                          >
                                            {item.columnName}
                                          </span>
                                        </li>
                                      </React.Fragment>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-3">
                <div className="desc__div">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="workarea__heading">
                        {traslate("Attribute Detail")}
                      </h3>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <h4 className="head__txt"> {traslate("Name")} </h4>
                        <p className="desc__txt">
                          {rowDetails && rowDetails?.columnName}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt">
                          {" "}
                          {traslate("Accepted Values")}{" "}
                        </h4>
                        <p className="desc__txt">
                          {(rowDetails &&
                            rowDetails?.attributeType === "Text Field") ||
                          (rowDetails &&
                            rowDetails?.attributeType === "Fixed Labels") ||
                          (rowDetails &&
                            rowDetails?.attributeType === "TextField")
                            ? "Alphanumeric"
                            : rowDetails &&
                              rowDetails?.attributeType === "Decimal Field"
                            ? "Float,Int"
                            : rowDetails &&
                              rowDetails?.attributeType === "Date Field"
                            ? "Date"
                            : (rowDetails &&
                                rowDetails?.attributeType ===
                                  "Boolean Field") ||
                              (rowDetails &&
                                rowDetails.attributeType === "Boolean")
                            ? "Yes/No"
                            : (rowDetails &&
                                rowDetails?.attributeType === "Numeric") ||
                              (rowDetails &&
                                rowDetails?.attributeType === "Numeric Field")
                            ? "Number"
                            : "-"}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt"> {traslate("Required")} </h4>
                        <p className="desc__txt">
                          {(rowDetails &&
                            rowDetails?.fieldValidation === "Required") ||
                          (rowDetails &&
                            rowDetails?.fieldValidation ===
                              "Required For Configurable Products")
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <h4 className="head__txt"> {traslate("Example")} </h4>
                        <p className="desc__txt">
                          {rowDetails && rowDetails?.example == ""
                            ? "-"
                            : rowDetails && rowDetails?.example}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt">
                          {" "}
                          {traslate("Field Type")}{" "}
                        </h4>
                        <p className="desc__txt">
                          {(rowDetails &&
                            rowDetails?.attributeType === "Text Field") ||
                          (rowDetails &&
                            rowDetails?.attributeType === "Fixed Labels") ||
                          (rowDetails &&
                            rowDetails?.attributeType === "TextField")
                            ? "Alphanumeric"
                            : rowDetails &&
                              rowDetails?.attributeType === "Decimal Field"
                            ? "Decimal"
                            : rowDetails &&
                              rowDetails?.attributeType === "Date Field"
                            ? "Date"
                            : (rowDetails &&
                                rowDetails?.attributeType ===
                                  "Boolean Field") ||
                              rowDetails?.attributeType === "Boolean"
                            ? "Boolean"
                            : (rowDetails &&
                                rowDetails?.attributeType === "Numeric") ||
                              (rowDetails &&
                                rowDetails?.attributeType === "Numeric Field")
                            ? "Integer"
                            : "-"}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt">
                          {" "}
                          {traslate("Configurable")}{" "}
                        </h4>
                        <p className="desc__txt">
                          {(rowDetails &&
                            rowDetails?.columnName ===
                              "IsConfigurableProduct") ||
                          (rowDetails &&
                            rowDetails?.columnName === "ParentSKU") ||
                          (rowDetails &&
                            rowDetails?.columnName === "VisibleAs") ||
                          (rowDetails &&
                            rowDetails?.columnName === "SelectedAttibutes") ||
                          (rowDetails &&
                            rowDetails?.columnName ===
                              "ConfigurableControlType") ||
                          (rowDetails &&
                            rowDetails?.columnName === "ControlDisplayOrder") ||
                          (rowDetails &&
                            rowDetails?.columnName === "FinishingTechnique") ||
                          (rowDetails && rowDetails?.columnName === "Gender")
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <h4 className="head__txt">
                          {" "}
                          {traslate("Definition")}{" "}
                        </h4>
                        <p className="desc__txt">
                          {rowDetails && rowDetails?.definition == ""
                            ? "-"
                            : rowDetails && rowDetails?.definition}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <Spin spinning={countSpin}>
                        <div className="count__div">
                          <div className="count__indiv">
                            <span className="no__txt">{mapCount}</span>
                            <span className="counthead__txt">
                              {" "}
                              {traslate("Mapped")}{" "}
                            </span>
                          </div>
                          <div className="count__indiv">
                            <span className="no__txt">{notMapCount}</span>
                            <span className="counthead__txt">
                              {" "}
                              {traslate("Remaining")}{" "}
                            </span>
                          </div>
                          <div className="reset__div">
                            <i
                              class="fa fa-refresh"
                              onClick={handleRefershData}
                            ></i>
                          </div>
                        </div>
                      </Spin>
                      <div className="content__div mt-4">
                        <p className="conthead__txt">
                          {traslate("Map Your Content")} <br />{" "}
                          {traslate("Drag & Drop Your Attributes")}
                        </p>
                        <span className="desc__txt">
                          {traslate(
                            "Match Your attributes with the jewel cloud industry standard"
                          )}
                        </span>
                      </div>
                      <div className="content__div mapping-help-docs-sp mt-4">
                        <p className="mb-0">
                          <a
                            className="conthead__txt"
                            href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Required%20Fields.xls`}
                            download
                          >
                            <img
                              src={MappingHelpDocs}
                              alt=""
                              className="mr-1"
                            />
                            {traslate("Mapping Help Docs")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Arrtibute column Modal */}
              <Modal
                title={traslate("Attribute Value Map")}
                className="datamapping__modal"
                visible={visible}
                width={800}
                onOk={() => handleSaveDefaultValue()}
                onCancel={() => handleCloseAttributModal(false)}
                footer={[
                  <Button
                    type=""
                    onClick={() => handleCloseAttributModal(false)}
                  >
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    //style={{ display: !isVisibleBtn ? "none" : "inline" }}
                    className="primary-btn" //: //     ? "disabled-btn" //   unmappedDetails.dealerColumnName == "" // {
                    //}
                    // disabled = {unmappedDetails.dealerColumnName == "" ? "disabled-btn" : "primary"}
                    loading={submitLoading}
                    onClick={() => handleSaveDefaultValue()}
                  >
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-8">
                        <span>
                          {traslate("Match Your Variables to the")}
                          {"  "}
                          {WhiteLabeling?.whiteLebelData?.[0]?.name ||
                            "JewelCloud"}{" "}
                          {"  "}
                          {traslate("Industry Standard.")}
                        </span>
                      </div>
                      <div className="col-lg-4">
                        <div className="inptsrch__div input__block mb-2">
                          <input
                            placeholder="Start Searching…"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            style={{ width: 200 }}
                          />
                        </div>
                        {unmappedDetails.dealerColumnName == "" ? (
                          <p className="error-color-red mb-0 text-right">
                            {traslate("Please Map Column First")}
                          </p>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="head__div">
                      <span>{selectedAttibutes}</span>
                    </div>
                  </div>
                  <Spin spinning={attributeLoading}>
                    <div className="col-lg-12">
                      {selectedAttibutes === "Categories" ? (
                        <React.Fragment>
                          {finalList.length > 0 ? (
                            finalList.map((item) => {
                              return (
                                <React.Fragment>
                                  <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header>
                                        <div>
                                          <span> {item.parentCategory}</span>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className="col-md-12">
                                          <div className="row">
                                            {attributeColumnData ? (
                                              attributeColumnData
                                                .filter(
                                                  (x) =>
                                                    x.parentCategory ===
                                                    item.parentCategory
                                                )
                                                .filter(
                                                  (x) =>
                                                    x.value
                                                      .toLowerCase()
                                                      .includes(searchValue) ||
                                                    x.value.includes(
                                                      searchValue
                                                    )
                                                )
                                                .map((citem, i) => {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="col-md-12">
                                                        <div className="input__block">
                                                          <div className="row">
                                                            <div className="col-md-3 align-self-center">
                                                              <label className="mb-0">
                                                                {citem.value}
                                                              </label>
                                                            </div>
                                                            <div className="col-md-9">
                                                              <input
                                                                type="text"
                                                                placeholder="Enter value"
                                                                name=""
                                                                value={
                                                                  citem.dvalue
                                                                }
                                                                onChange={(e) =>
                                                                  handleAttributeValueChanges(
                                                                    e,
                                                                    citem.dValueID
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                })
                                            ) : (
                                              <Empty
                                                image={
                                                  Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </React.Fragment>
                      ) : (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div>
                                <span> {selectedAttibutes}</span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="col-md-12">
                                <div className="row">
                                  {attributeColumnData ? (
                                    attributeColumnData.filter(
                                      (x) =>
                                        // x.value.toLowerCase().includes(searchValue)
                                        x.value
                                          .toLowerCase()
                                          .includes(searchValue) ||
                                        x.value.includes(searchValue)
                                    ).length > 0 ? (
                                      attributeColumnData
                                        .filter(
                                          (x) =>
                                            // x.value.toLowerCase().includes(searchValue)
                                            x.value
                                              .toLowerCase()
                                              .includes(searchValue) ||
                                            x.value.includes(searchValue)
                                        )
                                        .map((item, i) => {
                                          return (
                                            <React.Fragment>
                                              <div className="col-md-12">
                                                <div className="input__block">
                                                  <div className="row">
                                                    <div className="col-md-3 align-self-center">
                                                      <label className="mb-0">
                                                        {item.value}
                                                      </label>
                                                    </div>
                                                    <div className="col-md-9">
                                                      <input
                                                        type="text"
                                                        placeholder="Enter value"
                                                        name=""
                                                        value={item.dvalue}
                                                        onChange={(e) =>
                                                          handleAttributeValueChanges(
                                                            e,
                                                            item.id
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          );
                                        })
                                    ) : (
                                      <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      />
                                    )
                                  ) : (
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  )}
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      )}
                    </div>
                  </Spin>
                </div>
              </Modal>

              {/* Import Column Modal */}
              <Modal
                className="datamapping__modal"
                visible={visible1}
                width={600}
                onOk={() => setVisible1(false)}
                onCancel={() => handleCloseImportMappingModal()}
                footer={[]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12">
                    <div className="imptmap__headdiv">
                      <span> {traslate("Import Mapping")} </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="excel__maindiv mt-4">
                      <div className="excel__icndiv">
                        <img src={Excel_icon} alt="" />
                      </div>
                      <div className="excel__headtxtdiv">
                        <span className="head__txt">
                          {traslate(
                            "Your sheet just needs your column headers."
                          )}
                        </span>
                        <span className="extn__txt">
                          {" "}
                          {traslate(".xls | .txt | .csv")}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="excelfileupl__div mt-3">
                      <label
                        htmlFor="file-upload"
                        class="primary-btn choose_file_upload"
                      >
                        {traslate("Choose File")}
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        // value={importMappingFile ? importMappingFile : null}
                        onChange={(e) => handleImportFileUpload(e)}
                      />
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                      <p className="filename__txt">
                        {importMappingFile ? (
                          <>
                            {importMappingFile.name}{" "}
                            <img
                              src={close_red}
                              alt=""
                              // onClick={() => setImportMappingFile(null)}
                              onClick={handleSetImportMappingFile}
                            />
                          </>
                        ) : null}
                      </p>
                      {errorImportMappingTemplate ? (
                        <p>
                          <span style={{ color: "red" }}>
                            {errorImportMappingTemplate}
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="input__block">
                          <label className="">
                            {traslate("Choose Format")}
                            <span>*</span>
                            {/* <Tooltip
                            placement="right"
                            title={traslate("No content")}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip> */}
                          </label>
                          <Select
                            showSearch
                            className="border__grey"
                            placeholder="Choose Format"
                            name="availability"
                            optionFilterProp="children"
                            value={chooseFormate}
                            onChange={(e) => {
                              setChooseFormate(e);
                              setErrorChooseFormate(null);
                            }}
                          >
                            <Option>{traslate("Choose Format")}</Option>
                            <Option value="Comma"> {traslate("Comma")} </Option>
                            <Option value="Tab"> {traslate("Tab")} </Option>
                          </Select>
                          {errorChooseFormate ? (
                            <p>
                              <span style={{ color: "red" }}>
                                {errorChooseFormate}
                              </span>
                            </p>
                          ) : null}
                          <button
                            className="primary-btn mt-3"
                            onClick={() =>
                              handleOpenUploadMappingModal("UploadSheet")
                            }
                          >
                            {traslate("Upload Sheet")}
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="input__block">
                          <label className="text-center d-block">Or</label>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="input__block">
                          <label className="">
                            {traslate("Import Mapping Template")}
                            {/* <span>*</span> */}
                          </label>
                          <Select
                            showSearch
                            className="border__grey"
                            placeholder="Jewel Cloud"
                            name="availability"
                            optionFilterProp="children"
                            value={
                              additionalOption
                                ? additionalOption
                                : importMappingTemplate
                            }
                            onChange={(e) => {
                              additionalOption
                                ? setAdditionalOption(e)
                                : setImportMappingTemplate(e);
                            }}
                          >
                            <Option value="jewelcloud">
                              {" "}
                              {traslate("Jewel Cloud")}{" "}
                            </Option>
                            <Option value="edge">
                              {" "}
                              {traslate("Edge TPW")}{" "}
                            </Option>
                            <Option value="arms"> {traslate("Arms")} </Option>
                            <Option value="edge edt">
                              {" "}
                              {traslate("Edge EDT")}{" "}
                            </Option>
                          </Select>
                          <button
                            className="primary-btn mt-3"
                            onClick={() =>
                              handleOpenUploadMappingModal("LoadFile")
                            }
                          >
                            {traslate("Load Template")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* Add New Column Modal */}
              <Modal
                title={traslate("Add A New Mappable Column")}
                className="datamapping__modal"
                visible={visible2}
                // onOk={() => setVisible2(false)}
                onCancel={() => handleCloseAddNewColumnModal()}
                footer={[
                  <Button
                    key=""
                    type=""
                    onClick={() => handleCloseAddNewColumnModal()}
                  >
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleUpdateDealerColumn()}
                  >
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <Spin spinning={addNewColumnSpin}>
                    <div className="col-lg-12">
                      <span>
                        {" "}
                        {traslate("Add A Single New Column Header.")}{" "}
                      </span>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="input__block">
                            <input
                              type="text"
                              onChange={(e) => {
                                setNewColumnName(e.target.value);
                                setErrorNewColumnName(null);
                              }}
                              value={newColumnName}
                              placeholder="Add Attribute"
                            />
                            {errorNewColumnName ? (
                              <span className="error-color-red">
                                {errorNewColumnName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input__block">
                            <Button
                              loading={addnewLoading}
                              className="primary-btn"
                              onClick={handleSaveNewDealerColumn}
                            >
                              {traslate("Add New")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-2">
                      <Table
                        className="Addnewmap__tbl"
                        columns={[
                          {
                            title: traslate("Column ID"),
                            dataIndex: "columnId",
                          },
                          {
                            title: traslate("Column Name"),
                            dataIndex: "columnName",
                            width: 250,
                            render: (item, row) => {
                              return (
                                <React.Fragment>
                                  <div>
                                    {row.columnId ==
                                    editSelectedDealerColumn ? (
                                      <div className="input__block mb-0">
                                        <input
                                          type={"text"}
                                          value={item}
                                          onChange={(e) => {
                                            handleEditDealerColumnValue(
                                              row.columnId,
                                              e
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <span>{item}</span>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            },
                          },
                          {
                            title: traslate("Action"),
                            render: (item, row) => {
                              return (
                                <div className="action__btns">
                                  <div className="image__block">
                                    <img
                                      src={Edit_icon}
                                      onClick={() =>
                                        handleEditDealerColumn(row.columnId)
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={dealerColumnList}
                        scroll={{ y: 800 }}
                        pagination={false}
                        loading={loadingDealerColumnList}
                      />
                    </div>
                  </Spin>
                </div>
              </Modal>
              {/* Image Upload Setting Modal */}
              <Modal
                title={traslate("Image Upload Settings")}
                className="datamapping__modal"
                visible={imageUploadSetting}
                onCancel={() => handleCloseImageuploadSettingModal()}
                footer={[
                  <Button onClick={() => handleCloseImageuploadSettingModal()}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={submitLoading}
                    onClick={() => handleSaveImagePathSettings()}
                  >
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label> {traslate("Images Upload Option")} </label>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label> {traslate("Import Multiple")} </label>
                      <Radio.Group
                        value={importMultiple}
                        onChange={(e) => setImportMultiple(e.target.value)}
                      >
                        <Radio value={true}> {traslate("True")} </Radio>
                        <Radio value={false}> {traslate("False")} </Radio>
                      </Radio.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="input__block">
                        <label> {traslate("Select Delimiter")} </label>
                        <div className="d-flex">
                          <input
                            type={"text"}
                            value={delimiterImages}
                            onChange={(e) => {
                              setDelimiterImages(e.target.value);
                              setErrorDelimiterImages(null);
                            }}
                          />
                          <Tooltip
                            placement="right"
                            className="ml-1"
                            title={traslate(
                              "( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </div>
                        <div>
                          {errorDelimiterImages ? (
                            <span className="error-color-red">
                              {errorDelimiterImages}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* Price Type Modal */}
              <Modal
                title={traslate("Add Price Type Override")}
                className="datamapping__modal"
                visible={priceTypeModal}
                onCancel={() => handleClosePriceTypeModal()}
                footer={[
                  <Button onClick={() => handleClosePriceTypeModal()}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={submitLoading}
                    onClick={() => handleManageJewelryPriceTypeColumn()}
                  >
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label> {traslate("Price Type")} </label>
                      </div>
                      <div className="col-md-8">
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          // className="border__grey"
                          placeholder="Price Type"
                          name="availability"
                          optionFilterProp="children"
                          onChange={(e) => setPriceType(e)}
                          value={priceType}
                        >
                          <Option value="1">
                            {" "}
                            {traslate("Enforce MSRP")}{" "}
                          </Option>
                          <Option value="2">
                            {traslate(
                              "SuggestedRetail Price & Allow Retail Markups"
                            )}
                          </Option>
                          <Option value="3">
                            {" "}
                            {traslate("Allow Retail Markups")}{" "}
                          </Option>
                          <Option value="4">
                            {" "}
                            {traslate("Call For Price")}{" "}
                          </Option>
                          <Option value="5">
                            {" "}
                            {traslate("Prices Starting at")}{" "}
                          </Option>
                        </Select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-8">
                        <Checkbox
                          onChange={(e) => {
                            setAllowOverride(e.target.checked);
                          }}
                          checked={allowOverride === true ? true : false}
                        >
                          {traslate("Allow price type override")}
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* Conformation Modal */}
              <Modal
                className="datamapping__modal"
                width={400}
                centered
                visible={uploadMappingModal}
                onCancel={() => handleCloseUploadMappingModal()}
                footer={[
                  <Button onClick={() => handleCloseUploadMappingModal()}>
                    {traslate("Yikes...Go Back")}
                  </Button>,
                  <Button
                    loading={submitLoading}
                    type="primary"
                    onClick={() => handleUploadSheetDemo()}
                  >
                    {traslate("Reset The Map")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12 text-center">
                    <p>
                      {" "}
                      {traslate(
                        "Are your sure you want to reset the Data Map?"
                      )}{" "}
                    </p>
                    <h5>
                      {" "}
                      {traslate("THIS CAN NOT BE UNDONE")} ...{" "}
                      {traslate("THERE IS NO UNDO")}
                    </h5>
                  </div>
                </div>
              </Modal>

              {/* For Delete Your attribute */}

              <Modal
                className="datamapping__modal"
                width={400}
                centered
                visible={removeAttributeModal}
                onCancel={() => handleCancelRemoveAtrributeModal()}
                footer={[
                  <Button onClick={() => handleCancelRemoveAtrributeModal()}>
                    {traslate("Yikes...Go Back")}
                  </Button>,

                  <Button
                    loading={submitLoading}
                    type="primary"
                    onClick={() => handleRemoveColumnMapping()}
                  >
                    {traslate("Remove This Mapping")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12 text-center">
                    <p>
                      {" "}
                      {traslate(
                        "Are your sure you want to remove this mapping?"
                      )}{" "}
                    </p>
                    <h5>
                      {" "}
                      {traslate("THIS CAN NOT BE UNDONE")} ...{" "}
                      {traslate("THERE IS NO UNDO")}
                    </h5>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataMaping;
