import React, { useState, useEffect, useRef } from "react";
import { Select, Tooltip, Spin, Table, Modal, Button } from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import DefaultImage from "../../../assets/images/icons/sampleimage.jpg";
import PieChart from "../jewelry/PieChart";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ReportJewelryReportService from "../../../services/reports-Jewelry-service";
import moment from "moment";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const initialEventState = {
	dealerID: "",
	forDealer: "",
	dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
	dataRangeTo: moment().format("YYYY-MM-DD"),
	retailerIds: [],
	vendorIds: [],
	category: "",
	vendor: "",
	retailer: "",
};

const JeweleryChartReports = () => {
	const JewelRetailerMetalRef = useRef();
	const JewelRetailerMetalRef1 = useRef();
	const JewelRetailerMetalRef2 = useRef();

	const { Option } = Select;

	const [vendorList, setVendorList] = useState([]);
	const [retailerList, setRetailerList] = useState([]);
	const [topRetailerDealer, setTopRetailerDealer] = useState([]);
	const [topRetailerTotal, setTopRetailerTotal] = useState([]);
	const [dealerId, setDealerId] = useState();
	const loginDetials = useSelector((state) => state.loginReducer);
	const [selectedData, setSelectedData] = useState(initialEventState);
	const [isApplyFilter, setIsApplyFilter] = useState(false);
	const [countColumnData, setCountColumnData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [categoryColumnData, setCategoryColumnData] = useState([]);
	const [countCategoryColumnData, setCountCategoryColumnData] = useState([]);
	const [topVendorDealer, setTopVendorDealer] = useState([]);
	const [topVendorTotal, setTopVendorTotal] = useState([]);
	const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
	const [retailerTotalValuePercentage, setRetailerTotalValuePercentage] =
		useState(0);
	const [vendorTotalValuePercentage, setVendorTotalValuePercentage] =
		useState(0);
	const [key, setKey] = useState(true);
	const [dealerMembershipType, setDealerMembershiptype] = useState("");
	const [sortOrderType, setsortOrderType] = useState("desc");
	const [showTableLoader, setShowTableLoader] = useState(false);
	const [JewelryChartReportsTableList, setJewelryChartReportsTableList] =
		useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [pageNo, setPageNo] = useState(1);
	const [visible, setVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [defaultVisible, setDefaultVisible] = useState(false);
	const [downloadCSVDataloading, setDownloadCSVDataloading] = useState(false);
	const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
	const [totalCount, setTotalCount] = useState(0);
	const [categoryIdData, setCategoryIdData] = useState([]);
	const [vendorIdData, setVendorIdData] = useState([]);
	const [retailerIdData, setRetailerIdData] = useState([]);
	const [TooltipPercentage, setTooltipPercentage] = useState("");

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					setDealerMemberTypeName(
						loginDetials.loginDetials.responseData.dealerMemberTypeName
					);
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
					handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
					setDealerMembershiptype(
						loginDetials.loginDetials.responseData.dealerMemberTypeName
					);
				}
			}
		}
		handleGetVendorDropdownData();
		handleGetRetailerDropdownData();
	}, []);
	useEffect(() => {
		// debugger;
		if (selectedData.category || selectedData.vendor || selectedData.retailer) {
			handleGetTableChartData(
				dealerId,
				loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
					? 1
					: 3,
				1,
				10,
				"",
				sortOrderType
			);
		}
	}, [selectedData]);

	const handleGetForDealer = (id) => {
		try {
			const inputData = {
				dealerID: id,
			};
			setShowLoader(true);
			ReportJewelryReportService.GetForDealer(inputData).then((response) => {
				let message = response.data.message;
				let responseData = response.data.responseData;
				if (message === "Success") {
					let forDealerValue;
					if (
						loginDetials.loginDetials.responseData.dealerMemberTypeName ===
						"Vendor"
					) {
						forDealerValue = 1;
					} else {
						forDealerValue = 3;
					}

					handleGetVendorDropdownData();
					handleGetRetailerDropdownData();
					handleGetTableChartData(id, forDealerValue);
					handleGetClicksBasedOnCategory(id, forDealerValue);
					handleGetTopRetailerCoulmnData(id, forDealerValue);
					handleGetClicksBasedOnVendor(id, forDealerValue);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetVendorDropdownData = () => {
		try {
			const dealerId = loginDetials.loginDetials.responseData.dealerId;
			let inputData = {
				dealerId: dealerId,
				reporttype: "Dealer",
			};
			ReportJewelryReportService.GetVendorDropdownList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						setVendorList(responseData);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleGetRetailerDropdownData = () => {
		try {
			const dealerId = loginDetials.loginDetials.responseData.dealerId;
			let inputData = {
				dealerId: dealerId,
				reporttype: "Dealer",
			};
			ReportJewelryReportService.GetRetailerDropdownList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						setRetailerList(responseData);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const changeRetailerListHandler = (data) => {
		try {
			data = data.toString();
			let inputData = {
				vendorIds: data,
			};
			ReportJewelryReportService.ChangeRetailerList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						setRetailerList(responseData);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const changeVendorListHandler = (data) => {
		try {
			data = data.toString();
			let inputData = {
				retailerIds: data,
			};
			ReportJewelryReportService.ChangeVendorList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						setVendorList(responseData);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnChange = (e, isSelect, name) => {
		if (isSelect === "select" && name === "vendors") {
			let vId = "";
			if (e.includes(0)) {
				vId = [0];
				setSelectedData((prevState) => ({
					...prevState,
					vendorIds: vId,
				}));
			} else {
				vId = e;
				setSelectedData((prevState) => ({
					...prevState,
					vendorIds: e,
				}));
			}

			if (e.length === 0) {
				handleGetVendorDropdownData();
				handleGetRetailerDropdownData();
			} else {
				changeRetailerListHandler(vId);
			}
		} else if (isSelect === "select" && name === "retailers") {
			let rId = "";
			if (e.includes(0)) {
				rId = [0];
				setSelectedData((prevState) => ({
					...prevState,
					retailerIds: rId,
				}));
			} else {
				rId = e;
				setSelectedData((prevState) => ({
					...prevState,
					retailerIds: e,
				}));
			}

			if (e.length === 0) {
				handleGetVendorDropdownData();
				handleGetRetailerDropdownData();
			} else {
				changeVendorListHandler(rId);
			}
		}
	};

	const handleGetTableChartData = (
		DealerId,
		forDealerId,
		pageNumber,
		pageSize,
		sortColumn,
		sorttype
	) => {
		try {
			let inputData = {
				dealerID: DealerId.toString(),
				forDealer: forDealerId ? forDealerId.toString() : "",
				fromDate: selectedData.dataRangeFrom
					? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
					: "2020-08-17",
				toDate: selectedData.dataRangeTo
					? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
					: "2022-09-15",
				retailerIds: selectedData.retailerIds.toString(),
				vendorIds: selectedData.vendorIds.toString(),
				pageIndex: pageNumber ? pageNumber : 1,
				pageSize: pageSize ? pageSize : 10,
				category: selectedData.category,
				metalType: "",
				metalColorType: "",
				vendor: selectedData.vendor,
				retailer: selectedData.retailer,
				price: "",
				sortColumnName:
					sortColumn === "UserIPAddress"
						? "UserIPAddress"
						: sortColumn === "vendorName"
						? "VendorName"
						: sortColumn === "retailerName"
						? "RetailerName"
						: sortColumn === "collectionNew"
						? "CollectionNew"
						: sortColumn === "category"
						? "Category"
						: sortColumn === "date"
						? "Date"
						: sortColumn === "styleNumber"
						? "StyleNumber"
						: sortColumn === "metalType"
						? "MetalType"
						: sortColumn === "metalColor"
						? "MetalColor"
						: sortColumn === "price"
						? "Price"
						: "date",
				sortOrder: sorttype ? sorttype.toString() : sortOrderType.toString(),
				reporttype: "Dealer",
			};
			setShowTableLoader(true);
			ReportJewelryReportService.GetJewelryTableChartData(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message === "Success") {
						setJewelryChartReportsTableList(responseData);
						setShowTableLoader(false);
						setTotalCount(responseData?.[0]?.totalRecords || 0);
					} else {
						setJewelryChartReportsTableList([]);
						setShowTableLoader(false);
					}
				})
				.catch((error) => {
					setShowTableLoader(false);
					console.log(error);
				});
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const handleGetClicksBasedOnCategory = (DealerId, forDealerId) => {
		try {
			let inputData = {
				dealerID: DealerId.toString(),
				forDealer: forDealerId ? forDealerId.toString() : "",
				fromDate: selectedData.dataRangeFrom
					? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
					: "2020-08-17",
				toDate: selectedData.dataRangeTo
					? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
					: "2022-09-15",
				retailerIds: selectedData.retailerIds.toString(),
				vendorIds: selectedData.vendorIds.toString(),
				reporttype: "Dealer",
			};
			setShowLoader(true);
			ReportJewelryReportService.GetClicksBasedOnCategory(inputData).then(
				(response) => {
					let message = response.data.message;
					let responseData = response.data.responseData.Table;
					if (message === "Success") {
						let category = [];
						let count = [];
						for (let i of responseData) {
							category.push(i.Category);
							count.push(i.count);
						}
						let totalValuePercentage = count.reduce(
							(a, b) => (a = Number(a) + Number(b)),
							0
						);
						setCountCategoryColumnData(count);
						setCategoryColumnData(category);
						setCategoryIdData(category);
						setTotalValueForPercentage(totalValuePercentage);
						setShowLoader(false);
						setKey(!key);
					} else {
						setShowLoader(false);
					}
				}
			);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const handleGetTopRetailerCoulmnData = (DealerId, forDealerId) => {
		try {
			let inputData = {
				dealerID: DealerId.toString(),
				forDealer: forDealerId ? forDealerId.toString() : "",
				fromDate: selectedData.dataRangeFrom
					? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
					: "2020-08-17",
				toDate: selectedData.dataRangeTo
					? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
					: "2022-09-15",
				retailerIds: selectedData.retailerIds.toString(),
				vendorIds: selectedData.vendorIds.toString(),
				reporttype: "Dealer",
			};
			setShowLoader(true);
			ReportJewelryReportService.GetClicksBasedOnRetailer(inputData).then(
				(response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message === "Success") {
						let dealerName = [];
						let total = [];
						for (let i of responseData) {
							dealerName.push(i.dealerCompany);
							total.push(i.total);
						}
						let totalValuePercentage = total.reduce(
							(a, b) => (a = Number(a) + Number(b)),
							0
						);

						setTopRetailerTotal(total);
						setTopRetailerDealer(dealerName);
						setRetailerIdData(dealerName);
						setRetailerTotalValuePercentage(totalValuePercentage);
						setShowLoader(false);
						setKey(!key);
					} else {
						setShowLoader(false);
					}
				}
			);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};
	const handleGetClicksBasedOnVendor = (DealerId, forDealerId) => {
		try {
			let inputData = {
				dealerID: DealerId.toString(),
				forDealer:
					loginDetials.loginDetials.responseData.dealerMemberTypeName ===
					"Vendor"
						? "1"
						: "3",
				fromDate: selectedData.dataRangeFrom
					? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
					: "2020-08-17",
				toDate: selectedData.dataRangeTo
					? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
					: "2022-09-15",
				retailerIds: selectedData.retailerIds.toString(),
				vendorIds: selectedData.vendorIds.toString(),
				reporttype: "Dealer",
			};
			setShowLoader(true);
			ReportJewelryReportService.GetClicksBasedOnTopVendor(inputData).then(
				(response) => {
					let message = response.data.message;
					let responseData = response.data.responseData.Table;
					if (message === "Success") {
						let dealerCompany = [];
						let total = [];
						for (let i of responseData) {
							dealerCompany.push(i.DealerCompany);
							total.push(i.Total);
						}

						let totalValuePercentage = total.reduce(
							(a, b) => (a = Number(a) + Number(b)),
							0
						);

						setTopVendorTotal(total);
						setTopVendorDealer(dealerCompany);
						setVendorIdData(dealerCompany);
						setVendorTotalValuePercentage(totalValuePercentage);
						setShowLoader(false);
						setKey(!key);
					} else {
						setShowLoader(false);
					}
				}
			);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const handleExportChartReportToCSV = (sortColumn, sorttype) => {
		try {
			let forDealerValue = "";
			if (
				loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
			) {
				forDealerValue = 1;
			} else {
				forDealerValue = 3;
			}
			const inputData = {
				dealerId: dealerId.toString(),
				forDealer: forDealerValue.toString(),
				fromDate: selectedData.dataRangeFrom
					? moment(selectedData.dataRangeFrom).format("YYYY-MM-DD")
					: "2020-08-17",
				toDate: selectedData.dataRangeTo
					? moment(selectedData.dataRangeTo).format("YYYY-MM-DD")
					: "2022-09-15",
				pageIndex: pageNo ? pageNo : 1,
				pageSize: pageSize ? pageSize : 10,
				reporttype: "Dealer",
				retailerIds: selectedData.retailerIds.toString(),
				vendorIds: selectedData.vendorIds.toString(),
				sortColumnName:
					sortColumn === "UserIPAddress"
						? "UserIPAddress"
						: sortColumn === "vendorName"
						? "VendorName"
						: sortColumn === "retailerName"
						? "RetailerName"
						: sortColumn === "collectionNew"
						? "CollectionNew"
						: sortColumn === "category"
						? "Category"
						: sortColumn === "date"
						? "Date"
						: sortColumn === "styleNumber"
						? "StyleNumber"
						: sortColumn === "metalType"
						? "MetalType"
						: sortColumn === "metalColor"
						? "MetalColor"
						: sortColumn === "price"
						? "Price"
						: "UserIPAddress",
				sortOrder: sorttype ? sorttype.toString() : sortOrderType.toString(),
			};
			setDownloadCSVDataloading(true);
			ReportJewelryReportService.ExportChartReportToCSV(inputData)
				.then((response) => {
					setDownloadCSVDataloading(false);
					var fileName =
						"JewelryChartReports_" +
						new Date().toJSON().slice(0, 10).split("-").reverse().join("/") +
						".csv";
					BlobToDownloadFile(response.data, fileName);
				})
				.catch((error) => {
					setDownloadCSVDataloading(false);
				});
		} catch (error) {
			setDownloadCSVDataloading(false);
		}
	};

	const handleTableASCDES = (pagination, filters, sorter) => {
		var type = "";
		var column = "";
		//sorting
		if (sorter.order === "descend") {
			type = "DESC";
			column = sorter.field;
			setsortOrderType(type);
		} else if (sorter.order === "ascend") {
			type = "ASC";
			column = sorter.field;
			setsortOrderType(type);
		} else {
			type = "DESC";
			column = "date";
			setsortOrderType(type);
		}
		console.log("Sort Type:", type);
		console.log("Sort Column:", column);
		const forDealerValue =
			loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
				? 1
				: 3;
		setPageNo(pagination.current);
		setPageSize(pagination.pageSize);
		handleGetTableChartData(
			dealerId,
			forDealerValue,
			pagination.current,
			pagination.pageSize,
			column,
			type
		);
	};

	const handleApplyFilter = () => {
		setTopRetailerDealer([]);
		setTopRetailerTotal([]);
		setPageNo(1);
		setPageSize(10);
		handleGetForDealer(dealerId);
		setIsApplyFilter(!isApplyFilter);
	};

	const imageClickHandler = (img) => {
		setVisible(true);
		setSelectedImage(img);
	};

	const onDateChange = (dates, dateStrings) => {
		if (dates) {
			setSelectedData((prevState) => ({
				...prevState,
				dataRangeFrom: dateStrings[0],
				dataRangeTo: dateStrings[1],
			}));
		}
	};

	return (
		<React.Fragment>
			<div className="pendjewelreq__section form__fields">
				<div className="row magento__mapping">
					<div className="col-md-12">
						<div className="heading__block">
							<h4 class="workarea__heading mt-0 mb-0">
								{traslate("Jewelry Chart Report")}
							</h4>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input__block datepicker__block">
							<label> {traslate("Date Range")} </label>
							<RangePicker
								className="date-range-input"
								ranges={{
									Today: [moment(), moment()],
									Yesterday: [
										moment().subtract(1, "days"),
										moment().subtract(1, "days"),
									],
									"Last 7 Days": [moment().subtract(7, "days"), moment()],
									"Last 30 Days": [moment().subtract(30, "days"), moment()],
									"This Month": [
										moment().startOf("month"),
										moment().endOf("month"),
									],
									"Last Month": [
										moment().subtract(1, "month").startOf("month"),
										moment().subtract(1, "months").endOf("month"),
									],
									"Last 90 Days": [moment().subtract(90, "days"), moment()],
									"Year To Date": [moment().startOf("year"), moment()],
								}}
								format={"MM-DD-YYYY"}
								onChange={onDateChange}
								defaultValue={[moment().subtract(30, "days"), moment()]}
								defaultPickerValue={[moment().subtract(30, "days"), moment()]}
							/>
						</div>
					</div>

					{dealerMembershipType === "Vendor" ||
					dealerMembershipType === "VendorDiamond" ? null : (
						<div className="col-md-4">
							<div className="input__block">
								<label> {traslate("Vendor(s)")} </label>
								<Select
									className="border__grey"
									showSearch
									mode="multiple"
									showArrow
									placeholder="Select One or More Vendors"
									optionFilterProp="children"
									value={selectedData.vendorIds}
									onChange={(e) => handleOnChange(e, "select", "vendors")}>
									{vendorList.map((item, key) => {
										return (
											<Option key={item.dealerID} value={item.dealerID}>
												{item.dealerCompany}
											</Option>
										);
									})}
								</Select>
							</div>
						</div>
					)}

					{dealerMembershipType === "Retailer" ? null : (
						<div className="col-md-4">
							<div className="input__block">
								<label> {traslate("Retailer(s)")} </label>
								<Select
									showSearch
									showArrow
									className="border__grey"
									placeholder="Select one or more retailer "
									optionFilterProp="children"
									onChange={(e) => handleOnChange(e, "select", "retailers")}
									notFoundContent="No Data Found"
									mode="multiple"
									value={selectedData.retailerIds}>
									{retailerList.map((item, key) => {
										return (
											<Option key={key} value={item.dealerID}>
												{item.dealerCompany}
											</Option>
										);
									})}
								</Select>
							</div>
						</div>
					)}

					<div className="col-md-4 mt-4">
						<div className="input__block">
							<button className="primary-btn mt-2" onClick={handleApplyFilter}>
								{traslate("Apply Filter")}
							</button>
						</div>
					</div>
					{categoryColumnData.length > 0 &&
						topRetailerDealer.length > 0 &&
						topVendorDealer.length > 0 && (
							<div className="col-lg-12 col-md-6">
								<div className="input__block">
									<label>
										{totalCount !== 0 && (
											<b>
												{traslate("Total Jewelry Clicks")} : {totalCount}
											</b>
										)}
										<Tooltip
											placement="right"
											title={traslate(
												"Displays the total number of jewelry clicks through the currently specified date range above"
											)}>
											<img src={UnionIcon} alt="img" />
										</Tooltip>
									</label>
								</div>
							</div>
						)}
					<Spin spinning={showLoader}>
						<div className="col-lg-12">
							<label>
								<b> {traslate("Jewelry Clicks Based on Category")} </b>
							</label>
							<div className="chart__section">
								{categoryColumnData.length > 0 ? (
									categoryColumnData !== null &&
									categoryColumnData.length > 0 &&
									countCategoryColumnData.length > 0 &&
									totalValueForPercentage !== 0 && (
										<Pie
											key={key}
											onClick={(event) => {
												debugger;
												const element = getElementAtEvent(
													JewelRetailerMetalRef.current,
													event
												);
												if (!element.length) return;

												const { index } = element[0];
												setSelectedData((prevState) => ({
													...prevState,
													category: categoryIdData[index],
													retailer: "",
													vendor: "",
												}));
											}}
											ref={JewelRetailerMetalRef}
											data={{
												labels: categoryColumnData,
												datasets: [
													{
														data: countCategoryColumnData,
														backgroundColor: [
															"rgb(14, 220, 232)",
															"rgb(16, 150, 24)",
															"rgb(255, 153, 0)",
															"rgb(153, 0, 153)",
															"rgb(0, 153, 198)",
															"rgb(51, 102, 204)",
															"rgb(220, 57, 18)",
															"rgb(102, 170, 0)",
															"rgb(221, 68, 119)",
															"rgb(237, 237, 1)",
															"rgb(100, 50, 200)",
															"rgb(255, 105, 180)",
														],
														borderColor: [
															"rgb(14, 220, 232)",
															"rgb(16, 150, 24)",
															"rgb(255, 153, 0)",
															"rgb(153, 0, 153)",
															"rgb(0, 153, 198)",
															"rgb(51, 102, 204)",
															"rgb(220, 57, 18)",
															"rgb(102, 170, 0)",
															"rgb(221, 68, 119)",
															"rgb(237, 237, 1)",
															"rgb(100, 50, 200)",
															"rgb(255, 105, 180)",
														],
														borderWidth: 1,
													},
												],
											}}
											width={300}
											height={300}
											options={{
												maintainAspectRatio: false,
												responsive: true,
												plugins: {
													datalabels: {
														display: true,
														align: "end",
														color: "rgb(255, 255, 255)",
														font: {
															size: 12,
														},
														formatter: (value) => {
															if (totalValueForPercentage) {
																let finalPercentage = (
																	(value / totalValueForPercentage) *
																	100
																).toFixed(2);
																setTooltipPercentage(finalPercentage);
																if (finalPercentage >= 5) {
																	return finalPercentage + "%";
																} else {
																	return "";
																}
															}
														},
													},
													tooltip: {
														enabled: true,
														callbacks: {
															label: function (context) {
																let label = context.label || "";
																let formattedValue =
																	context.formattedValue || "";

																if (label) {
																	label =
																		label +
																		" " +
																		formattedValue +
																		" (" +
																		TooltipPercentage +
																		"%" +
																		")";
																}
																return label;
															},
														},
													},
													legend: {
														position: "left",
													},
												},
											}}
										/>
									)
								) : (
									<div className="no-report-render">
										<div className="desc-space">
											<h4 className="text-center">
												{traslate("No Data Found")}
											</h4>
										</div>
									</div>
								)}
							</div>
						</div>

						{dealerMemberTypeName === "Admin" ? (
							<>
								<div className="col-lg-12">
									<label>
										<b> {traslate("Jewelry Clicks Based on Vendor")} </b>
									</label>
									<div className="chart__section">
										{topRetailerDealer.length > 0 ? (
											topRetailerDealer !== null &&
											topRetailerDealer.length > 0 &&
											topRetailerTotal.length > 0 &&
											retailerTotalValuePercentage !== 0 && (
												<Pie
													key={key}
													onClick={(event) => {
														debugger;
														const element = getElementAtEvent(
															JewelRetailerMetalRef1.current,
															event
														);
														if (!element.length) return;

														const { index } = element[0];
														setSelectedData((prevState) => ({
															...prevState,
															vendor: retailerIdData[index],
															category: "",
															retailer: "",
														}));
													}}
													ref={JewelRetailerMetalRef1}
													data={{
														labels: topRetailerDealer,
														datasets: [
															{
																data: topRetailerTotal,
																backgroundColor: [
																	"rgb(14, 220, 232)",
																	"rgb(16, 150, 24)",
																	"rgb(255, 153, 0)",
																	"rgb(153, 0, 153)",
																	"rgb(0, 153, 198)",
																	"rgb(51, 102, 204)",
																	"rgb(220, 57, 18)",
																	"rgb(102, 170, 0)",
																	"rgb(221, 68, 119)",
																	"rgb(237, 237, 1)",
																	"rgb(100, 50, 200)",
																	"rgb(255, 105, 180)",
																],
																borderColor: [
																	"rgb(14, 220, 232)",
																	"rgb(16, 150, 24)",
																	"rgb(255, 153, 0)",
																	"rgb(153, 0, 153)",
																	"rgb(0, 153, 198)",
																	"rgb(51, 102, 204)",
																	"rgb(220, 57, 18)",
																	"rgb(102, 170, 0)",
																	"rgb(221, 68, 119)",
																	"rgb(237, 237, 1)",
																	"rgb(100, 50, 200)",
																	"rgb(255, 105, 180)",
																],
																borderWidth: 1,
															},
														],
													}}
													width={300}
													height={300}
													options={{
														maintainAspectRatio: false,
														responsive: true,
														plugins: {
															datalabels: {
																display: true,
																align: "end",
																color: "rgb(255, 255, 255)",
																font: {
																	size: 12,
																},
																formatter: (value) => {
																	if (retailerTotalValuePercentage) {
																		let finalPercentage = (
																			(value / retailerTotalValuePercentage) *
																			100
																		).toFixed(2);
																		setTooltipPercentage(finalPercentage);
																		if (finalPercentage >= 5) {
																			return finalPercentage + "%";
																		} else {
																			return "";
																		}
																	}
																},
															},
															tooltip: {
																enabled: true,
																callbacks: {
																	label: function (context) {
																		let label = context.label || "";
																		let formattedValue =
																			context.formattedValue || "";

																		if (label) {
																			label =
																				label +
																				" " +
																				formattedValue +
																				" (" +
																				TooltipPercentage +
																				"%" +
																				")";
																		}
																		return label;
																	},
																},
															},
															legend: {
																position: "left",
															},
														},
													}}
												/>
											)
										) : (
											<div className="no-report-render">
												<div className="desc-space">
													<h4 className="text-center">
														{traslate("No Data Found")}
													</h4>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="col-lg-12">
									<label>
										<b> {traslate("Jewelry Clicks Based on Retailer")} </b>
									</label>
									<div className="chart__section">
										{topVendorDealer.length > 0 ? (
											topVendorDealer !== null &&
											topVendorDealer.length > 0 &&
											topVendorTotal.length > 0 &&
											vendorTotalValuePercentage !== 0 && (
												<Pie
													key={key}
													onClick={(event) => {
														debugger;
														const element = getElementAtEvent(
															JewelRetailerMetalRef2.current,
															event
														);
														if (!element.length) return;

														const { index } = element[0];
														setSelectedData((prevState) => ({
															...prevState,
															retailer: vendorIdData[index],
															category: "",
															vendor: "",
														}));
													}}
													ref={JewelRetailerMetalRef2}
													data={{
														labels: topVendorDealer,
														datasets: [
															{
																data: topVendorTotal,
																backgroundColor: [
																	"rgb(14, 220, 232)",
																	"rgb(16, 150, 24)",
																	"rgb(255, 153, 0)",
																	"rgb(153, 0, 153)",
																	"rgb(0, 153, 198)",
																	"rgb(51, 102, 204)",
																	"rgb(220, 57, 18)",
																	"rgb(102, 170, 0)",
																	"rgb(221, 68, 119)",
																	"rgb(237, 237, 1)",
																	"rgb(100, 50, 200)",
																	"rgb(255, 105, 180)",
																],
																borderColor: [
																	"rgb(14, 220, 232)",
																	"rgb(16, 150, 24)",
																	"rgb(255, 153, 0)",
																	"rgb(153, 0, 153)",
																	"rgb(0, 153, 198)",
																	"rgb(51, 102, 204)",
																	"rgb(220, 57, 18)",
																	"rgb(102, 170, 0)",
																	"rgb(221, 68, 119)",
																	"rgb(237, 237, 1)",
																	"rgb(100, 50, 200)",
																	"rgb(255, 105, 180)",
																],
																borderWidth: 1,
															},
														],
													}}
													width={300}
													height={300}
													options={{
														maintainAspectRatio: false,
														responsive: true,
														plugins: {
															datalabels: {
																display: true,
																align: "end",
																color: "rgb(255, 255, 255)",
																font: {
																	size: 12,
																},
																formatter: (value) => {
																	if (vendorTotalValuePercentage) {
																		let finalPercentage = (
																			(value / vendorTotalValuePercentage) *
																			100
																		).toFixed(2);
																		setTooltipPercentage(finalPercentage);
																		if (finalPercentage >= 5) {
																			return finalPercentage + "%";
																		} else {
																			return "";
																		}
																	}
																},
															},
															tooltip: {
																enabled: true,
																callbacks: {
																	label: function (context) {
																		let label = context.label || "";
																		let formattedValue =
																			context.formattedValue || "";

																		if (label) {
																			label =
																				label +
																				" " +
																				formattedValue +
																				" (" +
																				TooltipPercentage +
																				"%" +
																				")";
																		}
																		return label;
																	},
																},
															},
															legend: {
																position: "left",
															},
														},
													}}
												/>
											)
										) : (
											<div className="no-report-render">
												<div className="desc-space">
													<h4 className="text-center">
														{traslate("No Data Found")}
													</h4>
												</div>
											</div>
										)}
									</div>
								</div>
							</>
						) : dealerMemberTypeName === "Retailer" ? (
							<div className="col-lg-12">
								<label>
									<b> {traslate("Jewelry Clicks Based on Vendor")} </b>
								</label>
								<div className="chart__section">
									{topRetailerDealer.length > 0 ? (
										<Pie
											key={key}
											onClick={(event) => {
												debugger;
												const element = getElementAtEvent(
													JewelRetailerMetalRef1.current,
													event
												);
												if (!element.length) return;

												const { index } = element[0];
												setSelectedData((prevState) => ({
													...prevState,
													vendor: retailerIdData[index],
													category: "",
													retailer: "",
												}));
											}}
											ref={JewelRetailerMetalRef1}
											data={{
												labels: topRetailerDealer,
												datasets: [
													{
														data: topRetailerTotal,
														backgroundColor: [
															"rgb(14, 220, 232)",
															"rgb(16, 150, 24)",
															"rgb(255, 153, 0)",
															"rgb(153, 0, 153)",
															"rgb(0, 153, 198)",
															"rgb(51, 102, 204)",
															"rgb(220, 57, 18)",
															"rgb(102, 170, 0)",
															"rgb(221, 68, 119)",
															"rgb(237, 237, 1)",
															"rgb(100, 50, 200)",
															"rgb(255, 105, 180)",
														],
														borderColor: [
															"rgb(14, 220, 232)",
															"rgb(16, 150, 24)",
															"rgb(255, 153, 0)",
															"rgb(153, 0, 153)",
															"rgb(0, 153, 198)",
															"rgb(51, 102, 204)",
															"rgb(220, 57, 18)",
															"rgb(102, 170, 0)",
															"rgb(221, 68, 119)",
															"rgb(237, 237, 1)",
															"rgb(100, 50, 200)",
															"rgb(255, 105, 180)",
														],
														borderWidth: 1,
													},
												],
											}}
											width={300}
											height={300}
											options={{
												maintainAspectRatio: false,
												responsive: true,
												plugins: {
													datalabels: {
														display: true,
														align: "end",
														color: "rgb(255, 255, 255)",
														font: {
															size: 12,
														},
														formatter: (value) => {
															if (retailerTotalValuePercentage) {
																let finalPercentage = (
																	(value / retailerTotalValuePercentage) *
																	100
																).toFixed(2);
																setTooltipPercentage(finalPercentage);
																if (finalPercentage >= 5) {
																	return finalPercentage + "%";
																} else {
																	return "";
																}
															}
														},
													},
													tooltip: {
														enabled: true,
														callbacks: {
															label: function (context) {
																let label = context.label || "";
																let formattedValue =
																	context.formattedValue || "";

																if (label) {
																	label =
																		label +
																		" " +
																		formattedValue +
																		" (" +
																		TooltipPercentage +
																		"%" +
																		")";
																}
																return label;
															},
														},
													},
													legend: {
														position: "left",
													},
												},
											}}
										/>
									) : (
										<div className="no-report-render">
											<div className="desc-space">
												<h4 className="text-center">
													{traslate("No Data Found")}
												</h4>
											</div>
										</div>
									)}
								</div>
							</div>
						) : (
							<div className="col-lg-12">
								<label>
									<b> {traslate("Jewelry Clicks Based on Retailer")} </b>
								</label>
								<div className="chart__section">
									{topVendorDealer.length > 0 ? (
										topVendorDealer !== null &&
										topVendorDealer.length > 0 &&
										topVendorTotal.length > 0 &&
										vendorTotalValuePercentage !== 0 && (
											<Pie
												key={key}
												onClick={(event) => {
													debugger;
													const element = getElementAtEvent(
														JewelRetailerMetalRef2.current,
														event
													);
													if (!element.length) return;

													const { index } = element[0];
													setSelectedData((prevState) => ({
														...prevState,
														retailer: vendorIdData[index],
														vendor: "",
														category: "",
													}));
												}}
												ref={JewelRetailerMetalRef2}
												data={{
													labels: topVendorDealer,
													datasets: [
														{
															data: topVendorTotal,
															backgroundColor: [
																"rgb(14, 220, 232)",
																"rgb(16, 150, 24)",
																"rgb(255, 153, 0)",
																"rgb(153, 0, 153)",
																"rgb(0, 153, 198)",
																"rgb(51, 102, 204)",
																"rgb(220, 57, 18)",
																"rgb(102, 170, 0)",
																"rgb(221, 68, 119)",
																"rgb(237, 237, 1)",
																"rgb(100, 50, 200)",
																"rgb(255, 105, 180)",
															],
															borderColor: [
																"rgb(14, 220, 232)",
																"rgb(16, 150, 24)",
																"rgb(255, 153, 0)",
																"rgb(153, 0, 153)",
																"rgb(0, 153, 198)",
																"rgb(51, 102, 204)",
																"rgb(220, 57, 18)",
																"rgb(102, 170, 0)",
																"rgb(221, 68, 119)",
																"rgb(237, 237, 1)",
																"rgb(100, 50, 200)",
																"rgb(255, 105, 180)",
															],
															borderWidth: 1,
														},
													],
												}}
												width={300}
												height={300}
												options={{
													maintainAspectRatio: false,
													responsive: true,
													plugins: {
														datalabels: {
															display: true,
															align: "end",
															color: "rgb(255, 255, 255)",
															font: {
																size: 12,
															},
															formatter: (value) => {
																if (vendorTotalValuePercentage) {
																	let finalPercentage = (
																		(value / vendorTotalValuePercentage) *
																		100
																	).toFixed(2);
																	setTooltipPercentage(finalPercentage);
																	if (finalPercentage >= 5) {
																		return finalPercentage + "%";
																	} else {
																		return "";
																	}
																}
															},
														},
														tooltip: {
															enabled: true,
															callbacks: {
																label: function (context) {
																	let label = context.label || "";
																	let formattedValue =
																		context.formattedValue || "";

																	if (label) {
																		label =
																			label +
																			" " +
																			formattedValue +
																			" (" +
																			TooltipPercentage +
																			"%" +
																			")";
																	}
																	return label;
																},
															},
														},
														legend: {
															position: "left",
														},
													},
												}}
											/>
										)
									) : (
										<div className="no-report-render">
											<div className="desc-space">
												<h4 className="text-center">
													{traslate("No Data Found")}
												</h4>
											</div>
										</div>
									)}
								</div>
							</div>
						)}

						<div className="col-lg-12 mt-3">
							<div className="options__div">
								<div className="row mb-4">
									<div className="col-lg-10 rules__div"></div>
									<div className="col-lg-2 crud__div">
										<Button
											className="primary-btn"
											onClick={() => handleExportChartReportToCSV()}
											loading={downloadCSVDataloading}>
											<i className="fa fa-download"></i>
											{traslate("Download CSV")}
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-12 col-md-12">
							<div className="table__scroll">
								<Table
									className="pendjewelreq__tbl"
									columns={[
										{
											title: traslate("Product Image"),
											dataIndex: "imagePathNew",
											width: 90,
											render: (item, data) => (
												<>
													{data.imagePathNew ? (
														<span
															onClick={() =>
																imageClickHandler(data.imagePathNew)
															}>
															<img
																src={data.imagePathNew}
																alt="img"
																className="product__img"
																style={{ cursor: "pointer" }}
															/>
														</span>
													) : (
														<span onClick={() => setDefaultVisible(true)}>
															<img
																src={DefaultImage}
																alt="img"
																className="product__img"
																style={{ cursor: "pointer" }}
															/>
														</span>
													)}
												</>
											),
										},
										{
											title: traslate("User IP Address"),
											dataIndex: "userIPAddress",
											sorter: (a, b) => {},
											width: 90,
										},
										...(dealerMembershipType !== "Vendor" &&
										dealerMembershipType !== "VendorDiamond"
											? [
													{
														title: traslate("Vendor"),
														dataIndex: "vendorName",
														width: 120,
														sorter: (a, b) => {},
													},
											  ]
											: []),
										...(dealerMembershipType !== "Retailer"
											? [
													{
														title: traslate("Retailer"),
														dataIndex: "retailerName",
														width: 120,
														sorter: (a, b) => {},
													},
											  ]
											: []),
										{
											title: traslate("Collection"),
											dataIndex: "collectionNew",
											sorter: (a, b) => {},
											width: 120,
										},
										{
											title: traslate("Category"),
											dataIndex: "category",
											sorter: (a, b) => {},
											width: 120,
										},
										{
											title: traslate("Date"),
											dataIndex: "date",
											sorter: (a, b) => {},
											width: 120,
											render: (row, item) => {
												if (Object.keys(item.date).length !== 0) {
													return (
														<React.Fragment>
															<span>
																{moment(item.date).format(
																	"MM-DD-YYYY, hh:mm:ss A"
																)}
															</span>
														</React.Fragment>
													);
												}
											},
										},
										{
											title: traslate("Style Number"),
											dataIndex: "styleNumber",
											sorter: (a, b) => {},
											width: 120,
										},
										{
											title: traslate("Metal Type"),
											dataIndex: "metalType",
											sorter: (a, b) => {},
											width: 100,
										},
										{
											title: traslate("Metal Color"),
											dataIndex: "metalColor",
											sorter: (a, b) => {},
											width: 100,
										},
										{
											title: traslate("Price"),
											dataIndex: "price",
											sorter: (a, b) => {},
											width: 100,
										},
									]}
									pagination={{
										total: totalCount,
										pageSizeOptions: [10, 20, 50, 100],
										responsive: true,
										showSizeChanger: true,
										pageSize: pageSize,
										current: pageNo,
									}}
									loading={showTableLoader}
									dataSource={JewelryChartReportsTableList}
									scroll={{ x: 600, y: 800 }}
									onChange={handleTableASCDES}
								/>
							</div>
						</div>
						<Modal
							title=""
							centered
							visible={visible}
							onOk={() => setVisible(false)}
							onCancel={() => setVisible(false)}
							width={500}
							footer={false}>
							<div className="">
								<img src={selectedImage} alt="img" className="img-fluid" />
							</div>
						</Modal>

						<Modal
							title=""
							centered
							visible={defaultVisible}
							onOk={() => setDefaultVisible(false)}
							onCancel={() => setDefaultVisible(false)}
							width={500}
							footer={false}>
							<div className="">
								<img src={DefaultImage} alt="img" className="img-fluid" />
							</div>
						</Modal>
					</Spin>
				</div>
			</div>
		</React.Fragment>
	);
};

export default JeweleryChartReports;
