import React, { useState, useEffect } from "react";
import { Select, Button, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const { Option } = Select;
const ReportsPermissions = () => {
  const initialState = {
    dealerId: null,
    clientType: null,
    diamondCharts: false,
    jewelryCharts: false,
    globalDiamondCharts: false,
    globalJewelryCharts: false,
    clientTypeList: [
      {
        id: 1,
        value: null,
        label: "All",
      },
      {
        id: 2,
        value: "vendordiamond",
        label: "Diamond Dealers",
      },
      {
        id: 3,
        value: "retailer",
        label: "Retailers",
      },
      {
        id: 4,
        value: "vendor",
        label: "Vendors",
      },
    ],
  };
  const [dealerList, setDealerList] = useState([]);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    GetUsersList();
  }, []);
  const onChange = (name, e) => {
    let tempState = state;

    if (name == "clientType") {
      tempState["dealerId"] = null;
      setDealerList([]);
      setTimeout(() => {
        GetUsersList();
      }, 1000);
    }
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name === "dealerId") {
      tempState["diamondCharts"] = false;
      tempState["jewelryCharts"] = false;
      tempState["globalDiamondCharts"] = false;
      tempState["globalJewelryCharts"] = false;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
      setLoading(true);
      setTimeout(() => {
        handleGetReportPermissionsList();
      }, 500);
    }
  };

  const GetUsersList = () => {
    let inputData = {
      clientType: state.clientType || "",
    };
    AdminToolsDataService.GetUsersList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setDealerList(responseData);
        } else {
          setDealerList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetReportPermissionsList = () => {
    let inputData = {
      dealerID: state.dealerId,
    };

    setLoading(true);
    AdminToolsDataService.GetReportPermissionsList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message === "Success") {
          const tempState = state;
          tempState["diamondCharts"] = responseData[0].diamondCharts;
          tempState["globalDiamondCharts"] =
            responseData[0].globalDiamondCharts;
          tempState["globalJewelryCharts"] =
            responseData[0].globalJewelryCharts;
          tempState["jewelryCharts"] = responseData[0].jewelryCharts;
          setState((prevState) => ({
            ...prevState,
            ...tempState,
          }));
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSaveReportPermissions = () => {
    let inputData = {
      dealerId: state.dealerId,
      diamondCharts: state.diamondCharts,
      jewelryCharts: state.jewelryCharts,
      globalDiamondCharts: state.globalDiamondCharts,
      globalJewelryCharts: state.globalJewelryCharts,
    };

    setSubmitLoading(true);
    AdminToolsDataService.SaveReportPermissions(inputData)
      .then((response) => {
        var message = response.data.message;
        setSubmitLoading(false);
        if (message === "Success") {
          NotificationManager.success(traslate("Data Updated Successfully."));
          setState(initialState);
        } else {
          NotificationManager.error(traslate("Record Not Submited."));
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const handleCheckboxChange = (e, name) => {
    let tempState = state;
    tempState[name] = e.target.checked;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  return (
    <React.Fragment>
      <div className="rpermission__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Report Permission Control")}
              </h4>
            </div>

            <div className="col-lg-3 col-md-6 mt-4 mobile-m-0">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("Select Client Type")}: <span>*</span>
                </label>
                <Select
                  className="border__grey"
                  showSearch
                  placeholder={traslate("Select Dealer")}
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={state.clientType}
                  onChange={(e) => onChange("clientType", e)}
                >
                  {state.clientTypeList.map((item, i) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-4 mobile-m-0">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("Client Name")}: <span>*</span>
                </label>
                <Select
                  className="border__grey"
                  showSearch
                  placeholder={traslate("Select Dealer")}
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={state.dealerId}
                  onChange={(e) => onChange("dealerId", e)}
                >
                  <Option value=""> {traslate("Select Dealer")} </Option>
                  {dealerList.map((item, i) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " (" + item.dealerID + " )"}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            {loading ? (
              <Spin size="large" />
            ) : state.dealerId ? (
              <React.Fragment>
                <div className="col-lg-12">
                  <div className="chckbox__maindiv">
                    <div>
                      <input
                        type="checkbox"
                        id="dr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "diamondCharts")
                        }
                        checked={state.diamondCharts}
                      />
                      <label htmlFor="dr">{traslate("Diamond Reports")}</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="jr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "jewelryCharts")
                        }
                        checked={state.jewelryCharts}
                      />
                      <label htmlFor="jr">{traslate("Jewelry Reports")}</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="gdr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "globalDiamondCharts")
                        }
                        checked={state.globalDiamondCharts}
                      />
                      <label htmlFor="gdr">
                        {traslate("Global Diamond Reports")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="gjr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "globalJewelryCharts")
                        }
                        checked={state.globalJewelryCharts}
                      />
                      <label htmlFor="gjr">
                        {traslate("Global Jewelry Reports")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-4">
                  <Button
                    className="primary-btn"
                    loading={submitLoading}
                    onClick={handleSaveReportPermissions}
                  >
                    {traslate("Submit")}
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportsPermissions;
