import CryptoJS from "crypto-js";

export default function useDecryptData() {
  const secretKey = "gemfind_react_site";

  function DecryptData(token) {
    const bytes = CryptoJS.AES.decrypt(token, secretKey.substring(0, 16));
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
  return DecryptData;
}
