import React, { memo } from "react";
import traslate from "../../../i18n/translate";

const ShoppingIntegrations = () => {
  return (
    <div className="shopintgrate__section">
      <div className="form__fields">
        <div className="col-md-12 mt-2">
          <h4 className="workarea__heading mt-0 mb-0">
            {" "}
            {traslate("Diamond Link Shopping Cart Configuration Instructions")}:{" "}
          </h4>
        </div>

        <div className="col-lg-12 mt-4">
          <p>
            {" "}
            {traslate(
              "We will be sending parameters to the page on your website as below url"
            )}{" "}
          </p>
          <a target="_blank" className="link__txt" style={{ cursor: "text" }}>
            http://www.yourwebsite.com/shoppingcart.aspx?DealerID=&DiamondID=
            {"<"} SelectedDiamond ID {">"}
          </a>
          <p className="mt-4"> {traslate("Example Url")}: </p>
          <a target="_blank" className="link__txt" style={{ cursor: "text" }}>
            http://www.yourwebsite.com/shoppingcart.aspx?DealerID=3030&DiamondID=38267238
          </a>
          <p className="subheading mt-4">
            {" "}
            {traslate("To Get Diamond Detail")}:{" "}
          </p>
          <a
            href="http://www.gemfind.net/getdiamondinfo.asmx?op=FetchData"
            target="_blank"
            className="link__txt"
          >
            {" "}
            {traslate("Click Here")}{" "}
          </a>{" "}
          {traslate(
            "to access API to get diamond detail and checkout how it works online."
          )}
          <p className="subheading mt-4">
            {" "}
            {traslate(
              "Below is the list of parameters with respective possible values which can be supplied to each parameter."
            )}{" "}
          </p>
          <p className="mt-4">
            <span className="headred__txt"> {traslate("DiamondId")}:- </span>{" "}
            {traslate(
              "Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)"
            )}{" "}
          </p>
          <p className="mt-4">
            <span className="headred__txt"> {traslate("DealerLink")}:- </span>{" "}
            {traslate(
              "Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."
            )}{" "}
          </p>
          <p className="mt-4">
            {" "}
            {traslate(
              "You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website."
            )}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(ShoppingIntegrations);
