import {
  LOGIN_SUBMIT,
  SET_LOGIN_TOKEN,
  SET_IMPERSONATE_DATA,
  SET_NEWLOGIN_DATA,
  SET_ADMINLOGIN_DATA,
  RESET_STATE,
  SET_CURRENCY_SIGN,
  SET_CURRENCY,
  SET_WHITE_LABELING_DATA
} from "../../actions/login/types";
const initialState = {
  loginDetials: null,
  impresonateData: null,
  currency: null,
  currencySign:null,
  whiteLebelData:[],
};
function loginReducer(loginState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUBMIT:
      return { ...loginState, loginDetials: payload };
    case SET_LOGIN_TOKEN:
      return { ...loginState, loginDetials: payload, impresonateData: null };
    case SET_IMPERSONATE_DATA:
      return { ...loginState, impresonateData: payload };
    case SET_NEWLOGIN_DATA:
      return { ...loginState, loginDetials: payload, impresonateData: null };
    case SET_ADMINLOGIN_DATA:
      return { ...loginState, loginDetials: payload, impresonateData: null };
    case RESET_STATE:
      return { ...loginState, loginDetials: null, impresonateData: null };
    case SET_CURRENCY:
      return { ...loginState, currency: payload, impresonateData: null };
    case SET_CURRENCY_SIGN:
      return { ...loginState, currencySign: payload, impresonateData: null };
    case SET_WHITE_LABELING_DATA:
        return { ...loginState, whiteLebelData: payload, impresonateData: null };
    default:
      return loginState;
  }
}
export default loginReducer;
