import React, { useState, useEffect, useRef } from "react";
import { Select, Table, Modal, Spin } from "antd";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ReportJewelryReportService from "../../../services/reports-Jewelry-service";
import moment from "moment";
import DefaultImage from "../../../assets/images/icons/sampleimage.jpg";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  SelectedDiamondValue: "All",
  price: "",
  price1: "",
  price2: "",
};

const DiamondPriceBasedAddToCartReports = () => {
  const JewelPriceChartRef = useRef();
  const { Option } = Select;

  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [JewelryPriceTableList, setJewelryPriceTableList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [defaultVisible, setDefaultVisible] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [priceRange, setPriceRange] = useState([]);
  const [topPriceTotal, setPriceTotal] = useState([]);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [key, setKey] = useState(true);
  const [sortColumnName, setSortColumnName] = useState("AK.Date");
  const [sortType, setSortType] = useState("desc");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
  }, []);

  useEffect(() => {
    if (selectedData.price1)
      handleGetTableChartData(dealerId, selectedData.forDealer);
  }, [selectedData]);

  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id,
      };
      setShowLoader(true);
      ReportJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer,
            }));

            handleGetPriceColumnData(id, responseData.forDealer);
            handleGetTableChartData(
              id,
              responseData.forDealer,
              pageNo,
              pageSize,
              sortColumnName,
              sortType,
              "Empty_Price"
            );
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPriceColumnData = (DealerId, forDealerId) => {
    if (
      loginDetials.loginDetials.responseData.dealerMemberTypeName ===
      "VendorDiamond"
    ) {
      forDealerId = 1;
    }
    try {
      const initialEventState = {
        dealerID: DealerId.toString(),
        forDealer: forDealerId.toString(),
        fromDate: selectedData.fromDate
          ? moment(selectedData.fromDate).format("YYYY-MM-DD")
          : "2016-03-07",
        toDate: selectedData.toDate
          ? moment(selectedData.toDate).format("YYYY-MM-DD")
          : "2023-04-06",
        selectedDiamondValue: selectedData.SelectedDiamondValue
          ? selectedData.SelectedDiamondValue.toString()
          : "All",
        addToType: "AddToCart",
      };
      setShowLoader(true);
      ReportsDiamondService.AddToCartOrWishlistChartColunmData(
        initialEventState
      ).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData.PriceBasedData;

        if (message === "Success") {
          let priceRange = [];
          let total = [];
          let showResp = responseData.every((element) => {
            return element.TOTAL == "0";
          });
          setShowChart(showResp);
          for (let i of responseData) {
            if (i.TOTAL !== 0) {
              priceRange.push(i.Range);
              total.push(i.TOTAL);
            }
          }

          var JewelVendorYAxisList = responseData.map((item) => {
            return parseInt(item.TOTAL);
          });
          var totalValueForPercentage = JewelVendorYAxisList.reduce(
            (a, b) => a + b,
            0
          );
          setTotalDiamondClicks(
            response.data.responseData.TotalDiamondClicks[0].TotalClickCnt
          );
          setTotalValueForPercentage(totalValueForPercentage);
          setPriceTotal(total);
          setPriceRange(priceRange);
          setShowLoader(false);
          setKey(!key);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  const [totalCount, setTotalCount] = useState(0);
  // For Chart Data
  const handleGetTableChartData = (
    DealerId,
    forDealerId,
    pageNumber,
    pageSiz,
    sortColumnName,
    sortOrder,
    priceValueEmpty
  ) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealerId = 1;
      }
      let inputData = {
        dealerID: DealerId.toString(),
        forDealer: forDealerId ? forDealerId.toString() : "",
        fromDate: selectedData.fromDate
          ? moment(selectedData.fromDate).format("YYYY-MM-DD")
          : "2016-03-07",
        toDate: selectedData.toDate
          ? moment(selectedData.toDate).format("YYYY-MM-DD")
          : "2023-04-06",
        clarity: "",
        cutGrade: "",
        shape: "",
        color: "",
        size: "",
        certificate: "",
        selectedDiamondValue: selectedData.SelectedDiamondValue
          ? selectedData.SelectedDiamondValue.toString()
          : "All",
        pageNumber: pageNumber ? pageNumber : pageNo,
        pageSize: pageSiz ? pageSiz : pageSize,
        sortOrderCol: sortColumnName ? sortColumnName : sortColumnName,
        sortOrderType: sortOrder ? sortOrder : sortType,
        addToType: "AddToCart",
        price: priceValueEmpty ? "" : selectedData.price,
        price1: priceValueEmpty ? "" : selectedData.price1.trim().toString(),
        price2: priceValueEmpty ? "" : selectedData.price2.trim().toString(),
        vendorIds: "",
      };

      setShowTableLoader(true);
      ReportsDiamondService.AddToCartOrWishlistReportForTableChart(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.tableChartList;
          if (message === "Success") {
            setTotalCount(response?.data?.responseData?.totalCount || 0);
            setJewelryPriceTableList(responseData);
            setShowTableLoader(false);
          } else {
            setTotalCount(0);

            setJewelryPriceTableList([]);
            setShowTableLoader(false);
          }
        })
        .catch((error) => {
          setTotalCount(0);
          setShowTableLoader(false);
          console.log(error);
        });
    } catch (error) {
      setTotalCount(0);
      console.log(error);
      setShowTableLoader(false);
    }
  };

  const handleApplyFilter = () => {
    setSelectedData((prevState) => ({
      ...prevState,
      price: "",
      price1: "",
      price2: "",
    }));
    handleGetForDealer(dealerId);

    setIsApplyFilter(!isApplyFilter);
  };

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(),
        toDate: moment.utc(dates[1]).toISOString(),
      }));
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    setSelectedData({
      ...selectedData,
      [name]: e,
    });
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "DESC";
      column = "dealerId";
      setSortColumnName(column);
      setSortType(type);
    }

    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);

    handleGetTableChartData(
      dealerId,
      selectedData.forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="form__fields">
          <div className="row magento__mapping">
            <div className="col-md-12">
              <div className="heading__block">
                <h4 class="workarea__heading mt-0 mb-0">
                  {traslate("Diamond Price Based Add To Cart Report")}
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="input__block datepicker__block">
                <label> {traslate("Date Range")} </label>
                <RangePicker
                  className="date-range-input"
                  ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [
                      moment().subtract(1, "days"),
                      moment().subtract(1, "days"),
                    ],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "months").endOf("month"),
                    ],
                    "Last 90 Days": [moment().subtract(90, "days"), moment()],
                    "Year To Date": [moment().startOf("year"), moment()],
                  }}
                  format={"MM-DD-YYYY"}
                  onChange={onDateChange}
                  defaultValue={[moment().subtract(30, "days"), moment()]}
                  defaultPickerValue={[moment().subtract(30, "days"), moment()]}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Select Diamond")} </label>
                <Select
                  className="border__grey"
                  placeholder="All"
                  showSearch
                  name="SelectedDiamondValue"
                  optionFilterProp="children"
                  value={
                    selectedData.SelectedDiamondValue
                      ? selectedData.SelectedDiamondValue
                      : null
                  }
                  onChange={(e) =>
                    handleOnChange(e, "select", "SelectedDiamondValue")
                  }
                >
                  <Option value="All">All</Option>
                  <Option value="Mined">Mined Diamond</Option>
                  <Option value="Lab">Lab Grown Diamond</Option>
                </Select>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="input__block">
                <label>
                  <Spin spinning={showLoader} size="small">
                    <b>
                      {traslate("Total Clicks")} :{" "}
                      {TotalDiamondClicks ? TotalDiamondClicks : 0}
                    </b>
                  </Spin>
                </label>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="button__block mb-4">
                <button
                  className="primary-btn mt-2"
                  onClick={handleApplyFilter}
                >
                  {traslate("Apply Filter")}
                </button>
              </div>
            </div>
            {!showChart ? (
              <Spin spinning={showLoader}>
                <div className="col-lg-12">
                  <div className="chart__section">
                    {priceRange !== null &&
                      priceRange.length !== 0 &&
                      topPriceTotal.length !== 0 &&
                      JewelryPriceTableList &&
                      JewelryPriceTableList.length > 0 && (
                        <Pie
                          key={key}
                          onClick={(event) => {
                            const element = getElementAtEvent(
                              JewelPriceChartRef.current,
                              event
                            );
                            if (!element.length) return;

                            const { index } = element[0];

                            const price1 =
                              priceRange[index] == "Call for Price"
                                ? ""
                                : priceRange[index].includes("Under")
                                ? "0"
                                : priceRange[index].split("-")[0].split("$")[1];
                            const price2 =
                              priceRange[index] == "Call for Price"
                                ? ""
                                : priceRange[index].includes("Under")
                                ? priceRange[index].split("$")[1]
                                : priceRange[index].split("-").length > 1
                                ? priceRange[index].split("-")[1].split("$")[1]
                                : priceRange[index];
                            setSelectedData((prevState) => ({
                              ...prevState,
                              price:
                                priceRange[index] == "Call for Price"
                                  ? "Call for Price"
                                  : "",
                              price1: price1,
                              price2: price2,
                            }));
                          }}
                          ref={JewelPriceChartRef}
                          data={{
                            labels: priceRange,
                            datasets: [
                              {
                                data: topPriceTotal,
                                backgroundColor: [
                                  "rgb(14, 220, 232)",
                                  "rgb(16, 150, 24)",
                                  "rgb(255, 153, 0)",
                                  "rgb(153, 0, 153)",
                                  "rgb(0, 153, 198)",
                                  "rgb(51, 102, 204)",
                                  "rgb(220, 57, 18)",
                                  "rgb(102, 170, 0)",
                                  "rgb(221, 68, 119)",
                                  "rgb(237, 237, 1)",
                                ],
                                borderColor: [
                                  "rgb(14, 220, 232)",
                                  "rgb(16, 150, 24)",
                                  "rgb(255, 153, 0)",
                                  "rgb(153, 0, 153)",
                                  "rgb(0, 153, 198)",
                                  "rgb(51, 102, 204)",
                                  "rgb(220, 57, 18)",
                                  "rgb(102, 170, 0)",
                                  "rgb(221, 68, 119)",
                                  "rgb(237, 237, 1)",
                                ],
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={300}
                          height={300}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            plugins: {
                              datalabels: {
                                display: true,
                                align: "end",
                                color: "rgb(255, 255, 255)",
                                font: {
                                  size: 12,
                                },
                                formatter: (value) => {
                                  if (totalValueForPercentage) {
                                    let finalPercentage = (
                                      (value / totalValueForPercentage) *
                                      100
                                    ).toFixed(2);
                                    setTooltipPercentage(finalPercentage);
                                    if (finalPercentage >= 5) {
                                      return finalPercentage + "%";
                                    } else {
                                      return "";
                                    }
                                  }
                                },
                              },
                              tooltip: {
                                enabled: true,
                                callbacks: {
                                  label: function (context) {
                                    let label = context.label || "";
                                    let formattedValue =
                                      context.formattedValue || "";

                                    if (label) {
                                      label =
                                        label +
                                        " " +
                                        formattedValue +
                                        " (" +
                                        TooltipPercentage +
                                        "%" +
                                        ")";
                                    }
                                    return label;
                                  },
                                },
                              },
                              legend: {
                                position: "left",
                              },
                            },
                          }}
                        />
                      )}
                  </div>
                </div>
              </Spin>
            ) : (
              <Spin spinning={showLoader}>
                <div className="no-report-render">
                  <div className="desc-space">
                    <h4 className="text-center">{traslate("No Data Found")}</h4>
                  </div>
                </div>
              </Spin>
            )}
            <div className="col-lg-12 col-md-12 mt-4">
              <div className="table__scroll">
                <Table
                  className="pendjewelreq__tbl"
                  columns={[
                    {
                      title: traslate("UserIPAddress"),
                      dataIndex: "usersIPAddress",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Date"),
                      sorter: (a, b) => {},
                      dataIndex: "date",
                      render: (item, row) => {
                        return moment(row.date).format(
                          "MM-DD-YYYY, hh:mm:ss A"
                        );
                      },
                    },
                    {
                      title: traslate("Shape"),
                      dataIndex: "shape",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Size"),
                      dataIndex: "size",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Color"),
                      dataIndex: "color",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Cut"),
                      dataIndex: "cutGrade",
                      sorter: (a, b) => {},
                    },

                    {
                      title: traslate("Clarity"),
                      dataIndex: "clarity",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Certificate"),
                      dataIndex: "certificate",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Retail Price"),
                      dataIndex: "retailPrice",
                      sorter: (a, b) => {},
                    },
                    {
                      title: traslate("Cost"),
                      dataIndex: "cost",
                      sorter: (a, b) => {},
                    },
                  ]}
                  pagination={{
                    total: totalCount,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: pageNo,
                  }}
                  onChange={handleTableASCDES}
                  loading={showTableLoader}
                  dataSource={JewelryPriceTableList}
                  scroll={{ x: 600, y: 800 }}
                />
              </div>
            </div>
            <Modal
              title=""
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              width={500}
              footer={false}
            >
              <div className="">
                <img alt="img" className="img-fluid" />
              </div>
            </Modal>

            <Modal
              title=""
              centered
              visible={defaultVisible}
              onOk={() => setDefaultVisible(false)}
              onCancel={() => setDefaultVisible(false)}
              width={500}
              footer={false}
            >
              <div className="">
                <img src={DefaultImage} alt="img" className="img-fluid" />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondPriceBasedAddToCartReports;
