import React, { useState, useEffect, useRef } from "react";
import { Select, Button, Table, Spin } from "antd";
import { Doughnut, Bar } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { DatePicker, Tooltip } from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const DiamondReportsChartsIndComp = () => {
  const chartRef = useRef("");
  const chartRefPie = useRef("");
  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: []
  };
  /*Initial State for Binding Ends*/

  /*React Binding Starts*/
  const [TableChartList, setTableChartList] = useState([]);
  const [TableDiamondChartList, setTableDiamondChartList] = useState([]);
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TableForDiamChartLoading, setTableForDiamChartLoading] =
    useState(false);
  const [TableChartLoading, setTableChartLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondReportsChartLabels, setDiamondReportsChartLabels] = useState(
    []
  );
  const [DiamondReportsChartList, setDiamondReportsChartList] = useState([]);
  const [ReportsDiamondChartColorData, setReportsDiamondChartColorData] =
    useState([]);
  const [DiamondReportsChartYAxisList, setDiamondReportsChartYAxisList] =
    useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [colorData, setColorData] = useState([]);
  const [downloadCSVDataloading, setDownloadCSVDataloading] = useState(false);
  const [downloadPDFDataloading, setDownloadPDFDataloading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [EventsCount, setEventsCount] = useState(0);
  const [TotalEventsCount, setTotalEventsCount] = useState(0);
  const [sortType, setSortType] = useState("desc");
  const [sortColumnName, setSortColumnName] = useState("GT.MODIFIED");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    //
    if (dates) {
      //
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: moment.utc(dates[0]).toISOString(),
        dataRangeTo: moment.utc(dates[1]).toISOString()
      }));
    }
  };
  /*DatePicker Binding Ends*/
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
    chartDataHandler(ReportsDiamondChartColorData);
  }, []);

  function chartDataHandler(arr) {
    const color = [];
    for (let i = 0; i < arr.length; i++) {
      color.push("#" + Math.floor(Math.random() * 16777215).toString(16));
    }
    setColorData(color);
  }
  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id
      };

      ReportsDiamondService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            handleGetDiamondColumnChartData(id, forDealer);
            handleGetDiamondReportForTableChart(id, forDealer);
            handleDimondChart_SortingBindData(
              id,
              forDealer,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleGetDiamondColumnChartData = (dealerId, forDealer) => {
    if (
      loginDetials.loginDetials.responseData.dealerMemberTypeName ===
      "VendorDiamond"
    ) {
      forDealer = 1;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? globalDiamonds.dataRangeFrom
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? globalDiamonds.dataRangeTo
          : "2022-09-15",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue
      };
      setLoadChartReportsLoading(true);
      ReportsDiamondService.GetDiamondColumnChartData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondReportsChartList = responseData.Table;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setTotalDiamondClicks(TotalDiamondClicks);
              setDiamondReportsChartList(DiamondReportsChartList);
              var DiamondReportsChartLabelArr = DiamondReportsChartList.map(
                (item) => {
                  return item.DealerCompany;
                }
              );
              var DiamondReportsChartYAxisList = DiamondReportsChartList.map(
                (item) => {
                  return item.count;
                }
              );
              var totalValueForPercentage = DiamondReportsChartYAxisList.reduce(
                (a, b) => a + b,
                0
              );
              var ReportDiamondChartColorList = DiamondReportsChartList.map(
                (item) => {
                  return item.DealerCompany;
                }
              );
              setTotalValueForPercentage(totalValueForPercentage);
              setDiamondReportsChartLabels(DiamondReportsChartLabelArr);
              setDiamondReportsChartYAxisList(DiamondReportsChartYAxisList);
              ReportDiamondChartsColorHandler(ReportDiamondChartColorList);
              chartDataHandler(ReportDiamondChartColorList);
              setKey(!key);
            }
          } else {
            setTotalDiamondClicks(0);
            setDiamondReportsChartList([]);
            setDiamondReportsChartYAxisList([]);
          }
        })
        .catch((error) => {
          setTotalDiamondClicks(0);
          setDiamondReportsChartList([]);
          setLoadChartReportsLoading(false);
          setDiamondReportsChartYAxisList([]);
          setDiamondReportsChartLabels([]);
        });
    } catch (error) {
      setTotalDiamondClicks(0);
      setDiamondReportsChartList([]);
      setLoadChartReportsLoading(false);
      setDiamondReportsChartYAxisList([]);
      setDiamondReportsChartLabels([]);
    }
  };

  const handleGetDiamondReportForTableChart = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue
      };
      setTableChartLoading(true);
      ReportsDiamondService.GetDiamondReportForTableChart(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var TableChartList = responseData.dtData;
          setTableChartLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              if (TableChartList.length > 0) {
                setTableChartList(TableChartList);
              }
            }
          } else {
            setTableChartList([]);
          }
        })
        .catch((error) => {
          setTableChartList([]);
          setTableChartLoading(false);
        });
    } catch (error) {
      setTableChartList([]);
      setTableChartLoading(false);
    }
  };

  const handleDimondChart_SortingBindData = (
    dealerId,
    forDealer,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId,
        forDealer: forDealer ? forDealer.toString() : "",
        orderBy: "",
        pagging: 0,
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        lastAccessDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        noOfRows: 0,
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        pageNumber: pageNo ? pageNo : 1,
        pageSize: pageSize ? pageSize : 10,
        sortOrderCol: sortColumnName || "GT.MODIFIED",
        sortOrderType: sortOrder || "DESC"
      };
      setTableForDiamChartLoading(true);
      ReportsDiamondService.DimondChart_SortingBindData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var TableDiamondChartList = responseData.TableData?.Table;
          var TotalEventsCount =
            responseData.TableData?.Table.length > 0
              ? responseData.TableData.Table[0].TotalCount
              : 0;
          setTotalCount(TotalEventsCount);
          setTotalEventsCount(TotalEventsCount);
          setTableForDiamChartLoading(false);
          setKey(!key);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setTableDiamondChartList(TableDiamondChartList);
              setEventsCount(TotalEventsCount > 0 ? pageSize : 0);
              setTotalEventsCount(TotalEventsCount);
            }
          } else {
            setTotalCount(0);
            setTableDiamondChartList([]);
            setEventsCount(0);
            setTotalEventsCount(0);
          }
        })
        .catch((error) => {
          setTotalCount(0);
          setTableDiamondChartList([]);
          setTableForDiamChartLoading(false);
        });
    } catch (error) {
      setTotalCount(0);
      setTableDiamondChartList([]);
      setTableForDiamChartLoading(false);
    }
  };

  const handleExportToCSV = (forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        hdnFromDate: globalDiamonds.dataRangeFrom,
        hdnToDate: globalDiamonds.dataRangeTo,
        hdnPaggingRun: "",
        hdnForDealer: forDealer.toString(),
        hdnRetailerFilterId: "",
        hdnOrderBy: "",
        dealerCOMPANY: "",
        checkVendorMembership: "",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue
      };
      setDownloadCSVDataloading(true);
      ReportsDiamondService.ExportToCSV(inputData)
        .then((response) => {
          setDownloadCSVDataloading(false);
          var fileName =
            "GemFind_DiamondChartReports_" +
            new Date().toJSON().slice(0, 10).split("-").reverse().join("/") +
            ".csv";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          setDownloadCSVDataloading(false);
          console.log(error);
        });
    } catch (error) {
      setDownloadCSVDataloading(false);
      console.log(error);
    }
  };

  const handleExportToPDF = (forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId,
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        lastAccessDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        noOfRows: 0,
        orderBy: "",
        pageNumber: 1,
        pageSize: 10,
        pagging: 0,
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        sortOrderCol: "GT.MODIFIED",
        sortOrderType: "ASC"
      };
      setDownloadPDFDataloading(true);
      ReportsDiamondService.ExportTableDataToPDF(inputData)
        .then((response) => {
          setDownloadPDFDataloading(false);
          var fileName =
            "Chart" +
            new Date().toJSON().slice(0, 10).split("-").reverse().join("/") +
            ".pdf";
          BlobToDownloadFile(response.data, fileName, "pdf");
        })
        .catch((error) => {
          setDownloadCSVDataloading(false);
          console.log(error);
        });
    } catch (error) {
      setDownloadCSVDataloading(false);
      console.log(error);
    }
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "DESC";
      column = "GT.MODIFIED";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleDimondChart_SortingBindData(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*API Binding Ends*/

  const ReportDiamondChartsColorHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(224, 68, 14)");
    }
    setReportsDiamondChartColorData(color);
  };
  const handleOnChange = (e, isSelect, name) => {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e
    });
  };

  const handleApplyFilter = () => {
    handleGetDiamondColumnChartData(dealerId, forDealer);
    handleGetDiamondReportForTableChart(dealerId, forDealer);
    handleDimondChart_SortingBindData(
      dealerId,
      forDealer,
      pageNo,
      pageSize,
      sortColumnName,
      sortType
    );
  };
  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="row magento__mapping">
          <div className="col-lg-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {""}
                {traslate("Diamond Charts")}
                {""}
              </h4>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label>
                {""} {traslate("Date Range")} {""}{" "}
              </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                className="border__grey"
                placeholder="All"
                showSearch
                name="SelectedDiamondValue"
                optionFilterProp="children"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All">{traslate("All")}</Option>
                <Option value="Mined">{traslate("Mined Diamond")}</Option>
                <Option value="Lab">{traslate("Lab Grown Diamond")}</Option>
              </Select>
            </div>
          </div>

          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <b>
                  {" "}
                  {traslate("Total Clicks")} : {TotalEventsCount}{" "}
                </b>
              </label>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="chart__section">
            <Spin spinning={LoadChartReportsLoading} size="large">
              <Bar
                key={key}
                ref={chartRef}
                data={{
                  labels: diamondReportsChartLabels,
                  datasets: [
                    {
                      label: "Count",
                      data: DiamondReportsChartYAxisList,
                      backgroundColor: ReportsDiamondChartColorData,
                      borderColor: ReportsDiamondChartColorData,
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  scales: {
                    x: {
                      title: {
                        color: "rgb(66, 66, 66)",
                        display: true,
                        text: "Connected Vendor",
                        font: {
                          size: 15
                        }
                      }
                    },
                    y: {
                      title: {
                        color: "rgb(66, 66, 66)",
                        display: true,
                        text:
                          "Diamond Clicks " +
                          "(" +
                          initialGlobalDiamondState.dataRangeFrom +
                          " - " +
                          initialGlobalDiamondState.dataRangeTo +
                          ")",
                        font: {
                          size: 11
                        }
                      }
                    }
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  onClick: function (evt, element) {},
                  plugins: {
                    datalabels: {
                      display: false
                    },
                    title: {
                      display: true,
                      text: `Diamond Clicks for ${loginDetials?.loginDetials?.responseData?.dealerCompany} (${dealerId})`
                    }
                  },
                  tooltip: {
                    enabled: true,
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";
                        return label;
                      }
                    }
                  }
                }}
              />
            </Spin>
          </div>

          <div className="chart__section">
            <Spin spinning={LoadChartReportsLoading} size="large">
              {DiamondReportsChartList.length > 0 ? (
                <Doughnut
                  key={key}
                  ref={chartRefPie}
                  data={{
                    labels: diamondReportsChartLabels,
                    datasets: [
                      {
                        data: DiamondReportsChartYAxisList,
                        backgroundColor: colorData.length > 0 ? colorData : "",
                        borderColor: colorData.length > 0 ? colorData : "",
                        borderWidth: 1
                      }
                    ]
                  }}
                  width={300}
                  height={300}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                      datalabels: {
                        display: true,
                        align: "middle",
                        color: "rgb(255, 255, 255)",
                        font: {
                          size: 12
                        },
                        formatter: (value) => {
                          if (totalValueForPercentage) {
                            let finalPercentage = (
                              (value / totalValueForPercentage) *
                              100
                            ).toFixed(2);
                            setTooltipPercentage(finalPercentage);
                            if (finalPercentage >= 5) {
                              return finalPercentage + "%";
                            } else {
                              return "";
                            }
                          }
                        }
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {
                            let label = context.label || "";
                            let formattedValue = context.formattedValue || "";
                            if (label) {
                              label =
                                label +
                                " " +
                                formattedValue +
                                " (" +
                                TooltipPercentage +
                                "%" +
                                ")";
                            }
                            return label;
                          }
                        }
                      },
                      legend: {
                        position: "left"
                      }
                    }
                  }}
                />
              ) : (
                <div className="no-report-render">
                  <div className="desc-space">
                    <h4 className="text-center">{traslate("No Data Found")}</h4>
                  </div>
                </div>
              )}
            </Spin>
          </div>

          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {traslate("Top Diamond Attribute Values")}
            </h4>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              loading={TableChartLoading}
              columns={[
                {
                  title: traslate("Date"),
                  dataIndex: "Date"
                },
                {
                  title: traslate("Shape"),
                  dataIndex: "shape"
                },
                {
                  title: traslate("Size"),
                  dataIndex: "Size"
                },
                {
                  title: traslate("Color"),
                  dataIndex: "Color"
                },
                {
                  title: traslate("Cut"),
                  dataIndex: "CutGrade"
                },
                {
                  title: traslate("Clarity"),
                  dataIndex: "Clarity"
                },
                {
                  title: traslate("Price"),
                  dataIndex: "Price",
                  render: (row, item) => {
                    return (
                      <React.Fragment>
                        <span>
                          {item.Price ? (
                            <span>{item.Price}</span>
                          ) : (
                            "Call For Price"
                          )}
                        </span>
                      </React.Fragment>
                    );
                  }
                }
              ]}
              dataSource={TableChartList}
              scroll={{ x: 600, y: 800 }}
            />
          </div>

          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {traslate("Recent Diamond Attribute Searches")}
            </h4>
          </div>

          <div className="limit_section">
            <div className="col-lg-12 mt-3">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-4 rules__div">
                    <span className="rules__txt">
                      {" "}
                      <span className="rules__txtno">
                        {EventsCount} / {TotalEventsCount}
                      </span>{" "}
                      {traslate("Events")}
                    </span>
                  </div>
                  <div className="col-lg-8 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() => handleExportToPDF(forDealer)}
                      loading={downloadPDFDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i>{" "}
                      {traslate("Download PDF")}
                    </Button>
                    <Button
                      className="primary-btn"
                      onClick={() => handleExportToCSV(forDealer)}
                      loading={downloadCSVDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i>{" "}
                      {traslate("Download CSV")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              loading={TableForDiamChartLoading}
              columns={[
                {
                  title: traslate("IPAddress"),
                  dataIndex: "UsersIPAddress",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.UsersIPAddress).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.UsersIPAddress}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Stock #"),
                  dataIndex: "DealerStockNo",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.DealerStockNo).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.DealerStockNo}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Dealer Company"),
                  dataIndex: "DealerCompany",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.DealerCompany).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.DealerCompany}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Shape"),
                  dataIndex: "Cut",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.Cut).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.Cut}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Size"),
                  dataIndex: "Size",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.Size).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.Size}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Cut"),
                  dataIndex: "CutGrade",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    if (Object.keys(item.CutGrade).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.CutGrade}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Color"),
                  dataIndex: "Color",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.Color).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.Color}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Clarity"),
                  dataIndex: "Clarity",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //
                    if (Object.keys(item.Clarity).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.Clarity}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Cert"),
                  dataIndex: "Certificate",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    if (Object.keys(item.Certificate).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>{item.Certificate}</span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Price"),
                  dataIndex: "WholesalePrice",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    if (Object.keys(item.WholesalePrice).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>
                            {item.WholesalePrice ? (
                              <span>{item.WholesalePrice} </span>
                            ) : (
                              "Call For Price"
                            )}
                          </span>
                        </React.Fragment>
                      );
                    }
                  }
                },
                {
                  title: traslate("Day & Time"),
                  dataIndex: "Modified",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    if (Object.keys(item.Modified).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>
                            {moment(item.Modified).format(
                              "MM-DD-YYYY, hh:mm:ss A"
                            )}
                          </span>
                        </React.Fragment>
                      );
                    }
                  }
                }
              ]}
              dataSource={TableDiamondChartList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondReportsChartsIndComp;
