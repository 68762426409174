import axios from "axios";
import config from "./config";
import { Cookies } from "react-cookie";
import useDecryptData from "../utils/DecryptData";

function GetDecryptToken() {
  const cookies = new Cookies();
  const DecryptData = useDecryptData();
  let EncryptToken = cookies.get("token");
  let finalToken = DecryptData(EncryptToken);
  return finalToken;
}

const addInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const headerToken = GetDecryptToken();
      if (headerToken) {
        config.headers["X-Authorized-Token"] = headerToken;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
};

export const httpWithoutToken = axios.create({
  baseURL: config.AdminAPIURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  }
});
export const http = axios.create({
  baseURL: config.AdminAPIURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  }
});
export const httpDealer = axios.create({
  baseURL: config.DealerAPIURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});
export const httpCatalog = axios.create({
  baseURL: config.CatalogAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});
export const httpApps = axios.create({
  baseURL: config.AppsAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});
export const httpCommunity = axios.create({
  baseURL: config.CommunityAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});

export const httpPurchaseOrder = axios.create({
  baseURL: config.PurchaseOrderAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});

export const httpManageUpload = axios.create({
  baseURL: config.MarketingUploadAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});

export const httpAdminReport = axios.create({
  baseURL: config.AdminReportURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});

export const httpAdminCompanyLocation = axios.create({
  baseURL: config.AdminAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*"
  }
});

[
  http,
  httpDealer,
  httpCatalog,
  httpApps,
  httpCommunity,
  httpPurchaseOrder,
  httpManageUpload,
  httpAdminReport,
  httpAdminCompanyLocation
].forEach(addInterceptors);
