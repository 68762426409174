import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";

import traslate from "../../../i18n/translate";

import { Accordion } from "react-bootstrap";

const WatchCustomizer = () => {
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [loading, setLoading] = useState(true);
  if (loginDetials) {
    if (loginDetials.responseData) {
      if (loginDetials.responseData.logInSucceeded) {
      }
    }
  }

  // Mgr  Watch Customizer
  // const src = `https://jc2-wtchcustmizer.mgr.brainvire.net/?DealerLink=${loginDetials.responseData.dealerId}&Track=0`;

  const src = `https://jc2-wtchcustmizer.mgr.brainvire.net/?DealerLink=${loginDetials.responseData.dealerId}&Track=0`;
  const hideSpinner = () => {
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <iframe
          onLoad={() => hideSpinner()}
          width="100%"
          className="application__preview"
          //height="1000px"
          src={src}
        ></iframe>
      </Spin>
    </React.Fragment>
  );
};

export default WatchCustomizer;
