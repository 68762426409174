import React, { useState, useEffect } from "react";
import {
  Select,
  Button,
  Modal,
  Table,
  Spin,
  Popconfirm,
  Pagination,
} from "antd";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import { DownloadFile } from "../../../helper/commanFunction";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import Create_icon from "../../../assets/images/create_icon.png";
import { NotificationManager } from "react-notifications";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import AdminToolsDataService from "../../../services/admin-tools.service";

const MailingLists = () => {
  const { Option } = Select;
  const [mailingList, setmailingList] = useState([]);
  const [mailingCount, setmailingCount] = useState(0);
  const [recipientList, setrecipientList] = useState([]);
  const [recipientCount, setrecipientCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fileName, setFileName] = useState("MailingLists.xlsx");
  const [fileNameRecipients, setFileNameRecipients] =
    useState("Recipients.xlsx");
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [mailingListData, setMailingListData] = useState([]);
  const [emailData, setEmailData] = useState({
    emailAddress: "",
    name: "",
  });
  const [groupData, setGroupData] = useState({
    groupName: "",
    members: "",
  });
  const [recepientLoader, setRecepientLoader] = useState(false);
  const [recepientData, setRecepientData] = useState({
    name: "",
    address: "",
    client: "",
  });
  const [mailingLoader, setMailingLoader] = useState(false);
  const [recepientListLoader, setRecepientListLoader] = useState(false);
  const [recepientListData, setRecepientListData] = useState({
    address: "",
    name: "",
    MailingListID: "",
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [totalGroupCount, setTotalGroupCount] = useState(0);
  const [error, setError] = useState({
    GroupName: "",
    Members: "",
  });
  const [membersList, setMembersList] = useState([]);
  const [membersListLoading, setMembersListLoading] = useState(false);
  const [isEditGroup, setIsEditGroup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const initialState = {
    clientTypeList: [
      {
        id: 1,
        value: "",
        label: "All"
      },
      {
        id: 2,
        value: "vendordiamond",
        label: "Diamond Dealers"
      },
      {
        id: 3,
        value: "retailer",
        label: "Retailers"
      },
      {
        id: 4,
        value: "vendor",
        label: "Vendors"
      }
    ],
    clientType: "", 
  };

  const [state, setState] = useState(initialState);
  const ManageCollectionDetails = useSelector(
    (state) => state.manageCollectionReducer
  );
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          // handleGetMailingListDropdownData(
          //   loginDetials.loginDetials.responseData.dealerId
          // );
          // handleGetMailingList(loginDetials.loginDetials.responseData.dealerId);
          handleGetGroupList(pageNo, pageSize);
          handleGetUsersList(
            loginDetials.loginDetials.responseData.dealerId,
            ""
          );
        }
      }
    }
  }, []);

  const [recepientListCount, setRecepientListCount] = useState(0);

  // const handleOnChange = (e, select, name) => {
  //   // if (select == "Select") {
  //   //   setEmailData({
  //   //     ...emailData,
  //   //     [name]: e,
  //   //   });
  //   // } else {
  //   //   setEmailData({
  //   //     ...emailData,
  //   //     [e.target.name]: e.target.value,
  //   //   });
  //   // }
  //   if (select == "Select") {
  //     setGroupData({
  //       ...groupData,
  //       [name]: e,
  //     });
  //   } else {
  //     setGroupData({
  //       ...groupData,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };
  const handleOnChange = (e, select, name) => {
    if (select === "Select") {
      // If "All" is selected
      if (e.includes('all')) {
        // Set all members' dealerID in the selected value
        setGroupData({
          ...groupData,
          [name]: membersList.map((item) => item.dealerID), // Select all dealer IDs
        });
      } else {
        // Otherwise, just update the selected value normally
        setGroupData({
          ...groupData,
          [name]: e, // Set the current selection
        });
      }
    } else {
      // If the selection is from another type (not a Select dropdown)
      setGroupData({
        ...groupData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleGetUsersListByDealerId = async () => {
    let inputData = {
      dealerID: state.clientName
    };
    try {
      await AdminToolsDataService.GetUsersListByDealerId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              var tempState = state;
              tempState["clientToolsData"] = responseData[0];

              setState((prevState) => ({
                ...prevState,
                ...tempState
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange =  (value, name) => {
     setGroupData((prevGroupData) => ({
      ...prevGroupData,
      members: "", 
    }));
    setState((prevState) => ({
      ...prevState,
      [name]: Array.isArray(value) ? value : value,
    }));

    if (name === "clientType") {
      setState((prevState) => ({
        ...prevState,
        clientToolsData: null,
        clientName: null,
      }));
       handleGetUsersList(dealerId, value);   
    }

    if (name === "clientName") {
      setLoading(true);
      handleGetUsersListByDealerId();
    }

    // Persist data if needed (optional)
    localStorage.setItem("FilterValue", ManageCollectionDetails.filterValue);
    localStorage.setItem(
      "AllFilterValue",
      ManageCollectionDetails.allFilterValue
    );
  };

  const handleOnChangeRecepient = (e, select, name) => {
    if (select == "Select") {
      setRecepientData({
        ...recepientData,
        [name]: e,
      });
    } else {
      setRecepientData({
        ...recepientData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleCancel = () => {
    // setEmailData({
    //   emailAddress: "",
    //   name: "",
    // });
    // setVisible(false);
    setVisible(false);
    let tempData = groupData;
    tempData["groupName"] = "";
    tempData["members"] = ""; 
    setGroupData(tempData);
    setIsEditGroup(false);
    setState(prevState => ({
      ...prevState,
      clientType: "",
    }));
    handleGetUsersList(dealerId,state.clientType);
    

  };

  const handleCancel1 = () => {
    setRecepientData({
      address: "",
      name: "",
      client: "",
    });
    setVisible1(false);
  };

  const handleGetMailingList = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    AdminCampaignsService.GetMailingList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var data = JSON.parse(responseData.jsonData);
          var data1 = JSON.parse(responseData.tableData);
          var dataObj = data.items;
          var data1Obj = data1.operations;
          var merge = dataObj.concat(data1Obj);
          setmailingList(merge);
          if (recepientListCount == 0) {
            handleGetRecepientList(
              merge[0].address,
              merge[0].description,
              merge[0].MailingListID ? merge[0].MailingListID : 0,
              merge[0].members_count ? merge[0].members_count : 0
            );
            setRecepientListCount(1);
          }
          setmailingCount(merge.length);
        } else {
          setmailingList([]);
          setmailingCount(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleGetRecepientList = (Email, Name, Count, Number) => {
    let inputData = {
      address: Email,
      page: Number.toString(),
      mailingListID: Count,
      nextRecipientAddress: "",
    };
    setRecepientLoader(true);
    AdminCampaignsService.GetRecepientList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var data = JSON.parse(responseData.jsonData);
          var data1 = JSON.parse(responseData.tableData);
          if (responseData.jsonData) {
            var dataObj = data.items;
            var data1Obj = data1.operations;
            var merge = dataObj.concat(data1Obj);
          } else {
            var data1Obj = data1.operations;
            var merge = data1Obj;
          }
          setrecipientList(merge);
          setrecipientCount(merge.length);
          setRecepientLoader(false);
        } else {
          setrecipientList([]);
          setrecipientCount(0);
          setRecepientLoader(true);
        }
      })
      .catch((error) => {
        setRecepientLoader(true);
        console.log(error);
      })
      .finally(() => setRecepientLoader(true));
  };

  // Dropdown API
  const handleGetMailingListDropdownData = (id) => {
    let inputData = {
      dealerID: id,
    };
    AdminCampaignsService.GetMailingListDropdownData(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;

        if (message === "Success") {
          setMailingListData(responseData.mailingListData);
        } else {
          setMailingListData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add New Mail
  const handleCampaignAddMailingList = () => {
    let inputData = {
      dealerID: dealerId,
      mailingListID: 0,
      aliasEmailID: emailData.emailAddress,
      name: emailData.name,
    };
    setMailingLoader(true);

    AdminCampaignsService.CampaignAddMailingList(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          handleCancel();
          NotificationManager.success(traslate("Record Added Successfully."));
          handleGetMailingList(dealerId);
        } else {
          handleCancel();
          NotificationManager.error(traslate("Record Not Added."));
        }
      })
      .catch((error) => {
        console.log(error);
        setMailingLoader(false);
        setVisible(false);
      })
      .finally(() => {
        setVisible(false);
        setMailingLoader(false);
      });
  };

  const handleAddOrEdit = () => {
    const trimmedGroupName = groupData.groupName.trim();
    if (trimmedGroupName === "") {
      NotificationManager.error(traslate("Group Name cannot not be empty."));
      return;
    }
    setGroupData({
      ...groupData,
      groupName: trimmedGroupName,
    });
    if (isEditGroup) {
      let tempRow = selectedRow;
      tempRow["dealerGroupName"] = groupData.groupName;
      tempRow["groupDealers"] = groupData.members + "";
      handleCreateUpdateDeleteDealerGroups("edit", tempRow);
      setPageNo(1);
      setState(prevState => ({
        ...prevState,
        clientType: "",
      }));
      handleGetUsersListByDealerId();
    } else {
      handleCreateUpdateDeleteDealerGroups("add");
      setPageNo(1);
      setState(prevState => ({
      ...prevState,
      clientType: "",
    }));
    }
  };

  const handleCreateUpdateDeleteDealerGroups = (flagStatus, row) => {
    if (
      (groupData.groupName == "" || groupData.members == "") &&
      flagStatus !== "delete"
    ) {
      NotificationManager.error(traslate("Fields should not be empty"));
      return false;
    } else {
      let inputData = {
        dealerGroupID: row ? row.dealerGroupID : "",
        dealerGroupName: row ? row.dealerGroupName : groupData.groupName,
        dealerlist: row ? row.groupDealers : groupData.members + "",
        flag: flagStatus,
        dealerID: dealerId?.toString(),
        groupDealers: "",
        ClientType:state.clientType,
      };
      setSubmitLoading(true);

      AdminCampaignsService.CreateUpdateDeleteDealerGroups(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            if (response?.data?.responseData == "DealerGroup Already exists") {
              NotificationManager.error(response.data.responseData);
              setSubmitLoading(false);
            } else {
              NotificationManager.success(response.data.responseData);
              setSubmitLoading(false);
              handleGetGroupList(1, 10);
              setVisible(false);
              let tempData = groupData;
              tempData["groupName"] = "";
              tempData["members"] = "";
              setGroupData(tempData);
              setIsEditGroup(false);
            }
          } else {
            NotificationManager.error(message);
            setSubmitLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };

  // Add New Recepient
  const handleCampaignAddMailingRecipientList = () => {
    let inputData = {
      dealerID: dealerId,
      mailingListID: recepientData.name,
      recipientEmailID: recepientData.address,
      clientName: recepientData.client,
    };
    setRecepientListLoader(true);
    AdminCampaignsService.CampaignAddMailingRecipientList(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          handleCancel1();
          NotificationManager.success(traslate("Record Added Successfully."));
          handleGetRecepientList(
            recepientListData.address,
            recepientListData.name,
            recepientListData.MailingListID
          );
        } else {
          handleCancel1();
          NotificationManager.error(traslate("Record Not Added."));
        }
      })
      .catch((error) => {
        console.log(error);
        setRecepientListLoader(false);
        setVisible1(false);
      })
      .finally(() => {
        setRecepientListLoader(false);
        setVisible1(false);
      });
  };

  const handleDownloadMailList = () => {
    DownloadFile(mailingList, fileName);
  };
  const handleDownloadRecipients = () => {
    DownloadFile(recipientList, fileNameRecipients);
  };

  const handleOnRowClick = (record) => {
    handleGetRecepientList(
      record.address,
      record.name,
      record.MailingListID ? record.MailingListID : 0,
      record.members_count
    );
    setRecepientListData({
      address: record.address,
      name: record.name,
      MailingListID: record.MailingListID ? record.MailingListID : 0,
    });
  };

  const handleGetGroupList = (page, size) => {
    let inputData = {
      pageno: page,
      pagesize: size,
    };
    setTableLoading(true);
    AdminCampaignsService.GetDealerGroupsList(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          let list = response?.data?.responseData?.campaignDealerGroupslist;
          if (list) {
            setGroupList(list);
            setTableLoading(false);
            setTotalGroupCount(response?.data?.responseData?.totalrecords);
          }
        } else {
          NotificationManager.error(message);
          setTableLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setTableLoading(false);
      });
  };

  const handleGetUsersList = (id, type) => {
    let inputData = {
      clientType: type || "",
      dealerId: id,
    };
    setMembersListLoading(true);
    try {
      AdminToolsDataService.GetUsersList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setMembersList(responseData);
            setMembersListLoading(false);
          } else {
            setMembersList([]);
            setMembersListLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setMembersListLoading(false);
        });
    } catch (error) {
      console.log(error);
      setMembersListLoading(false);
    }
  };

  const handleEditGroup = (row) => {
    setVisible(true);
    setState(prevState => ({
      ...prevState,
      clientType: row.clientType,
    }));
    setGroupData({
      groupName: row.dealerGroupName,
      members: row.groupDealers ? row.groupDealers.split(",") : [],
    });
    setSelectedRow(row);
    setIsEditGroup(true);
  };

  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetGroupList(page, pageSize);
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetGroupList(current, size);
  }

  return (
    <React.Fragment>
      <div className="mailinglist__section magento__mapping">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading__block mb-0">
                <h4 className="workarea__heading mt-0 mb-0">
                  {" "}
                  {/* {traslate("Mailing Lists")}{" "} */}
                  {traslate("Group Lists")}{" "}
                </h4>
                <button
                  className="primary-btn"
                  onClick={() => setVisible(true)}
                >
                  {" "}
                  <img src={Create_icon} /> {traslate("Add")}
                </button>
              </div>
            </div>
            {/* <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="dwnld__div">
                <div>
                  <span className="mailist__txt">
                    <span className="no__txt">{mailingCount}</span>
                    {traslate("Mailing Lists")}
                  </span>
                </div>
                <div>
                  <button
                    className="primary-btn"
                    onClick={handleDownloadMailList}
                  >
                    {" "}
                    {traslate("Download")}
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-12 mt-4">
              <div className="mailist_tbldiv">
                <Spin spinning={loading}>
                  <Table
                    const
                    columns={[
                      {
                        title: traslate("Alias Address"),
                        dataIndex: "address",
                        key: "address",
                        fixed: "left",
                      },
                      {
                        title: traslate("Name"),
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: traslate("Number Of Recipients"),
                        dataIndex: "members_count",
                        width: 120,
                        key: "members_count",
                      },
                      {
                        title: traslate("Created"),
                        dataIndex: "created_at",
                        key: "created_at",
                        render: (item, row) => {
                          return (
                            <React.Fragment>
                              <span>
                                {row.created_at.replace("-0000", " ")}
                              </span>
                            </React.Fragment>
                          );
                        },
                      },
                    ]}
                    onRow={(record, recordIndex) => ({
                      onClick: (event) => {
                        handleOnRowClick(record);
                      },
                    })}
                    dataSource={mailingList}
                    scroll={{ x: 600, y: 800 }}
                    pagination={{
                      responsive: true,
                      showSizeChanger: true,
                    }}
                  />
                </Spin>
              </div>
            </div> */}

            {/* <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="dwnld__div">
                <div>
                  <span className="mailist__txt">
                    <span className="no__txt">{recipientCount}</span>{" "}
                    {traslate("Recipients")}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="mr-2">
                    <Button
                      className="primary-btn"
                      onClick={() => setVisible1(true)}
                    >
                      {" "}
                      <img src={Create_icon} /> {traslate("Add")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="primary-btn"
                      onClick={handleDownloadRecipients}
                      loading={submitLoading}
                    >
                      {" "}
                      {traslate("Download")}
                    </Button>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-12 mt-4">
              <div className="mailist_tbldiv">
                <Spin spinning={recepientLoader}>
                  <Table
                    const
                    columns={[
                      {
                        title: traslate("Recipient Address"),

                        dataIndex: "address",
                        key: "address",
                        fixed: "left",
                      },
                      {
                        title: traslate("Client"),
                        dataIndex: "name",
                        key: "name",
                      },
                    ]}
                    dataSource={recipientList}
                    scroll={{ x: 300, y: 800 }}
                  />
                </Spin>
              </div>
            </div> */}

            <div className="col-lg-12 mt-4">
              <div className="mailist_tbldiv">
                <Spin spinning={tableLoading}>
                  <Table
                    const
                    columns={[
                      {
                        title: traslate("Group name"),
                        dataIndex: "dealerGroupName",
                        key: "dealerGroupName",
                      },
                      {
                        title: traslate("Members"),
                        dataIndex: "dealerlist",
                        key: "dealerlist",
                      },
                      {
                        title: traslate("Actions"),
                        dataIndex: "save",
                        render: (text, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => handleEditGroup(row)}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <Popconfirm
                                title={traslate(
                                  "Are you sure you want to delete ?"
                                )}
                                onConfirm={() => {
                                  handleCreateUpdateDeleteDealerGroups(
                                    "delete",
                                    row
                                  );
                                  setPageNo(1);
                                }}
                                placement="left"
                              >
                                <img src={Delete_iconbl} alt="" />
                              </Popconfirm>
                            </div>
                          </div>
                        ),
                        width: 100,
                      },
                    ]}
                    dataSource={groupList}
                    scroll={{ x: 300, y: 800 }}
                    pagination={false}
                  />
                  <div className="col-md-12 mt-3" style={{ textAlign: "end" }}>
                    <Pagination
                      current={pageNo}
                      pageSize={pageSize}
                      total={totalGroupCount}
                      onChange={handlePageNoChange}
                      onShowSizeChange={handlePageSizeChange}
                      showSizeChanger="true"
                    />
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        title={traslate("Add New Email")}
        centered
        visible={visible}
        width={400}
        onOk={handleCampaignAddMailingList}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCampaignAddMailingList}
            loading={mailingLoader}
          >
            {traslate("Submit")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label> {traslate("Enter Alias Address")} </label>
                      <input
                        name="emailAddress"
                        type="text"
                        placeholder="Enter Alias Address"
                        value={emailData.emailAddress}
                        onChange={(e) => handleOnChange(e, "", "emailAddress")}
                      />
                    </div>
                    <div className="input__block">
                      <label> {traslate("Name")} </label>
                      <input
                        name="name"
                        type="text"
                        placeholder="Enter Name"
                        value={emailData.name}
                        onChange={(e) => handleOnChange(e, "", "name")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal
        title={
          isEditGroup ? traslate("Edit Group") : traslate("Create New Group")
        }
        centered
        visible={visible}
        width={400}
        onOk={handleAddOrEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddOrEdit}
            loading={submitLoading}
          >
            {traslate(isEditGroup ? "Save" : "Submit")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("Group Name")}
                        <span>*</span>{" "}
                      </label>
                      <input
                        name="groupName"
                        type="text"
                        placeholder="Enter group name"
                        value={groupData.groupName}
                        onChange={(e) => handleOnChange(e, "", "groupName")}
                      />
                    </div>
                    <div className="input__block">
                      <label>
                      {" "}
                      {traslate("Select Client Type")} <span>*</span>
                      </label>
                      <Select
                        showSearch
                        className="border__grey"
                        placeholder="Select Client Type"
                        optionFilterProp="children"
                        value={state.clientType}
                        onChange={(e) => {
                          onChange(e, "clientType");
                        }}
                      >
                        {state.clientTypeList.map((item, i) => {
                          return <Option value={item.value}>{item.label}</Option>;
                        })}
                      </Select>
                     </div>
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("Members")} <span>*</span>
                      </label>
                      {/* <Select
                        showSearch
                        className="border__grey"
                        placeholder="Select Members"
                        optionFilterProp="children"
                        value={groupData.members ? groupData.members : []}
                        onChange={(e) => {
                          handleOnChange(e, "Select", "members");
                        }}
                        loading={membersListLoading}
                        mode="multiple"
                        style={{ maxHeight: "none", height: "80px" }}
                      >
                        {membersList?.map((item) => {
                          return (
                            <Option value={item.dealerID}>
                              {item.dealerCompany + " (" + item.dealerID + ")"}
                            </Option>
                          );
                        })}
                      </Select> */}
                       <Select
                        showSearch
                        className="border__grey"
                        placeholder="Select Members"
                        optionFilterProp="children"
                        value={groupData.members ? groupData.members.map((id) => id.trim()) : []}
                        onChange={(e) => {
                          handleOnChange(e, "Select", "members");
                        }}
                        loading={membersListLoading}
                        mode="multiple"
                        style={{ maxHeight: "none", height: "80px" }}
                      >
                        <Option key="all" value="all">
                          All
                        </Option>
                        {membersList?.map((item) => (
                          <Option key={item.dealerID} value={item.dealerID}>
                            {item.dealerCompany + " (" + item.dealerID + ")"}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Recepient Modal  */}
      <Modal
        title={traslate("Add New Recipient")}
        centered
        visible={visible1}
        width={400}
        onOk={handleCampaignAddMailingRecipientList}
        onCancel={handleCancel1}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel1}>
            {traslate("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCampaignAddMailingRecipientList}
            loading={recepientListLoader}
          >
            {traslate("Submit")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label> {traslate("Name")} </label>
                      <Select
                        placeholder="Select Name"
                        value={recepientData.name}
                        name="name"
                        showSearch
                        optionFilterProp="children"
                        className="border__grey"
                        onChange={(e) =>
                          handleOnChangeRecepient(e, "Select", "name")
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {mailingListData &&
                          mailingListData.map((item, i) => {
                            return (
                              <Option value={item.mailingListID}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                    <div className="input__block">
                      <label> {traslate("Address")} </label>
                      <input
                        name="address"
                        type="text"
                        placeholder="Enter Address"
                        value={recepientData.address}
                        onChange={(e) =>
                          handleOnChangeRecepient(e, "", "address")
                        }
                      />
                    </div>
                    <div className="input__block">
                      <label> {traslate("Client")} </label>
                      <input
                        name="client"
                        type="text"
                        placeholder="Enter Client"
                        value={recepientData.client}
                        onChange={(e) =>
                          handleOnChangeRecepient(e, "", "client")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MailingLists;
