import React, { useState, useEffect } from "react";
import { Select, Button, Spin } from "antd";
import traslate from "../../../i18n/translate";
import heartImage from "../../../assets/images/heart_image.png";
import AdminReportDataService from "../../../services/admin-report.service";
import { DatePicker } from "antd";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import User_image from "../../../assets/images/User_image.png";
import shareImage from "../../../assets/images/share.png";
import { useNavigate } from "react-router-dom";
import {
  setChecklinkHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../../actions/headerMenu/headerMenu";
import {
  setContactPageNameType,
} from "../../../actions/Contact/Contact";
const { RangePicker } = DatePicker;

const { Option } = Select;

/*Initial State for Binding Starts*/
const initialSyncState = {
  client: "0",
  dataRangeFrom: moment().subtract(30, "days").format("MM/DD/YYYY"),
  dataRangeTo: moment().format("MM/DD/YYYY"),
  type: [1, 2, 3, 4, 5],
  status: [200, 500, 1]
};
/*Initial State for Binding Ends*/

const Syncs = () => {
  const [globalEvents, setGlobalEvents] = useState(initialSyncState);
  const [downloadDataloading, setDownloadDataloading] = useState(false);
  const [SyncsGridDataList, setSyncsGridDataList] = useState([]);
  const [SyncsClientList, setSyncsClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [EventsCount, setEventsCount] = useState(0);
  const [TotalEventsCount, setTotalEventsCount] = useState(0);
  const [datesList, setDatesList] = useState([]);
  //const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dashboardConversation, setDashboardConversation] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(0);
  const [dataLabelConversation, setDataLabelConversation] = useState(0);
  //const [dealerId, setDealerId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    // if(loginDetials){
    //   if(loginDetials.responseData){
    //       setDealerId(loginDetials.responseData.dealerId);
          handleDashBoardConservationDetailsList(0);
    //   }
    // }
    handleGetSyncsClientList();     
  }, []);

  const columns = [
    {
      title: traslate("Dealer ID"),
      dataIndex: "DealerID",
      width: 100
    },
    {
      title: traslate("Dealer Company"),
      dataIndex: "DealerCompany"
    },
    {
      title: traslate("Type"),
      dataIndex: "Type",
      width: 100
    },
    {
      title: traslate("Status"),
      dataIndex: "Status",
      width: 100
    },
    {
      title: traslate("Initiated"),
      dataIndex: "Initiated"
    },
    {
      title: traslate("Complete"),
      dataIndex: "Complete"
    }
  ];

  const data = [
    {
      key: "1",
      DealerID: <span>4055</span>,
      DealerCompany: <span>{traslate("316 Jewelry and Watch Boutique")}</span>,
      Type: <span>{traslate("Full")}</span>,
      Status: <span>{traslate("Failed")}</span>,
      Initiated: <span>03-22-2021 07:06 PM</span>,
      Complete: <span>-</span>
    },
    {
      key: "1",
      DealerID: <span>4055</span>,
      DealerCompany: <span>{traslate("316 Jewelry and Watch Boutique")}</span>,
      Type: <span>{traslate("Full")}</span>,
      Status: <span>{traslate("Failed")}</span>,
      Initiated: <span>03-22-2021 07:06 PM</span>,
      Complete: <span>-</span>
    },
    {
      key: "1",
      DealerID: <span>4055</span>,
      DealerCompany: <span>{traslate("316 Jewelry and Watch Boutique")}</span>,
      Type: <span>{traslate("Full")}</span>,
      Status: <span>{traslate("Failed")}</span>,
      Initiated: <span>03-22-2021 07:06 PM</span>,
      Complete: <span>-</span>
    }
  ];

  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalEvents((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1]
      }));
    }
  };

  /*DatePicker Binding Ends*/
  function onSearch(val) {}
  
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setGlobalEvents({
        ...globalEvents,
        [name]: e
      });
    } else {
      setGlobalEvents({
        ...globalEvents,
        [e.target.name]: e.target.value
      });
    }
  };

  /*API Binding Starts*/
  const handleDashBoardConservationDetailsList = async (id) => {
    let inputData = {
      dealerID: id,
      showdata: "x",
      limitcondition: 0,
      startDate: globalEvents.dataRangeFrom
        ? moment(globalEvents.dataRangeFrom).format("YYYY-MM-DD")
        : "2012-08-21",
      endDate: globalEvents.dataRangeTo
        ? moment(globalEvents.dataRangeTo).format("YYYY-MM-DD")
        : "2013-08-21",
    };
    setLoading(true);
    try {
      AdminReportDataService.GetGlobalSyncData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response?.data?.responseData?.data;
          var tlcount =response?.data?.responseData?.totalCount;
          var shcount=response?.data?.responseData?.shownCount;
          if (message === "Success") {
            if (responseData.length > 0) {
              setDashboardConversation(responseData);

              var tempDates = [
                ...new Set(
                  responseData.map(({ formatedDate }) => formatedDate)
                ),
              ];
              setDatesList(tempDates);
              setLoading(false);
              setButtonVisible(1);
              setDataLabelConversation(0);
              setEventsCount(shcount);
              setTotalEventsCount(tlcount);
            } else {
              setDashboardConversation([]);
              setDatesList([]);
              setLoading(false);
              setDataLabelConversation(1);
              setEventsCount(0);
              setTotalEventsCount(0);
            }
          } else {
            setDashboardConversation([]);
            setDatesList([]);
            setLoading(false);
            setDataLabelConversation(1);
            setEventsCount(0);
            setTotalEventsCount(0);

          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setDataLabelConversation(1);
          setEventsCount(0);
          setTotalEventsCount(0);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDataLabelConversation(1);
      setEventsCount(0);
      setTotalEventsCount(0);
    }
  };

  const handleloadMore = () => {
    setButtonVisible(0);
    handleDashBoardConservationDetailsListLoadMore();
  };
  const handleDashBoardConservationDetailsListLoadMore = async (id) => {
    let inputData = {
      dealerID: parseInt(globalEvents.client, 10),
      showdata: "x",
      limitcondition: 1,
      startDate: globalEvents.dataRangeFrom
        ? moment(globalEvents.dataRangeFrom).format("YYYY-MM-DD")
        : "2012-08-21",
      endDate: globalEvents.dataRangeTo
        ? moment(globalEvents.dataRangeTo).format("YYYY-MM-DD")
        : "2013-08-21",
    };
    setLoading(true);
    try {
      AdminReportDataService.GetGlobalSyncData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.data;
          var tlcount =response?.data?.responseData?.totalCount;
          var shcount=response?.data?.responseData?.shownCount;
          if (message === "Success") {
            setDashboardConversation(responseData);

            var tempDates = [
              ...new Set(responseData.map(({ formatedDate }) => formatedDate)),
            ];
            setDatesList(tempDates);
            setLoading(false);
            setDataLabelConversation(0);
            setEventsCount(shcount);
            setTotalEventsCount(tlcount);
          } else {
            setDashboardConversation([]);
            setLoading(false);
            setDataLabelConversation(1);
            setEventsCount(0);
            setTotalEventsCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  let navigate = useNavigate();
  const handleRedirection = (type) => {
    if (type == "PendingRequest") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu("vendorManagerPendingRequests"));
      navigate("/VendorManagerPendingRequests");
    }
  };
  const handleRedirectConversation = (type, id, word) => {
    if (type == "Profile") {
      navigate("/profileNew", {
        state: {
          key: "Profile",
          id: id ? id : 0,
        },
      });
    } else if (type == "Appointment or Conversation") {
      navigate("/dashboard");
      //if (word == " of ") {
        // navigate("/contact", {
        //   state: {
        //     key: "AppointmentsOnly",
        //   },
        // });
        //dispatch(setContactPageNameType("AppointmentsOnly"));
      // } else {
      //   navigate("/profileNew", {
      //     state: {
      //       key: "Conversation",
      //       id: id ? id : 0,
      //     },
      //   });
      // }
    } else if (type == "Conversation") {
      navigate("/dashboard");
      // navigate("/profileNew", {
      //   state: {
      //     key: "Conversation",
      //     id: id ? id : 0,
      //   },
      // });
    }
  };
  const handleGetSyncsClientList = () => {
    try {
      AdminReportDataService.GetSyncsClientList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setSyncsClientList(responseData);
            }
          } else {
            setSyncsClientList([]);
          }
        })
        .catch((error) => {
          setSyncsClientList([]);
        });
    } catch (error) {
      setSyncsClientList([]);
    }
  };
  // const handleDownloadDataCSV_Synclist = () => {
  //   let inputData = {
  //     dealerID: globalEvents.client.toString(),
  //     fromDate: globalEvents.dataRangeFrom
  //       ? moment(globalEvents.dataRangeFrom).format("MM/DD/YYYY")
  //       : "08/21/2012",
  //     toDate: globalEvents.dataRangeTo
  //       ? moment(globalEvents.dataRangeTo).format("MM/DD/YYYY")
  //       : "08/21/2013",
  //     type: globalEvents.type.toString(),
  //     status: globalEvents.status.toString()
  //   };
  //   setDownloadDataloading(true);
  //   AdminReportDataService.downloadDataCSV_Synclist(inputData)
  //     .then((response) => {
  //       var fileName = "ReportFileSyncReport.csv";

  //       BlobToDownloadFile(response.data, fileName);
  //     })
  //     .catch((error) => {
  //       setDownloadDataloading(false);
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setDownloadDataloading(false);
  //     });
  // };

  const handleApplyFilter = () => {
    const dealerIdValue = parseInt(globalEvents.client, 10);
    handleDashBoardConservationDetailsList(dealerIdValue);
  }; 

  /*Sorting API Ends*/

  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block mobile-m-0">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Global Events")}
              </h4>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block mobile-m-0">
              <label> {traslate("Client")} </label>
              <Select
                className="border__grey"
                placeholder="All"
                showSearch
                name="client"
                optionFilterProp="children"
                value={globalEvents.client ? globalEvents.client : null}
                onChange={(e) => handleOnChange(e, "select", "client")}
                onSearch={onSearch}
              >
                {SyncsClientList.length > 0 &&
                  SyncsClientList.map((item, i) => {
                    if (item.dealerID == 0) {
                      return (
                        <Option value={item.dealerID.toString()}>
                          {item.dealerCompany}
                        </Option>
                      );
                    } else {
                      return (
                        <Option value={item.dealerID.toString()}>
                          {item.dealerCompany} ({item.dealerID})
                        </Option>
                      );
                    }
                  })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="limit_section">
            <div className="col-lg-12 mt-3">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-4 rules__div">
                    <span className="rules__txt">
                      {" "}
                      {EventsCount > TotalEventsCount ? (
                        <span className="rules__txtno">
                          {TotalEventsCount} / {TotalEventsCount}
                        </span>
                      ) : (
                        <span className="rules__txtno">
                          {EventsCount} / {TotalEventsCount}
                        </span>
                      )}{" "}
                      {traslate("Events")}
                    </span>
                  </div>
                  {/* <div className="col-lg-8 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() => handleDownloadDataCSV_Synclist()}
                      loading={downloadDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {traslate("Download")}
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="notifications-section">
              <div className="col-md-12">
                  <Spin spinning={loading}>
                    <div className="Notif_section">
                      {datesList && dataLabelConversation ==0 &&
                        datesList.map((header) => {
                          return (
                            <div className="msg_div">
                              <div>
                                <p className="msg_date">
                                  {moment(header).format("dddd, MMMM DD, YYYY ")}
                                </p>
                              </div>
                              <React.Fragment>
                                {dashboardConversation &&
                                  dashboardConversation
                                    .filter((x) => x.formatedDate == header)
                                    .map((item) => {
                                      return (
                                        <div className="msg_divtext">
                                          <span className="msg_txt">
                                            {" "}
                                            {/* <i
                                            class="fa fa-comments msg__icon"
                                            aria-hidden="true"
                                          ></i> */}
                                            <span>
                                              <img
                                                src={
                                                  item.icon == "user.png"
                                                    ? User_image
                                                    : item.icon == "share.png"
                                                    ? shareImage
                                                    : ""
                                                }
                                                style={
                                                  item.icon == "user.png"
                                                    ? { width: "17px" }
                                                    : { width: "15px" }
                                                }
                                              />
                                            </span>{" "}
                                            <span
                                              onClick={() =>
                                                handleRedirectConversation(
                                                  "Profile",
                                                  item.customerId
                                                )
                                              }
                                              className="linkText"
                                            >
                                              {item.eventTitle &&
                                                item.eventTitle
                                                  .split(">")[1]
                                                  .split("<")[0]}
                                            </span>{" "}
                                            <span>
                                              {item.eventTitle &&
                                                item.eventTitle
                                                  .split(">")[2]
                                                  .split("<")[0]}
                                            </span>{" "}
                                            <span
                                              onClick={() =>
                                                handleRedirectConversation(
                                                  "Appointment or Conversation",
                                                  item.customerId,
                                                  item.eventTitle &&
                                                    item.eventTitle.split(">")[4] &&
                                                    item.eventTitle
                                                      .split(">")[4]
                                                      .split("<")[0]
                                                )
                                              }
                                              className="linkText"
                                            >
                                              {item.eventTitle.split(">")[3] &&
                                                item.eventTitle
                                                  .split(">")[3]
                                                  .split("<")[0]}
                                            </span>{" "}
                                            <span>
                                              {item.eventTitle.split(">")[4] &&
                                                item.eventTitle
                                                  .split(">")[4]
                                                  .split("<")[0]}
                                            </span>{" "}
                                            <span
                                              onClick={() =>
                                                handleRedirectConversation(
                                                  "Conversation",
                                                  item.customerId
                                                )
                                              }
                                              className="linkText"
                                            >
                                              {item.eventTitle.split(">")[5] &&
                                                item.eventTitle
                                                  .split(">")[5]
                                                  .split("<")[0]}
                                            </span>{" "}
                                            <span>
                                              {item.eventTitle.split(">")[6] &&
                                                item.eventTitle
                                                  .split(">")[6]
                                                  .split("<")[0]}
                                            </span>
                                          </span>

                                          <span className="msg_timetxt">
                                            {item.actionTime == ""
                                              ? ""
                                              : item.actionTime}
                                          </span>
                                        </div>
                                      );
                                    })}
                              </React.Fragment>
                            </div>
                          );
                        })}
                        {dataLabelConversation == 0 &&buttonVisible == 1 && (
                          <Button
                            className="primary-btn mt-3"
                            onClick={() => handleloadMore()}
                          >
                            Load More
                          </Button>
                        )}

                      {dataLabelConversation == 1 && (
                        <div className="col-md-12 text-center mt-4">
                          <p>No Data Available</p>
                        </div>
                      )}
                    </div>
                  </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Syncs;
