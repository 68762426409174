import { httpApps } from "../helper/http-common";

class MasterLinkService {
    GetMasterLinkOptionPreferences(inputdata) {
        return httpApps.post("/MasterLink/GetMasterLinkOptionPreferences", inputdata);
    }
    SaveMasterLinkData(inputdata) {
        return httpApps.post("/MasterLink/SaveMasterLinkData", inputdata);
    }

    GetLegacyMasterLinkPreferences(inputdata) {
        return httpApps.post("/MasterLink/GetLegacyMasterLinkPreferences", inputdata);
    }

    SaveMasterLinkLegacyData(inputdata) {
        return httpApps.post("/MasterLink/SaveMasterLinkLegacyData", inputdata);
    }
    BindThemeDropDown() {
        return httpApps.post("/MasterLink/BindThemeDropDown");
    }
}

export default new MasterLinkService();
