import { httpManageUpload } from "../helper/http-common";
class ManageUploadServices {
    
    // <--- Manage Upload Details ---->

    AddFolder(inputData) {
    return httpManageUpload.post("/Marketing/AddFolder", inputData);
  }
 
  LoadMarketingData_Vendor(inputData) {
    return httpManageUpload.post("/Marketing/LoadMarketingData_Vendor", inputData);
  }
  BindFolders_Vendor(inputData) {
    return httpManageUpload.post("/Marketing/BindFolders_Vendor", inputData);
  }
  UploadJewelryImageFiles(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpManageUpload.post("/Marketing/UploadMedia", inputData, {
      headers,
    });
  }

  RemoveFileandFolder(inputData) {
    return httpManageUpload.post("/Marketing/RemoveFileandFolder", inputData);
  }
  DownloadMarketingZipFiler(inputData) {
    return httpManageUpload.post("/Marketing/DownloadMarketingZipFile", inputData,{responseType: 'arraybuffer'});
  }
  BindFolders(inputData) {
    return httpManageUpload.post("/Marketing/BindFolders", inputData);
  }
  
}
export default new ManageUploadServices();

