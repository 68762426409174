import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lft_chat from "../../assets/images/lft_chat.png";
import three from "../../assets/images/three.png";
import traslate from "../../i18n/translate";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import { Button, Spin, Tooltip } from "antd";
import { setChecklinkHeaderMenuData } from "../../actions/headerMenu/headerMenu";

export const Conversation = ({ pageNameType }) => {
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerId, setDealerId] = useState(0);
  const [conversationData, setConversationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datesList, setDatesList] = useState([]);
  const [leftSideData, setLeftSideData] = useState({});
  const [loginType, setLoginType] = useState("");
  const [leftSideLoading, setLeftSideLoading] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setLoginType(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
          handleGetContactCoversionListDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
          setTimeout(() => {
            handleGetLeftSideMenuConservationDetails(
              loginDetials.loginDetials.responseData.dealerId
            );
          }, 200);
        }
      }
    }
  }, []);

  //  API binding for Conversation

  const handleGetContactCoversionListDetails = async (id) => {
    let inputData = {
      dealerID: id,
      intType: 1
    };
    setLoading(true);
    try {
      AdminDashboardDataService.GetContactCoversionListDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setConversationData(responseData);

            let tempDates = [
              ...new Set(responseData.map(({ formatedDate }) => formatedDate))
            ];
            setDatesList(tempDates);
            setLoading(false);
          } else {
            setConversationData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Redirection
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRedirection = (type, id) => {
    if (type == "Conversation") {
      dispatch(setChecklinkHeaderMenuData("Contacts"));
      navigate("/profileNew", {
        state: {
          key: "Conversation",
          id: id ? id : 0
        }
      });
    } else if (type == "Profile") {
      dispatch(setChecklinkHeaderMenuData("Contacts"));
      navigate("/profileNew", {
        state: {
          key: "Profile",
          id: id ? id : 0
        }
      });
    } else if (type == "VendorProfile") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      navigate("/vendorProfile", {
        state: {
          key: "Profile",
          id: id ? id : 0
        }
      });
    }
  };

  const handleGetLeftSideMenuConservationDetails = async (id) => {
    let inputData = {
      dealerID: id ? id.toString() : ""
    };
    setLeftSideLoading(true);
    try {
      AdminDashboardDataService.GetLeftSideMenuConservationDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            setLeftSideData(responseData);
            setLeftSideLoading(false);
          } else {
            setLeftSideData({});
            setLeftSideLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLeftSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLeftSideLoading(false);
    }
  };

  const handleRefresh = () => {
    handleGetContactCoversionListDetails(dealerId);
  };

  return (
    <div
      className={
        pageNameType == "conversation" || pageNameType == "Conversation"
          ? "catalog__list dashbo_maindiv form__fields mt-5"
          : "catalog__list dashbo_maindiv form__fields"
      }
    >
      <div className="row permission__section">
        <div className="col-md-6">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-0">
              {traslate("Conversations")}
            </h4>
          </div>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-9">
          <div className="refresh__btn">
            {/* <Button
                type="primary"
                onClick={() => {
                  handleRefresh();
                }}
              >
                {traslate("Refresh")}
              </Button> */}
          </div>
          {datesList.length > 0 ? (
            <div className="Notif_section">
              <Spin spinning={loading}>
                {datesList &&
                  datesList.map((header) => {
                    return (
                      <div className="msg_div">
                        <div>
                          <p className="msg_date"> {header} </p>
                        </div>
                        <React.Fragment>
                          {conversationData &&
                            conversationData
                              .filter((x) => x.formatedDate == header)
                              .map((item) => {
                                return (
                                  <div className="msg_divtext">
                                    <span className="msg_txt">
                                      {loginType == "Retailer" ? (
                                        item.isRead == 1 ? (
                                          <img
                                            src={three}
                                            alt="abc"
                                            className="mr-1"
                                          />
                                        ) : null
                                      ) : null}
                                      <img
                                        src={lft_chat}
                                        alt="abc"
                                        className="mr-1"
                                      />
                                      <span
                                        className="msg_nametxt"
                                        onClick={() =>
                                          handleRedirection(
                                            "Profile",
                                            item.customerId
                                          )
                                        }
                                      >
                                        {" "}
                                        {item.eventTitle}{" "}
                                      </span>{" "}
                                      {traslate("Started a")}
                                      <span
                                        className="msg_nametxt"
                                        onClick={() =>
                                          handleRedirection(
                                            "Conversation",
                                            item.customerId
                                          )
                                        }
                                      >
                                        {" "}
                                        {traslate("Conversation")}{" "}
                                      </span>
                                      {traslate("with your store.")}
                                    </span>

                                    <span className="msg_timetxt">
                                      {item.actionTime}{" "}
                                    </span>
                                  </div>
                                );
                              })}
                        </React.Fragment>
                      </div>
                    );
                  })}
              </Spin>
            </div>
          ) : (
            <div className="no-report-render">
              <div className="no_desc_space">
                <h4 className="text-center">{traslate("No Data Found")}</h4> 
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-3 col-md-3">
          <Spin spinning={leftSideLoading}>
            <div className="right__section">
              <div className="profile__block">
                <p style={{ color: "#000000" }} class="retailer__name">
                  {traslate("Quick Links")}
                </p>
              </div>
              <hr />
              <div className="input__block">
                <label>{traslate("New Customers")}</label>
                {leftSideData.newCustomers &&
                leftSideData.newCustomers.length > 0 ? (
                  leftSideData.newCustomers.map((item, i) => {
                    return (
                      <p>
                        <a
                          onClick={() =>
                            handleRedirection("Profile", item.cust_id)
                          }
                          className="linkText"
                        >
                          {item.fullName}
                        </a>
                      </p>
                    );
                  })
                ) : (
                  <span className="linkText">
                    {traslate("No Data To Display")}
                  </span>
                )}
                <hr />
              </div>
              <div className="input__block">
                <label>{traslate("New Arrivals")}</label>
                {leftSideData.newArrivals &&
                leftSideData.newArrivals.length > 0 ? (
                  leftSideData.newArrivals.map((item, i) => {
                    return (
                      <Tooltip placement="right" title={item.productName}>
                        <img
                          src={item.imagePath}
                          className="new__arrival__image__conversation mr-2"
                        />
                      </Tooltip>
                    );
                  })
                ) : (
                  <span className="linkText">
                    {traslate("No Data To Display")}
                  </span>
                )}
                <hr />
              </div>
              <div className="input__block">
                <label>{traslate("Appointments")}</label>
                {leftSideData.appointments &&
                leftSideData.appointments.length > 0 ? (
                  leftSideData.appointments.map((item, i) => {
                    return (
                      <p>
                        <a className="linkText">
                          {item.dayDate}
                          {" - "}
                          {item.appointmentTime}
                          {" - "}
                          {item.fullName}
                        </a>
                      </p>
                    );
                  })
                ) : (
                  <span className="linkText">
                    {traslate("No Data To Display")}
                  </span>
                )}
                <hr />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
