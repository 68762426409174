import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Spin,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../assets/images/create_icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../i18n/translate";

const { Option } = Select;

export const Appointments = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: traslate("Contact"),
      dataIndex: "contact",
    },
    {
      title: traslate("Calendar"),
      dataIndex: "calendar",
    },
    {
      title: traslate("Time"),
      dataIndex: "time",
    },
    {
      title: traslate("Day"),
      dataIndex: "day",
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
    },
  ];

  const data = [
    {
      key: "1",
      contact: <span>Bhavin Patel</span>,
      calendar: <span>Schedule A Viewing</span>,
      time: <span>11 AM</span>,
      day: <span>Thursday 10/08/2020</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} onClick={() => showModal()} alt="" />
          </div>
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "2",
      contact: <span>Bhavin Patel</span>,
      calendar: <span>Schedule A Viewing</span>,
      time: <span>11 AM</span>,
      day: <span>Thursday 10/08/2020</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} onClick={() => showModal()} alt="" />
          </div>
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
  ];

  function onChange(value) {}

  function onSearch(val) {}

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block mb-0">
            <h4 className="workarea__heading mt-0 mb-0">
              {traslate("Appointments")}
            </h4>
            <button className="primary-btn" onClick={showModal}>
              <img src={Create_icon} alt="" /> {traslate("Create Appointment")}
            </button>
          </div>
        </div>

        <div className="col-md-6 mt-5">
          <p className="font-weight-bold">{traslate("Calendars")}</p>
          <div className="row">
            <div className="radio__block col">
              <input type="checkbox" id="1" />
              <label for="1"> {traslate("Engagement")} </label>
            </div>
            <div className="radio__block col">
              <input type="checkbox" id="2" />
              <label for="2"> {traslate("Cleaning")} </label>
            </div>
            <div className="radio__block col">
              <input type="checkbox" id="3" />
              <label for="3"> {traslate("Repair")} </label>
            </div>
            <div className="radio__block col">
              <input type="checkbox" id="4" />
              <label for="4"> {traslate("Wedding")} </label>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 mt-4">
          <Table
            className="pendjewelreq__tbl"
            columns={columns}
            dataSource={data}
            scroll={{ x: 600, y: 800 }}
          />
        </div>

        <Modal
          title={traslate("Create Appointment")}
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleCancel}>
              {traslate("Submit")}
            </Button>,
          ]}
        >
          <div className="form__fields mt-3 p-0 border-0">
            <div className="row">
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("Select Date")} </label>
                  <input type="date" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("Select Calendar")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Calendar "
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="1"> {traslate("Engagement")} </Option>
                    <Option value="2"> {traslate("Cleaning")} </Option>
                    <Option value="3"> {traslate("Repair")} </Option>
                    <Option value="4"> {traslate("Wedding")} </Option>
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("Select Contact")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Contact "
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="1"> {traslate("ABC")} </Option>
                    <Option value="2"> {traslate("John")} </Option>
                    <Option value="3"> {traslate("XYZ")} </Option>
                    <Option value="4"> {traslate("User")} </Option>
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("Set Day")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Set Day "
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack"> {traslate("Thursday")} </Option>
                    <Option value="1"> {traslate("Friday")} </Option>
                    <Option value="2"> {traslate("Saturday")} </Option>
                    <Option value="3"> {traslate("Monday")} </Option>
                    <Option value="4"> {traslate("Tuesday")} </Option>
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("Set Time")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Set Time "
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack"> 10:00 {traslate("AM")} </Option>
                    <Option value="2"> 11:00 {traslate("AM")} </Option>
                    <Option value="1"> 12:00 {traslate("PM")} </Option>
                    <Option value="3"> 1:00 {traslate("PM")} </Option>
                    <Option value="4"> 2:00 {traslate("PM")} </Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
