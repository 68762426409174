import React from "react";
import Allproducts from "../All Products/Allproducts";

const AllProduct = () => {
  return (
    <React.Fragment>
      <Allproducts type="AllProduct" />
    </React.Fragment>
  );
};

export default AllProduct;