import en from "./en-US";
import de from "./de-DE";
import fr from "./fr-CA";
import es from "./es-Es";
import ar from "./ar-AE";

export default{
    ...en,
    ...de,
    ...fr,
    ...es,
    ...ar
}