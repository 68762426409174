import React, { useState, useEffect } from "react";
import { Select, Table, Spin, Modal } from "antd";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import DoughnutChart from "../../admin/JewelryReport/Doughnut";
import traslate from "../../../i18n/translate";
import NoReportDataIcon from "../../../assets/images/no-report-img.png";

/*Initial State for Binding Starts*/
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: []
};
/*Initial State for Binding Ends*/
const { RangePicker } = DatePicker;
const { Option } = Select;

const GlobalJewelryPriceReport = () => {
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [tableChartData, setTableChartData] = useState([]);
  const [priceRange, setpriceRange] = useState([]);
  const [priceTotal, setPriceTotal] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const data1 = [];
  const [key, setKey] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [priceTotalValuePercentage, setPriceTotalValuePercentage] = useState(0);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setSelectedData((prevState) => ({
            ...prevState,
            dealerID: loginDetials.loginDetials.responseData.dealerId
          }));
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
  }, []);
  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id
      };
      setShowLoader(true);
      AdminJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer
            }));

            handleGetClicksBasedOnPrice(id, responseData.forDealer);
            handleGetTableChartData(id, responseData.forDealer);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetClicksBasedOnPrice = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksBasedOnPrice(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            let showResp = responseData.every((element) => {
              return element.total == "0";
            });
            setShowChart(showResp);
            let range = [];
            let total = [];
            for (let i of responseData) {
              range.push(i.range);
              total.push(i.total);
            }
            let totalValuePercentage = total.reduce(
              (a, b) => (a = Number(a) + Number(b)),
              0
            );
            setPriceTotal(total);
            setpriceRange(range);
            setPriceTotalValuePercentage(totalValuePercentage);
            setShowLoader(false);
            setKey(!key);
          } else {
            setShowLoader(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  const handleGetTableChartData = async (
    DealerId,
    forDealerId,
    type,
    pageNumber,
    pageSiz,
    sortColumnName = "date",
    sortOrder
  ) => {
    let price = "",
      price1 = "";
    if (type && type !== "Call For Price") {
      price =
        type == "None"
          ? "-"
          : type.includes("Under")
          ? "0"
          : type.split(" - ")[0].split("$")[1];
      price1 =
        type == "None"
          ? "-"
          : type.includes("Under")
          ? type.split(" - ")[0].split("$")[1]
          : type.split("-").length > 1
          ? type.split("-")[1].split("$")[1]
          : type;
    }
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: "",
        metalType: "",
        metalColorType: "",
        price: type == "Call For Price" ? type : "",
        price1: price,
        price2: price1,
        vendor: "",
        retailer: "",
        reporttype: "Global",
        pageIndex: pageNumber ? pageNumber : 1,
        pageSize: pageSiz ? pageSiz : 10,
        // sortColumnName:
        //   sortColumnName == "UserIPAddress"
        //     ? "UserIPAddress"
        //     : sortColumnName == "Vendor"
        //     ? "VendorName"
        //     : sortColumnName == "Retailer"
        //     ? "RetailerName"
        //     : sortColumnName == "Collection"
        //     ? "CollectionNew"
        //     : sortColumnName == "category"
        //     ? "Category"
        //     : sortColumnName == "date"
        //     ? "Date"
        //     : sortColumnName == "StyleNumber"
        //     ? "StyleNumber"
        //     : sortColumnName == "metalType"
        //     ? "MetalType"
        //     : sortColumnName == "metalColor"
        //     ? "MetalColor"
        //     : sortColumnName == "price"
        //     ? "Price"
        //     : "UserIPAddress",
        sortColumnName: sortColumnName,
        sortOrder: sortOrder ? sortOrder.toString() : sortType.toString()
      };
      setShowTableLoader(true);
      await AdminJewelryReportService.GetTableChartData(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            responseData.map((elem, index) => {
              data1.push({
                key: index,
                ProductImage: (
                  <span onClick={() => imageClickHandler(elem.imagePathNew)}>
                    <img src={elem.imagePathNew} className="product__img" />
                  </span>
                ),
                UserIPAddress: <span>{elem.userIPAddress}</span>,
                Vendor: (
                  <span>{elem.vendorName === null ? "" : elem.vendorName}</span>
                ),
                Retailer: (
                  <span>
                    {elem.retailerName === null ? "" : elem.retailerName}
                  </span>
                ),
                Collection: <span>{elem.collectionNew}</span>,
                Category: <span>{elem.category}</span>,
                Date: <span>{elem.dateTime}</span>,
                StyleNumber: <span>{elem.styleNumber}</span>,
                MetalType: <span>{elem.metalType}</span>,
                MetalColor: <span>{elem.metalColor}</span>,
                Price: <span>{elem.price}</span>
              });
            });
            setTotalCount(responseData?.[0]?.totalRecords || 0);
            setTableChartData(data1);
            setShowTableLoader(false);
          } else {
            setTotalCount(0);
            setShowTableLoader(false);
          }
        }
      );
    } catch (error) {
      setTotalCount(0);
      console.log(error);
      setShowTableLoader(false);
    }
  };
  const handleGetVendorDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "global"
    };
    try {
      AdminJewelryReportService.GetVendorDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetRetailerDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "global"
    };
    try {
      AdminJewelryReportService.GetRetailerDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(), //moment("2015-08-11T13:00:00.000000Z", "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format()
        toDate: moment.utc(dates[1]).toISOString()
      }));
    }
  };
  /*DatePicker Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };
  const changeRetailerListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        vendorIds: data
      };
      AdminJewelryReportService.ChangeRetailerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const changeVendorListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        retailerIds: data
      };
      AdminJewelryReportService.ChangeVendorList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const applyFilterHandler = () => {
    handleGetClicksBasedOnPrice(selectedData.dealerID, selectedData.forDealer);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      "",
      pageNo,
      pageSize
    );
  };
  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const [sortType, setSortType] = useState("desc");
  const [priceType, setPriceType] = useState("");

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
    } else {
      type = "DESC";
      column = "date";
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      priceType,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  const columns = [
    {
      title: traslate("Product Image"),
      dataIndex: "ProductImage",
      width: 120
    },
    {
      title: traslate("User IP Address"),
      dataIndex: "UserIPAddress",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Category"),
      dataIndex: "Category",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Date"),
      dataIndex: "Date",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Metal Type"),
      dataIndex: "MetalType",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Metal Color"),
      dataIndex: "MetalColor",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Price"),
      dataIndex: "Price",
      sorter: (a, b) => {},
      width: 120
    }
  ];

  const tableSortHandler = (type) => {
    setPriceType(type);
    setPageNo(1);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Jewelry Price Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[
                  moment().subtract(30, "days"),
                  moment().subtract(1, "days")
                ]}
                defaultPickerValue={[
                  moment().subtract(30, "days"),
                  moment().subtract(1, "days")
                ]}
              />
            </div>
          </div>
          {dealerMemberTypeName === "Admin" ? (
            <>
              <div className="col-md-4">
                <div className="input__block">
                  <label> {traslate("Vendor(s)")} </label>
                  <Select
                    className="border__grey"
                    showSearch
                    mode="multiple"
                    showArrow
                    placeholder="Select one or more vendor"
                    optionFilterProp="children"
                    value={selectedData.vendorIds}
                    onChange={(e) => handleOnChange(e, "select", "vendors")}
                  >
                    {vendorList.map((item, key) => {
                      return (
                        <Option key={item.dealerID} value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input__block">
                  <label> {traslate("Retailer(s)")} </label>
                  <Select
                    showSearch
                    showArrow
                    className="border__grey"
                    placeholder="Select one or more retailer "
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "retailers")}
                    notFoundContent="No Data Found"
                    mode="multiple"
                    value={selectedData.retailerIds}
                  >
                    {retailerList.map((item, key) => {
                      return (
                        <Option key={key} value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </>
          ) : dealerMemberTypeName === "Vendor" ? (
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Retailer(s)")} </label>
                <Select
                  showSearch
                  showArrow
                  className="border__grey"
                  placeholder="Select one or more retailer "
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "retailers")}
                  notFoundContent="No Data Found"
                  mode="multiple"
                  value={selectedData.retailerIds}
                >
                  {retailerList.map((item, key) => {
                    return (
                      <Option key={key} value={item.dealerID}>
                        {item.dealerCompany}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          ) : (
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Vendor(s)")} </label>
                <Select
                  className="border__grey"
                  showSearch
                  mode="multiple"
                  showArrow
                  placeholder="Select one or more vendor"
                  optionFilterProp="children"
                  value={selectedData.vendorIds}
                  onChange={(e) => handleOnChange(e, "select", "vendors")}
                >
                  {vendorList.map((item, key) => {
                    return (
                      <Option key={item.dealerID} value={item.dealerID}>
                        {item.dealerCompany}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          <div className="col-md-4">
            <div className="input__block">
              <button className="primary-btn mt-2" onClick={applyFilterHandler}>
                {traslate("Apply Filter")}
              </button>
            </div>
          </div>
          {!showChart ? (
            <Spin spinning={showLoader}>
              <div className="col-lg-12">
                <div className="chart__section">
                  {priceRange !== null &&
                    priceRange.length >= 1 &&
                    priceTotal.length >= 1 &&
                    priceTotalValuePercentage !== 0 && (
                      <DoughnutChart
                        key={key}
                        label={priceRange}
                        data={priceTotal}
                        typeGetter={tableSortHandler}
                        totalValuePercentage={priceTotalValuePercentage}
                      />
                    )}
                </div>
              </div>
            </Spin>
          ) : (
            <div className="no-report-render">
              <div className="img-space">
                <img src={NoReportDataIcon} alt="no data" />
              </div>
              <div className="desc-space">
                <h6 className="linkText">
                  {traslate("Sorry, No Chart Data was found")}
                </h6>
              </div>
            </div>
          )}

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              onChange={handleTableASCDES}
              pagination={{
                total: totalCount,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                pageSize: pageSize,
                current: pageNo
              }}
              dataSource={tableChartData}
              loading={showTableLoader}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default GlobalJewelryPriceReport;
