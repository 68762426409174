import { httpApps } from "../helper/http-common";
class DiamondLinksEmailTemplateService {
    GetDiamondEmailTemplatesDetails(inputData) {
        return httpApps.post("/DiamondLink/GetDiamondEmailTemplatesDetails", inputData);
    }
    UpdateDiamondLinkEmailTemplateDetails(inputData) {
        return httpApps.post("/DiamondLink/UpdateDiamondLinkEmailTemplateDetails", inputData);
    }
}

export default new DiamondLinksEmailTemplateService();
