import { httpApps } from "../helper/http-common";
class AppsStudBuilderService {
  // stud Builder
  GetStudOptionsDetails(inputdata) {
    return httpApps.post("/studBuilder/GetstudBuilderOptiondetails", inputdata);
  }

  UpdateStudOptionsDetails(inputdata) {
    return httpApps.post(
      "/studBuilder/UpdatestudBuilderOptiondetails",
      inputdata
    );
  }

  //Manage Vendors

  BindDataStudBuilderVendor(inputdata) {
    return httpApps.post("/studBuilder/BindDataStudBuilderVendor", inputdata);
  }

  // Diamond Vendor 

  StudBindDealerList(inputdata) {
    return httpApps.post("/studBuilder/StudBindDealerList", inputdata);
  }

  StudUpdateList_Click(inputdata) {
    return httpApps.post("/studBuilder/StudUpdateList_Click", inputdata);
  }
// Mined Diamond Markup and Lab grow Markup

GetRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/GetStudBuilderLabGrownMarkupLoadData",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/GetStudBuilderMinedDiamondMarkupLoadData",
      inputData
    );
  }
}

GetMakerRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/GetMakerStudBuilderLabGrownMarkupLoadData",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/GetMakerStudBuilderMinedDiamondMarkupLoadData",
      inputData
    );
  }
}

GetCustomerRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/GetCustomerStudBuilderLabGrownMarkupLoadData",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/GetCustomerStudBuilderMinedDiamondMarkupLoadData",
      inputData
    );
  }
}

SaveRingBuilderMinedDiamondDealerMarkupdata(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/SaveStudBuilderLabGrownDealerMarkupdata",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/SaveStudBuilderMinedDiamondDealerMarkupdata",
      inputData
    );
  }
}
SaveRingBuilderMinedDiamondCustomMarkupdata(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/SaveStudBuilderLabGrownCustomMarkupdata",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/SaveStudBuilderMinedDiamondCustomMarkupdata",
      inputData
    );
  }
}
RemoveRingBuilderMinedDiamondDealerMarkupdata(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/RemoveStudBuilderLabGrownDealerMarkupdata",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/RemoveStudBuilderMinedDiamondDealerMarkupdata",
      inputData
    );
  }
}
RemoveRingBuilderMinedDiamondCustomMarkupdata(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/RemoveStudBuilderLabGrownCustomMarkupdata",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/RemoveStudBuilderMinedDiamondCustomMarkupdata",
      inputData
    );
  }
}

GetAllMinedDiamondCostPriceAndSize(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/GetAllLabGrownCostPriceAndSize",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/GetAllStudBuilderMinedDiamondCostPriceAndSize",
      inputData
    );
  }
}
UpdateAllMinedDiamondCostPriceAndSize(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/UpdateAllLabGrownCostPriceAndSize",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/UpdateAllStudBuilderMinedDiamondCostPriceAndSize",
      inputData
    );
  }
}
UpdateAllRingBuilderMinedDiamondMarkupdata(inputData, type) {
  if (type === "LAB") {
    return httpApps.post(
      "/studBuilder/UpdateAllStudBuilderLabGrownMarkupdata",
      inputData
    );
  } else {
    return httpApps.post(
      "/studBuilder/UpdateAllStudBuilderMinedDiamondMarkupdata",
      inputData
    );
  }
}

// setting Markup

// Settings Markup
GetStudBuilderMarkupLoadData(inputdata) {
  return httpApps.post(
    "/studBuilder/GetStudBuilderMarkupLoadData",
    inputdata
  );
}

UpdateAllStudBuilderSettingMarkup(inputdata) {
  return httpApps.post(
    "/studBuilder/UpdateAllStudBuilderSettingMarkup",
    inputdata
  );
}

GetPendingRequests = (inputData) => {
  return httpApps.post("/studBuilder/StudGetPendingRequests", inputData);
};

StudBuilderPendingToApprove = (inputData) => {
  return httpApps.post("/studBuilder/StudBuilderPendingToApprove", inputData);
};
StudBuilderPendingToDisApprove = (inputData) => {
  return httpApps.post(
    "/studBuilder/StudBuilderPendingToDisApprove",
    inputData
  );
};

StusBuilderBindDataCommVendor = (inputData) => {
  return httpApps.post(
    "/studBuilder/StudBuilderBindDataCommVendor",
    inputData
  );
};

StudBuilderShowDataToRightNew = (inputData) => {
  return httpApps.post(
    "/studBuilder/StudBuilderShowDataToRightNew",
    inputData
  );
};
StudBuilder_BindDataCommVendor = (inputData) => {
  return httpApps.post(
    "/studBuilder/StudBuilder_BindDataCommVendor",
    inputData
  );
};

GetsStudBuildernavigationetails(inputdata) {
  return httpApps.post("/studBuilder/GetsStudBuildernavigationetails", inputdata);
}
UpdateStudBuildernavigationdetails(inputdata) {
  return httpApps.post("/studBuilder/UpdateStudBuildernavigationdetails", inputdata);
}


}

export default new AppsStudBuilderService();
