import { http } from "../helper/http-common";
class AdminManageLanguageService {

    GetSeleCtlanguageDropdowen(inputData) {
        return http.post(
            "/SelectLenguage/SeleCtlanguageDropdowen"
        );
    }
    GetSelectlanguageForDelar(inputData) {
        return http.post(
            "/SelectLenguage/SelectlanguageForDelar",
            inputData
        );
    }
    UpdateLanguage(inputData) {
        return http.post(
            "/SelectLenguage/UpdateLanguage",
            inputData
        );
    }
}

export default new AdminManageLanguageService();
