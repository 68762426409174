import React, { useEffect, useState } from "react";
import {
  Select,
  Button,
  Modal,
  Table,
  Spin,
  Popconfirm,
  Input,
  Pagination,
} from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import ManageWhiteLabelService from "../../services/white-label.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Organization = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validations, setValidations] = useState({
    domainName: "",
    serverName: "",
    databaseName: "",
    user: "",
    password: "",
    email: "",
  });

  const [organizationList, setOrganizationList] = useState([]);
  const [organization, setOrganization] = useState({
    domainName: "",
    serverName: "",
    newDBName: "",
    user: "",
    password: "",
    email: "",
  });
  const [dealerId, setDealerId] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const loginDetails = useSelector((state) => state.loginReducer.loginDetials);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);

  useEffect(() => {
    if (loginDetails && loginDetails.responseData) {
      setDealerId(loginDetails.responseData.dealerId);
      handleGetOrganizationList(pageNo, pageSize);
    }
  }, [loginDetails]);

  const handleCancel = () => {
    setVisible(false);
    resetOrganization();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const resetOrganization = () => {
    setOrganization({
      domainName: "",
      serverName: "",
      newDBName: "",
      user: "",
      password: "",
      email: "",
    });
    setValidations({
      domainName: "",
      serverName: "",
      databaseName: "",
      user: "",
      password: "",
      email: "",
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "newDBName" &&
      e.nativeEvent.inputType === "insertText" &&
      e.nativeEvent.data === " "
    ) {
      return;
    }
    setOrganization({
      ...organization,
      [name]: value,
    });
  };

  const handleValidation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );
    const validations = {
      domainName: "",
      serverName: "",
      newDBName: "",
      user: "",
      password: "",
      email: "",
    };
    let isValid = true;

    if (!organization.domainName.trim()) {
      isValid = false;
      validations.domainName = traslate("Domain Name is Compulsory.");
    }
    if (!organization.serverName.trim()) {
      isValid = false;
      validations.serverName = traslate("Server is Compulsory.");
    }
    if (!organization.newDBName.trim()) {
      isValid = false;
      validations.newDBName = traslate("Database Name is Compulsory.");
    }
    if (!organization.user.trim()) {
      isValid = false;
      validations.user = traslate("User is Compulsory.");
    }
    if (!organization.password.trim()) {
      isValid = false;
      validations.password = traslate("Password is Compulsory.");
    } else if (!passwordRegex.test(organization.password)) {
      isValid = false;
      validations.password = traslate(
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"
      );
    }
    if (!organization.email.trim()) {
      isValid = false;
      validations.email = traslate("Email Address is Compulsory.");
    } else if (!emailRegex.test(organization.email)) {
      isValid = false;
      validations.email = traslate("Please enter a valid Email Address.");
    }

    setValidations(validations);
    return isValid;
  };

  const handleGetOrganizationList = (pageIndex, pageSize) => {
    let inputData = {
      pageindex: pageIndex,
      pagesize: pageSize,
    };
    setLoading(true);
    ManageWhiteLabelService.GetOrganizationList(inputData)
      .then((response) => {
        if (response.data.message === "Success") {
          setOrganizationList(response.data.responseData || []);
          setTotalCount(response?.data?.responseData?.totalrecords);
        } else {
          NotificationManager.error(response.data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetOrganizationList(page, pageSize);
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetOrganizationList(current, size);
  }
  const handleAddOrganization = () => {
    if (handleValidation()) {
      handleCreateOrganization();
    }
  };

  const handleCreateOrganization = () => {
    const inputData = {
      domainName: organization.domainName,
      databaseName: organization.newDBName,
      server: organization.serverName,
      userName: organization.user,
      password: organization.password,
      flag: "A",
      email: organization.email,
    };

    setSubmitLoading(true);
    ManageWhiteLabelService.AddNewOrganization(inputData)
      .then((response) => {
        if (response.data.message === "Success") {
          NotificationManager.success("Organization Created Successfully");
          handleGetOrganizationList(pageNo, pageSize);
          resetOrganization();
          setVisible(false);
        } else {
          NotificationManager.error(response.data.message);
        }
        setSubmitLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
      });
  };

  const handleCreateDB = (id) => {
    setSubmitLoading(true);
    ManageWhiteLabelService.CreateOrganizationDB(id)
      .then((response) => {
        if (response.data.message === "Success") {
          NotificationManager.success("Database Created Successfully");
          handleGetOrganizationList(pageNo, pageSize);
          setVisible(false);
        } else {
          NotificationManager.error(response.data.message);
        }
        setSubmitLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("Organization List")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3 mobile-m-0">
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-end mobile-justify-content-start">
                  <Button
                    className="primary-btn"
                    onClick={() => setVisible(true)}
                  >
                    <img src={Create_icon} alt="Create" />{" "}
                    {traslate("Add Organization")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="tradeshow_tbldiv">
                <Spin spinning={loading}>
                  <Table
                    columns={[
                      {
                        title: traslate("Domain Name"),
                        dataIndex: "domainName",
                      },
                      {
                        title: traslate("Server"),
                        dataIndex: "server",
                      },
                      {
                        title: traslate("Database Name"),
                        dataIndex: "databaseName",
                      },
                      {
                        title: traslate("Actions"),
                        dataIndex: "actions",
                        render: (text, row) => (
                          <div className="action__btns">
                            {row.createdDatabase !== "1" ? (
                              <Button
                                className="success-btn"
                                style={{ marginRight: "10px" }}
                                onClick={() => handleCreateDB(row.id)}
                              >
                                {traslate("Create Database")}
                              </Button>
                            ) : (
                              <div style={{ marginRight: "10px" }} />
                            )}
                            {/* <Popconfirm
                              title={traslate(
                                "Are you sure you want to delete ?"
                              )}
                              onConfirm={() => {}}
                              placement="left"
                            >
                              <Button className="danger-btn" onClick={() => {}}>
                                {traslate("Delete Database")}
                              </Button>
                            </Popconfirm> */}
                          </div>
                        ),
                      },
                    ]}
                    dataSource={organizationList}
                    pagination={false}
                    scroll={{ x: 600, y: 800 }}
                  />
                  <div className="col-md-12 mt-3" style={{ textAlign: "end" }}>
                    <Pagination
                      current={pageNo}
                      pageSize={pageSize}
                      total={totalCount}
                      onChange={handlePageNoChange}
                      onShowSizeChange={handlePageSizeChange}
                      showSizeChanger="true"
                    />
                  </div>
                </Spin>
              </div>
            </div>
            <Modal
              title={traslate("Create Organization")}
              visible={visible}
              onOk={handleAddOrganization}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  {traslate("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={submitLoading}
                  onClick={handleAddOrganization}
                >
                  {traslate("Save Organization")}
                </Button>,
              ]}
            >
              <div className="col-lg-12">
                <div className="modal__body">
                  <div className="col-lg-12">
                    <div className="form__fields border-0 p-0">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("Domain Name")}
                              <span>*</span>
                            </label>
                            <Input
                              name="domainName"
                              value={organization.domainName}
                              onChange={handleOnChange}
                              placeholder="Enter Domain Name"
                              className={
                                validations.domainName && "border__red"
                              }
                            />
                            {validations.domainName && (
                              <p className="error-color-red">
                                {validations.domainName}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("Server")}
                              <span>*</span>
                            </label>
                            <Input
                              name="serverName"
                              value={organization.serverName}
                              onChange={handleOnChange}
                              placeholder="Enter Server"
                              className={
                                validations.serverName && "border__red"
                              }
                            />
                            {validations.serverName && (
                              <p className="error-color-red">
                                {validations.serverName}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("New Database Name")}
                              <span>*</span>
                            </label>
                            <Input
                              name="newDBName"
                              value={organization.newDBName}
                              onChange={handleOnChange}
                              placeholder="Enter Database Name"
                              className={validations.newDBName && "border__red"}
                            />
                            {validations.newDBName && (
                              <p className="error-color-red">
                                {validations.newDBName}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("User")}
                              <span>*</span>
                            </label>
                            <Input
                              name="user"
                              value={organization.user}
                              onChange={handleOnChange}
                              placeholder="Enter User Name"
                              className={validations.user && "border__red"}
                            />
                            {validations.user && (
                              <p className="error-color-red">
                                {validations.user}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("Email Address")}
                              <span>*</span>
                            </label>
                            <Input
                              name="email"
                              value={organization.email}
                              onChange={handleOnChange}
                              placeholder="Enter Email Address"
                              className={validations.email && "border__red"}
                            />
                            {validations.email && (
                              <p className="error-color-red">
                                {validations.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              Password
                              <span>*</span>
                            </label>
                            <div className="password-input-container">
                              <Input
                                name="password"
                                value={organization.password}
                                onChange={handleOnChange}
                                placeholder="Enter Password"
                                className={
                                  validations.password && "border__red"
                                }
                                type={showPassword ? "text" : "password"} // Toggle between text and password
                              />
                              <span
                                className="password-toggle-icon"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <EyeInvisibleOutlined />
                                ) : (
                                  <EyeOutlined />
                                )}
                              </span>
                            </div>
                            {validations.password && (
                              <p className="error-color-red">
                                {validations.password}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Organization;
