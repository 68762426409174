import React, { useState, useEffect } from "react";
import {
  // Input,
  // Row,
  // Col,
  Select,
  Checkbox,
  // Switch,
  Table,
  // Menu,
  // Dropdown,
  Button,
  // Space,
  Modal,
  Spin,
} from "antd";
// import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../i18n/translate";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import OwlCarousel from "react-owl-carousel";
import Groupbg from "../../assets/images/group_bg.png";
// import BannerBgImg from "../../assets/images/companyDetail-banner.png";
// import roundDiamond from "../../assets/images/Round.jpg";
// import colorDiamond from "../../../assets/images/yellow-diamond.jpg";
import DealerProfileService from "../../services/dealer-profile.service";
// import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
// import Edit_icon from "../../assets/images/icons/edit_icon.svg";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";
import AdminManageCurrencyService from "../../services/admin-manageCurrency.service";
import { setSelectedLanguage } from "../../actions/headerMenu/headerMenu";
import { setCurrency, setCurrencySign } from "../../actions/login/login";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Accordion } from "react-bootstrap";
// // import Modal from "antd/lib/modal/Modal";
// const { confirm } = Modal;
import { useCookies } from "react-cookie";

const { Option } = Select;

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const [DealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  // const [btnvisible, setBtnVisible] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  const [btnloading, setBtnLoading] = useState(false);
  const [mainSliderLoading, setMainSliderLoading] = useState(false);
  const [mainSliderSaveLoading, setMainSliderSaveLoading] = useState(false);
  const [companyLogoLoading, setcompanyLogoLoading] = useState(false);
  const [companyLogoSaveLoading, setcompanyLogoSaveLoading] = useState(false);
  const [advertisingLoading, setAdvertisingLoading] = useState(false);
  const [advertisingSaveLoading, setAdvertisingSaveLoading] = useState(false);
  const [userType, setUserType] = useState(false);
  // const [getLoading, setGetLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isImage, setIsImage] = useState(false);
  // const [loginType, setLoginType] = useState("");
  const [dealerMemberTypeName, setDealerMembershipType] = useState("");
  const [langcookies, setLangcookies] = useCookies(["lngCookie"]);
  const loginDetials = useSelector((state) => state.loginReducer);
  const expiryTime = new Date();
  expiryTime.setTime(expiryTime.getTime() + 3 * 60 * 60 * 1000); ///3 hours expiry time
  const domainSite = extractDomain();
  function extractDomain() {
    const url = window.location.href;
    const domainMatch = url.match(/:\/\/(www[0-9]?\.)?([^/:]+)/i);

    if (domainMatch && domainMatch.length > 2) {
      const fullDomain = domainMatch[2];
      const parts = fullDomain.split(".");
      if (parts.length >= 2) {
        const domain = "." + parts.slice(-2).join(".");
        return domain;
      }
    }

    return null;
  }
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setDealerMembershipType(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
          handleGetCompanyProfileDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
      handleGetSelectCurrencyDropdowen();
      handleGetSeleCtlanguageDropdowen();
    }
  }, []);

  const initialstate = {
    selectCompany: "",
    tagLine: "",
    companyName: "",
    contactName: "",
    phoneNumber: "",
    webSite: "",
    email: "",
    dealerCompanySince: "",
    companyBio: "",
    isAgreeTrue: false,
    companyImage: "",
    ImagefileName: "",
    Imagefile: null,
    ImagefileName1: "",
    Imagefile1: null,
    ImagefileName2: "",
    Imagefile2: null,
    Error: {},
    Language: null,
    Currency: null,
  };

  const [editdata, setEditData] = useState(initialstate);
  const [sliderList, setSliderList] = useState([]);
  // const [imageNameList, setImageNameList] = useState([]);
  const [advertismentList, setAdvertismentList] = useState([]);
  const [seleCtlanguageDropdowenList, setSeleCtlanguageDropdowenList] =
    useState([]);
  const [selectCurrencyDropdowenList, setSelectCurrencyDropdowenList] =
    useState([]);

  const [validation, setValidations] = useState({
    companyNameVal: "",
    emailVal: "",
    companylogoVal: "",
    phoneNumberVal: "",
    mainSliderVal: "",
    AdvertisementSliderVal: "",
    sinceVal: "",
  });
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteMainSliderData, setDeleteMainSliderData] = useState({
    id: "",
  });

  const [deleteAdvertismentModalVisible, setDeleteAdvertismentModalVisible] =
    useState(false);
  const [deleteAdvertismentSliderData, setDeleteAdvertismentSliderData] =
    useState({
      id: "",
    });

  const showDeleteModal = (row) => {
    setDeleteModalVisible(true);
    setDeleteMainSliderData({
      id: row.id,
    });
  };

  const handleCancelDeleteModal = () => {
    setDeleteModalVisible(false);
    setDeleteMainSliderData({
      id: "",
    });
  };

  const showDeleteModal1 = (row) => {
    setDeleteAdvertismentModalVisible(true);
    setDeleteAdvertismentSliderData({
      id: row.id,
    });
  };

  const handleCancelDeleteModal1 = () => {
    setDeleteAdvertismentModalVisible(false);
    setDeleteAdvertismentSliderData({
      id: "",
    });
  };

  const handleCancel = () => {
    setVisible(false);

    setEditData({
      ...editdata,
      Imagefile: null,
      ImagefileName: "",
    });
    setValidations({
      ...validation,
      companylogoVal: "",
    });
    document.getElementById("fileCompany-upload").value = "";
  };

  // const handleUpload = () => {
  //   setVisible(false);
  // };

  const handleEdit = () => {
    setVisible(true);
  };

  const handleUploadModal = () => {
    setVisible1(true);
  };

  const handleMainSliderCancel = () => {
    setEditData({
      ...editdata,
      Imagefile1: null,
      ImagefileName1: "",
    });
  };

  const handleAdvertisementSliderCancel = () => {
    setEditData({
      ...editdata,
      Imagefile2: null,
      ImagefileName2: "",
    });
  };
  const handleCancelModal = () => {
    setVisible1(false);
    setEditData({
      ...editdata,
      Imagefile1: null,
      ImagefileName1: "",
    });
    setValidations({
      ...editdata,
      mainSliderVal: "",
    });
    handleGetCompanyProfileDetails(DealerId);
    document.getElementById("fileSlider-upload").value = "";
  };
  const handleUploadModal1 = () => {
    setVisible2(true);
  };
  const handleCancelModal1 = () => {
    setVisible2(false);
    setEditData({
      ...editdata,
      Imagefile2: null,
      ImagefileName2: "",
    });
    setValidations({
      AdvertisementSliderVal: "",
    });
    handleGetCompanyProfileDetails(DealerId);
    document.getElementById("fileAdvertisement-upload").value = "";
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleImageValidation = (upName) => {
    const validations = {
      companylogoVal: "",
      mainSliderVal: "",
      AdvertisementSliderVal: "",
    };
    var isValid = true;
    if (upName === "companyLogo") {
      if (editdata.ImagefileName == "" || null) {
        // editdata.Error["Imagefile"] = traslate("Please select image.");
        validations.companylogoVal = traslate("Please select image.");
        isValid = false;
      }
    }
    if (upName === "mainSlider") {
      if (editdata.ImagefileName1 == "" || null) {
        // editdata.Error["Imagefile"] = traslate("Please select image.");
        validations.mainSliderVal = traslate("Please select image.");
        isValid = false;
      }
    }
    if (upName === "AdvertisementSlider") {
      if (editdata.ImagefileName2 == "" || null) {
        // editdata.Error["Imagefile"] = traslate("Please select image.");
        validations.AdvertisementSliderVal = traslate("Please select image.");
        isValid = false;
      }
    }
    setValidations(validations);

    // setEditData((prevState) => ({
    //   ...prevState,
    //   ...editdata,
    // }));

    return isValid;
  };

  // Get Profile Data

  const handleGetCompanyProfileDetails = async (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    setMainSliderLoading(true);
    setAdvertisingLoading(true);
    setcompanyLogoLoading(true);
    try {
      await DealerProfileService.GetCompanyProfileDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          // var sliderList = responseData.imagedt[0];

          if (message === "Success") {
            if (responseData) {
              // setLoading(false);
              setSliderList(responseData.imagedt);
              setAdvertismentList(responseData.aDimagedt);
              handleGetSelectCurrencyForDelar(id);
              handleGetSelectlanguageForDelar(id);
              setUserType(responseData?.isVendor);
              setEditData((prevState) => ({
                ...prevState,
                companyName: responseData.dealerCompany,
                contactName: responseData.dealerName,
                phoneNumber: responseData.dealerPhone,
                webSite: responseData.websiteAddress,
                email: responseData.dealerEmail,
                companyImage: responseData.imageName,
                dealerCompanySince: responseData.dealerCompanySince,
                companyBio: responseData.companyAboutUs,
                ImagefileName: "",
                Imagefile: null,
                ImagefileName1: "",
                Imagefile1: null,
                ImagefileName2: "",
                Imagefile2: null,
                isAgreeTrue: responseData.dealerBioDetails,
              }));
              setIsImage(responseData.isImage == "True" ? true : false);
              setIsVideo(responseData.isVideo == "True" ? true : false);
              setMainSliderLoading(false);
              setAdvertisingLoading(false);
              setcompanyLogoLoading(false);
            }
          } else {
            // setLoading(false);
            setMainSliderLoading(false);
            setAdvertisingLoading(false);
            setcompanyLogoLoading(false);
          }
        })
        .catch((error) => {
          // setLoading(false);
          setMainSliderLoading(false);
          setAdvertisingLoading(false);
          setcompanyLogoLoading(false);
        });
    } catch (error) {
      // setLoading(false);
      setMainSliderLoading(false);
      setAdvertisingLoading(false);
      setcompanyLogoLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Update Data

  const handleUpdateCompanyProfileDetails = async () => {
    var isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    try {
      setBtnLoading(true);
      let inputData = {
        dealerID: DealerId,
        dealerName: editdata.contactName,
        dealerCompany: editdata.companyName,
        dealerEmail: editdata.email,
        dealerPhone: editdata.phoneNumber,
        websiteAddress: editdata.webSite,
        dealerCompanySince: editdata.dealerCompanySince,
        companyAboutUs: editdata.companyBio,
        isImage: isImage.toString(),
        isVideo: isVideo.toString(),
        dealerBioDetails: isAgreeTrue ? isAgreeTrue : false,
      };

      // setLoading(true);
      await DealerProfileService.UpdateCompanyProfileDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var msg = responseData.returnMessage;
          // setLoading(true);

          if (message === "Success") {
            NotificationManager.success("Record Updated Successfully");
            handleGetCompanyProfileDetails(DealerId);
            // handleUpdateCurrency();
            // handleUpdateLanguage();
            handleUpdateCurrency(editdata.Currency);
            handleUpdateLanguage(editdata.Language);
            setLoading(false);
            setBtnLoading(false);
            setDisableBtn(true);
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBtnLoading(false);
    }
    setVisible(false);
    setBtnLoading(false);
  };

  // Upload Company Logo

  const handleuploadimagecompanylogo = async (name) => {
    if (handleImageValidation(name)) {
      try {
        const formData = new FormData();
        formData.append("delarid", DealerId);
        formData.append("UploadFile", editdata.Imagefile);

        setcompanyLogoSaveLoading(true);
        await DealerProfileService.uploadimagecompanylogo(formData)
          .then((response) => {
            var message = response.data.message;
            // var responseData = response.data.responseData;

            if (message === "Success") {
              setVisible(false);
              setcompanyLogoSaveLoading(false);
              NotificationManager.success("Company Logo Updated Successfully.");
              editdata["Imagefile"] = null;
              editdata["ImagefileName"] = "";

              setEditData({
                ImagefileName: "",
                Imagefile: null,
              });
              handleGetCompanyProfileDetails(DealerId);
              document.getElementById("fileCompany-upload").value = "";
            } else {
              NotificationManager.error("Company Logo Not Updated.");
              setcompanyLogoSaveLoading(false);
              document.getElementById("fileCompany-upload").value = "";
            }
          })
          .catch((error) => {
            console.log(error);
            setcompanyLogoSaveLoading(false);
          });
      } catch (error) {
        setcompanyLogoSaveLoading(false);
        console.log(error);
        // }
      }
    }
  };

  // Main Slider Upload

  const handleUploadSliderimage = async (name) => {
    if (handleImageValidation(name)) {
      try {
        const formData = new FormData();
        formData.append("delarid", DealerId);
        formData.append("UploadFile", editdata.Imagefile1);
        setMainSliderLoading(true);
        await DealerProfileService.UploadSliderimage(formData)
          .then((response) => {
            var message = response.data.message;
            // var responseData = response.data.responseData;
            // setSubmitLoading(false);
            if (message === "Success") {
              setMainSliderLoading(false);
              NotificationManager.success("Record Uploaded Successfully");
              handleGetCompanyProfileDetails(DealerId);
              editdata["Imagefile1"] = null;
              editdata["ImagefileName1"] = "";
              document.getElementById("fileSlider-upload").value = "";
            } else {
              NotificationManager.success("Record Not Uploaded");
              setMainSliderLoading(false);
              document.getElementById("fileSlider-upload").value = "";
            }
          })
          .catch((error) => {
            console.log(error);
            setMainSliderLoading(false);
          });
      } catch (error) {
        setMainSliderLoading(false);
        console.log(error);
      }
    }
  };

  //Advertising Slider Upload

  const handleUploadAdverticingSliderimage = async (name) => {
    if (handleImageValidation(name)) {
      try {
        const formData = new FormData();
        formData.append("delarid", DealerId);
        formData.append("UploadFile", editdata.Imagefile2);

        setAdvertisingSaveLoading(true);
        await DealerProfileService.UploadAdverticingSliderimage(formData)
          .then((response) => {
            var message = response.data.message;
            // var responseData = response.data.responseData;
            // setSubmitLoading(false);
            if (message === "Success") {
              setAdvertisingSaveLoading(false);
              setVisible(false);
              NotificationManager.success("Record Uploaded Successfully");
              handleGetCompanyProfileDetails(DealerId);
              document.getElementById("fileAdvertisement-upload").value = "";
            } else {
              NotificationManager.success("Record Not Uploaded ");
              setAdvertisingSaveLoading(false);
              document.getElementById("fileAdvertisement-upload").value = "";
            }
          })
          .catch((error) => {
            console.log(error);
            setAdvertisingSaveLoading(false);
          });
      } catch (error) {
        setAdvertisingSaveLoading(false);
        console.log(error);
      }
    }
  };

  // Update Method for Main Slider

  const handleUpdateStoreImage = async (row) => {
    try {
      setBtnLoading(true);
      let inputData = {
        displayOrder: row.displayOrder,
        dealerID: DealerId,
        id: row.id,
      };

      // setLoading(true);
      await DealerProfileService.UpdateStoreImage(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var msg = responseData.returnMessage;
          // setLoading(true);

          if (message === "Success") {
            NotificationManager.success("Record Updated Successfully");
            handleGetCompanyProfileDetails(DealerId);
            setLoading(false);
            setBtnLoading(false);
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBtnLoading(false);
    }
    setVisible(false);
    setBtnLoading(false);
  };

  // Delete for Main Slider

  const handleDeleteStoreImage = async () => {
    try {
      setBtnLoading(true);
      let inputData = {
        id: deleteMainSliderData.id,
        dealerID: DealerId,
      };

      // setLoading(true);
      await DealerProfileService.DeleteStoreImage(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var msg = responseData.returnMessage;
          // setLoading(true);

          if (message == "True") {
            // setVisible1(false);
            NotificationManager.success("Record Deleted Successfully");
            handleGetCompanyProfileDetails(DealerId);
            setDeleteModalVisible(false);
            setLoading(false);
            setBtnLoading(false);
          } else {
            NotificationManager.error("Record Not Deleted.");
            setLoading(false);
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBtnLoading(false);
    }
    setVisible(false);
    setBtnLoading(false);
  };

  // Update for Advertising Slider

  const handleUpdateAdvertisingImage = async (row) => {
    try {
      setBtnLoading(true);
      let inputData = {
        displayOrder: row.displayOrder,
        dealerID: DealerId,
        id: row.id,
      };

      // setLoading(true);
      await DealerProfileService.UpdateAdvertisingImage(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var msg = responseData.returnMessage;
          // setLoading(true);

          if (message === "Success") {
            NotificationManager.success("Record Updated Successfully");
            handleGetCompanyProfileDetails(DealerId);
            setLoading(false);
            setBtnLoading(false);
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBtnLoading(false);
    }
    setVisible(false);
    setBtnLoading(false);
  };

  // Delete for Advertising Slider

  const handleDeleteImageFromAddSample = async () => {
    try {
      setBtnLoading(true);
      let inputData = {
        id: deleteAdvertismentSliderData.id,
        dealerID: DealerId,
      };

      // setLoading(true);
      await DealerProfileService.DeleteImageFromAddSample(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var msg = responseData.returnMessage;
          // setLoading(true);

          if (message == "True") {
            NotificationManager.success("Record Deleted Successfully");
            handleGetCompanyProfileDetails(DealerId);
            setLoading(false);
            setBtnLoading(false);
            setDeleteAdvertismentModalVisible(false);
          } else {
            NotificationManager.error("Record Not Deleted.");
            setLoading(false);
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBtnLoading(false);
    }
    setVisible(false);
    setBtnLoading(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    // Handle CKEditor or other manual changes
    if (name) {
      setEditData((prevState) => ({
        ...prevState,
        [name]: e.target ? e.target.value : e, // For CKEditor, e is the content
      }));
    } else if (isSelect === "Checked") {
      setEditData((prevState) => ({
        ...prevState,
        [name]: e.target.checked,
      }));
    } else if (isSelect === "select") {
      setEditData((prevState) => ({
        ...prevState,
        [name]: e,
      }));
    } else {
      // Handle normal input changes
      setEditData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleOrderChange = (row, e) => {
    // setDisableBtn(false);
    sliderList.filter((x) => x.id === row.id)[0].displayOrder = e.target.value;
    setSliderList([...sliderList]);
  };

  const handleAdvertismentOrderChange = (row, e) => {
    // setDisableBtn(false);
    advertismentList.filter((x) => x.id === row.id)[0].displayOrder =
      e.target.value;
    setAdvertismentList([...advertismentList]);
  };

  const fileUpload = async (e, name) => {
    var file = e.target.files[0];

    if (name === "companyLogo") {
      if (file?.name?.match(/.(png|jpg|jpeg)$/i)) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          if (img.width == 250 && img.height == 250) {
            setEditData({
              ...editdata,
              Imagefile: file,
              ImagefileName: file.name,
            });
            setValidations({
              ...validation,
              companylogoVal: "",
            });
          } else {
            setValidations({
              ...validation,
              companylogoVal: "Please Select Correct Image Size.",
            });
          }
        };
      } else {
        setEditData({
          ...editdata,
          Imagefile: null,
          ImagefileName: "",
        });
        setValidations({
          ...validation,
          companylogoVal: traslate("Please select Image File Only"),
        });
      }
    }
    if (name === "mainSlider") {
      if (file.name.match(/.(png|jpg|jpeg)$/i)) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          if (img.width == 1024 && img.height == 305) {
            setEditData({
              ...editdata,
              Imagefile1: file,
              ImagefileName1: file.name,
            });
            setValidations({
              ...validation,
              mainSliderVal: null,
            });
          } else {
            setValidations({
              ...validation,
              mainSliderVal: "Please Select Correct Image Size.",
            });
          }
        };
      } else {
        setEditData({
          ...editdata,
          Imagefile1: null,
          Imagefile1Name: "",
        });
        setValidations({
          ...validation,
          mainSliderVal: traslate("Please select Image File Only"),
        });
      }
    }

    if (name === "AdvertisementSlider") {
      if (file.name.match(/.(png|jpg|jpeg)$/i)) {
        setEditData({
          ...editdata,
          Imagefile2: file,
          ImagefileName2: file.name,
        });
        setValidations({
          ...validation,
          AdvertisementSliderVal: "",
        });
      } else {
        setEditData({
          ...editdata,
          Imagefile2: null,
          ImagefileName2: "",
        });
        setValidations({
          ...validation,

          AdvertisementSliderVal: traslate("Please select Image File Only"),
        });
      }
    }
  };

  const handleValidation = () => {
    const { companyName, email, phoneNumber, dealerCompanySince } = editdata;

    const validations = {
      companyNameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      sinceVal: "",
    };
    let isValid = true;

    if (!companyName) {
      isValid = false;
      validations.companyNameVal = traslate("Company name is compulsory");
    } else {
      validations.companyNameVal = "";
    }
    // var validRegex =
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // if (!email.trim()) {
    //   isValid = false;
    //   validations.emailVal = traslate("Email Address is compulsory");
    // } else if (!email.match(validRegex)) {
    //   isValid = false;
    //   validations.emailVal = traslate("Email Address is Invalid");
    // } else {
    //   validations.emailVal = "";
    // }
    var validRegex =
      /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;

    if (!email) {
      isValid = false;
      validations.emailVal = traslate("Email address is compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      validations.emailVal = traslate("Email address is invalid");
    } else {
      validations.emailVal = "";
    }

    // if (
    //   (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
    //   (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
    //   (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    // ) {
    //   validations.phoneNumberVal = "";
    // } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
    //   isValid = false;
    //   validations.phoneNumberVal = traslate("Phone number is invalid");
    // } else {
    //   validations.phoneNumberVal = "";
    // }
    // let regExpForAllZeros = /^0*$/g;

    if (!phoneNumber) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is compulsory");
    } else if (
      (phoneNumber.length < 15 &&
        phoneNumber.includes("-") &&
        /^[0-9]+$/.test(phoneNumber)) ||
      (phoneNumber.length < 15 &&
        phoneNumber.includes("+") &&
        /^[0-9]+$/.test(phoneNumber))
    ) {
      validations.phoneNumberVal = "";
    } else if (
      /^0*$/.test(phoneNumber) ||
      /[a-zA-Z@{}#$%?/^*&]/g.test(phoneNumber)
    ) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is invalid");
    } else if (phoneNumber.length > 15 || phoneNumber.length < 10) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is invalid");
    } else {
      validations.phoneNumberVal = "";
    }
    setValidations(validations);
    return isValid;
  };

  const {
    selectCompany,
    companyName,
    contactName,
    tagLine,
    companyImage,
    phoneNumber,
    webSite,
    email,
    dealerCompanySince,
    companyBio,
    isAgreeTrue,
    Language,
    Currency,
  } = editdata;
  const {
    companyNameVal,
    emailVal,
    phoneNumberVal,
    companylogoVal,
    mainSliderVal,
    AdvertisementSliderVal,
    sinceVal,
  } = validation;

  /*Owl carousel options starts*/
  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  /*Owl carousel options ends*/

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "50",
      render: (item, row) => {
        return (
          <div>
            <img src={row.imageName} width="50" height="50" />
          </div>
        );
      },
    },
    {
      title: traslate("Image Name"),
      dataIndex: "realName",
      width: "100",
      render: (item, row) => {
        return (
          <React.Fragment>
            <span>
              <span>{row.realName}</span>
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: traslate("Display Order"),
      dataIndex: "displayOrder",
      width: 80,
      render: (item, row) => {
        return (
          <React.Fragment>
            <div className="input__block mb-0">
              <input
                className="mr-2"
                type="text"
                value={row.displayOrder}
                name="displayorder"
                // onChange={(e)=>{
                //   handleOrderChange(row,e)
                // }}
                onChange={(e) => {
                  e.target.value = e.target.value.slice(0, 3);
                  handleOrderChange(row, e);
                }}
                onKeyPress={onKeyPressEvent}
              />
            </div>
          </React.Fragment>
        );
      },
    },
    {
      title: traslate("Action"),
      width: 150,
      render: (index, row) => (
        <div className="d-flex">
          <div className="image__block mr-2">
            {/* <img
              src={Edit_icon}
              onClick={(e) => handleUpdateStoreImage(row)}
              alt=""
            /> */}
            <span
              onClick={(e) => handleUpdateStoreImage(row)}
              className="linkText"
            >
              {" "}
              {traslate("Update")}{" "}
            </span>
          </div>
          <div className="image__block">
            <span
              onClick={(e) => {
                showDeleteModal(row);
              }}
              className="linkText"
            >
              {" "}
              {traslate("Delete")}{" "}
            </span>
            {/* <img
              src={Delete_iconbl}
              onClick={(e) => {
                handleDeleteStoreImage(row);
              }}
              onClick={(e) => {
                showConfirm(row);
                showDeleteModal(row);
              }}
            /> */}
          </div>
        </div>
      ),
    },
  ];

  const columns1 = [
    {
      title: "",
      dataIndex: "",
      render: (item, row) => {
        return (
          <div>
            <img src={row.imageName} width="50" height="50" />
          </div>
        );
      },
    },
    {
      title: traslate("Image Name"),
      dataIndex: "iName",
      width: "200",
      render: (item, row) => {
        return (
          <React.Fragment>
            <span>
              <span>{row.iName}</span>
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: traslate("Display Order"),
      dataIndex: "displayOrder",
      width: 80,
      render: (item, row) => {
        return (
          <React.Fragment>
            <div className="input__block mb-0">
              <input
                className="mr-2"
                type="text"
                value={row.displayOrder}
                name="displayorder"
                onChange={(e) => {
                  e.target.value = e.target.value.slice(0, 3);
                  handleAdvertismentOrderChange(row, e);
                }}
                onKeyPress={onKeyPressEvent}
              />
            </div>
          </React.Fragment>
        );
      },
    },
    {
      title: traslate("Action"),
      width: 150,
      render: (index, row) => (
        <div className="d-flex">
          <div className="image__block mr-2">
            <span
              onClick={(e) => handleUpdateAdvertisingImage(row)}
              className="linkText"
            >
              {" "}
              {traslate("Update")}{" "}
            </span>
            {/* <img
              src={Edit_icon}
              onClick={(e) => handleUpdateAdvertisingImage(row)}
              alt=""
            /> */}
          </div>
          <div className="image__block">
            <span
              onClick={(e) => {
                showDeleteModal1(row);
              }}
              className="linkText"
            >
              {" "}
              {traslate("Delete")}{" "}
            </span>
            {/* <img
              src={Delete_iconbl}
              onClick={(e) => {
                // handleDeleteImageFromAddSample(row);
                // AdvertimentshowConfirm(row);
                showDeleteModal1(row);
              }}
            /> */}
          </div>
        </div>
      ),
    },
  ];

  const handleGetSeleCtlanguageDropdowen = async () => {
    try {
      await AdminManageLanguageService.GetSeleCtlanguageDropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSeleCtlanguageDropdowenList(responseData);
            } else {
              setSeleCtlanguageDropdowenList([]);
            }
          } else {
            setSeleCtlanguageDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSelectCurrencyDropdowen = async () => {
    try {
      await AdminManageCurrencyService.GetSelectCurrencyDropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSelectCurrencyDropdowenList(responseData);
            } else {
              setSelectCurrencyDropdowenList([]);
            }
          } else {
            setSelectCurrencyDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSelectlanguageForDelar = async (id) => {
    try {
      let inputData = {
        delarId: id,
      };
      await AdminManageLanguageService.GetSelectlanguageForDelar(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setEditData((prevState) => ({
                ...prevState,
                Language: responseData.languageId,
              }));
              dispatch(setSelectedLanguage(responseData.language));
              setLangcookies("lngCookie", responseData.language, {
                path: "/",
                expires: new Date(Date.now() + 86400000),
                domain: domainSite,
              });
            } else {
              setEditData({
                ...editdata,
                Language: null,
              });
            }
          } else {
            setEditData({
              ...editdata,
              Language: null,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSelectCurrencyForDelar = async (id) => {
    try {
      let inputData = {
        delarId: id,
      };
      // setGetLoading(false);
      await AdminManageCurrencyService.GetSelectlanguageForDelar(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setEditData((prevState) => ({
                ...prevState,
                Currency: responseData.currencyId,
              }));
              dispatch(setCurrencySign(responseData.currencysign));
            } else {
              setEditData({
                ...editdata,
                Currency: null,
              });
            }
          } else {
            setEditData({
              ...editdata,
              Currency: null,
            });
          }
        })
        .catch((error) => {
          // setGetLoading(false);
          console.log(error);
        });
    } catch (error) {
      // setGetLoading(false);
      console.log(error);
    }
  };

  const handleUpdateLanguage = async (language) => {
    try {
      let inputData = {
        delarId: DealerId,
        languageId: language ? language : editdata.Language,
      };
      setLoading(true);
      await AdminManageLanguageService.UpdateLanguage(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              const value = seleCtlanguageDropdowenList.filter(function (item) {
                return item.languageId == language;
              });
              dispatch(setSelectedLanguage(value ? value[0].language : ""));
              setLangcookies("lngCookie", value ? value[0].language : "", {
                path: "/",
                expires: new Date(Date.now() + 86400000),
                domain: domainSite,
              });
              setLoading(false);
              // NotificationManager.success("Language Updated Successfully");
              handleGetSelectlanguageForDelar(DealerId);
              setDisableBtn(true);
            } else {
              // NotificationManager.error("Language Update failed");
              setLoading(false);
            }
          } else {
            // NotificationManager.error("Language Update failed");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCurrency = async (currency) => {
    try {
      let inputData = {
        delarId: DealerId,
        currencyId: currency ? currency : editdata.Currency,
      };
      setLoading(true);
      await AdminManageCurrencyService.UpdateCurrency(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              const currencyType = selectCurrencyDropdowenList.filter(
                (item) => item.currencyId == editdata.Currency
              )?.[0]?.currencyType;
              dispatch(setCurrency(currencyType));
              setLoading(false);
              // NotificationManager.success("Currency Updated Successfully");
              handleGetSelectlanguageForDelar(DealerId);
              setDisableBtn(true);
            } else {
              // NotificationManager.error("Currency Update failed");
              setLoading(false);
            }
          } else {
            // NotificationManager.error("Currency Update failed");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const checkKeyPress = (e) => {
  //   const keyCode = e.keyCode || e.which;
  //   const keyValue = String.fromCharCode(keyCode);

  //   if (
  //     e.key == "@" ||
  //     e.key == "#" ||
  //     e.key == "$" ||
  //     e.key == "%" ||
  //     e.key == "^" ||
  //     e.key == "!" ||
  //     e.key == "&" ||
  //     e.key == "*" ||
  //     e.key == "_" ||
  //     e.key == "|" ||
  //     e.key == ":" ||
  //     e.key == ";" ||
  //     e.key == "'" ||
  //     e.key == "=" ||
  //     e.key == "{" ||
  //     e.key == "}" ||
  //     e.key == "[" ||
  //     e.key == "]" ||
  //     e.key == "<" ||
  //     e.key == ">" ||
  //     e.key == "." ||
  //     e.key == "," ||
  //     e.key == "?" ||
  //     e.key == "~" ||
  //     e.key == "`" ||
  //     e.key == "/" ||
  //     e.code == "Quote" ||
  //     e.code == "Backslash" ||
  //     !new RegExp("[0-9]").test(keyValue)
  //   ) {
  //     e.preventDefault();
  //   }
  // };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv app__preview p-0 border-0">
              <div className="row">
                {/* Owl Carousel Slider starts */}
                <div className="col-md-12 companyProfile-slider-sp mb-4">
                  <Spin spinning={mainSliderLoading}>
                    <div className="slider__section">
                      {sliderList == null ? (
                        <OwlCarousel className="owl-theme" {...options}>
                          <div class="item">
                            <div className="slider__item">
                              <div className="bgimage">
                                <img src={Groupbg} />
                              </div>
                            </div>
                          </div>
                        </OwlCarousel>
                      ) : (
                        <OwlCarousel className="owl-theme" {...options}>
                          {sliderList &&
                            sliderList.map((item, i) => {
                              return (
                                <div class="item">
                                  <div className="slider__item">
                                    <div className="bgimage">
                                      <img src={item.imageName} />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </OwlCarousel>
                      )}
                    </div>
                  </Spin>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="col-md-12 mb-4 text-right">
                    <button
                      class="primary-btn"
                      loading={mainSliderSaveLoading}
                      onClick={handleUploadModal}
                    >
                      {" "}
                      {traslate("Upload")}{" "}
                    </button>
                  </div>
                  {/* Owl Carousel Slider ends */}
                  {/* <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {" "}
                    {traslate("Company Detail")}{" "}
                  </h4>
                </div> */}
                  <Spin spinning={loading}>
                    <div className="col-lg-12 col-md-12">
                      <div className="prodesc__div">
                        <div className="row">
                          <div className="col-md-3">
                            <Spin spinning={companyLogoLoading}>
                              <h3 className="workarea__heading mt-0 mb-3 align__center">
                                {traslate("Company Logo")}{" "}
                              </h3>
                              <div className="image__block p-0">
                                <img
                                  className="img-fluid w-100"
                                  src={companyImage}
                                />
                                <span className="left_section_form mb-3">
                                  250 X 250 {traslate("Pixels")}
                                </span>
                              </div>
                              <div className="text-right">
                                <button
                                  onClick={handleEdit}
                                  className="primary-btn mt-3"
                                >
                                  {" "}
                                  {traslate("Edit")}{" "}
                                </button>
                              </div>
                            </Spin>
                            {/* Slider starts */}
                            <h3 className="workarea__heading mt-3 mb-3 align__center">
                              {traslate("Advertising Samples")}{" "}
                            </h3>
                            <div className="image__block p-0">
                              <div className="col-md-12 companyProfile-slider-sp">
                                <div className="slider__section">
                                  <Spin spinning={advertisingLoading}>
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...options}
                                    >
                                      {/* <div class='item'>
                                    <div className="slider__item">
                                      <div className="bgimage">
                                        <img src={Groupbg} />
                                      </div>
                                    </div>
                                  </div>
                                  <div class='item'>
                                    <div className="slider__item">
                                      <div className="bgimage">
                                        <img src={BannerBgImg} />
                                      </div>
                                    </div>
                                  </div> */}

                                      {advertismentList &&
                                        advertismentList.map((item, i) => {
                                          return (
                                            <div class="item">
                                              <div className="slider__item">
                                                <div className="bgimage">
                                                  <img src={item.imageName} />
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </OwlCarousel>
                                  </Spin>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 text-right">
                              <button
                                onClick={handleUploadModal1}
                                class={
                                  advertismentList &&
                                  advertismentList.length > 0
                                    ? "primary-btn mt-3"
                                    : "primary-btn mt-3"
                                }
                              >
                                {" "}
                                {traslate("Upload")}{" "}
                              </button>
                            </div>
                            {/* Slider ends */}
                          </div>

                          <div className="col-md-9">
                            <div className="row">
                              {/* <div className="col-md-6">
                              <div className="input__block">
                                <label>Select Company</label>
                                <Select

                                  className="border__grey"
                                  placeholder="Select Company"
                                  optionFilterProp="children"
                                  value={selectCompany ? selectCompany : null}
                                  onChange={(e) => handleOnChange(e, "select", "selectCompany")}
                                >

                                  <Option value="1">Company 1</Option>
                                  <Option value="2">Option 2</Option>
                                  <Option value="3">Option 3</Option>
                                </Select>
                              </div>
                            </div> */}

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("Company Name")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Company Name"
                                    name="companyName"
                                    value={companyName ? companyName : ""}
                                    onChange={handleOnChange}
                                    className={companyNameVal && "border__red"}
                                  />
                                  <div>
                                    {companyNameVal && (
                                      <p className="error-color-red">
                                        {companyNameVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("Primary Contact Name")}{" "}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Contact Name"
                                    name="contactName"
                                    value={contactName ? contactName : ""}
                                    onChange={handleOnChange}
                                  />
                                </div>
                              </div>

                              {/* <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Tag Line</label>
                                                            <input type="text"
                                                             placeholder="Enter Tag Line"
                                                             name="tagLine"
                                                             value={tagLine ? tagLine : ""}
                                                             onChange={handleOnChange} />
                                                        </div>
                                                    </div>                                                     */}

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("Primary Phone")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    name="phoneNumber"
                                    value={phoneNumber ? phoneNumber : ""}
                                    onChange={handleOnChange}
                                    className={phoneNumberVal && "border__red"}
                                  />

                                  {phoneNumberVal && (
                                    <span className="error-color-red">
                                      {phoneNumberVal}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label> {traslate("Web Site")} </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Web Site"
                                    name="webSite"
                                    value={webSite ? webSite : ""}
                                    onChange={handleOnChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {" "}
                                    {traslate("Primary E-mail")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Email Address"
                                    name="email"
                                    value={email ? email : ""}
                                    onChange={handleOnChange}
                                    className={emailVal && "border__red"}
                                  />
                                  {emailVal && (
                                    <span className="error-color-red error-color-red-left">
                                      {emailVal}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <label> {traslate("Since")}: </label>
                                  <input
                                    type="text"
                                    name="dealerCompanySince"
                                    value={
                                      dealerCompanySince
                                        ? dealerCompanySince
                                        : ""
                                    }
                                    // onChange={handleOnChange}
                                    onChange={(e) => {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        4
                                      );
                                      handleOnChange(e, "dealerCompanySince");
                                    }}
                                    // className={sinceVal && "border__red"}
                                    onKeyPress={onKeyPressEvent}
                                  />
                                  {/* {sinceVal && (
                                    <span className="error-color-red">
                                      {sinceVal}
                                    </span>
                                  )} */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block mb-3">
                                  <label> {traslate("Select Language")} </label>
                                  <Select
                                    className="border__grey language-start-sp"
                                    showSearch
                                    placeholder="Choose Any"
                                    name="Language"
                                    value={Language ? Language : null}
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(e, "select", "Language")
                                    }
                                  >
                                    <Option value="">
                                      {" "}
                                      {traslate("Select Language")}{" "}
                                    </Option>
                                    {seleCtlanguageDropdowenList.map((item) => {
                                      return (
                                        <React.Fragment>
                                          <Option value={item.languageId}>
                                            {item.language}
                                          </Option>
                                        </React.Fragment>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </div>

                              <div className="col-lg-6 mt-0">
                                <div className="input__block mb-0">
                                  <label> {traslate("Select Currency")} </label>
                                  <Select
                                    className="border__grey language-start-sp"
                                    showSearch
                                    placeholder="Choose Any"
                                    name="Currency"
                                    value={Currency ? Currency : null}
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(e, "select", "Currency")
                                    }
                                    disabled={userType}
                                  >
                                    <Option value="">
                                      {" "}
                                      {traslate("Select Currency")}{" "}
                                    </Option>
                                    {selectCurrencyDropdowenList.map((item) => {
                                      return (
                                        <React.Fragment>
                                          <Option value={item.currencyId}>
                                            {item.currencyType}
                                          </Option>
                                        </React.Fragment>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </div>

                              {dealerMemberTypeName !== "Vendor" &&
                              dealerMemberTypeName !== "VendorDiamond" ? (
                                <div className="row">
                                  <label>
                                    {" "}
                                    {traslate("Media Preferences")}{" "}
                                  </label>
                                  <div className="col-md-6 mt-1">
                                    <Checkbox
                                      onChange={(e) => {
                                        setIsVideo(e.target.checked);
                                        setDisableBtn(false);
                                      }}
                                      checked={isVideo == true ? true : false}
                                    >
                                      <label>
                                        {" "}
                                        {traslate("Display Video")}{" "}
                                      </label>
                                    </Checkbox>
                                  </div>
                                  <div className="col-md-6">
                                    <Checkbox
                                      onChange={(e) => {
                                        setIsImage(e.target.checked);
                                        setDisableBtn(false);
                                      }}
                                      checked={isImage === true ? true : false}
                                    >
                                      <label>
                                        {" "}
                                        {traslate("Display Image")}{" "}
                                      </label>
                                    </Checkbox>
                                  </div>
                                </div>
                              ) : null}

                              <div className="col-md-12 mt-4">
                                <div className="input__block">
                                  <label> {traslate("Company Bio")} </label>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={editdata.companyBio}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      handleOnChange(data, null, "companyBio");
                                    }}
                                  />
                                  {editdata.Error &&
                                    editdata.Error["companyBio"] && (
                                      <div className="text-danger">
                                        {editdata.Error["companyBio"]}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <Button
                                onClick={handleUpdateCompanyProfileDetails}
                                // className="primary-btn disabled-btn"
                                className={
                                  disableBtn ? "disabled-btn" : "primary-btn"
                                }
                                disabled={disableBtn}
                                loading={btnloading}
                              >
                                {traslate("Update Profile")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spin spinning={companyLogoSaveLoading}>
          <Modal
            title={traslate("Upload Images")}
            className="modal__section"
            centered
            visible={visible}
            onOk={handleuploadimagecompanylogo}
            onCancel={() => handleCancel()}
            width={400}
            footer={[
              <Button key="back" onClick={handleCancel}>
                {traslate("Cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleuploadimagecompanylogo("companyLogo")}
              >
                {traslate("Upload")}
              </Button>,
            ]}
          >
            <div className="form__fields border-0 m-0 p-0">
              <div className="input__block">
                <div className="image_block border-0 p-0">
                  <label> {traslate("Select File")} </label>
                  <div className="divimage_block">
                    <label
                      htmlFor="fileCompany-upload"
                      class="primary-btn choose_file_upload"
                    >
                      {traslate("Choose File")}
                    </label>
                    <input
                      id="fileCompany-upload"
                      type="file"
                      name="companyLogo"
                      onChange={(e) => fileUpload(e, "companyLogo")}
                    />

                    {editdata.ImagefileName ? (
                      editdata.ImagefileName
                    ) : (
                      <span className="mb-4">
                        {/* {" "}
                        {traslate("No File Choosen")}{" "} */}
                        {traslate("No File Chosen")}
                      </span>
                    )}
                    {editdata.Error ? (
                      <div className="text-danger">
                        {editdata.Error["Imagefile"]}
                      </div>
                    ) : null}

                    <div>
                      {companylogoVal && (
                        <p className="error-color-red">{companylogoVal}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Spin>

        {/* mainSlider modal starts */}
        <Modal
          title={traslate("Upload Images")}
          className="modal__section"
          centered
          visible={visible1}
          onOk={handleUploadSliderimage}
          onCancel={() => handleCancelModal()}
          width={700}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              {traslate("Cancel")}
            </Button>,
            <Button
              loading={mainSliderSaveLoading}
              key="submit"
              type="primary"
              onClick={(e) => handleUploadSliderimage("mainSlider")}
            >
              {traslate("Upload")}
            </Button>,
          ]}
        >
          <div className="col-md-12 font__bold">{traslate("Select Files")}</div>
          <div className="form__fields border-0 m-0 p-0">
            <div className="input__block col-md-4">
              <div className="image_block border-0 p-0">
                {editdata.ImagefileName1 ? (
                  <div className="divimage_block">
                    <span
                      onClick={handleMainSliderCancel}
                      className="close_image"
                    >
                      x
                    </span>
                    <img
                      className="img-fluid"
                      src={URL.createObjectURL(editdata.Imagefile1)}
                    />
                  </div>
                ) : (
                  <div className="divimage_block">
                    <label
                      htmlFor="fileSlider-upload"
                      class="primary-btn choose_file_upload"
                    >
                      {traslate("Choose Image")}
                    </label>
                    <input
                      id="fileSlider-upload"
                      type="file"
                      onChange={(e) => fileUpload(e, "mainSlider")}
                    />

                    {editdata.ImagefileName1 ? (
                      editdata.ImagefileName1
                    ) : (
                      <span className="mb-4">
                        {/* {" "}
                        {traslate("No File Choosen")}{" "} */}
                        {traslate("No File Chosen")}
                      </span>
                    )}
                    <span>1024 X 305 {traslate("Pixels")} </span>

                    <div>
                      {mainSliderVal && (
                        <p className="error-color-red">{mainSliderVal}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12 form__fields">
            <Spin spinning={mainSliderLoading}>
              <div className="table__scroll">
                <Table
                  columns={columns}
                  dataSource={sliderList}
                  scroll={{ x: 600, y: 800 }}
                />
              </div>
            </Spin>
          </div>
        </Modal>
        {/* mainSlider modal ends */}

        {/* For Advertising Sample starts */}

        <Modal
          title={traslate("Upload Images")}
          className="modal__section"
          centered
          visible={visible2}
          onOk={handleUploadAdverticingSliderimage}
          onCancel={() => handleCancelModal1()}
          width={700}
          footer={[
            <Button key="back" onClick={handleCancelModal1}>
              {traslate("Cancel")}
            </Button>,
            <Button
              loading={advertisingSaveLoading}
              key="submit"
              type="primary"
              onClick={(e) =>
                handleUploadAdverticingSliderimage("AdvertisementSlider")
              }
            >
              {traslate("Upload")}
            </Button>,
          ]}
        >
          <div className="form__fields border-0 m-0 p-0">
            <div className="col-md-12 font__bold">
              {traslate("Select Files")}
            </div>
            <div className="input__block col-md-4">
              <div className="image_block border-0 p-0">
                {editdata.ImagefileName2 ? (
                  <div className="divimage_block">
                    <span
                      onClick={handleAdvertisementSliderCancel}
                      className="close_image"
                    >
                      x
                    </span>
                    <img
                      className="img-fluid"
                      src={URL.createObjectURL(editdata.Imagefile2)}
                    />
                  </div>
                ) : (
                  <div className="divimage_block">
                    <label
                      htmlFor="fileAdvertisement-upload"
                      class="primary-btn choose_file_upload"
                    >
                      {traslate("Choose File")}
                    </label>
                    <input
                      id="fileAdvertisement-upload"
                      type="file"
                      onChange={(e) => fileUpload(e, "AdvertisementSlider")}
                    />

                    {editdata.ImagefileName2 ? (
                      editdata.ImagefileName2
                    ) : (
                      <span className="mb-4">
                        {/* {" "}
                        {traslate("No File Choosen")}{" "} */}
                        {traslate("No File Chosen")}
                      </span>
                    )}

                    <div>
                      {AdvertisementSliderVal && (
                        <p className="error-color-red">
                          {AdvertisementSliderVal}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12 form__fields">
            <Spin spinning={advertisingSaveLoading}>
              <Table
                columns={columns1}
                dataSource={advertismentList}
                scroll={{ x: 600, y: 800 }}
              />
            </Spin>
          </div>
        </Modal>
        {/* For Advertising Sample ends */}

        {/* Delete Confirmation Modal */}
        <Modal
          title={traslate("Delete")}
          className="modalconsupld__section"
          centered
          visible={deleteModalVisible}
          onOk={handleDeleteStoreImage}
          onCancel={handleCancelDeleteModal}
          width={400}
          footer={[
            <Button key="back" onClick={handleCancelDeleteModal}>
              {traslate("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleDeleteStoreImage}
            >
              {traslate("Delete")}
            </Button>,
          ]}
        >
          <div className="col-lg-12">
            <div className="modal__body">
              <div className="col-lg-12">
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <p>{traslate("Are you sure you want to delete")}</p>
                      <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Delete Advertisment Modal */}

        <Modal
          title={traslate("Delete")}
          className="modalconsupld__section"
          centered
          visible={deleteAdvertismentModalVisible}
          onOk={handleDeleteImageFromAddSample}
          onCancel={handleCancelDeleteModal1}
          width={400}
          footer={[
            <Button key="back" onClick={handleCancelDeleteModal1}>
              {traslate("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleDeleteImageFromAddSample}
            >
              {traslate("Delete")}
            </Button>,
          ]}
        >
          <div className="col-lg-12">
            <div className="modal__body">
              <div className="col-lg-12">
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <p>{traslate("Are you sure you want to delete")}</p>
                      <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CompanyProfile;
