import React, { useEffect, useState, useRef } from "react";
import { Select, Button, Modal, Table } from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import AdminSettingsService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import traslate from "../../../i18n/translate";
import Config from "./../../../helper/config";

const initialTradeShowData = {
  id: 0,
  image: "",
  showName: "",
  city: "",
  state: "",
  website: "",
  showStartTime: null,
  showEndTime: null,
  showStartDate: "",
  showEndDate: "",
  showsImagePath: ""
};
const initialValidations = {
  imageVal: "",
  showNameVal: "",
  cityVal: "",
  stateVal: "",
  websiteVal: "",
  showStartTimeVal: "",
  showEndTimeVal: "",
  showStartDateVal: "",
  showEndDateVal: "",
  showsImagePath: "",
  fileVal: ""
};

const { Option } = Select;
const TradeShows = () => {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIdEdit] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tradeShowList, setTradeShowList] = useState([]);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [tradeShowData, setTradeShowData] = useState(initialTradeShowData);
  const [validations, setValidations] = useState(initialValidations);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("showName");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    handleGetShowsDetailsList(pageNo, pageSize, sortColumnName, sortOrder);
  }, [pageNo, pageSize, sortColumnName, sortOrder]);

  const handleCancel = () => {
    setVisible(false);
    setIdEdit(false);
    setTradeShowData({
      id: 0,
      image: "",
      showName: "",
      city: "",
      state: "",
      website: "",
      showStartTime: "",
      showEndTime: "",
      showStartDate: "",
      showEndDate: ""
    });
    setValidations({
      imageVal: "",
      showNameVal: "",
      cityVal: "",
      stateVal: "",
      websiteVal: "",
      showStartTimeVal: "",
      showEndTimeVal: "",
      showStartDateVal: "",
      showEndDateVal: ""
    });
  };

  const handleOk2 = () => {
    handleDeleteShowsDetailsByID();
  };

  const handleCancel2 = () => {
    setDeleteRecordId(null);
    setVisible2(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    const tempValidations = validations;
    var validAlphabet = /^[a-zA-Z ]*$/;

    if (isSelect === "select") {
      setTradeShowData({
        ...tradeShowData,
        [name]: e
      });
      if (e === "closed") {
        hideEndTime(e);
      } else {
        showEndTime();
      }
      // Onchange remove Validation
      if (name === "showStartTime") {
        setValidations((prevdata) => ({
          ...prevdata,
          showStartTimeVal: ""
        }));
      }
      if (name === "showEndTime") {
        setValidations((prevdata) => ({
          ...prevdata,
          showEndTimeVal: ""
        }));
      }
    } else {
      if (e.target.name === "city") {
        if (!e.target.value.match(validAlphabet)) {
          tempValidations.cityVal = traslate("Please Enter Only Alphabet.");
          setTradeShowData({
            ...tradeShowData,
            [e.target.name]: e.target.value
          });
        } else {
          tempValidations.cityVal = "";
          setTradeShowData({
            ...tradeShowData,
            [e.target.name]: e.target.value
          });
        }
      } else if (e.target.name === "state") {
        if (!e.target.value.match(validAlphabet)) {
          tempValidations.stateVal = traslate("Please Enter Only Alphabet.");
          setTradeShowData({
            ...tradeShowData,
            [e.target.name]: e.target.value
          });
        } else {
          tempValidations.stateVal = "";
          setTradeShowData({
            ...tradeShowData,
            [e.target.name]: e.target.value
          });
        }
      } else if (e.target.name === "showName") {
        tempValidations.showNameVal = "";
        setTradeShowData({
          ...tradeShowData,
          [e.target.name]: e.target.value
        });
      } else if (e.target.name === "website") {
        tempValidations.websiteVal = "";
        setTradeShowData({
          ...tradeShowData,
          [e.target.name]: e.target.value
        });
      } else if (e.target.name === "showStartDate") {
        tempValidations.showStartDateVal = "";
        setTradeShowData({
          ...tradeShowData,
          [e.target.name]: e.target.value
        });
      } else if (e.target.name === "showEndDate") {
        tempValidations.showEndDateVal = "";
        setTradeShowData({
          ...tradeShowData,
          [e.target.name]: e.target.value
        });
      } else {
        setTradeShowData({
          ...tradeShowData,
          [e.target.name]: e.target.value
        });
      }

      setValidations({
        ...validations,
        tempValidations
      });
    }
  };
  const handleValidation = () => {
    const {
      file,
      showName,
      state,
      city,
      website,
      showStartTime,
      showEndTime,
      showStartDate,
      showEndDate,
      showsImagePath
    } = tradeShowData;
    const validations = {
      imageVal: "",
      showNameVal: "",
      cityVal: "",
      stateVal: "",
      websiteVal: "",
      showStartTimeVal: "",
      showEndTimeVal: "",
      showStartDateVal: "",
      showEndDateVal: "",
      file: ""
    };
    let isValid = true;

    if (!showsImagePath && !file) {
      isValid = false;
      validations.file = traslate("Please select the image");
    }
    if (!showName.trim()) {
      isValid = false;
      validations.showNameVal = traslate("ShowName is compulsory");
    }
    if (!city.trim()) {
      isValid = false;
      validations.cityVal = traslate("City is compulsory");
    }
    if (!website.trim()) {
      isValid = false;
      validations.websiteVal = traslate("Website is compulsory");
    }
    if (!state.trim()) {
      isValid = false;
      validations.stateVal = traslate("State is compulsory");
    }
    if (!showStartTime) {
      isValid = false;
      validations.showStartTimeVal = traslate(
        "Kindly select the Show Start Time"
      );
    }
    if (!showEndTime) {
      isValid = false;
      validations.showEndTimeVal = traslate("Kindly select the Show End Time");
    }
    if (!showStartDate) {
      isValid = false;
      validations.showStartDateVal = traslate(
        "Kindly select the Show Start Date"
      );
    }
    if (!showEndDate) {
      isValid = false;
      validations.showEndDateVal = traslate("Kindly select the Show End Date");
    } else if (showEndDate < showStartDate) {
      isValid = false;
      validations.showEndDateVal = traslate(
        "Kindly select the End Date greater than the Start Date"
      );
    }
    if (!isValid) {
      setValidations(validations);
    }
    return isValid;
  };

  const {
    file,
    showNameVal,
    cityVal,
    stateVal,
    websiteVal,
    showStartTimeVal,
    showEndTimeVal,
    showStartDateVal,
    showEndDateVal,
    fileVal
  } = validations;

  const handleOpenDeleteModal = (id) => {
    setDeleteRecordId(id);
    setVisible2(true);
  };
  const handleGetShowsDetailsList = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    let inputData = {
      pageNumber: pageNo.toString(),
      pageSize: pageSize.toString(),
      sortColumnName: sortColumnName || "showName",
      sortOrder: sortOrder || "asc"
    };
    setLoading(true);
    AdminSettingsService.GetShowsDetailsList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;

        if (message === "Success") {
          setTradeShowList(responseData.masterShowsNameResponses);
          setTotalCount(responseData.totalRecord);
        } else {
          setTradeShowList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "desc";
      column = sorter.field;
      setSortOrder(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "asc";
      column = sorter.field;
      setSortOrder(type);
      setSortColumnName(column);
    }
    // else {
    //   type = "asc";
    //   column = "showName";
    //   setSortColumnName(column);
    //   setSortOrder(type);
    // }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetShowsDetailsList(
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  const handleDeleteShowsDetailsByID = () => {
    let inputData = {
      id: deleteRecordId
    };
    setSubmitLoading(true);
    AdminSettingsService.DeleteShowsDetailsByID(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(
            traslate("Trade Show has been Deleted Successfully")
          );
          setDeleteRecordId(null);
          setVisible2(false);
          handleGetShowsDetailsList(
            tradeShowList.length > 1 ? pageNo : pageNo - 1,
            pageSize
          );
        } else {
          NotificationManager.error(
            "Trade Show has not been Deleted Successfully"
          );
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleGetShowsDetailsByID = (showId) => {
    let inputData = {
      id: showId
    };
    setVisible(true);
    setIdEdit(true);
    AdminSettingsService.GetShowsDetailsByID(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            var tempData = responseData[0];
            var tempObj = {
              image: tempData.showsImagePath,
              id: tempData.id,
              showName: tempData.showName,
              city: tempData.city,
              state: tempData.state,
              website: tempData.website,
              showStartTime: tempData.showOpenTime,
              showEndTime: tempData.showCloseTime,
              showStartDate: moment(tempData.showStartDate)
                .format("YYYY-MM-DD")
                .toString(),
              showEndDate: moment(tempData.showEndDate)
                .format("YYYY-MM-DD")
                .toString(),
              showsImagePath: tempData.showsImagePath
            };
            setTradeShowData((prevState) => ({
              ...prevState,
              ...tempObj
            }));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const handleSaveShowsDetailsByID = () => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append("ID", tradeShowData.id || 0);
      formData.append("ShowName", tradeShowData.showName);
      formData.append("City", tradeShowData.city);
      formData.append("website", tradeShowData.website);
      formData.append("State", tradeShowData.state);
      formData.append(
        "ShowStartDate",
        tradeShowData.showStartDate.replaceAll("-", "/")
      );
      formData.append(
        "showEndDate",
        tradeShowData.showEndDate.replaceAll("-", "/")
      );
      formData.append("ShowOpenTime", tradeShowData.showStartTime);
      formData.append("ShowCloseTime", tradeShowData.showEndTime);
      formData.append("IsClosed", false);
      formData.append("ShowsImage_Path", tradeShowData.file || null);
      formData.append(
        "ShowsImagePath",
        tradeShowData.showsImagePath == "" ||
          tradeShowData.showsImagePath == null
          ? tradeShowData.file.name
          : tradeShowData.showsImagePath.replace(
              `${Config.BlobURL}gemfind2staging/AdminShowsImages/`,
              ""
            )
      );
      setSubmitLoading(true);
      AdminSettingsService.SaveShowsDetailsByID(formData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setVisible(false);
            handleGetShowsDetailsList(pageNo, pageSize);
            handleCancel();
            if (isEdit) {
              NotificationManager.success(
                traslate("Trade Show has been Updated Successfully")
              );
            } else {
              NotificationManager.success(
                traslate("Trade Show has been Created Successfully")
              );
            }
          } else {
            if (isEdit) {
              NotificationManager.error(
                traslate("Trade Show has not been Updated Successfully")
              );
            } else {
              NotificationManager.error(
                traslate("Trade Show has not been  Created Successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };
  const fileUpload = async (e) => {
    if (e.target.files[0].name) {
      if (
        e.target.files[0].name.toLowerCase().match(/.(jpg|jpeg|png|gif||PNG)$/i)
      ) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          if (img.width <= 190 && img.height <= 190) {
            setTradeShowData({
              ...tradeShowData,
              ["file"]: e.target.files[0]
            });
            setValidations({
              ...validations,
              ["file"]: null
            });
          } else {
            setValidations({
              ...validations,
              ["file"]: traslate("Please Select Correct Image Size.")
            });
          }
        };
      }
    } else {
      setValidations({
        ...validations,
        ["file"]: traslate("Please Select Only Images.")
      });
      setTradeShowData({
        ...tradeShowData,
        ["file"]: null
      });
    }
  };
  const handleCancelImage = () => {
    setTradeShowData((prevState) => ({
      ...prevState,
      ["showsImagePath"]: null
    }));

    setTradeShowData((prevState) => ({
      ...prevState,
      ["file"]: null
    }));
  };
  const closedOption = useRef();
  const hideEndTime = (elem) => {
    closedOption.current.style.display = "none";
  };
  const showEndTime = (elem) => {
    closedOption.current.style.display = "block";
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetShowsDetailsList(page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetShowsDetailsList(current, size);
  }
  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {" "}
                    {traslate("Trade Shows")}{" "}
                  </h4>
                </div>
                <div className="col-lg-6">
                  <div className="addedit__btndiv">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible(true)}
                    >
                      {" "}
                      <img src={Create_icon} /> {traslate("Trade Show")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="tradeshow_tbldiv">
                <Table
                  columns={[
                    {
                      title: traslate("Show"),
                      dataIndex: "showName",
                      width: 350,
                      sorter: (a, b) => a.showName.localeCompare(b.showName)
                    },
                    {
                      title: traslate("City"),
                      dataIndex: "city",
                      width: 120,
                      sorter: (a, b) => a.city.localeCompare(b.city)
                    },
                    {
                      title: traslate("State"),
                      dataIndex: "state",
                      width: 120,
                      sorter: (a, b) => a.state.localeCompare(b.state)
                    },
                    {
                      title: traslate("Dates"),
                      dataIndex: "ShowStartDate",
                      width: 250,
                      render: (item, rows) => {
                        return (
                          <>{rows.showStartDate + " - " + rows.showEndDate}</>
                        );
                      },
                      sorter: (a, b) => {
                        const startDateA = new Date(a.showStartDate);
                        const endDateB = new Date(b.showEndDate);
                        return startDateA - endDateB;
                      }
                      // sorter: (a, b) =>
                      //   a.showStartDate.localeCompare(b.showStartDate),
                    },
                    {
                      title: traslate("Action"),
                      dataIndex: "action",
                      render: (item, row) => {
                        return (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() =>
                                  handleGetShowsDetailsByID(row.id)
                                }
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => handleOpenDeleteModal(row.id)}
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      },
                      width: 100
                    }
                  ]}
                  onChange={handleTableASCDES}
                  pagination={{
                    total: totalCount,
                    pageNo: pageNo,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                      setPageNo(page);
                      setPageSize(pageSize);
                    },
                    onShowSizeChange: handlePageSizeChange
                  }}
                  dataSource={tradeShowList}
                  scroll={{ x: 600, y: 800 }}
                  loading={loading}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={
                  !isEdit
                    ? traslate("Add Trade Show")
                    : traslate("Edit Trade Show")
                }
                className="modal__tradeshow"
                centered
                visible={visible}
                onOk={() => handleSaveShowsDetailsByID()}
                onCancel={() => handleCancel()}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleSaveShowsDetailsByID()}
                    loading={submitLoading}
                  >
                    {!isEdit ? traslate("Submit") : traslate("Save")}
                  </Button>
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div class="col-lg-12">
                            <div className="col-md-6">
                              <div className="divimage_block w-100">
                                {tradeShowData.showsImagePath ||
                                tradeShowData.file ? (
                                  <div className="img__showdiv">
                                    {isEdit ? (
                                      tradeShowData.file ? (
                                        <img
                                          src={URL.createObjectURL(
                                            tradeShowData.file
                                          )}
                                        />
                                      ) : (
                                        <img
                                          src={tradeShowData.showsImagePath}
                                        />
                                      )
                                    ) : tradeShowData.file ? (
                                      <img
                                        src={URL.createObjectURL(
                                          tradeShowData.file
                                        )}
                                      />
                                    ) : null}
                                    <span onClick={handleCancelImage}>x</span>
                                  </div>
                                ) : (
                                  <div>
                                    <label
                                      htmlFor="file-upload"
                                      class="primary-btn choose_file_upload"
                                    >
                                      {traslate("Choose Image")}
                                    </label>
                                    <input
                                      id="file-upload"
                                      type="file"
                                      onChange={(e) => fileUpload(e)}
                                    />
                                    {fileVal && (
                                      <p className="error-color-red">
                                        {fileVal}
                                      </p>
                                    )}
                                    <span class="mb-4">
                                      {" "}
                                      {traslate("No File Choosen")}{" "}
                                    </span>
                                    <span class="">
                                      190 X 190 {traslate("Pixels")}{" "}
                                    </span>
                                    <span class="">
                                      {" "}
                                      {traslate(".JPG | .JPEG | .PNG Format")}
                                    </span>
                                  </div>
                                )}
                                {validations["file"] ? (
                                  <span className="error-color-red">
                                    {validations["file"]}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Show Name")} <span>*</span>
                              </label>
                              <input
                                name="showName"
                                value={tradeShowData.showName}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Show Name"
                                className={showNameVal && "border__red"}
                              />
                              {showNameVal && (
                                <p className="error-color-red">{showNameVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("City")} <span>*</span>
                              </label>
                              <input
                                name="city"
                                value={tradeShowData.city}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter City"
                                className={cityVal && "border__red"}
                                autoComplete="off"
                              />
                              {cityVal && (
                                <p className="error-color-red">{cityVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("State")} <span>*</span>
                              </label>
                              <input
                                name="state"
                                value={tradeShowData.state}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter State"
                                className={stateVal && "border__red"}
                                autoComplete="off"
                              />
                              {stateVal && (
                                <p className="error-color-red">{stateVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Website")} <span>*</span>
                              </label>
                              <input
                                name="website"
                                value={tradeShowData.website}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Website"
                                className={websiteVal && "border__red"}
                              />
                              {websiteVal && (
                                <p className="error-color-red">{websiteVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Show Start Time")} <span>*</span>
                              </label>
                              <Select
                                name="showStartTime"
                                className={
                                  showStartTimeVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                                showSearch
                                placeholder="Select Start Time"
                                optionFilterProp="children"
                                value={tradeShowData.showStartTime}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "showStartTime")
                                }
                              >
                                <Option value="">
                                  {" "}
                                  {traslate("Select Start Time")}{" "}
                                </Option>
                                <Option value="8:00 AM">
                                  8 {traslate("AM")}
                                </Option>
                                <Option value="9:00 AM">
                                  9 {traslate("AM")}
                                </Option>
                                <Option value="10:00 AM">
                                  10 {traslate("AM")}
                                </Option>
                                <Option value="11:00 AM">
                                  11 {traslate("AM")}
                                </Option>
                                <Option value="closed">
                                  {traslate("Closed")}
                                </Option>
                              </Select>
                              {showStartTimeVal && (
                                <p className="error-color-red">
                                  {showStartTimeVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block" ref={closedOption}>
                              <label>
                                {" "}
                                {traslate("Show End Time")} <span>*</span>
                              </label>
                              <Select
                                name="showEndTime"
                                className={
                                  showEndTimeVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                                showSearch
                                placeholder="Select End Time"
                                optionFilterProp="children"
                                value={tradeShowData.showEndTime}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "showEndTime")
                                }
                              >
                                <Option value="">
                                  {" "}
                                  {traslate("Select End Time")}{" "}
                                </Option>
                                <Option value="4:00 PM">
                                  4 {traslate("PM")}
                                </Option>
                                <Option value="5:00 PM">
                                  5 {traslate("PM")}
                                </Option>
                                <Option value="6:00 PM">
                                  6 {traslate("PM")}
                                </Option>
                                <Option value="7:00 PM">
                                  7 {traslate("PM")}
                                </Option>
                                <Option value="8:00 PM">
                                  8 {traslate("PM")}
                                </Option>
                                <Option value="9:00 PM">
                                  9 {traslate("PM")}
                                </Option>
                                <Option value="10:00 PM">
                                  10 {traslate("PM")}
                                </Option>
                              </Select>
                              {showEndTimeVal && (
                                <p className="error-color-red">
                                  {showEndTimeVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Show Start Dates")} <span>*</span>
                              </label>
                              <input
                                name="showStartDate"
                                value={tradeShowData.showStartDate}
                                onChange={handleOnChange}
                                type="date"
                                placeholder="Enter Date"
                                className={showStartDateVal && "border__red"}
                              />
                              {showStartDateVal && (
                                <p className="error-color-red">
                                  {showStartDateVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Show End Dates")} <span>*</span>
                              </label>
                              <input
                                name="showEndDate"
                                value={tradeShowData.showEndDate}
                                onChange={handleOnChange}
                                type="date"
                                placeholder="Enter Date"
                                className={showEndDateVal && "border__red"}
                              />
                              {showEndDateVal && (
                                <p className="error-color-red">
                                  {showEndDateVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                title={traslate("Delete Trade Show")}
                className="modal__tradeshow"
                centered
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={() => setVisible2(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel2}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleOk2}>
                    {traslate("Delete")}
                  </Button>
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div class="col-lg-12">
                            <p>{traslate("You want to delete this show ?")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TradeShows;
