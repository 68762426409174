import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip, Modal, Popconfirm } from "antd";
import { ProgressBar } from "react-bootstrap";

import userIcon from "../../assets/images/user-icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import ExcellFormatIcon from "../../assets/images/excelFormatIcon.png";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import traslate from "../../i18n/translate";
import AdminDashboardService from "../../services/admin-dashboard.service";

const BlockedProduct = () => {
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: "COMMA",
    IsServer: false,
    rbtnRemove: false,
    Error: {},
    SelectedRetailers: [],
  };
  const [state, setState] = useState(initialState);
  const [submitLoading2, setSubmitLoading2] = useState(false);
  const [DealerId, setDealerId] = useState(null);
  const { Option } = Select;
  const [imageProgress, setImageProgress] = useState();
  const [progressStart, setProgressStart] = useState(false);
  const [progressFileName, setProgressFileName] = useState(null);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const [retailerList, setRetailerList] = useState([]);

  // for Popup
  const [visible, setVisible] = useState(false);
  // end

  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setDealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setDealerId(loginDetials.responseData.dealerId);
          handleGetRetailerList(loginDetials.responseData.dealerId, "");
          //setDealerDealerUseName(loginDetials.responseData.userName);
        }
      }
    }
  }, []);

  const replaceDetail = (
    <span>
      {" "}
      {traslate(
        "Clears all blocked product settings and blocks only the new list of SKU's uploaded, for the chosen connected retailer(s)."
      )}
    </span>
  );

  const addDetail = (
    <span>
      {" "}
      {traslate(
        "Updates the existing blocked products, with the new list of SKU's uploaded, for the chosen connected retailer(s)."
      )}
    </span>
  );

  function onChangeDrop(e, name) {
    let tempState = state;

    tempState[name] = e;
    tempState.Error["Delimiter"] = null;
    tempState.Error["SelectedRetailers"] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeRadio(e, name) {
    let tempState = state;
    if (name === "ReplaceAll") {
      setVisibleConfirmModal(true);
      tempState["rbtnRemove"] = true;
    } else {
      tempState["rbtnRemove"] = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }

  // For Popup

  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirm = () => {
    // let tempState = state;
    setVisible(false);
  };

  // end

  const handleValidation = (upName) => {
    var isValid = true;
    if (upName === "Upload1") {
      if (state.Imagefile === null) {
        state.Error["Imagefile"] = traslate("Please select image.");
        isValid = false;
      } else {
        if (state.Imagefile.length === 0) {
          state.Error["Imagefile"] = traslate("Please select image.");
          isValid = false;
        }
      }
    } else if (upName === "Upload2") {
      if (state.Datafile === null) {
        state.Error["Datafile"] = traslate("Please select file.");
        isValid = false;
      } else {
        if (state.Datafile.length === 0) {
          state.Error["Datafile"] = traslate("Please select file.");
          isValid = false;
        }
      }
      if (state.Delimiter === null) {
        state.Error["Delimiter"] = traslate("Please select delimiter.");
        isValid = false;
      }
      if (state.SelectedRetailers.length == 0) {
        state.Error["SelectedRetailers"] = "Please Select Connected Retailers.";
        isValid = false;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  const fileUpload = async (e, name) => {
    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (name == "Image") {
      if (e.target.files[0].name.match(/.(png|jpg|zip)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);

          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Imagefile"] = allFiles;
          tempState["ImagefileName"] = allFiles[0].name;
          tempState.Error["Imagefile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Imagefile"] =
          "Please select .png|.jpg|.zip file only.";

        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    } else if (name == "Data") {
      if (e.target.files[0].name.match(/.(csv|xls|txt|json)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);
          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Datafile"] = allFiles;
          tempState["DatafileName"] = allFiles[0].name;
          tempState.Error["Datafile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Datafile"] = traslate(
          "Please select .csv|.xls|.txt|.json file only."
        );
        tempState["DatafileName"] = null;
        tempState["Datafile"] = null;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  const option = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.round((100 * loaded) / total);
      setImageProgress(percent);
    },
  };

  const handleUploadBlockedProducts = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading2(true);
      const formData = new FormData();

      formData.append("file", state.Datafile[0]);
      formData.append("dealerId", DealerId);
      formData.append(
        "fileType",
        state.DatafileName.substring(
          state.DatafileName.lastIndexOf(".") + 1,
          state.DatafileName.length
        )
      );
      formData.append("ddlDELIMITERJew", state.Delimiter);
      formData.append("ddlSelectVendor", state.SelectedRetailers + "");
      formData.append("IsManufacturer", true);
      formData.append("rbReplaceAll", state.rbtnRemove);

      try {
        AdminDashboardService.UploadBlockedProduct(formData)
          .then((response) => {
            var message = response.data.message;
            setSubmitLoading2(false);
            if (message === "Success") {
              NotificationManager.success("File Uploaded Successfully.");
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              state["SelectedRetailers"] = [];
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            } else {
              NotificationManager.error("File Not Uploaded.");
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              state["SelectedRetailers"] = [];
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading2(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading2(false);
        console.log(error);
      }
    }
  };

  const handleGetRetailerList = (dealerID, sales) => {
    let inputData = {
      dealerID,
      sales,
    };
    try {
      AdminDashboardService.GetMyRetailerListforUploadBlock(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          } else {
            setRetailerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="prodetail__section mt-5">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping uplaod__file">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input__block">
                    <h4 className="workarea__heading mt-0 mb-3">
                      {" "}
                      {traslate("Blocked Products")}
                    </h4>
                    <p>
                      {traslate(
                        "Upload a datasheet with the column header of StyleNumber. Include one SKU per row of each product you want to block from your chosen connected retailers. Those retailers selected for these SKU's, will not be given access to those products."
                      )}
                    </p>
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-4 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload1"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("Choose File")}
                            </label>
                            <input
                              id="file-upload1"
                              type="file"
                              onChange={(e) => fileUpload(e, "Data")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Datafile"]}
                              </div>
                            ) : null}
                            {state.DatafileName ? (
                              ""
                            ) : (
                              <span className="mb-4">
                                {traslate("No File Choosen")}{" "}
                              </span>
                            )}
                            <span> {traslate("Xls/Txt/Csv/Json Format")} </span>
                            <span className="">{state.DatafileName}</span>
                          </div>

                          <div className="choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                            <span>x</span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div className="input__block text__box blocked-retailer-input">
                            <label>{traslate("Connected Retailers")}</label>

                            <Select
                              className="border__grey"
                              optionFilterProp="children"
                              placeholder="Select Retailer(s)"
                              value={state.SelectedRetailers}
                              style={{ height: 70 }}
                              onChange={(e) =>
                                onChangeDrop(e, "SelectedRetailers")
                              }
                              mode="multiple"
                            >
                              {retailerList.map((retailer) => {
                                return (
                                  <Option value={retailer.dealerID}>
                                    {retailer.dealerCompany}
                                  </Option>
                                );
                              })}
                            </Select>
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["SelectedRetailers"]}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <Select
                              className="border__grey mobile-w-100"
                              optionFilterProp="children"
                              placeholder="Please Select"
                              value={state.Delimiter}
                              style={{ width: 177 }}
                              onChange={(e) => onChangeDrop(e, "Delimiter")}
                            >
                              <Option value="COMMA">
                                {" "}
                                {traslate("Comma")}{" "}
                              </Option>
                              <Option value="TAB"> {traslate("Tab")} </Option>
                            </Select>
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Delimiter"]}
                              </div>
                            ) : null}
                            <div className="radio__block">
                              <div className="radio__btn">
                                <input
                                  type="radio"
                                  id="dr"
                                  name="mt"
                                  className="mr-1"
                                  onChange={(e) =>
                                    onChangeRadio(e, "ReplaceAll")
                                  }
                                />
                                <label>
                                  {traslate("Replace All")}
                                  <Tooltip
                                    placement="right"
                                    title={replaceDetail}
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </label>
                              </div>
                              <div className="radio__btn">
                                <input
                                  type="radio"
                                  id="jr"
                                  name="mt"
                                  className="mr-1"
                                  defaultChecked
                                  onChange={(e) =>
                                    onChangeRadio(e, "AddUpdate")
                                  }
                                />
                                <label>
                                  {traslate("Add & Update")}
                                  <Tooltip placement="right" title={addDetail}>
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </label>
                              </div>
                            </div>
                            <Button
                              className="primary-btn"
                              onClick={(e) =>
                                handleUploadBlockedProducts("Upload2")
                              }
                              loading={submitLoading2}
                            >
                              {traslate("Upload Data")}
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="format__img">
                            <img src={ExcellFormatIcon} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Popup */}
                  <Popconfirm
                    title={traslate("Are you sure to update Permission?")}
                    visible={visible}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  ></Popconfirm>
                  {/* End */}
                </div>
              </div>
              <Modal
                title={traslate("Progress")}
                className="modalconsupld__section"
                centered
                visible={progressStart}
                onOk={() => setProgressStart(false)}
                onCancel={() => setProgressStart(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={() => setProgressStart(false)}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setProgressStart(false)}
                  >
                    {traslate("Ok")}
                  </Button>,
                ]}
              >
                <div>
                  <ProgressBar
                    now={imageProgress}
                    label={`${imageProgress}%`}
                  />
                  <span>
                    {" "}
                    {traslate("Uploaded")} {imageProgress}% {traslate("Total")}{" "}
                    100%
                  </span>
                  <br></br>
                  <span>
                    {" "}
                    {traslate("Uploading file")}: {progressFileName}
                  </span>
                </div>
              </Modal>
              <Modal
                title={traslate("Confirmation")}
                centered
                visible={visibleConfirmModal}
                onCancel={() => setVisibleConfirmModal(false)}
                width={400}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setVisibleConfirmModal(false)}
                  >
                    {traslate("Ok")}
                  </Button>,
                ]}
              >
                <div className="text-center">
                  <h5> {traslate("This option will override the data.")} </h5>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockedProduct;
