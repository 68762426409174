import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Select, Button, Switch, Tooltip, Spin } from "antd";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import AppsDiamondLinksService from "../../../services/apps-diamondlinks.service";
import traslate from "../../../i18n/translate";
import { useLocation } from "react-router-dom";
const { Option } = Select;

const initialOptionsData = {
  diamondLinkURL: "",
  internalUseLink: true,
  acceptNewDiamondDealers: true,
  sortSearchResultsInitiallyBy: null,
  orderSearchResultsInitiallyBy: null,
  showInHouseDiamondsSKU: true,
  showInHouseDiamondsFirst: true,
  showGIAReportCheckLink: true,
  showAdvanceOptionsAsDefault: true,
  showCertificateInDiamondSearch: true,
  showEGLCertificateVariants: true,
  showRequestCertificate: true,
  disableCertificateLinks: true,
  showColorItemsOutOfRange: true,
  showClarityItemsOutOfRange: true,
  acceptTotalPriceForDiamond: true,
  scheduleViewing: true,
  dropAHint: true,
  emailAFriend: true,
  showOriginOnDiamondSearch: true,
  showVendorCommentsOnDetailsPage: true,
  showContactNumber: true,
  showAddresses: true,
  alternateDiamondDetailsLinkURL: "",
  customDetailURL: true,
  showDiamondPrices: true,
  markupYourOwnInventory: true,
  showPricePerCarat: true,
  applyGstTax: true,
  gstTaxValue: "",
  showPinterestShare: true,
  showTwitterShare: true,
  showFacebookShare: true,
  ShowGooglePlus: true,
  showFacebookLike: true,
  showInstagramShare: true,
  ctaButton: true,
  alternateShoppingCartURL: "",
  myOptionRadio: "",
  showViewCartButton: true,
  showAddToCartButton: true,
  isShowEcommerce: false,
};

export const Options = (props) => {
  const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);
  const [dealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tempMyOptionRadio, setTempMyOptionRadio] = useState("");
  const [btnVisible, setBtnVisible] = useState(true);
  const loginDetials = useSelector((state) => state.loginReducer);

  const location = useLocation();
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetOptionsDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const sortSearch = [
    {
      title: "Cut/Shape",
      value: "CUT",
    },
    {
      title: "Color",
      value: "COLOR",
    },
    {
      title: "Clarity",
      value: "CLARITYID",
    },
    {
      title: "Size",
      value: "SIZE",
    },
    {
      title: "Price",
      value: "FINALPRICE",
    },
    {
      title: "Depth",
      value: "DEPTH",
    },
    {
      title: "Table",
      value: "TABLEMEASURE",
    },
    {
      title: "Measurements",
      value: "MEASUREMENTS",
    },
    {
      title: "Certificates",
      value: "CERTIFICATE",
    },
  ];

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      type: "DiamondLink",
    };
    setLoading(true);
    try {
      AppsDiamondLinksService.GetOptionsDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setMyOptionsData((prevState) => ({
                ...prevState,
                diamondLinkURL: responseData.diamondLinkURL,
                internalUseLink: responseData.internalLinkDL,
                acceptNewDiamondDealers: responseData.allowNewDiamondDealers,
                sortSearchResultsInitiallyBy: responseData.searchSortName,
                orderSearchResultsInitiallyBy: responseData.searchSortOrder,
                showInHouseDiamondsSKU: responseData.showInhouseDiamonds,
                showInHouseDiamondsFirst: responseData.showInhouseFirst,
                showGIAReportCheckLink: responseData.showGIAreportchecklink,
                showAdvanceOptionsAsDefault:
                  responseData.showAdvanceDiamondSearch,
                showCertificateInDiamondSearch:
                  responseData.showCertificateDiamondSearch,
                showEGLCertificateVariants: responseData.showEGLVariants,
                showRequestCertificate: responseData.requestCer,
                disableCertificateLinks: responseData.disableCertificate,
                showColorItemsOutOfRange: responseData.showcoloritemsoutofrange,
                showClarityItemsOutOfRange:
                  responseData.showclarityitemsoutofrange,
                acceptTotalPriceForDiamond: responseData.acceptTotalPrice,
                scheduleViewing: responseData.isScheduleViewing,
                dropAHint: responseData.dropaHint,
                emailAFriend: responseData.emailAFriend,
                showOriginOnDiamondSearch: responseData.showOriginDiamondSearch,
                showVendorCommentsOnDetailsPage:
                  responseData.showCommentsOnDetailPage,
                showContactNumber: responseData.showContact,
                showAddresses: responseData.showAddresses,
                alternateDiamondDetailsLinkURL:
                  responseData.searchPageDetailLinkURL,
                customDetailURL: responseData.useCustomDetailURL,
                showDiamondPrices: responseData.showPricesOnSearchPage,
                markupYourOwnInventory: responseData.markupOwnInventory,
                showPricePerCarat: responseData.showPricePerCarat,
                applyGstTax: responseData.showGstPrice,
                gstTaxValue: responseData.taxRate,
                showPinterestShare: responseData.showPinterestShare,
                showTwitterShare: responseData.showTwitterShare,
                showFacebookShare: responseData.showFacebookShare,
                showFacebookLike: responseData.showFacebookLike,
                showInstagramShare: responseData.showInstagramShare,
                ShowGooglePlus: responseData.showGooglePlus,
                ctaButton:
                  responseData.showAddToCartButton == 1
                    ? true
                    : responseData.showAddToCartButton == 2
                    ? true
                    : responseData.showAddToCartButton == 0
                    ? true
                    : responseData.showAddToCartButton == 3
                    ? false
                    : null,
                alternateShoppingCartURL: responseData.alternateShoppingCartURL,
                myOptionRadio:
                  responseData.showAddToCartButton == 1
                    ? "addToCartBtn"
                    : responseData.showAddToCartButton == 2
                    ? "scheduleAViewing"
                    : responseData.showAddToCartButton == 0
                    ? "requestMoreInfo"
                    : null,
                showViewCartButton: responseData.showViewCartButton,
                showAddToCartButton:
                  responseData.showAddToCartButtonOnSearchPage,
                isShowEcommerce: responseData.isShowEcommerce,
              }));
              let temp =
                responseData.showAddToCartButton == 1
                  ? "addToCartBtn"
                  : responseData.showAddToCartButton == 2
                  ? "scheduleAViewing"
                  : responseData.showAddToCartButton == 0
                  ? "requestMoreInfo"
                  : "";

              setTempMyOptionRadio(temp);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setBtnVisible(false);
      setMyOptionsData({
        ...myOptionsData,
        [name]: e,
      });
    } else {
      setBtnVisible(false);
      setMyOptionsData({
        ...myOptionsData,
        [e.target.name]: e.target.value,
      });
    }
    if (name == "ctaButton") {
      if (e == false) {
        setMyOptionsData((prevState) => ({
          ...prevState,
          myOptionRadio: tempMyOptionRadio ? tempMyOptionRadio : "No Value",
        }));
      } else {
        setMyOptionsData((prevState) => ({
          ...prevState,
          myOptionRadio: tempMyOptionRadio ? tempMyOptionRadio : "addToCartBtn",
        }));
      }
    }
  };

  const handleOnChangeRadio = (event) => {
    setBtnVisible(false);
    setMyOptionsData((prevState) => ({
      ...prevState,
      myOptionRadio: event.target.name,
    }));
    setTempMyOptionRadio(event.target.name);
  };

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        type: "DiamondLink",
        diamondLinkURL: myOptionsData.diamondLinkURL,
        internalLinkDL: myOptionsData.internalUseLink,
        allowNewDiamondDealers: myOptionsData.acceptNewDiamondDealers,
        searchSortName: myOptionsData.sortSearchResultsInitiallyBy,
        searchSortOrder: myOptionsData.orderSearchResultsInitiallyBy,
        showInhouseDiamonds: myOptionsData.showInHouseDiamondsSKU,
        showInhouseFirst: myOptionsData.showInHouseDiamondsFirst,
        showGIAreportchecklink: myOptionsData.showGIAReportCheckLink,
        showAdvanceOptionInDiamondSearch:
          myOptionsData.showAdvanceOptionsAsDefault,
        showCertificateDiamondSearch:
          myOptionsData.showCertificateInDiamondSearch,
        showEGLVariants: myOptionsData.showEGLCertificateVariants,
        requestCer: myOptionsData.showRequestCertificate,
        disableCertificate: myOptionsData.disableCertificateLinks,
        showcoloritemsoutofrange: myOptionsData.showColorItemsOutOfRange,
        showclarityitemsoutofrange: myOptionsData.showClarityItemsOutOfRange,
        acceptTotalPrice: myOptionsData.acceptTotalPriceForDiamond,
        isScheduleViewing: myOptionsData.scheduleViewing,
        dropaHint: myOptionsData.dropAHint,
        emailAFriend: myOptionsData.emailAFriend,
        showOriginDiamondSearch: myOptionsData.showOriginOnDiamondSearch,
        showCommentsOnDetailPage: myOptionsData.showVendorCommentsOnDetailsPage,
        showContact: myOptionsData.showContactNumber,
        showAddresses: myOptionsData.showAddresses,
        searchPageDetailLinkURL: myOptionsData.alternateDiamondDetailsLinkURL,
        useCustomDetailURL: myOptionsData.customDetailURL,
        showDiamondPricesOnPage: myOptionsData.showDiamondPrices,
        markupOwnInventory: myOptionsData.markupYourOwnInventory,
        showPricePerCarat: myOptionsData.showPricePerCarat,
        showGstPrice: myOptionsData.applyGstTax,
        taxRate: myOptionsData.gstTaxValue,
        showPinterestShare: myOptionsData.showPinterestShare,
        showTwitterShare: myOptionsData.showTwitterShare,
        showFacebookShare: myOptionsData.showFacebookShare,
        showFacebookLike: myOptionsData.showFacebookLike,
        showInstagramShare: myOptionsData.showInstagramShare,
        ShowGooglePlus: myOptionsData.ShowGooglePlus,
        showAddToCartButton: myOptionsData.ctaButton
          ? myOptionsData.myOptionRadio == "addToCartBtn"
            ? 1
            : myOptionsData.myOptionRadio == "scheduleAViewing"
            ? 2
            : myOptionsData.myOptionRadio == "requestMoreInfo"
            ? 0
            : 3
          : 3,
        alternateShoppingCartURL: myOptionsData.alternateShoppingCartURL,
        showViewCartButton: myOptionsData.showViewCartButton,
        showAddToCartButtonOnSearchPage: myOptionsData.showAddToCartButton,
      };
      setLoading(true);
      AppsDiamondLinksService.UpdateDiamondLinkOptionDetailsUpload(inputData)
        .then((response) => {
          let message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Data Updated Successfully.");
            handleGetOptionsDetails(dealerId);
          } else {
            NotificationManager.error("Data not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setBtnVisible(true);
  };

  const onKeyPressMinusEvent = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-3">
              {" "}
              {traslate("Options")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <div className="row ">
        <Spin spinning={loading}>
          <div className="col-md-12">
            <Accordion
              defaultActiveKey={
                location.state ? (location.state.isSetup ? "0" : "") : ""
              }
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Setup")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {traslate("Diamond Link URL")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter the URL of this tool on your site to enable social sharing and dynamic sizing."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="diamondLinkURL"
                          value={myOptionsData.diamondLinkURL}
                          onChange={(e) =>
                            handleOnChange(e, "", "diamondLinkURL")
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            {traslate("Internal Use Link")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="internalUseLink"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked
                            checked={myOptionsData.internalUseLink}
                            onChange={(e) =>
                              handleOnChange(e, "select", "internalUseLink")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            {traslate(
                              "Automatically Accept New Diamond Dealers"
                            )}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="acceptNewDiamondDealers"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked
                            checked={myOptionsData.acceptNewDiamondDealers}
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                "select",
                                "acceptNewDiamondDealers"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <label htmlFor="h1"> {traslate("Display")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Sort Search Results Initially By")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          showSearch
                          className="border__grey"
                          placeholder="Select"
                          optionFilterProp="children"
                          name="sortSearchResultsInitiallyBy"
                          value={
                            myOptionsData.sortSearchResultsInitiallyBy
                              ? myOptionsData.sortSearchResultsInitiallyBy
                              : ""
                          }
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "sortSearchResultsInitiallyBy"
                            )
                          }
                        >
                          {sortSearch?.map((item, i) => {
                            return (
                              <Option value={item.value}>{item.title}</Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Order Search Results Initially By")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          className="border__grey"
                          value={
                            myOptionsData.orderSearchResultsInitiallyBy
                              ? myOptionsData.orderSearchResultsInitiallyBy
                              : ""
                          }
                          name="orderSearchResultsInitiallyBy"
                          placeholder="Select"
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "orderSearchResultsInitiallyBy"
                            )
                          }
                          showSearch
                          optionFilterProp="children"
                        >
                          <Option value="ascending">{"Ascending"}</Option>
                          <Option value="descending">{"Descending"}</Option>
                        </Select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label className="d-block">
                          {traslate(
                            "Show In House Diamonds Column with SKU (for New GF Link Only)"
                          )}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showInHouseDiamondsSKU"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showInHouseDiamondsSKU}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showInHouseDiamondsSKU"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show In House Diamonds First")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display in house diamonds first in the search result."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showInHouseDiamondsFirst"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showInHouseDiamondsFirst}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showInHouseDiamondsFirst"
                            )
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show GIA report check link")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showGIAReportCheckLink"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showGIAReportCheckLink}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showGIAReportCheckLink"
                            )
                          }
                        />
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div className="input__block">
                        <label className="align-items-start">
                          <b>
                            {traslate(
                              "Show Advance options as Default in Diamond Search"
                            )}
                          </b>
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."
                            )}
                          >
                            <img
                              src={UnionIcon}
                              alt=""
                              style={{ marginTop: 4 }}
                            />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showAdvanceOptionsAsDefault"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showAdvanceOptionsAsDefault}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showAdvanceOptionsAsDefault"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Certificate in Diamond Search")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display a link to the diamond certificate or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showCertificateInDiamondSearch"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showCertificateInDiamondSearch}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showCertificateInDiamondSearch"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show EGL Certificate Variants")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display EGL Certificate Variants or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showEGLCertificateVariants"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showEGLCertificateVariants}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showEGLCertificateVariants"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Request Certificate")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showRequestCertificate"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showRequestCertificate}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showRequestCertificate"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate(
                            "Disable Certificate Links in Diamond Search"
                          )}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Hide certificates and show only request form."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="disableCertificateLinks"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.disableCertificateLinks}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "disableCertificateLinks"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Color Items out of Range")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showColorItemsOutOfRange"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showColorItemsOutOfRange}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showColorItemsOutOfRange"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Clarity Items out of Range")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="visibility"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showClarityItemsOutOfRange}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showClarityItemsOutOfRange"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Accept Total Price for Diamond")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="acceptTotalPriceForDiamond"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.acceptTotalPriceForDiamond}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "acceptTotalPriceForDiamond"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Schedule Viewing")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the link which allows the customer the ability to schedule an appointment."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="scheduleViewing"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.scheduleViewing}
                          onChange={(e) =>
                            handleOnChange(e, "select", "scheduleViewing")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Drop A Hint")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the link which allows the customer the ability to Drop a Hint."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="dropAHint"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.dropAHint}
                          onChange={(e) =>
                            handleOnChange(e, "select", "dropAHint")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Email A Friend")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the link which allows the customer the ability to Email A Friend."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="emailAFriend"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.emailAFriend}
                          onChange={(e) =>
                            handleOnChange(e, "select", "emailAFriend")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Origin On Diamond Search")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showOriginOnDiamondSearch"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showOriginOnDiamondSearch}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showOriginOnDiamondSearch"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show vendor comments on details page")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showVendorCommentsOnDetailsPage"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={
                            myOptionsData.showVendorCommentsOnDetailsPage
                          }
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "showVendorCommentsOnDetailsPage"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Contact Number")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to use show contact Number or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showContactNumber"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showContactNumber}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showContactNumber")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Addresses")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to use show addresses or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showAddresses"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showAddresses}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showAddresses")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Price")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Diamond Prices")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showDiamondPrices"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showDiamondPrices}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showDiamondPrices")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Markup Your Own Inventory")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to apply markup on diamond inventory uploaded by you."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="markupYourOwnInventory"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.markupYourOwnInventory}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "markupYourOwnInventory"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Price Per Carat")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the price per carat value on the diamond details page “Specifications” tab."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showPricePerCarat"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showPricePerCarat}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showPricePerCarat")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Apply GST Tax")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Toggle the ability to apply international tax."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="applyGstTax"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.applyGstTax}
                          onChange={(e) =>
                            handleOnChange(e, "select", "applyGstTax")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("GST Tax Value")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Set the value when applying the GST Tax option."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          className="number-arrow-hidden"
                          type="number"
                          onKeyPress={onKeyPressMinusEvent}
                          value={myOptionsData.gstTaxValue}
                          onChange={(e) => handleOnChange(e, "", "gstTaxValue")}
                          name="gstTaxValue"
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Social")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Pinterest Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Pinterest “Pin it” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showPinterestShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showPinterestShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showPinterestShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Twitter Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Twitter “Tweet” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showTwitterShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showTwitterShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showTwitterShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Share” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showFacebookShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showFacebookShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showFacebookShare")
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Like")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Like” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="showFacebookLike"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showFacebookLike}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showFacebookLike")
                          }
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Instagram Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display Instagram Share or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="visibility"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          checked={myOptionsData.showInstagramShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "showInstagramShare")
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {myOptionsData.isShowEcommerce &&
              myOptionsData.isShowEcommerce == true ? (
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <div>
                      <label> {traslate("E-Commerce")} </label>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            {traslate("Call to Action Button")}
                            <Tooltip
                              placement="right"
                              title={`Choose whether to display the “Add to Cart” button or "Request More Info" or "Schedule a Viewing".`}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ctaButton"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked
                            checked={myOptionsData.ctaButton}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ctaButton")
                            }
                          />

                          {myOptionsData.ctaButton ? (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="radio__block">
                                  <input
                                    type="radio"
                                    id="AddToCartBtn"
                                    name="addToCartBtn"
                                    value={myOptionsData.myOptionRadio}
                                    checked={
                                      myOptionsData.myOptionRadio ===
                                      "addToCartBtn"
                                        ? true
                                        : false
                                    }
                                    onChange={handleOnChangeRadio}
                                  />
                                  <label htmlFor="AddToCartBtn">
                                    {" "}
                                    {traslate("AddToCart Button")}{" "}
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="radio__block">
                                  <input
                                    type="radio"
                                    id="RequestMoreInfo"
                                    name="requestMoreInfo"
                                    value={myOptionsData.myOptionRadio}
                                    checked={
                                      myOptionsData.myOptionRadio ===
                                      "requestMoreInfo"
                                        ? true
                                        : false
                                    }
                                    onChange={handleOnChangeRadio}
                                  />
                                  <label htmlFor="RequestMoreInfo">
                                    {" "}
                                    {traslate("Request More Info")}{" "}
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="radio__block">
                                  <input
                                    type="radio"
                                    id="ScheduleAViewing"
                                    name="scheduleAViewing"
                                    value={myOptionsData.myOptionRadio}
                                    checked={
                                      myOptionsData.myOptionRadio ===
                                      "scheduleAViewing"
                                        ? true
                                        : false
                                    }
                                    onChange={handleOnChangeRadio}
                                  />
                                  <label htmlFor="ScheduleAViewing">
                                    {" "}
                                    {traslate("Schedule a Viewing")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            {traslate("Alternate Shopping Cart URL")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="alternateShoppingCartURL"
                            value={myOptionsData.alternateShoppingCartURL}
                            onChange={(e) =>
                              handleOnChange(e, "", "alternateShoppingCartURL")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            {traslate("Show")} "{traslate("View Cart")}"{" "}
                            {traslate("Button")}
                            <Tooltip
                              placement="right"
                              title={`Choose whether to display the PayPal Shopping Cart's "View Cart" Button or not.`}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="showViewCartButton"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked
                            checked={myOptionsData.showViewCartButton}
                            onChange={(e) =>
                              handleOnChange(e, "select", "showViewCartButton")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            {traslate("Show AddToCart Button On Search Page")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Choose whether to display the “Add to Cart” button on Search Page or not."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="showAddToCartButton"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked
                            checked={myOptionsData.showAddToCartButton}
                            onChange={(e) =>
                              handleOnChange(e, "select", "showAddToCartButton")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
            </Accordion>
          </div>
        </Spin>
      </div>
      <div className="row">
        <div className="col-md-12 mt-4">
          <Button
            className={btnVisible ? "disabled-btn " : "primary-btn"}
            disabled={btnVisible}
            loading={submitLoading}
            onClick={handleSubmit}
          >
            {traslate("Update Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
