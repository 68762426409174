import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Space,
    Spin
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";
import PendantBuilderService from "../../../services/apps-pendantBuilder.service";
import { useSelector } from "react-redux";

const { Option } = Select;

export const PendantBuilderManageVendors = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const loginDetials = useSelector((state) => state.loginReducer);
    const [dealerID,setDealerId] = useState("")
    const [manageVendorList,setManageVendorList] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        if (loginDetials) {
          if (loginDetials.loginDetials) {
            if (loginDetials.loginDetials.responseData.logInSucceeded) {
              setDealerId(loginDetials.loginDetials.responseData.dealerId);
              handleBindDataPendantBuilderVendor(
                loginDetials.loginDetials.responseData.dealerId,
                "","","","","","",""
              );
            }
          }
        }
      }, []);

    const handleBindDataPendantBuilderVendor = (id) => {
        try {
          let inputData = {
            dealerID:id,
            filerType:"",
            currentPage:"",
            recordOnPage:"",
            sortBy:"",
            tagFilter:"",
            searchKeyChar:"",
            searchType:"",
          };
          setLoading(true);
           PendantBuilderService
            .BindDataPendantBuilderVendor(inputData)
            .then((response) => {
              var message = response.data.message;
              var responseData = response.data.responseData;
              if(message === "Success"){
                setManageVendorList(responseData);
                setLoading(false);
              }
              else{
                setManageVendorList([]);
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };

    const columns = [
        {
            title: traslate("Vendor"),
            dataIndex: "vendor",
        },
        {
            title: traslate("Status"),
            dataIndex: "status",
        },
        {
            title: traslate("Data Rating"),
            dataIndex: "dataRating",
        },
        {
            title: traslate("Items"),
            dataIndex: "items",
        },
        {
            title: traslate("Updated"),
            dataIndex: "updated",
        },
        {
            title: traslate("Action"),
            dataIndex: "action",
        },

    ];

    const data = [
        {
            key: "1",
            vendor: "Overnight Mountings Ring Builder",
            status: "Connected",
            dataRating:
                <div className='star-ratings'>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                </div>,
            items: "38101",
            updated: "05/05/2019",
            action: "Connected"
        },
        {
            key: "1",
            vendor: "Overnight Mountings Ring Builder",
            status: "Connected",
            dataRating:
                <div className='star-ratings'>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                </div>,
            items: "38101",
            updated: "05/05/2019",
            action: "Request Access"
        }
    ];



    return (
        <div className="form__fields">
            <div className="row magento__mapping">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0 mb-0"> {traslate("Manage Vendors")} </h4>
                    </div>
                </div>
                <div className="divForm vendors-section">
                    <Spin spinning={loading} >
                        <Table 
                        columns={columns}
                        dataSource={manageVendorList} 
                        scroll={{ x: 600,y: 800 }} 
                        />
                    </Spin>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendantBuilderManageVendors);
