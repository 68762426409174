import {
  RETAILER_ID,
  MESSAGE_ID,
  TAB_NAME,
  DEFAULT_MESSAGE,
  PAGE_TAB,
} from "../../actions/Retailer/types";

const initialState = {
  retailerId: 0,
  messageId: 0,
  tabName: "",
  appointmentDefaultMsg: "",
  pageTab: "myretailer",
};

function RetailerReducer(RetailerState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RETAILER_ID:
      return { ...RetailerState, retailerId: payload };
    case MESSAGE_ID:
      return { ...RetailerState, messageId: payload };
    case TAB_NAME:
      return { ...RetailerState, tabName: payload };
    case DEFAULT_MESSAGE:
      return { ...RetailerState, appointmentDefaultMsg: payload };
    case PAGE_TAB:
      return { ...RetailerState, pageTab: payload };
    default:
      return RetailerState;
  }
}
export default RetailerReducer;
