import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Select,
  Button,
  Table,
  Modal,
  Switch,
  Spin,
  Tooltip,
  Radio,
  Empty
} from "antd";
import StarRatings from "react-star-ratings";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import CatalogJewelryDataService from "../../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setManageInventoryVendorId } from "./../../../actions/Community/ManageInventory";

const { Option } = Select;

export const ManageCollection = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const loginDetials = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("All Vendors");
  const [collectionList, setCollectionList] = useState([]);
  const [rowDetails, setRowDetails] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const [VendorDrpList, setVendorDrpList] = useState([]);
  const [activeExpRow, setActiveExpRow] = useState();
  const [image, setImage] = useState({});
  const [isImageModal, setIsImageModal] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCatalogBindDataForVendorCount(
            loginDetials.loginDetials.responseData.dealerId
          );
          handleGetCollectionsList(
            loginDetials.loginDetials.responseData.dealerId,
            filterValue
          );
          handleGetBrandListbyDealer(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleGetBrandListbyDealer = (id) => {
    try {
      let inputData = {
        dealerID: id
      };
      setLoading(true);
      CatalogJewelryDataService.GetBrandListbyDealer(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setBrandList(responseData);
          } else {
            setBrandList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCatalogBindDataForVendorCount = (id) => {
    try {
      let inputData = {
        dealerID: id.toString()
      };

      CatalogJewelryDataService.CatalogBindDataForVendorCount(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.allCountList;
          if (message === "Success") {
            setVendorDrpList(responseData);
          } else {
            setVendorDrpList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCollectionsList = async (id, filterValue) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        BrandTypeID: filterValue,
        sortBy: ""
      };
      setLoading(true);
      await CatalogJewelryDataService.CatalogBindDataForVendor(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData =
            response.data.responseData.catalogBindDataForVendorList;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setCollectionList(responseData);
              setRowDetails(responseData[0]);
            } else {
              setCollectionList([]);
              setRowDetails([]);
            }
            setLoading(false);
          } else {
            setCollectionList([]);
            setRowDetails([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleIsSelectChange = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        visibility: saveRow.visibility.toString(),
        vendorID: saveRow.dealerID.toString()
      };
      CatalogJewelryDataService.UpdateVisibility(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(traslate("Record Updated Successfully."));
            handleGetCollectionsList(dealerId, filterValue);
          } else {
            NotificationManager.error(traslate("Record Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCollectionVendorEnable = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        manuID: saveRow.dealerID.toString(),
        isGrant: saveRow.isGrant.toString()
      };
      CatalogJewelryDataService.UpdateAcceptCollections(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(traslate("Record Updated Successfully."));
            handleGetCollectionsList(dealerId, filterValue);
          } else {
            NotificationManager.error(traslate("Record Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handlePricingVendorChange = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        pricing: saveRow.pricing.toString(),
        vendorID: saveRow.dealerID.toString()
      };
      CatalogJewelryDataService.UpdatePricing(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(traslate("Record Updated Successfully."));
            handleGetCollectionsList(dealerId, filterValue);
          } else {
            NotificationManager.error(traslate("Record Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOverride = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        vendorID: saveRow.dealerID.toString(),
        overrideText: saveRow.override.toString(),
        overrideenebled: saveRow.overrideEnabled
      };
      CatalogJewelryDataService.UpdateOverride(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(traslate("Record Updated Successfully."));
            handleGetCollectionsList(dealerId, filterValue);
          } else {
            NotificationManager.error(traslate("Record Not Updated."));
            handleGetCollectionsList(dealerId, filterValue);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (row, e, name) => {
    if (name === "visibility") {
      const temp = collectionList.map((x) => {
        if (x.designerID === row.designerID) {
          return { ...x, visibility: e };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.designerID == row.designerID)[0];
      setCollectionList(temp);
      handleIsSelectChange(saveRow);
    }
    if (name === "pricing") {
      const temp = collectionList.map((x) => {
        if (x.designerID === row.designerID) {
          return { ...x, pricing: e };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.designerID == row.designerID)[0];
      setCollectionList(temp);
      handlePricingVendorChange(saveRow);
    }
    if (name === "isGrant") {
      const temp = collectionList.map((x) => {
        if (x.designerID == row.designerID) {
          return { ...x, isGrant: e };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.designerID == row.designerID)[0];
      setCollectionList(temp);
      handleCollectionVendorEnable(saveRow);
    }
    if (name === "overrideEnabled") {
      const temp = collectionList.map((x) => {
        if (x.designerID === row.designerID) {
          return {
            ...x,
            overrideEnabled: e.target.value === "percentage"
          };
        } else return x;
      });
      setCollectionList(temp);
    }
    if (name === "markupOverride") {
      if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
        const temp = collectionList.map((x) => {
          if (x.designerID === row.designerID) {
            return {
              ...x,
              override: e.target.value
            };
          } else return x;
        });
        setCollectionList(temp);
      } else {
        e.preventDefault();
      }
    }
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleOnSelectChange = (e, name) => {
    setFilterValue(e);
    handleGetCollectionsList(dealerId, e);
  };

  const modifiedData = collectionList?.map((item) => ({
    ...item,
    key: item.designerID
  }));

  const handleOpenImageModal = (row) => {
    setImage(row);
    setIsImageModal(true);
  };

  const handleCloseImageModal = (row) => {
    setImage({});
    setIsImageModal(false);
  };

  const handleViewProfile = () => {
    navigate("/vendorProfile", {
      state: {
        key: "Profile",
        id: rowDetails.dealerID,
        pageType: ""
      }
    });
  };

  const handleCollection = () => {
    // navigate("/myvendors");
    navigate("/manageCollection");
  };

  const handleProducts = () => {
    navigate("/vendorProfile", {
      state: {
        key: "Inventory",
        id: rowDetails.dealerID
      }
    });
  };

  const handleSetRowDetails = (row) => {
    setRowDetails(row);
    dispatch(setManageInventoryVendorId(row.dealerID));
  };

  const openInNewTab = (Url) => {
    let url = Url;
    if (Url.includes("https")) {
      window.open(url, "_blank");
    } else if (Url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, row) => {
    handleSetRowDetails(row[0]);
    let tempData = modifiedData.filter((x) => x.key == newSelectedRowKeys[0]);
    setSelectedRowKeys(newSelectedRowKeys);
    localStorage.setItem("vendorDataForCollection", tempData[0].designerName);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 className="workarea__heading mt-0 mb-0">
              {traslate("Vendors")}
              {"(" + collectionList.length + ")"}
            </h4>
          </div>
        </div>

        <div className="row">
          <div className={"input__block col-md-4 d-flex"}>
            <Select
              name="filterValue"
              placeholder="Select Collections"
              optionFilterProp="children"
              className="border__grey"
              onChange={(e) => handleOnSelectChange(e)}
              value={filterValue}
            >
              {VendorDrpList?.map((item) => {
                return (
                  <Option value={item.allCountID}>{item.allCountData}</Option>
                );
              })}
            </Select>
          </div>

          <div className="col-md-4">
            <Button onClick={handleViewProfile} className="primary-btn mr-2">
              {traslate("View Profile")}
            </Button>
            <Button onClick={handleCollection} className="primary-btn mr-2">
              {traslate("Collections")}
            </Button>
            <Button onClick={handleProducts} className="primary-btn">
              {traslate("Products")}
            </Button>
          </div>
        </div>

        <div className="divForm">
          <Spin spinning={loading} size="large">
            <div className="table__scroll mobile__topspace">
              <Table
                pagination={{
                  showSizeChanger: true,
                  responsive: true
                }}
                className="vdiamond__table"
                columns={[
                  {
                    title: "",
                    dataIndex: "collectionImage",
                    width: 40,
                    render: (item, row) => {
                      return (
                        <React.Fragment>
                          <div
                            className="infoIcon_ManageCollection"
                            onClick={() => handleOpenImageModal(row)}
                          >
                            <i className="fa fa-info-circle"></i>
                          </div>
                        </React.Fragment>
                      );
                    }
                  },
                  {
                    title: traslate("Vendor Name"),
                    dataIndex: "designerName",
                    sorter: (a, b) =>
                      a.designerName.localeCompare(b.designerName),
                    showSorterTooltip: false
                  },

                  {
                    title: traslate("Status"),
                    dataIndex: "status",
                    showSorterTooltip: false,
                    sorter: (a, b) => {},
                    // width: 100,
                    sorter: (a, b) => a.status.localeCompare(b.status),
                    className: "display__tablecell"
                  },
                  {
                    title: traslate("Override"),
                    className: "display__tablecell",
                    dataIndex: "override",
                    key: "override",
                    showSorterTooltip: false,
                    sorter: (a, b) => a.override - b.override,
                    render: (index, row) => (
                      <span>
                        {row.overrideEnabled == false
                          ? "Disabled"
                          : row.override + "%"}
                      </span>
                    )
                  },

                  {
                    title: traslate("Collections"),
                    dataIndex: "totalCollection",
                    sorter: (a, b) => a.totalCollection - b.totalCollection,
                    showSorterTooltip: false
                  },
                  {
                    title: traslate("Items"),
                    dataIndex: "items",
                    sorter: (a, b) => a.items - b.items,
                    showSorterTooltip: false
                  },
                  {
                    title: traslate("Pricing"),
                    dataIndex: "pricing",
                    render: (item, row) => (
                      <div className="action__btns">
                        <Switch
                          name="pricing"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={row.pricing}
                          onChange={(e) => handleOnChange(row, e, "pricing")}
                        />
                      </div>
                    )
                  },
                  {
                    title: (
                      <span className="font__bold d-flex">
                        {traslate("Visibility")}

                        <Tooltip
                          placement="right"
                          className="ml-1"
                          title={traslate(
                            "This shows the collection on your own MasterLink, for preview purposes"
                          )}
                        >
                          <img src={UnionIcon} alt="" />
                        </Tooltip>
                      </span>
                    ),
                    dataIndex: "visibility",
                    render: (item, row) => (
                      <div className="action__btns">
                        <Switch
                          name="visibility"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={row.visibility}
                          onChange={(e) => handleOnChange(row, e, "visibility")}
                        />
                      </div>
                    )
                  }
                ]}
                expandable={{
                  expandedRowRender: (row) => (
                    <div className="d-flex align-items-center">
                      <p className="m-0">
                        <label className="fontSize_12px">
                          {traslate("Updated Date")} :{" "}
                        </label>
                        <span className="mx-2">
                          {moment(row.lastUpdatedDate).format("MM/DD/YYYY")}
                        </span>
                      </p>

                      <div className="display__tablecell">
                        <div className="managecollection__overide mx-3">
                          <label className="fontSize_12px">
                            {traslate("MarkUp Overrride")} :{" "}
                          </label>
                          <div className="radio__block mx-2">
                            <Radio
                              checked={row.overrideEnabled == false}
                              onChange={(e) =>
                                handleOnChange(row, e, "overrideEnabled")
                              }
                              value="disabled"
                              option="Disable"
                            >
                              {traslate("Disable")}
                            </Radio>
                          </div>
                          <div className="radio__block">
                            <Radio
                              checked={row.overrideEnabled == true}
                              onChange={(e) =>
                                handleOnChange(row, e, "overrideEnabled")
                              }
                              value="percentage"
                              option="Percentage"
                            >
                              {traslate("Percent")}
                            </Radio>
                          </div>
                          <div className="input__block m-0">
                            <div className="input__content">
                              <input
                                type="text"
                                onKeyPress={onKeyPressEvent}
                                step="1"
                                min="0"
                                max="100"
                                disabled={
                                  row.overrideEnabled == false ? true : false
                                }
                                onChange={(e) => {
                                  e.target.value = e.target.value.slice(0, 3);
                                  handleOnChange(row, e, "markupOverride");
                                }}
                                value={
                                  row.overrideEnabled == false
                                    ? ""
                                    : row.markupOverride === undefined
                                    ? row.override
                                    : row.markupOverride !== "" ||
                                      row.markupOverride === ""
                                    ? row.markupOverride
                                    : row.override
                                }
                                className={
                                  row.overrideEnabled == false ? "disabled" : ""
                                }
                              />
                              <span className="ml-1">%</span>
                            </div>
                          </div>
                          <div className="">
                            <div className="save__btn">
                              <i
                                class="fa fa-check"
                                aria-hidden="true"
                                onClick={() => {
                                  handleUpdateOverride(row);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  rowExpandable: (record) => true,
                  expandedRowKeys: activeExpRow,
                  onExpand: (expanded, record) => {
                    const keys = [];
                    if (expanded) {
                      keys.push(record.designerID);
                    }
                    setActiveExpRow(keys);
                  }
                }}
                onChange={(pagination, filters, sorters) => {
                  document
                    .getElementsByClassName("content__area")[0]
                    .scroll(0, 0);
                }}
                dataSource={modifiedData}
                onRow={(record, recordIndex) => ({
                  onClick: (event) => {
                    handleSetRowDetails(record);
                  }
                })}
                scroll={{ y: 800 }}
                rowSelection={{
                  type: "radio",
                  ...rowSelection
                }}
              />
            </div>
          </Spin>
        </div>
        <Modal
          title={traslate("Vendor Details")}
          className="modalvdiamond__section"
          centered
          visible={isImageModal}
          onOk={handleCloseImageModal}
          onCancel={handleCloseImageModal}
          footer={[
            <Button
              key="back"
              className="primary-btn"
              onClick={handleCloseImageModal}
            >
              {traslate("Cancel")}
            </Button>
          ]}
        >
          <div className="row">
            <div className="col-md-6">
              {image.dealersImage == "" ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <img
                  style={{ width: "100%" }}
                  src={image.dealersImage}
                  alt=""
                />
              )}
            </div>
            <div className="col-md-6">
              <p className="subheading">{image.designerName}</p>
              <a href={`mailto:${image.designerEmail}`} className="linkText">
                {image.designerEmail}
              </a>
              <p>
                {" "}
                <StarRatings
                  rating={Number(image.ratingCount)}
                  starRatedColor="#1883b8"
                  starDimension="18px"
                  starSpacing="0px"
                  numberOfStars={5}
                />
              </p>
              <p>{image.dealerPhone}</p>
              <p>{image.activity}</p>
              <p>{image.tagName}</p>
              <p onClick={() => openInNewTab(image.website)}>{image.website}</p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCollection);
