import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import DiamondReportsCutComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsCutComp";
import DiamondReportsShapeComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsShapeComp";
import DiamondReportsClarityComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsClarityComp";
import DiamondReportsCertificateComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsCertificateComp";
import DiamondReportsCutGradeComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsCutGradeComp";
import DiamondReportsColorComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsColorComp";
import DiamondReportsSizeComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsSizeComp";
import DiamondReportsClicksComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsClicksComp";
import DiamondReportsPriceComp from "../../ChartsGroup/DiamondReportsCharts/DiamondReportsPriceComp";
import traslate from "../../../i18n/translate";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const ExtendedDiamondChartReportsMain = () => {
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  /*Initial State for Binding Ends*/

  /*React Binding Starts*/
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [forDealer, setForDealer] = useState("");
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);

  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };
  /*DatePicker Binding Ends*/

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      ReportsDiamondService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            handleLoadExtendedDiamondChartReports(id, forDealer);
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleLoadExtendedDiamondChartReports = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: globalDiamonds.retailerIds.toString(),
        vendorIds: globalDiamonds.vendorIds.toString(),
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        reportName: "ExtDiamondChrt",
      };
      setLoadChartReportsLoading(true);

      ReportsDiamondService.LoadExtendedDiamondChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var TotalDiamondClicks =
            responseData.TotalDiamondClicks[0].TotalClickCnt;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setTotalDiamondClicks(TotalDiamondClicks);
              setKey(!key);
            }
          } else {
            setTotalDiamondClicks([]);
          }
        })
        .catch((error) => {
          setLoadChartReportsLoading(false);
          setTotalDiamondClicks([]);
        });
    } catch (error) {
      setLoadChartReportsLoading(false);
      setTotalDiamondClicks([]);
    }
  };

  /*API Binding Ends*/

  const handleOnChange = (e, isSelect, name) => {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e,
    });
  };

  const handleApplyFilter = () => {
    handleCheckDealermembers(dealerId);
    setIsApplyFilter(!isApplyFilter);
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Extended Diamond Chart Reports")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                className="border__grey"
                placeholder="All"
                showSearch
                name="SelectedDiamondValue"
                optionFilterProp="children"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All"> {traslate("All")} </Option>
                <Option value="Mined"> {traslate("Mined Diamond")} </Option>
                <Option value="Lab"> {traslate("Lab Grown Diamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <Spin spinning={LoadChartReportsLoading} size="small">
                  <b>
                    {" "}
                    {traslate("Total Clicks")} :{" "}
                    {TotalDiamondClicks ? TotalDiamondClicks : 0}
                  </b>
                </Spin>
              </label>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          {/* Diamond Cut Searches Chart Starts */}
          {forDealer && (
            <DiamondReportsCutComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Cut Searches Chart Ends */}

          {/* Diamond Shape Searches Chart Starts */}
          {forDealer && (
            <DiamondReportsShapeComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Shape Searches Chart Ends */}

          {/* Diamond Clarity Searches Chart Starts */}

          {forDealer && (
            <DiamondReportsClarityComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Clarity Searches Chart Ends */}

          {/* Diamond Certificate Searches Chart Starts */}
          {forDealer && (
            <DiamondReportsCertificateComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}
          {/* Diamond Certificate Searches Chart Ends */}

          {/* Diamond CutGrade Searches Chart Starts */}
          {forDealer && (
            <DiamondReportsCutGradeComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond CutGrade Searches Chart Ends */}

          {/* Diamond Color Searches Chart Starts */}
          {forDealer && (
            <DiamondReportsColorComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Color Searches Chart Ends */}

          {/* Diamond Size Searches Chart Starts */}
          {forDealer && (
            <DiamondReportsSizeComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Size Searches Chart Ends */}

          {/* Diamond Clicks Chart Starts */}
          {forDealer && (
            <DiamondReportsClicksComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Clicks Searches Chart Ends */}

          {/* Diamond Price Chart Starts */}
          {forDealer && (
            <DiamondReportsPriceComp
              globalDiamonds={globalDiamonds}
              mainForDealer={forDealer}
              isApplyFilter={isApplyFilter}
            />
          )}

          {/* Diamond Price Searches Chart Ends */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExtendedDiamondChartReportsMain;
