import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Spin,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
} from "antd";
import AdminManageCurrencyService from "../../services/admin-manageCurrency.service";
import AdminManageLanguageService from "../../services/admin-manageCurrency.service";
import traslate from "../../i18n/translate";
import { NotificationManager } from "react-notifications";

const ManageCurrency = () => {
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        handleGetSelectlanguageForDelar(loginDetials.responseData.dealerId);
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
    handleGetSelectCurrencyDropdowen();
  }, []);
  const { Option } = Select;

  const [CurrencySelect, setCurrencySelect] = useState({
    Currency: null,
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [dealerId, setDealerId] = useState();
  const [getLoading, setGetLoading] = useState(false);
  const [saveLaoding, setSaveLoading] = useState(false);

  const handleSubmit = async (e) => {};

  const handleCancel = async (e) => {
    setDisableBtn(true);
    setCurrencySelect({
      Currency: null,
    });
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);

    if (isSelect === "select") {
      setCurrencySelect({
        ...CurrencySelect,
        [name]: e,
      });
    } else {
      setCurrencySelect({
        ...CurrencySelect,
        [e.target.name]: e.target.value,
      });
    }
  };

  function onSearch(val) {}

  const handleCurrencyUpdate = () => {
    handleUpdateCurrency(loginDetials.responseData.dealerId);
  };
  /*API binding starts*/
  const [selectCurrencyDropdowenList, setSelectCurrencyDropdowenList] =
    useState([]);
  const [selectlanguageForDelarList, setSelectlanguageForDelarList] = useState(
    {}
  );
  const handleGetSelectCurrencyDropdowen = () => {
    try {
      AdminManageCurrencyService.GetSelectCurrencyDropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSelectCurrencyDropdowenList(responseData);
            } else {
              setSelectCurrencyDropdowenList([]);
            }
          } else {
            setSelectCurrencyDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetSelectlanguageForDelar = (id) => {
    try {
      let inputData = {
        delarId: id,
      };
      setGetLoading(false);
      AdminManageCurrencyService.GetSelectlanguageForDelar(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setGetLoading(false);
              setSelectlanguageForDelarList(responseData);
              setDisableBtn(true);
            } else {
              setGetLoading(false);
              setSelectlanguageForDelarList({});
            }
          } else {
            setGetLoading(false);
            setSelectlanguageForDelarList({});
          }
        })
        .catch((error) => {
          setGetLoading(false);
          console.log(error);
        });
    } catch (error) {
      setGetLoading(false);
      console.log(error);
    }
  };
  const handleUpdateCurrency = (id) => {
    try {
      let inputData = {
        delarId: id,
        currencyId: CurrencySelect.Currency,
      };
      setSaveLoading(true);
      AdminManageCurrencyService.UpdateCurrency(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSaveLoading(false);
              NotificationManager.success("Currency Updated Successfully");
              handleGetSelectlanguageForDelar(dealerId);
            } else {
              setSaveLoading(false);
              NotificationManager.error("Currency Update failed");
            }
          } else {
            setSaveLoading(false);
            NotificationManager.error("Currency Update failed");
          }
        })
        .catch((error) => {
          setSaveLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="col-md-12">
          <Spin spinning={getLoading}>
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-0">
                  {traslate("Currency Selection for Platform and Tools")}
                </h4>
                <p class="mt-3">
                  {traslate("Default Currency Is")}{" "}
                  {selectlanguageForDelarList.currencyType}
                </p>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-6 mt-0">
                        <div className="input__block mb-0">
                          <label> {traslate("Select Currency")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Choose Any"
                            name="Currency"
                            value={
                              CurrencySelect.Currency
                                ? CurrencySelect.Currency
                                : []
                            }
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleOnChange(e, "select", "Currency")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option Value="">
                              {" "}
                              {traslate("Select Currency")}{" "}
                            </Option>
                            {selectCurrencyDropdowenList.map((item) => {
                              return (
                                <React.Fragment>
                                  <Option value={item.currencyId}>
                                    {item.currencyType}
                                  </Option>
                                </React.Fragment>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-3">
                    <Button
                      loading={saveLaoding}
                      className={
                        disableBtn ? "disabled-btn   me-4" : "primary-btn  me-4"
                      }
                      onClick={handleCurrencyUpdate}
                      disabled={disableBtn == true ? true : false}
                    >
                      {traslate("Apply")}
                    </Button>
                    <button
                      className={
                        disableBtn ? "disabled-btn   " : "primary-btn  "
                      }
                      onClick={handleCancel}
                      disabled={disableBtn == true ? true : false}
                    >
                      {traslate("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageCurrency;
