import * as React from "react"
const GenderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="_x32_"
    width={15}
    height={15}
    viewBox="0 0 512 512"
    {...props}
    style={{
      marginRight:"10px"
    }}
  >
    <style>{".st0{fill:#000}"}</style>
    <path
      d="M474.234 284.88c23.29-23.268 37.781-55.654 37.766-91.185.015-35.53-14.476-67.917-37.766-91.178-23.261-23.298-55.64-37.788-91.185-37.766-35.53-.022-67.924 14.469-91.17 37.766-23.304 23.261-37.795 55.648-37.78 91.178-.015 35.53 14.476 67.916 37.78 91.185a128.684 128.684 0 0 0 73.21 36.488v52.976h-58.486v35.944h58.486v56.766h35.937v-56.766h58.486v-35.944h-58.486v-52.976a128.682 128.682 0 0 0 73.208-36.488zm-91.185-8.959c-11.413-.008-22.172-2.301-32.002-6.454-14.724-6.222-27.327-16.684-36.198-29.817-8.857-13.147-14.012-28.844-14.012-45.955 0-11.42 2.294-22.172 6.447-31.994 6.214-14.731 16.669-27.326 29.81-36.198 13.155-8.864 28.85-14.019 45.955-14.034 11.413.008 22.172 2.302 32.002 6.462 14.724 6.214 27.327 16.676 36.198 29.809 8.857 13.156 14.012 28.851 14.026 45.956-.014 11.42-2.308 22.172-6.461 31.995-6.214 14.73-16.683 27.333-29.81 36.198-13.155 8.87-28.836 14.018-45.955 14.032zM146.912 202.305v-88.55l35.69 35.683 25.424-25.41-79.075-79.082-79.09 79.082 25.41 25.41 35.704-35.69v88.557a128.706 128.706 0 0 0-73.209 36.502C14.462 262.069-.014 294.448 0 329.979c-.014 35.545 14.462 67.924 37.766 91.184 23.261 23.305 55.64 37.788 91.185 37.774 35.53.014 67.91-14.469 91.17-37.774 23.304-23.261 37.78-55.64 37.78-91.184 0-35.53-14.476-67.91-37.78-91.171a128.704 128.704 0 0 0-73.209-36.503zm57.804 159.676c-6.229 14.73-16.683 27.326-29.81 36.198-13.154 8.872-28.85 14.027-45.955 14.027-11.427 0-22.172-2.294-32.002-6.447-14.723-6.229-27.327-16.684-36.198-29.817-8.872-13.155-14.026-28.844-14.026-45.962 0-11.412 2.294-22.164 6.446-31.987 6.229-14.723 16.684-27.326 29.824-36.198 13.14-8.864 28.836-14.011 45.955-14.026 11.413 0 22.157 2.294 32.002 6.454 14.709 6.222 27.312 16.676 36.184 29.809 8.871 13.156 14.026 28.844 14.026 45.948.001 11.425-2.294 22.185-6.446 32.001z"
      className="st0"
    />
  </svg>
)
export default GenderIcon
