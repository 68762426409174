import React, { useState, useEffect } from "react";
import { Select, Tooltip, Spin } from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import AdminDiamondCutComp from "../../AdminChartsGroup/AdminDiamondCutComp";
import AdminDiamondShapeComp from "../../AdminChartsGroup/AdminDiamondShapeComp";
import AdminDiamondClarityComp from "../../AdminChartsGroup/AdminDiamondClarityComp";
import AdminDiamondCertificateComp from "../../AdminChartsGroup/AdminDiamondCertificateComp";
import moment from "moment";
import { useSelector } from "react-redux";
import AdminDiamondReportService from "../../../services/reports-AdminDiamond-service";
import AdminDiamondCutGradeComp from "../../AdminChartsGroup/AdminDiamondCutGradeComp";
import AdminDiamondColorSearchesComp from "../../AdminChartsGroup/AdminDiamondColorSearchesComp";
import AdminDiamondSizeSearchesComp from "../../AdminChartsGroup/AdminDiamondSizeSearchesComp";
import AdminDiamondClicksComp from "../../AdminChartsGroup/AdminDiamondClicksComp";
import AdminDiamondPriceComp from "../../AdminChartsGroup/AdminDiamondPriceComp";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

const ExtendedDiamondChartReports = () => {
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: []
  };
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [forDealer, setForDealer] = useState("");
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [isApplyFilter, setIsApplyFilter] = useState(false);

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1]
      }));
    }
  };

  const handleCheckDealermembers = (id) => {
    let inputData = {
      dealerID: id
    };
    AdminDiamondReportService.CheckDealermembers(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var forDealer = responseData.forDealer;
        console.log(JSON.stringify(response), "handleCheckDealermembers");
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setForDealer(forDealer);
          } else {
            setForDealer("");
          }
          handleLoadChartReports(id, forDealer);
        } else {
          setForDealer("");
        }
      })
      .catch((error) => {
        console.log(error);
        setForDealer("");
      });
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      type: "Global",
      reportName: "ExtDiamondChrt"
    };
    setLoadChartReportsLoading(true);

    AdminDiamondReportService.LoadChartReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var TotalDiamondClicks =
          responseData.TotalDiamondClicks[0].TotalClickCnt;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setTotalDiamondClicks(TotalDiamondClicks);
          }
        } else {
          setTotalDiamondClicks([]);
        }
      })
      .catch((error) => {
        setLoadChartReportsLoading(false);
        setTotalDiamondClicks([]);
      })
      .finally(() => setLoadChartReportsLoading(false));
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setGlobalDiamond((prevState) => ({
          ...prevState,
          vendorIds: vId
        }));
      } else {
        vId = e;
        setGlobalDiamond((prevState) => ({
          ...prevState,
          vendorIds: e
        }));
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setGlobalDiamond((prevState) => ({
          ...prevState,
          retailerIds: rId
        }));
      } else {
        rId = e;
        setGlobalDiamond((prevState) => ({
          ...prevState,
          retailerIds: e
        }));
      }
    } else {
      setGlobalDiamond({
        ...globalDiamonds,
        [name]: e
      });
    }
  };

  const handleApplyFilter = () => {
    handleCheckDealermembers(dealerId);
    setIsApplyFilter(!isApplyFilter);
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Extended Diamond Chart Reports")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Select Diamond")} </label>
              <Select
                className="border__grey"
                placeholder="All"
                showSearch
                name="SelectedDiamondValue"
                optionFilterProp="children"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All"> {traslate("All")} </Option>
                <Option value="Mined"> {traslate("Mined Diamond")} </Option>
                <Option value="Lab"> {traslate("Lab Grown Diamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <button
                className="primary-btn mobile-mt-0"
                style={{ marginTop: 25 }}
                onClick={handleApplyFilter}
              >
                {traslate("Apply Filter")}
              </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4 mobile-m-0">
            <div className="input__block">
              <label>
                <Spin spinning={LoadChartReportsLoading} size="small">
                  <b>
                    {traslate("Total Clicks")} :{" "}
                    {TotalDiamondClicks ? TotalDiamondClicks : 0}
                  </b>
                </Spin>
                <Tooltip
                  placement="right"
                  title={traslate(
                    "Displays the total number of jewelry clicks through the currently specified date range above"
                  )}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>
          <div className="col-lg-12 mt-4 mobile-m-0">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondCutComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondShapeComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondClarityComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondCertificateComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondCutGradeComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondColorSearchesComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondSizeSearchesComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondClicksComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="chart__section">
              {forDealer && (
                <AdminDiamondPriceComp
                  globalDiamonds={globalDiamonds}
                  mainForDealer={forDealer}
                  isApplyFilter={isApplyFilter}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExtendedDiamondChartReports;
