import {
  FILTER_VALUE_SUBMIT,
  ALL_FILTER_VALUE_SUBMIT,
} from "../../actions/manageCollection/types";
const initialState = {
  filterValue: "All",
  allFilterValue: "",
};

function manageCollectionReducer(ManageCollectionState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FILTER_VALUE_SUBMIT:
      return { ...ManageCollectionState, filterValue: payload };
    case ALL_FILTER_VALUE_SUBMIT:
      return { ...ManageCollectionState, allFilterValue: payload };
    default:
      return ManageCollectionState;
  }
}
export default manageCollectionReducer;
