import React, { useState, useEffect, useRef } from "react";
import { Button, Table } from "antd";
import traslate from "../../../i18n/translate";
import AdminReportDataService from "../../../services/admin-report.service";
import { BlobToDownloadFile } from "../../../helper/commanFunction";

/*Initial State for Binding Starts*/
const initialToolsReportState = {
  toolsReportRadio: 69,
};
/*Initial State for Binding Ends*/

const ToolsReports = () => {
  const [globalEvents, setGlobalEvents] = useState(initialToolsReportState);
  const [downloadDataloading, setDownloadDataloading] = useState(false);
  const [ToolsReportsGridDataList, setToolsReportsGridDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);
  const [sortColumnName, setSortColumnName] = useState("dealerId");
  const [sortType, setSortType] = useState("asc");
  const [key, setKey] = useState(true);
  const toolsReportRadioRef = useRef(null);

  useEffect(() => {
    handleGetToolsReportsGridData(
      globalEvents.toolsReportRadio,
      pageNo,
      pageSize,
      sortColumnName,
      sortType
    );
  }, []);

  const handleOnChangeRadio = (event) => {
    setGlobalEvents({
      ...globalEvents,
      toolsReportRadio: parseInt(event.target.name),
    });
  };

  /*API Binding Starts*/
  const handleGetToolsReportsGridData = (
    subMenuId = 69,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    toolsReportRadioRef.current = globalEvents.toolsReportRadio;
    let inputData = {
      subMenuId: subMenuId,
      pageIndex: pageNo,
      pageSize: pageSize,
      sortColumnName: sortColumnName || "dealerId",
      sortOrder: sortOrder || "ASC",
    };
    setLoading(true);
    AdminReportDataService.GetToolsReportsGridData(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var ToolsReportsGridDataList =
          response.data.responseData.getToolsReportsGridDataresp;
        if (message === "Success") {
          if (responseData) {
            var temptotalRecords = responseData.totalRecord;
            setTotalCount(temptotalRecords);
            setToolsReportsGridDataList(ToolsReportsGridDataList);
          }
        } else {
          setToolsReportsGridDataList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setToolsReportsGridDataList([]);
      })
      .finally(() => setLoading(false));
  };

  const handleDownloadToolDataCSV_ServerClick = () => {
    let inputData = {
      subMenuID: toolsReportRadioRef?.current
        ? toolsReportRadioRef?.current.toString()
        : "",
    };
    setDownloadDataloading(true);
    AdminReportDataService.downloadToolDataCSV_ServerClick(inputData)
      .then((response) => {
        var fileName = "ToolsReport.csv";
        BlobToDownloadFile(response.data, fileName);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDownloadDataloading(false);
      });
  };

  const handleApplyFilter = () => {
    setKey(!key);
    handleGetToolsReportsGridData(
      globalEvents.toolsReportRadio,
      1,
      10,
      sortColumnName,
      sortType
    );
    setPageNo(1);
    setPageSize(10);
  };

  /*Sorting API Starts*/
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerId";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetToolsReportsGridData(
      globalEvents.toolsReportRadio,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*Sorting API Ends*/

  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block mobile-m-0">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Tools Reports")}
              </h4>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="MasterLink"
                        name="69"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === 69 ? true : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="MasterLink">
                        {" "}
                        {traslate("MasterLink")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="RingBuilder"
                        name="61"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === 61 ? true : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="RingBuilder">
                        {" "}
                        {traslate("RingBuilder")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="StudBuilder"
                        name="54"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === 54 ? true : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="StudBuilder">
                        {" "}
                        {traslate("StudBuilder")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="PendantBuilder"
                        name="55"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === 55 ? true : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="PendantBuilder">
                        {" "}
                        {traslate("PendantBuilder")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="WatchBuilder"
                        name="68"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === 68 ? true : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="WatchBuilder">
                        {" "}
                        {traslate("WatchBuilder")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="button__block mb-4 mt-2 mobile-m-0">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="limit_section">
            <div className="col-lg-12 mt-3">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-12 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() => handleDownloadToolDataCSV_ServerClick()}
                      loading={downloadDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {traslate("Download")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              key={key}
              className="pendjewelreq__tbl"
              loading={loading}
              columns={[
                {
                  title: traslate("Dealer ID"),
                  dataIndex: "dealerID",
                  sorter: (a, b) => {},
                  width: 120,
                },
                {
                  title: traslate("Dealer Company"),
                  dataIndex: "dealerCompany",
                  sorter: (a, b) => {},
                  width: 200,
                },
                {
                  title: traslate("Version"),
                  dataIndex: "dealerCompany",
                  render: (row, item) => {
                    if (
                      item.hasMasterLink3 == "True" ||
                      (item.hasMasterLink1 == "True" &&
                        item.hasMasterLink2 == "True" &&
                        item.hasMasterLink3 == "True")
                    ) {
                      return (
                        <React.Fragment>
                          <span>{traslate("ML3")}</span>
                        </React.Fragment>
                      );
                    } else if (item.hasMasterLink1 == "True") {
                      return (
                        <React.Fragment>
                          <span>{traslate("Legacy")}</span>
                        </React.Fragment>
                      );
                    } else if (item.hasMasterLink3 == "True") {
                      return (
                        <React.Fragment>
                          <span>{traslate("ML2")}</span>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment>
                          <span>-</span>
                        </React.Fragment>
                      );
                    }
                  },
                  width: 100,
                },
              ]}
              dataSource={ToolsReportsGridDataList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                current: pageNo,
                onChange: (pageNum) => setPageNo(pageNum),
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ToolsReports;
