import {
  SET_HEADERMENU_DATA,
  SET_IMPERSONATE_HEADERMENU_DATA,
  SET_ADMIN_HEADERMENU_DATA,
  SET_CHECKLINK_HEADERMENU_DATA,
  SET_OPENKEY_HEADERMENU_DATA,
  RESET_STATE,
  SELECT_SIDE_MENU,
  EDIT_FOURTIER_MENU,
  EDIT_THIRD_MENU,
  ORGINAL_FOURTIER_MENU,
  OLD_URL_ARRAY,
  ADMIN_MENU_ARRAY,
  RESET_IFRAME,
  CONTACT_PAGE_NAME_TYPE,
  SET_SELECTED_LANGUAGE,
  SET_SELECTED_VENDOR,
} from "./types";

export const setHeaderMenuData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_HEADERMENU_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setImpersonateHeaderMenuData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IMPERSONATE_HEADERMENU_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setAdminHeaderMenuData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ADMIN_HEADERMENU_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setChecklinkHeaderMenuData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CHECKLINK_HEADERMENU_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setOpenKeyHeaderMenuData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_OPENKEY_HEADERMENU_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setMenuResetData = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_STATE,
    });
  } catch (err) {}
};

export const setSelectSideMenu = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SELECT_SIDE_MENU,
      payload: data,
    });
  } catch (err) {}
};

export const setEditTierMenu = (data) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_FOURTIER_MENU,
      payload: data,
    });
  } catch (err) {}
};

export const setEditCollectionTierMenu = (data) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_THIRD_MENU,
      payload: data,
    });
  } catch (err) {}
};

export const setoldArrayUrlMenu = (data) => async (dispatch) => {
  try {
    dispatch({
      type: OLD_URL_ARRAY,
      payload: data,
    });
  } catch (err) {}
};

export const setAdminMenuData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_MENU_ARRAY,
      payload: data,
    });
  } catch (err) {}
};

//To Reset Iframe

export const handleResetIframe = (data) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_IFRAME,
      payload: data,
    });
  } catch (err) {}
};

export const setSelectedLanguage = (language) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_LANGUAGE,
      payload: language,
    });
  } catch (err) {}
};

export const setSelectedVendor = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_VENDOR,
      payload: data,
    });
  } catch (err) {}
};
