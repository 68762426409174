import { httpDealer } from "../helper/http-common";
class UserSettingsService {

    GetCompanyProfileSettingDetails(inputData) {
        return httpDealer.post(
            "/Dealer/GetCompanyProfileSettingDetails",
            inputData
        );
    }
    UpdateCompanyProfileSettingDetails(inputData) {
        return httpDealer.post("/Dealer/UpdateCompanyProfileSettingDetails",inputData);
    } 
    
    UpdateSocialIntegration(inputData) {
        const headers = {
          "Content-Type": "multipart/form-data",
        };
        return httpDealer.post("/Dealer/AddorEditSocialIntegration", inputData, { headers });
    }

    GetSocialIntegrationSetting(inputData) {
        const headers = {
            "Content-Type": "multipart/form-data",
          };
          return httpDealer.post("/Dealer/GetUserSocialIntegrations", inputData, { headers });
    }
}

export default new UserSettingsService();
