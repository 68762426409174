import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Select } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setChecklinkHeaderMenuData } from "../../actions/headerMenu/headerMenu";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import AdminToolsDataService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";

const Register = (props) => {
  const [visible, setVisible] = useState(false);

  const { Option } = Select;

  const [RegisterData, setRegisterData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    userName: "",
    password: "",
    accountType: "",
    contactNo: "",
  });
  const [validations, setValidations] = useState({
    companyNameVal: "",
    firstNameVal: "",
    lastNameVal: "",
    emailVal: "",
    addressVal: "",
    cityVal: "",
    stateVal: "",
    countryVal: "",
    zipCodeVal: "",
    userNameVal: "",
    passwordVal: "",
    accountTypeVal: "",
    contactNoVal: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const [locationDropdownListByCountry, setLocationDropdownListByCountry] =
    useState([]);
  const [locationDropdownListByState, setLocationDropdownListByState] =
    useState([]);
  const [locationDropdownListByCity, setLocationDropdownListByCity] = useState(
    []
  );

  const WhiteLabeling = useSelector((state) => state.loginReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogoRedirection = () => {
    dispatch(setChecklinkHeaderMenuData("Dashboard"));
    navigate("/dashboard");
  };
  const search = useLocation().search;
  const paramCompanyName = new URLSearchParams(search).get("CompanyName");
  const paramCity = new URLSearchParams(search).get("City");
  const paramState = new URLSearchParams(search).get("State");
  const paramCountry = new URLSearchParams(search).get("Country");
  const paramContactName = new URLSearchParams(search).get("ContactName");
  const paramEmail = new URLSearchParams(search).get("Email");
  const paramPhoneNumber = new URLSearchParams(search).get("PhoneNumber");
  const paramType = new URLSearchParams(search).get("Type");

  useEffect(() => {
    if (paramCompanyName) {
      handleGetLocationDropdownDetailsByCountry();
      handleGetLocationDropdownDetailsByState(paramCountry, "State");
      handleGetLocationDropdownDetailsByCity(paramState, "City");
      setTimeout(() => {
        setRegisterData((prevState) => ({
          ...prevState,
          companyName: paramCompanyName ? paramCompanyName : "",
          city: paramCity ? parseInt(paramCity) : "",
          state: paramState ? parseInt(paramState) : "",
          country: paramCountry ? parseInt(paramCountry) : "",
          userName: paramContactName ? paramContactName : "",
          email: paramEmail ? paramEmail : "",
          contactNo: paramPhoneNumber ? paramPhoneNumber : "",
          accountType:
            paramType == "Retailer" ? "2" : paramType == "Vendor" ? "1" : "1",
        }));
      }, 2000);
    } else {
      handleGetLocationDropdownDetailsByCountry();
      handleGetLocationDropdownDetailsByState(233, "State");
    }
  }, []);

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRegisterData({
        ...RegisterData,
        [name]: e,
      });
      if (name === "country") {
        setValidations((prevdata) => ({
          ...prevdata,
          countryVal: "",
        }));
        handleGetLocationDropdownDetailsByState(e, "State");
      }
      if (name === "state") {
        setValidations((prevdata) => ({
          ...prevdata,
          stateVal: "",
        }));
        handleGetLocationDropdownDetailsByCity(e, "City");
      }
      if (name === "city") {
        setValidations((prevdata) => ({
          ...prevdata,
          cityVal: "",
        }));
      }
      if (name === "accountType") {
        setValidations((prevdata) => ({
          ...prevdata,
          accountTypeVal: "",
        }));
      }
    } else {
      setRegisterData({
        ...RegisterData,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "companyName") {
        setValidations((prevdata) => ({
          ...prevdata,
          companyNameVal: "",
        }));
      }
      if (e.target.name === "firstName") {
        setValidations((prevdata) => ({
          ...prevdata,
          firstNameVal: "",
        }));
      }
      if (e.target.name === "lastName") {
        setValidations((prevdata) => ({
          ...prevdata,
          lastNameVal: "",
        }));
      }
      if (e.target.name === "email") {
        setValidations((prevdata) => ({
          ...prevdata,
          emailVal: "",
        }));
      }
      if (e.target.name === "address") {
        setValidations((prevdata) => ({
          ...prevdata,
          addressVal: "",
        }));
      }

      if (e.target.name === "zipCode") {
        setValidations((prevdata) => ({
          ...prevdata,
          zipCodeVal: "",
        }));
      }
      if (e.target.name === "userName") {
        setValidations((prevdata) => ({
          ...prevdata,
          userNameVal: "",
        }));
      }
      if (e.target.name === "password") {
        setValidations((prevdata) => ({
          ...prevdata,
          passwordVal: "",
        }));
      }
      if (e.target.name === "contactNo") {
        setValidations((prevdata) => ({
          ...prevdata,
          contactNoVal: "",
        }));
      }
    }
  };

  // Show Password

  const handlePassShowHide = () => {
    setshowPassword(!showPassword);
  };

  const handleValidation = () => {
    const validations = {};
    let isValid = true;

    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // For Password
    if (!RegisterData.companyName.trim()) {
      isValid = false;
      validations.companyNameVal = "Company name is compulsory";
    } else {
      validations.companyNameVal = "";
    }
    if (!RegisterData.firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First name is compulsory";
    } else {
      validations.firstNameVal = "";
    }
    if (!RegisterData.lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last name is compulsory";
    } else {
      validations.lastNameVal = "";
    }
    var validEmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!RegisterData.email.trim()) {
      isValid = false;
      validations.emailVal = traslate("Email address is compulsory");
    } else if (!RegisterData.email.match(validEmailRegex)) {
      isValid = false;
      validations.emailVal = traslate("Email address is invalid");
    } else {
      if (!RegisterData.email.match(validRegex)) {
        isValid = false;
        validations.emailVal = traslate("Email address is invalid");
      } else {
        validations.emailVal = "";
      }
    }
    if (!RegisterData.address.trim()) {
      isValid = false;
      validations.addressVal = traslate("Address is compulsory");
    } else {
      validations.addressVal = "";
    }
    if (!RegisterData.city) {
      isValid = false;
      validations.cityVal = "City is compulsory";
    } else {
      validations.cityVal = "";
    }
    if (!RegisterData.state) {
      isValid = false;
      validations.stateVal = "State is compulsory";
    } else {
      validations.stateVal = "";
    }
    if (!RegisterData.country) {
      isValid = false;
      validations.countryVal = "Kindly select the Country ";
    } else {
      validations.countryVal = "";
    }
    if (!RegisterData.zipCode.trim()) {
      isValid = false;
      validations.zipCodeVal = "Zip Code is compulsory";
    } else {
      validations.zipCodeVal = "";
    }
    if (!RegisterData.userName.trim()) {
      isValid = false;
      validations.userNameVal = "User name is compulsory";
    } else {
      validations.userNameVal = "";
    }
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );
    if (!RegisterData.password.trim()) {
      isValid = false;
      validations.passwordVal = "Password is compulsory";
    } else if (!RegisterData.password.match(strongRegex)) {
      isValid = false;
      validations.passwordVal =
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character";
    } else {
      validations.passwordVal = "";
    }
    if (!RegisterData.accountType) {
      isValid = false;
      validations.accountTypeVal = "Kindly select the Account Type ";
    } else {
      validations.accountTypeVal = "";
    }

    if (!RegisterData.contactNo.trim()) {
      isValid = false;
      validations.contactNoVal = traslate("Phone number is compulsory");
    } else if (
      (RegisterData.contactNo.length >= 10 &&
        RegisterData.contactNo.includes("-")) ||
      (RegisterData.contactNo.length >= 10 &&
        RegisterData.contactNo.includes(",")) ||
      (RegisterData.contactNo.length >= 10 &&
        RegisterData.contactNo.includes("+"))
    ) {
      validations.contactNoVal = "";
    } else if (
      !/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(RegisterData.contactNo)
    ) {
      isValid = false;
      validations.contactNoVal = traslate("Phone number is invalid");
    } else {
      validations.contactNoVal = "";
    }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  //Cascading Dropdown API starts
  const handleGetLocationDropdownDetailsByCountry = () => {
    let inputData = {
      id: 0,
      dropdownType: "Country",
    };

    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setLocationDropdownListByCountry(responseData);
            setRegisterData((prevState) => ({
              ...prevState,
              country: 233,
            }));
          } else {
            setLocationDropdownListByCountry([]);
          }
        } else {
          setLocationDropdownListByCountry([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetLocationDropdownDetailsByState = (id, dropdownType) => {
    let inputData = {
      id: id,
      dropdownType: dropdownType,
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDropdownListByState(responseData);
        } else {
          setLocationDropdownListByState([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetLocationDropdownDetailsByCity = (id, dropdownType) => {
    let inputData = {
      id: id,
      dropdownType: dropdownType,
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDropdownListByCity(responseData);
        } else {
          setLocationDropdownListByCity([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Cascading Dropdown API ends

  const handleCheckDealerCompanyName = async () => {
    try {
      if (!RegisterData.companyName) {
        return false;
      }
      let inputData = {
        companyName: RegisterData.companyName,
      };

      var isValid = true;
      await AdminToolsDataService.CheckDealerCompanyName(inputData)
        .then((response) => {
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isCompanyExist;

          isValid = checkMessage;
          setValidations((prevState) => ({
            ...prevState,
            companyNameVal: checkMessage ? "Company Name is Taken" : "",
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    return isValid;
  };

  const handleCheckDealerEmail = async () => {
    try {
      if (!RegisterData.email) {
        return false;
      }
      let inputData = {
        email: RegisterData.email,
      };

      var isValid = true;
      await AdminToolsDataService.CheckDealerEmail(inputData)
        .then((response) => {
          var checkMessage = response.data.responseData.isEmailExist;

          isValid = checkMessage;
          setValidations((prevState) => ({
            ...prevState,
            emailVal: checkMessage ? "Email is Taken" : "",
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    return isValid;
  };

  const handleCheckDealerUser = async () => {
    try {
      if (!RegisterData.userName) {
        return false;
      }
      let inputData = {
        userName: RegisterData.userName,
      };
      var isValid = true;
      await AdminToolsDataService.CheckDealerUser(inputData)
        .then((response) => {
          var checkMessage = response.data.responseData.isUserNameExist;
          isValid = checkMessage;
          setValidations((prevState) => ({
            ...prevState,
            userNameVal: checkMessage && "Username is Taken",
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    return isValid;
  };

  const handleOpenLink = (link) => {
    window.open(link, "__blank");
  };

  const handleRegister = async () => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    } else {
      setSubmitLoading(true);
      let promise1 = await handleCheckDealerEmail();
      let promise2 = await handleCheckDealerCompanyName();
      let promise3 = await handleCheckDealerUser();
      Promise.all([promise1, promise2, promise3]).then((values) => {
        let checker = (arr) => arr.every((v) => v === false);
        if (checker(values)) {
          setSubmitLoading(true);
          try {
            let inputData = {
              dealerId: 0,
              companyName: RegisterData.companyName,
              streetAddress: RegisterData.address,
              cityId: 0,
              stateId: 0,
              dealerCity: RegisterData.city.toString(),
              dealerState: RegisterData.state.toString(),
              zipCode: RegisterData.zipCode,
              countryId: RegisterData.country,
              contactName: RegisterData.firstName + " " + RegisterData.lastName,
              emailAddress: RegisterData.email,
              phoneNumber: RegisterData.contactNo,
              userName: RegisterData.userName,
              password: RegisterData.password,
              permissionTypeId: RegisterData.accountType,
              isVerified: false,
              retailerStatusId: 0,
              vendorStatusId: 0,
              ipAddress: "noneknown",
              logInUser: 0,
              logInUserName: "datapending",
              isRegister: true,
              mailBody: "",
              mode: "mode",
            };
            setSubmitLoading(true);
            AdminToolsDataService.CreateNewClient(inputData)
              .then((response) => {
                var message = response.data.message;
                setSubmitLoading(false);
                if (message === "Success") {
                  NotificationManager.success("User Registered Successfully.");

                  handleGetLocationDropdownDetailsByCountry();
                  handleGetLocationDropdownDetailsByState(233, "State");
                  setshowPassword(false);
                } else {
                  NotificationManager.success("User Registeration Failed.");
                }
              })
              .catch((error) => {
                console.log(error);
                setSubmitLoading(false);
              });
          } catch (error) {
            console.log(error);
            setSubmitLoading(false);
          }
          setVisible(true);
          setRegisterData({
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
            userName: "",
            password: "",
            accountType: "",
            contactNo: "",
          });
          setValidations({
            companyNameVal: "",
            firstNameVal: "",
            lastNameVal: "",
            emailVal: "",
            addressVal: "",
            cityVal: "",
            stateVal: "",
            countryVal: "",
            zipCodeVal: "",
            userNameVal: "",
            passwordVal: "",
            accountTypeVal: "",
            contactNoVal: "",
          });
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleOnTop = () => {
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  return (
    <div className="login__section register__section container-fluid h-100">
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="login__header">
              <div className="logo__section">
                <img
                  src={WhiteLabeling?.whiteLebelData?.[0]?.logo || ""}
                  style={{ marginBottom: "10px" }}
                  onClick={handleLogoRedirection}
                />
              </div>

              <div className="right__text">
                <p>Social Product Network for the Jewelry Industry</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form__section">
              <div className="login__block">
                <h3>Welcome to {WhiteLabeling?.whiteLebelData?.[0]?.name || "JewelCloud"}</h3>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>
                        Company Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Company Name"
                        name="companyName"
                        value={RegisterData.companyName}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.companyNameVal && "border__red"}
                        onBlur={handleCheckDealerCompanyName}
                      />
                      <div>
                        {validations.companyNameVal && (
                          <p className="error-color-red">
                            {validations.companyNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        First Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={RegisterData.firstName}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.firstNameVal && "border__red"}
                      />
                      <div>
                        {validations.firstNameVal && (
                          <p className="error-color-red">
                            {validations.firstNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        Last Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        value={RegisterData.lastName}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.lastNameVal && "border__red"}
                      />
                      <div>
                        {validations.lastNameVal && (
                          <p className="error-color-red">
                            {validations.lastNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>
                        Email
                        <span>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={RegisterData.email}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.emailVal && "border__red"}
                        onBlur={handleCheckDealerEmail}
                      />
                      <div>
                        {validations.emailVal && (
                          <p className="error-color-red">
                            {validations.emailVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>
                        Address
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={RegisterData.address}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.addressVal && "border__red"}
                      />
                      <div>
                        {validations.addressVal && (
                          <p className="error-color-red">
                            {validations.addressVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        Contact No
                        <span>*</span>
                      </label>
                      <div className="contno__inptdiv">
                        <input
                          placeholder="Enter Contact No"
                          name="contactNo"
                          value={RegisterData.contactNo}
                          autocomplete="off"
                          onChange={handleOnChange}
                          className={validations.contactNoVal && "border__red"}
                        />
                      </div>
                      <div>
                        {validations.contactNoVal && (
                          <p className="error-color-red">
                            {validations.contactNoVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        Country
                        <span>*</span>
                      </label>

                      <Select
                        showSearch
                        name="country"
                        placeholder="Select Country"
                        value={
                          RegisterData.country ? RegisterData.country : null
                        }
                        optionFilterProp="children"
                        onChange={(e) => handleOnChange(e, "select", "country")}
                        className={validations.countryVal ? "border__red" : ""}
                      >
                        <Option>Select Country</Option>
                        {locationDropdownListByCountry.map((item) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                      <div>
                        {validations.countryVal && (
                          <p className="error-color-red select_block">
                            {validations.countryVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {RegisterData.country && (
                    <div className="col-lg-6">
                      <div className="input__block">
                        <label>
                          State
                          <span>*</span>
                        </label>
                        <Select
                          showSearch
                          name="state"
                          placeholder="Select State"
                          value={RegisterData.state ? RegisterData.state : null}
                          autocomplete="off"
                          optionFilterProp="children"
                          onChange={(e) => handleOnChange(e, "select", "state")}
                          className={validations.stateVal ? "border__red" : ""}
                        >
                          <Option>Select State</Option>
                          {locationDropdownListByState.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <div>
                          {validations.stateVal && (
                            <p className="error-color-red">
                              {validations.stateVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {RegisterData.state && (
                    <div className="col-lg-6">
                      <div className="input__block">
                        <label>
                          City
                          <span>*</span>
                        </label>
                        <Select
                          showSearch
                          name="city"
                          placeholder="Select City"
                          value={RegisterData.city ? RegisterData.city : null}
                          autocomplete="off"
                          optionFilterProp="children"
                          onChange={(e) => handleOnChange(e, "select", "city")}
                          className={validations.cityVal ? "border__red" : ""}
                        >
                          <Option>Select City</Option>
                          {locationDropdownListByCity.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <div>
                          {validations.cityVal && (
                            <p className="error-color-red">
                              {validations.cityVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        Zip Code
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Zip Code"
                        name="zipCode"
                        value={RegisterData.zipCode}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.zipCodeVal && "border__red"}
                      />
                      <div>
                        {validations.zipCodeVal && (
                          <p className="error-color-red">
                            {validations.zipCodeVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        User Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter User Name"
                        name="userName"
                        value={RegisterData.userName}
                        autocomplete="off"
                        onChange={handleOnChange}
                        className={validations.userNameVal && "border__red"}
                        onBlur={handleCheckDealerUser}
                      />

                      <div>
                        {validations.userNameVal && (
                          <p className="error-color-red">
                            {validations.userNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        Password
                        <span>*</span>
                      </label>
                      <input
                        // type="password"
                        placeholder="Enter Password"
                        name="password"
                        value={RegisterData.password}
                        autoComplete="new-password"
                        onChange={handleOnChange}
                        type={showPassword ? "text" : "password"}
                        className={validations.passwordVal && "border__red"}
                      />
                      <span class="p-viewer" onClick={handlePassShowHide}>
                        <i
                          class={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                          aria-hidden="true"
                        ></i>
                      </span>
                      <div>
                        {validations.passwordVal && (
                          <p className="error-color-red">
                            {validations.passwordVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input__block">
                      <label>
                        Account Type
                        <span>*</span>
                      </label>
                      <Select
                        showSearch
                        placeholder="Select AccountType"
                        name="accountType"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "accountType")
                        }
                        value={
                          RegisterData.accountType
                            ? RegisterData.accountType
                            : null
                        }
                        autocomplete="off"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className={
                          validations.accountTypeVal ? "border__red" : ""
                        }
                      >
                        <Option>Select Account Type</Option>
                        <Option value="2">Retailer</Option>
                        <Option value="1">Jewelry Vendor</Option>
                        <Option value="3">Diamond Vendor</Option>
                      </Select>
                      <div>
                        {validations.accountTypeVal && (
                          <p className="error-color-red select_block">
                            {validations.accountTypeVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mx-auto">
                    <div className="input__block">
                      <Button
                        type="primary"
                        htmlType="button"
                        className="primary-btn w-100"
                        onClick={handleRegister}
                        loading={submitLoading}
                      >
                        Register
                      </Button>

                      <p className="login__btn">
                        Already have an account?
                        <Link to={"/"} className="manage__Link">
                          {" "}
                          Login Here{" "}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0">
            <div className="footer__section login__footer position-relative">
              <div className="left__block">
                <div className="footer__logo">
                  <img
                    className="imageHover"
                    src={WhiteLabeling?.whiteLebelData?.[0]?.footerLogo || ""}
                    alt=""
                    onClick={() =>
                      handleOpenLink(
                        WhiteLabeling?.whiteLebelData?.[0]?.footerLogoLink || ""
                      )
                    }
                  />
                </div>
                <div className="footer__menu">
                  <ul>
                    <li>
                      <Link to={"/termsOfService"} onClick={handleOnTop}>
                        {traslate("Terms of Service")}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacyStatement"} onClick={handleOnTop}>
                        {traslate("Privacy Statement")}
                      </Link>
                    </li>
                    <li>
                      <a href="#">
                        Copyright {new Date().getFullYear()}{" "}
                        {WhiteLabeling?.whiteLebelData?.[0]?.name || ""}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right__block">
                <ul>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo1} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo2} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo3} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo4} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo5} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className=""
        visible={visible}
        onOk={() => navigate("/")}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" className="d-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => navigate("/")}>
            Ok
          </Button>,
        ]}
      >
        <div className="col-lg-12 text-center">
          <h4 className="subheading">Thank You For Registration</h4>
          <p>Please check your mail for an activation link</p>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
