import { http } from "../helper/http-common";
class AdminCampaignsService {
	GetMailingList(inputData) {
		return http.post("/Campaign/GetMailingList", inputData);
	}
	GetRecepientList(inputData) {
		return http.post("/Campaign/GetRecepientList", inputData);
	}
	GetRecepientListByMailingListName(inputData) {
		return http.post("/Campaign/GetRecepientListByMailingListName", inputData);
	}
	SendCampaignMail(inputData) {
		return http.post("/Campaign/SendCampaignMail", inputData);
	}
	LocationLoadDetails(inputData) {
		return http.post("/Profile/LocationLoadDetails", inputData);
	}
	GetCampaignList() {
		return http.get("/Campaign/GetCampaignList");
	}
	GetMailingListByCampaignName(inputData) {
		return http.post("/Campaign/GetMailingListByCampaignName", inputData);
	}
	Campaignfromgrid(inputData) {
		return http.post("/Campaign/Campaignfromgrid", inputData);
	}
	Campaignmailform(inputData) {
		return http.post("/Campaign/Campaignmailform", inputData);
	}
	Memebershipdropdowen(inputData) {
		return http.post("/Campaign/Memebershipdropdowen", inputData);
	}
	GetMailingListDropdownData(inputData) {
		return http.post("/Campaign/GetMailingListDropdownData", inputData);
	}
	CampaignAddMailingList(inputData) {
		return http.post("/Campaign/CampaignAddMailingList", inputData);
	}
	CampaignAddMailingRecipientList(inputData) {
		return http.post("/Campaign/CampaignAddMailingRecipientList", inputData);
	}
	SaveCampaingMailing(inputData) {
		return http.post("/Campaign/SaveCampaingMailing", inputData);
	}
	CreateUpdateDeleteTemplates(inputData) {
		return http.post("/Campaign/CreateUpdateDeleteTemplates", inputData);
	}
	GetCampaignTemplateList(inputData) {
		return http.post("/Campaign/GetCampaignTemplateList", inputData);
	}
	GetDealerGroupsList(inputData) {
		return http.post("/Campaign/GetDealerGroupsList", inputData);
	}
	CreateUpdateDeleteDealerGroups(inputData) {
		return http.post("/Campaign/CreateUpdateDeleteDealerGroups", inputData);
	}
	GetCampaignListV2(inputData) {
		return http.post("/Campaign/GetCampaignListV2", inputData);
	}
	GetCampaignDropDownList(inputData) {
		return http.post("/Campaign/GetCampaignDropDownList", inputData);
	}
	CreateUpdateDeleteCampaignMaster(inputData) {
		return http.post("/Campaign/CreateUpdateDeleteCampaignMaster", inputData);
	}
	GetCampaignMailingListV2(inputData) {
		return http.post("/Campaign/GetCampaignMailingListV2", inputData);
	}
	PostSendCampaignMails(inputData) {
		return http.post("/Campaign/SendCampaignMails", inputData);
	}
	GetCampaignStats(sendGridCampaignID) {
		return http.get(
			`/SendGrid/GetCampaignStats?campaignId=${sendGridCampaignID}`
		);
	}
}

export default new AdminCampaignsService();
