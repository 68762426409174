import { httpPurchaseOrder } from "../helper/http-common";
class PurchaseOrderServices {
  // <--- Purchase Order Details ---->

  GetMyPurchaseOrder(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetMyPurchaseOrder",
      inputData
    );
  }
  purchaseorderhistotry(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/purchaseorderhistotry",
      inputData
    );
  }
  UpdateOrderStatus(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/UpdateOrderStatus",
      inputData
    );
  }
  CreateReOrder(inputData) {
    return httpPurchaseOrder.post("/PurchaseOrder/CreateReOrder", inputData);
  }
  GetWorksheetDetails(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetWorksheetDetails",
      inputData
    );
  }
  UpdateWorkSheetStatus(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/UpdateWorkSheetStatus",
      inputData
    );
  }
  InsertWorksheetDetails(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/InsertWorksheetDetails",
      inputData
    );
  }
  RemoveWorkSheetName(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/RemoveWorkSheetName",
      inputData
    );
  }
  GetDashBoardMyPurchaseOrder(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetDashBoardMyPurchaseOrder",
      inputData
    );
  }
  GetDashBoardWorksheetDetails(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetDashBoardWorksheetDetails",
      inputData
    );
  }
  GetPOConnectedVendorDropdown(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetPOConnectedVendorDropdown",
      inputData
    );
  }
  GetWorksheetDetailsById(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetWorksheetDetailsById",
      inputData
    );
  }
  GetVendorListForSelectProduct(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetVendorListForSelectProduct",
      inputData
    );
  }
  UpdateWorkSheetDetailsById(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/UpdateWorkSheetDetailsById",
      inputData
    );
  }
  GetGetConfirmOrderDetailByID(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetGetConfirmOrderDetailByID",
      inputData
    );
  }
  GetWorksheetDetailsSelectedValue(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GetWorksheetDetailsSelectedValue",
      inputData
    );
  }
  UpdateWorkSheetName(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/UpdateWorkSheetName",
      inputData
    );
  }
  RemoveWorkSheetDetailsById(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/RemoveWorkSheetDetailsById",
      inputData
    );
  }
  GeneratePOForSelectedVendor(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/GeneratePOForSelectedVendor",
      inputData
    );
  }
  CreateNewWorksheerdetils(inputData) {
    return httpPurchaseOrder.post(
      "/PurchaseOrder/CreateNewWorksheerdetils",
      inputData
    );
  }
}
export default new PurchaseOrderServices();
