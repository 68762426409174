import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { setChecklinkHeaderMenuData } from "../../actions/headerMenu/headerMenu";
import logoImg from "../../assets/images/logo-img.png";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import scrollToTopIcon from "../../assets/images/scrollToTop.png";
import traslate from "../../i18n/translate";

const TermsOfService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const WhiteLabeling = useSelector((state) => state.loginReducer);

  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const handleOnTop = () => {
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  function handleRedirect() {
    navigate("/");
  }
  const handleOpenLink = (link) => {
    window.open(link, "__blank");
  };
  return (
    <React.Fragment>
      <div className="login__section register__section container-fluid h-100">
        <div className="form__fields">
          <div className="row">
            <div className="col-md-12">
              <div className="login__header">
                <div className="logo__section">
                  <img
                    src={WhiteLabeling?.whiteLebelData?.[0]?.logo || ""}
                    onClick={() => handleRedirect()}
                    className="logo-redirect-sp"
                    style={{ marginBottom: "10px" }}
                  />
                </div> 
                <div className="right__text">
                  <p>Social Product Network for the Jewelry Industry</p>
                </div> 
              </div>
            </div>
                <div className="col-lg-12">
              <div className="form__section">
                <div className="login__block w-100 terms0fservice">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="jewelcloud_tos_text">
                          <div style={{color:"white"}} dangerouslySetInnerHTML={{ __html: WhiteLabeling?.whiteLebelData?.[0]?.terms_of_Service }} />            
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div>

                     
                        {/* //{WhiteLabeling?.whiteLebelData?.[0]?.privacy_Statement || ""} */}
            </div>

          
          </div>
        </div>
      </div>
      {showButton && (
        // <button onClick={scrollToTop} className="back-to-top">
        //     &#8679;
        // </button>
        <div className="scrollToTop-section">
          <img
            src={scrollToTopIcon}
            onClick={scrollToTop}
            alt="scrollToTopBtn"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TermsOfService;
