import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Button, Table, Modal, Spin, Checkbox } from "antd";
import { NotificationManager } from "react-notifications";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../../i18n/translate";
import {
  RingMinMaxMarkupPrice,
  RingMinMaxSize,
} from "../../../helper/commanData";
import PendantBuilderService from "../../../services/apps-pendantBuilder.service";
import AdminManageCurrencyService from "../../../services/admin-manageCurrency.service";
import { setCurrency } from "../../../actions/login/login";

const { Option } = Select;

export const PendantBuilderDiamondMarkup = (props) => {
  const dispatch = useDispatch();
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [makerLoading, setMakerLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [DealerId, setDealerId] = useState();
  const [delMarkupData, setDelMarkupData] = useState({
    dealermarkupid: "",
    dealerID: "",
  });
  const [DelCutomerMarkupData, setDelCutomerMarkupData] = useState({
    dealerID: "",
    customMarkupid: "",
  });
  const [isedit, setIsEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [costPriceAndSize, setCostPriceAndSize] = useState({});
  const [page, setPage] = useState("Custom");
  const [manufactureList, setmanufactureList] = useState([]);
  const [minMaxMarkupPrice] = useState(RingMinMaxMarkupPrice);
  const [minMaxSize] = useState(RingMinMaxSize);
  const [checkbox, setCheckBox] = useState("");
  const [MinedDiamondData, setMinedDiamondData] = useState({
    Method: "Custom",
    Currency: null,
    CustomMethod: null,
    DefaultMarkup: "",
    SelectManufacturer: null,
    MarkupPercent: null,
    custommarkupid: 0,
    CertCl: "",
    CertClValue: "",
    CertClPercent: "",
    DefaultMarkupOld: 0,
    GIA: false,
    AGS: false,
    EGL: false,
    IGI: false,
    Currency: null,
  });
  const category = [
    {
      label: "GIA",
      value: "GIA",
    },
    {
      label: "AGS",
      value: "AGS",
    },
    {
      label: "IGI",
      value: "IGI",
    },
    {
      label: "EGL",
      value: "EGL",
    },
  ];
  const [Custom, setCustom] = useState(null);
  const [customersPageNo, setCustomersPageNo] = useState(1);
  const [customersPageSize, setCustomersPageSize] = useState(10);
  const [markedUpDealersList, setMarkedUpDealersList] = useState([]);
  const [totalMakerCount, setMarkerTotalCount] = useState(0);
  const [customMarkUpsList, setCustomMarkUpsList] = useState([]);
  const [totalCustomMarkUpCount, setCustomMarkUpTotalCount] = useState(0);

  const [validations, setValidations] = useState({
    MarkupPercentVal: "",
    SelectManufacturerVal: "",
  });
  const [validations1, setValidations1] = useState({
    CertClVal: "",
    CertClValueVal: "",
    CertClPercentVal: "",
  });
  const [defaultMarkupValidation, setDefaultMarkupValidation] = useState({
    DefaultMarkupVal: "",
  });
  const [selectCurrencyDropdowenList, setSelectCurrencyDropdowenList] =
    useState([]);
  const [getAllCostPriceAndSizeLoading, setGetAllCostPriceAndSizeLoading] =
    useState(false);
  const loginDetials = useSelector((state) => state.loginReducer);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol =
    currency?.substring(currency.lastIndexOf("-") + 1);

  const [showAllComponent, setShowAllComponent] = useState(true);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetMineMarkupLoadData(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
      handleGetSelectCurrencyDropdowen();
    }
  }, []);

  const handleGetSelectCurrencyDropdowen = () => {
    try {
      AdminManageCurrencyService.GetSelectCurrencyDropdowen()
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSelectCurrencyDropdowenList(responseData);
            } else {
              setSelectCurrencyDropdowenList([]);
            }
          } else {
            setSelectCurrencyDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleDefaultMarkupValidation = () => {
    const { DefaultMarkup } = MinedDiamondData;
    const defaultMarkupValidation = {
      DefaultMarkupVal: "",
    };
    let isValid = true;

    if (!DefaultMarkup) {
      isValid = false;
      defaultMarkupValidation.DefaultMarkupVal = traslate(
        "Please Select Default Markup"
      );
    } else if (DefaultMarkup.includes("-")) {
      isValid = false;
      defaultMarkupValidation.DefaultMarkupVal = traslate(
        "Negative Values is not Accepted"
      );
    } else {
      defaultMarkupValidation.DefaultMarkupVal = "";
    }
    setDefaultMarkupValidation(defaultMarkupValidation);
    return isValid;
  };

  const handleValidation = () => {
    const { SelectManufacturer, MarkupPercent } = MinedDiamondData;

    const validations = {
      MarkupPercentVal: "",
      SelectManufacturerVal: "",
    };

    let isValid = true;

    if (!SelectManufacturer) {
      isValid = false;
      validations.SelectManufacturerVal = traslate(
        "Please Select Manufacturer"
      );
    } else {
      validations.SelectManufacturerVal = "";
    }

    if (!MarkupPercent) {
      isValid = false;
      validations.MarkupPercentVal = traslate("Please select markup");
    } else if (MarkupPercent.includes("-")) {
      isValid = false;
      validations.MarkupPercentVal = traslate(
        "Negative Values is not Accepted"
      );
    } else {
      validations.MarkupPercentVal = "";
    }

    setValidations(validations);
    return isValid;
  };

  const handleValidation1 = () => {
    const { CertCl, CertClValue, CertClPercent } = MinedDiamondData;

    const validations1 = {
      CertClVal: "",
      CertClValueVal: "",
      CertClPercentVal: "",
    };

    let isValid = true;

    if (!CertCl) {
      isValid = false;
      validations1.CertClVal = traslate("Please Enter Min Range");
    } else if (CertCl.length > 10) {
      isValid = false;
      validations1.CertClVal = traslate(
        "Min Range should be less than 10 digit"
      );
    } else if (CertCl.includes("-")) {
      isValid = false;
      validations1.CertClVal = traslate("Negative Values is not Accepted");
    } else {
      validations1.CertClVal = "";
    }

    if (!CertClValue) {
      isValid = false;
      validations1.CertClValueVal = traslate("Please Enter Max Range");
    } else if (CertClValue.length > 10) {
      isValid = false;
      validations1.CertClValueVal = traslate(
        "Max Range should be less than 10 digit"
      );
    } else if (CertClValue.includes("-")) {
      isValid = false;
      validations1.CertClValueVal = traslate("Negative Values is not Accepted");
    } else if (
      Number(MinedDiamondData.CertClValue) < Number(MinedDiamondData.CertCl)
    ) {
      isValid = false;
      validations1.CertClValueVal = traslate(
        "Max Price should be greater than Min Price"
      );
    } else {
      validations1.CertClValueVal = "";
    }

    if (!CertClPercent) {
      isValid = false;
      validations1.CertClPercentVal = traslate("Please Enter Markup");
    } else if (CertClPercent.includes("-")) {
      isValid = false;
      validations1.CertClPercentVal = traslate(
        "Negative Values is not Accepted"
      );
    } else {
      validations1.CertClPercentVal = "";
    }

    setValidations1(validations1);
    return isValid;
  };

  const handleOnChange = (e, isSelect, name) => {
    //
    setIsButtonDisable(false);
    if (isSelect === "select") {
      setMinedDiamondData({
        ...MinedDiamondData,
        [name]: e,
      });

      if (name === "Currency") {
        setMinedDiamondData({
          ...MinedDiamondData,
          [name]: e,
        });
      }
      if (name == "CustomMethod") {
        setCustom(e);
        handleGetCustomerMineMarkupLoadData(DealerId, e);
        
      }

      if (e == "Custom" || e == "Price" || e == "Size") {
        setPage(e);
        setCostPriceAndSize({});
        handleGetAllCostPriceAndSize(DealerId, e);
        setCustomersPageNo(1);
        setCustomersPageSize(10);
        if (e == "Price" || e == "Size") {
          setMinedDiamondData((prevState) => ({
            ...prevState,
            CertCl: "",
            CertClValue: "",
            CertClPercent: "",
          }));
        }
      }

      if (name === "SelectManufacturer") {
        setValidations((prevdata) => ({
          ...prevdata,
          SelectManufacturerVal: "",
        }));
      }
      if (e !== "Custom") {
        setDefaultMarkupValidation({
          DefaultMarkupVal: "",
        });
      }
    } else {
      if (e.target.name == "DefaultMarkup") {
        setMinedDiamondData({
          ...MinedDiamondData,
          [e.target.name]: e.target.value,
        });
      }
    
      else {
        var regExp = /[a-zA-Z]/g;

        if (e.target.name == "CertCl") {
          if (regExp.test(e.target.value)) {
            e.preventDefault();
          } else {
            setMinedDiamondData({
              ...MinedDiamondData,
              [e.target.name]: e.target.value,
            });
          }
        }

        if (e.target.name == "CertClValue") {
          if (regExp.test(e.target.value)) {
            e.preventDefault();
          } else {
            setMinedDiamondData({
              ...MinedDiamondData,
              [e.target.name]: e.target.value,
            });
          }
        }

        if (e.target.name == "CertClPercent") {
          if (regExp.test(e.target.value)) {
            e.preventDefault();
          } else {
            setMinedDiamondData({
              ...MinedDiamondData,
              [e.target.name]: e.target.value,
            });
          }
        }

        if (e.target.name == "MarkupPercent") {
          if (regExp.test(e.target.value)) {
            e.preventDefault();
          } else {
            setMinedDiamondData({
              ...MinedDiamondData,
              [e.target.name]: e.target.value,
            });
          }
        }
      }

      if (e.target.name === "MarkupPercent") {
        setValidations((prevdata) => ({
          ...prevdata,
          MarkupPercentVal: "",
        }));
      }
      if (e.target.name == "CertCl") {
        setValidations1((prevdata) => ({
          ...prevdata,
          CertClVal: "",
        }));
      }
      if (e.target.name == "CertClValue") {
        setValidations1((prevdata) => ({
          ...prevdata,
          CertClValueVal: "",
        }));
      }
      if (e.target.name == "CertClPercent") {
        setValidations1((prevdata) => ({
          ...prevdata,
          CertClPercentVal: "",
        }));
      }
    }
  };
  const handleOnChangeRadio = (event, name) => {
    let tempObj = {
      GIA: false,
      AGS: false,
      EGL: false,
      IGI: false,
    };
    tempObj[name] = event.target.checked;
    setMinedDiamondData({
      ...MinedDiamondData,
      ...tempObj,
    });
  };


  const handleTableOnchange = (e) => {
    const { name, value } = e.target;
    const tempcostPriceAndSize = costPriceAndSize;

    tempcostPriceAndSize[name] = value;
    setCostPriceAndSize({ ...tempcostPriceAndSize });
    setIsButtonDisable(false);
  };

  

  const handleGetMineMarkupLoadData = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      PendantBuilderService.GetRingBuilderMinedDiamondMarkupLoadData(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setLoading(false);

              setPage(
                responseData.markUpMethod == null
                  ? "Price"
                  : responseData.markUpMethod
              );
              setmanufactureList(responseData.ddlManufacturerList);
              setMinedDiamondData((prevdata) => ({
                ...prevdata,
                Method:
                  responseData.markUpMethod == null
                    ? "Price"
                    : responseData.markUpMethod,
                CustomMethod: responseData.customMethod.toString(),
                DefaultMarkup: responseData.currentMarkup,
                Currency: Number(responseData.drp_Currency),
                DefaultMarkupOld: responseData.currentMarkup,
              }));
              handleGetMakerMineMarkupLoadData(id);
              handleGetCustomerMineMarkupLoadData(
                id,
                responseData.customMethod
              );
              if (
                responseData.markUpMethod == "Price" ||
                responseData.markUpMethod == "Size"
              ) {
                handleGetAllCostPriceAndSize(id, responseData.markUpMethod);
              }
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetMakerMineMarkupLoadData = (id) => {
    let inputData = {
      dealerID: id,
    };
    setMakerLoading(true);
    try {
      PendantBuilderService.GetMakerRingBuilderMinedDiamondMarkupLoadData(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setMakerLoading(false);
              setMarkedUpDealersList(responseData.getMakeromMarkUpsLst);

              setMarkerTotalCount(responseData.makertotalRecord);
              setShowAllComponent(false);
            }
          } else {
            setMakerLoading(false);
            setMarkedUpDealersList([]);
            setMarkerTotalCount(0);
            setShowAllComponent(false);
          }
        })
        .catch((error) => {
          setMakerLoading(false);
          setShowAllComponent(false);
          console.log(error);
        });
    } catch (error) {
      setMakerLoading(false);
      setShowAllComponent(false);
      console.log(error);
    }
  };

  const handleGetCustomerMineMarkupLoadData = (id, type) => {
    let inputData = {
      dealerID: id,
      custMethod: Number(type),
    };
    setCustomerLoading(true);
    try {
      PendantBuilderService.GetCustomerRingBuilderMinedDiamondMarkupLoadData(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setCustomerLoading(false);
              setCustomMarkUpsList(responseData.getCustomMarkUpsLst);
              setCustomMarkUpTotalCount(responseData.custtotalRecord);
              setShowAllComponent(false);
            }
          } else {
            setCustomerLoading(false);
            setCustomMarkUpsList([]);
            setCustomMarkUpTotalCount(0);
            setShowAllComponent(false);
          }
        })
        .catch((error) => {
          setCustomerLoading(false);
          setShowAllComponent(false);
          console.log(error);
        });
    } catch (error) {
      setCustomerLoading(false);
      setShowAllComponent(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handleCancel1 = () => {
    setVisible1(false);
  };

  const handleDeleteData = (row) => {
    setVisible(true);
    setDelMarkupData({
      dealermarkupid: row.dealermarkupid,
      dealerID: row.dealerid,
    });
  };

  const handleDeleteCustomerMarkupData = (row) => {
    setIsButtonDisable(false);
    setVisible1(true);
    setDelCutomerMarkupData({
      customMarkupid: row.custommarkupid,
      dealerID: DealerId,
    });
  };

 

  const handleAddDealerMarkupdata = () => {
    let isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: DealerId,
        ddlManufacturerId: MinedDiamondData.SelectManufacturer,
        dealerMarkup: MinedDiamondData.MarkupPercent,
      };
      PendantBuilderService.SaveRingBuilderMinedDiamondDealerMarkupdata(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let msg = responseData.returnMessage;

          if (message === "Success") {
            if (msg == "Markup Already Exists For Selected Manufacturer") {
              NotificationManager.success(
                "Markup Already Exists For Selected Manufacturer"
              );
            } else {
              NotificationManager.success(
                "Manufacturer Markup Saved Successfully"
              );
              handleGetMakerMineMarkupLoadData(DealerId);
            }
          } else {
            NotificationManager.error("Manufacturer Markup Not Saved");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setVisible(false);
    setDelMarkupData({});
    setMinedDiamondData({
      ...MinedDiamondData,
      SelectManufacturer: "",
      MarkupPercent: "",
    });
  };

  // Add SaveMIneCustomMarkupdata

  const handleSaveCustomMarkupdata = () => {
    let isValid1 = handleValidation1();
    if (!isValid1) {
      return false;
    }
    try {
      let inputData = {
        customMarkupID: MinedDiamondData.custommarkupid
          ? MinedDiamondData.custommarkupid
          : 0,
        dealerId: DealerId,
        minrange: MinedDiamondData.CertCl,
        maxrange: MinedDiamondData.CertClValue,
        markup: MinedDiamondData.CertClPercent,
        certificate: "",
        customMethod: MinedDiamondData.CustomMethod,
        chkGIA: isedit
          ? checkbox.includes("GIA")
            ? true
            : false
          : MinedDiamondData.GIA,
        chkAGS: isedit
          ? checkbox.includes("AGS")
            ? true
            : false
          : MinedDiamondData.AGS,
        chkEGL: isedit
          ? checkbox.includes("EGL")
            ? true
            : false
          : MinedDiamondData.EGL,
        chkIGI: isedit
          ? checkbox.includes("IGI")
            ? true
            : false
          : MinedDiamondData.IGI,
        hdnSaveeditval: "",
      };

      PendantBuilderService.SaveRingBuilderMinedDiamondCustomMarkupdata(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let msg = responseData.returnMessage;
          if (message === "Success") {
            if (
              msg ==
              "- Invalid Range. There is already a markup within given range."
            ) {
              NotificationManager.success(
                "- Invalid Range. There is already a markup within given range."
              );
            } else {
              NotificationManager.success("Custom markup saved successfully.");
              handleGetCustomerMineMarkupLoadData(
                DealerId,
                MinedDiamondData.CustomMethod
              );
            }
          } else {
            NotificationManager.error("Custom Markup Not Saved.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setIsEdit(false);
    setVisible(false);
    setDelMarkupData({});
    setMinedDiamondData({
      ...MinedDiamondData,
      custommarkupid: 0,
      CertCl: "",
      CertClValue: "",
      CertClPercent: "",
      GIA: false,
      AGS: false,
      EGL: false,
      IGI: false,
    });
  };

  // Delete Markup By Vendor Data

  const DeleteDealerMarkupData = () => {
    try {
      let inputData = {
        dealermarkupid: delMarkupData.dealermarkupid,
        dealerID: delMarkupData.dealerID,
      };

      PendantBuilderService.RemoveRingBuilderMinedDiamondDealerMarkupdata(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(
              "Manufacturer Markup Removed Successfully."
            );
            handleGetMakerMineMarkupLoadData(DealerId);
          } else {
            NotificationManager.error("Manufacturer Markup Not Removed.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setVisible(false);
    setDelMarkupData({});
  };

  // delete customer Markup data
  const DeleteCustomerMarkupData = () => {
    try {
      let inputData = {
        customMarkupid: DelCutomerMarkupData.customMarkupid,
        dealerID: DelCutomerMarkupData.dealerID,
      };

      PendantBuilderService.RemoveRingBuilderMinedDiamondCustomMarkupdata(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success("Custom Markup Removed Successfully");
            handleGetCustomerMineMarkupLoadData(
              DealerId,
              MinedDiamondData.CustomMethod
            );
            setMinedDiamondData({
              ...MinedDiamondData,
              custommarkupid: 0,
              CertCl: "",
              CertClValue: "",
              CertClPercent: "",
              GIA: false,
              AGS: false,
              EGL: false,
              IGI: false,
            });
            setCheckBox("");
            setIsButtonDisable(false);
          } else {
            NotificationManager.error("Custom Markup Not Removed.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setVisible1(false);
  };

  // Price and Size API Start
  const handleGetAllCostPriceAndSize = (id, type) => {
    try {
      let inputData = {
        dealerId: id,
        type: type,
      };
      setGetAllCostPriceAndSizeLoading(true);
      PendantBuilderService.GetAllMinedDiamondCostPriceAndSize(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setCostPriceAndSize(responseData);
            setGetAllCostPriceAndSizeLoading(false);
            setShowAllComponent(false);
          } else {
            setGetAllCostPriceAndSizeLoading(false);
            setShowAllComponent(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setGetAllCostPriceAndSizeLoading(false);
          setShowAllComponent(false);
        });
    } catch (error) {
      console.log(error);
      setGetAllCostPriceAndSizeLoading(false);
      setShowAllComponent(false);
    }
  };
  const handleUpdateAllCostPriceAndSize = () => {
    try {
      let inputData = costPriceAndSize;
      inputData.markUpMethod = page;
      inputData.dealerId = DealerId;
      inputData.CurrencyValue = MinedDiamondData.Currency + "";
      setSubmitLoading(true);
      PendantBuilderService.UpdateAllMinedDiamondCostPriceAndSize(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Updated Successfully.");
            const currencyType = selectCurrencyDropdowenList.filter(
              (item) => item.currencyId == MinedDiamondData.Currency
            )?.[0]?.currencyType;
            dispatch(setCurrency(currencyType));
            setIsButtonDisable(true);
            handleGetAllCostPriceAndSize(DealerId, page);
          } else {
            NotificationManager.error("Record Not Updated.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  
  const UpdateMIneCustomMarkupdata = () => {
    let isValid = handleDefaultMarkupValidation();

    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        currentMarkup: MinedDiamondData.DefaultMarkupOld
          ? MinedDiamondData.DefaultMarkupOld
          : "",
        dealerId: DealerId,
        defaltmarkup: MinedDiamondData.DefaultMarkup,
        custommarkupmethod: MinedDiamondData.CustomMethod,
        Markupmethod: MinedDiamondData.Method,
        CurrencyValue: MinedDiamondData.Currency + "",
      };
      setSubmitLoading(true);
      PendantBuilderService.UpdateAllRingBuilderMinedDiamondMarkupdata(
        inputData,
        props.type
      )
        .then((response) => {
          let message = response.data.message;
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Default Markup Updated Successfully.");
            const currencyType = selectCurrencyDropdowenList.filter(
              (item) => item.currencyId == MinedDiamondData.Currency
            )?.[0]?.currencyType;
            dispatch(setCurrency(currencyType));
            handleGetMineMarkupLoadData(DealerId);
            setIsButtonDisable(true);
          } else {
            NotificationManager.error("Record Not Updated.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };

  const handleUpdateButton = () => {
    if (page == "Custom") {
      UpdateMIneCustomMarkupdata();
    } else {
      handleUpdateAllCostPriceAndSize();
    }
  };

  

  const onKeyPressMinusEvent = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/).test(keyValue))
      e.preventDefault();
    return;
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
  }
  

  return (
    <div className="form__fields markup__section">
      <Spin spinning={showAllComponent} style={{ height: "100vh" }}>
        {!showAllComponent && (
          <>
            <div className="row ">
              <div className="col-md-12">
                <div className="heading__block">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {props.type
                      ? traslate("Lab Grown Markup")
                      : traslate("Mined Diamond Markup")}
                    
                  </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="markup__heading">
                      <h4> {traslate("General Settings")} </h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input__block">
                      <label> {traslate("Method")} </label>
                      <Select
                        className="border__grey"
                        showSearch
                        placeholder="Select Method"
                        name="Method"
                        value={
                          MinedDiamondData.Method
                            ? MinedDiamondData.Method
                            : null
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleOnChange(e, "select", "Method")}
                      >
                        {MinedDiamondData.Method == "Size" ? (
                          <>
                            <Option value="Size"> Size </Option>
                            <Option value="Custom"> Custom </Option>
                            <Option value="Price"> Price </Option>
                          </>
                        ) : MinedDiamondData.Method == "Price" ? (
                          <>
                            <Option value="Price"> Price </Option>
                            <Option value="Custom"> Custom </Option>
                            <Option value="Size"> Size </Option>
                          </>
                        ) : (
                          <>
                            <Option value="Custom"> Custom </Option>
                            <Option value="Price"> Price </Option>
                            <Option value="Size"> Size </Option>
                          </>
                        )}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-4 mt-0">
                    <div className="input__block mb-0">
                      <label> {traslate("Select Currency")} </label>
                      <Select
                        className="border__grey"
                        showSearch
                        placeholder="Choose Any"
                        name="Currency"
                        value={
                          MinedDiamondData.Currency
                            ? MinedDiamondData.Currency
                            : null
                        }
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "Currency")
                        }
                      >
                        <Option value="">
                          {" "}
                          {traslate("Select Currency")}{" "}
                        </Option>
                        {selectCurrencyDropdowenList.map((item) => {
                          return (
                            <React.Fragment>
                              <Option value={item.currencyId}>
                                {item.currencyType}
                              </Option>
                            </React.Fragment>
                          );
                        })}
                      </Select>
                    </div>
                  </div>

                  {page == "Custom" ? (
                    
                      <div className="col-md-4">
                        <div className="input__block">
                          <label> {traslate("Custom Method")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Custom Method"
                            name="CustomMethod"
                            value={
                              MinedDiamondData.CustomMethod
                                ? MinedDiamondData.CustomMethod
                                : []
                            }
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleOnChange(e, "select", "CustomMethod")
                            }
                          >
                            <Option value="0"> {traslate("By Price")} </Option>
                            <Option value="1"> {traslate("By Size")} </Option>
                          </Select>
                        </div>
                      </div>
                    
                  ) : null}
                </div>

                <div className="row ">
                  {page == "Custom" ? (
                    <div className="col-md-12">
                      <div className="heading__block markup__heading">
                        <h4 className="workarea__heading mt-0">
                          {" "}
                          {traslate("Default Markup")}{" "}
                        </h4>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-4">
                    <div className="input__block d-flex align-items-center mb-0 position-relative">
                      {page == "Custom" ? (
                        <React.Fragment>
                          <input
                            type="number"
                            name="DefaultMarkup"
                            step="1"
                            min="0"
                            max="100"
                            value={
                              MinedDiamondData.DefaultMarkup
                                ? MinedDiamondData.DefaultMarkup
                                : null
                            }
                            onChange={(e) => handleOnChange(e, "DefaultMarkup")}
                            onKeyPress={onKeyPressMinusEvent}
                          />
                          <label
                            style={{ top: "8px", right: "35px" }}
                            className="percent markup__symbol"
                          >
                            %
                          </label>
                        </React.Fragment>
                      ) : null}
                    </div>
                    {defaultMarkupValidation.DefaultMarkupVal && (
                      <p className="error-color-red">
                        {defaultMarkupValidation.DefaultMarkupVal}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label className="mt-2">
                        {" "}
                        {traslate(
                          "In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%"
                        )}{" "}
                      </label>
                    </div>
                  </div>
                </div>

                {page == "Custom" ? (
                  
                    <div className="row">
                      <div className="col-md-12">
                        <div className="heading__block markup__heading">
                          <h4 class="workarea__heading mt-0">
                            {" "}
                            {traslate("Markup By Vendor")}{" "}
                          </h4>
                        </div>

                        <div className="row markupinputrow">
                          <div className="col-md-4 col-6">
                            <div className="input__block">
                              <Select
                                className="border__grey"
                                showSearch
                                placeholder="Select Manufacturer"
                                name="SelectManufacturer"
                                value={
                                  MinedDiamondData.SelectManufacturer
                                    ? MinedDiamondData.SelectManufacturer
                                    : []
                                }
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "SelectManufacturer"
                                  )
                                }
                              >
                                <Option>Select Manufacturer</Option>
                                {manufactureList &&
                                  manufactureList.map((item, i) => {
                                    return (
                                      <Option value={item.dealerid}>
                                        {item.dealertitle +
                                          " " +
                                          "(" +
                                          item.dealerid +
                                          ")"}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              <div>
                                {validations.SelectManufacturerVal && (
                                  <p className="error-color-red">
                                    {validations.SelectManufacturerVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 col-6">
                            <div className="input__block position-relative">
                              <input
                                type="text"
                                name="MarkupPercent"
                                value={
                                  MinedDiamondData.MarkupPercent
                                    ? MinedDiamondData.MarkupPercent
                                    : ""
                                }
                                onChange={(e) => {
                                  handleOnChange(e, "MarkupPercent");
                                }}
                                onKeyPress={onKeyPressMinusEvent}
                              />
                              <label class="percent">%</label>
                              <div>
                                {validations.MarkupPercentVal && (
                                  <p className="error-color-red mt-2">
                                    {validations.MarkupPercentVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 col-6">
                            <div className="input__block">
                              <button
                                className="primary-btn ml-2"
                                onClick={handleAddDealerMarkupdata}
                              >
                                {traslate("Add")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 divForm">
                        <Spin spinning={makerLoading} size="large">
                          <div className="table__scroll mb-3">
                            <Table
                              columns={[
                                {
                                  title: traslate("ID"),
                                  dataIndex: "manufacturerid",
                                  sorter: (a, b) =>
                                    a.manufacturerid - b.manufacturerid,
                                },
                                {
                                  title: traslate("Dealers"),
                                  dataIndex: "manufacturercompany",
                                  sorter: (a, b) =>
                                    a.manufacturercompany.localeCompare(
                                      b.manufacturercompany
                                    ),
                                },
                                {
                                  title: traslate("Markup"),
                                  dataIndex: "markup",
                                  sorter: (a, b) => a.markup - b.markup,
                                  render: (index, row) => {
                                    return <span>{row.markup}%</span>;
                                  },
                                },

                                {
                                  title: "",
                                  fixed: "right",
                                  width: 100,
                                  render: (index, row) => (
                                    <div className="action__btns">
                                      <div
                                        className="image__block"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                      >
                                        <img
                                          src={Delete_iconbl}
                                          alt=""
                                          onClick={() => {
                                            handleDeleteData(row);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ),
                                },
                              ]}
                              dataSource={markedUpDealersList}
                              pagination={{
                                showSizeChanger: true,
                                responsive: true,
                              }}
                              scroll={{ y: 800 }}
                            />
                          </div>
                        </Spin>
                      </div>
                    </div>
                  
                ) : null}
              </div>

              <div className="col-md-6">
                {page == "Custom" ? (
                  
                    <div className="row">
                      <div className="col-md-12">
                        <div className="heading__block markup__heading">
                          <h4 class="workarea__heading mt-0">
                            {MinedDiamondData.CustomMethod === "0" ? (
                              <>
                                {" "}
                                {traslate(
                                  "Markup By PriceRange With Certs"
                                )}{" "}
                              </>
                            ) : (
                              <>
                                {" "}
                                {traslate(
                                  "Markup By SizeRange With Certs"
                                )}{" "}
                              </>
                            )}
                          </h4>
                        </div>

                        <div className="row markupinputrow">
                          <div className="col-md-3 col-6 mobile__bottomspace">
                            <div className="input__block d-flex align-items-center mb-0 position-relative">
                              {MinedDiamondData.CustomMethod == "0" ? (
                                <label className="markup__symbol">
                                  {currencySymbol}{" "}
                                </label>
                              ) : null}
                              <input
                                type="text"
                                name="CertCl"
                                placeholder={
                                  MinedDiamondData.CustomMethod == "0"
                                    ? "Min Price"
                                    : "Min Size"
                                }
                                value={
                                  MinedDiamondData.CertCl
                                    ? MinedDiamondData.CertCl
                                    : ""
                                }
                                onChange={(e) => {
                                  handleOnChange(e, "CertCl");
                                }}
                                onKeyPress={onKeyPressMinusEvent}
                              />
                              {MinedDiamondData.CustomMethod == "1" ? (
                                <label className="ml-2">
                                  {" "}
                                  {traslate("Ct.")}{" "}
                                </label>
                              ) : null}
                            </div>
                            <div>
                              {validations1.CertClVal && (
                                <p className="error-color-red">
                                  {validations1.CertClVal}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3 col-6 mobile__bottomspace">
                            <div className="input__block d-flex align-items-center mb-0 position-relative">
                              {MinedDiamondData.CustomMethod == "0" ? (
                                <label className="markup__symbol">
                                  {currencySymbol}{" "}
                                </label>
                              ) : null}

                              <input
                                type="text"
                                name="CertClValue"
                                placeholder={
                                  MinedDiamondData.CustomMethod == "0"
                                    ? "Max Price"
                                    : "Max Size"
                                }
                                value={
                                  MinedDiamondData.CertClValue
                                    ? MinedDiamondData.CertClValue
                                    : ""
                                }
                                onChange={(e) => {
                                  handleOnChange(e, "CertClValue");
                                }}
                                onKeyPress={onKeyPressMinusEvent}
                              />
                              {MinedDiamondData.CustomMethod == "1" ? (
                                <label className="ml-2">
                                  {" "}
                                  {traslate("Ct.")}{" "}
                                </label>
                              ) : null}
                            </div>
                            <div>
                              {validations1.CertClValueVal && (
                                <p className="error-color-red">
                                  {validations1.CertClValueVal}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3 col-6 mobile__bottomspace">
                            <div className="input__block d-flex align-items-center mb-0 position-relative">
                              <input
                                type="text"
                                name="CertClPercent"
                                placeholder="MarkUP"
                                className="left__markup"
                                value={
                                  MinedDiamondData.CertClPercent
                                    ? MinedDiamondData.CertClPercent
                                    : ""
                                }
                                step="0.01"
                                min="0"
                                max="100"
                                onChange={(e) => {
                                  handleOnChange(e, "CertClPercent");
                                }}
                                onKeyPress={onKeyPressMinusEvent}
                              />
                              <label className="percent">%</label>
                            </div>
                            <div>
                              {validations1.CertClPercentVal && (
                                <p className="error-color-red">
                                  {validations1.CertClPercentVal}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-1 col-6 mobile__bottomspace">
                            <div className="input__block d-flex mb-0">
                              <button
                                className="primary-btn ml-2"
                                onClick={() => handleSaveCustomMarkupdata()}
                              >
                                {traslate("Add")}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="row mt-3 mb-3 markup__checkbkox">
                              {!isedit ? (
                                <Fragment>
                                  <div className="col-md-2 col-6 checkbox__block">
                                    <Checkbox
                                      type="checkbox"
                                      className="mr-1"
                                      id="MarkupGIA"
                                      name="GIA"
                                      value={MinedDiamondData.GIA}
                                      checked={MinedDiamondData.GIA}
                                      onChange={(e) =>
                                        handleOnChangeRadio(e, "GIA")
                                      }
                                    >
                                      <label htmlFor="MarkupGIA">
                                        {" "}
                                        {traslate("GIA")}{" "}
                                      </label>
                                    </Checkbox>
                                  </div>

                                  <div className="col-md-2 col-6 checkbox__block">
                                    <Checkbox
                                      type="checkbox"
                                      className="mr-1"
                                      id="MarkupAGS"
                                      name="AGS"
                                      value={MinedDiamondData.AGS}
                                      checked={MinedDiamondData.AGS}
                                      onChange={(e) =>
                                        handleOnChangeRadio(e, "AGS")
                                      }
                                    >
                                      <label htmlFor="MarkupAGS">
                                        {" "}
                                        {traslate("AGS")}{" "}
                                      </label>
                                    </Checkbox>
                                  </div>

                                  <div className="col-md-2 col-6 checkbox__block">
                                    <Checkbox
                                      className="mr-1"
                                      id="MarkupEGL"
                                      name="EGL"
                                      value={MinedDiamondData.EGL}
                                      checked={MinedDiamondData.EGL}
                                      onChange={(e) =>
                                        handleOnChangeRadio(e, "EGL")
                                      }
                                    >
                                      <label htmlFor="MarkupEGL">
                                        {" "}
                                        {traslate("EGL")}{" "}
                                      </label>
                                    </Checkbox>
                                  </div>

                                  <div className="col-md-2 col-6 checkbox__block">
                                    <Checkbox
                                      className="mr-1"
                                      id="MarkupIGI"
                                      name="IGI"
                                      value={MinedDiamondData.IGI}
                                      checked={MinedDiamondData.IGI}
                                      onChange={(e) =>
                                        handleOnChangeRadio(e, "IGI")
                                      }
                                    >
                                      <label htmlFor="MarkupIGI">
                                        {" "}
                                        {traslate("IGI")}{" "}
                                      </label>
                                    </Checkbox>
                                  </div>
                                </Fragment>
                              ) : (
                                <div className="d-flex">
                                  {category.map((item) => {
                                    return (
                                      <Fragment>
                                        <div
                                          className={
                                            item.value == "GIA"
                                              ? "radio__block"
                                              : "radio__block mx-5"
                                          }
                                        >
                                          <Checkbox
                                            key={item.value}
                                            onChange={handleCheckBoxChange}
                                            checked={item.value == checkbox}
                                            value={item.value}
                                            id={item.value}
                                          >
                                            <label htmlFor={item.value}>
                                              {" "}
                                              {traslate(item.label)}{" "}
                                            </label>
                                          </Checkbox>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 divForm">
                        <div className="table__scroll mb-3">
                          <Table
                            columns={[
                              {
                                title:
                                  MinedDiamondData.CustomMethod == "0"
                                    ? traslate("Min Price")
                                    : traslate("Min Size"),
                                dataIndex: "minrange",
                                render: (item, row) => {
                                  return (
                                    <span>
                                      {MinedDiamondData.CustomMethod == "0"
                                        ? currencySymbol + " " + row.minrange
                                        : row.minrange}
                                    </span>
                                  );
                                },
                                sorter: (a, b) => a.minrange - b.minrange,
                              },
                              {
                                title:
                                  MinedDiamondData.CustomMethod == "0"
                                    ? traslate("Max Price")
                                    : traslate("Max Size"),
                                dataIndex: "maxrange",
                                render: (item, row) => {
                                  return (
                                    <span>
                                      {MinedDiamondData.CustomMethod == "0"
                                        ? currencySymbol + " " + row.maxrange
                                        : row.maxrange}
                                    </span>
                                  );
                                },
                                sorter: (a, b) => a.maxrange - b.maxrange,
                              },
                              {
                                title: traslate("Certs"),
                                dataIndex: "certificate",
                                sorter: (a, b) =>
                                  a.certificate.localeCompare(b.certificate),
                              },
                              {
                                title: traslate("Markup"),
                                dataIndex: "markup",
                                sorter: (a, b) => a.markup - b.markup,
                                render: (index, row) => {
                                  return <span>{row.markup}%</span>;
                                },
                              },
                              {
                                title: "",
                                fixed: "right",
                                width: 100,
                                render: (index, row) => (
                                  <div className="action__btns">
                                    <div
                                      className="image__block"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }}
                                    >
                                      <img
                                        src={Delete_iconbl}
                                        alt=""
                                        onClick={() => {
                                          handleDeleteCustomerMarkupData(row);
                                        }}
                                      />
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                            dataSource={customMarkUpsList}
                            loading={customerLoading}
                            pagination={{
                              showSizeChanger: true,
                              responsive: true,
                            }}
                            onRow={(record, recordIndex) => ({
                              onClick: (event) => {
                                setIsEdit(true);
                                setMinedDiamondData({
                                  ...MinedDiamondData,
                                  custommarkupid: record.custommarkupid,
                                  CertCl: record.minrange,
                                  CertClValue: record.maxrange,
                                  CertClPercent: record.markup,
                                });
                                setCheckBox(record.certificate);
                              },
                            })}
                          />
                        </div>
                      </div>
                      <Modal
                        title=""
                        className="modalconsupld__section"
                        centered
                        visible={visible}
                        onOk={() => setVisible(false)}
                        onCancel={() => setVisible(false)}
                        width={400}
                        footer={[
                          <Button key="back" onClick={handleCancel}>
                            {traslate("Cancel")}
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            onClick={DeleteDealerMarkupData}
                          >
                            {traslate("Delete")}
                          </Button>,
                        ]}
                      >
                        <div className="col-lg-12">
                          <div className="modal__body">
                            <div className="col-lg-12">
                              <div className="form__fields border-0 p-0">
                                <div className="row">
                                  <div className="col-lg-12 mt-4 text-center">
                                    <p>
                                      {traslate(
                                        "Are You Sure You Want To Delete This Markup ?"
                                      )}
                                    </p>
                                    <h5>{traslate("THIS CAN NOT BE UNDO")}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        title=""
                        className="modalconsupld__section"
                        centered
                        visible={visible1}
                        onOk={() => setVisible1(false)}
                        onCancel={() => setVisible1(false)}
                        width={400}
                        footer={[
                          <Button key="back" onClick={handleCancel1}>
                            {traslate("Cancel")}
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            onClick={DeleteCustomerMarkupData}
                          >
                            {traslate("Delete")}
                          </Button>,
                        ]}
                      >
                        <div className="col-lg-12">
                          <div className="modal__body">
                            <div className="col-lg-12">
                              <div className="form__fields border-0 p-0">
                                <div className="row">
                                  <div className="col-lg-12 mt-4 text-center">
                                    <p>
                                      {traslate(
                                        "Are You Sure You Want To Delete This Markup ?"
                                      )}
                                    </p>
                                    <h5>{traslate("THIS CAN NOT BE UNDO")}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                
                ) : null}

                {/* By Price && By Size */}

                {page == "Price" || page == "Size" ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="heading__block markup__heading">
                        <h4 className="workarea__heading mt-0">
                          {page == "Price" ? (
                            <> {traslate("Markup By Price Range")} </>
                          ) : (
                            <> {traslate("Markup By Size Range")} </>
                          )}
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-12 divForm">
                      <Spin
                        spinning={getAllCostPriceAndSizeLoading}
                        size="large"
                      >
                        {page == "Price" ? (
                          <div className="table__scroll mb-3">
                            <Table
                              columns={[
                                {
                                  title: traslate("Min Price"),

                                  dataIndex: "min",
                                  render: (item, row) => {
                                    return (
                                      <div>
                                        {row.min === "50000.01" ? (
                                          <span>
                                            Above {currencySymbol} {row.min}
                                          </span>
                                        ) : (
                                          <span>
                                            {currencySymbol + " " + row.min}
                                          </span>
                                        )}
                                      </div>
                                    );
                                  },
                                },
                                {
                                  title: traslate("Max Price"),

                                  dataIndex: "max",
                                  render: (item, row) => {
                                    return (
                                      <div>
                                        <span>
                                          {row.max ? currencySymbol : ""}{" "}
                                          {row.max}
                                        </span>
                                      </div>
                                    );
                                  },
                                },
                                {
                                  title: traslate("Markup"),
                                  render: (item, row) => (
                                    <div className="markupinputrow">
                                      <div className="col-md-6">
                                        <div className="input__block d-flex align-items-center mb-0">
                                          <input
                                            type="text"
                                            min="0"
                                            onKeyPress={onKeyPressMinusEvent}
                                            value={
                                              costPriceAndSize[row.Markup]
                                            }
                                            onChange={handleTableOnchange}
                                            name={row.Markup}
                                            onPaste={preventPasteNegative}
                                          />

                                          <span className="percent"> %</span>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                },
                              ]}
                              dataSource={minMaxMarkupPrice}
                              pagination={{
                                showSizeChanger: true,
                                responsive: true,
                              }}
                            />
                          </div>
                        ) : null}

                        {page == "Size" ? (
                          <div className="table__scroll mb-3">
                            <Table
                              columns={[
                                {
                                  title: traslate("Min Size"),
                                  dataIndex: "min",

                                  render: (item, row) => {
                                    return (
                                      <div>
                                        <span>
                                          {row.min == "6.00ct"
                                            ? "6.00ct and larger"
                                            : row.min}
                                        </span>
                                      </div>
                                    );
                                  },
                                },
                                {
                                  title: traslate("Max Size"),
                                  dataIndex: "max",
                                },
                                {
                                  title: traslate("Markup"),
                                  render: (item, row) => (
                                    <div className="markupinputrow">
                                      <div className="col-md-6">
                                        <div className="input__block d-flex align-items-center mb-0">
                                          <input
                                            type="text"
                                            min="0"
                                            onKeyPress={onKeyPressMinusEvent}
                                            onPaste={preventPasteNegative}
                                            value={
                                              costPriceAndSize[row.Markup]
                                            }
                                            onChange={handleTableOnchange}
                                            name={row.Markup}
                                          />

                                          <span className="percent"> %</span>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                },
                              ]}
                              dataSource={minMaxSize}
                              pagination={{
                                showSizeChanger: true,
                                responsive: true,
                              }}
                            />
                          </div>
                        ) : null}
                      </Spin>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block">
                <Button
                  className={isButtonDisable ? "disabled-btn " : "primary-btn"}
                  disabled={isButtonDisable}
                  onClick={handleUpdateButton}
                  loading={submitLoading}
                >
                  {" "}
                  {traslate("Update Markup")}{" "}
                </Button>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default PendantBuilderDiamondMarkup;
