import { http } from "../helper/http-common";
class AdminManageCurrencyService {

    GetSelectCurrencyDropdowen() {
        return http.post(
            "/SelectCourency/SelectCurrencyDropdowen"
        );
    }
    GetSelectlanguageForDelar(inputData) {
        return http.post(
            "/SelectCourency/SelectlanguageForDelar",
            inputData
        );
    }
    UpdateCurrency(inputData) {
        return http.post(
            "/SelectCourency/UpdateCurrency",
            inputData
        );
    }
}

export default new AdminManageCurrencyService();
