import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Spin } from "antd";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import home_icns from "../../../assets/images/home_icns.jpg";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import MarketingUploadVendor from "../../../component/admin/settings/MarketingUploadVendor";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ManageUploadServices from "../../../services/manageupload.service";
const Marketing = () => {
  const [visible, setVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteVisibleById, setDeleteVisibleById] = useState(false);
  const [fileDeleteData, setFileDeleteData] = useState({
    FolderName: "",
    Type: "",
    Id: "",
  });
  const [fileDeleteDataById, setFileDeleteDataById] = useState({
    FolderNameById: "",
    TypeById: "",
    IdById: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [manageUploadDetails, setManageUploadDetails] = useState([]);
  const [manageUploadDetailsById, setManageUploadDetailsById] = useState([]);
  const [manageUserDetails, setManageUserDetails] = useState({
    folderNameUserDetails: "",
    folderFileCountUserDetails: "",
    folderFileId: "",
  });
  const [dealerID, setDealerId] = useState(0);

  const [manageUploadFolder, setManageUploadFolder] = useState({
    foldernameValue: "",
  });
  const [validation, setValidations] = useState({
    foldernameVal: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [recordId, setRecord] = useState({});
  const [headername, setHeaderName] = useState("");
  const [disableFileData, setDisableFileData] = useState(true);
  const [disableFileDataById, setDisableFileDataById] = useState(false);
  const [disableUploadMedia, setDisableUploadMedia] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetUserDetailsData(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setManageUploadFolder({
        ...manageUploadFolder,
        [name]: e,
      });
    } else {
      setManageUploadFolder({
        ...manageUploadFolder,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name == "foldernameValue") {
      setValidations((prevdata) => ({
        ...prevdata,
        foldernameVal: "",
      }));
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setManageUploadFolder({
      foldernameValue: "",
    });

    setValidations({
      foldernameVal: "",
    });
  };

  const handleValidation = () => {
    const { foldernameValue } = manageUploadFolder;

    const validation = {
      foldernameVal: "",
    };
    let isValid = true;

    if (!foldernameValue.trim()) {
      isValid = false;
      validation.foldernameVal = traslate("Enter Folder Name");
    } else {
      validation.foldernameVal = "";
    }
    setValidations(validation);
    return isValid;
  };

  const handleCancel1 = () => {
    setVisible(true);
  };

  const handleCreateAddFile = () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    }
    let inputData = {
      dealerID: dealerID,
      folderName: manageUploadFolder.foldernameValue,
      parentFolderId: manageUserDetails.folderFileId
        ? manageUserDetails.folderFileId
        : 0,
    };
    setSubmitLoading(true);
    try {
      ManageUploadServices.AddFolder(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData == "Already exists.") {
              NotificationManager.error(traslate("Folder Has Been Already Created."));
              setManageUploadFolder({ foldernameValue: "" });
              setSubmitLoading(false);
            } else {
              if (message === "Success") {
                NotificationManager.success(
                  traslate("Folder Has Been Successfully Add.")
                );
                handleGetFileData(dealerID, manageUserDetails.folderFileId);
                setManageUploadFolder({ foldernameValue: "" });
                setVisible(false);
                setSubmitLoading(false);
              } else {
                NotificationManager.error(traslate("Folder Has Not Been Created."));
                setVisible(false);
                setManageUploadFolder({ foldernameValue: "" });
                setSubmitLoading(false);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setVisible(false);
          setManageUploadFolder({ foldernameValue: "" });
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setVisible(false);
      setManageUploadFolder({ foldernameValue: "" });
      setSubmitLoading(false);
    }
  };

  const handleGetFileData = (id, dealerFolerId) => {
    let inputData = {
      dealerId: id.toString(),
      dealerFolerId: dealerFolerId,
    };
    setLoading(true);
    try {
      ManageUploadServices.LoadMarketingData_Vendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setManageUploadDetails(responseData);
            setManageUserDetails((prevState) => ({
              ...prevState,
              folderFileCountUserDetails: responseData.length,
            }));
            setLoading(false);
          } else {
            setManageUploadDetails([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteData = (row) => {
    setDeleteVisible(true);
    setFileDeleteData({
      FolderName: row.folderName,
      Type: row.type,
      Id: row.id,
    });
  };

  const handleDeleteData1 = (row) => {
    setDeleteVisibleById(true);
    setFileDeleteDataById({
      FolderNameById: row.folderName,
      TypeById: row.type,
      IdById: row.id,
    });
  };

  const handleGetUserDetailsData = (id) => {
    let inputData = {
      dealerId: id.toString(),
      dealerFolerId: "0",
    };
    setLoadingFolder(true);
    try {
      ManageUploadServices.BindFolders_Vendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.bindFolders_VendorList.length > 0) {
              setManageUserDetails((prevState) => ({
                ...prevState,
                folderNameUserDetails:
                  responseData.bindFolders_VendorList[0].folderName,
                folderFileCountUserDetails:
                  responseData.bindFolders_VendorList[0].folderFileCount,
                folderFileId: responseData.bindFolders_VendorList[0].id,
              }));
            }

            handleGetFileData(id, responseData.bindFolders_VendorList[0].id);
            setLoadingFolder(false);
          } else {
            setManageUserDetails({});
            setLoadingFolder(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingFolder(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingFolder(false);
    }
  };

  const columns = [
    {
      title: traslate("Name"),
      dataIndex: "folderName",
      key: "folderName",
      render: (item, row) => {
        return (
          <div>
            <i class="fa fa-folder mr-2" aria-hidden="true"></i>
            <span>{row.folderName}</span>
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dealerID, record);
          },
        };
      },
    },
    {
      title: traslate("Kind"),
      dataIndex: "type",
      key: "type",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dealerID, record);
          },
        };
      },
    },
    {
      title: traslate("Modified"),
      dataIndex: "lastWriteTime",
      key: "lastWriteTime",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dealerID, record);
          },
        };
      },
    },
    {
      title: traslate("Size"),
      dataIndex: "sizeinMB",
      key: "sizeinMB",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleShowDataFileByIdNew(dealerID, record);
          },
        };
      },
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      render: (item, row) => {
        return (
          <React.Fragment>
            <Link
              to=""
              onClick={() => {
                handleGetDownloadFolderZip(row);
              }}
            >
              {traslate("Download")}
            </Link>
          </React.Fragment>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Delete_iconbl}
                alt=""
                onClick={() => {
                  handleDeleteData(row);
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleGetDownloadFolderZip = (row) => {
    let inputData = {
      folderName: row.folderName.toString(),
      id: row.id.toString(),
      dealerFolderName: row.dealerFolderName.toString(),
      dealerID: dealerID.toString(),
    };
    setLoading(true);
    try {
      ManageUploadServices.DownloadMarketingZipFiler(inputData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var filename = row.folderName + ".zip";
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns1 = [
    {
      title: traslate("Name"),
      dataIndex: "folderName",
      key: "folderName",
      render: (item, row) => {
        return (
          <div>
            <i class="fa fa-folder mr-2" aria-hidden="true"></i>
            <span className="linkText">{row.folderName}</span>
          </div>
        );
      },
    },
    {
      title: traslate("Kind"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: traslate("Modified"),
      dataIndex: "lastWriteTime",
      key: "lastWriteTime",
    },
    {
      title: traslate("Size"),
      dataIndex: "sizeinMB",
      key: "sizeinMB",
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      render: (item, row) => {
        return (
          <React.Fragment>
            <Link
              to=""
              onClick={() => {
                handleGetDownloadImage(row);
              }}
            >
              {traslate("Download")}
            </Link>
          </React.Fragment>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Delete_iconbl}
                alt=""
                onClick={() => {
                  handleDeleteData1(row);
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleGetDownloadImage = (row) => {
    window.open(row.path);
  };

  const handleShowDataFileByIdNew = (dealerID, record) => {
    setRecord(record);
    setHeaderName(record.folderName);
    setDisableFileData(false);
    setDisableFileDataById(true);
    try {
      let inputData = {
        dealerFolerId: record.id.toString(),
        dealerId: dealerID.toString(),
      };
      setLoading(true);
      ManageUploadServices.LoadMarketingData_Vendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setManageUploadDetailsById(responseData);
              setLoading(false);
            } else {
              setManageUploadDetailsById([]);
              setLoading(false);
            }
          } else {
            setManageUploadDetailsById([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleShowDataFileByIdNewDelete = (dealerID, id) => {
    setDisableFileData(false);
    setDisableFileDataById(true);
    try {
      let inputData = {
        dealerFolerId: id.toString(),
        dealerId: dealerID.toString(),
      };
      setLoading(true);
      ManageUploadServices.LoadMarketingData_Vendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setManageUploadDetailsById(responseData);
              setLoading(false);
            } else {
              setManageUploadDetailsById([]);
              setLoading(false);
            }
          } else {
            setManageUploadDetailsById([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const DeleteFileDelaerData = () => {
    try {
      let inputData = {
        fileNamePath: "",
        folderName: fileDeleteData.FolderName,
        id: fileDeleteData.Id,
        type: fileDeleteData.Type,
        dealerId: "",
        dealerFolerId: "",
      };

      ManageUploadServices.RemoveFileandFolder(inputData)
        .then((response) => {
          var message = response.data.message;

          if (message === "Success") {
            handleGetFileData(dealerID, manageUserDetails.folderFileId);
            NotificationManager.success(traslate("Folder Removed Successfully."));
          } else {
            NotificationManager.error(traslate("Folder Not Removed."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setDeleteVisible(false);
    setFileDeleteData({});
  };

  //  delete api for ImageId

  const DeleteFileDelaerDataById = () => {
    try {
      let inputData = {
        fileNamePath: "",
        folderName: fileDeleteDataById.FolderNameById,
        id: fileDeleteDataById.IdById,
        type: fileDeleteDataById.TypeById,
        dealerId: dealerID.toString(),
        dealerFolerId: recordId.id.toString(),
      };

      ManageUploadServices.RemoveFileandFolder(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            handleShowDataFileByIdNewDelete(dealerID, recordId.id);
            NotificationManager.success(traslate("File Removed Successfully."));
          } else {
            NotificationManager.error(traslate("File Not Removed."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setDeleteVisibleById(false);
    setFileDeleteDataById({});
  };

  const handleCancel3 = () => {
    setDisableFileData(false);
    setDisableFileDataById(false);
    setDisableUploadMedia(true);
  };
  const handleCancel4 = () => {
    handleGetFileData(dealerID, manageUserDetails.folderFileId);
    setDisableFileData(true);
    setDisableFileDataById(false);
    setDisableUploadMedia(false);
    setHeaderName("");
  };
  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("Marketing")}
                  </h4>
                </div>
                <div className="col-lg-6">
                  <div className="addedit__btndiv">
                    <label className="mr-2" onClick={handleCancel4}>
                      <span>{manageUserDetails.folderNameUserDetails}</span>
                      <span className="font__bold ml-1">
                        {manageUserDetails.folderFileCountUserDetails}
                      </span>
                    </label>

                    <button className="primary-btn" onClick={handleCancel3}>
                      {" "}
                      {traslate("Upload Media")}
                    </button>

                    <button
                      className="primary-btn"
                      onClick={() => setVisible(true)}
                    >
                      {" "}
                      {traslate("Add Folder")}
                    </button>
                  </div>
                </div>

                <div className="col-lg-12">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item linkText">
                        <img src={home_icns} alt="" onClick={handleCancel4} />
                      </li>
                      {manageUserDetails.folderNameUserDetails ? (
                        <li
                          class="breadcrumb-item linkText"
                          onClick={handleCancel4}
                        >
                          {manageUserDetails.folderNameUserDetails
                            ? manageUserDetails.folderNameUserDetails
                            : null}{" "}
                        </li>
                      ) : null}
                      {headername ? (
                        <li class="breadcrumb-item linkText">
                          {headername ? headername : null}
                        </li>
                      ) : null}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="tradeshow_tbldiv table__scroll">
                {disableFileData ? (
                  <Spin spinning={loadingFolder}>
                    <Spin spinning={loading}>
                      <Table
                        dataSource={manageUploadDetails}
                        columns={columns}
                        scroll={{ y: 800 }}
                        pagination={{
                          pageSizeOptions: [10, 20, 50, 100],
                          responsive: true,
                          showSizeChanger: true,
                        }}
                      />
                    </Spin>
                  </Spin>
                ) : null}

                {disableFileDataById ? (
                  <Spin spinning={loading}>
                    <Table
                      dataSource={manageUploadDetailsById}
                      columns={columns1}
                      scroll={{ y: 800 }}
                    />
                  </Spin>
                ) : null}

                {disableUploadMedia ? (
                  <MarketingUploadVendor userData={manageUploadDetails} />
                ) : null}
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={traslate("Marketing")}
                className="modal__tradeshow"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={handleCancel}
                width={400}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    className="primary-btn col-md-4"
                    loading={submitLoading}
                    onClick={handleCreateAddFile}
                  >
                    {traslate("Add Folder")}
                  </Button>,
                ]}
              >
                <div className="col-md-12">
                  <div className="form__fields border-0 p-0">
                    <div className="input__block">
                      <label>{traslate("Folder Name")}</label>
                      <input
                        type="text"
                        placeholder="Enter Folder Name"
                        className={validation.foldernameVal && "border__red"}
                        name="foldernameValue"
                        value={manageUploadFolder.foldernameValue}
                        onChange={handleOnChange}
                        autoComplete="off"
                      />
                      {validation.foldernameVal && (
                        <p className="error-color-red">
                          {validation.foldernameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        className="modalconsupld__section"
        centered
        visible={deleteVisible}
        onOk={() => setDeleteVisible(false)}
        onCancel={() => setDeleteVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={() => setDeleteVisible(false)}>
            {traslate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={DeleteFileDelaerData}>
            {traslate("Delete")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 mt-4 text-center">
                    <p>
                      {traslate(
                        "Are You Sure You Want To Delete This Folder ?"
                      )}
                    </p>
                    <h5>{traslate("THIS CAN NOT BE UNDO")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Delete For FolderId */}

      <Modal
        title=""
        className="modalconsupld__section"
        centered
        visible={deleteVisibleById}
        onOk={() => setDeleteVisibleById(false)}
        onCancel={() => setDeleteVisibleById(false)}
        width={400}
        footer={[
          <Button key="back" onClick={() => setDeleteVisibleById(false)}>
            {traslate("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={DeleteFileDelaerDataById}
          >
            {traslate("Delete")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 mt-4 text-center">
                    <p>
                      {traslate("Are You Sure You Want To Delete This File ?")}
                    </p>
                    <h5>{traslate("THIS CAN NOT BE UNDO")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Marketing;
