import React, { Fragment } from "react";
import Network from "./Network";

export const VendorManagerPendingRequests = () => {
  return (
    <Fragment>
      <Network type="Pending Requests" />
    </Fragment>
  );
};

export default VendorManagerPendingRequests;
