import { http } from "../helper/http-common";
class AdminSettingsDataService {
  GetEmailTemplatesById(inputData) {
    return http.post("/Setting/GetEmailTemplatesById", inputData);
  }
  SaveEmailTemplate(inputData) {
    return http.post("/Setting/SaveEmailTemplate", inputData);
  }
  GetEmailDealerStatus(inputData) {
    return http.post("/Setting/GetEmailDealerStatus", inputData);
  }

  // Data maps
  InsertTierData(inputData) {
    return http.post("/Setting/InsertTierData", inputData);
  }
  GetProductIdDataList() {
    return http.post("/Setting/GetProductIdDataList");
  }
  GetCategoryDataList() {
    return http.post("/Setting/GetCategoryDataList");
  }
  GetAttributesDataList() {
    return http.post("/Setting/GetAttributesDataList");
  }
  GetGemStonesDataList() {
    return http.post("/Setting/GetGemStonesDataList");
  }
  GetWatchInfoList() {
    return http.post("/Setting/GetWatchInfoList");
  }
  SaveDataMaps(inputData) {
    return http.post("/Setting/SaveDataMaps", inputData);
  }
  GetShowsDetailsList(inputData) {
    return http.post("/Setting/GetShowsDetailsList", inputData);
  }
  GetShowsDetailsByID(inputData) {
    return http.post("/Setting/GetShowsDetailsByID", inputData);
  }
  DeleteShowsDetailsByID(inputData) {
    return http.post("/Setting/DeleteShowsDetailsByID", inputData);
  }
  SaveShowsDetailsByID(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.post("/Setting/SaveShowsDetailsByID", inputData, { headers });
  }
  GetApiAccessTokenList(inputData) {
    return http.post("/Setting/GetApiAccessTokenList", inputData);
  }
  SaveApiAccessToken(inputData) {
    return http.post("/Setting/SaveApiAccessToken", inputData);
  }
  /*Colors */
  GetMetalColors(inputData) {
    return http.post("/Setting/GetMetalColors", inputData);
  }
  SaveMetalColor(inputData) {
    return http.post("/Setting/SaveMetalColor", inputData);
  }
  DeleteMetalColors(inputData) {
    return http.post("/Setting/DeleteMetalColors", inputData);
  }
}

export default new AdminSettingsDataService();
