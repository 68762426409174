import React, { useState, useEffect } from "react";
import { Switch, Table, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const DiamondRapNetPermission = () => {
  const [diamondRapNetPermissionData, setDiamondRapNetPermission] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);

  useEffect(() => {
    GetDiamondImportRightsList(pageNo, pageSize);
  }, []);

  const GetDiamondImportRightsList = (pageNo, pageSize) => {
    let inputData = {
      pageNumber: pageNo,
      pageSize: pageSize,
    };
    setLoading(true);
    AdminToolsDataService.GetDiamondImportRightsList(inputData)
      .then((response) => {
        setLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData.thirdPartyDiamond;
        var totalCount = response.data.responseData.totalRecord;
        if (message === "Success") {
          setDiamondRapNetPermission(responseData);
          setTotalCount(totalCount);
        } else {
          setDiamondRapNetPermission([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnChange = (row, e, name) => {
    const temp = diamondRapNetPermissionData.map((x) => {
      if (x.dealerID == row.dealerID) {
        return { ...x, enabled: e };
      } else return x;
    });
    setDiamondRapNetPermission(temp);
    var item = temp.filter((item) => item.dealerID == row.dealerID)[0];
    handleSubmit(item);
  };

  const handleSubmit = (item) => {
    let inputData = {
      dealerID: Number(item.dealerID),
      enabled: item.enabled,
    };
    setLoading(true);
    AdminToolsDataService.SaveDiamondImportRights(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(traslate("Data Updated Successfully."));
          GetDiamondImportRightsList(pageNo, pageSize);
        } else {
          NotificationManager.success(traslate("Data Not Updated."));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    GetDiamondImportRightsList(page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    GetDiamondImportRightsList(current, size);
  }

  return (
    <React.Fragment>
      <div className="diamond_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-md-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Enable 3rd Party Diamond Imports")}
              </h4>
            </div>
            <div className="col-md-12 mt-4">
              <div className="dealer_tbldiv table__scroll">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "dealerID",
                        title: traslate("Dealer ID"),
                        width: 120,
                      },
                      {
                        dataIndex: "dealerName",
                        title: traslate("Dealer Name"),
                      },
                      {
                        dataIndex: "dealerCompany",
                        title: traslate("Dealer Company"),
                      },
                      {
                        dataIndex: "enabled",
                        title: traslate("Enabled"),
                        width: 100,
                        Key: "completed",
                        render: (item, row) => {
                          return (
                            <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              checked={row.enabled}
                              onChange={(e) =>
                                handleOnChange(row, e, "enabled")
                              }
                            />
                          );
                        },
                      },
                    ]}
                    onChange={(pagination, filters, sorters) => {
                      document
                        .getElementsByClassName("content__area")[0]
                        .scroll(0, 0);
                    }}
                    pagination={{
                      total: totalCount,
                      pageNo: pageNo,
                      pageSize: pageSize,
                      pageSizeOptions: [10, 20, 50, 100],
                      responsive: true,
                      showSizeChanger: true,
                      onChange: handlePageNoChange,
                      onShowSizeChange: handlePageSizeChange,
                    }}
                    dataSource={diamondRapNetPermissionData}
                    scroll={{ y: 800 }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondRapNetPermission;
