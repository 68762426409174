import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Select, Button, Spin } from "antd";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";
import { setSelectSideMenu } from "../../actions/headerMenu/headerMenu";
import Config from "./../../helper/config";

const AddCollection = () => {
  const initialAddCollection = {
    collectionID: 0,
    collectionName: "",
    brandID: 0,
    collectionDescription: "",
    collectionDisplayOrder: "",
    bridal: false,
    fashion: true,
    watches: false,
    giftware: false,
    file: null,
    showsImagePath: "",
  };
  const initialValidation = {
    collectionNameVal: "",
    brandNameVal: "",
    NavigationVal: "",
    fileVal: "",
  };
  const [AddCollectionData, setAddCollectionData] =
    useState(initialAddCollection);
  const [isEdit, setIsEdit] = useState(false);
  const [validations, setValidations] = useState(initialValidation);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [dealerId, setDealerId] = useState();
  const [dealerCompany, setdealerCompany] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);

  const location = useLocation();
  const dispatch = useDispatch();
  const [vendorStatusID, setVendorStatusID] = useState(0);

  const WhiteLabeling = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        setVendorStatusID(
          loginDetials?.loginDetials?.responseData?.vendorStatusID || 0
        );
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setdealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
          handleGetBrandListId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  const { Option } = Select;

  const handleInputOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAddCollectionData({
        ...AddCollectionData,
        [name]: e,
      });
    } else {
      setAddCollectionData({
        ...AddCollectionData,
        [e.target.name]: e.target.value,
        [e.target.id]: e.target.checked,
      });
      if (e.target.name === "collectionName") {
        setValidations((prevdata) => ({
          ...prevdata,
          collectionNameVal: "",
        }));
      }
    }
  };

  const handleGetBrandListId = (value) => {
    let inputData = {
      dealerID: value,
    };
    setLoading(true);
    CatalogJewelryDataService.GetBrandList(inputData)
      .then((response) => {
        setLoading(false);
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            const tempAddCollectionData = AddCollectionData;
            tempAddCollectionData.brandID = responseData[0].designerID + "";
            setBrandList(responseData);
            if (location.state.key) {
              handleGetCollectionStatusById(location.state.key);
            }
          }
        } else {
          setBrandList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValidation = () => {
    const {
      collectionName,
      brandID,
      collectionDescription,
      collectionDisplayOrder,
      bridal,
      fashion,
      watches,
      giftware,
    } = AddCollectionData;
    const validations = {};
    let isValid = true;

    if (!collectionName.trim()) {
      isValid = false;
      validations.collectionNameVal = traslate("Collection name is compulsory");
    }

    if (
      bridal == false &&
      fashion == false &&
      watches == false &&
      giftware == false
    ) {
      isValid = false;
      validations.NavigationVal = traslate(
        "Need to select atleast one category"
      );
    }
    if (!isValid) {
      setValidations(validations);
    }
    return isValid;
  };

  const handleGetCollectionStatusById = (ID) => {
    setIsEdit(true);

    let inputData = {
      collectionID: ID,
    };
    setLoading(true);
    CatalogJewelryDataService.GetCollectionStatusById(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            let tempString = responseData[0].collectionCategory.toLowerCase();
            let tempObj = {
              collectionID: responseData[0].collectionID,
              collectionName: responseData[0].collection,
              brandID: responseData[0].brandID + "",
              collectionDescription: responseData[0].description,
              collectionDisplayOrder:
                responseData[0].collectionDisplayOrder == 0
                  ? ""
                  : responseData[0].collectionDisplayOrder,
              bridal: tempString.includes("bridal") ? true : false,
              fashion: tempString.includes("fashion") ? true : false,
              watches: tempString.includes("watches") ? true : false,
              giftware: tempString.includes("giftware") ? true : false,
              showsImagePath: responseData[0].collectionImage,
            };
            setAddCollectionData((prevState) => ({
              ...prevState,
              ...tempObj,
            }));
          }
        } else {
          setAddCollectionData(initialAddCollection);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let navigate = useNavigate();
  function handleRedirect() {
    navigate("/manageCollection", { state: { key: "HomeOnly" } });
  }

  const handleSuccessRedirect = () => {
    navigate("/manageCollection", { state: { key: "All" } });
    dispatch(setSelectSideMenu(["manageCollection"]));
  };

  const handleOk = () => {
    const {
      collectionID,
      collectionName,
      brandID,
      collectionDescription,
      collectionDisplayOrder,
      bridal,
      fashion,
      watches,
      giftware,
      file,
      showsImagePath,
    } = AddCollectionData;
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    let str = "";
    if (bridal == true) {
      str += "bridal" + "|";
    }
    if (fashion == true) {
      str += "fashion" + "|";
    }
    if (watches == true) {
      str += "watches" + "|";
    }
    if (giftware == true) {
      str += "giftware" + "|";
    }
    const formData = new FormData();
    formData.append("collectionID", collectionID || 0);
    formData.append("dealerID", dealerId);
    formData.append("dealerCompany", dealerCompany);
    formData.append("collectionName", collectionName);
    formData.append("collectionDescription", collectionDescription);
    formData.append("brandID", Number(brandID));
    formData.append("collectionCategory", str);
    formData.append("collectionDisplayOrder", Number(collectionDisplayOrder));
    if (file) {
      formData.append("collectionImage", file || null);
    } else {
      formData.append("collectionImage", null);
    }
    if (file) {
      formData.append("collectionImage_Name", showsImagePath);
    } else {
      formData.append(
        "collectionImage_Name",
        showsImagePath.replace(`${Config.BlobURL}gemfind2staging/CollectionImages/`, "")
      );
    }
    setSaveLoading(true);
    CatalogJewelryDataService.SaveCollection(formData)
      .then((response) => {
        let message = response.data.message;
        let returnMessage = response.data.responseData.returnMessage;
        if (message === "Success") {
          if (isEdit) {
            if (returnMessage == "Collection name already exist") {
              NotificationManager.error("Collection name already exists");
            } else {
              NotificationManager.success("Collection Edited Successfully.");
              handleSuccessRedirect();
              setAddCollectionData(initialAddCollection);
              setValidations(initialValidation);
            }
          } else {
            if (returnMessage == "Collection name already exist") {
              NotificationManager.error("Collection name already exists");
              setSaveLoading(false);
            } else {
              NotificationManager.success("Collection Created Successfully.");
              handleSuccessRedirect();
              setAddCollectionData(initialAddCollection);
              setValidations(initialValidation);
            }
          }
        } else {
          NotificationManager.error("Collection Data Not Added.");
          handleRedirect();
        }
      })
      .catch((error) => {
        console.log(error);
        setSaveLoading(false);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const fileUpload = async (e) => {
    if (e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        if (img.width <= 500 && img.height <= 500) {
          setAddCollectionData({
            ...AddCollectionData,
            ["file"]: e.target.files[0],
          });
          setValidations({
            ...validations,
            ["file"]: null,
          });
        } else {
          setValidations({
            ...validations,
            ["file"]: "Please Select Correct Image Size.",
          });
        }
      };
    } else {
      setValidations({
        ...validations,
        ["file"]: "Please Select Only Images.",
      });
      setAddCollectionData({
        ...AddCollectionData,
        ["file"]: null,
      });
    }
  };

  const handleCancelImage = () => {
    setAddCollectionData((prevState) => ({
      ...prevState,
      ["showsImagePath"]: null,
    }));

    setAddCollectionData((prevState) => ({
      ...prevState,
      ["file"]: null,
    }));
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <div className="addcollection__section">
          {vendorStatusID == 3 ? (
            <>{traslate("Please Contact Gemfind to get Access.")}</>
          ) : (
            <div className="form__fields">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="workarea__heading mt-0 mb-0">
                      {isEdit
                        ? traslate("Edit Collection")
                        : traslate("Create A Collection")}
                    </h4>
                    <p className="mt-3">
                      {traslate("Items you add for your store's personal use. Collections can be added to ")}
                      {WhiteLabeling?.whiteLebelData?.[0]?.name || "JewelCloud"}
                      {traslate(" Vendor as an Extension of their official profile or your own House Brand")}
                      .{" "}
                    </p>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="input__block mt-4">
                          <div className="divimage_block w-100">
                            {AddCollectionData.showsImagePath ||
                              AddCollectionData.file ? (
                              <div className="img__showdiv addCollection-img-sp">
                                {isEdit ? (
                                  AddCollectionData.file ? (
                                    <img
                                      src={URL.createObjectURL(
                                        AddCollectionData.file
                                      )}
                                    />
                                  ) : (
                                    <img
                                      src={AddCollectionData.showsImagePath}
                                    />
                                  )
                                ) : AddCollectionData.file ? (
                                  <img
                                    src={URL.createObjectURL(
                                      AddCollectionData.file
                                    )}
                                  />
                                ) : null}
                                <span onClick={handleCancelImage}>x</span>
                              </div>
                            ) : (
                              <div>
                                <label
                                  htmlFor="file-upload"
                                  class="primary-btn choose_file_upload"
                                >
                                  {traslate("Choose Image")}
                                </label>
                                <input
                                  id="file-upload"
                                  type="file"
                                  onChange={(e) => fileUpload(e)}
                                />
                                {validations.fileVal && (
                                  <p className="error-color-red">
                                    {validations.fileVal}
                                  </p>
                                )}
                                <span class="mb-4">
                                  {" "}
                                  {traslate("No File Choosen")}{" "}
                                </span>
                                <span class="">
                                  500 X 500 {traslate("Pixels")}{" "}
                                </span>
                                <span class="">
                                  {" "}
                                  {traslate(".JPG | .JPEG | .PNG Format")}
                                </span>
                              </div>
                            )}
                            {validations["file"] ? (
                              <span className="error-color-red">
                                {validations["file"]}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-6 mt-4">
                            <div className="input__block">
                              <label>
                                {traslate("Collection Name")}
                                <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Collection Name"
                                name="collectionName"
                                value={AddCollectionData.collectionName}
                                onChange={handleInputOnChange}
                                className={
                                  validations.collectionNameVal && "border__red"
                                }
                              />
                              <div>
                                {validations.collectionNameVal && (
                                  <p className="error-color-red">
                                    {validations.collectionNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mt-4">
                            <div className="input__block">
                              <label>{traslate("Brand Name")}</label>
                              <Select
                                name="brandID"
                                placeholder="Select Brand Name"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleInputOnChange(e, "select", "brandID")
                                }
                                className="border__grey"
                                value={AddCollectionData.brandID}
                              >
                                {brandList.map((item) => {
                                  return (
                                    <Option
                                      key={item.designerID}
                                      value={item.designerID + ""}
                                    >
                                      {item.designerName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Collection Description")}{" "}
                              </label>
                              <textarea
                                name="collectionDescription"
                                value={AddCollectionData.collectionDescription}
                                onChange={handleInputOnChange}
                                placeholder="Enter Collection Description"
                                className="textarea__height"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Collection Display Order")}{" "}
                              </label>
                              <input
                                type="text"
                                onKeyPress={onKeyPressEvent}
                                placeholder="Enter Collection Order"
                                name="collectionDisplayOrder"
                                value={AddCollectionData.collectionDisplayOrder}
                                onChange={handleInputOnChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {traslate("Navigation Category")} <span>*</span>
                              </label>
                              <div className="chkbox__div">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="bridal"
                                    checked={AddCollectionData.bridal}
                                    onChange={handleInputOnChange}
                                    id="bridal"
                                  />
                                  <label htmlFor="bridal">
                                    {" "}
                                    {traslate("Bridal")}{" "}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    name="fashion"
                                    checked={AddCollectionData.fashion}
                                    onChange={handleInputOnChange}
                                    id="fashion"
                                  />
                                  <label htmlFor="fashion">
                                    {" "}
                                    {traslate("Fashion")}{" "}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    name="watches"
                                    checked={AddCollectionData.watches}
                                    onChange={handleInputOnChange}
                                    id="watches"
                                  />
                                  <label htmlFor="watches">
                                    {" "}
                                    {traslate("Watches")}{" "}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    name="giftware"
                                    checked={AddCollectionData.giftware}
                                    onChange={handleInputOnChange}
                                    id="giftware"
                                  />
                                  <label htmlFor="giftware">
                                    {" "}
                                    {traslate("Giftware")}{" "}
                                  </label>
                                </div>
                              </div>
                              <div>
                                {validations.NavigationVal && (
                                  <p className="error-color-red">
                                    {validations.NavigationVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <Button
                              loading={saveLoading}
                              className="primary-btn"
                              onClick={handleOk}
                            >
                              {" "}
                              {isEdit
                                ? traslate("Save Collection")
                                : traslate("Create Collection")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default AddCollection;
