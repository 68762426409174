import {
  LOGIN_SUBMIT,
  SET_LOGIN_TOKEN,
  SET_IMPERSONATE_DATA,
  SET_NEWLOGIN_DATA,
  SET_ADMINLOGIN_DATA,
  RESET_STATE,
  SET_CURRENCY,
  SET_CURRENCY_SIGN,
  SET_WHITE_LABELING_DATA
} from "./types";
import LoginDataService from "../../services/login.service";

export const loginSubmit = (inputDate) => async (dispatch) => {
  try {
    const res = await LoginDataService.login(inputDate);
    dispatch({
      type: LOGIN_SUBMIT,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setLoginToken = (token) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOGIN_TOKEN,
      payload: token,
    });
  } catch (err) {}
};
export const setImpersonateData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IMPERSONATE_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setNewLoginData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_NEWLOGIN_DATA,
      payload: data,
    });
  } catch (err) {}
};

export const setAdminLoginData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ADMINLOGIN_DATA,
      payload: data,
    });
  } catch (err) {}
};
export const setLoginResetData = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_STATE,
    });
  } catch (err) {}
};
export const setCurrency = (data) => async (dispatch) => {
  try{
    dispatch({
      type: SET_CURRENCY,
      payload: data,
    })
  }catch (err) {}
}
export const setCurrencySign = (data) => async (dispatch) => {
  try{
    dispatch({
      type: SET_CURRENCY_SIGN,
      payload: data,
    })
  }catch (err) {}
}

// White Labeling

export const setWhiteLabelingData = (data) => async (dispatch) => {
  try{
    dispatch({
      type: SET_WHITE_LABELING_DATA,
      payload: data,
    })
  }catch (err) {}
}
