import { httpApps } from "../helper/http-common";

class PendantBuilderService {
    GetsPendantBuilderOptiondetails(inputdata) {
        return httpApps.post("/PendantBuilder/GetsPendantBuilderOptiondetails", inputdata);
    }
    UpdatePendantBuilderOptiondetails(inputdata) {
        return httpApps.post("/PendantBuilder/UpdatePendantBuilderOptiondetails", inputdata);
    }
    GetsPendantBuildernavigationetails(inputdata) {
        return httpApps.post("/PendantBuilder/GetsPendantBuildernavigationetails", inputdata);
    }
    UpdatePendantBuildernavigationdetails(inputdata) {
        return httpApps.post("/PendantBuilder/UpdatePendantBuildernavigationdetails", inputdata);
    }
    BindDataPendantBuilderVendor(inputdata){
        return httpApps.post("/PendantBuilder/BindDataPendantBuilderVendor", inputdata);
    }
    PendantBuilder_BindDealerList(inputdata){
        return httpApps.post("/PendantBuilder/PendantBuilder_BindDealerList", inputdata);
    }
    UpdateList_Click_PendantBuilder(inputdata){
        return httpApps.post("/PendantBuilder/UpdateList_Click_PendantBuilder", inputdata);
    }

    // Mined Diamond Markup and Lab grow Markup


    GetRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/GetPendantBuilderLabGrownMarkupLoadData",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/GetPendantBuilderMinedDiamondMarkupLoadData",
            inputData
          );
        }
      }

      GetMakerRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/GetMakerPendantBuilderLabGrownMarkupLoadData",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/GetMakerPendantBuilderMinedDiamondMarkupLoadData",
            inputData
          );
        }
      }

      GetCustomerRingBuilderMinedDiamondMarkupLoadData(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/GetCustomerPendantBuilderLabGrownMarkupLoadData",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/GetCustomerPendantBuilderMinedDiamondMarkupLoadData",
            inputData
          );
        }
      }

      SaveRingBuilderMinedDiamondDealerMarkupdata(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/SavePendantBuilderLabGrownDealerMarkupdata",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/SavePendantBuilderMinedDiamondDealerMarkupdata",
            inputData
          );
        }
      }
    
      SaveRingBuilderMinedDiamondCustomMarkupdata(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/SavePendantBuilderLabGrownCustomMarkupdata",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/SavePendantBuilderMinedDiamondCustomMarkupdata",
            inputData
          );
        }
      }

      RemoveRingBuilderMinedDiamondDealerMarkupdata(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/RemovePendantBuilderLabGrownDealerMarkupdata",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/RemovePendantBuilderMinedDiamondDealerMarkupdata",
            inputData
          );
        }
      }

      RemoveRingBuilderMinedDiamondCustomMarkupdata(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/RemovePendantBuilderLabGrownCustomMarkupdata",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/RemovePendantBuilderMinedDiamondCustomMarkupdata",
            inputData
          );
        }
      }

      GetAllMinedDiamondCostPriceAndSize(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/GetAllPendantBuilderLabGrownCostPriceAndSize",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/GetAllPendantBuilderMinedDiamondCostPriceAndSize",
            inputData
          );
        }
      }

      UpdateAllMinedDiamondCostPriceAndSize(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/UpdateAllPendantBuilderLabGrownCostPriceAndSize",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/UpdateAllPendantBuilderMinedDiamondCostPriceAndSize",
            inputData
          );
        }
      }

      UpdateAllRingBuilderMinedDiamondMarkupdata(inputData, type) {
        if (type === "LAB") {
          return httpApps.post(
            "/PendantBuilder/UpdateAllPendantBuilderLabGrownMarkupdata",
            inputData
          );
        } else {
          return httpApps.post(
            "/PendantBuilder/UpdateAllPendantBuilderMinedDiamondMarkupdata",
            inputData
          );
        }
      }

      // seeting markup 

      GetPendantBuilderMarkupLoadData(inputdata) {
        return httpApps.post(
          "/PendantBuilder/GetPendantBuilderMarkupLoadData",
          inputdata
        );
      }
      
      UpdateAllPendantBuilderSettingMarkup(inputdata) {
        return httpApps.post(
          "/PendantBuilder/UpdateAllPendantBuilderSettingMarkup",
          inputdata
        );
      }

      GetPendingRequests = (inputData) => {
        return httpApps.post("/PendantBuilder/PendentGetPendingRequests", inputData);
      };
      
      PendantBuilderPendingToApprove = (inputData) => {
        return httpApps.post("/PendantBuilder/PendantBuilderPendingToApprove", inputData);
      };
      PendantBuilderPendingToDisApprove = (inputData) => {
        return httpApps.post(
          "/PendantBuilder/PendantBuilderPendingToDisApprove",
          inputData
        );
      };
      
      PendantBuilderBindDataCommVendor = (inputData) => {
        return httpApps.post(
          "/PendantBuilder/PendantBuilderBindDataCommVendor",
          inputData
        );
      };
      
      PendantBuilderShowDataToRightNew = (inputData) => {
        return httpApps.post(
          "/PendantBuilder/PendantBuilderShowDataToRightNew",
          inputData
        );
      };
      PendantBuilder_BindDataCommVendor = (inputData) => {
        return httpApps.post(
          "/PendantBuilder/PendantBuilder_BindDataCommVendor",
          inputData
        );
      };
      
      
    

}

export default new PendantBuilderService();
