import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Modal, Spin } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import traslate from "../../../i18n/translate";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import DiamondLinksEmailTemplateService from "../../../services/diamondLinks-emailTemplate.service";

export const PendantBuilderEmailTemplate = (props) => {
  const [typeName, setTypeName] = useState(null);
  const [visible, setVisible] = useState(false);
  /*API Binding Starts*/

  //Dropdown List Starts
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerId, setDealerId] = useState(0);
  const [relaod, setReload] = useState(false);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  //Dropdown List Ends

  const handleEmailUpdate = () => {
    handleUpdateDiamondLinkEmailTemplateDetails(
      loginDetials.responseData.dealerId
    );
  };

  const refreshPage = () => {
    setVisible(false);
    setReload(!relaod);
  };

  const handleGetDiamondEmailTemplatesDetails = (name, id) => {
    try {
      let inputData = {
        dealerID: dealerId || id,
        typeName: name,
      };
      setContent("");
      setVisible(true);
      setTypeName(name);
      setLoading(true);
      DiamondLinksEmailTemplateService.GetDiamondEmailTemplatesDetails(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setContent(responseData[0].emailContent);
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadImg", file);
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleUpdateDiamondLinkEmailTemplateDetails = (id) => {
    try {
      let inputData = {
        dealerID: dealerId || id,
        typeName: typeName,
        emailContent: content,
      };
      DiamondLinksEmailTemplateService.UpdateDiamondLinkEmailTemplateDetails(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(
              "Email template has been saved successfully."
            );
          } else {
            NotificationManager.error("Email template has not been updated.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*API Binding Ends*/

  return (
    <div className="form__fields">
      <div className="row">
        <div className="col-md-12">
          <h4 className="workarea__heading mt-0">
            {" "}
            {""} {traslate("Pendant Builder Emails")} {""}{" "}
          </h4>
        </div>
      </div>

      <div className="emailTemplate">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1>
                {" "}
                {""} {traslate("Diamond Request More Info")} {""}{" "}
              </h1>
              <ul>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBDiamondToRetailerInquires"
                      )
                    }
                  >
                    {""} {traslate("Send Mail to Retailer")} {""}{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBDiamondToSenderInquires"
                      )
                    }
                  >
                    {""} {traslate("Send Mail to Sender")} {""}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1>
                {" "}
                {""} {traslate("Request More Info")} {""}{" "}
              </h1>
              <ul>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToRetailerInquires"
                      )
                    }
                  >
                    {""} {traslate("Send Mail To Retailer")} {""}{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToSenderInquires"
                      )
                    }
                  >
                    {""} {traslate("Send Mail To Sender")} {""}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1>
                {" "}
                {""} {traslate("Email To Friend")} {""}{" "}
              </h1>
              <ul>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails("PBToFriendEmailF")
                    }
                  >
                    {""} {traslate("Send Mail To Friend")} {""}{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToRetailerEmailF"
                      )
                    }
                  >
                    {""} {traslate("Send Mail To Retailer")} {""}{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails("PBToSenderEmailF")
                    }
                  >
                    {""} {traslate("Send Mail To Sender")} {""}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1>
                {" "}
                {""} {traslate("Drop A Hint")} {""}{" "}
              </h1>
              <ul>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToRecipientDropAHint"
                      )
                    }
                  >
                    {""} {traslate("Send Mail to Recipient")} {""}{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToRetailerDropAHint"
                      )
                    }
                  >
                    {""} {traslate("Send Mail to Retailer")} {""}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1>
                {" "}
                {""} {traslate("Schedule View")} {""}{" "}
              </h1>
              <ul>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToScheduleViewDiamondDetail"
                      )
                    }
                  >
                    {""} {traslate("Diamond Detail")} {""}{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "PBToScheduleViewComplete"
                      )
                    }
                  >
                    {""} {traslate("Complete Pendant Setting")} {""}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <h1 className="request-diamond-sp mb-3">
              {" "}
              {""} {traslate("Request A Diamond Email")} {""}{" "}
            </h1>
            <div className="emailDetailBlock">
              <ul>
                <li>
                  <h2>
                    {" "}
                    {""} {traslate("Request A Diamond")} {""}{" "}
                  </h2>
                  <ul>
                    <li>
                      <Link
                        to=""
                        onClick={() =>
                          handleGetDiamondEmailTemplatesDetails(
                            "PBToRequestADiamond"
                          )
                        }
                      >
                        {" "}
                        {""} {traslate("Send Mail to Request A Diamond")} {""}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="emailTemplateEditorModal"
        title={traslate("Send Email")}
        centered
        visible={visible}
        onOk={() => {
          setVisible(false);
          handleEmailUpdate();
        }}
        onCancel={refreshPage}
        width={800}
        hieght={600}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={refreshPage}>
            {traslate("Cancel")}
          </Button>,
          <Button
            className="primary-btn"
            onClick={() => {
              setVisible(false);
              handleEmailUpdate();
            }}
          >
            {traslate("Save")}
          </Button>,
        ]}
      >
        <div className="editor__section">
          <Spin spinning={loading}>
            <CKEditor
              key={relaod}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              editor={ClassicEditor}
              data={content ? content : ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendantBuilderEmailTemplate);
