import { httpAdminReport } from "../helper/http-common";

class ReportJewelryReportService {
  GetVendorDropdownList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_VendorDetails",
      inputData
    );
  }
  GetRetailerDropdownList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_RetailerDetails",
      inputData
    );
  }
  ChangeRetailerList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Bind_RetailerDetails",
      inputData
    );
  }
  ChangeVendorList(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Bind_VendorDetails",
      inputData
    );
  }
  GetForDealer(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/CheckDealermembers",
      inputData
    );
  }
  GetJewelryTableChartData(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryReportForTableChart",
      inputData
    );
  }
  GetTableChartData(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/GetJewelryReportForTableChartAll",
      inputData
    );
  }
  GetClicksBasedOnTopVendor(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/GetJewelryTopVendorRetailerChartColunm",
      inputData
    );
  }
  GetClicksBasedOnPrice(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/GetJewelryPriceChartColunmData",
      inputData
    );
  }
  GetClicksBasedOnCategory(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/GetJewelryCategoryChartColunmData",
      inputData
    );
  }
  GetClicksBasedOnRetailer(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryTopRetailerChartColunm",
      inputData
    );
  }
  GetClicksBasedOnVendor(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryTopVendorRetailerChartColunm",
      inputData
    );
  }
  GetMetalTypeData(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/GetJewelryMetaltypeChartColunmData",
      inputData
    );
  }

  ExportDataToCSV(inputData) {
    return httpAdminReport.post(
      "/Admin_JewelryReport/Admin_JewelryReportForTableChart_CSV_Download",
      inputData
    );
  }

  ExportChartReportToCSV(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/GetJewelryReportForTableChartAll_CSV",
      inputData
    );
  }

  // New Reports Add to cart and WishList
  AddToCartOrWIshlistReportForTableChart(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/AddToCartOrWIshlistReportForTableChart",
      inputData
    );
  }
  AddToCartOrWishlistChartColunmData(inputData) {
    return httpAdminReport.post(
      "/JewelryReport/AddToCartOrWishlistChartColunmData",
      inputData
    );
  }
}

export default new ReportJewelryReportService();
