import React, { useState, useEffect, useRef } from "react";
import { Spin } from "antd";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import traslate from "../../i18n/translate";
import AdminDiamondReportService from "../../services/reports-AdminDiamond-service";
import { useSelector } from "react-redux";
import moment from "moment";

const AdminDiamondCertificateIndComp = ({
  globalDiamonds,
  mainForDealer,
  isApplyFilter,
  pageNo,
  pageSize,
  sortOrderCol,
  sortOrderType,
  setPageNo,
  handleGetDiamondCertificateList,
}) => {
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [
    diamondCertificateSearchesLabels,
    setdiamondCertificateSearchesLabels,
  ] = useState([]);
  const [DiamondCertificateSearchesList, setDiamondCertificateSearchesList] =
    useState([]);
  const [DiamondCertificateYAxisList, setDiamondCertificateYAxisList] =
    useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const DiamondCertificateChartRef = useRef();
  const [TableCertificateValue, setTableCertificateValue] = useState("");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleLoadChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  useEffect(() => {
    if (TableCertificateValue) {
      handleGetDiamondCertificateList(
        dealerId,
        mainForDealer,
        pageNo,
        pageSize,
        sortOrderCol,
        sortOrderType,
        TableCertificateValue
      );
    }
  }, [TableCertificateValue]);

  const handleLoadChartReports = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      type: "Global",
      reportName: "Certificate",
    };
    setLoadChartReportsLoading(true);
    AdminDiamondReportService.LoadChartReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var DiamondCertificateSearchesList =
          responseData.DiamondCertificateSearches;
        if (message === "Success") {
          //
          if (Object.keys(responseData).length !== 0) {
            setDiamondCertificateSearchesList(DiamondCertificateSearchesList);
            var DiamondCertificateSearchesLabelArr =
              DiamondCertificateSearchesList.map((item) => {
                return item.Certificate;
              });
            setdiamondCertificateSearchesLabels(
              DiamondCertificateSearchesLabelArr
            );
            var DiamondCertificateYAxisList =
              DiamondCertificateSearchesList.map((item) => {
                return item.CNT;
              });
            setDiamondCertificateYAxisList(DiamondCertificateYAxisList);
            var totalValueForPercentage = DiamondCertificateYAxisList.reduce(
              (a, b) => a + b,
              0
            );
            setTotalValueForPercentage(totalValueForPercentage);
            setKey(!key);
          }
        } else {
          setDiamondCertificateSearchesList([]);
          setDiamondCertificateYAxisList([]);
        }
      })
      .catch((error) => {
        setDiamondCertificateSearchesList([]);
        setDiamondCertificateYAxisList([]);
        setdiamondCertificateSearchesLabels([]);
      })
      .finally(() => setLoadChartReportsLoading(false));
  };
  /*API Binding Ends*/

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label></label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            {DiamondCertificateSearchesList.length > 0 ? (
              <Pie
                key={key}
                onClick={(event) => {
                  const element = getElementAtEvent(
                    DiamondCertificateChartRef.current,
                    event
                  );
                  if (!element.length) return;

                  const { index } = element[0];
                  setTableCertificateValue(
                    diamondCertificateSearchesLabels[index]
                  );
                  setPageNo(1);
                }}
                ref={DiamondCertificateChartRef}
                data={{
                  labels: diamondCertificateSearchesLabels,
                  datasets: [
                    {
                      label: "Count",
                      data: DiamondCertificateYAxisList,
                      fill: false,
                      backgroundColor: [
                        "rgb(14, 220, 232)",
                        "rgb(16, 150, 24)",
                        "rgb(255, 153, 0)",
                        "rgb(153, 0, 153)",
                        "rgb(0, 153, 198)",
                        "rgb(51, 102, 204)",
                        "rgb(220, 57, 18)",
                        "rgb(102, 170, 0)",
                        "rgb(221, 68, 119)",
                        "rgb(237, 237, 1)",
                      ],
                      borderColor: [
                        "rgb(14, 220, 232)",
                        "rgb(16, 150, 24)",
                        "rgb(255, 153, 0)",
                        "rgb(153, 0, 153)",
                        "rgb(0, 153, 198)",
                        "rgb(51, 102, 204)",
                        "rgb(220, 57, 18)",
                        "rgb(102, 170, 0)",
                        "rgb(221, 68, 119)",
                        "rgb(237, 237, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    datalabels: {
                      display: true,
                      align: "center",
                      color: "rgb(255, 255, 255)",
                      font: {
                        size: 12,
                      },
                      formatter: (value) => {
                        if (totalValueForPercentage) {
                          let finalPercentage = (
                            (value / totalValueForPercentage) *
                            100
                          ).toFixed(2);
                          setTooltipPercentage(finalPercentage);
                          if (finalPercentage >= 5) {
                            return finalPercentage + "%";
                          } else {
                            return "";
                          }
                        }
                      },
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (context) {
                          let label = context.label || "";
                          let formattedValue = context.formattedValue || "";

                          if (label) {
                            label =
                              label +
                              " " +
                              formattedValue +
                              " (" +
                              TooltipPercentage +
                              "%" +
                              ")";
                          }
                          return label;
                        },
                      },
                    },
                    legend: {
                      position: "left",
                    },
                  },
                }}
              />
            ) : (
              <div className="no-report-render">
                <div className="desc-space">
                  <h4 className="text-center">{traslate("No Data Found")}</h4>
                </div>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDiamondCertificateIndComp;
