import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { handleResetIframe } from "../../../actions/headerMenu/headerMenu";

const ApplicationPreviewStud = () => {
  const dispatch = useDispatch();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    //Receiving the message from child
    window.addEventListener("message", handleIframeTask);

    window.onpageshow = () => {
      dispatch(handleResetIframe(false));
    };
    const iframe = document.querySelector("iframe");
    iframe.contentWindow.postMessage("Show", "*");
  }, []);

  const handleIframeTask = (e) => {
    if (e.data === "submitted form") {
      document.getElementsByClassName("content__area")[0].scroll(0, 0);
    }
  };

  //   Mgr Stud Builder -
  //const src = `https://jc2-sbuild.mgr.brainvire.net?DealerLink=${loginDetials.responseData.dealerId}&isTab=True&tabName=selectYourDiamond&isChange=True`;

  const src = `https://jc2-sbuild.stage.jewelcloud.com?DealerLink=${loginDetials.responseData.dealerId}&isTab=True&tabName=selectYourDiamond&isChange=True`;
  const hideSpinner = () => {
    setLoading(false);
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
    dispatch(handleResetIframe(false));
    //Send Message to child
    const iframe = document.querySelector("iframe");
    iframe.contentWindow.postMessage("Show", "*");
  };

  return (
    <React.Fragment>
      {/* <h4 className="mr-3">Application Preview</h4> */}
      <Spin spinning={loading}>
        <iframe
          onLoad={() => hideSpinner()}
          width="100%"
          className="application__preview"
          //height="1000px"
          src={src}
        ></iframe>
      </Spin>
    </React.Fragment>
  );
};

export default ApplicationPreviewStud;
