import React, { useState, useEffect } from "react";
import { Select, Button, Spin } from "antd";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import DealerProfileService from "../../services/dealer-profile.service";
import config from "../../helper/config";

const { Option } = Select;

const UserProfile = () => {
  const [DealerId, setDealerId] = useState();
  const [userId, setUserID] = useState();
  const [loading, setLoading] = useState(false);
  const [btnvisible, setBtnVisible] = useState(true);
  const [btnloading, setBtnLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [isEdit, setIsEdit] = useState(true);

  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    // ;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setUserID(loginDetials.loginDetials.responseData.userId);
          handleGetUserProfileDetails(
            loginDetials.loginDetials.responseData.dealerId,
            loginDetials.loginDetials.responseData.userId
          );
          handleGetDealersLocation(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const initialstate = {
    userName: "",
    Name: "",
    jobTitle: "",
    phoneNumber: "",
    email: "",
    location: "",
    avtar: "",
    ImagefileName: null,
    Imagefile: null,
    showsImagePath: null,
  };

  const [editdata, setEditData] = useState(initialstate);
  const [validation, setValidations] = useState({
    nameVal: "",
    emailVal: "",
    ImageVal: "",
    phoneNumberVal: "",
    locationVal: "",
  });

  const fileUpload = async (e) => {
    setBtnVisible(false);
    var file = e.target.files[0];
    if (file?.name?.match(/.(png|jpg|jpeg)$/i)) {
      if (file) {
        setEditData({
          ...editdata,
          Imagefile: file,
          ImagefileName: file.name,
        });
        setValidations((prevdata) => ({
          ...prevdata,
          ImageVal: "",
        }));
      }
    } else {
      setEditData({
        ...editdata,
        Imagefile: null,
        ImagefileName: null,
      });
      setValidations((prevdata) => ({
        ...prevdata,
        ImageVal: "Please Select Image",
      }));
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    setBtnVisible(false);
    if (isSelect === "select") {
      setEditData({
        ...editdata,
        [name]: e,
      });
    } else {
      setEditData({
        ...editdata,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "Name") {
        setValidations((prevdata) => ({
          ...prevdata,
          nameVal: "",
        }));
      }
      if (e.target.name === "email") {
        setValidations((prevdata) => ({
          ...prevdata,
          emailVal: "",
        }));
      }

      if (e.target.name === "phoneNumber") {
        setValidations((prevdata) => ({
          ...prevdata,
          phoneNumberVal: "",
        }));
      }
    }
  };

  /* API Start */

  const handleGetDealersLocation = (id) => {
    let inputData = {
      dealerID: id,
    };
    try {
      DealerProfileService.GetDealersLocation(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setLocationList(responseData);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetUserProfileDetails = async (id, uid) => {
    let inputData = {
      dealerID: id,
      userId: uid,
    };
    setLoading(true);
    try {
      await DealerProfileService.GetUserProfileDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setBtnVisible(true);
              setIsEdit(true);
              setEditData({
                userName: responseData.userName,
                Name: responseData.name,
                jobTitle: responseData.jobTitle,
                phoneNumber: responseData.phoneNumber,
                email: responseData.email,
                location: responseData.locationId,
                showsImagePath: responseData.userAvtar,
                Imagefile: null,
                ImagefileName: null,
              });
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUserProfileDetails = () => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    setBtnLoading(true);
    const formData = new FormData();
    formData.append(
      "UserAvtarUploadFile",
      editdata.Imagefile ? editdata.Imagefile : null
    );
    formData.append("UserName", editdata.userName);
    formData.append("Name", editdata.Name);
    formData.append("JobTitle", editdata.jobTitle);
    formData.append("PhoneNumber", editdata.phoneNumber);
    formData.append("Email", editdata.email);
    formData.append("locationId", editdata.location);
    formData.append("UserAvtar", editdata.ImagefileName);
    formData.append("userId", userId);
    formData.append(
      "UserAvatarLink",
      editdata.showsImagePath == "" || editdata.showsImagePath == null
        ? ""
        : editdata.showsImagePath.replace(
            `${config.BlobURL}gemfind2staging/administration/UserAvtar/`,
            ""
          )
    );
    setLoading(true);
    DealerProfileService.UpdateUserProfileDetails(formData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("User Details Updated Successfully");
          handleGetUserProfileDetails(DealerId, userId);
        } else {
          NotificationManager.error("User Details not Updated.");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setBtnLoading(false);
      })
      .finally(() => {
        setLoading(false);
        setBtnLoading(false);
        setBtnLoading(false);
      });
  };
  /* API End */

  const handleValidation = () => {
    const { Name, email } = editdata;

    const validations = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      ImageVal: "",
      locationVal: "",
    };
    let isValid = true;

    if (!Name.trim()) {
      isValid = false;
      validations.nameVal = traslate("Name is compulsory");
    } else {
      validations.nameVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      validations.emailVal = traslate("Email Address is compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      validations.emailVal = traslate("Email Address is Invalid");
    } else {
      validations.emailVal = "";
    }
    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is compulsory");
    } else if (
      (phoneNumber.length < 15 &&
        phoneNumber.includes("-") &&
        /^[0-9]+$/.test(phoneNumber)) ||
      (phoneNumber.length < 15 &&
        phoneNumber.includes("+") &&
        /^[0-9]+$/.test(phoneNumber))
    ) {
      validations.phoneNumberVal = "";
    } else if (
      /^0*$/.test(phoneNumber) ||
      /[a-zA-Z@{}#$%?/^*&]/g.test(phoneNumber)
    ) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is invalid");
    } else if (phoneNumber.length > 15 || phoneNumber.length < 10) {
      isValid = false;
      validations.phoneNumberVal = traslate("Phone number is invalid");
    } else {
      validations.phoneNumberVal = "";
    }
    if (!location) {
      isValid = false;
      validations.locationVal = traslate("Location is compulsory");
    } else {
      validations.locationVal = "";
    }
    setValidations(validations);
    return isValid;
  };

  const handleCancelImage = () => {
    setBtnVisible(false);
    setEditData((prevState) => ({
      ...prevState,
      ["showsImagePath"]: null,
    }));

    setEditData((prevState) => ({
      ...prevState,
      ["Imagefile"]: null,
    }));
  };

  const { userName, Name, email, phoneNumber, jobTitle, location } = editdata;
  const { nameVal, emailVal, ImageVal, phoneNumberVal, locationVal } =
    validation;

  /*Validation for all letters starts*/
  const handleLetterChange = (e) => {
    if (
      e.key == 1 ||
      e.key == 2 ||
      e.key == 3 ||
      e.key == 4 ||
      e.key == 5 ||
      e.key == 6 ||
      e.key == 7 ||
      e.key == 8 ||
      e.key == 9 ||
      e.code == "Digit0"
    ) {
      e.preventDefault();
    }
  };
  /*Validation for all letters ends*/
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv app__preview p-0 border-0">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {traslate("Basic Information")}{" "}
                  </h4>
                </div>
                <Spin spinning={loading}>
                  <div className="col-lg-12 col-md-12">
                    <div className="prodesc__div">
                      <div className="row">
                        <div className="col-md-3 input__block">
                          <div className="col-md-12 image__block image_block">
                            <div className="divimage_block border-0">
                              {editdata.showsImagePath || editdata.Imagefile ? (
                                <div className="">
                                  {isEdit ? (
                                    editdata.Imagefile ? (
                                      <img
                                        src={URL.createObjectURL(
                                          editdata.Imagefile
                                        )}
                                      />
                                    ) : (
                                      <img src={editdata.showsImagePath} />
                                    )
                                  ) : editdata.Imagefile ? (
                                    <img
                                      src={URL.createObjectURL(
                                        editdata.Imagefile
                                      )}
                                    />
                                  ) : null}
                                  <span
                                    className="error-color-red cross__icon"
                                    onClick={handleCancelImage}
                                  >
                                    x
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <label
                                    htmlFor="file-upload"
                                    class="primary-btn choose_file_upload"
                                  >
                                    {traslate("Choose File")}
                                  </label>
                                  <input
                                    id="file-upload"
                                    type="file"
                                    onChange={(e) => fileUpload(e)}
                                  />

                                  {ImageVal && (
                                    <p className="error-color-red">
                                      {ImageVal}
                                    </p>
                                  )}
                                  <span class="mb-4">
                                    {" "}
                                    {traslate("No File Choosen")}{" "}
                                  </span>

                                  <span class="">
                                    {" "}
                                    {traslate(".JPG | .JPEG | .PNG Format")}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mt-3 d-flex justify-content-center align-items-center">
                            <span
                              className="linkText mr-2"
                              onClick={handleCancelImage}
                            >
                              {" "}
                              {traslate("Change")}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="row mt-4">
                            <div className="col-md-6">
                              <div className="input__block">
                                <label> {traslate("User Name")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter User Name"
                                  name="userName"
                                  value={userName ? userName : ""}
                                  onChange={handleOnChange}
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Name")} <span>*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Name"
                                  name="Name"
                                  value={Name ? Name : ""}
                                  onChange={handleOnChange}
                                  className={nameVal && "border__red"}
                                  onKeyPress={handleLetterChange}
                                />
                                <div>
                                  {nameVal && (
                                    <p className="error-color-red">{nameVal}</p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input__block">
                                <label> {traslate("Job Title")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Job Title"
                                  name="jobTitle"
                                  value={jobTitle ? jobTitle : ""}
                                  onChange={handleOnChange}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Email")} <span>*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Email Address"
                                  name="email"
                                  value={email ? email : ""}
                                  onChange={handleOnChange}
                                  className={emailVal && "border__red"}
                                />
                                <div>
                                  {emailVal && (
                                    <p className="error-color-red">
                                      {emailVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Phone Number")} <span>*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Phone Number"
                                  name="phoneNumber"
                                  value={phoneNumber ? phoneNumber : ""}
                                  onChange={(e) => {
                                    handleOnChange(e, "phoneNumber");
                                  }}
                                  className={phoneNumberVal && "border__red"}
                                />
                                <div>
                                  {phoneNumberVal && (
                                    <p className="error-color-red">
                                      {phoneNumberVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Location")} <span>*</span>{" "}
                                </label>
                                <Select
                                  className={
                                    locationVal ? "border__red" : "border__grey"
                                  }
                                  placeholder="Select Location"
                                  optionFilterProp="children"
                                  value={
                                    location
                                      ? location
                                      : locationList.length > 0 &&
                                        locationList[0].locationName
                                  }
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "location")
                                  }
                                >
                                  {locationList &&
                                    locationList.map((item, i) => {
                                      return (
                                        <Option
                                          key={item.locationId}
                                          value={item.locationId}
                                        >
                                          {item.locationName}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                <div>
                                  {locationVal && (
                                    <p className="error-color-red">
                                      {locationVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="text-align-right">
                              <Button
                                onClick={handleUpdateUserProfileDetails}
                                className={
                                  btnvisible == true
                                    ? "primary-btn disabled-btn"
                                    : "primary-btn"
                                }
                                disabled={btnvisible}
                                loading={btnloading}
                              >
                                {traslate("Save")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
