import { httpCatalog } from "../helper/http-common";
class CatalogGroupDiscountService {
    
    // DeleteAddRetailer(inputData) {
    //     return httpCatalog.post("/DiamondGroupDisCount/DeleteAddRetailer", inputData);
    // }

    UpdateAddRetailerOnDropdownChange(inputData) {
        return httpCatalog.post("/DiamondGroupDisCount/UpdateAddRetailerOnDropdownChange", inputData);
    }
    
  GetRetailerGroupDetails(inputData) {
    return httpCatalog.post(
      "/DiamondGroupDisCount/GetRetailerGroupDetails",
      inputData
    );
  }

  GetDiamondGroupDiscountDetails(inputData) {
    return httpCatalog.post(
      "/DiamondGroupDisCount/GetDiamondGroupDiscountDetails",
      inputData
    );
  }

  SaveAndUpdateDiamondGroupDiscount(inputData) {
    return httpCatalog.post(
      "/DiamondGroupDisCount/SaveAndUpdateDiamondGroupDiscount",
      inputData
    );
  }

  DeleteDiamondGroupDiscount(inputData) {
    return httpCatalog.post(
      "/DiamondGroupDisCount/DeleteDiamondGroupDiscount",
      inputData
    );
  }
}

export default new CatalogGroupDiscountService();
