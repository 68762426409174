import {
  CONTACT_CONVERSATION_COUNT,
  CONTACT_APPOINTMENT_COUNT,
  CONTACT_CUSTOMER_COUNT,
  CONTACT_PAGE_NAME_TYPE,
  DASHBOARD_PAGE_NAME_TYPE,
  TOGGLE_SIDEMENU_DASHBOARD_DATA,
  VENDOR_CONVERSATION_COUNT,
  VENDOR_APPOINTMENT_COUNT,
  VENDOR_CONVERSATION_COUNT_UNREAD,
  VENDOR_CONVERSATION_COUNT_SENT,
  SET_JEWELRY_TAB,
  DEALERID_ADMIN_DATA_MAPPING,
  COLUMNID_ADMIN_DATA_MAPPING,
  PROFILE_ID,
  PROFILE_TAB,
} from "../../actions/Contact/types";

const initialState = {
  toggleSideMenuData: false,
  contactConversationCount: 0,
  contactAppointmentCount: 0,
  contactCustomerCount: 0,
  ContactPageNameType: "Customer",
  DashboardPageNameType: "Dashboard",
  vendorConversionCount: "0",
  vendorAppointmentCount: 0,
  vendorConversionCountUnRead: "0",
  vendorConversionCountSend: "0",
  jewelryTabName: "",
  dealerIdAdminDataMap: null,
  columnIdAdminDataMap: null,
  profileID: 0,
  profileTab: "",
};

function contactReducer(ContactState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CONTACT_CONVERSATION_COUNT:
      return { ...ContactState, contactConversationCount: payload };
    case CONTACT_APPOINTMENT_COUNT:
      return { ...ContactState, contactAppointmentCount: payload };
    case CONTACT_CUSTOMER_COUNT:
      return { ...ContactState, contactCustomerCount: payload };
    case CONTACT_PAGE_NAME_TYPE:
      return { ...ContactState, ContactPageNameType: payload };
    case DASHBOARD_PAGE_NAME_TYPE:
      return { ...ContactState, DashboardPageNameType: payload };
    case TOGGLE_SIDEMENU_DASHBOARD_DATA:
      return { ...ContactState, toggleSideMenuData: payload };
    case VENDOR_CONVERSATION_COUNT:
      return { ...ContactState, vendorConversionCount: payload };
    case VENDOR_APPOINTMENT_COUNT:
      return { ...ContactState, vendorAppointmentCount: payload };
    case VENDOR_CONVERSATION_COUNT_UNREAD:
      return { ...ContactState, vendorConversionCountUnRead: payload };
    case VENDOR_CONVERSATION_COUNT_SENT:
      return { ...ContactState, vendorConversionCountSend: payload };
    case SET_JEWELRY_TAB:
      return { ...ContactState, jewelryTabName: payload };
    case DEALERID_ADMIN_DATA_MAPPING:
      return { ...ContactState, dealerIdAdminDataMap: payload };
    case COLUMNID_ADMIN_DATA_MAPPING:
      return { ...ContactState, columnIdAdminDataMap: payload };
    case PROFILE_ID:
      return { ...ContactState, profileID: payload };
    case PROFILE_TAB:
      return { ...ContactState, profileTab: payload };
    default:
      return ContactState;
  }
}
export default contactReducer;
