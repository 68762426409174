import {
  MANAGE_INVENTORY_VENDOR_ID,
  MANAGE_INVENTORY_TABNAME,
  VENDOR_PROFILE_PAGE_NAME,
  BIND_ACTIVE_PAGE_NAME,
} from "./types";

export const setManageInventoryVendorId = (vendorId) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGE_INVENTORY_VENDOR_ID,
      payload: vendorId,
    });
  } catch (err) {}
};
export const setManageTabName = (data) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGE_INVENTORY_TABNAME,
      payload: data,
    });
  } catch (err) {}
};

export const setVendorProfilePageName = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_PROFILE_PAGE_NAME,
      payload: data,
    });
  } catch (err) {}
};

export const setBindActivePageName = (data) => async (dispatch) => {
  try {
    dispatch({
      type: BIND_ACTIVE_PAGE_NAME,
      payload: data,
    });
  } catch (err) {}
};
