import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
  Spin,
} from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../i18n/translate";
import CatalogGroupDiscountService from "../../services/catalog-group-discount.service";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
const { Option } = Select;

const RetailerGroup = () => {
  const [visible, setVisible] = useState(false);
  const [dealerId, setDealerId] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [retailerGroupList, setRetailerGroupList] = useState([]);
  const [selectedValues, setSelectedValues] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetRetailerGroupDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleGetRetailerGroupDetails = (id) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setLoading(true);
      CatalogGroupDiscountService.GetRetailerGroupDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerGroupList(responseData);
            setSelectedValues(responseData.selectedValue);
            setLoading(false);
          } else {
            setRetailerGroupList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateAddRetailerOnDropdownChange = (
    id,
    retailerId,
    priceGrp
  ) => {
    try {
      let inputData = {
        dealerId: id.toString(),
        retailerID: retailerId.toString(),
        priceGroups: priceGrp.toString(),
      };
      CatalogGroupDiscountService.UpdateAddRetailerOnDropdownChange(inputData)
        .then((response) => {
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success("Data Updated Successfully.");
            handleGetRetailerGroupDetails(dealerId);
          } else {
            NotificationManager.error("Data Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: traslate("Company Name (User Name)"),
      dataIndex: "dealerCompany",
    },
    {
      title: traslate("Group Name"),
      dataIndex: "groupDropDownList",
      render: (item, row) => {
        return (
          <div className="input__block mb-0">
            <Select
              name=""
              className="border__grey"
              showSearch
              placeholder=""
              optionFilterProp="children"
              value={
                row.selectedValue && row.selectedValue != "0"
                  ? parseInt(row.selectedValue)
                  : ""
              }
              onChange={(e) =>
                handleUpdateAddRetailerOnDropdownChange(
                  dealerId,
                  row.dealerID,
                  e
                )
              }
            >
              {row.groupDropDownList.map((item, i) => {
                return <Option value={item.id}>{item.groupName}</Option>;
              })}
            </Select>
          </div>
        );
      },
    },
    // {
    //   title: traslate("Action"),
    //   dataIndex: "action",
    //   render: (item, row) => {
    //     return (
    //       <div className="action__btns">
    //         <div className="image__block">
    //           <img
    //             src={Edit_icon}
    //             alt=""
    //             onClick={() => setVisible(true)}
    //           />
    //         </div>
    //         <div className="image__block">
    //           <img
    //             src={Delete_iconbl}
    //             alt=""
    //             onClick={(e) =>handleDeleteAddRetailer(row.groupDropDownList[0].id)}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   width: 100,
    // }
  ];

  const data = [
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Retailer Group")}
              </h4>
              {/* <button className="primary-btn">
                <img src={Create_icon} />
                {traslate("Add Retailer")}
              </button> */}
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="button__block my-4">
              <button className="primary-btn">Download</button>
            </div>
          </div> */}

          <div className="col-lg-12 col-md-12">
            <Spin spinning={loading}>
              <Table
                className="pendjewelreq__tbl"
                columns={columns}
                dataSource={retailerGroupList}
                scroll={{ x: 600, y: 800 }}
              />
            </Spin>
          </div>
        </div>
      </div>
      <Modal
        title={traslate("Add Retailer")}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {traslate("Submit")}
          </Button>,
        ]}
        width={1000}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Username")}</label>
                      <input
                        name="showName"
                        type="text"
                        value="ChrisDoddridge"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Password")}</label>
                      <input name="showName" type="text" value="Ae15aSxi" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("BrandGroup")}</label>
                      <input name="showName" type="text" value="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Role")}</label>
                      <input name="showName" type="text" value="Retailer" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("First Name")}</label>
                      <input name="showName" type="text" value="Chris" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Last Name")}</label>
                      <input name="showName" type="text" value="Doddridge" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Address")}</label>
                      <input
                        name="showName"
                        type="text"
                        value="15732 TUSTIN VILLAGE WAY"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Address2")}</label>
                      <input name="showName" type="text" value="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Email")}</label>
                      <input
                        name="showName"
                        type="email"
                        value="chris@jeweleryexchange.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Company")}</label>
                      <input
                        name="showName"
                        type="text"
                        value="GOLDEN WEST CORP."
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("City")}</label>
                      <input name="showName" type="text" value="TUSTIN" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("State")}</label>
                      <Select
                        name=""
                        className="border__grey"
                        showSearch
                        placeholder="Select State"
                        optionFilterProp="children"
                      >
                        <Option value="">{traslate("Select GroupName")}</Option>
                        <Option value="1"> {traslate("Option 1")} </Option>
                        <Option value="2"> {traslate("Option 2")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Retailer Number")}</label>
                      <input name="showName" type="text" value="NY-300637" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Sales Person")}</label>
                      <input name="showName" type="text" value="Lenny Kramer" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Zip")}</label>
                      <input name="showName" type="text" value="92780" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Active")}</label>
                      <Switch
                        name="enabled"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Price Group")}</label>
                      <Select
                        name=""
                        className="border__grey"
                        showSearch
                        placeholder="Select Price Group"
                        optionFilterProp="children"
                      >
                        <Option value="">{traslate("Select GroupName")}</Option>
                        <Option value="1"> {traslate("Option 1")} </Option>
                        <Option value="2"> {traslate("Option 2")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Country")}</label>
                      <Select
                        name=""
                        className="border__grey"
                        showSearch
                        placeholder="Select Country"
                        optionFilterProp="children"
                      >
                        <Option value="">{traslate("Select GroupName")}</Option>
                        <Option value="1"> {traslate("Option 1")} </Option>
                        <Option value="2"> {traslate("Option 2")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{traslate("Telephone")}</label>
                      <input name="showName" type="text" value="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RetailerGroup;
