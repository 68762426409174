import React, { useState, useEffect, Fragment } from "react";

import {
  Input,
  Checkbox,
  Button,
  Table,
  Modal,
  Space,
  Spin,
  Switch,
} from "antd";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import sliderbgImg from "../../assets/images/slider-bg.png";
import tacoriImg from "../../assets/images/tacori-logo.png";
import hoverBg1 from "../../assets/images/daimond-bg.png";
import hoverBg2 from "../../assets/images/jewelry-bg.png";
import hoverBg3 from "../../assets/images/watch-bg.png";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import catalogJewelryService from "../../services/catalog-jewelry.service";
import communityVendorService from "../../services/community-vendor.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const CatlogList = () => {
  const loginDetials = useSelector((state) => state.loginReducer);
  const headerDetials = useSelector((state) => state.headerMenuReducer);
  const [countData, setCountData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDiamondShow, setIsDiamondShow] = useState(false);
  const [isJewelaryShow, setIsJewelaryShow] = useState(false);
  const [isWatchShow, setIsWatchShow] = useState(false);
  const [recentlyUpdatedVendorData, setRecentlyUpdatedVendorData] = useState(
    []
  );
  const [vendorLoading, setVendorLoading] = useState(false);
  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);
  const [row, setRow] = useState([]);
  const [checkbox, setCheckBox] = useState("");
  const [page, setPage] = useState("RetailerAffirmation");
  const [disableBtn, setDisableBtn] = useState(true);
  const [content, setContent] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [dealerId, setDealerId] = useState("");
  const [dealerCompany, setDealerCompany] = useState("");

  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: ` By checking this box and clicking the submit button below I here by affirm that ${
        dealerCompany ? dealerCompany : null
      } has an open and active account with ${
        row.dealerCompany
      },that I am an authorized reseller of ${
        row.dealerCompany
      },and that I am permitted to have the ir virtual inventory on my website.`,
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",

      note: `I am interested in opening a new account with ${row.dealerCompany} and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to ${row.dealerCompany}.`,
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetCatalogCount(
            loginDetials.loginDetials.responseData.dealerId
          );
          handleAdminDashBoardBindDataCommVendor(
            loginDetials.loginDetials.responseData.dealerId,
            0
          );
        }
      }
    }
  }, []);

  // Dimaond and Jewelery

  useEffect(() => {
    if (headerDetials.sideMenuTierThreeDetails) {
      for (var i = 0; i < headerDetials.sideMenuTierThreeDetails.length; i++) {
        if (
          headerDetials.sideMenuTierThreeDetails[i].twoTierPageName ==
            "Products" &&
          headerDetials.sideMenuTierThreeDetails[i].threeTierPageName ==
            "Diamond"
        ) {
          setIsDiamondShow(true);
        }
        if (
          headerDetials.sideMenuTierThreeDetails[i].twoTierPageName ==
            "Products" &&
          headerDetials.sideMenuTierThreeDetails[i].threeTierPageName ==
            "Jewelry"
        ) {
          setIsJewelaryShow(true);
        }
        if (
          headerDetials.sideMenuTierThreeDetails[i].twoTierPageName ==
            "Products" &&
          headerDetials.sideMenuTierThreeDetails[i].threeTierPageName ==
            "Watches"
        ) {
          setIsWatchShow(true);
        }
      }
      // if (
      //   element.twoTierPageName == "Products" &&
      //   element.threeTierPageName == "Diamond"
      // ) {
      //   setIsDiamondShow(true);
      // } else {
      //   setIsDiamondShow(false);
      // }
      // if (
      //   element.twoTierPageName == "Products" &&
      //   element.threeTierPageName == "Jewelry"
      // ) {
      //   setIsJewelaryShow(true);
      // } else {
      //   setIsDiamondShow(false);
      // }
      // if (
      //   element.twoTierPageName == "Products" &&
      //   element.threeTierPageName == "Watches"
      // ) {
      //   setIsWatchShow(true);
      // } else {
      //   setIsWatchShow(false);
      // }
    }
  }, [headerDetials]);

  const handleAdminDashBoardBindDataCommVendor = async (id) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        filerType: "3",
        currentPage: 1,
        recordOnPage: 10,
        searchKeyChar: "",
        searchType: "",
        sortColumnName: "dealerCompany",
        sortOrder: "asc",
        lstColumnFilter: [],
        limitcount: 0,
      };
      setVendorLoading(true);
      communityVendorService
        .AdminDashBoardBindDataCommVendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.vendor_NetworkResponse.length > 0) {
              setRecentlyUpdatedVendorData(responseData.vendor_NetworkResponse);
              setVendorLoading(false);
            } else {
              setRecentlyUpdatedVendorData([]);
              setVendorLoading(false);
            }
          } else {
            setRecentlyUpdatedVendorData([]);
            setVendorLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setVendorLoading(false);
        });
    } catch (error) {
      console.log(error);
      setVendorLoading(false);
    }
  };

  const handleGetCatalogCount = (Id) => {
    try {
      let inputData = {
        dealerId: Number(Id),
      };
      setLoading(true);
      catalogJewelryService
        .GetCatalogCount(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setCountData(responseData);
            setLoading(false);
          } else {
            setCountData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

const options = {
  margin: 25, 
  loop: true,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
    1200: {
      items: 4, 
    },
  },
};

  const handleRedirectVendorProfile = () => {};

  const handleRequestAcces = (row) => {
    setRow(row);
    setIsModalSendInvitationVisible(true);
  };
  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
    setDisableBtn(false);
  }
  const handleSendInvitationModalCancel = () => {
    setCheckBox("");
    setDisableBtn(false);
    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setContent("");
  };

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .SendEmailToVendor(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success("Email has been Sent Successfully.");
          } else {
            NotificationManager.error("Email has not been Sent Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleNewAccountRequest = () => {
    try {
      let inputData = {
        email_Type: row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success("Email has been Sent Successfully.");
          } else {
            NotificationManager.error("Email has not been Sent Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleGetEmailData = () => {
    try {
      let inputData = {
        vendorID: row.dealerID,

        dealerID: dealerId,
        emailType: row.emailType,
      };
      setEmailLoading(true);
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="catalog__list">
        {loginDetials.loginDetials.responseData.dealerMemberTypeName !==
          "Vendor" &&
        loginDetials.loginDetials.responseData.dealerMemberTypeName !==
          "VendorDiamond" ? (
          <div className="row">
            <div className="col-md-12">
              <Spin spinning={vendorLoading}>
                <div className="slider__section">
                  <OwlCarousel
                    className="owl-theme custom_Owl_carousel"
                    {...options}
                  >
                    {recentlyUpdatedVendorData &&
                      recentlyUpdatedVendorData.map((item, i) => {
                        return (
                          <div class="item">
                            <div className="slider__block">
                              <div className="bgimage">
                                <img src={item.imageAdv} />
                              </div>
                              <div className="slider__content">
                                <div className="logo__img"></div>
                                <div className="slider__btns">
                                  <Link
                                    to={"/vendorProfile"}
                                    state={{
                                      key: "Profile",
                                      id: item.dealerID,
                                    }}
                                  >
                                    <button class="primary-btn">
                                      {" "}
                                      {traslate("View Profile")}{" "}
                                    </button>
                                  </Link>

                                  <button
                                    class="primary-btn"
                                    onClick={() => handleRequestAcces(item)}
                                  >
                                    {" "}
                                    {traslate("Request Access")}{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </Spin>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-12">
            <h3 className="subheading"> {traslate("Category")} </h3>
          </div>
        </div>
        <Spin spinning={loading}>
          <div className="row">
            {isDiamondShow ? (
              <div className="col-lg-4">
                <div className="whole__block">
                  <div className="top__image">
                    <img src={hoverBg1} className="img-fluid" />
                    <h3> {traslate("Diamonds")} </h3>
                  </div>
                  <div className="bottom__details">
                    <div className="row">
                      {loginDetials.loginDetials.responseData
                        .dealerMemberTypeName !== "VendorDiamond" &&
                        loginDetials.loginDetials.responseData
                          .dealerMemberTypeName !== "Vendor" && (
                          <div className="col-md-6 col-6">
                            <div className="content__block">
                              <h3 className="count">
                                {countData.yourTotalDiamonds}
                              </h3>
                              <h4 className="text">
                                {" "}
                                {traslate("Total Diamonds")}{" "}
                              </h4>
                            </div>
                          </div>
                        )}

                      {loginDetials.loginDetials.responseData
                        .dealerMemberTypeName !== "VendorDiamond" &&
                        loginDetials.loginDetials.responseData
                          .dealerMemberTypeName !== "Vendor" && (
                          <div className="col-md-6 col-6">
                            <div className="content__block">
                              <h3 className="count">
                                {countData.connectedDiamond}
                              </h3>
                              <h4 className="text">
                                {" "}
                                {traslate("Connected Diamonds")}{" "}
                              </h4>
                            </div>
                          </div>
                        )}
                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">{countData.yourDiamonds}</h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Your Diamonds")}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">{countData.minedDiamond}</h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Mined Diamonds")}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">{countData.labDiamond}</h3>
                          <h4 className="text"> {traslate("Lab Diamonds")} </h4>
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">{countData.colorDiamond}</h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Color Diamonds")}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {isJewelaryShow ? (
              <div className="col-lg-4">
                <div className="whole__block">
                  <div className="top__image">
                    <img src={hoverBg2} className="img-fluid" />
                    <h3> {traslate("Jewelry")} </h3>
                  </div>
                  <div className="bottom__details">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">
                            {countData.yourJeweryProducts}
                          </h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Your Products")}{" "}
                          </h4>
                        </div>
                      </div>

                      {loginDetials.loginDetials.responseData
                        .dealerMemberTypeName !== "Vendor" &&
                        loginDetials.loginDetials.responseData
                          .dealerMemberTypeName !== "VendorDiamond" && (
                          <div className="col-md-6 col-6">
                            <div className="content__block">
                              <h3 className="count">
                                {countData.connectedJewelry}
                              </h3>
                              <h4 className="text">
                                {" "}
                                {traslate("Connected Products")}{" "}
                              </h4>
                            </div>
                          </div>
                        )}

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">
                            {countData.yourJeweryActiveProducts}
                          </h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Active Products")}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">
                            {" "}
                            {countData.importType
                              ? countData.importType
                              : "-"}{" "}
                          </h3>
                          <h4 className="text"> {traslate("Import Type")} </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {isWatchShow && (
              <div className="col-lg-4">
                <div className="whole__block">
                  <div className="top__image">
                    <img src={hoverBg3} className="img-fluid" />
                    <h3> {traslate("Watches")} </h3>
                  </div>
                  <div className="bottom__details">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">
                            {" "}
                            {countData.yourWatchesProducts
                              ? countData.yourWatchesProducts
                              : 0}{" "}
                          </h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Your Products")}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">
                            {" "}
                            {countData.connectedWatches
                              ? countData.connectedWatches
                              : 0}{" "}
                          </h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Connected Products")}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="content__block">
                          <h3 className="count">
                            {" "}
                            {countData.connectedWatches
                              ? countData.connectedWatches
                              : 0}{" "}
                          </h3>
                          <h4 className="text">
                            {" "}
                            {traslate("Active Products")}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </div>
      <Modal
        title={
          page == "RetailerAffirmation"
            ? traslate("Retailer Affirmation")
            : traslate("Send Email")
        }
        visible={isModalSendInvitationVisible}
        className="forgot__password"
        onCancel={handleSendInvitationModalCancel}
        footer={[
          //
          <div className={page == "RetailerAffirmation" ? "d-block" : "d-none"}>
            <Button
              key="submit"
              className={checkbox == "" ? "disabled-btn" : "primary-btn"}
              disabled={(checkbox == "") == true ? true : false}
              onClick={
                checkbox == "CurrentAuthorizedReseller"
                  ? handleShowEmailWindow
                  : handleNewAccountRequest
              }
            >
              {traslate("Submit")}
            </Button>
          </div>,
          <div className={page == "SendEmail" ? "d-block" : "d-none"}>
            <Button
              className="primary-btn"
              key="submit"
              onClick={handleSendEmail}
            >
              {traslate("Send")}
            </Button>
          </div>,
        ]}
      >
        <div className="form__fields p-0 border-0 mh-auto">
          {page == "RetailerAffirmation" ? (
            <div>
              {category.map((item) => {
                return (
                  <Fragment>
                    <div className="radio__block">
                      <Checkbox
                        key={item.value}
                        onChange={handleCheckBoxChange}
                        checked={item.value == checkbox}
                        value={item.value}
                        id={item.value}
                      >
                        <label htmlFor={item.value}>
                          {" "}
                          {traslate(item.label)}{" "}
                        </label>
                      </Checkbox>
                    </div>
                    <div>
                      <p> {item.note} </p>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <div className="editor__section">
              <Spin spinning={emailLoading}>
                <CKEditor
                  editor={ClassicEditor}
                  data={content}
                  // config={editorConfiguration}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                />
              </Spin>
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CatlogList;
