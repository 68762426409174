import { httpDealer } from "../helper/http-common";
class RetailerDataService {
  GetMyRetailerList(inputData) {
    return httpDealer.post("/Retailer/GetMyRetailerList", inputData);
  }
  GetmyRetailerListVendorDiamond(inputData) {
    return httpDealer.post("/Retailer/GetmyRetailerListVendorDiamond", inputData);
  }
  EnableDisableIsRestricted(inputData) {
    return httpDealer.post("/Retailer/EnableDisableIsRestricted", inputData);
  }
  EnableDisablePrice(inputData) {
    return httpDealer.post("/Retailer/EnableDisablePrice", inputData);
  }
  EnableDisableMarkup(inputData) {
    return httpDealer.post("/Retailer/EnableDisableMarkup", inputData);
  }
  EnableDisableRingBuilder(inputData) {
    return httpDealer.post("/Retailer/EnableDisableRingBuilder", inputData);
  }
  EnableDisableIsAutoPermission(inputData) {
    return httpDealer.post(
      "/Retailer/EnableDisableIsAutoPermission",
      inputData
    );
  }
  EnableDisablePurchaseOrder(inputData) {
    return httpDealer.post("/Retailer/EnableDisablePurchaseOrder", inputData);
  }
  GetRetailerProfile(inputData) {
    return httpDealer.post("/Retailer/GetRetailerProfile", inputData);
  }
  GetALLRetailerList(inputData) {
    return httpDealer.post("/Retailer/GetALLRetailerList", inputData);
  }
  GetPendingRequests(inputData) {
    return httpDealer.post("/Retailer/GetPendingRequests", inputData);
  }
  RetailerContactDetail(inputData) {
    return httpDealer.post("/Retailer/RetailerContactDetail", inputData);
  }
  GetRetailerCollectionAccess(inputData) {
    return httpDealer.post("/Retailer/GetRetailerCollectionAccess", inputData);
  }
  UpdateCollection(inputData) {
    return httpDealer.post("/Retailer/UpdateCollection", inputData);
  }
  SaveUpdateAppointmentNote(inputData) {
    return httpDealer.post("/Retailer/SaveUpdateAppointmentNote", inputData);
  }
  LoadRetAppData(inputData) {
    return httpDealer.post("/Retailer/LoadRetAppData", inputData);
  }
  LoadDataNotesList(inputData) {
    return httpDealer.post("/Retailer/LoadDataNotesList", inputData);
  }
  AddNewNote(inputData) {
    return httpDealer.post("/Retailer/AddNewNote", inputData);
  }
  DeleteNote(inputData) {
    return httpDealer.post("/Retailer/DeleteNote", inputData);
  }
  SaveEditedNote(inputData) {
    return httpDealer.post("/Retailer/SaveEditedNote", inputData);
  }
  GetSalesRepsList(inputData) {
    return httpDealer.post("/Retailer/GetSalesRepsList", inputData);
  }
  SaveSalesReps(inputData) {
    return httpDealer.post("/Retailer/SaveSalesReps", inputData);
  }
  RetailerDisApprove(inputData) {
    return httpDealer.post("/Retailer/RetailerDisApprove", inputData);
  }
  SendMsg(inputData) {
    return httpDealer.post("/Retailer/SendMsg", inputData);
  }
  LoadRetailerMsg(inputData) {
    return httpDealer.post("/Retailer/LoadRetailerMsg", inputData);
  }
  GetDealersDropdown() {
    return httpDealer.post("/Retailer/GetDealersDropdown");
  }
  GetLocationDropdown() {
    return httpDealer.post("/Retailer/GetLocationDropdown");
  }
  GetDealerSelectedShowforappointment(inputData) {
    return httpDealer.post("/Dealer/GetDealerSelectedShowforappointment", inputData);
  }
  GetDealerSelectedTradShowDaysforappointment(inputData) {
    return httpDealer.post("/Dealer/GetDealerSelectedTradShowDaysforappointment", inputData);
  }
  BindDropDownForTimeTradShowappointment(inputData) {
    return httpDealer.post("/Dealer/BindDropDownForTimeTradShowappointment", inputData);
  }
  RetailerSaveAppointmentdata(inputData) {
    return httpDealer.post("/Retailer/RetailerSaveAppointmentdata", inputData);
  }
  Loadappoinmentdata(inputData) {
    return httpDealer.post("/Retailer/Loadappoinmentdata", inputData);
  }
  GetAppNoteData(inputData) {
    return httpDealer.post("/Retailer/GetAppNoteData", inputData);
  }
  //// Pending Request
  PendingToApprove(inputData) {
    return httpDealer.post("/Retailer/PendingToApprove", inputData);
  }
  PendingToDisApprove(inputData) {
    return httpDealer.post("/Retailer/PendingToDisApprove", inputData);
  }

  LoadGlobelSetting(inputData) {
    return httpDealer.post("/Retailer/LoadGlobelSetting", inputData);
  }

  LoadRetailersPermissions(inputData) {
    return httpDealer.post("/Retailer/LoadRetailersPermissions", inputData);
  }

  Setglobalpermission(inputData) {
    return httpDealer.post("/Retailer/Setglobalpermission", inputData);
  }

  BindRightSideDataForPermission(inputData) {
    return httpDealer.post(
      "/Retailer/BindRightSideDataForPermission",
      inputData
    );
  }

  UpdateCollection(inputData) {
    return httpDealer.post("/Retailer/UpdateCollection", inputData);
  }

  DisableProductbyVendor(inputData) {
    return httpDealer.post("/Retailer/DisableProductbyVendor", inputData);
  }

  SendReailerRequest(inputData) {
    return httpDealer.post("/Retailer/SendReailerRequest", inputData);
  }
  GetDealerPermission(inputData) {
    return httpDealer.post("/Retailer/GetDealerPermission", inputData);
  }
  GetVendorRequests(inputData) {
    return httpDealer.post("/Retailer/GetVendorRequests", inputData);
  }
  VenorRequestDisApprove(inputData) {
    return httpDealer.post("/Retailer/VenorRequestDisApprove", inputData);
  }
  VendorRequestApprove(inputData) {
    return httpDealer.post("/Retailer/VendorRequestApprove", inputData);
  }
}

export default new RetailerDataService();
