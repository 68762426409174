import { httpCatalog } from "../helper/http-common";

class PricingGeneralService {

    SetDefaultMarkup(inputdata) {
        return httpCatalog.post(
          "Pricing/SetDefaultMarkup",
          inputdata
        );
      }
}

export default new PricingGeneralService();