import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Table,
  Spin,
} from "antd";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from "chart.js";
import { Doughnut, Bar, Line, Scatter, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import traslate from "../../i18n/translate";
// import GlobalDiamondReportService from "../../services/reports-globalDiamond-service";
import AdminDiamondReportService from "../../services/reports-AdminDiamond-service";
import { DatePicker, Space } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import NoReportDataIcon from "../../assets/images/no-report-img.png";
const { RangePicker } = DatePicker;
const { Option } = Select;

Chart.register(ChartDataLabels);
// Chart.defaults.plugins.tooltip.enabled = false;
const AdminDiamondPriceComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer,
}) => {
  /*React Binding Starts*/
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  // const [diamondClaritySearchesLabels, setdiamondClaritySearchesLabels] = useState([]);
  const [diamondPriceLabels, setdiamondPriceLabels] = useState([]);
  // const [DiamondClaritySearchesList, setDiamondClaritySearchesList] = useState([]);
  const [DiamondPriceList, setDiamondPriceList] = useState([]);
  // const [DiamondClarityYAxisList, setDiamondClarityYAxisList] = useState([]);
  const [DiamondPriceYAxisList, setDiamondPriceYAxisList] = useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [IsReportEmpty, setIsReportEmpty] = useState(true);
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          // handleCheckDealermembers(loginDetials.loginDetials.responseData.dealerId);
          handleLoadChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  // const calculatePercentage = (dataList) => {
  //     var sumOfValues, percentageValue;
  //     sumOfValues = dataList.reduce((a, b) => a + b, 0);
  //     percentageValue = dataList.map(function (entry) {

  //         entry.percent = entry.y / quartersum[entry.label] * 100;

  //     })
  // }
  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };
      //
      AdminDiamondReportService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            //
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            // handleDiamondChartForCutGrade(id, forDealer);
            handleLoadChartReports(id, forDealer);
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: globalDiamonds.retailerIds.toString(),
        vendorIds: globalDiamonds.vendorIds.toString(),
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        type: "Global",
        reportName: "ExtDiamondChrt",
      };
      setLoadChartReportsLoading(true);

      AdminDiamondReportService.LoadChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondPriceList = responseData.DiamondPrice;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            //
            if (Object.keys(responseData).length !== 0) {
              //
              setDiamondPriceList(DiamondPriceList);
              if (
                responseData.DiamondPrice.every((item) => {
                  return item.TOTAL == 0;
                })
              ) {
                setIsReportEmpty(true);
              } else {
                setIsReportEmpty(false);
              }
              var DiamondPriceLabelArr = DiamondPriceList.map((item) => {
                return item.Range;
              });
              setdiamondPriceLabels(DiamondPriceLabelArr);
              var DiamondPriceYAxisList = DiamondPriceList.map((item) => {
                return item.TOTAL;
              });
              setDiamondPriceYAxisList(DiamondPriceYAxisList);
              var totalValueForPercentage = DiamondPriceYAxisList.reduce(
                (a, b) => a + b,
                0
              );
              setTotalValueForPercentage(totalValueForPercentage);
              setKey(!key);
            }
          } else {
            setDiamondPriceList([]);
            setDiamondPriceYAxisList([]);
          }
        })
        .catch((error) => {
          setDiamondPriceList([]);
          setDiamondPriceYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondPriceLabels([]);
        });
    } catch (error) {
      setDiamondPriceList([]);
      setDiamondPriceYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondPriceLabels([]);
    }
  };
  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {traslate("Diamond Price")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            {IsReportEmpty == false ? (
              <Pie
                key={key}
                data={{
                  labels: diamondPriceLabels,
                  datasets: [
                    {
                      // label: "Clarity Price",
                      data: DiamondPriceYAxisList,
                      backgroundColor: [
                        "rgb(14, 220, 232)",
                        "rgb(16, 150, 24)",
                        "rgb(255, 153, 0)",
                        "rgb(153, 0, 153)",
                        "rgb(0, 153, 198)",
                        "rgb(51, 102, 204)",
                        "rgb(220, 57, 18)",
                        "rgb(102, 170, 0)",
                        "rgb(221, 68, 119)",
                      ],
                      borderColor: [
                        "rgb(14, 220, 232)",
                        "rgb(16, 150, 24)",
                        "rgb(255, 153, 0)",
                        "rgb(153, 0, 153)",
                        "rgb(0, 153, 198)",
                        "rgb(51, 102, 204)",
                        "rgb(220, 57, 18)",
                        "rgb(102, 170, 0)",
                        "rgb(221, 68, 119)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    datalabels: {
                      display: true,
                      align: "end",
                      color: "rgb(255, 255, 255)",
                      font: {
                        size: 12,
                      },
                      formatter: (value) => {
                        if (totalValueForPercentage) {
                          // if (value >= 35000) {
                          let finalPercentage = (
                            (value / totalValueForPercentage) *
                            100
                          ).toFixed(2);
                          setTooltipPercentage(finalPercentage);
                          if (finalPercentage >= 5) {
                            return finalPercentage + "%";
                          } else {
                            return "";
                          }
                        }
                      },
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (context) {
                          let label = context.label || "";
                          let formattedValue = context.formattedValue || "";

                          if (label) {
                            label =
                              label +
                              " " +
                              formattedValue +
                              " (" +
                              TooltipPercentage +
                              "%" +
                              ")";
                          }
                          return label;
                        },
                      },
                    },
                    legend: {
                      position: "left",
                      // maxHeight: 1000
                    },
                  },
                }}
              />
            ) : (
              <div className="no-report-render">
                <div className="desc-space">
                  <h4 className="text-center">No Data Found</h4>
                </div>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDiamondPriceComp;
