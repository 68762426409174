import { httpWithoutToken } from "../helper/http-common";

class LoginDataService {
  login(inputData) {
    return httpWithoutToken.post("/Identity/LogIn", inputData);
  }
  AdminResetPassword(inputData) {
    return httpWithoutToken.post("/Identity/AdminResetPassword", inputData);
  }
  AdminSuperResetPassword(inputData) {
    return httpWithoutToken.post(
      "/Identity/AdminSuperResetPassword",
      inputData
    );
  }
  currency(inputData) {
    return httpWithoutToken.post(
      "/SelectCourency/SelectlanguageForDelar",
      inputData
    );
  }
}

export default new LoginDataService();
