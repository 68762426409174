import { httpAdminReport } from "../helper/http-common";
class GlobalDiamondReportService {
    GetRetailers(inputData) {
        return httpAdminReport.post("/DiamondReport/GetRetailers", inputData)
    }
    GetVendors(inputData) {
        return httpAdminReport.post("/DiamondReport/GetVendors", inputData)
    }
    GetRetailersOnVendorDropdownChange(inputData) {
        return httpAdminReport.post("/DiamondReport/GetRetailersOnVendorDropdownChange", inputData)
    }
    GetVendorsOnRetailerDropdownChange(inputData) {
        return httpAdminReport.post("/DiamondReport/GetVendorsOnRetailerDropdownChange", inputData)
    }
    // ChangeVendorList(inputData) {
    //     return httpAdminReport.post("/Admin_JewelryReport/Bind_VendorDetails", inputData)
    // }
    CheckDealermembers(inputData) {
        return httpAdminReport.post("/Admin_JewelryReport/CheckDealermembers", inputData);
    }
    DiamondChartForCutGrade(inputData) {
        return httpAdminReport.post("/DiamondReport/DiamondChartForCutGrade", inputData);
    }
    LoadChartReports(inputData) {
        return httpAdminReport.post("/GlobalDiamondReport/LoadChartReports", inputData);
    }
    GetDiamondReportForTableChartAll(inputData) {
        return httpAdminReport.post("/DiamondReport/GetDiamondReportForTableChartAll", inputData);
    }
}

export default new GlobalDiamondReportService();
