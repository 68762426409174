import React, { useState, useRef, useEffect } from "react";

import { NotificationManager } from "react-notifications";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { Input, Select, Button, Table, Modal, Switch, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { useSelector, useDispatch } from "react-redux";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import traslate from "../../../i18n/translate";
import { setSelectSideMenu } from "../../../actions/headerMenu/headerMenu";

const { Option } = Select;

export const ClientStatus = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [clientStatusData, setClientStatusData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [permisssionTypeMaster, setPermisssionTypeMaster] = useState([]);
  const [retailerStatusMaster, setRetailerStatusMaster] = useState([]);
  const [vendorStatusMaster, setVendorStatusMaster] = useState([]);
  const [key, setKey] = useState("approveUser");
  const [editData, setEditData] = useState({
    dealerCompany: "",
    dealerId: "",
    verified: true,
    vendorStatusID: 0,
    retailerStatusId: 0,
    vendorTagNameID: 0,
    vendorMembershipType: "",
    retailerNameId: 0,
    vendorStatus: "",
    retailerStatus: "",
    productType: "",
    productTypeId: 0,
    vendorTagName: "",
    hasMasterLink: true,
    vendorApp: "",
    username: "",
    email: "",
    password: "",
    scheduledReminder: false,
    advertisementStatus: false,
    apiAccess: false,
  });
  const [dateData, setDateData] = useState({
    dealerID: "",
    changeDate: "",
    dealerCompany: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingpageNo, setPendingPageNo] = useState(1);
  const [pendingpageSize, setPendingPageSize] = useState(10);
  const [pendingtotalCount, setPendingTotalCount] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("dealerId");
  const [sortType, setSortType] = useState("asc");
  const [statusColumn, setStatusColumn] = useState([]);
  const [vendorMembershipType, setVendorMembershipType] = useState([]);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [pendingJewelryRequest, setPendingJewelryRequest] = useState([]);
  const [pendingDataLoading, setPendingDataLoading] = useState(false);
  const [reload, setRelaod] = useState(false);
  const [search, setSearch] = useState([]);
  const [pendingUserSearch, setPendingUserSearch] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          if (props.type) {
          } else {
            handleClientStatusList(pageNo, pageSize, "dealerId", "asc", search);
            GetPermisssionTypeMaster();
            GetRetailerStatusMaster();
            GetVendorStatusMaster();
          }
        }
      }
    }
  }, []);
  const GetPermisssionTypeMaster = () => {
    AdminToolsDataService.GetPermisssionTypeMaster()
      .then((response) => {
        var message = response.data.message;

        var responseData = response.data.responseData;
        if (message === "Success") {
          setPermisssionTypeMaster(responseData);
        } else {
          setPermisssionTypeMaster([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetRetailerStatusMaster = () => {
    AdminToolsDataService.GetRetailerStatusMaster()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerStatusMaster(responseData);
        } else {
          setRetailerStatusMaster([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetVendorStatusMaster = () => {
    AdminToolsDataService.GetVendorStatusMaster()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorStatusMaster(responseData);
        } else {
          setVendorStatusMaster([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClientStatusList = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    filters
  ) => {
    let inputData = {
      clickChar: "",
      sortExpression: "",
      pageIndex: pageNo,
      pageSize: pageSize,
      sortColumnName: sortColumnName || "dealerId",
      sortOrder: sortOrder || "ASC",
      lstColumnFilter: filters || [],
    };
    setLoading(true);

    AdminToolsDataService.GetClientStatus(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setClientStatusData(responseData.lstClientStatus);
            setTotalCount(responseData.totalRecords);

            if (responseData.lstClientStatusColumn) {
              responseData.lstClientStatusColumn.forEach(function (data) {
                data["text"] = data["clientStatus"];
                data["value"] = data["clientStatus"];
                delete data["clientStatus"];
                delete data["id"];
              });
              setStatusColumn(responseData.lstClientStatusColumn);
            }
            if (responseData.lstVendorMembershipType) {
              responseData.lstVendorMembershipType.forEach(function (data) {
                data["text"] = data["vendorMembershipType"];
                data["value"] = data["vendorMembershipType"];
                delete data["vendorMembershipType"];
                delete data["id"];
              });
              setVendorMembershipType(responseData.lstVendorMembershipType);
            }
          } else {
            setStatusColumn([]);
            setVendorMembershipType([]);
            setClientStatusData([]);
            setTotalCount(0);
          }
          setLoading(false);
        } else {
          setClientStatusData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showModal = (row) => {
    setIsModalVisible(true);
    setEditData({
      dealerCompany: row.dealerCompany,
      dealerId: row.dealerId,
      verified: row.verified,
      vendorStatusID: row.vendorStatusID,
      retailerStatusId: row.retailerStatusId,
      vendorTagNameID: row.vendorTagNameID,
      vendorMembershipType: row.vendorMembershipType,
      retailerNameId: row.retailerNameId,
      vendorStatus: row.vendorStatus,
      retailerStatus: row.retailerStatus,
      productTypeId: row.productTypeId,
      productType: row.productType,
      vendorTagName: row.vendorTagName,
      hasMasterLink: true,
      vendorApp: row.vendorApp,
      username: row.username,
      email: row.email,
      password: row.password,
      scheduledReminder: row.scheduledReminder,
      advertisementStatus: row.advertisementStatus,
      apiAccess: row.apiaccess,
    });
  };

  const showModal2 = (row) => {
    setDateData((prevState) => ({
      ...prevState,
      dealerID: row.dealerId,
      dealerCompany: row.dealerCompany,
      changeDate: moment(row.createdDate).format("YYYY-MM-DD").toString(),
    }));
    setIsModalVisible2(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditData({});
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setDateData({
      dealerID: "",
      changeDate: "",
      dealerCompany: "",
    });
  };
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => { },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
    setSearch([]);
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditData({
        ...editData,
        [name]: e,
      });
    } else {
      setEditData({
        ...editData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdate = async () => {
    try {
      let inputData = {
        dealerCompany: editData.dealerCompany,
        dealerId: editData.dealerId,
        verified: editData.verified,
        vendorStatusID: editData.vendorStatusID,
        retailerStatusId: editData.retailerStatusId,
        vendorMembershipType: editData.vendorMembershipType,
        retailerNameId: 0,
        vendorStatus: "",
        retailerStatus: "",
        productTypeId: 0,
        productType: "",
        vendorTagName: "",
        hasMasterLink: true,
        vendorApp: "",
        username: "",
        email: "",
        password: "",
        ipAddress: "",
        logInUser: 0,
        logInUserName: "",
        scheduledReminder: editData.scheduledReminder,
        advertisementStatus: editData.advertisementStatus,
        apiaccess: editData.apiAccess,
      };

      await AdminToolsDataService.UpdateClientStatus(inputData)
        .then((response) => {
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(traslate("Client status Updated Successfully."));

            handleClientStatusList(
              pageNo,
              pageSize,
              sortColumnName,
              sortType,
              search
            );
          } else {
            NotificationManager.success(traslate("Client status Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleCancel();
  };

  const handleChangeDate = async () => {
    try {
      let inputData = {
        dealerID: dateData.dealerID.toString(),
        createdDate: dateData.changeDate,
      };

      await AdminToolsDataService.ChangeDealerCreatedDate(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(traslate("Date Changed Successfully."));
            handleClientStatusList(
              pageNo,
              pageSize,
              sortColumnName,
              sortType,
              search
            );
          } else {
            NotificationManager.success(traslate("Date Not Changed."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleCancel2();
  };

  const onSelectDate = (e) => {
    setDateData((prevState) => ({
      ...prevState,
      changeDate: e.target.value,
    }));
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerId";
      setSortColumnName(column);
      setSortType(type);
    }
    //search and filter
    if (filters.clientStatus) {
      filters.clientStatus.forEach((element) => {
        obj = {
          columnName: "clientStatus",
          columnValue: element,
          columnType: "CheckBox",
        };
        filter.push(obj);
      });
    } else {
    }

    if (filters.vendorMembershipType) {
      filters.vendorMembershipType.forEach((element) => {
        obj = {
          columnName: "vendorMembershipType",
          columnValue: element,
          columnType: "CheckBox",
        };
        filter.push(obj);
      });
    } else {
    }

    if (filters.dealerCompany) {
      obj = {
        columnName: "dealerCompany",
        columnValue: filters.dealerCompany[0],
        columnType: "Input",
      };
      filter.push(obj);
    } else {
    }
    if (filters.dealerId) {
      obj = {
        columnName: "dealerId",
        columnValue: filters.dealerId[0],
        columnType: "Input",
      };
      filter.push(obj);
    } else {
    }

    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);

    if (key == "approveUser") {
      setSearch(filter);
      handleClientStatusList(
        pagination.current,
        pagination.pageSize,
        column,
        type,
        filter
      );
    } else if (key == "pendingUser") {
      setPendingUserSearch(filter);
      setPendingPageNo(pagination.current);
      setPendingPageSize(pagination.pageSize);
      handleGetnotVerifiedClientStatus(
        pagination.current,
        pagination.pageSize,
        column,
        type,
        filter
      );
    }
  };

  /*Pending Jewelry Request Starts*/
  const columns = [
    {
      title: traslate("Dealer Company"),
      dataIndex: "dealerCompany",
      ...getColumnSearchProps("dealerCompany"),
      key: "dealerCompany",
      showSorterTooltip: false,
      sorter: (a, b) => { },
      width: 100,
    },
    {
      title: traslate("Country"),
      width: 60,
      dataIndex: "countryName",
      showSorterTooltip: false,
      sorter: (a, b) => { },
    },
    {
      title: traslate("City"),
      width: 60,
      dataIndex: "cityName",
      showSorterTooltip: false,
      sorter: (a, b) => { },
    },
    {
      title: traslate("State"),
      width: 60,
      dataIndex: "stateName",
      showSorterTooltip: false,
      sorter: (a, b) => { },
    },
    {
      title: traslate("Requested Date"),
      width: 80,
      dataIndex: "createdDate",
      showSorterTooltip: false,
      sorter: (a, b) => { },
    },
    {
      title: traslate("Action Request"),
      render: (index, row) => (
        <div className="action__btns">
          <div className="icn__div">
            <span
              className="linkText"
              onClick={() => {
                handleUpdateNoteVerifedClientStatus(row.dealerId, true);
              }}
            >
              {traslate("Approve")}
            </span>
          </div>
          <div className="icn__div ml-2">
            <span
              className="linkText"
              onClick={() => {
                handleRejectVerifedClientStatus(row.dealerId, false);
              }}
            >
              {traslate("Reject")}
            </span>
          </div>
        </div>
      ),
      width: 100,
    },
  ];

  const handleGetnotVerifiedClientStatus = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    filters
  ) => {
    let inputData = {
      clickChar: "",
      sortExpression: "",
      pageIndex: pageNo ? pageNo : 1,
      pageSize: pageSize ? pageSize : 10,
      sortColumnName: sortColumnName,
      sortOrder: sortOrder,
      lstColumnFilter: filters ? filters : [],
    };
    setPendingDataLoading(true);
    AdminToolsDataService.GetnotVerifiedClientStatus(inputData)
      .then((response) => {
        setLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData;
        var temptotalRecords = responseData.totalRecords;
        if (message === "Success") {
          setPendingTotalCount(temptotalRecords);
          setPendingJewelryRequest(responseData.lstClientStatus);
          setPendingDataLoading(false);
        } else {
          setPendingJewelryRequest([]);
          setPendingDataLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPendingDataLoading(false);
      })
      .finally(() => {
        setPendingDataLoading(false);
      });
  };

  const handleUpdateNoteVerifedClientStatus = (id, approve) => {
    let inputData = {
      dealerID: id,
      approve: approve,
    };
    setLoading(true);
    AdminToolsDataService.ApproveClientStatusAsync(inputData)
      .then((response) => {
        setLoading(false);
        var message = response.data.message;
        if (message === "Success") {
          if (approve == true) {
            NotificationManager.success(traslate("Request Approved."));
          }

          handleGetnotVerifiedClientStatus(
            pageNo,
            pageSize,
            sortColumnName,
            sortType
          );
        } else {
          NotificationManager.error(traslate("Request Not Approved"));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRejectVerifedClientStatus = (id, approve) => {
    let inputData = {
      dealerID: id,
      approve: approve,
    };
    AdminToolsDataService.rejectVerifedClientStatus(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          if (approve == false) {
            NotificationManager.success(traslate("Request Rejected."));
          }
          handleGetnotVerifiedClientStatus(
            pageNo,
            pageSize,
            sortColumnName,
            sortType
          );
        } else {
          NotificationManager.error(traslate("Request Not Approved"));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlTabChanges = (e) => {
    setKey(e);
    setPageNo(1);
    setPageSize(10);
    setPendingPageNo(1);
    setPendingPageSize(10);
    if (e == "approveUser") {
      handleClientStatusList(1, 10, "dealerId", "asc", search);
    } else if (e == "pendingUser") {
      handleGetnotVerifiedClientStatus(
        1,
        10,
        "dealerId",
        "asc",
        pendingUserSearch
      );
    }
  };

  let navigate = useNavigate();
  const handleOpenSideMenu = (row) => {
    navigate("/applicationPermissions", { state: { key: row.dealerId } });
    dispatch(setSelectSideMenu(["applicationPermissions"]));
  };
  return (
    <div className="form__fields border-0">
      <div className="row">
        <div className="col-lg-12">
          <h4 class="workarea__heading mt-0 mb-0">
            {traslate("Client Status")}
          </h4>
        </div>

        {/* Tabs Start */}
        <div className="col-lg-12 col-md-12">
          <div className="prodetail__section">
            <div className="prod__maindiv border-0 p-0">
              <div className="prodesc__div">
                <Tabs
                  defaultActiveKey="approveUser"
                  id="uncontrolled-tab-example"
                  className="tab__div mb-3 mt-4 mobile-m-0"
                  activeKey={key}
                  onSelect={(k) => handlTabChanges(k)}
                >
                  <Tab
                    eventKey="approveUser"
                    title={traslate("Approved Users")}
                  >
                    <div className="divForm mt-4 ClientStatusTable">
                      <Spin spinning={loading}>
                        <Table
                          key={reload}
                          columns={[
                            {
                              title: traslate("Dealer ID"),
                              ...getColumnSearchProps("dealerId"),
                              dataIndex: "dealerId",
                              key: "dealerId",
                              width: 150,
                              showSorterTooltip: false,
                              sorter: (a, b) => { },
                            },
                            {
                              title: traslate("Dealer Company"),
                              ...getColumnSearchProps("dealerCompany"),
                              dataIndex: "dealerCompany",
                              key: "dealerCompany",
                              showSorterTooltip: false,
                              sorter: (a, b) => { },
                              width: 180,
                            },
                            {
                              title: traslate("Client Status"),
                              dataIndex: "clientStatus",
                              filters: statusColumn,
                              showSorterTooltip: false,
                              onFilter: (value, record) =>
                                record.clientStatus === value,
                              sorter: (a, b) => { },
                              render: (index, row) => (
                                <span>{row.clientStatus}</span>
                              ),
                              width: 150,
                            },
                            {
                              title: traslate("Approved Apps"),
                              dataIndex: "vendorApp",
                              showSorterTooltip: false,
                              sorter: (a, b) => { },
                              width: 180,
                              render: (index, row) => (
                                <div>
                                  <div>{row.vendorApp}</div>
                                  <div>
                                    <span
                                      className="linkText"
                                      onClick={() => handleOpenSideMenu(row)}
                                    >
                                      {traslate("Edit")}
                                    </span>
                                  </div>
                                </div>
                              ),
                            },
                            {
                              title: traslate("Account Type"),
                              dataIndex: "vendorMembershipType",
                              filters: vendorMembershipType,
                              showSorterTooltip: false,
                              sorter: (a, b) => { },

                              onFilter: (value, record) =>
                                record.vendorMembershipType === value,

                              width: 180,
                            },

                            {
                              title: traslate("Verified"),
                              dataIndex: "verified",
                              width: 120,
                              showSorterTooltip: false,
                              render: (index, row) => (
                                <span>
                                  {row.verified
                                    ? traslate("Yes")
                                    : traslate("No")}
                                </span>
                              ),
                              sorter: (a, b) => { },
                            },
                            {
                              title: traslate("Scheduled Reminder"),
                              dataIndex: "scheduledReminder",
                              width: 150,
                              showSorterTooltip: false,
                              render: (index, row) => (
                                <span>
                                  {row.scheduledReminder
                                    ? traslate("Yes")
                                    : traslate("No")}
                                </span>
                              ),
                              sorter: (a, b) => { },
                            },
                            {
                              title: traslate("API Access"),
                              dataIndex: "apiaccess",
                              width: 150,
                              showSorterTooltip: false,
                              render: (index, row) => (
                                <span>
                                  {row.apiaccess
                                    ? traslate("Yes")
                                    : traslate("No")}
                                </span>
                              ),
                              sorter: (a, b) => { },
                            },

                            {
                              title: traslate("Action"),
                              width: 100,
                              render: (index, row) => (
                                <div className="action__btns">
                                  <div className="image__block">
                                    <img
                                      src={Edit_icon}
                                      onClick={() => showModal(row)}
                                      alt=""
                                    />
                                  </div>
                                  <div className="image__block">
                                    <i
                                      class="fa fa-calendar"
                                      onClick={() => showModal2(row)}
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                          dataSource={clientStatusData}
                          onChange={handleTableASCDES}
                          pagination={{
                            total: totalCount,
                            pageNo: pageNo,
                            pageSize: pageSize,
                            pageSizeOptions: [10, 20, 50, 100],
                            responsive: true,
                            showSizeChanger: true,
                          }}
                          scroll={{ x: 1400, y: 800 }}
                        />
                      </Spin>
                    </div>
                  </Tab>
                  <Tab eventKey="pendingUser" title={traslate("Pending Users")}>
                    <div className="pendjewelreq__section mt-4">
                      <div className="form__fields border-0 p-0">
                        <div className="col-md-12"></div>
                        <div className="col-lg-12 col-md-12">
                          <Spin spinning={pendingDataLoading}>
                            <Table
                              key={reload}
                              className="pendjewelreq__tbl"
                              columns={columns}
                              dataSource={pendingJewelryRequest}
                              onChange={handleTableASCDES}
                              scroll={{ x: 600, y: 800 }}
                              pagination={{
                                total: pendingtotalCount,
                                pageNo: pendingpageNo,
                                pageSize: pendingpageSize,
                                pageSizeOptions: [10, 20, 50, 100],
                                responsive: true,
                                showSizeChanger: true,
                              }}
                            />
                          </Spin>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* Tabs End */}

        <Modal
          title={traslate("Update Client Status Information")}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleUpdate}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          <h6>
            {editData.dealerCompany}-{editData.dealerId}
          </h6>
          <div className="form__fields mt-3 p-0 border-0">
            <div className="row">
              <div className="col-md-6">
                <div className="input__block">
                  <label>{traslate("Account Type")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Permission Type "
                    optionFilterProp="children"
                    onChange={(e) =>
                      handleOnChange(e, "select", "vendorMembershipType")
                    }
                    name="vendorMembershipType"
                    value={
                      editData.vendorMembershipType
                        ? editData.vendorMembershipType
                        : null
                    }
                  >
                    <Option key="" value="">
                      Select Status
                    </Option>
                    {permisssionTypeMaster.map((item) => {
                      return (
                        <Option
                          key={item.membeshipTypeId}
                          value={item.membeshipTypeText}
                        >
                          {item.membeshipTypeText}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="input__block">
                  <label>{traslate("Verified")} </label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    name="verified"
                    checked={editData.verified}
                    onChange={(e) => handleOnChange(e, "select", "verified")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="input__block">
                  <label>{traslate("Schedule Reminder")} </label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    name="scheduledReminder"
                    checked={editData.scheduledReminder}
                    onChange={(e) =>
                      handleOnChange(e, "select", "scheduledReminder")
                    }
                  />
                </div>
              </div>

              {(editData.vendorMembershipType == "VendorDiamond" ||
                editData.vendorMembershipType == "Vendor") && (
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>{traslate("Vendor Status")} </label>
                      <Select
                        showSearch
                        className="border__grey"
                        placeholder="Select Vendor Status"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "vendorStatusID")
                        }
                        name="vendorStatusID"
                        value={
                          editData.vendorStatusID ? editData.vendorStatusID : null
                        }
                      >
                        {vendorStatusMaster.map((item) => {
                          return (
                            <Option key={item.statusId} value={item.statusId}>
                              {item.statusText}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                )}
              {editData.vendorMembershipType == "Retailer" && (
                <div className="col-md-6">
                  <div className="input__block">
                    <label> {traslate("Retailer Status")} </label>
                    <Select
                      showSearch
                      className="border__grey"
                      placeholder="Select Retailer Status "
                      optionFilterProp="children"
                      onChange={(e) =>
                        handleOnChange(e, "select", "retailerStatusId")
                      }
                      name="retailerStatusId"
                      value={
                        editData.retailerStatusId
                          ? editData.retailerStatusId
                          : null
                      }
                    >
                      {retailerStatusMaster.map((item) => {
                        return (
                          <Option key={item.statusId} value={item.statusId}>
                            {item.statusText}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              )}
              <div className="col-md-4">
                <div className="input__block">
                  <label>{traslate("API Access")} </label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    name="apiAccess"
                    checked={editData.apiAccess}
                    onChange={(e) => handleOnChange(e, "select", "apiAccess")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title={traslate("Update Client Status Information")}
          visible={isModalVisible2}
          onCancel={handleCancel2}
          footer={[
            <Button key="back" onClick={handleCancel2}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleChangeDate}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          <h6>
            {" "}
            {traslate("Update Date on which")} {dateData.dealerCompany}{" "}
            {traslate("Is Created")}{" "}
          </h6>
          <div className="row">
            <div className="col-md-6">
              <div className="input__block">
                <input
                  className="style__border"
                  name="changeDate"
                  value={dateData.changeDate ? dateData.changeDate : ""}
                  onChange={onSelectDate}
                  type="date"
                  placeholder="Enter Date"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ClientStatus;
