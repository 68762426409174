import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Select, Modal, Button, Table, Spin } from "antd";
import traslate from "../../../i18n/translate";
import AdminToolsDataService from "../../../services/admin-tools.service";
import Grd_Prnding from "../../../assets/images/grd_pending.png";
import Grd_Complete from "../../../assets/images/grd_complete.png";
import Grd_Error from "../../../assets/images/grd_error.png";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
const { Option } = Select;

const SyncHistory = () => {
  const loginDetials = useSelector((state) => state.loginReducer);
  const [visible, setVisible] = useState(false);
  const [dealerId, setDealerId] = useState(0);
  const [dealerIdname, setDealerIdName] = useState("");
  const [detailsId, setDetailsId] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [SyncHistoryDetails, SetSyncHistoryDetails] = useState([]);
  const [SyncDropDetails, SetSyncDropDetails] = useState({
    dealerNameDrop: "",
  });
  const [SyncDropListDetails, SetSyncDropListDetails] = useState([]);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setDealerIdName(loginDetials.loginDetials.responseData.dealerCompany);
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
          GetMySyncHistotyDetails(
            loginDetials.loginDetials.responseData.dealerId,
            pageNo,
            pageSize
          );
          setTimeout(() => {
            GetSyncDealerDetails();
          }, 200);
        }
      }
    }
  }, []);

  const GetMySyncHistotyDetails = (id, pageNo, size) => {
    let inputData = {
      dealerID: id ? id.toString() : SyncDropDetails.dealerNameDrop.toString(),
      index: pageNo,
      pageSize: size,
    };
    setLoading(true);
    try {
      AdminToolsDataService.GetSyncList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.lstsyncHHistory;
          let count = response.data.responseData.totalRows;
          if (message === "Success") {
            SetSyncHistoryDetails(responseData);
            setTotalCount(count);
            setLoading(false);
          } else {
            SetSyncHistoryDetails([]);
            setTotalCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          SetSyncHistoryDetails([]);
          setTotalCount(0);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      SetSyncHistoryDetails([]);
      setTotalCount(0);
      setLoading(false);
    }
  };

  const GetSyncDealerDetails = () => {
    try {
      AdminToolsDataService.GetDealerSyncDetails()
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            SetSyncDropListDetails(responseData);
          } else {
            SetSyncHistoryDetails([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetMySyncProductDetails = () => {
    let inputData = {
      dealerID: dealerId,
    };
    setLoading(true);
    try {
      AdminToolsDataService.SyncProductsDetails(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (
              responseData.returnMessage ==
              "Product sync request has been sent and the file processing has started. You can check upload status in following grid."
            ) {
              NotificationManager.success(responseData.returnMessage);
              GetMySyncHistotyDetails(dealerId, pageNo, pageSize);
              setLoading(false);
            } else {
              setLoading(false);
              NotificationManager.error(responseData.returnMessage);
            }
          } else {
            SetSyncHistoryDetails([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetMyForceFullSyncDetails = () => {
    let inputData = {
      dealerID: dealerId,
    };
    setLoading(true);
    try {
      AdminToolsDataService.ForceFullSync(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (
              responseData.returnMessage ==
              "Product force full sync request has been sent and the file processing has started. You can check upload status in following grid."
            ) {
              NotificationManager.success(responseData.returnMessage);
              GetMySyncHistotyDetails(dealerId, pageNo, pageSize);
              setLoading(false);
            } else {
              NotificationManager.error(responseData.returnMessage);
              setLoading(false);
            }
          } else {
            SetSyncHistoryDetails([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    GetMySyncHistotyDetails(dealerId, page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    GetMySyncHistotyDetails(dealerId, current, size);
  }
  const handleGetStoreDetails = (row) => {
    setVisible(true);
    handleSyncErrorMsg(row);
  };

  const handleSyncErrorMsg = (row) => {
    let inputData = {
      detaillinkID: row.jewelryCSVHistoryID,
      totalCount: row.records,
    };
    setLoading(true);
    try {
      AdminToolsDataService.SyncErrorMsg(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setDetailsId(responseData);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      SetSyncDropDetails({
        ...SyncDropDetails,
        [name]: e,
      });
    } else {
      SetSyncDropDetails({
        ...SyncDropDetails,
        [e.target.name]: e.target.value,
      });
    }
    if (e != "0") {
      GetMySyncHistotyDetails(e, pageNo, pageSize);
    }
    setDealerId(e);
  };

  const handleGetDownloadSync = (row) => {
    const sign = "-";
    const firstvalue = row.jewelryCSVHistoryID.toString();
    const secondvalue = row.typeId;
    let inputData = {
      dealerID: dealerId,
      summaryID: `${firstvalue}${sign}${secondvalue}`,
    };
    setLoading(true);
    try {
      AdminToolsDataService.DownloadReportSync(inputData)
        .then((response) => {
          if (response.data.message === "Success") {
            if (response.data.statusCode == 200) {
              window.open(response.data.responseData, "__blank");
              setLoading(false);
            } else if (response.data.statusCode == 1001) {
              NotificationManager.error("File Not Found");
              setLoading(false);
            } else {
              NotificationManager.error("File Not Found");
              setLoading(false);
            }
          } else {
            NotificationManager.error("File Not Found");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: traslate("Status"),
      dataIndex: "status",
      key: "status",
      render: (item, row) => {
        return (
          <span>
            {" "}
            {row.status == "Pending" ? (
              <img src={Grd_Prnding} alt="" />
            ) : row.status == "Error" ? (
              <img src={Grd_Error} alt="" />
            ) : (
              <img src={Grd_Complete} alt="" />
            )}{" "}
            <span>{row.status}</span>
          </span>
        );
      },
    },
    {
      title: traslate("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: traslate("Initiated"),
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: traslate("Completed"),
      dataIndex: "completedDate",
      key: "completedDate",
    },
    {
      title: traslate("Detail"),
      dataIndex: "Date",
      key: "",
      render: (item, row) => {
        return (
          <span
            className="linkText"
            onClick={() => {
              handleGetStoreDetails(row);
            }}
          >
            {traslate("Details")}
          </span>
        );
      },
    },
    {
      title: traslate("Records"),
      dataIndex: "records",
      key: "records",
    },
    {
      title: traslate("Folder ID"),
      dataIndex: "jewelryCSVHistoryID",
      key: "jewelryCSVHistoryID",
    },
    {
      title: traslate("File"),
      dataIndex: "Date",
      render: (item, row) => {
        return (
          <React.Fragment>
            <Link
              to=""
              onClick={() => {
                handleGetDownloadSync(row);
              }}
            >
              {traslate("Download")}
            </Link>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <div className="pendjewelreq__section magento__mapping">
      <div className="form__fields">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {traslate("Sync History")}
            </h4>

            <div className="d-flex justify-content-end w-85 display__block mobile-w-100">
              {dealerMemberTypeName == "Admin" ? (
                <div className="input__block w-30 mr-2 mb-0 d-flex mobile-w-100">
                  <label className="w-60">
                    {" "}
                    {traslate("Select Dealer :")}{" "}
                  </label>
                  <Select
                    value={
                      SyncDropDetails.dealerNameDrop
                        ? SyncDropDetails.dealerNameDrop
                        : SyncDropListDetails.find(
                            (o) =>
                              o.dealerID ===
                              loginDetials.loginDetials.responseData.dealerId
                          )
                        ? loginDetials.loginDetials.responseData.dealerId
                        : dealerIdname
                    }
                    name="dealerNameDrop"
                    placeholder="Select Location"
                    onChange={(e) =>
                      handleOnChange(e, "select", "dealerNameDrop")
                    }
                    // onSearch={onSearch}
                    showSearch
                    optionFilterProp="children"
                    className="border__grey"
                  >
                    <Option value="0"> {traslate("Select Dealer")} </Option>
                    {SyncDropListDetails?.map((item, i) => {
                      return (
                        <Option value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              ) : (
                ""
              )}
              <button
                className="primary-btn mr-2 mobile-w-100 mobile__topspace"
                onClick={() => {
                  GetMySyncProductDetails();
                }}
              >
                {traslate("Sync Products")}
              </button>
              <button
                className="primary-btn mr-2 mobile-w-100 mobile__topspace"
                onClick={() => {
                  GetMyForceFullSyncDetails();
                }}
              >
                {traslate("Forced Full Sync")}
              </button>
              <button
                className="primary-btn mr-2 mobile-w-100 mobile__topspace"
                onClick={() => {
                  GetMySyncHistotyDetails(dealerId, pageNo, pageSize);
                }}
              >
                {traslate("Update History")}
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <Spin spinning={loading}>
            <Table
              className="pendjewelreq__tbl"
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [
                  10, 20, 50, 100, 200, 250, 300, 350, 400, 450, 500, 550, 600,
                ],
                responsive: true,
                showSizeChanger: true,
                onChange: handlePageNoChange,
                onShowSizeChange: handlePageSizeChange,
              }}
              loading={loading}
              columns={columns}
              dataSource={SyncHistoryDetails}
              scroll={{ x: 600, y: 800 }}
            />
          </Spin>
        </div>
        <Modal
          title=""
          className="modal__tradeshow"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={handleCancel}
          width={500}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("OK")}
            </Button>,
          ]}
        >
          <div className="col-md-12">
            <div className="form__fields border-0 p-0">
              <div className="input__block">
                <label>{detailsId}</label>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SyncHistory;
