import { httpCatalog } from "../helper/http-common";
class SearchDiamondService {
  SearchMinedDiamonds(inputData, type) {
    if (type === "LAB") {
      return httpCatalog.post(
        "/SearchDimond/SearchLabGrownDiamondList",
        inputData
      );
    } else {
      return httpCatalog.post("/SearchDimond/SearchMinedDiamonds", inputData);
    }
  }
  GetALLFancyDiamondList(inputData) {
    return httpCatalog.post("/SearchDimond/GetALLFancyDiamondList", inputData);
  }
}

export default new SearchDiamondService();
