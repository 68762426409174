import { http } from "../helper/http-common";

class CompanyShowsService {
    GetDealerShowsDetails(inputData) {
        return http.post("/Profile/GetDealerShowDetails",inputData)
    }
    GetBindDealerShowDetailss(inputData) {
        return http.post("/Profile/BindDealerShowDetails",inputData)
    }
    BindDealerShowDetails(inputData){
        return http.post("/Profile/BindDealerShowDetails",inputData)
    }
    UpdateDealerShowsDetails(inputData){
        return http.post("/Profile/UpdateDealerShowsDetails",inputData)
    }
    AddShowToDealer(inputData){
        return http.post("/Profile/AddShowToDealer",inputData)
    }
    RemoveShowToDealer(inputData){
        return http.post("/Profile/RemoveShowToDealer",inputData)
    }
}
export default new CompanyShowsService();