import { httpAdminCompanyLocation ,httpPurchaseOrder} from "../helper/http-common";

class CompanyLocationService {
    GetLocationDetails(inputData) {
        return httpAdminCompanyLocation.post("/Profile/LocationLoadDetails", inputData)
    }
    GetPOLocationDetails(inputData) {
        return httpPurchaseOrder.post("/PurchaseOrder/POLocationLoadDetails", inputData)
    }
    GetLocationData(inputData) {
        return httpAdminCompanyLocation.post("/Profile/LocationByID", inputData)
    }
    UpdateLocationName(inputData) {
        //return httpAdminCompanyLocation.post("/Profile/UpdateLocationName?DealerID="+inputData.DealerID+"")
        return httpAdminCompanyLocation.post("/Profile/UpdateLocationName", inputData)
    }
    UpdatePOLocationName(inputData) {
        //return httpAdminCompanyLocation.post("/Profile/UpdateLocationName?DealerID="+inputData.DealerID+"")
        return httpPurchaseOrder.post("/PurchaseOrder/AddPOLocation", inputData)
    }
    AddNewLocation(inputData) {
        return httpAdminCompanyLocation.post("/Profile/AddLocation", inputData)
    }
    UpdateLocation(inputData) {
        return httpAdminCompanyLocation.post("/Profile/SaveDealersLocationData", inputData)
    }
    UpdatePOLocation(inputData) {
        return httpPurchaseOrder.post("/PurchaseOrder/SavePOLocationDetails", inputData)
    }
    DeleteLocation(inputData) {
        return httpAdminCompanyLocation.post("/Profile/DeleteLocation", inputData)
    }
    DeletePOLocation(inputData) {
        return httpPurchaseOrder.post("/PurchaseOrder/DeletePOLocation", inputData)
    }
    QRCodeTextUpdate(inputData) {
        return httpAdminCompanyLocation.post("/Profile/LocationUpdateQRCode", inputData)
    }
}

export default new CompanyLocationService();